import {faInfo} from "@fortawesome/free-solid-svg-icons";
import SettingsLayout from "../layout/SettingsLayout";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import ProjectDefault from "../form/project/ProjectDefault";
import SettingsStepLayout from "../layout/SettingsStepLayout";
import {getProject, postProject} from "../../api/project/project.api";

const ProjectEdit = () => {
    const [query] = useSearchParams();
    const [project, setProject] = useState({ projectSettings: {} })
    const [isRequired, setRequired] = useState(false)

    const tabs = [
        { label: "기본 설정", value: "default" },
    ]
    const [tab, setTab] = useState(tabs[0].value);
    const id = query.get("id")

    useEffect(() => {
        if (id) {
            getProject(id).then(({data}) => {
                setProject(data)
                setRequired(true)
            })
        }
    }, [id, query])

    const updateProject = (key, value) => {
        const newProject = { ...project }
        newProject[key] = value
        setProject(newProject)
    }

    const handleConfirm = async () => {
        postProject(project).then(({data}) => {
            alert("저장 되었습니다.")
            window.location.href = (`/project/detail?id=${data}`);
        })
    }

    const render = () => {
        if (tab === "default") {
            return (
                <div className="setting-step-padding">
                    <ProjectDefault project={project} onChangeProjectName={(name) => {
                        updateProject("name", name)
                        setRequired(name.length > 0)
                    }}
                                    onChangeStartDate={(startDate) => {
                                        updateProject("startDate", startDate.format("YYYY-MM-DD HH:mm"))
                                    }}
                                    onChangeEndDate={(endDate) => {
                                        updateProject("endDate", endDate?.format("YYYY-MM-DD HH:mm"))
                                    }}
                                    onChangeDuplicateProject={(projects) => {
                                        updateProject("duplicateProjects", projects)
                                    }}
                    />
                </div>
            )
        }
    }

    const description = () => {
        if (tab === "default") {
            return "프로젝트의 기본 정보를 설정해 주세요."
        } else {
            return ""
        }
    }

    return (
        <SettingsLayout
            title={"프로젝트 만들기"}
            icon={faInfo}
            tabs={tabs}
            activeTab={tab}
            onConfirm={handleConfirm}
            onChange={setTab}
            isConfirm={isRequired}
            confirmText={"완료"}
            onClose={() => window.location.href = ("/project")}
        >
            <SettingsStepLayout description={description()} renderChild={render()} />
        </SettingsLayout>
    );
};

export default ProjectEdit;
