import {Button, TextInput} from "omoplay";
import {useEffect, useState} from "react";
import {getPartner, partnerAuth, partnerRegistration} from "../../../api/partner/partner.api";
import GuideIcon from "../../../assets/images/guide_icon.svg";


const KakaoAuthorization = ({onSubmit}) => {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [authFlag, setAuthFlag] = useState(false)
    const [plusFriendName, setPlusFriendName] = useState()
    const [authCodeDisabled, setAuthCodeDisabled] = useState(true)
    const [code, setCode] = useState("")

    useEffect(() => {
        getPartner().then(({data}) => {
            setPlusFriendName(data.kakaoChannelName)
        })
    }, [])
    const auth = () => {
        partnerAuth(plusFriendName, phoneNumber)
            .then(({data}) => {
                if (data.code !== "200") {
                    alert(data.message)
                } else {
                    setAuthCodeDisabled(false)
                    alert("인증번호가 전송되었습니다. 알림톡을 확인해 주세요.")
                }
            })
    }
    const registration = () => {
        partnerRegistration(plusFriendName, phoneNumber, code)
            .then(({data}) => {
                if (data.code !== "509") {
                    alert(data.message)
                } else {
                    alert("인증 되었습니다.")
                    setAuthCodeDisabled(true)
                    setAuthFlag(true)
                    onSubmit()
                }
            })
    }

    return (
        <div className="flex-col gap-4">
            <div>
                <div>카카오톡채널 관리자 휴대폰 번호 <span className="text-red-500">*</span></div>
                <div>
                    <button className="rounded-lg bg-gray-100" style={{width: "165px", height: "42px"}}
                            onClick={() => {
                                window.open("https://local-room-f00.notion.site/1132d9e7196a8161b713c87184417389?pvs=4")
                            }}>
                        <div className="flex justify-center items-center">
                            <img alt="" src={GuideIcon} width="16px" height="16px" />
                            <div className="ml-2">설정 가이드 확인하기</div>
                        </div>
                    </button>
                </div>
            </div>
            <div className="flex-center-all">
                <div style={{width: "100%"}}>
                    <TextInput placeholder="01000000000"
                               onKeyUp={(e) => {
                                   setPhoneNumber(e.currentTarget.value)
                               }}
                               onKeyDown={(e) => {
                                   setPhoneNumber(e.currentTarget.value)
                               }}
                               disabled={authFlag} />
                </div>
                <div style={{minWidth: "130px", marginLeft: "10px"}}>
                    <Button variants="solid" value="인증번호 받기" onClick={() => auth()} disabled={!plusFriendName} />
                </div>
            </div>
            <div className="flex items-center justify-between">
                <div style={{width: "100%"}}>
                    <TextInput placeholder="인증번호"
                               onKeyUp={(e) =>
                                   setCode(e.currentTarget.value)}
                               disabled={authFlag} />
                </div>
                <div style={{minWidth: "130px", marginLeft: "10px"}}>
                    <Button variants="solid" value="인증 확인" disabled={authCodeDisabled}
                            onClick={() => registration()} />
                </div>
            </div>
        </div>
    )
}

export default KakaoAuthorization