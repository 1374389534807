import { useEffect, useState } from "react";
import ActiveButton from "../../components/ActiveButton";
import Popup from "../../components/Popup";
import { Link, useNavigate } from "react-router-dom";
import SuspensePopup from "../SuspensePopup";
import EventPreview from "../form/event/EventPreview";
import {Table, Pagination} from "omoplay";
import Badge from "../form/badge/Badge";
import MiniMenu from "../form/MiniMenu";
import {deleteEvent, getEventList} from "../../api/event/event.api";

const EventList = () => {
  const [page, setPage] = useState(1);
  const [eventList, setEventList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [deleteEventId, setDeleteEventId] = useState(undefined);
  const [previewPopup, setPreviewPopup] = useState(false);
  const [tableData, setTableData] = useState([])
  const [openMenuId, setOpenMenuId] = useState(null)

  const rowSize = 10

  useEffect(() => {
    getEventList(page, rowSize).then((res) => {
      if (res.data) {
        setEventList(res.data.content);
        setTotalPages(res.data.totalElements);
      }
    })
  }, [page])

  useEffect(() => {
    setTableData(eventList.map((event) => {
      const status = event.statusKor
      let badgeColor;
      if (event.status === "ACTIVE") {
        badgeColor = "blue"
      } else {
        badgeColor = "grey"
      }

      return [
          <div>{event.id}</div>,
        <div>
          <div>
            {
              event.type === "skip" ? <Badge name="바로당첨형" color="blue" /> : <Badge name="룰렛형" color="blue" />
            }
          </div>
          <div className="font-semibold">{event.name}</div>
          <div className="text-xs grey-7-color">
            {event.modifiedAt} 업데이트
          </div>
        </div>,
        <div className="flex-col gap-1">
          {
            event.benefits?.map(benefit => {
              return (
                  <div className="flex gap-1">
                    <div>{benefit.name ?? "꽝"}</div>
                    <div>({benefit.winningPercent}%)</div>
                  </div>
              )
            })
          }
        </div>,
          <div>
            {
              event.projectNames?.map(name => {
                return (
                    <div>{name}</div>
                )
              })
            }
          </div>,
        <div className="flex justify-center">
          <Badge
              name={status}
              color={badgeColor}
          />
        </div>,
        <div className="flex-center-all">
          <MiniMenu
              id={event.id}
              openMenuId={openMenuId}
              setOpenMenuId={setOpenMenuId}
              onClickEdit={() => window.location.href = `/event/edit?id=${event.id}`}
              onClickPreview={() => setPreviewPopup(event.id)}
              onClickDelete={() => setDeleteEventId(event.id)}
              disabledDelete={event.used}
          />
        </div>
      ]
    }))
  }, [eventList, openMenuId])

  const handleDelete = () => {
    setDeleteEventId(undefined)
    deleteEvent(deleteEventId).then(() => {
      window.location.reload()
    })
  };

  const headers = [
    { name: "ID", width: "60px" },
    { name: "이벤트", sortable: true },
    { name: "혜택", sortable: false },
    { name: "프로젝트", sortable: false },
    { name: "상태", align: "center", width: "120px" },
    { name: "", align: "center", sortable: false, width: "80px" },
  ];

  return (
    <>
      <div className="flex items-center gap-4">
        <div className="page-title">이벤트</div>
      </div>
      <div className="w-full" style={{marginTop: "33px"}}>
        <div className="flex w-100 items-center">
          <div className="w-full flex justify-between items-center">
            <div className="text-gray-600">
              총 <span className="font-bold">{totalPages}</span>개
            </div>
            <Link to={`/event/edit`}>
              <ActiveButton className="text-md ml-4 py-2 px-4">
                + 이벤트 추가
              </ActiveButton>
            </Link>
          </div>
        </div>
        <div className="flex-col py-2">
          <Table headers={headers} data={tableData} />
          <div className="flex justify-center" style={{marginTop: "24px"}}>
            <Pagination rowSize={rowSize} totalSize={totalPages} page={page} onPaging={(page) => setPage(page)} />
          </div>
        </div>
      </div>
      {deleteEventId && (
        <Popup
          title={"정말 삭제하시겠습니까?"}
          buttons={[
            { label: "취소", onClick: () => setDeleteEventId(undefined) },
            { label: "확인", type: "info", onClick: () => handleDelete() },
          ]}
        />
      )}
      {previewPopup && (
        <SuspensePopup
          title={"미리보기"}
          size={"w-full max-w-xl"}
          onClose={() => setPreviewPopup(null)}
        >
          <EventPreview id={previewPopup} />
        </SuspensePopup>
      )}
    </>
  );
};
export default EventList;
