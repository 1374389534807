import Layout from "../components/layout/Layout";
import Messages from "../components/page/Messages";

const MessagePage = () => {
  return (
    <Layout>
      <Messages />
    </Layout>
  );
};
export default MessagePage;
