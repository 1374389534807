import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabTitle from "../TabTitle";
const EditTitle = ({ icon, title, tabs, activeTab }) => {
  return (
    <div className="relative">
      <nav className="fixed top-0 w-full bg-white border-b border-b-gray-300 divide-y divide-gray-300 z-10">
        <div className="p-1">
          <div className="absolute flex items-center justify-start rtl:justify-end p-3">
            <div className="bg-indigo-200 text-indigo-500 px-1.5 py-0.5 rounded h-fit">
              <FontAwesomeIcon icon={icon} />
            </div>
            <h2 className="ml-2 font-bold text-lg">{title}</h2>
          </div>
          <div className="flex items-center justify-between">
            <div className="px-3 py-4 lgp:x-5 lg:pl-3 relative" style={{flexGrow: "2"}}>
              <TabTitle tabs={tabs} activeTab={activeTab} />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default EditTitle;
