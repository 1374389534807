import {Button, TextInput} from "omoplay";
import {codeAuthorization, idCheck, joinUserInfo} from "../api/auth/auth.api";
import {useEffect, useState} from "react";
import OMOPlayLogo from "../assets/images/logo_omoplay_black.svg";
import IconCheck from "../assets/images/icon.check.svg";
import Loading from "../components/Loading";

const JoinPage = () => {
    const [disabled, setDisabled] = useState(true)
    const [isIdValid, setIsIdValid] = useState()
    const [userId, setUserId] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [passwordValid, setPasswordValid] = useState(false)
    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [isRequest, setRequest] = useState(false)
    const [timeLeft, setTimeLeft] = useState(180)
    const [code, setCode] = useState("")
    const [requestCode, setRequestCode] = useState("")
    const [codeConfirm, setCodeConfirm] = useState(false)
    const [isRegistration, setRegistration] = useState(true)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        if (name.length > 1 && userId.length > 5 && passwordValid && codeConfirm && isIdValid) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [name, userId, passwordValid, codeConfirm, isIdValid]);

    useEffect(() => {
        if (isRequest && !codeConfirm) {
            if (timeLeft <= 0) {
                setRequestCode("");
                return;
            }

            const timer = setInterval(() => {
                setTimeLeft((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [timeLeft, isRequest])

    const formatTime = () => {
        const minutes = Math.floor(timeLeft / 60);
        const secs = timeLeft % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    function requestCodeFun() {
        setRequest(true)
        codeAuthorization(phoneNumber).then(({data}) => {
            alert("인증 메시지가 전송되었습니다.")
            setTimeLeft(180)
            setRequestCode(data.data)
        })
    }

    return (
        <div className="w-full h-screen flex justify-center items-center login-background-gradient">
            {
                isLoading && (
                    <div className="left-0 top-0 w-full fixed z-10 h-full flex-center-all" style={{backgroundColor: "black", opacity: "0.8"}}>
                        <Loading className={"w-20 h-20 absolute"} />
                    </div>
                )
            }
            <div className="h-full flex-1 bg-white">
                <div className="w-full h-full flex-col items-center justify-center" style={{minWidth: "540px"}}>
                    <div className="w-[400px]">
                        {
                            isRegistration ? (
                                <>
                                    <div className="flex justify-center text-[24px] font-medium" style={{marginBottom: "20px"}}>
                                        회원가입
                                    </div>
                                    <div className="text-center font-medium" style={{marginBottom: "43px"}}>회원가입을 환영합니다.</div>
                                    <div className="flex-col gap-4 w-full">
                                        <div className="flex-col gap-2">
                                            <label
                                                className="block text-gray-700 text-sm font-bold"
                                            >
                                                아이디
                                            </label>
                                            <TextInput placeholder="계정 아이디를 입력해주세요." onChange={(e) => {
                                                idCheck(e.currentTarget.value).then(({data}) => {
                                                    setIsIdValid(data.data)
                                                })
                                                setUserId(e.currentTarget.value)
                                            }} />
                                            {
                                                userId.length < 6 && (<div className="text-red-400">아이디는 6자리 이상이어야 합니다.</div>)
                                            }
                                            {
                                                isIdValid === false && (<div className="text-red-400">이미 존재하는 아이디 입니다.</div>)
                                            }
                                        </div>
                                        <div className="flex-col gap-2">
                                            <label
                                                className="block text-gray-700 text-sm font-bold"
                                            >
                                                비밀번호
                                            </label>
                                            <TextInput type="password" placeholder="비밀번호를 입력해주세요." onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                        <div className="flex-col gap-2">
                                            <label
                                                className="block text-gray-700 text-sm font-bold"
                                            >
                                                비밀번호 재입력
                                            </label>
                                            <TextInput type="password" placeholder="비밀번호를 다시 입력해주세요"
                                                       onChange={(e) => {
                                                           setPasswordValid(e.target.value === password)
                                                           setPasswordConfirm(e.target.value)
                                                       }} />
                                            {
                                                passwordConfirm.length > 0 && !passwordValid && <div className="text-red-400">비밀번호가 맞지 않습니다.</div>
                                            }

                                        </div>
                                        <div className="flex-col gap-2">
                                            <label
                                                className="block text-gray-700 text-sm font-bold"
                                            >
                                                이름
                                            </label>
                                            <TextInput placeholder="이름을 입력해주세요." onChange={(e) => setName(e.target.value)} />
                                        </div>
                                        <div className="flex-col gap-2">
                                            <label
                                                className="block text-gray-700 text-sm font-bold"
                                            >
                                                휴대폰번호
                                            </label>
                                            <div className="flex gap-2">
                                                <div className="flex-1">
                                                    <TextInput disabled={codeConfirm} placeholder="01012345678" onChange={(e) => setPhoneNumber(e.target.value)} />
                                                </div>
                                                <Button variants="primary" disabled={isRequest || phoneNumber.length < 10}
                                                        value="인증 요청" onClick={requestCodeFun} />
                                            </div>
                                        </div>
                                        <div className="flex-col gap-2">
                                            <div className="flex-align-center gap-2">
                                                <div className="flex-1">
                                                    <TextInput disabled={codeConfirm} placeholder="6자리 인증번호를 입력해주세요." onChange={(e) => {
                                                        setCode(e.target.value)
                                                    }} />
                                                </div>
                                                {(isRequest && !codeConfirm) && <div>{formatTime()}</div>}
                                                <Button variants="primary" disabled={!isRequest || codeConfirm} value="인증 확인" onClick={(e) => {
                                                    if (code === requestCode.toString()) {
                                                        alert("인증 되었습니다.")
                                                        setCodeConfirm(true)
                                                    } else {
                                                        alert("인증번호가 맞지 않습니다.")
                                                    }
                                                }} />
                                            </div>
                                            <div className="flex gap-1">
                                                <div className="text-red-400">* 오모플레이 채널을 차단한 경우 수신이 불가합니다.</div>
                                            </div>
                                            <div className="flex gap-1">
                                                <div>알림톡을 받지 못하셨나요?</div>
                                                <div className="underline pointer" onClick={requestCodeFun}>알림톡 다시 받기</div>
                                            </div>
                                        </div>
                                        <Button variants="primary" size="large" disabled={disabled} onClick={() => {
                                            setDisabled(true)
                                            setLoading(true)
                                            joinUserInfo(name, userId, passwordConfirm, phoneNumber).then(() => {
                                                setLoading(false)
                                                setRegistration(false)
                                            })
                                        }} value="회원 가입" style={{marginTop: "16px"}} />
                                    </div>
                                    <div className="flex gap-1 mt-[47px]">
                                        <div>이미 회원이신가요?</div>
                                        <div className="underline pointer" onClick={() => window.location.href = "/login"}>로그인 페이지로 이동</div>
                                    </div>
                                </>
                                )
                                : (
                                    <div className="flex-col items-center">
                                        <div className="flex justify-center" style={{marginBottom: "20px"}}>
                                            <img alt="" src={OMOPlayLogo} width="272px" />
                                        </div>
                                        <div style={{marginBottom: "43px"}}>NO.1 온오프라인 브랜드사 고객 마케팅 서비스</div>
                                        <div className="text-[24px] font-medium">회원가입 완료</div>
                                        <div><img src={IconCheck} alt="Check" width={100} height={100} /></div>
                                        <div className="font-medium">가입한 계정으로 로그인 하세요.</div>
                                        <div className="font-medium">서비스 신청에 필요한 과정을 안내해 드리겠습니다.</div>
                                        <Button variants="primary" size="large" onClick={() => window.location.href = "/login"}
                                                value="로그인 페이지로 돌아가기" style={{marginTop: "16px", width: "320px"}} />
                                    </div>
                                )
                        }
                        </div>
                </div>
            </div>
            <div className="flex-1"></div>
        </div>
    );
};
export default JoinPage;
