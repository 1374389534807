import SettingsLayout from "./layout/SettingsLayout";
import { useSearchParams } from "react-router-dom";
import {useEffect, useState} from "react";
import {
  getVolatilityMessage,
  saveNotification, validContent,
} from "../api/message/volatility-message.api";
import SettingsStepLayout from "./layout/SettingsStepLayout";
import MessageDefaultInfo from "./volatility-message/MessageDefaultInfo";
import MessageContentsSettings from "./volatility-message/content/setting/MessageContentsSettings";
import MessageTargetSetting from "./volatility-message/MessageTargetSetting";
import MessageConfirm from "./volatility-message/content/MessageConfirm";
import moment from "moment";
import SuspensePopup from "./SuspensePopup";
import {getMessagePrice} from "../libs/helper";
import CheckCreditModalForm from "./form/CheckCreditModalForm";

const VolatilityMessageSettings = () => {
  const [query] = useSearchParams();
  const tabs = ["기본 설정", "콘텐츠 작성하기", "대상 및 발송일정", "최종 확인"]
  const [tab, setTab] = useState(1);
  const [message, setMessage] = useState({
    campaignName: "1회성 메시지 캠페인",
    type: "FI", messageData: { type: "FI" },
    issuedAt: {
      date: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
      },
      hour: String(moment().hours()).padStart(2, "0"),
      min: String(moment().minutes()).padStart(2, "0")
    }
  })
  const [isConfirm, setIsConfirm] = useState(false)
  const [disabledTempBtn, setDisabledTempBtn] = useState(false)
  const [customerCnt, setCustomerCnt] = useState(0)
  const [countAllFriend, setCountAllFriend] = useState(0)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
  const [deductMoney, setDeductMoney] = useState(0)
  const [showMoneyModal, setShowMoneyModal] = useState(false)
  const [validCredit, setValidCredit] = useState(true)

  const id = query.get("id");

  useEffect(() => {
    if (id) {
      getVolatilityMessage(id).then(({data}) => {
        const reserved = moment(data.issuedAt, "YYYY-MM-DD HH:mm")
        data.issuedAt = {
          date: {
            startDate: reserved.format("YYYY-MM-DD"),
            endDate: reserved.format("YYYY-MM-DD")
          },
          hour: String(reserved.hours()).padStart(2, "0"),
          min: String(reserved.minutes()).padStart(2, "0")
        }
        setMessage(data)
      })
    }
  }, [id]);

  useEffect(() => {
    setDeductMoney(
        getMessagePrice(message.messageType) * customerCnt
        + (countAllFriend > 0 && 22 * countAllFriend)
    )
  }, [customerCnt]);

  const handleConfirm = (status) => {
    setIsConfirm(false)
    setDisabledTempBtn(false)
    saveNotification(status, message).then(() => {
      alert(
          status === "RESERVED" ? "발송이 등록되었습니다." : "임시 저장되었습니다."
      );
      window.location.replace("/volatility-message");
    })
  };

  const updateMessage = (key, value) => {
    const newMessage = { ...message }
    newMessage[key] = value
    setMessage(newMessage)
  }

  const updateMessageData = (key, value) => {
    const newMessage = { ...message }
    const messageData = newMessage.messageData ?? {}
    messageData[key] = value
    newMessage.messageData = messageData
    setMessage(newMessage)
  }

  const updateMessageDataArrays = (arrayKeys, value) => {
    const newMessage = { ...message }
    const messageData = newMessage.messageData ?? {}

    let currentLevel = messageData;
    arrayKeys.forEach((arrayKey, idx) => {
      const isIndex = !isNaN(Number(arrayKeys[idx + 1]))
      currentLevel[arrayKey] = currentLevel[arrayKey] ?? (isIndex ? [] : {})

      if (idx < arrayKeys.length - 1) {
        currentLevel = currentLevel[arrayKey]
      } else {
        currentLevel[arrayKey] = value
      }
    })
    newMessage.messageData = messageData
    setMessage(newMessage)
  }

  const deleteMessageDataArrays = (index, arrayKeys) => {
    const newMessage = { ...message }
    const messageData = newMessage.messageData ?? {}
    messageData[arrayKeys] = messageData[arrayKeys] ?? []
    messageData[arrayKeys] = messageData[arrayKeys].filter((_, i) => i !== index)
    newMessage.messageData = messageData
    setMessage(newMessage)
  }

  const description = () => {
    if (tab === 0) {
      return "메시지의 기본 정보를 설정해 주세요."
    } else {
      return ""
    }
  }


  useEffect(() => {
    if (tab === 2) {
      setNextButtonDisabled(!validContent(message.messageData))
    } else {
      setNextButtonDisabled(false)
    }
  }, [tab, message]);

  const render = () => {
    const messageData = message.messageData ?? {}
    const type = messageData.type
    const filters = message.filters ?? []
    const issuedAt = message.issuedAt

    if (tab === 1) {
      return (
          <MessageDefaultInfo campaignName={message?.campaignName}
                              type={type}
                              onChangeCampaignName={(campaignName) => {
                                updateMessage("campaignName", campaignName)
                              }}
                              onChangeMessageType={(type) => {
                                updateMessageData("type", type)
                              }}
          />
      )
    } else if (tab === 2) {
      return (
          <MessageContentsSettings type={type}
                                   messageData={messageData}
                                   onChangeMessageData={(key, value) => updateMessageData(key, value)}
                                   onChangeImage={(key, imgUrl) => {
                                     const newMessage = { ...message }
                                     const messageData = newMessage.messageData ?? {}
                                     messageData["type"] = imgUrl === undefined ? "FT" : "FI"
                                     if (!messageData.image) {
                                       messageData["image"] = {}
                                     }
                                     messageData["image"][key] = imgUrl
                                     newMessage.messageData = messageData
                                     setMessage(newMessage)
                                   }}
                                   onChangeMessageDataArrays={(arrayKeys, value) => updateMessageDataArrays(arrayKeys, value)}
                                   onDeleteMessageDataArrays={(index, arrayKeys) => deleteMessageDataArrays(index, arrayKeys)}
          />
      )
    } else if (tab === 3) {
      return (
          <MessageTargetSetting
              id={id}
              targetFilters={filters}
              reservedDate={issuedAt}
              isSendMoment={message.isSendMoment}
              targetType={message.targetType}
              onChangeTargetType={(type) => {
                updateMessage("targetType", type)
              }}
              onChangeTargetFilters={(filters) => {
                updateMessage("filters", filters)
              }}
              onChangeCustomerCnt={(count) => {
                setCustomerCnt(count)
              }}
              type={type}
              messageData={messageData}
              onChangeReservedDate={(issuedAt) => {
                updateMessage("issuedAt", issuedAt)
              }}
              onChangeAdult={(flag) => {
                updateMessageData("adult", flag)
              }}
              selectUserIds={message.selectUserIds}
              onChangeSelectUserIds={(userIds) => {
                updateMessage("selectUserIds", userIds)
              }}
              countAllFriend={countAllFriend}
              onChangeSendFriend={(count) => {
                setCountAllFriend(count)
                updateMessage("sendAllFriend", count > 10)
              }}
          />
      )
    } else if (tab === 4) {
      return (
          <MessageConfirm
              message={message}
              deductMoney={deductMoney}
              customerCount={customerCnt + countAllFriend}
              isSendMoment={countAllFriend === 0}
              onValidState={setIsConfirm}
          />
      )
    }
  };

  return (
    <SettingsLayout
      title={"메시지 보내기"}
      tabs={tabs}
      activeTab={tab}
      isTemporarySave={!disabledTempBtn}
      onTemporarySave={() => handleConfirm("TEMPORARY")}
      onConfirm={() => setShowMoneyModal(true)}
      onChange={setTab}
      isConfirm={isConfirm}
      nextButtonDisabled={nextButtonDisabled}
      confirmText={"등록하기"}
      onClose={() => window.location.href = ("/volatility-message")}
    >
      <SettingsStepLayout description={description()} renderChild={render()} />
      <SuspensePopup visible={showMoneyModal} title="예치금 차감 안내" onConfirm={() => {
        if (validCredit) {
          handleConfirm("RESERVED")
        }
      }} onClose={() => setShowMoneyModal(false)}>
        <CheckCreditModalForm deductMoney={deductMoney} onValid={setValidCredit} />
      </SuspensePopup>
    </SettingsLayout>
  );
};
export default VolatilityMessageSettings;
