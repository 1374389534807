import {Button, Toggle, MessageItem} from "omoplay";
import {changeVariantsStatus, getVariantsAIMessage, saveVariantsAIMessage} from "../../api/ai/ai.api";
import {useEffect, useState} from "react";

const MessageSection = ({ id, title, variantsKey, showToggle = true, hideImage, limitWidth, onChangeVariants }) => {
    const [variantData, setVariantData] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getVariantsAIMessage(id, variantsKey).then(({data}) => {
            setVariantData(data)
        })
    }, [id, variantsKey])

    function onChangedStatus(flag) {
        changeVariantsStatus(id, variantsKey, flag)
    }

    return (
        <div className="p-4" style={{ backgroundColor: "rgba(247, 247, 249, 1)" }}>
            <div className="flex-align-center justify-between p-4">
                <div className="flex-align-center gap-4">
                    {
                        showToggle && (<Toggle value={variantData?.status} onToggle={onChangedStatus} />)
                    }
                    <div>{title}</div>
                </div>
                <div>
                    {
                        variantsKey !== "default" && (
                            loading ? <Button disabled={true} variants="primary" value="AI 생성중입니다." />
                                : <Button variants="primary" value="AI 재생성" onClick={() => {
                                    setLoading(true)
                                    saveVariantsAIMessage(id, variantsKey).then(({data}) => {
                                        setLoading(false)
                                        onChangeVariants(data)
                                        setVariantData(data)
                                    })
                                }} />
                        )
                    }
                </div>
            </div>
            <div className="border-b"></div>
            <div className="p-4">
                <MessageItem messageData={variantData?.messageData} type={variantData?.messageData?.type} hideImage={hideImage} limitWidth={limitWidth} />
            </div>
        </div>
    );
};

export default MessageSection