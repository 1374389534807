import {Button, TextInput} from "omoplay";
import CardBillingForm from "./CardBillingForm";
import {useEffect, useState} from "react";

const CreditChargeForm = ({creditType, onSelectedCard, onChargeAmount}) => {
    const [chargeAmount, setChargeAmount] = useState(1000)

    useEffect(() => {
        onChargeAmount(chargeAmount)
    }, [chargeAmount]);

    return (
        <>
            <div className="flex-col gap-2">
                <div className="flex-col gap-1">
                    <div className="flex gap-2">
                        <div className="font-medium text-[16px]">결제 금액</div>
                        {
                            chargeAmount < 1000 && (
                                <label className="text-red-500 font-medium text-[16px]">
                                    * 결제는 1000원 이상 가능합니다.
                                </label>
                            )
                        }
                    </div>
                    <div className="flex-align-center gap-2">
                        <TextInput type="numeric" value={chargeAmount} onChange={(e) => {
                            const num = Number(e.target.value)
                            if (!isNaN(num)) {
                                setChargeAmount(num)
                            }
                        }
                        } size={7} />
                        <div>원 (KRW)</div>
                    </div>
                </div>
                <div className="flex gap-2">
                    <Button variants="default" size="normal" value="+ 1,000원" onClick={() => setChargeAmount(prev => prev + 1000)} />
                    <Button variants="default" size="normal" value="+ 5,000원" onClick={() => setChargeAmount(prev => prev + 5000)} />
                    <Button variants="default" size="normal" value="+ 10,000원" onClick={() => setChargeAmount(prev => prev + 10000)} />
                </div>
            </div>
            <div className="border" style={{margin: "36px 0"}}></div>
            <CardBillingForm onSelectedCard={onSelectedCard} />
            <div className="w-full flex-col items-end">
                {
                    creditType === "AI" && (
                        <label className="font-medium text-[16px]">
                            충전 Credit: {(chargeAmount / 10).toLocaleString()} Credit
                        </label>
                    )
                }
                <label className="font-medium text-[18px] text-primary">
                    총 결제 금액: {(chargeAmount * (creditType === "AI" ? 1.1 : 1)).toLocaleString()}원 (KRW)
                </label>
            </div>
        </>
    )
}

export default CreditChargeForm