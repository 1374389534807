import Layout from "../components/layout/Layout";
import UserStatistics from "../components/page/UserStatistics";

const HomePage = () => {
  return (
    <Layout>
      <UserStatistics />
    </Layout>
  );
};
export default HomePage;
