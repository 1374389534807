import {Button} from "omoplay";

const EditController = ({
  tabs,
  activeTab,
  onChange,
  isTemporarySave,
  onTemporarySave,
  onConfirm,
  isConfirm,
  confirmText,
  onClose,
  nextButtonDisabled,
}) => {
  const findIndex = tabs.findIndex((tab) => tab.value === activeTab)

  return (
    <nav className="fixed bottom-0 w-full bg-white border-b border-b-gray-300 divide-y divide-gray-300">
      <div className="settings-box flex justify-center mx-auto px-3 py-3 lgp:x-5 lg:pl-3">
        <div className="flex justify-between" style={{width: "100%"}}>
          <Button variants="dark" value="나가기" onClick={onClose} />
          <div className="flex gap-4">
            {onTemporarySave && (
                <Button
                    disabled={!isTemporarySave}
                    variants="default"
                    onClick={() => onTemporarySave()}
                    value="임시저장"
                />
            )}
            {findIndex > 0 && (
                <Button
                    variants="default"
                    onClick={() => onChange(tabs[findIndex - 1].value)}
                    value="이전"
                />
            )}
            {findIndex < tabs.length - 1 ? (
                <Button
                    variants="solid"
                    onClick={() => onChange(tabs[findIndex + 1].value)}
                    value="다음"
                    disabled={nextButtonDisabled ?? false}
                />
            ) : (
                <Button
                    disabled={!isConfirm}
                    variants="solid"
                    onClick={() => onConfirm()}
                    value={confirmText}
                />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default EditController;
