import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";
import { Cookies } from "react-cookie";

export const getPartner = async () => {
  const res = await fetchApi.get(API_URL.PARTNER)

  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};

export const logout = () => {
  const cookies = new Cookies();
  cookies.remove("token");
  window.location.href = "/login";
};

export const putPartner = async (hostingName, hostingId, hostingPwd) => {
  const res = await fetchApi.put(API_URL.PARTNER, {
    hostingName: hostingName,
    hostingId: hostingId,
    hostingPwd: hostingPwd
  })

  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};

export const partnerAuth = async (plusFriendName, phoneNumber) => {
  try {
    const res = await fetchApi.post(API_URL.PARTNER_AUTH, {
      plusFriendName: plusFriendName,
      phoneNumber: phoneNumber
    });
    if (res.status === 200) {
      return res.data;
    } else {
      return {};
    }
  } catch (e) {
    return e.response;
  }
};

export const partnerRegistration = async (plusFriendName, phoneNumber, code) => {
  try {
    const res = await fetchApi.post(API_URL.PARTNER_REGISTRATION, {
      plusFriendName: plusFriendName,
      phoneNumber: phoneNumber,
      code: code
    });
    if (res.status === 200) {
      return res.data;
    } else {
      return {};
    }
  } catch (e) {
    return e.response;
  }
};