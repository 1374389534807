import SettingsLayout from "../layout/SettingsLayout";
import { useSearchParams } from "react-router-dom";
import {useEffect, useState} from "react";
import {saveNotification} from "../../api/message/volatility-message.api";
import SettingsStepLayout from "../layout/SettingsStepLayout";
import moment from "moment";
import {getAIMessage} from "../../api/ai/ai.api";
import AIMessageDefaultInfo from "../form/ai/message/AIMessageDefaultInfo";
import AIMessageEdit from "./AIMessageEdit";
import MessageContentsSettings from "../volatility-message/content/setting/MessageContentsSettings";
import AIAgentMessageTargetSetting from "../form/AIAgentMessageTargetSetting";
import AIAgentMessageConfirm from "../form/AIAgentMessageConfirm";

const AIAgentMessageSettings = () => {
    const [query] = useSearchParams();
    const tabs = ["기본 설정", "AI 생성", "콘텐츠 작성", "발송 대상", "최종 확인"]
    const [tab, setTab] = useState(1);
    const [message, setMessage] = useState({})
    const [isConfirm, setIsConfirm] = useState(false)
    const [disabledTempBtn, setDisabledTempBtn] = useState(false)
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
    const [countAllFriend, setCountAllFriend] = useState(0)

  const id = query.get("id");

  useEffect(() => {
    if (id && tab === 1) {
      getAIMessage(id).then(({data}) => {
        setNextButtonDisabled(!data.campaignName || data.messageData.type === "")
        const reserved = moment(data.issuedAt, "YYYY-MM-DD HH:mm")
        data.issuedAt = {
          date: {
            startDate: reserved.format("YYYY-MM-DD"),
            endDate: reserved.format("YYYY-MM-DD")
          },
          hour: String(reserved.hours()).padStart(2, "0"),
          min: String(reserved.minutes()).padStart(2, "0")
        }
        data.isAI = true
        setMessage(data)
      })
    } else if (tab === 2) {
        setNextButtonDisabled(message.emptyMessage)
    }
  }, [id, tab]);


    const handleConfirm = async (status) => {
        setIsConfirm(false)
        setDisabledTempBtn(false)
        message.isSendMoment = true
        saveNotification(status, message).then(() => {
            alert(status === "RESERVED" ? "발송이 등록되었습니다." : "임시 저장되었습니다.");
            window.location.replace("/ai/volatility-message");
        })
    };

  const updateMessage = (key, value) => {
    const newMessage = { ...message }
    newMessage[key] = value
    setMessage(newMessage)
  }

  const updateMessageData = (key, value) => {
    const newMessage = { ...message }
    const messageData = newMessage.messageData ?? {}
    messageData[key] = value
    newMessage.messageData = messageData
    setMessage(newMessage)
  }

  const updateMessageDataArrays = (arrayKeys, value) => {
    const newMessage = { ...message }
    const messageData = newMessage.messageData ?? {}

    let currentLevel = messageData;
    arrayKeys.forEach((arrayKey, idx) => {
      const isIndex = !isNaN(Number(arrayKeys[idx + 1]))
      currentLevel[arrayKey] = currentLevel[arrayKey] ?? (isIndex ? [] : {})

      if (idx < arrayKeys.length - 1) {
        currentLevel = currentLevel[arrayKey]
      } else {
        currentLevel[arrayKey] = value
      }
    })
    newMessage.messageData = messageData
    setMessage(newMessage)
  }

  const deleteMessageDataArrays = (index, arrayKeys) => {
    const newMessage = { ...message }
    const messageData = newMessage.messageData ?? {}
    messageData[arrayKeys] = messageData[arrayKeys] ?? []
    messageData[arrayKeys] = messageData[arrayKeys].filter((_, i) => i !== index)
    newMessage.messageData = messageData
    setMessage(newMessage)
  }

  const description = () => {
    return ""
  }

  useEffect(() => {
    if ((message.campaignName != null && message.campaignName !== "") && message.messageType != null &&
        message.messageData.type !== "") {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true)
    }
  }, [message]);

  const render = () => {
    const messageData = message.messageData ?? {}
    const type = messageData.type
    const filters = message.filters ?? []
    const issuedAt = message.issuedAt

    if (tab === 1) {
      return (
          <div className="setting-step-padding">
            <AIMessageDefaultInfo campaignName={message.campaignName}
                                  type={type}
                                  isNotEdit={message.messageType !== "TEXT"}
                                  onChangeCampaignName={(campaignName) => {
                                    updateMessage("campaignName", campaignName)
                                  }}
                                  onChangeMessageType={(type) => {
                                    updateMessageData("type", type)
                                  }}
            />
          </div>
      )
    } else if (tab === 2) {
      return (
          <AIMessageEdit message={message} onChangeMessageData={(data) => {
            const newMessage = {...message}
            newMessage.messageData = data
            setMessage(newMessage)
            setNextButtonDisabled(false)
          }}
          />
      )
    } else if (tab === 3) {
      return (
          <div style={{display: "flex", gap: "60px", marginTop: "20px"}}>
            <div className="setting-step-padding" style={{flex: "1.5"}}>
              <MessageContentsSettings type={type}
                                       messageData={messageData}
                                       onChangeMessageData={(key, value) => updateMessageData(key, value)}
                                       onChangeImage={(key, imgUrl) => {
                                         const newMessage = {...message}
                                         const messageData = newMessage.messageData ?? {}
                                         messageData["type"] = imgUrl === undefined ? "FT" : "FI"
                                         if (!messageData.image) {
                                           messageData["image"] = {}
                                         }
                                         messageData["image"][key] = imgUrl
                                         newMessage.messageData = messageData
                                         setMessage(newMessage)
                                       }}
                                       onChangeMessageDataArrays={(arrayKeys, value) => updateMessageDataArrays(arrayKeys, value)}
                                       onDeleteMessageDataArrays={(index, arrayKeys) => deleteMessageDataArrays(index, arrayKeys)}
              />
            </div>
          </div>
      )
    } else if (tab === 4) {
      return (
          <AIAgentMessageTargetSetting
              id={id}
              reservedDate={issuedAt}
              type={type}
              messageData={messageData}
              onChangeReservedDate={(issuedAt) => {
                updateMessage("issuedAt", issuedAt)
              }}
              onChangeAdult={(flag) => {
                updateMessageData("adult", flag)
              }}
              onChangeCountFriend={(count) => {
                  setCountAllFriend(count)
              }}
              selectUserIds={message.selectUserIds}
          />
      )
    } else if (tab === 5) {
      return (
          <AIAgentMessageConfirm
              message={message}
              customerCount={countAllFriend}
              onValidState={setIsConfirm}
          />
      )
    }
  };

  return (
    <SettingsLayout
      title={"메시지 보내기"}
      tabs={tabs}
      activeTab={tab}
      isTemporarySave={!disabledTempBtn}
      onTemporarySave={() => handleConfirm("TEMPORARY")}
      onConfirm={() => handleConfirm("RESERVED")}
      onChange={setTab}
      nextButtonDisabled={nextButtonDisabled}
      isConfirm={isConfirm}
      confirmText={"등록하기"}
      onClose={() => window.location.href = ("/ai/volatility-message")}
    >
      <SettingsStepLayout description={description()} renderChild={render()} />
    </SettingsLayout>
  );
};
export default AIAgentMessageSettings;
