// Setting 은 헤드리스에서 진행함.
// export const TAB_MENU = [
//     {
//         key: "setting",
//         label: "대상 선택",
//     },
//     {
//         key: "content",
//         label: "콘텐츠 작성",
//     },
//     {
//         key: "confirm",
//         label: "최종 확인",
//     },
// ];

export const TAB_MENU = [
  {
    key: "scenario",
    label: "시나리오 선택",
  },
  {
    key: "content",
    label: "콘텐츠 작성",
  },
];

export const GROUP_TYPE = [
  {
    label: "전체 고객",
    value: "all",
    description: "등록된 고객 전체를 대상으로 할 때 선택하세요",
  },
  {
    label: "상품으로 조회",
    value: "product",
    description: "특정 상품에 반응했던 고객을 선택하세요 (조회, 구매)",
  },
  {
    label: "멤버십으로 조회",
    value: "membership",
    description: "고객 등급 별로 고객을 선택하세요",
  },
];
export const GENDER = [
  {
    value: "female",
    label: "여자",
  },
  {
    value: "male",
    label: "남자",
  },
];

export const AGE_RANGE = [
  {
    value: 10,
    label: "10대 이하",
  },
  {
    value: 20,
    label: "20대",
  },
  {
    value: 30,
    label: "30대",
  },
  {
    value: 40,
    label: "40대",
  },
  {
    value: 50,
    label: "50대",
  },
  {
    value: 60,
    label: "60대 이상",
  },
];

export const ORDER_COUNT = [
  {
    value: 0,
    label: "비구매자",
  },
  {
    value: 1,
    label: "1회 구매자",
  },
  {
    value: "over_2",
    label: "2회 이상",
  },
  {
    value: "over_3",
    label: "3회 이상",
  },
];

export const ORDER_DATE = [
  {
    value: "3d",
    label: "3일 이내",
  },
  {
    value: "1w",
    label: "1주일 이내",
  },
  {
    value: "2w",
    label: "2주일 이내",
  },
  {
    value: "3w",
    label: "3주일 이내",
  },
  {
    value: "1m",
    label: "한 달 이내",
  },
  {
    value: "3m",
    label: "3개월 이내",
  },
  {
    value: "6m",
    label: "6개월 이내",
  },
  {
    value: "1y",
    label: "1년 이내",
  },
];

export const ORDER_PRICE = [
  {
    value: "custom",
    customValue: "",
    label: "구매금액",
    placeholder: "구매금액을 입력해 주세요",
  },
];
export const QUICK_MENU = [
  {
    key: "gender",
    label: "성별",
    values: GENDER,
  },
  {
    key: "ageRange",
    label: "연령대",
    values: AGE_RANGE,
  },
  {
    key: "orderCount",
    label: "구매횟수",
    values: ORDER_COUNT,
  },
  {
    key: "orderDate",
    label: "구매일",
    values: ORDER_DATE,
  },
  {
    key: "orderPrice",
    label: "구매금액",
    values: ORDER_PRICE,
  },
];

export const MESSAGE_TYPES = [
  {
    key: "FI",
    imageClass: "ft-ordinary-image",
    title: "기본 텍스트형",
    description: "이미지, 동영상, 버튼 첨부가 가능한 기본형 메시지입니다.",
  },
  {
    key: "FW",
    imageClass: "ft-wide-image-image",
    title: "와이드 이미지형",
    description: "이미지 또는 동영상을 강조할 수 있습니다.",
  },
  {
    key: "FL",
    imageClass: "ft-wide-list-image",
    title: "와이드 리스트형",
    description: "다수의 소식을 리스트 형태로 구성할 수 있습니다.",
  },
  {
    key: "FC",
    imageClass: "ft-carousel-image",
    title: "캐러셀 피드형",
    description: "다수의 소식을 피드 형태로 보여줄 수 있습니다.",
  },
];
export const EVENT_TYPES = [
  {
    key: "roulette",
    imageClass: "roulette",
    title: "룰렛 이벤트",
    description: "룰렛을 돌리는 재미 요소를 통해 오프라인 이벤트에 적합합니다.",
  },
  {
    key: "skip",
    imageClass: "skip",
    title: "바로 당첨 이벤트",
    description: "고객에게 혜택까지의 도달 단계가 짧아 온라인 이벤트에 적합합니다.",
  },
  {
    key: "preparing",
    imageClass: "preparing",
    title: "사다리 이벤트",
    description: "-",
  },
  {
    key: "preparing",
    imageClass: "preparing",
    title: "복권 이벤트",
    description: "-",
  },
];

export const SCENARIO_LIST = [
  {
    id: 1,
    name: "과거 [골프 라인 상품]을 [2회 이상] 구매한 고객에게 [신규 골프 라인 상품]을 홍보한다",
    keywords: ["f"],
  },
  {
    id: 2,
    name: "과거 [역시즌 상품]을 [1회 이상] 구매한 고객에게 올해 [역시즌 할인 상품]을 홍보한다",
    keywords: ["f"],
  },
  {
    id: 3,
    name: "과거 [롱치마 상품]을 [2회 이상] 구매한 고객에게 [재고 롱치마 제품]들을 모아 홍보한다",
    keywords: ["f"],
  },
  {
    id: 4,
    name: "남성 여성라인 따로 출시된 신상품을 알리기 위해 [남성/여성]으로 분류하여 홍보한다",
    keywords: ["a"],
  },
  {
    id: 5,
    name: "[강남 지역] 로그인 한 사람에게 [강남 지점] 제품을 홍보한다",
    keywords: ["c"],
  },
  {
    id: 6,
    name: "[지점 방문자]에게 [00이벤트 행사]를 홍보한다",
    keywords: ["c"],
  },
  {
    id: 7,
    name: "[가입 지점]이 강남인 고객에게 [강남 행사]를 홍보한다",
    keywords: ["c"],
  },
  {
    id: 8,
    name: "신상품 홍보 타겟으로, [30-40대] 고객 중 [여성]으로 분류하여 홍보한다",
    keywords: ["a", "d"],
  },
  {
    id: 9,
    name: "1년 이내, [누적 결제액 상위 10%] 이내에 드는 고객에게 감사 30% 할인 쿠폰을 발급한다",
    keywords: ["a", "d"],
  },
  {
    id: 10,
    name: "1년 이내, [누적 결제액 상위 10%] 이내에 드는 고객에게 무료 [행사 참가 쿠폰]을 발급한다",
    keywords: ["h"],
  },
  {
    id: 11,
    name: "1년 이내, [누적 결제액 상위 50%] 이내에 드는 고객에게 구매 유도 1,000원 [시크릿 쿠폰]을 발급한다 ",
    keywords: ["h"],
  },
  {
    id: 12,
    name: "이번 달 [생일] 인 사람에게 생일 턱, 한 번 더 [할인 쿠폰] 발급한다",
    keywords: ["b"],
  },
  {
    id: 13,
    name: "[1달 이내]에 [구매]한 고객에게 [신상품]을 홍보한다 ",
    keywords: ["f"],
  },
  {
    id: 14,
    name: "[1주일 이내] [방문]한 고객에게 [할인상품]을 홍보한다",
    keywords: ["g"],
  },
  {
    id: 15,
    name: "[장바구니]에 물건을 담아둔 고객에게 [해당 제품의 할인 쿠폰]을 발급한다 ",
    keywords: ["m"],
  },
  {
    id: 16,
    name: "[장바구니]에 물건을 담아둔 고객에게 [장바구니 제품을 다시 알려] 구매 유도한다",
    keywords: ["m"],
  },
  {
    id: 17,
    name: "[신규 가입 7일 이내]의 고객에게 [첫 구매 10,000원 쿠폰]을 발급한다 ",
    keywords: ["l"],
  },
  {
    id: 18,
    name: "[비구매자] 고객에게 [첫구매 유도 쿠폰]을 발급한다",
    keywords: ["e"],
  },
  {
    id: 19,
    name: "[신상/이벤트 상품을 조회]한 고객에게 다시 알려 구매 유도한다",
    keywords: ["i"],
  },
  {
    id: 20,
    name: "[청바지 제품]을 [2회 이상 조회]한 고객에게 [청바지 제품]들을 모아 홍보한다",
    keywords: ["i"],
  },
  {
    id: 21,
    name: "[00 제품]을 검색한 고객에게 [00 제품]의 다시 알려 구매를 유도한다 ",
    keywords: ["n"],
  },
  {
    id: 22,
    name: "[결제 단계]에서 이탈한 고객에게 [결제 예정 상품]을 다시 알려 구매를 유도한다",
    keywords: ["o"],
  },
  {
    id: 23,
    name: "[연관 상품]을 [조회한 고객]에게 [연관 상품]을 다시 알려 구매를 유도한다",
    keywords: ["i", "k"],
  },
  {
    id: 24,
    name: "주문을 하였지만 배송전 [취소]시 재구매 유도를 위해 [10% 할인 쿠폰]을 발급한다",
    keywords: ["o"],
  },
  {
    id: 25,
    name: "클리어런스 세일을 진행하면서 해당 상품이 [장바구니]에 담아둔 고객에게 홍보한다",
    keywords: ["m"],
  },
  {
    id: 26,
    name: "고객의 [장바구니]에 담아둔 상품의 재고가[2개 이하]일 경우 고객에게 홍보한다",
    keywords: ["m"],
  },
  {
    id: 27,
    name: "고객의 [장바구니]에 담아둔 상품의 재고가[10개 이하]일 경우 고객에게 타임세일을 홍보한다",
    keywords: ["m"],
  },
  {
    id: 26,
    name: "최근 [1주일 이내] 상품을 구매한 고객에게 해당상품과 연관 되어있는 자사몰 상품을 홍보한다",
    keywords: ["f", "k"],
  },
  {
    id: 29,
    name: "신규 상품 출시 시 연관 되어있는 상품을 구매한 고객에게 상품을 [10% 할인 쿠폰]발급과 함께 홍보한다",
    keywords: ["f", "k"],
  },
  {
    id: 30,
    name: "1년 이내, [누적 결제액 상위 20%] 고객에게 감사의 뜻으로 [배송비 쿠폰]을 발급한다",
    keywords: ["h"],
  },
];

export const SCENARIO_CONDITION_ITEM = {
  a: {
    label: "성별",
    details: ["남자", "여자"],
  },
  b: {
    label: "생일",
    details: ["생일"],
  },
  c: {
    label: "지역",
    details: ["가입 지역 설정", "로그인 지역 설정"],
  },
  c_1: {
    label: "오프라인 지점",
    details: ["방문 지점", "가입 지점", "구매"],
  },
  d: {
    label: "연령대",
    details: ["10대 이하", "20대", "30대", "40대", "50대", "60대", "70대 이상"],
  },
  e: {
    label: "구매 횟수",
    details: ["비구매자", "1회 구매자", "2회 이상"],
  },
  f: {
    label: "구매 이력",
    details: [
      "1주일 이내",
      "1개월 이내",
      "3개월 이내",
      "6개월 이내",
      "12개월 이내",
    ],
  },
  g: {
    label: "방문 이력",
    details: [
      "1주일 이내",
      "1개월 이내",
      "3개월 이내",
      "6개월 이내",
      "12개월 이내",
      "1회",
      "2회 이상~",
    ],
  },
  h: {
    label: "누적 결제액",
    details: ["1년 내", "상위 5%", "상위 20%", "상위 50%"],
  },
  i: {
    label: "상품 조회",
    details: ["특정 상품 조회"],
  },
  j: {
    label: "상품 구매",
    details: ["특정 상품 구매"],
  },
  k: {
    label: "연관 상품",
    details: ["연관 상품 조회", "연관 상품 구매"],
  },
  l: {
    label: "회원가입",
    details: ["신규 회원(7일이내)", "30일 이내", "30일 이후"],
  },
  m: {
    label: "장바구니",
    details: ["장바구니 담아둔 상품"],
  },
  n: {
    label: "검색",
    details: ["검색한 상품"],
  },
  o: {
    label: "결제",
    details: ["결제 단계 이탈"],
  },
};

export const SCENARIO_CONDITIONS = {
  customer_info: {
    label: "고객 정보",
    values: ["a", "b", "c", "c_1", "d"],
  },
  customer_behavior: {
    label: "고객 행동",
    values: ["e", "f", "g", "h"],
  },
  product: {
    label: "상품 관련",
    values: ["i", "j", "k"],
  },
  homepage: {
    label: "홈페이지",
    values: ["l", "m", "n", "o"],
  },
};

export const MESSAGE_TARGET_FILTER = [
  {
    value: "gender",
    label: "성별",
    data: [
      { value: "male", label: "남성" },
      { value: "female", label: "여성" },
    ],
  },
    /*
  {
    value: "birthday",
    label: "생일",
    data: [
      { value: "month_01", label: "1월" },
      { value: "month_02", label: "2월" },
      { value: "month_03", label: "3월" },
      { value: "month_04", label: "4월" },
      { value: "month_05", label: "5월" },
      { value: "month_06", label: "6월" },
      { value: "month_07", label: "7월" },
      { value: "month_08", label: "8월" },
      { value: "month_09", label: "9월" },
      { value: "month_10", label: "10월" },
      { value: "month_11", label: "11월" },
      { value: "month_12", label: "12월" },
    ],
  },
     */
  {
    value: "age",
    label: "연령대",
    data: [
      { value: "15-19", label: "15~19세" },
      { value: "20-29", label: "20~29세" },
      { value: "30-39", label: "30~39세" },
      { value: "40-49", label: "40~49세" },
      { value: "50-59", label: "50~59세" },
      { value: "60-69", label: "60~69세" },
      { value: "70-79", label: "70~79세" },
      { value: "80-89", label: "80~89세" },
      { value: "90", label: "90세~" },
    ],
  },
  {
    value: "createdWeek",
    label: "회원가입",
    data: [
      { value: "week_1", label: "월요일" },
      { value: "week_2", label: "화요일" },
      { value: "week_3", label: "수요일" },
      { value: "week_4", label: "목요일" },
      { value: "week_5", label: "금요일" },
      { value: "week_6", label: "토요일" },
      { value: "week_0", label: "일요일" },
    ],
  },
  {
    value: "time",
    label: "회원가입 시간대",
    data: [
      { value: "15-18", label: "6시~9시" },
      { value: "18-21", label: "9시~12시" },
      { value: "21-0", label: "12시~15시" },
      { value: "0-3", label: "15시~18시" },
      { value: "3-6", label: "18시~21시" },
      { value: "6-9", label: "21시~24시" },
      { value: "9-15", label: "0시~6시" },
    ],
  },
  { value: "spot", label: "QR", data: [] },

  {
    value: "address",
    label: "배송지 주소",
    data: [
      {
        value: 11,
        label: "서울특별시",
        data: [
          { value: 11, label: "전체" },
          { value: 680, label: "강남구" },
          { value: 740, label: "강동구" },
          { value: 305, label: "강북구" },
          { value: 500, label: "강서구" },
          { value: 620, label: "관악구" },
          { value: 215, label: "광진구" },
          { value: 530, label: "구로구" },
          { value: 545, label: "금천구" },
          { value: 350, label: "노원구" },
          { value: 320, label: "도봉구" },
          { value: 230, label: "동대문구" },
          { value: 590, label: "동작구" },
          { value: 440, label: "마포구" },
          { value: 410, label: "서대문구" },
          { value: 650, label: "서초구" },
          { value: 200, label: "성동구" },
          { value: 290, label: "성북구" },
          { value: 710, label: "송파구" },
          { value: 470, label: "양천구" },
          { value: 560, label: "영등포구" },
          { value: 170, label: "용산구" },
          { value: 380, label: "은평구" },
          { value: 110, label: "종로구" },
          { value: 140, label: "중구" },
          { value: 260, label: "중랑구" },
        ],
      },
      {
        value: 26,
        label: "부산광역시",
        data: [
          { value: 26, label: "전체" },
          { value: 440, label: "강서구" },
          { value: 410, label: "금정구" },
          { value: 710, label: "기장군" },
          { value: 290, label: "남구" },
          { value: 170, label: "동구" },
          { value: 260, label: "동래구" },
          { value: 230, label: "부산진구" },
          { value: 320, label: "북구" },
          { value: 530, label: "사상구" },
          { value: 380, label: "사하구" },
          { value: 140, label: "서구" },
          { value: 500, label: "수영구" },
          { value: 470, label: "연제구" },
          { value: 200, label: "영도구" },
          { value: 110, label: "중구" },
          { value: 350, label: "해운대구" },
        ],
      },
      {
        value: 27,
        label: "대구광역시",
        data: [
          { value: 27, label: "전체" },
          { value: 720, label: "군위군" },
          { value: 200, label: "남구" },
          { value: 290, label: "달서구" },
          { value: 710, label: "달성군" },
          { value: 140, label: "동구" },
          { value: 230, label: "북구" },
          { value: 170, label: "서구" },
          { value: 260, label: "수성구" },
          { value: 110, label: "중구" },
        ],
      },
      {
        value: 28,
        label: "인천광역시",
        data: [
          { value: 28, label: "전체" },
          { value: 710, label: "강화군" },
          { value: 245, label: "계양구" },
          { value: 200, label: "남동구" },
          { value: 140, label: "동구" },
          { value: 177, label: "미추홀구" },
          { value: 237, label: "부평구" },
          { value: 260, label: "서구" },
          { value: 185, label: "연수구" },
          { value: 720, label: "옹진군" },
          { value: 110, label: "중구" },
        ],
      },
      {
        value: 29,
        label: "광주광역시",
        data: [
          { value: 29, label: "전체" },
          { value: 200, label: "광산구" },
          { value: 155, label: "남구" },
          { value: 110, label: "동구" },
          { value: 170, label: "북구" },
          { value: 140, label: "서구" },
        ],
      },
      {
        value: 30,
        label: "대전광역시",
        data: [
          { value: 30, label: "전체" },
          { value: 230, label: "대덕구" },
          { value: 110, label: "동구" },
          { value: 170, label: "서구" },
          { value: 200, label: "유성구" },
          { value: 140, label: "중구" },
        ],
      },
      {
        value: 31,
        label: "울산광역시",
        data: [
          { value: 31, label: "전체" },
          { value: 140, label: "남구" },
          { value: 170, label: "동구" },
          { value: 200, label: "북구" },
          { value: 710, label: "울주군" },
          { value: 110, label: "중구" },
        ],
      },
      { value: 36, label: "세종특별자치시", data: [] },
      {
        value: 41,
        label: "경기도",
        data: [
          { value: 41, label: "전체" },
          { value: 820, label: "가평군" },
          { value: 281, label: "고양시 덕양구" },
          { value: 285, label: "고양시 일산동구" },
          { value: 287, label: "고양시 일산서구" },
          { value: 290, label: "과천시" },
          { value: 210, label: "광명시" },
          { value: 610, label: "광주시" },
          { value: 310, label: "구리시" },
          { value: 410, label: "군포시" },
          { value: 570, label: "김포시" },
          { value: 360, label: "남양주시" },
          { value: 250, label: "동두천시" },
          { value: 194, label: "부천시 소사구" },
          { value: 196, label: "부천시 오정구" },
          { value: 192, label: "부천시 원미구" },
          { value: 135, label: "성남시 분당구" },
          { value: 131, label: "성남시 수정구" },
          { value: 133, label: "성남시 중원구" },
          { value: 113, label: "수원시 권선구" },
          { value: 117, label: "수원시 영통구" },
          { value: 111, label: "수원시 장안구" },
          { value: 115, label: "수원시 팔달구" },
          { value: 390, label: "시흥시" },
          { value: 273, label: "안산시 단원구" },
          { value: 271, label: "안산시 상록구" },
          { value: 550, label: "안성시" },
          { value: 173, label: "안양시 동안구" },
          { value: 171, label: "안양시 만안구" },
          { value: 630, label: "양주시" },
          { value: 830, label: "양평군" },
          { value: 670, label: "여주시" },
          { value: 800, label: "연천군" },
          { value: 370, label: "오산시" },
          { value: 463, label: "용인시 기흥구" },
          { value: 465, label: "용인시 수지구" },
          { value: 461, label: "용인시 처인구" },
          { value: 430, label: "의왕시" },
          { value: 150, label: "의정부시" },
          { value: 500, label: "이천시" },
          { value: 480, label: "파주시" },
          { value: 220, label: "평택시" },
          { value: 650, label: "포천시" },
          { value: 450, label: "하남시" },
          { value: 590, label: "화성시" },
        ],
      },
      {
        value: 51,
        label: "강원특별자치도",
        data: [
          { value: 51, label: "전체" },
          { value: 150, label: "강릉시" },
          { value: 820, label: "고성군" },
          { value: 170, label: "동해시" },
          { value: 230, label: "삼척시" },
          { value: 210, label: "속초시" },
          { value: 800, label: "양구군" },
          { value: 830, label: "양양군" },
          { value: 750, label: "영월군" },
          { value: 130, label: "원주시" },
          { value: 810, label: "인제군" },
          { value: 770, label: "정선군" },
          { value: 780, label: "철원군" },
          { value: 110, label: "춘천시" },
          { value: 190, label: "태백시" },
          { value: 760, label: "평창군" },
          { value: 720, label: "홍천군" },
          { value: 790, label: "화천군" },
          { value: 730, label: "횡성군" },
        ],
      },
      {
        value: 43,
        label: "충청북도",
        data: [
          { value: 43, label: "전체" },
          { value: 760, label: "괴산군" },
          { value: 800, label: "단양군" },
          { value: 720, label: "보은군" },
          { value: 740, label: "영동군" },
          { value: 730, label: "옥천군" },
          { value: 770, label: "음성군" },
          { value: 150, label: "제천시" },
          { value: 745, label: "증평군" },
          { value: 750, label: "진천군" },
          { value: 111, label: "청주시 상당구" },
          { value: 112, label: "청주시 서원구" },
          { value: 114, label: "청주시 청원구" },
          { value: 113, label: "청주시 흥덕구" },
          { value: 130, label: "충주시" },
        ],
      },
      {
        value: 44,
        label: "충청남도",
        data: [
          { value: 44, label: "전체" },
          { value: 250, label: "계룡시" },
          { value: 150, label: "공주시" },
          { value: 710, label: "금산군" },
          { value: 230, label: "논산시" },
          { value: 270, label: "당진시" },
          { value: 180, label: "보령시" },
          { value: 760, label: "부여군" },
          { value: 210, label: "서산시" },
          { value: 770, label: "서천군" },
          { value: 200, label: "아산시" },
          { value: 810, label: "예산군" },
          { value: 131, label: "천안시 동남구" },
          { value: 133, label: "천안시 서북구" },
          { value: 790, label: "청양군" },
          { value: 825, label: "태안군" },
          { value: 800, label: "홍성군" },
        ],
      },
      {
        value: 52,
        label: "전북특별자치도",
        data: [
          { value: 52, label: "전체" },
          { value: 790, label: "고창군" },
          { value: 130, label: "군산시" },
          { value: 210, label: "김제시" },
          { value: 190, label: "남원시" },
          { value: 730, label: "무주군" },
          { value: 800, label: "부안군" },
          { value: 770, label: "순창군" },
          { value: 710, label: "완주군" },
          { value: 140, label: "익산시" },
          { value: 750, label: "임실군" },
          { value: 740, label: "장수군" },
          { value: 113, label: "전주시 덕진구" },
          { value: 111, label: "전주시 완산구" },
          { value: 180, label: "정읍시" },
          { value: 720, label: "진안군" },
        ],
      },
      {
        value: 46,
        label: "전라남도",
        data: [
          { value: 46, label: "전체" },
          { value: 810, label: "강진군" },
          { value: 770, label: "고흥군" },
          { value: 720, label: "곡성군" },
          { value: 230, label: "광양시" },
          { value: 730, label: "구례군" },
          { value: 170, label: "나주시" },
          { value: 710, label: "담양군" },
          { value: 110, label: "목포시" },
          { value: 840, label: "무안군" },
          { value: 780, label: "보성군" },
          { value: 150, label: "순천시" },
          { value: 910, label: "신안군" },
          { value: 130, label: "여수시" },
          { value: 870, label: "영광군" },
          { value: 830, label: "영암군" },
          { value: 890, label: "완도군" },
          { value: 880, label: "장성군" },
          { value: 800, label: "장흥군" },
          { value: 900, label: "진도군" },
          { value: 860, label: "함평군" },
          { value: 820, label: "해남군" },
          { value: 790, label: "화순군" },
        ],
      },
      {
        value: 47,
        label: "경상북도",
        data: [
          { value: 47, label: "전체" },
          { value: 290, label: "경산시" },
          { value: 130, label: "경주시" },
          { value: 830, label: "고령군" },
          { value: 190, label: "구미시" },
          { value: 150, label: "김천시" },
          { value: 280, label: "문경시" },
          { value: 920, label: "봉화군" },
          { value: 250, label: "상주시" },
          { value: 840, label: "성주군" },
          { value: 170, label: "안동시" },
          { value: 770, label: "영덕군" },
          { value: 760, label: "영양군" },
          { value: 210, label: "영주시" },
          { value: 230, label: "영천시" },
          { value: 900, label: "예천군" },
          { value: 940, label: "울릉군" },
          { value: 930, label: "울진군" },
          { value: 730, label: "의성군" },
          { value: 820, label: "청도군" },
          { value: 750, label: "청송군" },
          { value: 850, label: "칠곡군" },
          { value: 111, label: "포항시 남구" },
          { value: 113, label: "포항시 북구" },
        ],
      },
      {
        value: 48,
        label: "경상남도",
        data: [
          { value: 48, label: "전체" },
          { value: 310, label: "거제시" },
          { value: 880, label: "거창군" },
          { value: 820, label: "고성군" },
          { value: 250, label: "김해시" },
          { value: 840, label: "남해군" },
          { value: 270, label: "밀양시" },
          { value: 240, label: "사천시" },
          { value: 860, label: "산청군" },
          { value: 330, label: "양산시" },
          { value: 720, label: "의령군" },
          { value: 170, label: "진주시" },
          { value: 740, label: "창녕군" },
          { value: 125, label: "창원시 마산합포구" },
          { value: 127, label: "창원시 마산회원구" },
          { value: 123, label: "창원시 성산구" },
          { value: 121, label: "창원시 의창구" },
          { value: 129, label: "창원시 진해구" },
          { value: 220, label: "통영시" },
          { value: 850, label: "하동군" },
          { value: 730, label: "함안군" },
          { value: 870, label: "함양군" },
          { value: 890, label: "합천군" },
        ],
      },
      {
        value: 50,
        label: "제주특별자치도",
        data: [
          { value: 50, label: "전체" },
          { value: 130, label: "서귀포시" },
          { value: 110, label: "제주시" },
        ],
      },
    ],
  },
];
