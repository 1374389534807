import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const getMessages = async (
    startDate = null,
    endDate = null,
    type = null,
    result = null,
    page = 0,
    size = 10,
    sort = "name,desc"
) => {
    const params = new URLSearchParams({
        page,
        size,
        sort,
    });
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);
    if (type) params.append("type", type);
    if (result) params.append("result", result);

    const res = await fetchApi.get(`${API_URL.MESSAGES}?${params.toString()}`);
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
};

export const getCampaignMessages = async (
    id,
    page = 0,
    size = 10,
) => {
    const params = new URLSearchParams({
        page,
        size,
    });

    const res = await fetchApi.get(`${API_URL.MESSAGES_CAMPAIGN.replace("{id}", id)}?${params.toString()}`);
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
};
