import { useRecoilValue } from "recoil";
import { partnerSelector } from "../../store/selector/partner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faUser } from "@fortawesome/free-regular-svg-icons";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { logout } from "../../api/partner/partner.api";
import { Link } from "react-router-dom";
import ArrowRightIcon from "../../assets/images/w_arrow_right_icon 2.svg";

export const HeaderAccountInfo = () => {
  const partner = useRecoilValue(partnerSelector);
  const [open, setOpen] = useState(false);
  return (
    <div className="relative font-medium">
      <div
        className="rounded bg-account border border-grey py-2 px-4 text-sm flex gap-2 items-center min-w-[200px] cursor-pointer justify-between content-center"
        onClick={() => setOpen(!open)}
      >
          <p className="normal-white-text">{partner.name}</p>
          <p><img src={ArrowRightIcon} /></p>
      </div>
      {open && (
        <div className="absolute top-12 bg-white rounded w-full border border-gray-300">
          <ul className="text-sm text-gray-600">
            <Link to={"/payments"}>
              <li className="py-3 px-4 cursor-pointer hover:bg-gray-100 rounded-t">
                <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                결제 조회
              </li>
            </Link>
            <li
              className="py-3 px-4 cursor-pointer hover:bg-gray-100 rounded-b"
              onClick={() => logout()}
            >
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                className="mr-2"
              />
              로그아웃
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default HeaderAccountInfo;
