import {CalendarBar} from "omoplay";
import moment from "moment";
import {useEffect, useState} from "react";


const CommonCalendarSearchBar = ({periodStartDate, onConfirm}) => {
    const [dateRange, setDateRange] = useState(
        {
            startDate: moment().toDate(),
            endDate: moment().toDate()
        }
    )

    useEffect(() => {
        setDateRange({
            startDate: periodStartDate.toDate(),
            endDate: moment().toDate()
        })
    }, []);

    return (
        <div className="bg-white relative flex gap-4 p-4 items-center" style={{marginBottom: "15px"}}>
            <CalendarBar initDate={dateRange}
                         minDate={dateRange.startDate}
                         onConfirm={(dateProps) => {
                             const convertDateRange = {
                                 startDate: moment(dateProps.startDate).format("yyyy-MM-DD"),
                                 endDate: moment(dateProps.endDate).format("yyyy-MM-DD")
                             }
                             onConfirm(convertDateRange)
                         }} />
        </div>
    )
}

export default CommonCalendarSearchBar