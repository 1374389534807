import Layout from "../components/layout/Layout";
import BenefitList from "../components/page/BenefitList";

const BenefitListPage = () => {
  return (
    <Layout>
      <BenefitList />
    </Layout>
  );
};
export default BenefitListPage;
