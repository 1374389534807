import SettingsLayout from "../layout/SettingsLayout";
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
  convertRequestData,
  getContentRequired,
} from "../../api/message/volatility-message.api";
import {
  getAutomateMessage,
  postAutomateMessageList
} from "../../api/message/automate-messages.api";
import SettingsStepLayout from "../layout/SettingsStepLayout";
import MessageDefaultInfo from "../volatility-message/MessageDefaultInfo";
import MessageContentsSettings from "../volatility-message/content/setting/MessageContentsSettings";
import AutomateMessageCondition from "../form/automatemessage/AutomateMessageCondition";
import AutomateMessageConfirm from "../form/automatemessage/AutomateMessageConfirm";

const AutomateMessageEdit = () => {
  const tabs = ["기본 설정", "콘텐츠 작성하기", "발송 조건 설정", "최종 확인"]
  const [query] = useSearchParams();
  const [tab, setTab] = useState(1);
  const [isConfirm, setIsConfirm] = useState(false)
  const [message, setMessage] = useState({
    type: "FI", messageData: { type: "FI" }
  })

  const id = query.get("id");

  const handleConfirm = async () => {
    setIsConfirm(true)
    message.message = JSON.stringify(convertRequestData(message.messageType, message.message))
    message.status = false
    await postAutomateMessageList(message);
    alert("수정 되었습니다.");
    window.location.href = "/automate-message"
  }

  useEffect(() => {
    getAutomateMessage(id).then((res) => {
      const data = res.data
      data.message = data.message ?? { type: "FI" }
      setMessage(data)
    })
  }, [id]);

  const getIsConfirm = () => {
    return (
        !isConfirm && message && getContentRequired(message.messageType, message.message)
    )
  }

  const description = () => {
    if (tab === "default") {
      return "메시지의 기본 정보를 설정해 주세요."
    } else {
      return ""
    }
  }

  const updateMessage = (key, value) => {
    const newMessage = { ...message }
    newMessage[key] = value
    setMessage(newMessage)
  }

  const updateMessageData = (key, value) => {
    const newMessage = { ...message }
    const messageData = newMessage.message ?? {}
    messageData[key] = value
    newMessage.message = messageData
    setMessage(newMessage)
  }

  const updateMessageDataArrays = (arrayKeys, value) => {
    const newMessage = { ...message }
    const messageData = newMessage.message ?? {}

    let currentLevel = messageData;
    arrayKeys.forEach((arrayKey, idx) => {
      const isIndex = !isNaN(Number(arrayKeys[idx + 1]))
      currentLevel[arrayKey] = currentLevel[arrayKey] ?? (isIndex ? [] : {})

      if (idx < arrayKeys.length - 1) {
        currentLevel = currentLevel[arrayKey]
      } else {
        currentLevel[arrayKey] = value
      }
    })
    newMessage.message = messageData
    setMessage(newMessage)
  }

  const deleteMessageDataArrays = (index, arrayKeys) => {
    const newMessage = { ...message }
    const messageData = newMessage.message ?? {}
    messageData[arrayKeys] = messageData[arrayKeys] ?? []
    messageData[arrayKeys] = messageData[arrayKeys].filter((_, i) => i !== index)
    newMessage.message = messageData
    setMessage(newMessage)
  }

  const render = () => {
    const messageData = message.message ?? {}
    const type = messageData.type === "FT" ? "FI" : messageData.type

    if (tab === 1) {
      return (
          <div className="setting-step-padding">
            <MessageDefaultInfo campaignName={message?.name}
                                type={type}
                                onChangeCampaignName={(name) => {
                                  updateMessage("name", name)
                                }}
                                onChangeMessageType={(type) => {
                                  updateMessageData("type", type)
                                }}
            />
          </div>
      );
    } else if (tab === 2) {
      return (
          <MessageContentsSettings type={type}
                                   messageData={messageData}
                                   onChangeMessageData={(key, value) => updateMessageData(key, value)}
                                   onChangeMessageDataArrays={(arrayKeys, value) => updateMessageDataArrays(arrayKeys, value)}
                                   onDeleteMessageDataArrays={(index, arrayKeys) => deleteMessageDataArrays(index, arrayKeys)}
          />
      );
    } else if (tab === 3) {
      return (
          <AutomateMessageCondition
              automateMessageData={message}
              setAutomateMessageData={setMessage}
          />
      );
    } else if (tab === 4) {
      return (
          <AutomateMessageConfirm
              messageData={message}
              type={type}
          />
      );
    }
  }

  return (
      <SettingsLayout
          title={"자동화 메시지"}
          tabs={tabs}
          activeTab={tab}
          onConfirm={handleConfirm}
          onChange={setTab}
          isConfirm={getIsConfirm()}
          confirmText={"설정 완료"}
          onClose={() => window.location.href = "/automate-message"}
      >
        <SettingsStepLayout description={description()} renderChild={render()} />
      </SettingsLayout>
  );
};
export default AutomateMessageEdit;
