import MobilePreview from "../../MobilePreview";
import {Button} from "omoplay";

const EventPrizePreview = ({eventBackgroundImage, eventImage, eventButtonImage, prizePrecautionContent, additionalButtons}) => {
    const API_HOST = process.env.REACT_APP_API_HOST
    const url = `${API_HOST}/view/event?temp=true&prize=true` +
        `&eventBackgroundImage=${encodeURIComponent(eventBackgroundImage)}` +
        `&eventImage=${encodeURIComponent(eventImage)}` +
        `&eventButtonImage=${encodeURIComponent(eventButtonImage)}` +
        `&prizePrecautionContent=${encodeURIComponent(prizePrecautionContent.replaceAll("\n", "-br-"))}` +
        `&prizeImage=https://img.headless.co.kr/coupon.jpg` +
        `${additionalButtons?.length > 0 ? `&buttons=${encodeURIComponent(additionalButtons.map((item) => item.name).join(","))}` : ""}`

    return (
        <div className="flex-col items-center gap-2">
            <MobilePreview>
                <iframe
                    className="relative h-full"
                    src={url}
                    title="OMOPLAY TEMP"
                />
            </MobilePreview>
            <div>
                <Button variants="default" value="미리보기" onClick={() => window.open(url)} />
            </div>
        </div>
    )
}

export default EventPrizePreview