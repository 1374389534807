import { useCallback, useEffect, useState } from "react";
import { duplicateCheck } from "../../api/spot/spot.api";
import SuspensePopup from "../SuspensePopup";
import {Alert, TextInput} from "omoplay";

const QREditForm = ({
    visible,
  data,
  onClose,
  benefit,
  onConfirm,
}) => {
  const [name, setName] = useState(data?.name);
  const [isDuplicate, setDuplicate] = useState(false);
  const [benefitId, setBenefitId] = useState(data?.benefit?.id)

  useEffect(() => {
    if (benefit) {
      setBenefitId(benefit.id);
    }
  }, [benefit]);

  useEffect(() => {
    if (data) {
      setName(data.name)
    }
  }, [data]);

  const handleConfirm = useCallback(async () => {
    duplicateCheck(name).then((res) => {
      setDuplicate(data?.name !== name && !res.data)
      if (!(data?.name !== name && !res.data)) {
        onConfirm(name, benefitId);
      }
    })
  }, [benefitId, data?.name, name, onConfirm]);

  return (
      <SuspensePopup
          title={"진입점 추가"}
          visible={visible}
          style={{maxWidth: "520px"}}
          onConfirm={() => handleConfirm()}
          onClose={() => onClose()}
      >
        <div>
          <Alert type="info" withIcon={true}
                 title="프로젝트(이벤트)와 연결하여 사용하실 수 있으며, 진입점에 연결된 프로젝트가 없을 때는 고객들에게 ‘종료된 이벤트입니다' 페이지로 안내됩니다." />
          <div className="py-4 flex-col gap-8">
            <div className="flex-col gap-2">
              <div className="flex-align-center">
                <label className="text-md font-semibold text-gray-900 relative mr-2">
                  진입점명
                </label>
                {
                  isDuplicate && (
                      <label className="text-red-500"> 중복된 이름 입니다.</label>
                    )
                }
              </div>
              <div className="relative">
                <TextInput placeholder="진입점명을 설정해 주세요. (ex. 광화문점)"
                           maxLength={20}
                           defaultValue={name}
                           value={name}
                           onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </SuspensePopup>
  );
};

export default QREditForm;
