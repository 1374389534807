import Loading from "./Loading";

const GrayButton = ({
  children = "",
  isLoading,
  type = "button",
  disabled = false,
  className = "py-1.5 px-4",
  onClick = () => {
    return;
  },
}) => {
  return (
    <div className="flex items-center justify-between">
      <button
        className={
          "border border-gray-300 bg-gray-100 hover:bg-gray-300 text-gray-500 w-full font-medium rounded focus:outline-none focus:shadow-outline text-center transition disabled:text-gray-400 disabled:bg-gray-100 disabled:border-gray-100 " +
          className
        }
        type={type}
        disabled={disabled || isLoading}
        onClick={() => onClick()}
      >
        {isLoading ? (
          <div className="flex justify-center">
            <p className="text-white fill-gray-500 m-auto">
              <Loading className="w-6 h-6" />
            </p>
          </div>
        ) : (
          children
        )}
      </button>
    </div>
  );
};
export default GrayButton;
