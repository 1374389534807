import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GrayButton from "../GrayButton";
import {
  faArrowLeft,
  faBullseye, faC,
  faCircleQuestion,
  faComputerMouse,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import {MESSAGE_TARGET_FILTER} from "../../constants/menus";
import moment from "moment";
import Tooltip from "../Tooltip";
import {
  copyMessage, getVolatilityClickUsers, getVolatilityMessage,
} from "../../api/message/volatility-message.api";
import {useSearchParams} from "react-router-dom";
import MessagePreview from "./content/preview/MessagePreview";
import {getCampaignMessages} from "../../api/message/messages.api";
import {Button, Pagination, Table} from "omoplay";
import {userBarChartConfig, userLineChartConfig} from "../../constants/chart_config";
import DateChart from "../DateChart";
import CommonCalendarSearchBar from "../CommonCalendarSearchBar";

export const VolatilityMessageDetail = () => {
  const [query] = useSearchParams();
  const id = query.get("id")
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sendUsersTable, setSendUsersTable] = useState([]);
  const [volatilityData, setVolatilityData] = useState({})
  const [benefitTableData, setBenefitTableData] = useState([])
  const [previewData, setPreviewData] = useState({})
  const [filterCategories, setFilterCategories] = useState([])
  const [clickUserData, setClickUserData] = useState({})
  const [contentsClickUserTable, setContentsClickUserTable] = useState([])
  const [totalClickUserTable, setTotalClickUserTable] = useState([])
  const [clickUserChartConfig, setClickUserChartConfig] = useState({...userBarChartConfig})
  const [clickUserLineChartConfig, setClickUserLineChartConfig] = useState({...userLineChartConfig})
  const [dateRange, setDateRange] = useState({})

  const clickUserHeaders = [
    { name: "이름", align: "center", width: "100px" },
    { name: "성별", align: "center", width: "100px" },
    { name: "연령", align: "center", width: "120px" },
    { name: "회원가입" },
    { name: "클릭 수" },
    { name: "클릭 시간" },
  ];
  const sendUserHeaders = [
    { name: "이름", align: "center", width: "100px" },
    { name: "성별", align: "center", width: "100px" },
    { name: "연령", align: "center", width: "120px" },
    { name: "회원가입" },
    { name: "상태" },
  ];

  const benefitHeaders = [
    { name: "혜택명"},
    { name: "금액" },
    { name: "형식" },
    { name: "사용 수 / 발급 수" },
  ];

  const displayGenderText = (gender) => {
    if (gender === "male") {
      return "남성";
    } else if (gender === "female") {
      return "여성";
    } else {
      return gender;
    }
  }

  useEffect(() => {
    getVolatilityMessage(id).then(({data}) => {
      setVolatilityData(data);
      setBenefitTableData(data.benefitList?.map((benefit, index) => {
        return [
            <div>{benefit.name}</div>,
            <div>{benefit.price.toLocaleString()}원</div>,
            <div>{benefit.type}</div>,
            <div>{benefit.usedAndIssued}</div>
        ]
      }))
      setFilterCategories(Array.from(new Set(data.filters.map((item) => item.category))))
      setPreviewData(JSON.parse(data.messageData));
    })

    getVolatilityClickUsers(id, dateRange.startDate, dateRange.endDate).then(({data}) => {
      setClickUserData(data)
      setContentsClickUserTable(data.contents.map(content => {
        return [
            <div>{content.name}</div>,
            <div>{content.typeName}</div>,
            <div>{content.totalCount}</div>,
            <div>{content.users.length}</div>
        ]
      }))
      setTotalClickUserTable(data.totalClickUsers.map(user => {
        return [
          <div className="text-center">{user.name}</div>,
          <div className="text-center">{displayGenderText(user.gender)}</div>,
          <div className="text-center">{user.age}</div>,
          <>
            <div
                className="flex"
                style={{
                  maxWidth: "180px",
                  textOverflow: "ellipsis",
                  overflowX: "clip",
                  whiteSpace: "nowrap",
                  color: "var(--mainblue-0)"
                }}
            >
              {user.spotName}
            </div>
            <div>
              {user.createdAt}
            </div>
          </>,
            <div>{user.clickNum}</div>,
            <div>{user.clickDates.map(date => {
              return (
                  <div>{date}</div>
              )
            })}</div>
        ]
      }))

      const userBarConfig = { ...userBarChartConfig };
      userBarConfig.series[0].data = [];
      userBarConfig.series[1].data = [];

      Object.entries(data.groupAgeTotalCount).forEach(([key, value]) => {
        userBarConfig.series[0].data.push({
          x: key,
          y: value,
        });
      });
      Object.entries(data.groupAgeClickCount).forEach(([key, value]) => {
        userBarConfig.series[1].data.push({
          x: key,
          y: value,
        });
      });
      setClickUserChartConfig(userBarConfig);

      const userLineConfig = { ...userLineChartConfig };
      userLineConfig.series[0].data = [];

      Object.entries(data.groupDateClickCount).forEach(([key, value]) => {
        userLineConfig.series[0].data.push({
          x: key,
          y: value,
        });
      });
      setClickUserLineChartConfig(userLineConfig)
    })
  }, [id, dateRange]);

  useEffect(() => {
    getCampaignMessages(id, page - 1).then(({data}) => {
      setSendUsersTable(data.content.map(user => {
        return [
          <div className="text-center">{user.name}</div>,
          <div className="text-center">{displayGenderText(user.gender)}</div>,
          <div className="text-center">{user.age}</div>,
          <>
            <div
                className="flex"
                style={{
                  maxWidth: "180px",
                  textOverflow: "ellipsis",
                  overflowX: "clip",
                  whiteSpace: "nowrap",
                  color: "var(--mainblue-0)"
                }}
            >
              {user.spotName}
            </div>
            <div>
              {moment(user.createdAt).format("YYYY.MM.DD HH:mm")}
            </div>
          </>,
          <div>
            {displayStatus(user.status, user.errorCode)}
          </div>
        ]
      }));
      setTotalPages(data.totalElements);
    })
  }, [id, page]);


  const displayStatus = (status, errorCode) => {
    if (status === "2") {
      return <span>발송 성공</span>
    } else if (status === "4") {
      return <><span style={{color: "#CE326C"}}>발송 실패</span><br/><span>({errorCode})</span></>
    } else {
      return <span>결과 대기</span>;
    }
  };

  return (
    <div>
      <div className="mb-4 gap-4" style={{ width: "1248px" }}>
        <div>
          <div className="flex justify-between items-center mb-2">
            <div className="flex gap-4">
              <div className="w-10 h-10">
                <GrayButton
                    className="w-10 h-10"
                    onClick={() => window.location.href = ("/volatility-message")}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </GrayButton>
              </div>
              <h4 className="text-3xl font-medium">{volatilityData.name}</h4>
            </div>
            <div>
              <Button variants="dark" value="복제하기" onClick={() => {
                if (window.confirm("정말 복제하시겠습니까?")) {
                  copyMessage(id).then(() => {
                    window.location.href = "/volatility-message"
                  })
                }
              }} />
            </div>
          </div>
          <div className="flex-col gap-20 mt-8">
            <div>
              <div className="flex-col gap-2">
                <label className="text-lg font-medium text-gray-900 relative">
                  메시지 발송 결과
                </label>
                <div className="white border border-gray-300 rounded p-4 bg-white">
                  <p>
                    <span className="font-semibold">발송 일시 : </span>
                    {volatilityData.reservedDate}
                  </p>
                </div>
                <div className="white border border-gray-300 rounded bg-white divide-x h-[135px] flex">
                  <div className="flex-col justify-between w-1/3 p-4 h-full">
                    <div className="flex gap-2 items-center">
                      <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                        <FontAwesomeIcon icon={faBullseye} size="sm" />
                      </div>
                      <p className="font-semibold text-sm">
                        발송 성공 / 발송 시도 고객 수
                        <div className="inline-block align-text-top ml-2">
                          <Tooltip
                            top={"top-4"}
                            message={
                              "친구 삭제,차단 등으로 고객 성공 수에 차이가 발생합니다."
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCircleQuestion}
                              size={"lg"}
                              color={"#999"}
                            />
                          </Tooltip>
                        </div>
                      </p>
                    </div>
                    <div className="flex justify-end text-right">
                      <div>
                        <p className="text-gray-500">
                          {volatilityData.sendCount?.toLocaleString()}명 시도
                        </p>
                        <p className="font-bold text-2xl">
                          {volatilityData.successCount?.toLocaleString()}명
                          <span className="font-medium text-base ml-1">
                            <td>
                              (
                              {volatilityData.successCount && volatilityData.sendCount
                                ? Math.round(
                                    (volatilityData.successCount / volatilityData.sendCount) * 1000
                                  ) / 10
                                : 0}
                              %)
                            </td>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex-col justify-between w-1/3 p-4 h-full">
                    <div className="flex gap-2 items-center">
                      <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                        <FontAwesomeIcon icon={faComputerMouse} size="sm" />
                      </div>
                      <p className="font-semibold text-sm">
                        클릭 고객 수
                        <div className="inline-block align-text-top ml-2">
                          <Tooltip
                            top={"top-4"}
                            message={
                              "중복을 제외한 실제 고객 수를 의미합니다. 포함된 링크가 없는 경우, 숫자가 카운트 되지 않습니다."
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCircleQuestion}
                              size={"lg"}
                              color={"#999"}
                            />
                          </Tooltip>
                        </div>
                      </p>
                    </div>
                    <div className="flex justify-end text-right">
                      <p className="font-bold text-2xl">
                        {clickUserData.totalClickUsers?.length.toLocaleString()}명
                      </p>
                    </div>
                  </div>
                  <div className="flex-col justify-between w-1/3 p-4 h-full">
                    <div className="flex gap-2 items-center">
                      <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                        <FontAwesomeIcon icon={faDollarSign} size="sm" />
                      </div>
                      <p className="font-semibold text-sm">발송 비용</p>
                    </div>
                    <div className="flex justify-end text-right">
                      <p className="font-bold text-2xl">
                        ₩{volatilityData.totalPrice?.toLocaleString()}원
                      </p>
                    </div>
                  </div>
                  <div className="flex-col justify-between w-1/3 p-4 h-full">
                    <div className="flex gap-2 items-center">
                      <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                        <FontAwesomeIcon icon={faC} size="sm" />
                      </div>
                      <p className="font-semibold text-sm">쿠폰 사용 / 쿠폰 발급 수</p>
                    </div>
                    <div className="flex justify-end text-right">
                      <div>
                        <p className="text-gray-500">
                          {volatilityData.issuedCouponCount}개 발급
                        </p>
                        <p className="font-bold text-2xl">
                          {volatilityData.usedCouponCount}개
                          <span className="font-medium text-base ml-1">
                            <td>
                              (
                              {volatilityData.usedCouponCount && volatilityData.issuedCouponCount
                                  ? Math.round(
                                  (volatilityData.usedCouponCount / volatilityData.issuedCouponCount) * 1000
                              ) / 10
                                  : 0}
                              %)
                            </td>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <label className="text-lg font-medium text-gray-900 relative">
                  발송 조건
                </label>
                <p className="text-gray-500 mb-2">
                  다른 메시지들과 구분하기 위한 캠페인명을 작성해 주세요.
                </p>
              </div>
              <ul className="flex-col gap-4 mt-4">
                {filterCategories.length > 0 ?
                  filterCategories.map((value, i) => {
                    const categoryIndex = MESSAGE_TARGET_FILTER.findIndex(
                      (parent) => parent.value === value
                    );
                    return (
                      <li
                        key={"confirm_category_" + i}
                        className="flex gap-4 items-center"
                      >
                        <div className="border border-gray-300 rounded flex w-5/6">
                          <div className="min-w-[160px] bg-gray-100 p-4 border-r border-gray-300 flex rounded-l">
                            <p className="mt-1">
                              {MESSAGE_TARGET_FILTER[categoryIndex].label}
                            </p>
                          </div>
                          <div className="flex flex-wrap gap-4 px-4 py-4 bg-white w-full rounded-r">
                            {volatilityData.filters
                              .filter((item) => item.category === value)
                              .map((item, j) => {
                                return (
                                  <div
                                    key={"confirm_category_" + i + "_item_" + j}
                                    className="rounded py-1 bg-indigo-100 text-indigo-600 px-4 font-medium"
                                  >
                                    {item.label}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className="w-1/6 flex">
                          {i < filterCategories.length - 1 && (
                            <p className="ml-4">
                              이고 <span className="font-semibold">(AND)</span>
                            </p>
                          )}
                        </div>
                      </li>
                    )
                  }) : (
                        <li
                            key={"confirm_category_0"}
                            className="flex gap-4 items-center"
                        >
                          <div className="border border-gray-300 rounded flex w-5/6">
                            <div className="min-w-[160px] bg-gray-100 p-4 border-r border-gray-300 flex rounded-l">
                              <p className="mt-1">
                                발송 조건
                              </p>
                            </div>
                            <div className="flex flex-wrap gap-4 px-4 py-4 bg-white w-full rounded-r">
                              <div
                                  key={"confirm_category_0_item_0"}
                                  className="rounded py-1 bg-indigo-100 text-indigo-600 px-4 font-medium"
                              >
                                전체
                              </div>
                            </div>
                          </div>
                        </li>
                  )}
              </ul>
            </div>
            <div>
              <div>
                <label className="text-lg font-medium text-gray-900 relative">
                  발급 혜택
                </label>
                <p className="text-gray-500 mb-8">
                  메시지에 포함된 발급 혜택 리스트 입니다.
                </p>
              </div>
              <Table headers={benefitHeaders} data={benefitTableData} />
            </div>
            <div className="flex justify-around">
              <div>
                <label className="text-lg font-medium text-gray-900">
                  발송 메시지
                </label>
                <div className="mt-4"></div>
                <MessagePreview messageData={previewData} type={previewData.type} />
              </div>
              <div className="flex-col gap-4">
                <label className="text-lg font-medium text-gray-900">
                  발송 분석
                </label>
                <div className="flex justify-between items-center bg-white" style={{padding: "10px 20px"}}>
                  <div className="flex gap-2">
                    <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                      <FontAwesomeIcon icon={faComputerMouse} size="sm" />
                    </div>
                    <div className="font-semibold text-sm">
                      링크 클릭 수
                      <div className="inline-block align-text-top ml-2">
                        <Tooltip
                            top={"top-4"}
                            message={
                              "중복을 포함한 링크 클릭 수를 의미합니다. 포함된 링크가 없는 경우, 숫자가 카운트 되지 않습니다."
                            }
                        >
                          <FontAwesomeIcon
                              icon={faCircleQuestion}
                              size={"lg"}
                              color={"#999"}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className="font-bold text-2xl">
                    {volatilityData.linkCount?.toLocaleString()}번
                  </div>
                </div>
                <div style={{width: "726px"}}>
                  <Table headers={
                    [{name: "클릭 컨텐츠"}, {name: "형태"}, {name: "클릭 횟수(중복o)"}, {name: "클릭 고객수(중복x)"}]
                  } data={contentsClickUserTable} />
                </div>
              </div>
            </div>
            <div className="flex-col gap-2">
              <label className="text-lg font-medium text-gray-900">
                메시지 클릭 고객 분석
              </label>
              <div>메시지에 클릭한 고객을 분석합니다</div>
              <div className="flex justify-between items-center bg-white">
                <div className="flex gap-2 p-20px" style={{backgroundColor: "#EFEFEF"}}>
                  <div>클릭 고객 수</div>
                  <div>/</div>
                  <div>메시지 발송 수</div>
                </div>
                <div className="flex-align-center gap-2" style={{paddingRight: "20px"}}>
                  <div style={{fontSize: "26px", fontWeight: "700"}}>{clickUserData.totalClickUsers?.length.toLocaleString()}명</div>
                  <div style={{fontSize: "16px"}}>/</div>
                  <div style={{fontSize: "16px", color: "#A3A8AF"}}>{volatilityData.successCount?.toLocaleString()}명</div>
                  <div style={{fontSize: "16px"}}>({Math.round(clickUserData.totalClickUsers?.length / volatilityData.successCount * 1000) / 10}%)</div>
                </div>
              </div>
            </div>
            <div>
              <div>
                {
                  volatilityData.reservedDate ? (
                      <CommonCalendarSearchBar periodStartDate={moment(volatilityData.reservedDate)}
                                               onConfirm={(dateRange) => {
                                                 setDateRange(dateRange)
                                               }} />
                  ) : <></>
                }
              </div>
              <DateChart
                  keyName={"click_user_line_chart"}
                  chartConfig={clickUserLineChartConfig}
              />
            </div>
            <div>
              <DateChart
                  keyName={"user_bar_chart"}
                  chartConfig={clickUserChartConfig}
              />
            </div>
            <div className="flex-col gap-4">
              <label className="text-lg font-medium text-gray-900">
                메시지 클릭 고객
              </label>
              <div className="flex-col bg-white p-2 border-gray-300 border rounded">
                <Table headers={clickUserHeaders} data={totalClickUserTable}
                       pagination={{
                         rowSize: 10
                       }}
                />
              </div>
            </div>
            <div className="flex-col gap-4">
              <label className="text-lg font-medium text-gray-900">
                메시지 발송 고객
              </label>
              <div className="flex-col bg-white p-2 border-gray-300 border rounded">
                <Table headers={sendUserHeaders} data={sendUsersTable} />
                <div className="flex justify-center" style={{marginTop: "24px"}}>
                  <Pagination rowSize={10} totalSize={totalPages} page={page} onPaging={(page) => setPage(page)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VolatilityMessageDetail;
