import CircleBadge from "../CircleBadge";
import {Button, TextInput} from "omoplay";
import {useEffect, useState} from "react";
import {getSpot} from "../../../api/spot/spot.api";

const EntryPointPreviewPopup = ({ spotId, excludeEntryPointIds }) => {
    const [copy, setCopy] = useState(false)
    const [spot, setSpot] = useState({})

    useEffect(() => {
        getSpot(spotId).then(({data}) => {
            setSpot(data)
        })
    }, [spotId]);

  return (
    <div className="flex-col gap-4 py-8">
        <div>
            <label className="flex-align-center gap-2">
                <CircleBadge label={1} />
                <div className="font-bold font-16px">
                    QR 이미지
                </div>
            </label>
            <div className="text-grey-5">활용 예시 : 오프라인 홍보물, 오프라인 광고 디스플레이</div>
        </div>
        <div className="flex-align-center gap-8">
            <img alt="" src={spot.qrImageUrl} className="w-32 h-32"/>
            <div className="font-bold font-16px">화면 아래에서 해당 ID를 확인 해주세요. [{spot.spotUid}]</div>
        </div>
        <div>
            <label className="flex-align-center gap-2">
                <CircleBadge label={2} />
                <div className="font-bold font-16px">
                    URL 링크
                </div>
            </label>
            <div className="text-grey-5">활용 예시 : 온라인 광고 링크</div>
        </div>
        <div className="flex-col gap-2">
            <TextInput
                disabled={true}
                value={spot.qrLinkUrl}
            />
            <div className="flex justify-between">
                <Button
                    variants="default"
                    onClick={() => window.open(spot.qrLinkUrl)}
                    value="URL 확인"
                />
                <div className={`absolute ${!copy && 'none'}`} style={{bottom: "20px", right: "40px", color: "red"}}>복사 되었습니다</div>
                <Button
                    variants="dark"
                    onClick={() => navigator.clipboard.writeText(spot.qrLinkUrl).then(() => {
                        setCopy(true)
                    })}
                    value="URL 복사"
                />
            </div>
        </div>
    </div>
  );
};
export default EntryPointPreviewPopup;
