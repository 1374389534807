const DateStatistics = ({ children, keyName, cards = [] }) => {
    return (
        <>
            <div
                className={"flex-col border rounded"}
            >
                {cards && cards.length > 0 && (
                    <div className="bg-white">
                        {cards.map((card, index) => {
                            return (
                                <div
                                    className="p-4"
                                    key={keyName + "_card" + index}
                                >
                                    <div className="w-full flex gap-4 items-center justify-end">
                                        <label className="font-extrabold text-2xl text-green-500">
                                            + {card.value.toLocaleString()}
                                        </label>
                                    </div>
                                    {card.total != null && (
                                        <label className="block text-gray-400 w-full text-right">
                                            TOTAL {card.total.toLocaleString()}
                                        </label>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
                {children}
            </div>
        </>
    );
};
export default DateStatistics;
