import { useEffect, useState } from "react";
import {Button, Table, Toggle} from "omoplay";
import {getAutomateMessageList, postAutomateMessageList} from "../../api/message/automate-messages.api";

const AutomateMessageListPage = () => {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    getAutomateMessageList().then((res) => {
      setTableData(res.data.map(message => {
        return [
          <div className="flex-center-all h-full">
            <Toggle disabled={message.status === null} value={message.status} onToggle={(flag) => updateStatus(message, flag)} />
          </div>,
          <div className="flex-align-center h-full">{message.name}</div>,
          <div className="flex-align-center h-full">{message.modifiedAt}</div>,
            <Button variants="outlined" value="수정" onClick={() => window.location.href = `/automate-message/edit?id=${message.id}`} />
        ]
      }))
    })
  }, []);

  const updateStatus = async (message, flag) => {
    message.status = flag
    await postAutomateMessageList(message)
  }

  const headers = [
    { name: "상태", align: "center", width: "120px" },
    { name: "이름" },
    { name: "최근 업데이트", width: "174px"},
    { name: "수정", align: "center", width: "100px" },
  ];

  return (
    <>
      <div className="flex flex-col rounded w-full">
        <div className="text-red-500">* 수정 이후 ON 상태로 변경 가능합니다</div>
        <div className="flex flex-col py-4">
          <Table headers={headers} data={tableData} />
        </div>
      </div>
    </>
  );
};
export default AutomateMessageListPage;
