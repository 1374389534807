import React, { createContext, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {instance} from "../../api/fetch";

const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const requestInterceptor = instance.interceptors.request.use((config) => {
            setLoading(true);
            return config;
        });

        const responseInterceptor = instance.interceptors.response.use(
            (response) => {
                setLoading(false);
                return response;
            },
            (error) => {
                setLoading(false);
                return Promise.reject(error);
            }
        );

        return () => {
            instance.interceptors.request.eject(requestInterceptor);
            instance.interceptors.response.eject(responseInterceptor);
        };
    }, []);

    return (
        <LoadingContext.Provider value={{ loading }}>
            {children}
            {loading && ReactDOM.createPortal(<LoadingOverlay />, document.body)}
        </LoadingContext.Provider>
    );
};

const LoadingOverlay = () => (
    <div className="loading-overlay">
        <div className="loading-spinner"></div>
    </div>
);