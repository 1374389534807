const InputGroup = ({label, description, children}) => {
  return (
      <div className="flex-col gap-2 bg-white border" style={{borderRadius: "8px"}}>
          <div className="border-b" style={{padding: "16px 24px"}}>
              <label className="font-bold text-gray-900 font-16px">
                  {label}
              </label>
              <p className="text-gray-500 text-sm">{description}</p>
          </div>
          <div style={{padding: "16px 24px"}}>
              {children}
          </div>
      </div>
  );
};

export default InputGroup;
