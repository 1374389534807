import AIAgentAutomationList from "../components/page/AIAgentAutomationList";
import Layout from "../components/layout/Layout";

const AIAgentAutomationPage = () => {
  return (
      <Layout>
        <AIAgentAutomationList />
      </Layout>
  );
};
export default AIAgentAutomationPage;
