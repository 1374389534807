import {Alert, Button, CalendarBar, Dropdown, RadioGroup} from "omoplay";
import moment from "moment";
import {useEffect, useState} from "react";
import {postProjectPeriod} from "../../../api/project/project.api";
import InputGroup from "../InputGroup";
import {DROP_DOWN_HOURS, DROP_DOWN_MINUTES} from "../../Const";


const ProjectDetailPeriod = ({projectId, initStartDate, initEndDate,
                                 onChangeStartDate = () => {}, onChangeEndDate = () => {},
                                 disabledEdit, isEdit}) => {
    const [type, setType] = useState(initEndDate ? "period" : "always")
    const [startDate, setStartDate] = useState(moment().hours(0).minutes(0))
    const [endDate, setEndDate] = useState(moment().hours(0).minutes(0))
    const format = "YYYY년 MM월 DD일 HH시 mm분"

    useEffect(() => {
        setStartDate(moment(initStartDate))
        setEndDate(initEndDate ? moment(initEndDate) : moment().hours(0).minutes(0))
        setType(initEndDate ? "period" : "always")
    }, [initStartDate, initEndDate]);

    const onSubmit = (startDate, endDate) => {
        postProjectPeriod(projectId, startDate, endDate).then(() => {
            alert("적용 되었습니다.")
        })
    }

    return (
        <InputGroup label="프로젝트 기간">
            <div className="flex-col gap-4">
                {
                    isEdit && (
                        <div className="flex justify-end">
                            <Button variants="dark" value="기간 수정" onClick={() => {
                                onSubmit(startDate.format("YYYY-MM-DD HH:mm"), type === "period" ? endDate.format("YYYY-MM-DD HH:mm") : null)
                            }} />
                        </div>
                    )
                }
                <Alert type="info" title="">
                    <div className="flex gap-1">
                        <div>{`${startDate.format(format)} ~ ${type === "always" ? '상시 진행' : endDate.format(format)}`}</div>
                        {
                            type === "period" && (
                                <div className="text-primary font-semibold">({endDate.diff(startDate, 'days')}일간)</div>
                            )
                        }
                    </div>
                </Alert>
                <div>
                    <RadioGroup key={endDate} items={[
                        { label: "상시 진행", value: "always" },
                        { label: "기간 설정", value: "period" }
                    ]} initChecked={type} onSelected={(value) => {
                        setType(value)
                    }} />
                </div>
                <div className="flex-col gap-2">
                    <div>시작 날짜</div>
                    <div className="flex items-center justify-evenly gap-4">
                        <div className="flex-1">
                            <CalendarBar
                                initDate={{
                                    startDate: startDate.toDate(),
                                    endDate: startDate.toDate()
                                }}
                                multiple={false}
                                minDate={new Date()}
                                onConfirm={(dateProps) => {
                                    const confirmDate = moment(dateProps.startDate)
                                    const convertDate = moment(startDate).set({
                                        year: confirmDate.year(),
                                        month: confirmDate.month(),
                                        date: confirmDate.date()
                                    })
                                    onChangeStartDate(convertDate)
                                    setStartDate(convertDate)
                                }}
                                disabled={disabledEdit}
                            />
                        </div>
                        <div className="flex-1">
                            <Dropdown key={startDate}
                                      variants="default"
                                      initialValue={startDate.hours().toString()}
                                      items={DROP_DOWN_HOURS}
                                      onChanged={(items) => {
                                          onChangeStartDate(startDate.hours(items[0].value))
                                          setStartDate(startDate.hours(items[0].value))
                                      }} />
                        </div>
                        <div className="flex-1">
                            <Dropdown key={startDate}
                                      variants="default"
                                      initialValue={startDate.minutes().toString()}
                                      items={DROP_DOWN_MINUTES}
                                      onChanged={(items) => {
                                          onChangeStartDate(startDate.minutes(items[0].value))
                                          setStartDate(startDate.minutes(items[0].value))
                                      }} />
                        </div>
                    </div>
                    {
                        type === "always" ? (
                            <></>
                        ) : (
                            <>
                                <div>종료일</div>
                                <div className="flex items-center justify-evenly gap-4">
                                    <div className="flex-1">
                                        <CalendarBar initDate={{
                                            startDate: endDate.toDate(),
                                            endDate: endDate.toDate()
                                        }} multiple={false} onConfirm={(dateProps) => {
                                            const confirmDate = moment(dateProps.endDate)
                                            const convertDate = moment(endDate).set({
                                                year: confirmDate.year(),
                                                month: confirmDate.month(),
                                                date: confirmDate.date()
                                            })
                                            onChangeEndDate(convertDate)
                                            setEndDate(convertDate)
                                        }} minDate={new Date()}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <Dropdown variants="default"
                                                  initialValue={endDate.hours().toString()}
                                                  items={DROP_DOWN_HOURS}
                                                  onChanged={(items) => {
                                                      setEndDate(endDate.hours(items[0].value))
                                                      onChangeEndDate(endDate.hours(items[0].value))
                                                  }}
                                                  disabled={type === "always"}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <Dropdown variants="default"
                                                  initialValue={endDate.minutes().toString()}
                                                  items={DROP_DOWN_MINUTES}
                                                  onChanged={(items) => {
                                                      setEndDate(endDate.minutes(items[0].value))
                                                      onChangeEndDate(endDate.minutes(items[0].value))
                                                  }}
                                                  disabled={type === "always"}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </InputGroup>
    )
}

export default ProjectDetailPeriod