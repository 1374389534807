import OMOPlayLogo from "../../assets/images/omoplay_logo_black.svg";
import GuideIcon from "../../assets/images/guide_icon.svg";
import CheckBlueIcon from "../../assets/images/icon.blue.check.svg";
import CheckBlueBoldIcon from "../../assets/images/icon.check.bold.svg";
import CancelBoldIcon from "../../assets/images/icon.cancel.bold.svg";
import CheckWhiteBoldIcon from "../../assets/images/icon.check.white.svg";
import CancelWhiteBoldIcon from "../../assets/images/icon.cancel.white.svg";
import OutLinkIcon from "../../assets/images/icon.outlink.svg";
import BookIcon from "../../assets/images/icon.book.blue.svg";
import ConnectFin from "../../assets/images/connect_setting_fin.svg";
import {TextInput, Button, Dropdown, Alert, Stepper} from "omoplay";
import {postConnectRegistration, postCounsel, updateHostingPlatform} from "../../api/account/account.api";
import {useEffect, useState} from "react";
import OMOSettingsLayout from "../layout/OMOSettingsLayout";
import {useSearchParams} from "react-router-dom";
import ValidCheckForm from "../form/ValidCheckForm";
import CardBillingForm from "../form/CardBillingForm";
import AnotherMallForm from "../form/AnotherMallForm";

const OMOConnectSettingPage = () => {
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [hostingName, setHostingName] = useState("카페24")
    const [hostingLabel, setHostingLabel] = useState("카페24 쇼핑몰 (cafe24.com)")
    const [hostingId, setHostingId] = useState("")
    const [hostingPwd, setHostingPwd] = useState("")
    const [step, setStep] = useState(0)
    const [confirmName, setConfirmName] = useState("다음")
    const [isBusiness, setBusiness] = useState()
    const [useKakaoSync, setUseKakaoSync] = useState()
    const [existsCardInfo, setExistsCardInfo] = useState(false)
    const [validBusinessSetting, setValidBusinessSetting] = useState(false)
    const [validNotBusinessSettings, setValidNotBusinessSetting] = useState([false, false, false, false])

    const [query] = useSearchParams()
    const id = query.get("id");

    useEffect(() => {
        setConfirmName("다음")
        if (step === 0) {
            setConfirmName("계정 제출 및 다음 단계로 가기")
            if (useKakaoSync) {
                setSubmitDisabled(hostingId.length === 0)
            } else if (useKakaoSync === false) {
                setSubmitDisabled(false)
            } else {
                setSubmitDisabled(hostingId.length === 0 || hostingPwd.length === 0)
            }
        } else if (step === 1) {
            setSubmitDisabled(isBusiness === undefined)
        } else if (step === 2) {
            if (isBusiness) {
                setSubmitDisabled(!validBusinessSetting)
            } else {
                setSubmitDisabled(validNotBusinessSettings.findIndex(st => !st) > -1)
            }
        } else if (step === 3) {
            setSubmitDisabled(!existsCardInfo)
        } else if (step === 4) {
            setSubmitDisabled(false)
            setConfirmName("완료")
        } else {
            setSubmitDisabled(true)
        }
    }, [step, isBusiness, existsCardInfo, hostingId, hostingPwd, validNotBusinessSettings, validBusinessSetting, useKakaoSync]);

    const changeStep = (step) => {
        setStep(step)
    }

    return (
        <OMOSettingsLayout title="오모 Connect 신청">
            <div>
                <div className="items-center">
                    <div className="flex-col gap-[32px] border bg-white p-[32px] w-[720px] rounded-[16px]">
                        <div>
                            <div className="w-[200px]">
                                <img alt="" src={OMOPlayLogo} />
                            </div>
                        </div>
                        {
                            (useKakaoSync === false && step === 1) ? (
                                <>
                                    <div className="text-gray-900 font-bold text-[28px]">
                                        카카오 연동이 되어있지 않은<br/>
                                        독립몰의 경우 별도 상담이 필요합니다
                                    </div>
                                    <AnotherMallForm />
                                </>
                            ) : (
                                <>
                                    <Stepper
                                        listLabel={[]}
                                        totalStep={4}
                                        stepActive={step + 1}
                                    />
                                    {
                                        step === 0 && (
                                            <>
                                                <div className="text-gray-900 font-bold text-[28px]">
                                                    환영합니다! 오모플레이 세팅을 위해<br/>
                                                    쇼핑몰 호스팅사 정보를 알려주세요
                                                </div>
                                                <div>
                                                    <div className="flex-col gap-[32px]">
                                                        <div>
                                                            <div>세팅할 플랫폼 (예: 카페24, 아임웹)</div>
                                                            <Dropdown variants="default" initialValue={hostingName} items={[
                                                                {label: "카페24 쇼핑몰 (cafe24.com)", value: "카페24"}, {label: "아임웹 쇼핑몰 (imweb.me)", value: "아임웹"},
                                                                {label: "식스샵 쇼핑몰 (sixshop.com)", value: "식스샵"}, {label: "고도몰 (NHN Commerce)", value: "고도몰"},
                                                                {label: "기타 독립몰 (자체 개발 쇼핑몰)", value: "독립몰"}
                                                            ]} onChanged={(items) => {
                                                                setUseKakaoSync(undefined)
                                                                setHostingName(items[0].value)
                                                                setHostingLabel(items[0].value)
                                                            }} />
                                                        </div>
                                                        {
                                                            hostingName === "독립몰" ? (
                                                                <>
                                                                    <div className="flex gap-2">
                                                                        <Button variants={useKakaoSync ? "primary" : "outlined"} value={
                                                                            <div className="flex gap-1">
                                                                                <img src={useKakaoSync ? CheckWhiteBoldIcon : CheckBlueBoldIcon} alt="check_bold" />
                                                                                <div>카카오 싱크/로그인을 사용하고 있습니다</div>
                                                                            </div>
                                                                        } onClick={() => setUseKakaoSync(true)} />
                                                                        <Button variants={useKakaoSync === false ? "danger" : "outlined"} value={
                                                                            <div className="flex gap-1">
                                                                                <img src={useKakaoSync === false ? CancelWhiteBoldIcon : CancelBoldIcon} alt="cancel_bold" />
                                                                                <div>사용하고 있지 않습니다</div>
                                                                            </div>
                                                                        } onClick={() => setUseKakaoSync(false)} />
                                                                    </div>
                                                                    {
                                                                        useKakaoSync && (
                                                                            <div>
                                                                                <div>독립몰 주소 (URL)<span className="text-red-500">*</span></div>
                                                                                <TextInput placeholder="독립몰 주소를 입력해주세요."
                                                                                           autoComplete="off"
                                                                                           id="hostingId"
                                                                                           value={hostingId}
                                                                                           onChange={(e) => {
                                                                                               setHostingId(e.currentTarget.value)
                                                                                           }} />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div>
                                                                        <div>운영자 계정 아이디</div>
                                                                        <TextInput placeholder="아이디를 입력해주세요."
                                                                                   autoComplete="off"
                                                                                   id="hostingId"
                                                                                   value={hostingId}
                                                                                   onChange={(e) => {
                                                                                       setHostingId(e.currentTarget.value)
                                                                                   }} />
                                                                    </div>
                                                                    <div style={{width: "100%"}}>
                                                                        <div>비밀번호</div>
                                                                        <TextInput type="password" placeholder="비밀번호를 입력해주세요."
                                                                                   autoComplete="new-password"
                                                                                   id="hostingPwd"
                                                                                   value={hostingPwd}
                                                                                   onChange={(e) => {
                                                                                       setHostingPwd(e.currentTarget.value)
                                                                                   }} />
                                                                    </div>
                                                                    <Alert title="계정정보가 왜 필요한가요?" type="warning" withIcon={true}>
                                                                        <label className="text-[13px]">
                                                                            카카오 싱크 설정, 회원가입 필수정보 설정, 약관 확인을 위해 필요합니다.<br/>
                                                                            제공해주신 정보는 안전하게 암호화 되어 보관되며, 최소한의 필요 인원 외에는 접근할 수 없도록 관리하고 있습니다.<br/>
                                                                            계정 정보가 부정확한 경우 세팅이 지연될 수 있습니다.
                                                                        </label>
                                                                    </Alert>
                                                                    <div className="text-[20px] font-bold">온라인 쇼핑몰이 없으신가요?</div>
                                                                    <div>오프라인 매장만 운영하는 경우에도(식당, 카페 등) 오모플레이를 이용할 수 있습니다. <span className="text-primary pointer underline">상담하기</span></div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        step > 0 && step < 4 && (
                                            <div className="rounded-[12px] px-[20px] py-[16px] bg-[rgba(247,247,249,1)]">
                                                <div className="flex-col gap-2">
                                                    <div className="text-primary font-semibold">준비 완료!</div>
                                                    <div className="flex-align-center gap-1 relative">
                                                        <li>
                                                            쇼핑몰 호스팅사 확인
                                                        </li>
                                                        <div className="w-[120px] border-b-2 border-[rgba(231,231,235,1)] border-dashed" />
                                                        <div className="flex gap-1 flex-1">
                                                            <img alt="check" src={CheckBlueIcon} />
                                                            <div>{hostingLabel}</div>
                                                        </div>
                                                    </div>
                                                    {
                                                        step > 1 && step < 4 && isBusiness && (
                                                            <div className="flex-align-center gap-1 relative">
                                                                <li>
                                                                    카카오 디벨로퍼 초대
                                                                </li>
                                                                <div className="w-[120px] border-b-2 border-[rgba(231,231,235,1)] border-dashed" />
                                                                <div className="flex gap-1 flex-1">
                                                                    <img alt="check" src={CheckBlueIcon} />
                                                                    <div>카카오 디벨로퍼에 오모플레이 계정 초대</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        step > 2 && step < 4 && (
                                                            <div className="flex-align-center gap-1 relative">
                                                                <li>
                                                                    카카오 채널 비즈니스 인증
                                                                </li>
                                                                <div className="w-[120px] border-b-2 border-[rgba(231,231,235,1)] border-dashed" />
                                                                <div className="flex gap-1 flex-1">
                                                                    <img alt="check" src={CheckBlueIcon} />
                                                                    <div>카카오 채널이 비즈니스 인증을 받았습니다.</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        step === 1 && (
                                            <>
                                                <div className="text-gray-900 font-bold text-[28px]">
                                                    오모플레이와 연동하려면<br/>
                                                    비즈니스 인증을 받은 카카오 채널이 필요합니다
                                                </div>
                                                <div className="flex-col gap-[16px]">
                                                    <div>
                                                        <div className="text-[16px] font-bold">연동할 카카오 채널이 있고, 비즈니스 인증을 받았습니까?</div>
                                                        <div className="text-tertiary">인증을 받았는지 불확실 하다면 하단의 가이드를 통해 확인해보세요.</div>
                                                    </div>
                                                    <div className="flex gap-2">
                                                        <Button variants={isBusiness ? "primary" : "outlined"} value={
                                                            <div className="flex gap-1">
                                                                <img src={isBusiness ? CheckWhiteBoldIcon : CheckBlueBoldIcon} alt="check_bold" />
                                                                <div>비즈니스 인증을 받았습니다</div>
                                                            </div>
                                                        } onClick={() => setBusiness(true)} />
                                                        <Button variants={isBusiness === false ? "danger" : "outlined"} value={
                                                            <div className="flex gap-1">
                                                                <img src={isBusiness === false ? CancelWhiteBoldIcon : CancelBoldIcon} alt="cancel_bold" />
                                                                <div>비즈니스 인증을 받지 않았습니다</div>
                                                            </div>
                                                        } onClick={() => setBusiness(false)} />
                                                    </div>
                                                </div>
                                                <Alert title="어떻게 확인할 수 있나요?" type="info" withIcon={true}>
                                                    <div className="flex-col gap-2">
                                                        <div className="text-[13px]">카카오 비즈니스에서 내 채널의 목록과 인증 여부를 확인할 수 있습니다. 더 자세한 내용은 아래 설정가이드를 확인해 보세요.</div>
                                                        <div className="flex gap-1 pointer" onClick={() => window.open("https://business.kakao.com/dashboard")}>
                                                            <div className="text-primary underline font-medium">카카오 비즈니스(새창으로 열기)</div>
                                                            <div><img src={OutLinkIcon} alt="outlink" /></div>
                                                        </div>
                                                        <div className="flex">
                                                            <Button variants="outlined" value={
                                                                <div className="flex-align-center">
                                                                    <img alt="" src={GuideIcon} width="16px" height="16px" />
                                                                    <div className="ml-2">신청 가이드 - 내 채널이 비즈니스 인증을 받았는지 알고 싶습니다</div>
                                                                </div>
                                                            } onClick={() => {
                                                                window.open("https://local-room-f00.notion.site/1132d9e7196a81ceb0f8f719e50aa7dc?pvs=4")
                                                            }} />
                                                        </div>
                                                    </div>
                                                </Alert>
                                            </>
                                        )
                                    }
                                    {
                                        step === 2 && (
                                            <>
                                                <div className="text-gray-900 font-bold text-[28px]">
                                                    이제 필요한 작업을 알려드리겠습니다
                                                </div>
                                                {
                                                    isBusiness ? (
                                                        <ValidCheckForm index={1} title="카카오 디벨로퍼에 오모플레이 계정을 EDITOR로 초대해 주세요"
                                                                        onChecked={(checked) => setValidBusinessSetting(checked)}
                                                        >
                                                            <div className="flex-col item gap-2 p-[24px]">
                                                                <div className="font-medium">아래의 신청 가이드를 먼저 확인해주세요</div>
                                                                <div className="flex">
                                                                    <Button variants="outlined" style={{borderColor: "var(--shape-primary)"}} value={
                                                                        <div className="flex-align-center">
                                                                            <img alt="" src={BookIcon} width="16px" height="16px" />
                                                                            <div className="ml-2 text-primary font-bold">신청 가이드 - 카카오 디벨로퍼에 오모플레이 계정 초대하기</div>
                                                                        </div>
                                                                    } onClick={() => {
                                                                        window.open("https://local-room-f00.notion.site/1132d9e7196a81ceb0f8f719e50aa7dc?pvs=4")
                                                                    }} />
                                                                </div>
                                                                <div className="border rounded-[12px] bg-white flex-center-all p-[16px] text-center font-medium">
                                                                    팀원으로 초대할 계정<br/>omoplay@kakao.com
                                                                </div>
                                                            </div>
                                                        </ValidCheckForm>
                                                    ) : (
                                                        <div className="flex-col gap-[23px]">
                                                            <ValidCheckForm index={1} title="카카오 채널의 비즈니스 인증을 받으세요"
                                                                            onChecked={(checked) => setValidNotBusinessSetting((prev) => {
                                                                                prev[0] = checked
                                                                                return [...prev]
                                                                            })}
                                                            >
                                                                <div className="flex-col item gap-2 p-[24px]">
                                                                    <div className="font-medium">아래의 신청 가이드를 먼저 확인해주세요</div>
                                                                    <div className="flex">
                                                                        <Button variants="outlined" style={{borderColor: "var(--shape-primary)"}} value={
                                                                            <div className="flex-align-center">
                                                                                <img alt="" src={BookIcon} width="16px" height="16px" />
                                                                                <div className="ml-2 text-primary font-bold">신청 가이드 - 카카오 채널 생성 및 비즈니스 인증 받기</div>
                                                                            </div>
                                                                        } onClick={() => {
                                                                            window.open("https://local-room-f00.notion.site/1132d9e7196a81ceb0f8f719e50aa7dc?pvs=4")
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            </ValidCheckForm>
                                                            <ValidCheckForm index={2} title={<div className="text-[16px] font-bold">카카오 비즈니스에서 채널에 오모플레이 계정을<br/>관리자(매니저)로 추가하세요</div>}
                                                                            onChecked={(checked) => setValidNotBusinessSetting((prev) => {
                                                                                prev[1] = checked
                                                                                return [...prev]
                                                                            })}
                                                            >
                                                                <div className="flex-col item gap-2 p-[24px]">
                                                                    <div className="font-medium">아래의 신청 가이드를 먼저 확인해주세요</div>
                                                                    <div className="flex">
                                                                        <Button variants="outlined" style={{borderColor: "var(--shape-primary)"}} value={
                                                                            <div className="flex-align-center">
                                                                                <img alt="" src={BookIcon} width="16px" height="16px" />
                                                                                <div className="ml-2 text-primary font-bold">신청 가이드 - 카카오 비즈니스에서 채널에 오모플레이 계정 초대하기</div>
                                                                            </div>
                                                                        } onClick={() => {
                                                                            window.open("https://local-room-f00.notion.site/1132d9e7196a81ceb0f8f719e50aa7dc?pvs=4")
                                                                        }} />
                                                                    </div>
                                                                    <div className="border rounded-[12px] bg-white flex-center-all p-[16px] text-center font-medium">
                                                                        팀원으로 초대할 계정<br/>omoplay@kakao.com
                                                                    </div>
                                                                </div>
                                                            </ValidCheckForm>
                                                            <ValidCheckForm index={3} title="카카오 디벨로퍼에 앱을 생성하세요"
                                                                            onChecked={(checked) => setValidNotBusinessSetting((prev) => {
                                                                                prev[2] = checked
                                                                                return [...prev]
                                                                            })}
                                                            >
                                                                <div className="flex-col item gap-2 p-[24px]">
                                                                    <div className="font-medium">아래의 신청 가이드를 먼저 확인해주세요</div>
                                                                    <div className="flex">
                                                                        <Button variants="outlined" style={{borderColor: "var(--shape-primary)"}} value={
                                                                            <div className="flex-align-center">
                                                                                <img alt="" src={BookIcon} width="16px" height="16px" />
                                                                                <div className="ml-2 text-primary font-bold">신청 가이드 - 카카오 디벨로퍼에 앱 생성하기</div>
                                                                            </div>
                                                                        } onClick={() => {
                                                                            window.open("https://local-room-f00.notion.site/1132d9e7196a81ceb0f8f719e50aa7dc?pvs=4")
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            </ValidCheckForm>
                                                            <ValidCheckForm index={4} title="카카오 디벨로퍼에 오모플레이 계정을 EDITOR로 초대해 주세요"
                                                                            onChecked={(checked) => setValidNotBusinessSetting((prev) => {
                                                                                prev[3] = checked
                                                                                return [...prev]
                                                                            })}
                                                            >
                                                                <div className="flex-col item gap-2 p-[24px]">
                                                                    <div className="font-medium">아래의 신청 가이드를 먼저 확인해주세요</div>
                                                                    <div className="flex">
                                                                        <Button variants="outlined" style={{borderColor: "var(--shape-primary)"}} value={
                                                                            <div className="flex-align-center">
                                                                                <img alt="" src={BookIcon} width="16px" height="16px" />
                                                                                <div className="ml-2 text-primary font-bold">신청 가이드 - 카카오 디벨로퍼에 오모플레이 계정 초대하기</div>
                                                                            </div>
                                                                        } onClick={() => {
                                                                            window.open("https://local-room-f00.notion.site/1132d9e7196a81ceb0f8f719e50aa7dc?pvs=4")
                                                                        }} />
                                                                    </div>
                                                                    <div className="border rounded-[12px] bg-white flex-center-all p-[16px] text-center font-medium">
                                                                        팀원으로 초대할 계정<br/>omoplay@kakao.com
                                                                    </div>
                                                                </div>
                                                            </ValidCheckForm>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        step === 3 && (
                                            <>
                                                <div className="text-gray-900 font-bold text-[28px]">
                                                    이제 마지막 단계입니다
                                                </div>
                                                <div className="text-gray-900 font-semibold text-lg mb-2">
                                                    결제 수단을 입력 해주세요
                                                </div>
                                                <CardBillingForm onSelectedCard={() => setExistsCardInfo(true)} />
                                            </>
                                        )
                                    }
                                    {
                                        step === 4 && (
                                            <div className="flex-col gap-[32px]">
                                                <div className="text-gray-900 font-bold text-[28px]">
                                                    최고의 CRM 파이프라인을<br/>
                                                    구축할 준비가 완료되었습니다
                                                </div>
                                                <div className="text-gray-900 font-semibold text-[20px]">
                                                    <span className="text-primary font-semibold text-[20px]">오모플레이 담당자</span>가 배정되어 서비스 연동작업이 시작됩니다.<br/>
                                                    활성화 까지는 약 1~3 영업일이 소요될 예정이며<br/>
                                                    필요한 경우 연동작업 도중에 담당자가 연락을 드릴 수 있습니다.
                                                </div>
                                                <div>
                                                    <img src={ConnectFin} alt="fin" />
                                                </div>
                                                <div className="text-gray-900 font-semibold text-[20px]">
                                                    연동작업이 완료되면 <span className="text-primary font-semibold text-[20px]">오모플레이 플친메시지</span>로 알려드립니다.
                                                    서비스 이용료는 연동작업이 완료된 날을 기준으로 과금이 시작됩니다.
                                                </div>
                                                <div>
                                                    <Alert title="확인해 주세요." type="warning" withIcon={true}>
                                                        <label className="text-[13px]">
                                                            첫달 이용료는 첫 이용일에 1달 요금이 우선 결제된 후, 다음달 1일날 실제 사용한 날짜 만큼<br/>
                                                            일할계산된 요금을 제외한 금액이 자동으로 부분 취소 됩니다. 다음달 부터 이용료는 매달 1일에 결제가 자동으로 이뤄집니다.<br/>
                                                            기타 서비스 이용관련 및 이용 요금에 대한 문의는 영업 담당자를 통해 문의하시기 바랍니다.
                                                        </label>
                                                    </Alert>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className="flex justify-end gap-2 mt-[12px]">
                                        {
                                            step > 0 && (<Button variants="outlined" value="이전" onClick={() => changeStep(step - 1)} />)
                                        }
                                        <Button variants="primary" disabled={submitDisabled} value={confirmName} onClick={() => {
                                            if (step === 0) {
                                                updateHostingPlatform(id, hostingName, hostingId, hostingPwd)
                                            } else if (step === 4) {
                                                postConnectRegistration().then(() => {
                                                    alert("신청이 완료되었습니다.")
                                                    window.location.href = "/services"
                                                })
                                            }
                                            changeStep(step + 1)
                                        }} />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </OMOSettingsLayout>
    );
};
export default OMOConnectSettingPage;
