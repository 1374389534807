import {Box} from "@mui/material";
import {Add, CloseOutlined} from "@mui/icons-material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {messageFileUpload} from "../../../api/file/file.api";

const CarouselImageForm = ({index, activeIndex, imgUrl, onChangeImage, onChangeActiveIndex, onDelete}) => {
    const id = "carousel-item-image-" + index

    const uploadImage = (e) => {
        const file = e.target.files[0]
        if (!file) return

        messageFileUpload(
            "carousel",
            e.target.files[0],
            (res) => {
                if (res.status === 200) {
                    const data = res.data;
                    if (data.code === "0") {
                        onChangeImage(res.data.imageUrl);
                    } else {
                        alert(data.message);
                    }
                } else {
                    console.error(res.data);
                    alert(
                        res.status +
                        "에러가 발생했습니다. 헤드리스 담당자에게 문의 해주세요."
                    );
                }
            },
            (error) => {
                alert(error);
            }
        );
    }

    return (
        <Box
            key={"carousel-header-item-" + index}
            className={`clickable ${activeIndex === index && "active"}`}
            onClick={() => onChangeActiveIndex(index)}
        >
            <Box sx={{ textAlign: "right" }}>
                <CloseOutlined
                    fontSize="12px"
                    onClick={() => onDelete()}
                />
            </Box>
            <input
                accept="image/jpg, image/jpeg, image/png"
                style={{ display: "none" }}
                id={id}
                type="file"
                onChange={(e) => uploadImage(e)}
            />

            {imgUrl ? (
                <Box
                    className="carousel-image-box uploaded"
                    sx={{
                        backgroundImage: `url(${imgUrl})`,
                    }}
                >
                    <label htmlFor={id}>
                        <ModeEditOutlineOutlinedIcon
                            sx={{
                                color: "white",
                                opacity: 0.7,
                            }}
                        />
                    </label>
                </Box>
            ) : (
                <Box className="carousel-image-box clickable">
                    <label htmlFor={id}>
                        <Add />
                    </label>
                </Box>
            )}
        </Box>
    )
}

export default CarouselImageForm