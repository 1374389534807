import InputGroup from "../../../form/InputGroup";
import {MESSAGE_TYPES} from "../../../../constants/menus";
import {RadioGroup, Tab, TextInput} from "omoplay";
import TypeSelectionForm from "../../../form/TypeSelectionForm";
import Badge from "../../badge/Badge";
import React, {useState} from "react";
import KakaoIcon from "../../../../assets/images/kakao_icon.svg";
import BrandDetail from "../../../page/BrandDetail";

export const AIMessageDefaultInfo = ({campaignName, type, isNotEdit, onChangeCampaignName, onChangeMessageType}) => {
  const [tab, setTab] = useState("campaign")

  const tabBarItems = [
    {
      label: <div className="flex gap-4"><div>켐페인 정보</div>{(!campaignName) && <Badge name="설정 필요" color="red" />}</div>,
      value: "campaign",
      style: {flex: "1"},
      selected: true
    },
    {
      label: <div className="flex gap-4"><div>브랜드 정보</div></div>,
      value: "brand",
      style: {flex: "1"}
    }
  ]

  return (
    <>
      <div className="flex-col gap-2">
        <Tab variants="sub" items={tabBarItems} onChanged={(item) => setTab(item.value)} />
        {
          tab === "campaign" && (
              <>
                <InputGroup label="메시지 캠페인명">
                  <TextInput
                      value={campaignName}
                      placeholder="다른 메시지들과 구분하기 위한 캠페인명을 작성해 주세요."
                      maxLength={40}
                      onChange={(e) => onChangeCampaignName(e.target.value)}
                  />
                </InputGroup>
                <InputGroup label="메시지 타입" description="어떤 메시지를 보낼지 골라주세요">
                  <RadioGroup items={[{label: (<div className="flex-align-center gap-2"><img src={KakaoIcon} alt="ft" /><div>친구톡</div></div>), value: "ft"}]} onSelected={() => {}} />
                </InputGroup>
                <InputGroup label="친구톡 타입 고르기" description={
                  <div>
                    어떤 메시지를 보내실지 골라주세요.
                    {
                        isNotEdit && (<span className="ml-2 text-red-500">* 이미 AI 메시지가 생성된 경우 수정할 수 없습니다.</span>)
                    }</div>
                }>
                  <TypeSelectionForm types={MESSAGE_TYPES} selectedType={type === "FT" ? "FI" : type} disabled={isNotEdit} onChangeType={onChangeMessageType} />
                </InputGroup>
              </>
            )
        }
        {
          tab === "brand" && (<>
              <InputGroup label="브랜드 정보" description="AI 생성 시 브랜드 내용을 참고합니다. 반드시 저장하기를 눌러야 반영 됩니다.">
                <BrandDetail />
              </InputGroup>
            </>)
        }
      </div>
    </>
  );
};

export default AIMessageDefaultInfo;
