import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft,} from "@fortawesome/free-solid-svg-icons";
import {useSearchParams} from "react-router-dom";
import {getDetailProject, postNameProject} from "../../../api/project/project.api";
import {Button, CoinIcon, MouseIcon, Panel, ProjectIcon, Tab, Tag, TextInput, UserIcon} from "omoplay";
import {useEffect, useState} from "react";
import ProjectDetailSummary from "./ProjectDetailSummary";
import GraphStatistics from "../GraphStatistics";
import EntryPointStatistics from "../EventStatistics";
import BenefitStatistics from "../BenefitStatistics";
import QuantitySettings from "./QuantitySettings";
import EditPenIcon from "../../../assets/images/icon.edit.svg"
import RevisitSettings from "./RevisitSettings";

export const ProjectDetail = () => {
  const [query] = useSearchParams();
  const [project, setProject] = useState({})
  const [tab, setTab] = useState("summary")
  const [isEditName, setIsEditName] = useState(false)
  const [editProjectName, setEditProjectName] = useState("")
  const id = query.get("id")
  const tabItems = [
    { label: "개요", value: "summary", selected: true},
    { label: "수량 설정", value: "quantity"},
    { label: "혜택 성과", value: "benefitStatistics"},
    { label: "고객 분석", value: "userStatistics"},
    { label: "진입점 분석", value: "eventStatistics"},
    //{ label: "재방문 이벤트", value: "revisit"}
  ]

  useEffect(() => {
    getDetailProject(id).then(({data}) => {
      setProject(data)
      setEditProjectName(data.name)
    })
  }, [id])

  const tabRender = () => {
    if (tab === "summary") {
      return <ProjectDetailSummary projectId={id} project={project}
                                   benefitPassword={project.benefitPassword} />
    } else if (tab === "quantity") {
      return <QuantitySettings projectId={id} />
    } else if (tab === "benefitStatistics") {
      return <BenefitStatistics projectId={id} />
    } else if (tab === "userStatistics") {
      return <GraphStatistics periodStartDate={project.startDate} projectId={id} />
    } else if (tab === "eventStatistics") {
      return <EntryPointStatistics periodStartDate={project.startDate} projectId={id} />
    } else if (tab === "revisit") {
      return <RevisitSettings projectId={id} />
    }
  }

  return (
      <div className="flex-col gap-4">
        <div className="flex-align-center gap-2">
          <div><Tag color={project.badgeColor} >{project.statusKor}</Tag></div>
          <div className="text-grey-7">{project.period}</div>
        </div>
        <div>
          <div className="flex gap-4 items-center mb-2">
            <div className="bg-white" style={{borderRadius: "6px"}}>
              <Button variants="outlined"
                      style={{width: "40px"}}
                      onClick={() => window.location.href = ("/project")}
                      value={<FontAwesomeIcon icon={faArrowLeft} />}
              />
            </div>
            {
              isEditName ? (
                  <div>
                    <TextInput value={editProjectName}
                               onChange={(e) => setEditProjectName(e.target.value)}
                               onKeyUp={(e) => {
                                 if (e.key === "Enter") {
                                   postNameProject(project.id, editProjectName).then(() => {
                                     project.name = editProjectName
                                     setIsEditName(false)
                                   })
                                 } else if (e.key === "Escape") {
                                   setIsEditName(false)
                                 }
                               }}
                    />
                  </div>
              ) : (<h4 className="font-extrabold" style={{fontSize: "28px"}}>{project.name}</h4>)
            }
            <div className="pointer" onClick={() => {
              if (isEditName) {
                postNameProject(project.id, editProjectName).then(() => {
                  project.name = editProjectName
                  setIsEditName(false)
                })
              } else {
                setIsEditName(true)
              }
            }}>
              <img alt="" src={EditPenIcon} />
            </div>
          </div>
          <div className="flex-col gap-20 mt-8">
            <Panel items={[
              {
                title: (
                    <>
                      <div className='panel-icon'>
                        <ProjectIcon />
                      </div>
                      <p>프로젝트</p>
                    </>
                ),
                value: (project.days >= 0 ? `${project.days + 1}일차` : `D-${Math.abs(project.days)}일`),
                suffix:
                    (project.remainDays !== null && project.remainDays >= 0) ? (
                        <div style={{fontSize: "24px"}}>
                          ({project.remainDays}일 남음)
                        </div>
                    ) : <></>,
              },
              {
                title: (
                    <>
                      <div className='panel-icon'>
                        <UserIcon />
                      </div>
                      <p>참여 고객</p>
                    </>
                ),
                value: project.joinUserNum?.toLocaleString(),
                suffix: '명',
              },
              {
                title: (
                    <>
                      <div className='panel-icon'>
                        <MouseIcon />
                      </div>
                      <p>노출 수</p>
                    </>
                ),
                value: project.totalViews?.toLocaleString(),
                suffix: '회',
              },
              {
                title: (
                    <>
                      <div className='panel-icon'>
                        <CoinIcon />
                      </div>
                      <p>마케팅 비용</p>
                    </>
                ),
                value: project.totalMarketingPrice?.toLocaleString(),
                suffix: '원',
              },
            ]} />
          </div>
        </div>
        <div className="flex-col gap-4 pt-[24px]">
          <div style={{width: "100%"}}>
            <Tab variants="default"
                 items={tabItems}
                 onChanged={(item) => {
                     setTab(item.value)
                 }}
                 style={{gap: "4px"}}
            />
          </div>
          <div>
            {
              tabRender()
            }
          </div>
          <div style={{height: "60px"}}></div>
        </div>
      </div>
  );
};
export default ProjectDetail;
