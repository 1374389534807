import {Button, RadioGroup, TextInput} from "omoplay";
import InputGroup from "../InputGroup";
import {useEffect, useState} from "react";
import {getProjectRevisit, postProjectRevisitEvent} from "../../../api/project/project.api";

const RevisitSettings = ({projectId}) => {
    const [used, setUsed] = useState(false)
    const [minPoint, setMinPoint] = useState("0")
    const [maxPoint, setMaxPoint] = useState("0")

    useEffect(() => {
        getProjectRevisit(projectId).then(({data}) => {
            setUsed(data.used)
            setMinPoint(data.minPoint.toString())
            setMaxPoint(data.maxPoint.toString())
        })
    }, [projectId]);


    const saveRevisit = () => {
        postProjectRevisitEvent(projectId, used, minPoint, maxPoint).then(() => {
            alert("저장 되었습니다.")
        })
    }

    return (
        <div className="flex-col gap-4">
            <div className="font-bold font-20px">재방문 이벤트</div>
            <div className="flex gap-2 font-16px">
                <div className="font-bold">재방문 이벤트 사용</div>
            </div>
            <div className="bg-white p-20px radius-6">
                <RadioGroup items={[{label: "미사용", value: "disabled"}, {label: "사용", value: "enabled"}]}
                            key={used ? "enabled" : "disabled"}
                            initChecked={used ? "enabled" : "disabled"}
                            onSelected={(value) => setUsed(value === "enabled")}
                />
            </div>
            {
                used && (
                    <div>
                        <InputGroup
                            label={"당첨 포인트 설정"}
                            isRequired={true}
                            className="py-8"
                        >
                            <div className="flex-col gap-4 bg-white p-20px">
                                <div className="flex-align-center gap-4">
                                    <div>최소 획득 포인트</div>
                                    <TextInput type="numeric" value={minPoint} onChange={(e) => setMinPoint(e.target.value)} />
                                </div>
                                <div className="flex-align-center gap-4">
                                    <div>최대 획득 포인트</div>
                                    <TextInput type="numeric" value={maxPoint} onChange={(e) => setMaxPoint(e.target.value)} />
                                </div>
                            </div>
                        </InputGroup>
                    </div>
                )
            }
            <div>
                <Button variants="solid" value="저장하기" onClick={() => saveRevisit()} />
            </div>
        </div>
    )
}

export default RevisitSettings