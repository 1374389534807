import InputGroup from "../InputGroup";
import {Button, CalendarBar, Dropdown, RadioGroup, TextInput} from "omoplay";
import {Divider} from "@mui/material";
import {useEffect, useState} from "react";
import moment from "moment";
import ProjectDuplicateLink from "./ProjectDuplicateLink";
import SuspensePopup from "../../SuspensePopup";
import ProjectSimpleList from "./ProjectSimpleList";
import {DROP_DOWN_HOURS, DROP_DOWN_MINUTES} from "../../Const";

export const ProjectDefault = ({project, onChangeProjectName, onChangeStartDate, onChangeEndDate, onChangeDuplicateProject}) => {
    const [type, setType] = useState("always")
    const startDate = project.startDate ? moment(project.startDate) : moment().hours(0).minutes(0)
    const endDate = project.endDate ? moment(project.endDate) : moment().hours(0).minutes(0)
    const duplicateProjectList = project.duplicateProjects ?? []
    const [showDuplicateProject, setShowDuplicateProject] = useState(false)
    const format = "yyyy년 MM월 DD일 HH시 mm분"

    useEffect(() => {
        setType(project.endDate == null ? "always" : "period")
    }, [project.endDate]);

    return (
        <>
          <InputGroup
              label={"프로젝트 이름"}
              isRequired={true}
              className="py-8"
          >
            <p className="text-gray-500 text-sm mb-2">
              프로젝트 이름을 입력해 주세요
            </p>
            <div style={{width: "640px"}}>
              <TextInput
                  value={project.name}
                  placeholder="프로젝트 이름을 입력해 주세요."
                  onChange={(e) => onChangeProjectName(e.target.value)}
                  maxLength={20}
              />
            </div>
          </InputGroup>
          <Divider sx={{ mt: 2, mb: 4 }} />
          <InputGroup
              label={"프로젝트 기간"}
              isRequired={true}
              className="py-8"
          >
            <p className="text-gray-500 text-sm mb-2">
              프로젝트 기간을 설정해 주세요
            </p>
              <div className="flex-col gap-4">
                  <div className="flex justify-evenly p-4 bg-mainblue-l4 rounded-lg gap-8" style={{width: "fit-content", minWidth: "400px"}}>
                      <div>프로젝트 기간</div>
                      <div style={{borderLeft: "1px solid var(--grey-2)"}}></div>
                      <div className="flex gap-2">
                          <div>{`${startDate.format(format)} ~ ${type === "always" ? '상시 진행' : endDate.format(format)}`}</div>
                          {
                              type === "period" && (
                                  <div className="text-mainblue-0 font-semibold">({endDate.diff(startDate, 'days')}일간)</div>
                              )
                          }
                      </div>
                  </div>
                  <div>
                      <RadioGroup key={project.endDate} items={[
                          { label: "상시 진행", value: "always" },
                          { label: "기간 설정", value: "period" }
                      ]} initChecked={type} onSelected={(value) => {
                          setType(value)
                          if (value === "always") {
                              onChangeEndDate(null)
                          } else {
                              onChangeEndDate(moment().hours(0).minutes(0))
                          }
                      }} />
                  </div>
                  <div className="flex-align-center gap-4">
                      <div>시작 날짜</div>
                      <div>
                          <CalendarBar
                              initDate={{
                                  startDate: startDate.toDate(),
                                  endDate: startDate.toDate()
                                }}
                              multiple={false}
                              minDate={new Date()}
                              onConfirm={({startDate}) => {
                                   onChangeStartDate(moment(startDate))
                               }}
                          />
                      </div>
                      <div className="flex items-center gap-4">
                          <div>
                              <Dropdown key={startDate}
                                        variants="default"
                                        initialValue={startDate.hours().toString()}
                                        items={DROP_DOWN_HOURS}
                                        onChanged={(items) => {
                                            onChangeStartDate(startDate.hours(items[0].value))
                                        }} />
                          </div>
                          <div>
                              <Dropdown key={startDate}
                                        variants="default"
                                        initialValue={startDate.minutes().toString()}
                                        items={DROP_DOWN_MINUTES}
                                        onChanged={(items) => {
                                            onChangeStartDate(startDate.minutes(items[0].value))
                                        }} />
                          </div>
                      </div>
                      <div className="flex-center-all font-bold">~</div>
                      <div className="flex-align-center gap-4">
                          {
                              type === "always" ? (
                                  <div>상시 진행</div>
                              ) : (
                                  <>
                                      <div>종료 날짜</div>
                                      <div>
                                          <CalendarBar initDate={{
                                              startDate: endDate.toDate(),
                                              endDate: endDate.toDate()
                                          }} multiple={false} onConfirm={({endDate}) => {
                                              onChangeEndDate(moment(endDate))
                                          }} minDate={new Date()}
                                          />
                                      </div>
                                      <div>
                                          <Dropdown key={endDate}
                                                    variants="default"
                                                    initialValue={endDate.hours().toString()}
                                                    items={[
                                                        {label: "00시", value: "0"},{label: "01시", value: "1"},
                                                        {label: "02시", value: "2"},{label: "03시", value: "3"},
                                                        {label: "04시", value: "4"},{label: "05시", value: "5"},
                                                        {label: "06시", value: "6"},{label: "07시", value: "7"},
                                                        {label: "08시", value: "8"}, {label: "09시", value: "9"},
                                                        {label: "10시", value: "10"}, {label: "11시", value: "11"},
                                                        {label: "12시", value: "12"}, {label: "13시", value: "13"},
                                                        {label: "14시", value: "14"}, {label: "15시", value: "15"},
                                                        {label: "16시", value: "16"}, {label: "17시", value: "17"},
                                                        {label: "18시", value: "18"}, {label: "19시", value: "19"},
                                                        {label: "20시", value: "20"}, {label: "21시", value: "21"},
                                                        {label: "22시", value: "22"}, {label: "23시", value: "23"}
                                                    ]}
                                                    onChanged={(items) => {
                                                        onChangeEndDate(endDate.hours(items[0].value))
                                                    }}
                                                    disabled={type === "always"}
                                          />
                                      </div>
                                      <div>
                                          <Dropdown key={endDate}
                                                    variants="default"
                                                    initialValue={endDate.minutes().toString()}
                                                    items={[
                                                        {label: "00분", value: "0"}, {label: "05분", value: "5"},
                                                        {label: "10분", value: "10"}, {label: "15분", value: "15"},
                                                        {label: "20분", value: "20"}, {label: "25분", value: "25"},
                                                        {label: "30분", value: "30"}, {label: "35분", value: "35"},
                                                        {label: "40분", value: "40"}, {label: "45분", value: "45"},
                                                        {label: "50분", value: "50"}, {label: "55분", value: "55"},
                                                    ]}
                                                    onChanged={(items) => {
                                                        onChangeEndDate(endDate.minutes(items[0].value))
                                                    }}
                                                    disabled={type === "always"}
                                          />
                                      </div>
                                  </>
                              )
                          }
                      </div>
                  </div>
              </div>
          </InputGroup>
          <Divider sx={{ mt: 2, mb: 4 }} />
          <InputGroup
              label={"프로젝트 중복 불가 설정"}
              isRequired={false}
              className="py-8"
          >
              <div className="flex justify-between">
                  <div className="text-gray-500 text-sm mb-2">
                      총 <span className="text-mainblue-0 font-semibold">{duplicateProjectList.length}</span>개 프로젝트와는 중복으로 참여할 수 없습니다.
                  </div>
                  <div>
                      <Button variants="dark" value="프로젝트 추가" onClick={() => setShowDuplicateProject(true)} />
                  </div>
              </div>
            <div>
                {
                    duplicateProjectList.map(duplicateProject => {
                        return (
                            <ProjectDuplicateLink projectName={duplicateProject.name} onCancel={() => {
                                onChangeDuplicateProject(duplicateProjectList.filter(rProject => rProject.id !== duplicateProject.id))
                            }} />
                        )
                    })
                }
            </div>
          </InputGroup>
            {
                showDuplicateProject && (
                    <SuspensePopup
                        isEditPopup={true}
                        title={"중복 불가 프로젝트 추가하기"}
                        size={"w-[1200px]"}
                        onClose={() => setShowDuplicateProject(false)}
                    >
                        <ProjectSimpleList selectedProjectList={duplicateProjectList} excludeProjectId={project.id} onSelected={(selectProjectList) => {
                            onChangeDuplicateProject(selectProjectList)
                            setShowDuplicateProject(false)
                        }} />
                    </SuspensePopup>
                )
            }
        </>
    )
}

export default ProjectDefault
