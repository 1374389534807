import {Button} from "omoplay";
import ProjectDuplicateLink from "./ProjectDuplicateLink";
import {useState} from "react";
import SuspensePopup from "../../SuspensePopup";
import ProjectSimpleList from "./ProjectSimpleList";

const ProjectDetailDuplicate = ({projectId, duplicateProjects, onChange, onDelete}) => {
    const [showDuplicateProject, setShowDuplicateProject] = useState(false)

    return (
        <>
            <div className="flex-col gap-4">
                <div className="flex justify-between">
                    <div>
                        <div className="font-bold font-20px">중복 불가 프로젝트</div>
                    </div>
                    <div>
                        <Button variants="dark" value="프로젝트 추가" onClick={() => setShowDuplicateProject(true)} />
                    </div>
                </div>
                <div className="flex justify-between bg-white border radius-6 p-20px">
                    <div className="text-gray-500 text-sm mb-2">
                        총 <span className="text-mainblue-0 font-semibold">{duplicateProjects.length}</span>개 프로젝트와는 중복으로 참여할 수 없습니다.
                    </div>
                </div>
                <div>
                    {
                        duplicateProjects.map(duplicateProject => {
                            return (
                                <ProjectDuplicateLink projectName={duplicateProject.name} onCancel={() => {
                                    onDelete(duplicateProject.id)
                                }} />
                            )
                        })
                    }
                </div>
            </div>
            {
                showDuplicateProject && (
                    <SuspensePopup
                        isEditPopup={true}
                        title={"중복 불가 프로젝트 추가하기"}
                        size={"w-[1200px]"}
                        onClose={() => setShowDuplicateProject(false)}
                    >
                        <ProjectSimpleList selectedProjectList={duplicateProjects} excludeProjectId={projectId} onSelected={(selectProjectList) => {
                            onChange(selectProjectList.map(project => project.id))
                            setShowDuplicateProject(false)
                        }} />
                    </SuspensePopup>
                )
            }
        </>
    )
}

export default ProjectDetailDuplicate