import Loading from "./Loading";

const InfoButton = ({
  children = "",
  isLoading,
  type = "button",
  disabled = false,
  className = "",
  onClick,
}) => {
  return (
    <div className="flex items-center justify-between">
      <button
        className={
          "bg-gray-600 hover:bg-gray-700 text-white rounded focus:outline-none focus:shadow-outline w-full text-center disabled:bg-gray-400 transition " +
          className
        }
        type={type}
        disabled={disabled || isLoading}
        onClick={() => onClick()}
      >
        {isLoading ? (
          <div className="flex justify-center">
            <p className="text-white fill-indigo-500 m-auto">
              <Loading className="w-6 h-6" />
            </p>
          </div>
        ) : (
          children
        )}
      </button>
    </div>
  );
};
export default InfoButton;
