import InputGroup from "../InputGroup";
import TypeSelectionForm from "../TypeSelectionForm";
import {BENEFIT_TYPES,} from "../../../constants/menus";

const BenefitDefault = ({ benefitDetails, disabledBenefitType, onChangeBenefitType }) => {
  const benefitData = benefitDetails().benefit

  return (
      <InputGroup label={"혜택 타입"} description={<div className="text-red-400">{disabledBenefitType && "* 선택 후 저장된 혜택은 변경이 불가합니다."}</div>}>
        <TypeSelectionForm types={BENEFIT_TYPES} selectedType={benefitData.type} disabled={disabledBenefitType} onChangeType={onChangeBenefitType} />
      </InputGroup>
  )
};

export default BenefitDefault;
