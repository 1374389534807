import {CalendarBar, Tab} from "omoplay";
import moment from "moment";
import {useState} from "react";
import {getAllDateRange, getDefaultDateRange} from "../libs/helper";


const ProjectCalendarSearchBar = ({periodStartDate, onConfirm}) => {
    const dateItems = [
        { value: "all", label: "전체" },
        { value: "month", label: "최근 한 달" },
        { value: "week", label: "최근 일주일", selected: true },
    ]

    const [dateRange, setDateRange] = useState(getDefaultDateRange());

    const handleQuickChange = (type) => {
        let convertDateRage = dateRange
        const allDateRange = getAllDateRange()

        if (type === "all") {
            convertDateRage = {
                startDate: (periodStartDate ? moment(periodStartDate) : allDateRange.startDate).format("YYYY-MM-DD"),
                endDate: allDateRange.endDate,
            }
        } else if (type === "month") {
            convertDateRage = {
                startDate: allDateRange.startDate,
                endDate: allDateRange.endDate,
            }
        } else if (type === "week") {
            convertDateRage = {
                startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
                endDate: allDateRange.endDate,
            }
        }
        setDateRange(convertDateRage)
        onConfirm(convertDateRage)
    }

    return (
        <div className="flex gap-4">
            <Tab
                variants="input"
                items={dateItems}
                onChanged={(data) => handleQuickChange(data.value)}
            />
            <CalendarBar initDate={{startDate: moment(dateRange.startDate).toDate(), endDate: moment(dateRange.endDate).toDate()}}
                         onConfirm={(dateProps) => {
                             const convertDateRange = {
                                 startDate: moment(dateProps.startDate).format("yyyy-MM-DD"),
                                 endDate: moment(dateProps.endDate).format("yyyy-MM-DD")
                             }
                             setDateRange(convertDateRange)
                             onConfirm(convertDateRange)
                         }} />
        </div>
    )
}

export default ProjectCalendarSearchBar