import {Tab, TextInput, Toggle} from "omoplay";
import {useEffect, useState} from "react";
import CardBillingForm from "./CardBillingForm";

const AutoChargeAiCreditForm = ({minCharge, autoCharge, onOnCharge, onMinCharge, onAutoCharge, onSelectedCard}) => {
    const [on, setOn] = useState(true)
    const [localMinCharge, setLocalMinCharge] = useState(minCharge)
    const [localAutoCharge, setLocalAutoCharge] = useState(autoCharge)
    const [passiveMinCharge, setPassiveMinCharge] = useState(!["5000", "10000", "30000", "50000", "100000"].includes(localMinCharge.toString()))
    const [passiveAutoCharge, setPassiveAutoCharge] = useState(!["5000", "10000", "30000", "50000", "100000"].includes(localAutoCharge.toString()))
    const [errorMin, setErrorMin] = useState(false)
    const [errorCharge, setErrorCharge] = useState(false)

    useEffect(() => {
        onMinCharge(localMinCharge)
        onAutoCharge(localAutoCharge)
    }, [localMinCharge, localAutoCharge]);

    return (
        <div className="flex-col gap-[24px]">
            <div className="flex-align-center gap-2">
                <div>
                    <Toggle onToggle={(flag) => {
                        setOn(flag)
                        onOnCharge(flag)}
                    } value={on} />
                </div>
                <div className="text-[16px] font-semibold">예치금 자동 충전 사용</div>
            </div>
            <div className="border"></div>
            {
                on && (
                    <>
                        <div className="flex gap-1">
                            <div className="text-[16px] font-semibold">예치금이</div>
                            <div className="text-[16px] font-semibold underline">{localMinCharge.toLocaleString()} 원(KRW)</div>
                            <div className="text-[16px] font-semibold">이하이면</div>
                        </div>
                        <div className="flex gap-2">
                            <Tab variants="default"
                                 style={{gap: "4px"}}
                                 items={[
                                     {label: "5,000", value: "5000", selected: localMinCharge.toString() === "5000"},
                                     {label: "10,000", value: "10000", selected: localMinCharge.toString() === "10000"},
                                     {label: "30,000", value: "30000", selected: localMinCharge.toString() === "30000"},
                                     {label: "50,000", value: "50000", selected: localMinCharge.toString() === "50000"},
                                     {label: "10,0000", value: "100000", selected: localMinCharge.toString() === "100000"},
                                     {label: "직접 입력", value: "passive", selected: !["5000", "10000", "30000", "50000", "100000"].includes(localMinCharge.toString())},
                                 ]}
                                 onChanged={(item) => {
                                     const value = item.value
                                     setPassiveMinCharge(value === "passive")
                                     if (value !== "passive") {
                                         setLocalMinCharge(value)
                                     }
                                 }}
                            />
                        </div>
                        {
                            passiveMinCharge && (
                                <div className="flex-align-center gap-2">
                                    <TextInput type="numeric" value={localMinCharge}
                                               onChange={(e) => {
                                                   setLocalMinCharge(e.target.value)
                                                   setErrorMin(Number(e.target.value) < 1000)
                                               }}
                                    />
                                    <div className="text-[16px] font-semibold">원(KRW)</div>
                                    {
                                        errorMin && (<div className="text-red-500">1,000원(KRW) 이하로 지정할 수 없습니다.</div>)
                                    }
                                </div>
                            )
                        }
                        <div className="flex gap-1">
                            <div className="text-[16px] font-semibold underline">{localAutoCharge.toLocaleString()} 원(KRW)</div>
                            <div className="text-[16px] font-semibold">을 자동으로 충전</div>
                        </div>
                        <div className="flex gap-2">
                            <Tab variants="default"
                                 style={{gap: "4px"}}
                                 items={[
                                     {label: "5,000", value: "5000", selected: localAutoCharge.toString() === "5000"},
                                     {label: "10,000", value: "10000", selected: localAutoCharge.toString() === "10000"},
                                     {label: "30,000", value: "30000", selected: localAutoCharge.toString() === "30000"},
                                     {label: "50,000", value: "50000", selected: localAutoCharge.toString() === "50000"},
                                     {label: "10,0000", value: "100000", selected: localAutoCharge.toString() === "100000"},
                                     {label: "직접 입력", value: "passive", selected: !["5000", "10000", "30000", "50000", "100000"].includes(localAutoCharge.toString())},
                                 ]}
                                 onChanged={(item) => {
                                     setPassiveAutoCharge(item.value === "passive")
                                     if (item.value !== "passive") {
                                         setLocalAutoCharge(item.value)
                                     }
                                 }}
                            />
                        </div>
                        {
                            passiveAutoCharge && (
                                <div className="flex-align-center gap-2">
                                    <TextInput type="numeric" value={localAutoCharge}
                                               onChange={(e) => {
                                                   setLocalAutoCharge(e.target.value)
                                                   setErrorCharge(Number(e.target.value) < 5000)
                                               }} />
                                    <div className="text-[16px] font-semibold">원(KRW)</div>
                                    {
                                        errorCharge && (<div className="text-red-500">5,000원(KRW) 이하로 지정할 수 없습니다.</div>)
                                    }
                                </div>
                            )
                        }
                        <div className="border"></div>
                        <CardBillingForm onSelectedCard={onSelectedCard} />
                    </>
                )
            }
        </div>
    )
}

export default AutoChargeAiCreditForm