import moment from "moment";

export const defaultLineBarChartConfig = {
    type: "line",
    height: 350,
    series: [
        {
            name: "가입수",
            type: "column",
            data: [],
        },
    ],
    options: {
        chart: {
            toolbar: {
                show: true,
                tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: true,
                    reset: false
                }
            },
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: "13px",
                            fontWeight: 900,
                        },
                    },
                    position: "top",
                },
            },
        },
        stroke: {
            width: [4],
        },
        dataLabels: {
            enabled: true,
            background: false,
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#008ffb"],
            },
        },
        xaxis: {
            type: "date",
        },
        yaxis: {
            labels: {
                formatter: (value) => {
                    return Math.floor(value);
                },
            },
        },
    },
};

export const defaultPieChartConfig = {
    type: "pie",
    height: 250,
    series: [],
    options: {
        chart: {
            width: 250,
            type: "pie",
        },
        colors: [
            "#62B2FD",
            "#9BDFC4",
            "#F99BAB",
            "#FFB44F",
            "#a3e635",
            "#6ee7b7",
            "#67e8f9",
            "#93c5fd",
            "#a78bfa",
            "#f472b6",
            "#53836d",
            "#7283f4",
            "#b6e733",
            "#d03520",
            "#800d0d",
            "#2bb65b",
            "#055964",
            "#480264",
            "#8a06f5",
            "#fffe7e",
        ],
        labels: [],
        legend: {
            show: false,
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                },
            },
        ],
    },
};

export const defaultBarChartConfig = {
    type: "line",
    height: 350,
    series: [
        {
            name: "혜택 발급수",
            type: "column",
            data: [],
        },
        {
            name: "혜택 사용수",
            type: "column",
            data: [],
        },
    ],
    options: {
        chart: {
            toolbar: {
                show: true,
                tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: true,
                    reset: false
                }
            },
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: "13px",
                            fontWeight: 900,
                        },
                    },
                    position: "top",
                },
            },
        },
        fill: {
            opacity: [0.85, 0.5],
            gradient: {
                inverseColors: false,
                shade: "light",
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
            },
        },
        stroke: {
            width: [4, 4],
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#008ffb", "#00e397"],
            },
        },
        xaxis: {
            type: "date",
        },
        yaxis: [
            {
                title: {
                    text: "혜택 발급수 / 혜택 사용수",
                },
                type: "numeric",
                labels: {
                    formatter: (value) => {
                        return Math.floor(value);
                    },
                },
            },
        ],
    },
};

export const userBarChartConfig = {
    type: "line",
    height: 350,
    series: [
        {
            name: "발송수",
            type: "column",
            data: [],
        },
        {
            name: "클릭수",
            type: "column",
            data: [],
        },
    ],
    options: {
        chart: {
            toolbar: {
                show: true,
                tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: true,
                    reset: false
                }
            },
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: "13px",
                            fontWeight: 900,
                        },
                    },
                    position: "top",
                },
            },
        },
        fill: {
            opacity: [0.85, 0.5],
            gradient: {
                inverseColors: false,
                shade: "light",
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
            },
        },
        stroke: {
            width: [4, 4],
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#008ffb", "#00e397"],
            },
        },
        xaxis: {
            type: "category",
        },
        yaxis: [
            {
                title: {
                    text: "",
                },
                labels: {
                    formatter: (value) => {
                        return Math.floor(value);
                    },
                },
            },
        ],
    },
};

export const userLineChartConfig = {
    type: "line",
    height: 350,
    series: [
        {
            name: "클릭수",
            type: "line",
            color: "#ff9930",
            data: [],
        },
    ],
    options: {
        chart: {
            toolbar: {
                show: true,
                tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: true,
                    reset: false
                }
            },
            zoom: {
                enabled: true
            }
        },
        stroke: {
            width: [3],
        },
        dataLabels: {
            enabled: true,
            background: {
                enabled: false
            },
            offsetY: -10,
            style: {
                fontSize: "12px",
                colors: ["#ff9930"],
            },
            formatter: function (value) {
                return value === 0 ? '' : value;
            }
        },
        xaxis: {
            type: "category",
            labels: {
                formatter: (value) => {
                    return moment(new Date(value)).format("DD일 HH:00")
                }
            }
        },
        yaxis: {
        },
    },
};