import { useEffect, useState } from "react";
import TabTitle from "../../TabTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {getEvent} from "../../../api/event/event.api";
import EventLoginPreview from "./EventLoginPreview";
import EventRoulettePreview from "./EventRoulettePreview";
import EventPrizePreview from "./EventPrizePreview";
import EventLoginRoulettePreview from "./EventLoginRoulettePreview";
import GrayButton from "../../GrayButton";
import EventLoginPrizePreview from "./EventLoginPrizePreview";

const EventPreview = ({ id }) => {
  const [tabs, setTabs] = useState([])
  const [tab, setTab] = useState(1);
  const [event, setEvent] = useState({})
  const [renderPreview, setRenderPreview] = useState(<></>)

  useEffect(() => {
    getEvent(id).then(({data}) => {
      setEvent(data)
    })
  }, [id]);

  useEffect(() => {
    const renderByTab = () => {
      if (event.type === "roulette" && event.loginType === "registration") {
        setTabs([
          { label: "로그인", value: 1 },
          { label: "룰렛", value: 2 },
          { label: "당첨 안내", value: 3 },
        ])
        if (tab === 1) {
          return (
              <EventLoginPreview loginBackgroundImage={event.loginBackgroundImage}
                                 loginButtonName={event.loginButtonName ?? ""}
                                 loginPrecautionContent={event.loginPrecautionContent ?? ""}
                                 loginPrecautionName={event.loginPrecautionName ?? ""}
              />
          )
        } else if (tab === 2) {
          return (
              <EventRoulettePreview eventBackgroundImage={event.eventBackgroundImage}
                                    eventImage={event.eventImage}
                                    eventButtonImage={event.eventButtonImage}
              />
          )
        } else if (tab === 3) {
          return (
              <EventPrizePreview eventBackgroundImage={event.eventBackgroundImage}
                                 eventImage={event.eventImage}
                                 eventButtonImage={event.eventButtonImage}
                                 prizePrecautionContent={event.prizePrecautionContent}
                                 additionalButtons={event.additionalButtons}
              />
          )
        }
      } else if (event.type === "roulette" && event.loginType === "event") {
        setTabs([
          { label: "룰렛", value: 1 },
          { label: "로그인", value: 2 },
        ])
        if (tab === 1) {
          return (
              <EventLoginRoulettePreview eventImage={event.eventImage}
                                         eventButtonImage={event.eventButtonImage}
                                         eventBackgroundImage={event.eventBackgroundImage}
                                         loginButtonName={event.loginButtonName}
                                         loginPrecautionContent={event.loginPrecautionContent}
                                         loginPrecautionName={event.loginPrecautionName}
              />
          )
        } else if (tab === 2) {
          return (
              <EventLoginPrizePreview eventBackgroundImage={event.eventBackgroundImage}
                                      eventImage={event.eventImage}
                                      eventButtonImage={event.eventButtonImage}
                                      loginButtonName={event.loginButtonName}
                                      prizePrecautionContent={event.prizePrecautionContent} />
          )
        }
      }
    }
    setRenderPreview(renderByTab())
  }, [event, tab]);

  return (
    <div className="flex-col items-center gap-4 py-8">
      <TabTitle tabs={tabs} activeTab={tab} />
      <div>{renderPreview}</div>
      <div className="flex gap-4">
        <GrayButton className="px-1.5 z-[1000]" disabled={tab === 1}
                    onClick={() => {
                      setTab(tab - 1)
                    }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </GrayButton>
        <GrayButton className="px-1.5 z-[1000]" disabled={tab === tabs.length}
                    onClick={() => {
                      setTab(tab + 1)
                    }}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </GrayButton>
      </div>
    </div>
  );
};
export default EventPreview;
