import Layout from "../components/layout/Layout";
import CreditDetail from "../components/CreditDetail";

const CreditDetailPage = () => {
  return (
    <Layout>
      <CreditDetail />
    </Layout>
  );
};
export default CreditDetailPage;
