import ActiveButton from "./ActiveButton";
import ContentMask from "./ContentMask";
import InfoButton from "./InfoButton";
import NormalButton from "./NormalButton";

const Popup = ({
  title,
  content,
  onClose = () => {},
  withoutMask = false,
  buttons = [{ label: "닫기", type: "info", onClick: () => onClose() }],
}) => {
  const renderButton = (button) => {
    const commonClass = "px-8 py-2";
    if (button.type === "active") {
      return (
        <ActiveButton className={commonClass} onClick={button.onClick}>
          {button.label}
        </ActiveButton>
      );
    } else if (button.type === "info") {
      return (
        <InfoButton className={commonClass} onClick={button.onClick}>
          {button.label}
        </InfoButton>
      );
    } else {
      return (
        <NormalButton className={commonClass} onClick={button.onClick}>
          {button.label}
        </NormalButton>
      );
    }
  };
  const renderPopup = () => {
    return (
      <>
        <h4 className="font-semibold w-100 text-center text-lg">{title}</h4>
        {content && (
          <div className="my-4 py-3 px-4 bg-gray-100 rounded font-thin">
            <p className="text-sm">{content}</p>
          </div>
        )}
        <div className={`w-100 mx-auto mt-8 flex gap-4 justify-center`}>
          {buttons.map((button) => renderButton(button))}
        </div>
      </>
    );
  };
  if (withoutMask) {
    return (
      <div
        className={
          "overflow-y-auto overflow-x-hidden absolute top-12 right-0 left-0 z-50 flex justify-center items-center w-full h-[calc(100%-54px)] max-h-full"
        }
      >
        <div className="relative w-full max-w-sm max-h-full">
          <div className="relative bg-white rounded-lg py-10 px-8 shadow-2xl border border-gray-300">
            {renderPopup()}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <ContentMask>
        <div className="relative w-full max-w-sm max-h-full">
          <div className="relative bg-white rounded-lg py-10 px-8">
            {renderPopup()}
          </div>
        </div>
      </ContentMask>
    );
  }
};
export default Popup;
