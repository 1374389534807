export default function Tooltip({ message, children, top, left = null }) {
  return (
    <div className="group relative flex">
      {children}
      <span
        className={
          "absolute scale-0 whitespace-nowrap w-fit z-10 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 " +
          (top ? top + " " : "top-10 ") +
          (left ? left : "")
        }
      >
        {message}
      </span>
    </div>
  );
}
