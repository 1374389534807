import { useRecoilValue } from "recoil";
import { spotSelector } from "../store/selector/spot";
const PromotionTable = ({ data, totalPrice }) => {
  const sumCount = (data) => data.reduce((a, b) => b.count + a, 0);
  const spots = useRecoilValue(spotSelector);
  const headerStyle =
    "whitespace-nowrap sticky left-0 px-6 text-gray-500 border-b py-2 font-normal";
  return (
    <table className="w-full text-left rtl:text-right text-gray-500 bg-white">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 rounded-tl border-b divide-y">
        <tr className="divide-x text-center">
          <th className={headerStyle}>혜택</th>
          <th className={headerStyle}>원가</th>
          <th className={headerStyle}>발급</th>
          <th className={headerStyle}>사용</th>
          <th className={headerStyle}>사용률</th>
          <th className={headerStyle}>총 사용 비용</th>
        </tr>
      </thead>
      <tbody className="divide-y">
        {data.items?.map((item) => {
          const issuedCount = sumCount(
            data.results.map((result) => {
              return {
                count:
                  sumCount(
                    result.issueItems.filter((row) => row.benefitId === item.id)
                  ) || 0,
              };
            })
          );

          const usedCount = sumCount(
            data.results.map((result) => {
              return {
                count:
                  sumCount(
                    result.usedItems.filter((row) => row.benefitId === item.id)
                  ) || 0,
              };
            })
          );
          return (
            <tr
              key={"promotion-" + item.id}
              className="text-center divide-x text-gray-600"
            >
              <td className="py-2 text-left pl-4 font-bold">{item.name}</td>
              <td>₩{item.supplyPrice?.toLocaleString()}</td>
              <td>{issuedCount}</td>
              <td>{usedCount}</td>
              <td>{Math.round((usedCount / issuedCount) * 1000) / 10 || 0}%</td>
              <td>₩{(item.supplyPrice * usedCount).toLocaleString()}</td>
            </tr>
          );
        })}
        <tr className="bg-gray-100">
          <th className="my-2 ml-4 block text-sm border-r border-r-gray-300 text-gray-700">
            총합
          </th>
          <th className=""></th>
          <th className=""></th>
          <th className=""></th>
          <th className=""></th>
          <td className=" text-center text-gray-700 font-bold">
            ₩{totalPrice.toLocaleString()}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PromotionTable;
