import Layout from "../components/layout/Layout";
import PointMenuList from "../components/page/PointMenuList";

const EventPage = () => {
  return (
    <Layout>
      <PointMenuList />
    </Layout>
  );
};
export default EventPage;
