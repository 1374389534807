import { useCallback, useEffect, useMemo, useState } from "react";
import { getSpotStatistics } from "../api/benefit/statistics.api";
import DateStatistics from "./DateStatistics";
import Loading from "./Loading";
import PromotionTable from "./PromotionTable";

const PromotionCard = ({ spot, startDate, endDate }) => {
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const sumCount = (data) => data.reduce((a, b) => b.count + a, 0);
    const totalPrice = useMemo(() => {
        return (
            data.items
                ?.map((item) => {
                    return (
                        sumCount(
                            data.results.map((result) => {
                                return {
                                    count:
                                        sumCount(
                                            result.usedItems.filter(
                                                (row) =>
                                                    row.benefitId === item.id
                                            )
                                        ) || 0,
                                };
                            })
                        ) * item.supplyPrice
                    );
                })
                .reduce((a, b) => (b + a), 0) || 0
        );
    }, [data]);

    const getBenefitChart = useCallback(async () => {
        const res = await getSpotStatistics(spot?.spotId, startDate, endDate);
        if (res.code === 2000) {
            setData(res.data);
        }
    });
    useEffect(() => {
        if (spot?.spotId != null) {
            getBenefitChart();
        }
    }, [spot, startDate, endDate]);

    return (
        <div>
            <h4 className="text-lg mb-2">{spot?.name}</h4>
            <DateStatistics keyName={"customers"} cards={[]}>
                <div className="relative">
                    {isLoading && (
                        <div className="absolute w-full bottom-0 left-0 top-0 flex items-center justify-center bg-white bg-opacity-30 z-30">
                            <td className="w-32">
                                <Loading className="text-gray-600" />
                            </td>
                        </div>
                    )}
                    <div className="w-full flex flex-wrap overflow-x-auto relative border border-gray-300 rounded-lg text-xs">
                        <PromotionTable data={data} totalPrice={totalPrice} />
                    </div>
                </div>
            </DateStatistics>
        </div>
    );
};

export default PromotionCard;
