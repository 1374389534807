import { useEffect, useState } from "react";
import {getMessages} from "../../api/message/messages.api";
import {getAllDateRange} from "../../libs/helper";
import {CalendarBar, Dropdown, Pagination, RadioGroup, Table} from "omoplay";
import moment from "moment/moment";

const MessageListPage = () => {
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState(getAllDateRange());
  const [type, setType] = useState()
  const [result, setResult] = useState()
  const [tableData, setTableData] = useState([])
  const [totalSize, setTotalSize] = useState(0)

  const messageTypes = [
    {label: "전체", value: ""}, {label: "텍스트 이미지형", value: "FI"},
    {label: "와이드 이미지형", value: "FW"}, {label: "와이드 리스트형", value: "FL"}, {label: "캐러셀 피드형", value: "FC"}
  ]

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    getMessages(startDate, endDate, type, result, page - 1, 10, "createdAt,desc").then((res) => {
      if (res.data) {
        setTableData(res.data.map(message => {
          return [
            <div className="text-center">
              {message.sendAt}
            </div>,
            <div className="text-center" style={{fontWeight: "700"}}>
              {displayStatus(message.result, message.errorCode)}
            </div>,
            <div className="text-center">
              {message.type}
            </div>,
            <div className="ellipsis" style={{maxWidth: "500px"}}>
              {message.content}
            </div>,
            <div className="text-center">
              {message.name}
            </div>,
            message.phoneNumber
          ]
        }))
        setTotalSize(res.data.length === 0 ? 0 : res.data[0].totalSize)
      }
    })
  }, [type, result, dateRange, page]);

  const onRadioChecked = (value) => {
    setPage(1)
    setResult(value)
  }
  const onDateInput = (dateRange) => {
    setPage(1)
    setDateRange({
      startDate: moment(dateRange.startDate).format("yyyy-MM-DD"),
      endDate: moment(dateRange.endDate).format("yyyy-MM-DD")
    });
  };

  const onDropdownChanged = (value) => {
    setPage(1)
    setType(value)
  }

  const displayStatus = (status, errorCode) => {
    if (status === "2") {
      return <span>발송 성공</span>
    } else if (status === "4") {
      return <><span style={{color: "#CE326C"}}>발송 실패</span><br/><span>({errorCode})</span></>
    } else {
      return <span>결과 대기</span>;
    }
  };

  const headers = [
    { name: "발송 일시", align: "center", width: "150px" },
    { name: "상태", align: "center", width: "140px" },
    { name: "메시지 타입", align: "center", width: "220px" },
    { name: "발송 내역", width: "540px"},
    { name: "이름", align: "center", width: "110px" },
    { name: "전화번호", align: "center"},
  ];

  const statusItems = [
    { label: "전체", value: "" },
    { label: "발송 성공", value: "2" },
    { label: "발송 실패", value: "4" }
  ]

  return (
    <div className="w-100">
      <div className="flex-col rounded w-full">
        <div className="page-title"> 메시지 리포트</div>
        <div className="flex justify-evenly bg-white border rounded-lg p-20px" style={{marginTop: "35px", height: "64px"}}>
          <div className="flex justify-center items-center" style={{gap: "53px"}}>
            <div>발송 일시</div>
            <CalendarBar initDate={{startDate: moment(dateRange.startDate).toDate(), endDate: moment(dateRange.endDate).toDate()}}
                         onConfirm={(props) => onDateInput(props)} />
          </div>
          <div className="flex justify-center items-center" style={{gap: "53px"}}>
            <div>메시지 타입</div>
            <Dropdown variants="default"
                      items={messageTypes}
                      onChanged={(items) => onDropdownChanged(items[0].value)}
            />
          </div>
          <div className="flex justify-center items-center" style={{gap: "53px"}}>
            <div>상태</div>
            <div>
              <RadioGroup items={statusItems} initChecked={result ?? ""} onSelected={(value) => onRadioChecked(value)} />
            </div>
          </div>
        </div>
        <div style={{marginTop: "30px", marginLeft: "14px"}}>
          <span className="font-16px">
            총 <span className="mainblue-0 font-bold">{totalSize.toLocaleString()}</span>건
          </span>
        </div>
        <div className="flex-col py-4">
          <Table headers={headers} data={tableData} />
          <div className="flex justify-center" style={{marginTop: "24px"}}>
            <Pagination rowSize={10} totalSize={totalSize} page={page} onPaging={(page) => setPage(page)} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MessageListPage;
