import { useEffect, useState } from "react";
import Popup from "../../components/Popup";
import SuspensePopup from "../../components/SuspensePopup";
import BenefitPreview from "../BenefitPreview";
import {Button, Pagination, Table, Tag} from "omoplay";
import MiniMenu from "../form/MiniMenu";
import {deleteBenefit, listBenefit} from "../../api/benefit/benefit.api";
import moment from "moment";

const BenefitList = () => {
  const [page, setPage] = useState(1);
  const [benefits, setBenefits] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [deleteBenefitId, setDeleteBenefitId] = useState(undefined);
  const [previewPopup, setPreviewPopup] = useState();
  const [tableData, setTableData] = useState([])
  const [openMenuId, setOpenMenuId] = useState(null)
  const rowSize = 10

  useEffect(() => {
    listBenefit(page, rowSize).then(({data}) => {
      setBenefits(data.content)
      setTotalPages(data.totalElements);
    })
  }, [page])

  function goEdit(benefitId) {
    window.location.href = (`/benefit/edit?id=${benefitId}`)
  }

  useEffect(() => {
    setTableData(benefits.map(benefit => {
      let badgeColor;
      if (benefit.status === "ACTIVE") {
        badgeColor = "success"
      } else {
        badgeColor = "default"
      }
      return [
        <div className="flex-align-center h-full">{benefit.id}</div>,
        <div>
          <div className="underline pointer" onClick={() => goEdit(benefit.id)}>{benefit.name}</div>
          <div className="text-grey-4">{moment(benefit.modifiedAt).format("YYYY-MM-DD HH:mm")} 업데이트</div>
        </div>,
        <div className="flex-align-center h-full">{benefit.retailPrice ? `${benefit.retailPrice.toLocaleString()}원` : "미설정"}</div>,
        <div className="flex-align-center h-full">{benefit.typeKor}</div>,
        <div className="flex-center-all h-full">
          <Tag color={badgeColor}>{benefit.statusKor}</Tag>
        </div>,
        <div className="flex-center-all h-full">
          <MiniMenu
              id={benefit.id}
              openMenuId={openMenuId}
              setOpenMenuId={setOpenMenuId}
              onClickEdit={() => goEdit(benefit.id)}
              onClickDelete={() => setDeleteBenefitId(benefit.id)}
              disabledDelete={benefit.used}
          />
        </div>
      ]
    }))
  }, [benefits, openMenuId]);

  const headers = [
    { name: "ID", width: "60px" },
    { name: "혜택명", sortable: false },
    {
      name: "소비자가",
      width: "150px",
      sortable: false,
    },
    {
      name: "혜택 형식",
      width: "150px",
      sortable: false,
    },
    {
      name: "상태",
      width: "120px",
      sortable: false,
      align: "center",
    },
    {
      name: "",
      sortable: false,
      align: "center",
      width: "80px"
    },
  ];

  const handleDelete = () => {
    setDeleteBenefitId(undefined)
    deleteBenefit(deleteBenefitId).then(() => {
      window.location.reload()
    })
  };

  return (
    <>
      <div>
        <div className="w-full flex items-center justify-between">
          <div className="flex gap-4 items-center">
          </div>
          <div className="w-full flex justify-between gap-2 items-center">
            <div>
              총 <span className="text-primary font-bold">{totalPages.toLocaleString()}</span>개
            </div>
            <Button
                variants="primary"
                value="+ 혜택 추가"
                onClick={() => window.location.href = ("/benefit/edit")}
             />
          </div>
        </div>
        <div className="flex-col py-4">
          <Table headers={headers} data={tableData} />
          <div className="flex justify-center" style={{marginTop: "24px"}}>
            <Pagination rowSize={rowSize} totalCount={totalPages} currentPage={page} onPaging={(page) => setPage(page)} />
          </div>
        </div>
      </div>
      <SuspensePopup
          title={"미리보기"}
          visible={previewPopup}
          onClose={() => setPreviewPopup(null)}
      >
        <BenefitPreview id={previewPopup} />
      </SuspensePopup>
      {deleteBenefitId && (
        <Popup
          title={"정말 삭제하시겠습니까?"}
          buttons={[
            { label: "취소", onClick: () => setDeleteBenefitId(undefined) },
            { label: "확인", type: "info", onClick: () => handleDelete() },
          ]}
        />
      )}
    </>
  );
};
export default BenefitList;
