const ContentMask = ({ children, includeHeader = false }) => {
  return (
    <div
      className={
        "overflow-y-auto overflow-x-hidden fixed right-0 left-0 z-50 flex justify-center items-center w-full max-h-full bg-black bg-opacity-40 " +
        (includeHeader ? "top-0 h-full" : "top-12  h-[calc(100%-54px)]")
      }
    >
      {children}
    </div>
  );
};

export default ContentMask;
