import Layout from "../components/layout/Layout";
import Payments from "../components/page/Payments";

const HomePage = () => {
  return (
    <Layout>
      <Payments />
    </Layout>
  );
};
export default HomePage;
