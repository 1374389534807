import { faMessage } from "@fortawesome/free-solid-svg-icons";
import SettingsLayout from "./layout/SettingsLayout";
import { useSearchParams } from "react-router-dom";
import {useEffect, useState} from "react";
import {
  getVolatilityMessage,
  saveNotification,
} from "../api/message/volatility-message.api";
import SettingsStepLayout from "./layout/SettingsStepLayout";
import MessageDefaultInfo from "./volatility-message/MessageDefaultInfo";
import MessageContentsSettings from "./volatility-message/content/setting/MessageContentsSettings";
import MessagePreview from "./volatility-message/content/preview/MessagePreview";
import MessageTargetSetting from "./volatility-message/MessageTargetSetting";
import MessageConfirm from "./volatility-message/content/MessageConfirm";
import moment from "moment";

const tabs = [
  { label: "기본 설정", value: "default" },
  { label: "콘텐츠 작성하기", value: "contents" },
  { label: "대상 및 발송일정", value: "target" },
  { label: "최종확인", value: "confirm" },
];
const VolatilityMessageSettings = () => {
  const [query] = useSearchParams();
  const [tab, setTab] = useState(tabs[0].value);
  const [message, setMessage] = useState({
    type: "FI", messageData: { type: "FI" },
    reservedDate: {
      date: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
      },
      hour: String(moment().hours()).padStart(2, "0"),
      min: String(moment().minutes()).padStart(2, "0")
    }
  })
  const [isConfirm, setIsConfirm] = useState(false)
  const [disabledTempBtn, setDisabledTempBtn] = useState(false)
  const [customerCnt, setCustomerCnt] = useState(0)

  const id = query.get("id");

  useEffect(() => {
    if (id) {
      getVolatilityMessage(id).then(({data}) => {
        const reserved = moment(data.reservedDate, "YYYY-MM-DD HH:mm")
        data.reservedDate = {
          date: {
            startDate: reserved.format("YYYY-MM-DD"),
            endDate: reserved.format("YYYY-MM-DD")
          },
          hour: String(reserved.hours()).padStart(2, "0"),
          min: String(reserved.minutes()).padStart(2, "0")
        }
        data.messageData = JSON.parse(data.messageData)
        setMessage(data)
      })
    }
  }, [id]);


  const handleConfirm = async (status) => {
    setIsConfirm(true)
    setDisabledTempBtn(true)
    saveNotification(status, message).then(() => {
      alert(
          status === "RESERVED" ? "발송이 등록되었습니다." : "임시 저장되었습니다."
      );
      window.location.replace("/volatility-message");
    })
  };

  const updateMessage = (key, value) => {
    const newMessage = { ...message }
    newMessage[key] = value
    setMessage(newMessage)
  }

  const updateMessageData = (key, value) => {
    const newMessage = { ...message }
    const messageData = newMessage.messageData ?? {}
    messageData[key] = value
    newMessage.messageData = messageData
    setMessage(newMessage)
  }

  const updateMessageDataArrays = (arrayKeys, value) => {
    const newMessage = { ...message }
    const messageData = newMessage.messageData ?? {}

    let currentLevel = messageData;
    arrayKeys.forEach((arrayKey, idx) => {
      const isIndex = !isNaN(Number(arrayKeys[idx + 1]))
      currentLevel[arrayKey] = currentLevel[arrayKey] ?? (isIndex ? [] : {})

      if (idx < arrayKeys.length - 1) {
        currentLevel = currentLevel[arrayKey]
      } else {
        currentLevel[arrayKey] = value
      }
    })
    newMessage.messageData = messageData
    setMessage(newMessage)
  }

  const deleteMessageDataArrays = (index, arrayKeys) => {
    const newMessage = { ...message }
    const messageData = newMessage.messageData ?? {}
    messageData[arrayKeys] = messageData[arrayKeys] ?? []
    messageData[arrayKeys] = messageData[arrayKeys].filter((_, i) => i !== index)
    newMessage.messageData = messageData
    setMessage(newMessage)
  }

  const description = () => {
    if (tab === "default") {
      return "메시지의 기본 정보를 설정해 주세요."
    } else {
      return ""
    }
  }

  const render = () => {
    const messageData = message.messageData ?? {}
    const type = messageData.type === "FT" ? "FI" : messageData.type
    const filters = message.filters ?? []
    const reservedDate = message.reservedDate

    if (tab === "default") {
      return (
          <div className="setting-step-padding">
            <MessageDefaultInfo campaignName={message?.campaignName}
                                type={type}
                                onChangeCampaignName={(campaignName) => {
                                  updateMessage("campaignName", campaignName)
                                }}
                                onChangeMessageType={(type) => {
                                  updateMessageData("type", type)
                                }}
            />
          </div>
      )
    } else if (tab === "contents") {
      return (
          <div style={{ display: "flex", gap: "60px", marginTop: "20px" }}>
            <div className="setting-step-padding" style={{flex: "1.5"}}>
              <MessageContentsSettings type={type}
                                       messageData={messageData}
                                       onChangeMessageData={(key, value) => updateMessageData(key, value)}
                                       onChangeMessageDataArrays={(arrayKeys, value) => updateMessageDataArrays(arrayKeys, value)}
                                       onDeleteMessageDataArrays={(index, arrayKeys) => deleteMessageDataArrays(index, arrayKeys)}
              />
            </div>
            <div style={{flex: "1"}}>
              <MessagePreview type={type} messageData={messageData} fixed={true} />
            </div>
          </div>
      )
    } else if (tab === "target") {
      return (
          <MessageTargetSetting
              targetFilters={filters}
              reservedDate={reservedDate}
              onChangeTargetFilters={(filters) => {
                updateMessage("filters", filters)
              }}
              onChangeCustomerCnt={(count) => {
                setCustomerCnt(count)
              }}
              type={type}
              messageData={messageData}
              onChangeReservedDate={(reservedDate) => {
                updateMessage("reservedDate", reservedDate)
              }}
          />
      )
    } else if (tab === "confirm") {
      return (
          <MessageConfirm
              message={message}
              customerCount={customerCnt}
              onValidState={setIsConfirm}
          />
      )
    }
  };

  return (
    <SettingsLayout
      title={"메시지 보내기"}
      icon={faMessage}
      tabs={tabs}
      activeTab={tab}
      isTemporarySave={!disabledTempBtn}
      onTemporarySave={() => handleConfirm("TEMPORARY")}
      onConfirm={() => handleConfirm("RESERVED")}
      onChange={setTab}
      isConfirm={isConfirm}
      confirmText={"등록하기"}
      onClose={() => window.location.href = ("/volatility-message")}
    >
      <SettingsStepLayout description={description()} renderChild={render()} />
    </SettingsLayout>
  );
};
export default VolatilityMessageSettings;
