import {Button, Table, TextInput} from "omoplay";
import {useEffect, useState} from "react";
import {getProductList} from "../../api/product/product.api";

export const ProductConnectionList = ({onConfirm, isMultiple}) => {
    const [products, setProducts] = useState([])
    const [productTableList, setProductTableList] = useState([])
    const [totalSize, setTotalSize] = useState(0)
    const [checkedIds, setCheckedIds] = useState([])
    const rowSize = 5
    const [page, setPage] = useState(1)

    const headers = [
        { name: "ID", width: "70px" }, { name: "상품명" },
        { name: "카테고리", width: "200px" }, { name: "원가", width: "140px" },
        { name: "생성일", width: "200px" }, {name: "", width: "140px"}
    ]

    useEffect(() => {
        getProductList().then(({data}) => {
            setProducts(data)
            setTotalSize(data.length)
            setProductTableList(data.map(product => {
                const info = product.info
                if (!info) {
                    return []
                }

                return [
                    <div>{product.id}</div>,
                    <div className="flex">
                        <div><img src={info.images[0]} width="48px" height="48px" /></div>
                        <div>{info.productName}</div>
                    </div>,
                    <div>{info.category}</div>,
                    <div>{info.originalPrice?.toLocaleString()}</div>,
                    <div>{product.createdAt}</div>,
                    <div>{!isMultiple && (<div><Button variants="dark" value="선택하기" onClick={() => onConfirm(product.id)} /></div>)}</div>
                ]
            }))
        })
    }, [page])

    return (
        <>
            <div className="flex-col gap-4" style={{marginTop: "24px"}}>
                <div className="flex justify-between gap-4">
                    <div className="flex-1">
                        <TextInput showIcon={true} placeholder="검색어를 입력해 주세요." />
                    </div>
                    {
                        isMultiple &&
                        <div>
                            <Button variants="dark" value="선택하기" onClick={() => onConfirm(checkedIds)} />
                        </div>
                    }
                </div>
                <div>
                    <Table checkbox={isMultiple} headers={headers} data={productTableList} pagination={{
                        rowSize: rowSize,
                        totalSize: totalSize,
                        pageSize: 5,
                        onPaging: (page) => setPage(page)
                    }} onCheckIndex={(index, checked) => {
                        const id = products[index].id
                        setCheckedIds((prevIds) => {
                            if (checked) {
                                return [...prevIds, id];
                            } else {
                                // id 제거
                                return prevIds.filter((existingId) => existingId !== id);
                            }
                        });
                    }} />
                </div>
            </div>
        </>
    )
}

export default ProductConnectionList
