import {Button, TextInput} from "omoplay";
import {useState} from "react";
import {kakaoProfileChannelAuth, channelAuthorization} from "../../../api/account/account.api";
import GuideIcon from "../../../assets/images/guide_icon.svg";


const KakaoAuthorization = ({channelName, onSubmit}) => {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [authFlag, setAuthFlag] = useState(false)
    const [authCodeDisabled, setAuthCodeDisabled] = useState(true)
    const [code, setCode] = useState("")


    const auth = () => {
        kakaoProfileChannelAuth("@" + channelName, phoneNumber)
            .then(({data}) => {
                if (data.code !== "200") {
                    alert(data.message)
                } else {
                    setAuthCodeDisabled(false)
                    alert("인증번호가 전송되었습니다. 알림톡을 확인해 주세요.")
                }
            })
    }

    const registration = () => {
        channelAuthorization(channelName, phoneNumber, code)
            .then(({data}) => {
                if (data.code !== "509") {
                    alert(data.message)
                } else {
                    alert("인증 되었습니다.")
                    setAuthCodeDisabled(true)
                    setAuthFlag(true)
                    onSubmit(data.profileKey)
                }
            })
    }

    return (
        <div className="flex-col gap-4">
            <div className="flex-col gap-2">
                <div>카카오톡채널 관리자 휴대폰 번호 <span className="text-red-500">*</span></div>
                <div>
                    <Button variants="outlined" value={
                        <div className="flex justify-center items-center">
                            <img alt="" src={GuideIcon} width="16px" height="16px" />
                            <div className="ml-2">설정 가이드 확인하기</div>
                        </div>
                    } onClick={() => {
                        window.open("https://local-room-f00.notion.site/1132d9e7196a8161b713c87184417389?pvs=4")
                    }} />
                </div>
            </div>
            <div className="flex-center-all gap-2">
                <div className="flex-1">
                    <TextInput placeholder="관리자 휴대폰 번호를 입력하세요"
                               type="numeric"
                               onKeyUp={(e) => {
                                   setPhoneNumber(e.currentTarget.value)
                               }}
                               onKeyDown={(e) => {
                                   setPhoneNumber(e.currentTarget.value)
                               }}
                               disabled={authFlag} />
                </div>
                <div>
                    <Button variants="primary" value="인증번호 받기" onClick={() => auth()} disabled={phoneNumber.length < 8} />
                </div>
            </div>
            <div className="flex-center-all gap-2">
                <div className="flex-1">
                    <TextInput placeholder="인증 번호를 입력하세요"
                               type="numeric"
                               onKeyUp={(e) =>
                                   setCode(e.currentTarget.value)}
                               disabled={authFlag} />
                </div>
                <div>
                    <Button variants="primary" value="인증 확인하기" disabled={authCodeDisabled}
                            onClick={() => registration()} />
                </div>
            </div>
        </div>
    )
}

export default KakaoAuthorization