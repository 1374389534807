import {Button, Table, Tag, TextInput} from "omoplay";
import {getProductList, productRegistration} from "../../api/product/product.api";
import React, {useEffect, useState} from "react";
import moment from "moment";
import SuspensePopup from "../SuspensePopup";
import AICreditIcon from "../../assets/images/icon.credit.svg"

const ProductList = () => {
    const [url, setUrl] = useState("")
    const [products, setProducts] = useState([])
    const [showRegister, setShowRegister] = useState(false)

    useEffect(() => {
        getProductList().then(({data}) => {
            setProducts(data.map(product => {
                const info = product.info ?? { images: []}
                return [
                    <div className="flex-align-center pointer gap-2 underline" onClick={() => {
                        window.location.href = `/product/detail?id=${product.id}`
                    }}>
                        <div><img width={60} height={60} src={info.images[0]} /></div>
                        <div>{info.productName}</div>
                    </div>,
                    <div className="flex-center-all h-full">{info.category}</div>,
                    <div className="flex-center-all h-full">{info.productCode}</div>,
                    <div className="flex-center-all h-full">{info.price}</div>,
                    <div className="flex-center-all h-full">{info.siteName}</div>,
                    <div className="flex-center-all h-full text-center">{moment(product.createdAt).format("YYYY-MM-DD HH:mm:ss")}</div>,
                    <div className="flex-center-all h-full">{product.info ? <Tag color="info">사용 가능</Tag> : <Tag color="warning">불러오는 중</Tag>}</div>
                ]
            }))
        })
    }, []);

    function registration() {
        if (url !== "") {
            productRegistration(url).then(({data}) => {
                if (data === "insufficient") {
                    alert("크레딧이 부족합니다.")
                } else {
                    alert("자동 등록을 호출 했습니다. 완료되면 상품이 등록됩니다.")
                    window.location.reload()
                }
            })
        }
    }

    return (
        <>
            <div className="flex-col gap-2">
                <div className="flex justify-end">
                    <Button variants="primary" value="+ AI로 제품 추가하기" onClick={() => setShowRegister(true)} />
                </div>
                <Table headers={[
                    { name: "제품명" }, { name: "카테고리", align: "center" }, { name: "제품 코드", width: "150px", align: "center" },
                    { name: "소비자가", width: "130px", align: "center" }, { name: "채널명", width: "160px", align: "center" },
                    { name: "생성 날짜", width: "130px", align: "center" }, { name: "상태", width: "120px", align: "center" }
                ]} data={products}
                       pagination={{
                           rowSize: 7,
                           totalSize: products.length
                       }}
                />
            </div>
            <SuspensePopup title="URL로 제품 등록하기"
                           visible={showRegister}
                           onClose={() => setShowRegister(false)}>
                <div className="flex-col gap-2">
                    <div className="w-full flex-1">
                        <TextInput placeholder="제품 상세페이지가 있는 URL을 입력하세요."
                                   onChange={(e) => setUrl(e.target.value)} />
                    </div>
                    <div className="flex justify-end w-full">
                        <Button variants="secondary" disabled={url === "" || !url.includes("http")}
                                value={<div className="flex">AI로 제품 추가하기 (<div><img src={AICreditIcon} alt="ai_credit" /></div>5 Credit 차감)</div>}
                                onClick={() => registration()} />
                    </div>
                    <div className="text-tertiary">
                        <div>* 제품 정보를 가지고 오는데에는 약 1~3분이 소요됩니다.</div>
                        <div>* 제품 정보를 가지고 온 이후에도 필수 항목은 추가로 수기 입력이 필요할 수 있습니다.</div>
                        <div>* 정보가 올바르지 않은 경우 불러오지 못할 수 있습니다.</div>
                    </div>
                </div>
            </SuspensePopup>
        </>
    );
};

export default ProductList;
