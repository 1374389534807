import InputGroup from "../InputGroup";
import {EVENT_TYPES} from "../../../constants/menus";
import {Alert, TextInput} from "omoplay";
import TypeSelectionForm from "../TypeSelectionForm";

export const EventDefault = ({event, onChangeEventName, onSelectType}) => {
    const eventType = event.type ?? "roulette"

    return (
      <div className="flex-col gap-2">
          <Alert title="이벤트의 기본 정보를 설정해 주세요." type="info" withIcon={true} />
        <InputGroup
            label={"이벤트명"}
        >
          <p className="text-gray-500 text-sm mb-2">
            이벤트명을 입력해 주세요.
          </p>
          <TextInput
              value={event.name}
              placeholder="이벤트명을 입력해 주세요."
              onChange={(e) => onChangeEventName(e.target.value)}
              maxLength={30}
          />
        </InputGroup>
        <InputGroup
            label={"이벤트 타입"}
            description="
                어떤 타입으로 보내실지 골라주세요."
        >
            <TypeSelectionForm types={EVENT_TYPES} selectedType={eventType} onChangeType={(key) => {
                if (key !== "preparing") {
                    onSelectType(key)
                }
            }} />
        </InputGroup>
      </div>
    );
};

export default EventDefault;
