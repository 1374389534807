import {useEffect, useState} from "react";
import {Button, TextInput} from "omoplay";
import SuspensePopup from "../SuspensePopup";
import InputGroup from "../form/InputGroup";
import BenefitForm from "../form/benefit/BenefitForm";
import DescriptionBar from "../DescriptionBar";
import {getPointMenu, savePointMenu} from "../../api/membership/point-menu.api";

const PointMenuCreate = ({id, onSubmit}) => {
    const [isOpenBenefit, setOpenBenefit] = useState(false)
    const [benefit, setBenefit] = useState({})
    const [point, setPoint] = useState(0)
    const [quantity, setQuantity] = useState(null)

    useEffect(() => {
        if (id !== null) {
            getPointMenu(id).then(({data}) => {
                setBenefit({
                    id: data.benefitId,
                    name: data.benefitName
                })
                setPoint(data.point)
                setQuantity(data.quantity)
            })
        }
    }, [id])

    const save = () => {
        savePointMenu(id, benefit.id, point, quantity).then(() => {
            alert("저장 되었습니다.")
            onSubmit()
        })
    }

    return (
        <>
            <DescriptionBar description="고객이 누적한 포인트로 교환할 수 있는 포인트 메뉴판을 생성해 보세요." />
          <div>
            <InputGroup
                label={"지급 혜택"}
                isRequired={true}
                className="py-4"
            >
              <div className="flex gap-2">
                  <div>
                      <Button variants="default" value="혜택 연결" onClick={() => {
                          setOpenBenefit(true)
                      }} />
                  </div>
                  <div className="flex-1 w-full">
                      <TextInput disabled={true} value={benefit.name} />
                  </div>
              </div>
            </InputGroup>
          </div>
          <InputGroup
              label={"차감 포인트"}
              isRequired={true}
              className="py-4"
          >
            <TextInput type="numeric" value={point} onChange={(e) => setPoint(e.target.value)} />
          </InputGroup>
          <InputGroup
              label={"제한 수량"}
              isRequired={true}
              className="py-4"
          >
              <TextInput type="numeric" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
          </InputGroup>
          <div className="flex-center-all mt-8">
            <Button variants="solid" value="포인트 메뉴 추가" onClick={() => save()} />
          </div>
          {
            isOpenBenefit && (
                  <SuspensePopup
                      isEditPopup={true}
                      size={"flex items-center"}
                      title={"혜택 연결하기"}
                      onClose={() => setOpenBenefit(false)}
                  >
                    <BenefitForm onSelectedBenefit={(benefitId, benefitName) => {
                        setBenefit({
                            id: benefitId,
                            name: benefitName
                        })
                        setOpenBenefit(false)
                    }} />
                  </SuspensePopup>
              )
          }
        </>
    );
};
export default PointMenuCreate
