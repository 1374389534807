import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Pagination = ({ page, totalPages, setPage }) => {
  const getOverThanZeroByMinusTwoRange = (value) => {
    const targets = [value, value - 1, value - 2];
    let response = value;
    targets.forEach((target) => {
      if (target > 0) {
        response = target;
      }
    });
    return response;
  };

  const getPageNumbers = (currentPage, totalPage) => {
    if (currentPage < 3) {
      return Array.from(
        {
          length: totalPage < 5 ? totalPage : 5,
        },
        (_, i) => 1 + i
      );
    } else if (totalPage - currentPage < 3) {
      return Array.from(
        {
          length: 5,
        },
        (_, i) => totalPage - 4 + i
      ).filter((i) => i > 0);
    } else {
      const minValue = getOverThanZeroByMinusTwoRange(currentPage);
      const diff = currentPage - minValue;
      const maxValue = currentPage + diff;
      return Array.from(
        {
          length: maxValue - minValue + 1,
        },
        (_, i) => minValue + i
      );
    }
  };

  return (
    <ul className="-space-x-px text-base h-10 flex gap-1">
      <li>
        <button
          className="flex items-center justify-center w-10 h-10 ms-0 leading-tight text-gray-500 hover:bg-gray-700 hover:text-white rounded"
          onClick={() => setPage(1)}
        >
          <FontAwesomeIcon icon={faAnglesLeft} />
        </button>
      </li>

      <li>
        <button
          className="flex items-center justify-center w-10 h-10 ms-0 leading-tight text-gray-500 hover:bg-gray-700 hover:text-white rounded"
          onClick={() => setPage(page - 1 < 1 ? 1 : page - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
      </li>
      {getPageNumbers(page, totalPages).map((pageNumber) => {
        return (
          <li key={"pagenation" + pageNumber}>
            <button
              className={
                "flex items-center justify-center w-10 h-10 leading-tight transition border rounded " +
                (page === pageNumber
                  ? "bg-black text-white hover:bg-gray-700 border-black"
                  : "text-gray-500 hover:bg-gray-100 hover:text-gray-700  ")
              }
              onClick={() => setPage(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        );
      })}

      <li>
        <button
          className="flex items-center justify-center w-10 h-10 leading-tight text-gray-500  hover:bg-gray-700 hover:text-white rounded"
          onClick={() => setPage(page + 1 > totalPages ? totalPages : page + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </li>
      <li>
        <button
          className="flex items-center justify-center w-10 h-10 leading-tight text-gray-500 hover:bg-gray-700 hover:text-white rounded"
          onClick={() => setPage(totalPages)}
        >
          <FontAwesomeIcon icon={faAnglesRight} />
        </button>
      </li>
    </ul>
  );
};
export default Pagination;
