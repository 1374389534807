import Layout from "../components/layout/Layout";
import OnlineOrderUsers from "../components/page/OnlineOrderUsers";

const OnlineOrderUsersPage = () => {
  return (
    <Layout>
      <OnlineOrderUsers />
    </Layout>
  );
};
export default OnlineOrderUsersPage;
