import { useCallback, useEffect, useState } from "react";
import SortableTableHeader from "./table/SortableTableHeader";
import { getBillDetails } from "../api/cash/cash.api";
import Pagination from "./Pagination";
import Loading from "./Loading";
import moment from "moment";

export const BillDetail = ({ startDate, endDate, type }) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [size] = useState(10);
  const [totalPage, setTotalPages] = useState(1);
  const headers = [
    { key: "issuedAt", label: "일자", sortable: false },
    { key: "name", label: "내역", sortable: false },
    {
      key: "perPrice",
      label: "단가",
      sortable: false,
    },
    {
      key: "count",
      label: "수량",
      sortable: false,
    },
    {
      key: "price",
      label: "사용 금액",
      sortable: false,
    },
  ];

  const getData = useCallback(async () => {
    const res = await getBillDetails(page - 1, size, startDate, endDate, type);
    setData(res.data.content);
    setTotalPages(res.data.totalPages);
    setLoading(false);
  }, [page, size, startDate, endDate, type]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [startDate, endDate, type, getData]);
  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 border mb-4 relative ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 rounded-tl border-b">
          <tr className="divide-x">
            {headers.map((header, index) => {
              return (
                <SortableTableHeader
                  key={"bill-wrap-table-header" + index}
                  header={header}
                />
              );
            })}
          </tr>
        </thead>
        {isLoading ? (
          <tbody>
            <tr>
              <td colSpan={7} className="text-center">
                <Loading className="w-12 h-12 mx-auto my-6" />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data.map((row, index) => {
              const cs = "px-3 py-2 ";
              return (
                <tr
                  className="bg-white border-b hover:bg-gray-50 divide-x"
                  key={"cash-history-" + index}
                >
                  <td className={cs}>{row.issueDate}</td>
                  <td className={cs}>{row.name}</td>
                  <td className={cs + "text-right"}>
                    {row.unitPrice.toLocaleString()}
                  </td>
                  <td className={cs + "text-right"}>
                    {row.count.toLocaleString()}
                  </td>
                  <td className={cs + "font-semibold text-right"}>
                    ₩{(row.value * -1).toLocaleString()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      <div className="w-100 flex justify-center mt-2">
        <Pagination page={page} totalPages={totalPage} setPage={setPage} />
      </div>
    </div>
  );
};

export default BillDetail;
