import { useEffect, useState } from "react";
import {Button, Table, Toggle} from "omoplay";
import {listPointMenu, saveVisiblePointMenu} from "../../api/membership/point-menu.api";
import SuspensePopup from "../SuspensePopup";
import PointMenuCreate from "./PointMenuCreate";
import MiniMenu from "../form/MiniMenu";

const PointMenuList = () => {
  const [savePointMenuId, setSavePointMenuId] = useState(undefined)
  const [pointMenuList, setPointMenuList] = useState([])
  const [tableData, setTableData] = useState([])
  const [openMenuId, setOpenMenuId] = useState(null)

  const headers = [
    { name: "ON/OFF", width: "150px", align: "center" },
    { name: "혜택" },
    { name: "제한 수량"},
    { name: "생성일" },
    { name: "", align: "center", sortable: false, width: "80px" }
  ];

  useEffect(() => {
    listPointMenu().then(({data}) => {
      setPointMenuList(data)
    })
  }, [])

  const turnVisible = (id, visible) => {
    let isConfirm = !visible
    if (visible) {
      if (window.confirm("해당 포인트 메뉴를 보이게 변경하면 고객이 바로 사용할 수 있습니다.\n진행하시겠습니까?")) {
        isConfirm = true
      } else {
        window.location.reload()
      }
    }
    if (isConfirm) {
      saveVisiblePointMenu(id, visible).then(() => {
      })
    }
  }

  useEffect(() => {
    setTableData(pointMenuList.map((pointMenu) => {
      return [
        <div className="flex-center"><Toggle value={pointMenu.status} onToggle={(flag) => turnVisible(pointMenu.id, flag)} /></div>,
        <div className="flex justify-between">
          <div>
            <div>{pointMenu.benefitName}</div>
            <div className="text-mainblue-0 font-bold font-16px">{pointMenu.point}P</div>
          </div>
          <div>
            <img alt="" width={96} height={96} src={pointMenu.benefitImage} />
          </div>
        </div>,
        <div>{pointMenu.quantity}개</div>,
        <div>{pointMenu.createdAt}</div>,
        <div className="flex justify-center">
          <MiniMenu
              id={pointMenu.id}
              openMenuId={openMenuId}
              setOpenMenuId={setOpenMenuId}
              onClickEdit={() => setSavePointMenuId(pointMenu.id)}
              onClickDelete={() => {}}
              disabledDelete={false}
          />
        </div>
      ]
    }))
  }, [pointMenuList, openMenuId])

  return (
    <>
      <div className="flex items-center gap-4">
        <div className="page-title">포인트 메뉴</div>
      </div>
      <div className="w-full" style={{marginTop: "33px"}}>
        <div className="flex w-100 items-center">
          <div className="w-full flex justify-end items-center">
            <Button variants="solid" value="+ 포인트 메뉴 추가" onClick={() => {
              setSavePointMenuId(null)
            }} />
          </div>
        </div>
        <div className="flex-col py-2">
          <Table headers={headers} data={tableData} />
        </div>
      </div>
      {
          savePointMenuId !== undefined && (
              <SuspensePopup
                  title={"포인트 메뉴 추가"}
                  size={"w-[600px]"}
                  onClose={() => setSavePointMenuId(undefined)}
              >
                <PointMenuCreate id={savePointMenuId} onSubmit={() => {
                  window.location.reload()
                }} />
              </SuspensePopup>
          )
      }
    </>
  );
};
export default PointMenuList;
