import React, {useRef, useState} from "react";
import {Button} from "omoplay";
import {fileUpload} from "../api/file/file.api";


const ImageEditorContentsList = ({messageData, editors, onClickImage, onUploadFile}) => {
    const type = messageData.type
    const fileInputRef = useRef(null)
    const [index, setIndex] = useState(0)

    const onChangeFile = (e, index) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            fileUpload(file).then(({data}) => {
                onUploadFile(data, index)
            })
        }
    }
    const handleButtonClick = (index) => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
            setIndex(index)
        }
    };

    if (type === "FI" || type === "FT" || type === "FW") {
        return (
            <div>
                <img className="w-[142px] h-[106px]" src={editors[0]?.thumbnailURL} alt="Thumbnail url"
                     crossOrigin="anonymous" />
                <div className="p-[8px] text-center">
                    <input
                        accept="image/jpg, image/jpeg, image/png"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        type="file"
                        onChange={(e) => onChangeFile(e, index)}
                    />
                    <Button variants="default" value="파일 업로드" size="small" style={{width: "100%"}}
                            onClick={handleButtonClick} />
                </div>
            </div>
        )
    } else if (type === "FL") {
        const items = messageData.items ?? []

        return (
            <>
                <input
                    accept="image/jpg, image/jpeg, image/png"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    type="file"
                    onChange={(e) => onChangeFile(e, index)}
                />
                {
                    items
                    .filter((item) => item.imgUrl != null)
                    .map((item, index) => {
                        return (
                            <div>
                                <img className="w-[142px] h-[106px]" src={editors[index]?.thumbnailURL} alt="Thumbnail url"
                                     crossOrigin="anonymous"
                                     onClick={() => onClickImage(index)} />
                                <div className="p-[8px] text-center">
                                    <Button variants="default" value="파일 업로드" size="small" style={{width: "100%"}}
                                            onClick={() => handleButtonClick(index)}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </>
        )
    } else if (type === "FC") {
        const carousels = messageData.carousels ?? []

        return (
            <>
                <input
                    accept="image/jpg, image/jpeg, image/png"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    type="file"
                    onChange={(e) => onChangeFile(e, index)}
                />
                {
                    carousels
                    .filter((carousel) => carousel.attachment?.image != null)
                    .map((carousel, index) => {
                        return (
                            <div className="bg-white rounded-[8px]">
                                <img className="w-[142px] h-[106px]" src={editors[index]?.thumbnailURL}
                                     alt="Thumbnail url"
                                     crossOrigin="anonymous"
                                     onClick={() => onClickImage(index)} />
                                <div className="p-[8px] text-center">
                                    <Button variants="default" value="파일 업로드" size="small" style={{width: "100%"}}
                                            onClick={() => handleButtonClick(index)}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </>
        )
    }
}

export default ImageEditorContentsList