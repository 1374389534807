import Layout from "../components/layout/Layout";
import Users from "../components/page/Users";

const HomePage = () => {
  return (
    <Layout>
      <Users />
    </Layout>
  );
};
export default HomePage;
