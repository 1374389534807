import CarouselMessageSetting from "./CarouselMessageSetting.js";
import DefaultMessageSetting from "./DefaultMessageSetting";
import WideListMessageSetting from "./WideListMessageSetting";
import WideMessageSetting from "./WideMessageSetting";
import InputGroup from "../../../form/InputGroup";
import MessageWrapPreview from "../preview/MessageWrapPreview";

const MessageContentsSettings = ({ type, messageData, onChangeMessageData, onChangeMessageDataArrays, onChangeImage, onDeleteMessageDataArrays }) => {
    let typeKor = ""
    let renderSetting
    const variables = ["이름", "생일", "주소"]
    const regex = /\[[이름|생일|주소]+\]/g

    if (type === "FT" || type === "FI") {
        typeKor = "기본 텍스트형"
        renderSetting = (
            <DefaultMessageSetting messageData={messageData}
                                   variables={variables}
                                   regex={regex}
                                   onChangeImage={(key, imgUrl) => {
                                       onChangeImage(key, imgUrl)
                                   }}
                                   onChangeContents={(content) => {
                                       onChangeMessageData("content", content)
                                   }}
                                   onChangeButtons={(index, key, value) => {
                                       onChangeMessageDataArrays(["buttons", index, key], value)
                                   }}
            />
        )
    } else if (type === "FW") {
        typeKor = "와이드 이미지형"
        renderSetting = (
            <WideMessageSetting messageData={messageData}
                                variables={variables}
                                regex={regex}
                                onChangeImage={(key, imgUrl) => {
                                    onChangeMessageDataArrays(["image", key], imgUrl)
                                }}
                                onChangeImageLink={(imgLink) => {
                                    onChangeMessageDataArrays(["image", "imgLink"], imgLink)
                                }}
                                onChangeContents={(content) => {
                                   onChangeMessageData("content", content)
                                }}
                                onChangeButtons={(index, key, value) => {
                                    onChangeMessageDataArrays(["buttons", index, key], value)
                                }}
            />
        )
    } else if (type === "FL") {
        typeKor = "와이드 리스트형"
        renderSetting = (
            <WideListMessageSetting messageData={messageData}
                                    variables={variables}
                                    regex={regex}
                                    onChangeHeader={(header) => {
                                        onChangeMessageData("header", header)
                                    }}
                                    onChangeItems={(index, key, value) => {
                                        onChangeMessageDataArrays(["items", index, key], value)
                                    }}
                                    onDeleteItem={(index) => {
                                        onDeleteMessageDataArrays(index, ["items"])
                                    }}
                                    onChangeButtons={(index, key, value) => {
                                        onChangeMessageDataArrays(["buttons", index, key], value)
                                    }}
            />
        )
    } else if (type === "FC") {
        typeKor = "캐러셀 피드형"
        renderSetting = (
            <CarouselMessageSetting messageData={messageData}
                                    variables={variables}
                                    regex={regex}
                                    onChangeCarousels={(index, key, value) => {
                                        onChangeMessageDataArrays(["carousels", index, key], value)
                                    }}
                                    onOrderCarousels={(carousels) => {
                                        onChangeMessageDataArrays(["carousels"], carousels)
                                    }}
                                    onDeleteCarousels={(index) => {
                                        onDeleteMessageDataArrays(index, ["carousels"])
                                    }}
                                    onChangeCarouselImage={(index, key, value) => {
                                        onChangeMessageDataArrays(["carousels", index, "attachment", "image", key], value)
                                    }}
                                    onChangeCarouselButtons={(index, buttonIndex, key, value) => {
                                        onChangeMessageDataArrays(["carousels", index, "attachment", "buttons", buttonIndex, key], value)
                                    }}
                                    onChangeTail={(key, value) => {
                                        onChangeMessageDataArrays(["tail", key], value)
                                    }}
            />
        )
    }
  return (
      <InputGroup label={`${typeKor} 설정`}>
          <div className="flex">
              <div className="flex-1 pr-[12px]">
                  {renderSetting}
              </div>
              <div className="flex-1 pl-[12px] flex justify-center">
                  <MessageWrapPreview type={type} messageData={messageData} />
              </div>
          </div>
      </InputGroup>
  );
};

export default MessageContentsSettings;
