import {Table} from "omoplay";
import {useEffect, useState} from "react";
import {getUserDetail} from "../../api/user/user.api";
import {useSearchParams} from "react-router-dom";
import MessageWrapPreview from "../volatility-message/content/preview/MessageWrapPreview";
import {getOrdersStatistics} from "../../api/statistics/statistics.api";

const UserDetail = () => {
  const [query] = useSearchParams()
  const [user, setUser] = useState([])
  const [volatilityMessages, setVolatilityMessages] = useState([])
  const [projects, setProjects] = useState([])
  const [orderTables, setOrderTables] = useState([])
  const [orderTotalCount, setOrderTotalCount] = useState(0)
  const [orderTotalSales, setOrderTotalSales] = useState(0)

  function goProjectDetail(id) {
    window.location.href = `/project/detail?id=${id}`
  }
  useEffect(() => {
    const userId = query.get("id")
    getUserDetail(userId).then(({data}) => {
      setUser([[
        <div>{data.name}</div>,
        <div>{data.gender}</div>,
        <div>{data.birthDay}</div>,
        <div>{data.address}</div>,
        <div>{data.friendStatus}({data.modifiedFriendsAt})</div>]
      ])
      setVolatilityMessages(data.volatilityMessages)
      setProjects(data.projects)
    })

    getOrdersStatistics(userId).then(({data}) => {
      setOrderTotalCount(data.totalCount)
      setOrderTotalSales(data.totalSales)
      setOrderTables(data.users.map(user => {
        return [
          <div className="">{user.productNames.map(name => <div>{name}</div>)}</div>,
          <div className="flex-center-all h-full">{user.productStatus}</div>,
          <div className="flex-center-all h-full">{user.paymentAmount.toLocaleString()}원</div>,
          <div className="flex-center-all h-full">{user.paymentDate}</div>,
        ]
      }))
    })
  }, [query]);

  return (
    <>
      <div className="flex-col gap-4">
        <div className="flex-col gap-4 bg-white p-20px">
          <Table headers={
            [{name: "이름"}, {name: "성별"}, {name: "연령"}, {name: "주소", width: "300px"}, {name: "친구 상태"}]
          } data={user} />
          <div className="flex-col gap-4">
            <label className="font-20px">온라인 매출</label>
            <div className="flex gap-8">
              <div>총 <span className="mainblue-0">{orderTotalCount.toLocaleString()}</span>개</div>
              <div>매출 <span className="mainblue-0">{orderTotalSales.toLocaleString()}</span>원</div>
            </div>
            <Table headers={
              [
                  {name: "상품명"}, {name: "상태", width: "110px", align: "center"},
                {name: "결제 가격", width: "110px", align: "center"}, {name: "결제 날짜", width: "160px", align: "center"}
              ]
            } data={orderTables} />
          </div>
          <div className="flex-col gap-4">
            <label className="font-20px">참여한 프로젝트</label>
            {
              projects.map(project => {
                return (
                    <div className="flex gap-2">
                      <MessageWrapPreview messageData={project.messageData} type={project.messageData.type} />
                      <Table headers={
                        [{name: "프로젝트"}, {name: "진입점"}, {name: "받은 혜택명"}, {name: "참여 날짜"}]
                      } data={[[
                        <div className="underline pointer" onClick={() => goProjectDetail(project.id)}>{project.name}</div>,
                        <div>{project.entryPointName}</div>,
                        <div>{project.benefitName}</div>,
                        <div>{project.createdAt}</div>,
                      ]]} />
                    </div>
                )
              })
            }
          </div>
          <div>
            <div>
              <label className="font-20px">받은 메시지</label>
              <div className="flex-col gap-4">
                {
                  volatilityMessages.map(message => {
                    const messageData = message.messageData
                    return (
                        <div className="flex gap-2">
                          <MessageWrapPreview messageData={messageData} type={messageData.type} />
                          <Table headers={
                            [{name: "클릭 컨텐츠"}, {name: "형태"}, {name: "클릭 횟수"}, {name: "클릭 시간"}]
                          } data={message.clickContents.map((content, index) => {
                            return [
                              <div>{content.name}</div>,
                              <div>{content.typeName}</div>,
                              <div>{content.clickCount}</div>,
                              <div>
                                {
                                  content.clickDates.map(date => {
                                    return (
                                      <div>{date}</div>
                                    )
                                  })
                                }
                              </div>
                            ]
                          })} />
                        </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserDetail;
