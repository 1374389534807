import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const listCreditHistory = async (creditType) => {
  const res = await fetchApi.get(API_URL.BILLING_HISTORY + `?creditType=${creditType}`);
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};

export const getCreditHistory = async (id) => {
  const res = await fetchApi.get(`${API_URL.GET_BILLING_HISTORY.replace("{id}", id)}`);
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};