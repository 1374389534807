import {Modal} from "omoplay";

const SuspensePopup = ({title, style, visible, onConfirm, confirmText, onClose, children}) => {
  return (
      <Modal style={style} visible={visible} title={title} closable={true}
             onConfirm={onConfirm}
             confirmText={confirmText ?? "확인"}
             cancelText="닫기" onCancel={onClose}
      >
        {
          children
        }
      </Modal>
  );
};

export default SuspensePopup;
