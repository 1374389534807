import {MockUp} from "omoplay";
const BenefitMessagePreview = ({ content, preview }) => {
  return (
    <div className="flex rounded">
      <div className="w-2/5 bg-white rounded-l">
          <MockUp>
              {preview}
          </MockUp>
      </div>
      <div className="w-3/5 flex">{content}</div>
    </div>
  );
};

export default BenefitMessagePreview;
