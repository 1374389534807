import {Button, TextInput} from "omoplay";
import React, {useRef, useState} from "react";
import {fileUpload} from "../api/file/file.api";


const ImageForm = ({imageUrl, onChangeImageUrl}) => {
    const [uploadImageUrl, setUploadImageUrl] = useState(imageUrl ?? "")
    const fileInputRef = useRef(null)

    const uploadImage = (e) => {
        fileUpload(e.target.files[0]).then(({data}) => {
            onChangeImageUrl(data)
            setUploadImageUrl(data)
            e.target.value = null
        })
    }

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    return (
        <div>
            <div className="flex gap-2">
                <input
                    accept="image/jpg, image/jpeg, image/png"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    type="file"
                    onChange={(e) => uploadImage(e)}
                />
                <div className="w-full">
                    <TextInput
                        readOnly
                        placeholder="이미지 파일을 업로드 해주세요."
                        value={uploadImageUrl}
                    />
                </div>
                <Button
                    variants="dark"
                    value="첨부"
                    onClick={handleButtonClick}
                    style={{ width: "105px", height: "40px" }}
                />
            </div>
            <p className="text-gray-400 text-xs mt-2">
                * 지원파일 : JPG, PNG (최대 10MB)
            </p>
        </div>
    )
}

export default ImageForm