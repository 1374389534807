import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const getEventList = async (page, size, status) => {
  const res = await fetchApi.get(`${API_URL.EVENT_LIST}${page && size ? `?page=${page}&size=${size}` : ''}${status ? `${page && size ? "&" : "?"}status=${status}` : ''}`);
  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};

export const getEvent = async (id) => {
  const res = await fetchApi.get(API_URL.GET_EVENT.replace("{id}", id));
  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};

export const postEvent = async (event) => {
  const res = await fetchApi.post(API_URL.POST_EVENT, {
    ...event,
    type: event.type ?? "roulette",
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};

export const deleteEvent = async (id) => {
  const res = await fetchApi.delete(API_URL.DELETE_EVENT.replace("{id}", id));
  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};
