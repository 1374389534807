import { Box, Typography, Button } from "@mui/material";
const WideListTypeContent = ({ data }) => {

  const header = data.header
  const items = data.items ?? []
  const buttons = data.buttons ?? []

  const handleImageClick = (data) => {
    if (data?.imgLink) {
      window.open(data.imgLink);
    }
  }

  return (
    <Box
      sx={{ width: "246px" }}
      className="message-content default-message-content"
    >
      <Typography textAlign={"center"} fontWeight={"600"} color="grey7">
        {header || "타이틀"}
      </Typography>
      <Box
        className="preview-image-wrap clickable wide-list"
        sx={{
          width: "100%",
          height: "115px",
          backgroundImage: items[0]?.imgUrl
            ? `url(${items[0]?.imgUrl})`
            : "linear-gradient( 0deg, rgb(145 143 143), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) );",
        }}
        onClick={() => handleImageClick(items.get(0))}
      >
        <Typography sx={{ color: "#FFF", p: 2 }} fontSize={"14px"}>
          {items[0]?.title}
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        {Array.from({ length: Math.max(2, items.length - 1) }, (_, index) => {
          const item = items[index + 1] ?? {}
            return (
              <Box
                key={"wide-list-type-content" + index}
                sx={{
                  display: "flex",
                  gap: 1,
                  mt: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  className="preview-list-wrap clickable"
                  onClick={() => handleImageClick(item)}
                  sx={{
                    backgroundImage: `url(${item.imgUrl})`,
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "174px",
                  }}
                >
                  <Typography
                    sx={{ wordBreak: "break-word", fontSize: "14px" }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </Box>
            );
          })}
      </Box>
      <Box className="flex-center-all" style={{padding: "8px", gap: "5px"}}>
      {buttons?.filter((button) => button.type === "WL" || button.type === "COUPON")
          .map((button, index) => {
            return (
                <Button
                    className="preview-button"
                    variant="outlined"
                    color={"grey7"}
                    onClick={() => window.open(button.link)}
                >
                  {button.name || "메시지 버튼"}
                </Button>
            );
          })}
      </Box>
    </Box>
  );
};

export default WideListTypeContent;
