import Layout from "../components/layout/Layout";
import BrandDetail from "../components/page/BrandDetail";

const BrandPage = () => {
  return (
    <Layout>
      <BrandDetail />
    </Layout>
  );
};
export default BrandPage;
