import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const getPartnerStatistics = async (startDate, endDate) => {
    const res = await fetchApi.get(
        API_URL.PARTNER_BENEFIT_STATSTICS +
            `?startDate=${startDate}&endDate=${endDate}`
    );
    if (res.status === 200) {
        return res.data;
    }
    return {};
};

export const getSpotStatistics = async (spotId, startDate, endDate) => {
    const res = await fetchApi.get(
        API_URL.SPOT_BENEFIT_STATSTICS.replace("{spotId}", spotId) +
            `?startDate=${startDate}&endDate=${endDate}`
    );
    if (res.status === 200) {
        return res.data;
    }
    return {};
};
