import MobilePreview from "../../MobilePreview";
import {Button} from "omoplay";

const EventLoginRoulettePreview = ({eventImage, eventButtonImage, eventBackgroundImage, loginButtonName, loginPrecautionName, loginPrecautionContent}) => {
    const API_HOST = process.env.REACT_APP_API_HOST
    const url = `${API_HOST}/view/event?temp=true&login=true` +
        `&eventImage=${encodeURIComponent(eventImage)}` +
        `&eventButtonImage=${encodeURIComponent(eventButtonImage)}` +
        `&eventBackgroundImage=${encodeURIComponent(eventBackgroundImage)}` +
        `&loginButtonName=${encodeURIComponent(loginButtonName)}` +
        `&loginPrecautionName=${encodeURIComponent(loginPrecautionName)}` +
        `&loginPrecautionContent=${encodeURIComponent(loginPrecautionContent)}`

    return (
        <div className="flex-col items-center gap-2">
            <MobilePreview>
                <iframe
                    className="relative h-full"
                    src={url}
                    title="OMOPLAY TEMP"
                />
            </MobilePreview>
            <div>
                <Button variants="outlined" value="미리보기" onClick={() => window.open(url)} />
            </div>
        </div>
    )
}

export default EventLoginRoulettePreview