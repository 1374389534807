import InputGroup from "../InputGroup";
import BenefitMessageSettingForm from "./BenefitMessageSettingForm";
import BenefitMessagePreview from "../../layout/BenefitMessagePreview";
import NoImage from "../../../assets/images/no-image.svg"
import Badge from "../badge/Badge";
import BenefitTitle from "./BenefitTitle";

const BenefitPrizeMessage = ({benefitDetails, onImageChange, onMessageChange, onButtonNameChange}) => {
    const benefitData = benefitDetails().benefit
    const prizeMessage = benefitData.messageText ?? benefitDetails().message
    const regex = benefitDetails().variableReg
    const variable = benefitDetails().variable
    const buttonName = benefitData.buttonName ?? benefitDetails().buttonName

  return (
      <div className="flex" style={{ paddingLeft: "34px", height: "100%"}}>
        <div style={{flexBasis: "50%"}}>
          <InputGroup
              label={"기본 당첨 메시지"}
              isRequired={true}
              className="py-8"
          >
            <div>혜택에 대한 기본 당첨 메시지를 설정해 주세요.</div>
          </InputGroup>
          <div style={{paddingRight: "20px"}}>
              <div className="bg-grey-0 border rounded" style={{paddingLeft: "32px", paddingRight: "32px"}}>
                  <BenefitMessageSettingForm image={benefitData.messageImage} regex={regex} variables={variable}
                                             onImageChange={onImageChange}
                                             message={prizeMessage} onMessageChange={onMessageChange}
                                             buttonName={buttonName} onChangeButtonName={onButtonNameChange}
                  />
              </div>
          </div>
        </div>
        <div className="bg-grey-0 flex-align-center flex-col gap-6" style={{flexBasis: "50%"}}>
            <div style={{marginTop: "20px"}}>
                <BenefitTitle name={benefitData.name} retailPrice={benefitData.retailPrice} />
            </div>
            <Badge name={benefitDetails().label} color="blue" padding="6px 40px" />
            <BenefitMessagePreview
              preview={
                <div className="relative h-full bg-repeat-round bg-contain bg-[#ABC1D1] p-4 max-h-[460px] overflow-auto">
                  <h4 className="font-medium text-gray-800 pl-2 py-2">
                    당첨 카카오톡 안내
                  </h4>
                  <div className="flex w-full flex-col justify-center bg-white rounded-lg p-2.5">
                      <img src={benefitData.messageImage ?? NoImage} />
                    <div className="mt-2 text-xs whitespace-pre-line">{prizeMessage}</div>
                    <button className="bg-[#F5F5F5] py-1 text-[#474A4E] text-sm font-medium rounded mt-4">
                        {buttonName}
                    </button>
                  </div>
                </div>
              }
            />
        </div>
      </div>
  )
};

export default BenefitPrizeMessage;
