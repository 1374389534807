import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";
import { Cookies } from "react-cookie";

export const getAccount = async () => {
  const res = await fetchApi.get(API_URL.ACCOUNT)

  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};

export const logout = () => {
  const cookies = new Cookies();
  cookies.remove("token");
  window.location.href = "/login";
};

export const updateHostingPlatform = async (hostingName, hostingId, hostingPwd) => {
  const res = await fetchApi.post(API_URL.ACCOUNT_HOSTING, {
    hostingName: hostingName,
    hostingId: hostingId,
    hostingPwd: hostingPwd
  })

  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};

export const kakaoProfileChannelAuth = async (channelName, phoneNumber) => {
  try {
    const res = await fetchApi.post(API_URL.KAKAO_PROFILE_CHANNEL_AUTH, {
      channelName: channelName,
      phoneNumber: phoneNumber
    });
    if (res.status === 200) {
      return res.data;
    } else {
      return {};
    }
  } catch (e) {
    return e.response;
  }
};

export const channelAuthorization = async (plusFriendName, phoneNumber, code) => {
  try {
    const res = await fetchApi.post(API_URL.CHANNEL_AUTHORIZATION, {
      plusFriendName: plusFriendName,
      phoneNumber: phoneNumber,
      code: code
    });
    if (res.status === 200) {
      return res.data;
    } else {
      return {};
    }
  } catch (e) {
    return e.response;
  }
};

export const postAgentRegistration = async () => {
  const res = await fetchApi.get(API_URL.ACCOUNT_AGENT_REGISTRATION)
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const postConnectRegistration = async () => {
  const res = await fetchApi.get(API_URL.ACCOUNT_CONNECT_REGISTRATION)
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const getCredit = async () => {
  const res = await fetchApi.get(API_URL.ACCOUNT_CREDIT)
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const postAutoChargeCredit = async (isOn, type, minCredit, chargeCredit) => {
  const res = await fetchApi.post(API_URL.ACCOUNT_AUTO_CHARGE_CREDIT, {
    isOn: isOn,
    type: type,
    minCredit: minCredit,
    chargeCredit: chargeCredit
  })
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const getReservedCredits = async () => {
  const res = await fetchApi.get(API_URL.ACCOUNT_RESERVED_CREDIT)
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const getChannelInfo = async (id) => {
  const res = await fetchApi.get(API_URL.ACCOUNT_CHANNEL + `?id=${id}`)
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const postChannelRegistration = async (channelName, publicId) => {
  const res = await fetchApi.get(API_URL.ACCOUNT_CHANNEL, {
    channelName: channelName,
    publicId: publicId
  })
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const postCounsel = async (name, phoneNumber, url, framework, isDeveloper, other) => {
  const res = await fetchApi.post(API_URL.ACCOUNT_COUNSEL, {
    name: name,
    phoneNumber: phoneNumber,
    url: url,
    framework: framework,
    isDeveloper: isDeveloper,
    other: other
  })
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}