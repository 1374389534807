import {fetchApi} from "../fetch";
import {API_URL} from "../../constants/axios";

export const getBrand = async () => {
    const res = await fetchApi.get(`${API_URL.BRAND}`)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const saveBrand = async (name, logoUrl, targetDescription) => {
    const res = await fetchApi.post(`${API_URL.BRAND}`, {
        name: name,
        logoUrl: logoUrl,
        targetDescription: targetDescription
    })
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}