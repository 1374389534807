import Layout from "../components/layout/Layout";
import SpotPage from "../components/page/Spot";

const HomePage = () => {
  return (
    <Layout>
      <SpotPage />
    </Layout>
  );
};
export default HomePage;
