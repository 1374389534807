import {useEffect, useState} from "react";
import {getProjectPrizeUsers} from "../../../api/project/project.api";
import {Pagination, Table, TextInput} from "omoplay";


const BenefitSimplePrizeUsers = ({projectId, benefitId}) => {
    const [prizeUsers, setPrizeUsers] = useState([])
    const [headers, setHeaders] = useState([])
    const [page, setPage] = useState(1)
    const [totalSize, setTotalSize] = useState(0)
    const [userName, setUserName] = useState("")
    const [isSearch, setSearch] = useState(true)
    const maxSize = 10

    useEffect(() => {
        if (isSearch) {
            getProjectPrizeUsers(projectId, benefitId, userName, page, maxSize).then(({data}) => {
                setSearch(false)
                const type = data.type
                setTotalSize(data.totalSize)
                setPrizeUsers(data.prizeUsers.map((user, index) => {
                    const commonUsers = [
                        <div className="text-center">{index + 1}</div>,
                        <div className="text-center">{user.name}</div>,
                        <div className="text-center">{user.gender}</div>,
                        <div className="text-center">{user.phoneNumber}</div>
                    ]
                    if (type === "DELIVERY") {
                        setHeaders([
                            { name: "", align: "center", width: "90px"}, { name: "당첨자 이름", align: "center", width: "120px"},
                            { name: "성별", align: "center", width: "100px"}, { name: "전화번호", align: "center", width: "160px"},
                            { name: "수령자 정보"}, { name: "제출 일자"}
                        ])
                        commonUsers.push(
                            <div>
                                <div className="font-bold text-primary">{user.receiverName ?? ""}</div>
                                <div>{user.receiverAddress ?? ""}</div>
                                <div>{user.receiverPhoneNumber ?? ""}</div>
                            </div>
                        )
                        commonUsers.push(<div>{user.receiverConfirmAt ?? ""}</div>)
                    } else {
                        setHeaders([
                            { name: "", align: "center", width: "90px"}, { name: "당첨자 이름", align: "center", width: "120px"},
                            { name: "성별", align: "center", width: "100px"}, { name: "전화번호", align: "center", width: "160px"},
                            { name: "당첨 진입점"}, { name: "당첨 일시"}, { name: "사용 일시"}
                        ])
                        commonUsers.push(<div>{user.spotName}</div>)
                        commonUsers.push(<div>{user.prizeAt}</div>)
                        commonUsers.push(<div>{user.usedAt}</div>)
                    }
                    return commonUsers
                }))
            })
        }
    }, [projectId, benefitId, isSearch, page])


    return (
        <div className="flex-col gap-4 pt-4">
            <div className="font-bold font-16px">총 {totalSize.toLocaleString()} 명</div>
            <div className="flex"><TextInput placeholder="검색하기" showicon={true} value={userName}
                                             onKeyUp={(e) => {
                                                 if (e.key === "Enter") {
                                                     setSearch(true)
                                                 }
                                             }}
                                             onChange={(e) => {
                                                 setUserName(e.target.value)
                                             }}
            /></div>
            <div className="font-bold">당첨자</div>
            <div>
                <Table headers={headers} data={prizeUsers} />
                <div className="flex justify-center" style={{marginTop: "24px"}}>
                    <Pagination rowSize={maxSize} totalCount={totalSize} currentPage={page} onPaging={(page) => {
                        setPage(page)
                        setSearch(true)
                    }} />
                </div>
            </div>
        </div>
    )
}

export default BenefitSimplePrizeUsers