import {getProductDetail} from "../../api/product/product.api";
import {useEffect, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import InputGroup from "../form/InputGroup";
import {Button, Panel, Tag} from "omoplay";
import NoImage from "../../assets/images/no-image.svg";
import EditPenIcon from "../../assets/images/icon.edit.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

const ProductDetail = () => {
    const [query] = useSearchParams()
    const [product, setProduct] = useState({})
    const [isOpen, setIsOpen] = useState(false);
    const [isEditName, setIsEditName] = useState(false)
    const mainPropertyScrollRef = useRef(null);
    const propertyScrollRef = useRef(null);
    const imageScrollRef = useRef(null);
    const [isDrag, setIsDrag] = useState(false);
    const [startX, setStartX] = useState(0);
    const id = query.get("id")

    const productInfo = product.info

    useEffect(() => {
        getProductDetail(id).then(({data}) => {
            setProduct(data)
        })
    }, [query]);

    const onDragStart = (e, ref) => {
        e.preventDefault();
        if (ref.current) {
            setIsDrag(true);
            setStartX(e.pageX + ref.current.scrollLeft);
        }
    };

    const onDragEnd = () => {
        setIsDrag(false);
    };

    const onDragMove = (e, ref) => {
        if (isDrag && ref.current) {
            ref.current.scrollLeft = startX - e.pageX;
        }
    };

    return (
        <>
            <div className="flex-col gap-[8px]">
                <div className="flex-align-center justify-between">
                    <div className="flex-align-center gap-[8px]">
                        <div className="bg-white" style={{borderRadius: "6px"}}>
                            <Button variants="outlined"
                                    style={{width: "40px"}}
                                    onClick={() => window.location.href = ("/product")}
                                    value={<FontAwesomeIcon icon={faArrowLeft} />}
                            />
                        </div>
                        <div className="font-extrabold pt-[24px] pb-[24px] text-[28px]">{productInfo?.productName}</div>
                    </div>
                    <div>
                        <Button variants="primary" size="large" value="수정하기"
                                style={{width: "160px"}}
                                onClick={() => window.location.href = `/product/edit?id=${id}`} />
                    </div>
                </div>
                <InputGroup label="제품 상세 정보" description="여기에 미리 정의된 제품 상세정보 내용이 AI생성에 사용됩니다.">
                    <div className="flex gap-8">
                        <div className="flex gap-8">
                            <div className="flex-col gap-2">
                                <div className="text-secondary font-bold">제품 대표 이미지</div>
                                <div><img alt="main-image" src={productInfo?.images[0] ?? ""}
                                          className="max-w-[216px] max-h-[306px] min-w-[216px] min-h-[306px]" /></div>
                            </div>
                            {/*
                            <div className="flex-col gap-2">
                                <div className="text-secondary font-bold">배경없는 이미지</div>
                                <div><img alt="no-image" src={NoImage} width={216} height={306} /></div>
                            </div>
                            */}
                        </div>
                        <div>
                            <div className="text-secondary font-bold">제품 정보</div>
                            <div>
                                <Panel variants="list-bar" items={[
                                    {
                                        title: "제품 이름",
                                        value: productInfo?.productName,
                                        titleStyle: {minWidth: "150px"}
                                    },
                                    {
                                        title: "제품 코드/상품 코드",
                                        value: productInfo?.productCode,
                                        titleStyle: {minWidth: "150px"}
                                    },
                                    {
                                        title: "가격 (현재가격 / 정상가격 / 할인율)",
                                        value: `${productInfo?.price ?? ""} / ${productInfo?.originalPrice ?? ""} / ${productInfo?.discountRate ?? ""}`,
                                        titleStyle: {minWidth: "150px", maxWidth: "150px"}
                                    },
                                    {
                                        title: "제품 카테고리",
                                        value: productInfo?.category,
                                        titleStyle: {minWidth: "150px"}
                                    },
                                    {
                                        title: "제품 옵션 및 가격",
                                        value: productInfo?.options.map(option => {
                                            return (
                                                <div>{option.toString()}</div>
                                            )
                                        }),
                                        titleStyle: {minWidth: "150px"}
                                    },
                                    {
                                        title: "제품 URL",
                                        value: product.url,
                                        titleStyle: {minWidth: "150px"}
                                    }
                                ]} />
                            </div>
                        </div>
                    </div>
                </InputGroup>
                <InputGroup label="대표 제품 이미지에서 추출한 제품 속성">
                    <div className="flex gap-[4px] overflow-x-scroll"
                         onMouseDown={(e) => onDragStart(e, mainPropertyScrollRef)}
                         onMouseMove={(e) => onDragMove(e, mainPropertyScrollRef)}
                         onMouseUp={onDragEnd}
                         onMouseLeave={onDragEnd}
                         ref={mainPropertyScrollRef}>
                        {
                            productInfo?.imageProperties?.map(property => {
                                return (<Tag style={{minWidth: "fit-content"}}>{property}</Tag>)
                            })
                        }
                    </div>
                </InputGroup>
                <InputGroup label="상세페이지에서 추출한 제품 속성">
                    <div className="flex gap-[4px] overflow-x-scroll"
                         onMouseDown={(e) => onDragStart(e, propertyScrollRef)}
                         onMouseMove={(e) => onDragMove(e, propertyScrollRef)}
                         onMouseUp={onDragEnd}
                         onMouseLeave={onDragEnd}
                         ref={propertyScrollRef}>
                        {
                            productInfo?.imagesProperties?.map(property => {
                                return (<Tag style={{minWidth: "fit-content"}}>{property}</Tag>)
                            })
                        }
                    </div>
                </InputGroup>
                <InputGroup label="수집한 이미지 정보" description="클릭하면 원본 이미지를 확인할 수 있습니다.">
                    <div className="flex gap-[4px] overflow-x-scroll"
                         style={{height: "80px"}}
                         onMouseDown={(e) => onDragStart(e, imageScrollRef)}
                         onMouseMove={(e) => onDragMove(e, imageScrollRef)}
                         onMouseUp={onDragEnd}
                         onMouseLeave={onDragEnd}
                         ref={imageScrollRef}
                    >
                        {
                            productInfo?.images.map(image => {
                                return (<img alt="parsing-image"
                                             src={image} width={80} height={80}
                                             onClick={() => setIsOpen(image)
                                }
                                />)
                            })
                        }
                    </div>
                    {isOpen && (
                        <div
                            onClick={() => setIsOpen(undefined)}
                            style={{
                                position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh",
                                backgroundColor: "rgba(0, 0, 0, 0.8)", display: "flex",
                                justifyContent: "center", alignItems: "center"
                            }}
                        >
                            <img
                                src={isOpen}
                                alt={"original-image"}
                                style={{ objectFit: "contain", borderRadius: "8px" }}
                            />
                        </div>
                    )}
                </InputGroup>
                <InputGroup label="AI가 만든 상품 상세 페이지">
                    <div className="whitespace-pre-line">
                        {
                            productInfo?.summary
                        }
                    </div>
                </InputGroup>
            </div>
        </>
    );
};

export default ProductDetail;
