import IconMore from "../../assets/images/icon.more.svg"

const MiniMenu = ({id, openMenuId, setOpenMenuId, onClickEdit, disabledEdit, onClickPreview, onClickDelete, disabledDelete}) => {
    const isOpen = openMenuId === id;

    return (
        <div>
            <img alt="" src={IconMore} onClick={() => setOpenMenuId(prevId => (prevId === id ? null : id))} />
            {
                isOpen && (
                    <div>
                        <ul className="absolute bg-white rounded-lg text-sm shadow-lg border border-gray-200" style={{width: "140px", marginLeft: "-100px"}}>
                            <li
                                className={`py-3 px-6 rounded-t-lg ${disabledEdit ? 'bg-grey-1' : 'hover:bg-gray-50 cursor-pointer'}`}
                                onClick={(e) => {
                                    if (!disabledEdit) {
                                        onClickEdit(e)
                                    }

                                }}
                            >
                                수정하기
                            </li>
                            {
                                onClickPreview && (
                                    <li
                                        className="hover:bg-gray-50 cursor-pointer py-3 px-6 rounded-t-lg"
                                        onClick={onClickPreview}
                                    >
                                        미리보기
                                    </li>
                                )
                            }
                            {
                                onClickDelete && (
                                    <li
                                        className={`hover:bg-gray-50 py-3 px-6 rounded-b-lg text-red-500 ${disabledDelete ? 'none' : 'cursor-pointer'}`}
                                        onClick={onClickDelete}
                                    >
                                        삭제하기
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                )
            }
        </div>
    )
}

export default MiniMenu