import MiniDefaultTypeContent from "./MiniDefaultTypeContent";
import MiniWideTypeContent from "./MiniWideTypeContent";
import MiniWideListTypeContent from "./MiniWideListTypeContent";
import MiniCarouselTypeContent from "./MiniCarouselTypeContent";
import {ArrowDropDownIcon, ArrowDropUpIcon, Button, FlexMessage} from "omoplay";
import React, {useEffect, useState} from "react";
import SuspensePopup from "../../../SuspensePopup";
import OmoplayImageEditor from "../../../OmoplayImageEditor";
import {updateImage} from "../../../../api/ai/ai.api";
import {getProductList} from "../../../../api/product/product.api";

const MessageMiniPreview = ({ id, messageData, productIds, onChangeImage }) => {
    const [initEditors, setInitEditors] = useState([])
    const [isOpen, setOpen] = useState(false)
    const [indexShowImageEditor, setIndexShowImageEditor] = useState(undefined)
    const [editors, setEditors] = useState([])
    const [products, setProducts] = useState([])
    const type = messageData.type

    const miniContentsRender = () => {
        if (type === "FI" || type === "FT") {
          return <MiniDefaultTypeContent data={messageData} />
        } else if (type === "FW") {
          return <MiniWideTypeContent data={messageData} />
        } else if (type === "FL") {
          return <MiniWideListTypeContent data={messageData} />
        } else if (type === "FC") {
          return <MiniCarouselTypeContent data={messageData} />
        }
    };

    useEffect(() => {
        if (type === "FI" || type === "FT" || type === "FW") {
            setInitEditors([{id: '0', mainImageUrl: messageData.image.originalUrl}])
        } else if (type === "FL") {
            const items = messageData.items ?? []
            setInitEditors(items.map((item, index) => ({id: index.toString(), mainImageUrl: item.originalUrl})))
        } else if (type === "FC") {
            const carousels = messageData.carousels ?? []
            setInitEditors(carousels
                .filter((carousel) => carousel.attachment?.image != null)
                .map((carousel, index) => ({id: index.toString(), mainImageUrl: carousel.attachment.image.originalUrl})))

        }

        console.log(productIds)
        if (productIds && productIds.length > 0) {
            getProductList(productIds).then(({data}) => {
                console.log(data)
                setProducts(data)
            })
        }
    }, []);

    return (
        <div>
          <div className={ isOpen ? "flex-col" : "flex-align-center"}>
              <div className="flex-1">
                  {
                      isOpen ? <FlexMessage data={messageData} status="AI_DONE"
                                            onClickEditButton={(index) => setIndexShowImageEditor(index?.toString())} />
                          : <div className="flex gap-2">{miniContentsRender()}</div>
                  }
              </div>
              <div className={`flex-align-center gap-[12px] ${isOpen && "mt-[12px] justify-end"}`}>
                  <Button variants="outlined" value="메시지 미리보기" onClick={() => setOpen(!isOpen)} />
                  <Button variants="rounded" value={ isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          style={{backgroundColor: "white", justifyContent: "center", alignItems: "center", border: "1px solid #e5e7eb"}}
                          onClick={() => setOpen(!isOpen)}
                  />
              </div>
          </div>
          <SuspensePopup
              title="AI 이미지 편집기"
              visible={indexShowImageEditor !== undefined}
              style={{width: "960px"}}
              confirmText="적용하기"
              onConfirm={async () => {
                  const blobs = await Promise.all(
                      initEditors.map(async (_, i) => {
                          return await editors[i].ref.current.toBlob()
                      })
                  );
                  const formData = new FormData();
                  blobs.forEach(blob => formData.append("images", blob));
                  updateImage(id, formData).then(() => {
                      alert("저장 되었습니다.")
                      setIndexShowImageEditor(undefined)
                      onChangeImage()
                      setOpen(false)
                  })
              }}
              onClose={() => {
                  if (window.confirm("변경된 내용이 모두 사라집니다. 닫으시겠습니까?")) {
                      setIndexShowImageEditor(undefined)
                  }
              }}
          >
              <OmoplayImageEditor messageData={messageData}
                                  initEditors={initEditors}
                                  index={indexShowImageEditor}
                                  products={products}
                                  onChangeEditors={(editors) => {
                                      setEditors(editors)
                                  }}
                                  onUploadImage={(originalUrl, index) => {
                                      setInitEditors((prev) => {
                                          prev[index].mainImageUrl = originalUrl
                                          return [...prev]
                                      })
                                  }}
              />
          </SuspensePopup>
        </div>
    );
};

export default MessageMiniPreview;
