import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortableTableHeader from "../table/SortableTableHeader";
import { useCallback, useEffect, useState } from "react";
import Pagination from "../Pagination";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";
import { cashFamilySelector } from "../../store/selector/cashHistory";
import moment from "moment";
import Loading from "../Loading";
import { partnerState } from "../../store/state/partner";
import { partnerSelector } from "../../store/selector/partner";

export const CashWrap = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [totalPage, setTotalPages] = useState(1);
  const [size] = useState(10);
  const [data, setData] = useState([]);
  const startOfMonth = moment()
    .subtract(5, "years")
    .startOf("month")
    .format("YYYY-MM-DD");
  const endOfMonth = moment()
    .endOf("month")
    .add("9", "hours")
    .format("YYYY-MM-DD");

  const [loading] = useRecoilStateLoadable(
    cashFamilySelector([page - 1, size, startOfMonth, endOfMonth].join("|"))
  );
  const refresh = useRecoilRefresher_UNSTABLE(
    cashFamilySelector([page - 1, size, startOfMonth, endOfMonth].join("|"))
  );
  const partner = useRecoilValue(partnerSelector);

  const fetchCashState = useCallback(() => {
    if (loading.state !== "loading") {
      const data = loading.contents;
      setLoading(false);
      setData(data.content);
      setTotalPages(data.totalPages);
    }
  }, [loading]);

  useEffect(() => {
    fetchCashState();
  }, [loading]);

  const headers = [
    { key: "issuedAt", label: "처리 날짜", sortable: false },
    { key: "name", label: "내역", sortable: false, className: "w-[630px]" },
    {
      key: "amount",
      label: "변동 금액",
      sortable: false,
      className: "w-[200px]",
    },
    {
      key: "balance",
      label: "잔여 캐시",
      sortable: false,
      className: "w-[200px]",
    },
  ];

  const renderCurrentCashInfo = (value) => {
    if (value < 0) {
      return (
        <div className="flex items-center gap-4">
          <p className="text-red-600">
            - {(value * -1).toLocaleString()}
            <span className="text-sm ml-1">원</span>
          </p>
          <div className="py-1 px-4 bg-red-100 text-red-600 text-xs rounded-xl">
            캐시가 부족합니다
          </div>
        </div>
      );
    } else {
      return (
        <p className="text-3xl font-bold">
          {value.toLocaleString()}
          <span className="text-sm ml-1">원</span>
        </p>
      );
    }
  };

  const renderValue = (value) => {
    if (value < 0) {
      return (
        <p className="text-gray-400">- ₩{(value * -1).toLocaleString()}</p>
      );
    } else {
      return <p className="text-indigo-600">+ ₩{value.toLocaleString()}</p>;
    }
  };

  const renderBalance = (value) => {
    if (value < 0) {
      return <p>-₩{(value * -1).toLocaleString()}</p>;
    } else {
      return <p>₩{value.toLocaleString()}</p>;
    }
  };
  return (
    <div className="w-full mt-8 flex-col gap-8">
      <div className="flex gap-4 text-sm">
        <div className="p-6 bg-white border border-gray-300 w-1/2">
          <div className="flex justify-between items-center">
            <p className="text-gray-700">사용 가능 캐시</p>
            <p className="text-red-400 text-xs">
              <FontAwesomeIcon icon={faCoins} className="mr-2" />
              캐시 충전은 별도 문의해 주세요
            </p>
          </div>
          <div className="mt-5">
            <p className="text-3xl font-bold">
              {renderCurrentCashInfo(partner.currentCash)}
            </p>
          </div>
        </div>
        <div className="p-6 bg-white border border-gray-300 w-1/2">
          <div className="flex justify-between items-center h-full">
            <div>
              <p className="text-gray-700 mb-1">월별 청구서 조회 가능일</p>
              <p className="text-gray-400 text-xs">
                월 별 청구서 탭에서 조회 가능합니다
              </p>
            </div>
            <p className="text-lg font-semibold">익월 5일</p>
          </div>
        </div>
      </div>
      <div>
        <h4 className="font-semibold text-xl mb-2">캐시 충전/사용 내역</h4>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 border mb-4 relative">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 rounded-tl border-b">
            <tr className="divide-x">
              {headers.map((header, index) => {
                return (
                  <SortableTableHeader
                    key={"cash-wrap-table-header" + index}
                    header={header}
                  />
                );
              })}
            </tr>
          </thead>
          {isLoading ? (
            <tbody>
              <tr>
                <td colSpan={7} className="text-center">
                  <Loading className="w-12 h-12 mx-auto my-6" />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {data.map((row, index) => {
                const cs = "px-3 py-2 ";
                return (
                  <tr
                    className="bg-white border-b hover:bg-gray-50 divide-x"
                    key={"cash-history-" + index}
                  >
                    <td className={cs}>
                      {moment(row.issuedAt)
                        .add(9, "hours")
                        .format("YYYY-MM-DD HH:mm")}
                    </td>
                    <td className={cs}>{row.name}</td>
                    <td className={cs + "text-right"}>
                      {renderValue(row.value)}
                    </td>
                    <td className={cs + "font-semibold text-right"}>
                      {renderBalance(row.balance.toLocaleString())}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        <div className="w-100 flex justify-center mt-2">
          <Pagination page={page} totalPages={totalPage} setPage={setPage} />
        </div>
      </div>
    </div>
  );
};
export default CashWrap;
