import {kakaoImageUpload} from "../../../api/file/file.api";
import {Button, TextInput} from "omoplay";
import VariableTextArea from "../VariableTextArea";
import React, {useRef} from "react";

const BenefitMessageSettingForm = ({image, onImageChange, message, onMessageChange, buttonName, onChangeButtonName, regex, variables}) => {
    const fileInputRef = useRef(null)

    const uploadImage = (e) => {
        kakaoImageUpload(e.target.files[0]).then(({data}) => {
            if (!data?.imageUrl) {
                alert(data.message)
            } else {
                onImageChange(data?.imageUrl)
            }
            e.target.value = null
        })
    }

    return (
        <div className="flex-col gap-8">
            <div className="flex-col gap-2">
                <div className="font-semibold">메시지 이미지</div>
                <div className="flex gap-2">
                    <div className="flex-1">
                        <input
                            accept="image/jpg, image/jpeg, image/png"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            type="file"
                            onChange={(e) => uploadImage(e)}
                        />
                        <TextInput
                            readOnly={true}
                            placeholder="이미지 파일을 업로드 해주세요."
                            value={image}
                        />
                    </div>
                    <Button
                        variants="dark"
                        value="첨부"
                        onClick={() => {
                            if (fileInputRef.current) {
                                fileInputRef.current.click();
                            }
                        }}
                        style={{ width: "105px", height: "40px" }}
                    />
                </div>
                <div className="text-tertiary" style={{fontSize: "12px"}}>
                    가로 500px 이상, 최소 비율 2:1, 최대 비율 3:4 지원 포맷: png,jpeg (최대 5MB)
                </div>
            </div>
            <div className="flex-col gap-2">
                <div className="font-semibold">당첨 안내 설정</div>
                <VariableTextArea message={message} variables={variables} regex={regex} maxLength={400} onChangeMessage={onMessageChange} />
            </div>
            <div className="flex-col gap-2">
                <div className="font-semibold">버튼명</div>
                <TextInput value={buttonName} maxLength={8} onChange={(e) => onChangeButtonName(e.target.value)} />
            </div>
        </div>
    )
}

export default BenefitMessageSettingForm