import {Box, TextField} from "@mui/material";
import SettingFormLabel from "../SettingFormLabel";
import { messageFileUpload } from "../../../api/file/file.api";
import NormalButton from "../../NormalButton";
import LabelNormalButton from "../../LabelNormalButton";
import InputGroup from "../InputGroup";
import VariableTextArea from "../VariableTextArea";
import React from "react";

const SettingItemForm = ({index, variables, regex, item, onChangeImage, onChangeTitle, onChangeImageLink}) => {
  const imgUrl = item?.imgUrl
  const title = item?.title
  const urlMobile = item?.urlMobile

  const uploadImage = (e) => {
    messageFileUpload(
      "wide_list",
      e.target.files[0],
      (res) => {
        if (res.status === 200) {
          const data = res.data;
          if (data.code === "0") {
            onChangeImage(res.data.imageUrl);
          } else {
            alert(data.message);
          }
        } else {
          alert(
            res.status +
              "에러가 발생했습니다. 헤드리스 담당자에게 문의 해주세요."
          );
        }
      },
      (error) => {
        alert(error);
      }
    );
  };

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: "#F2F4F7",
        borderRadius: "6px",
      }}
    >
      <Box>
        <InputGroup
            label={"이미지 업로드"}
            isRequired={true}
        >
          <Box sx={{ display: "flex", gap: "8px", mt: 1 }}>
            <input
                accept="image/jpg, image/jpeg, image/png"
                style={{ display: "none" }}
                id={`settings-${index}`}
                type="file"
                onChange={(e) => uploadImage(e)}
            />
            <TextField
                inputProps={{ readOnly: true }}
                fullWidth
                placeholder="이미지 파일을 업로드 해주세요."
                helperText={
                    (index === 0
                        ? "* 권장사이즈 : 800 x 400px"
                        : "* 권장사이즈 : 400 x 400px") +
                    " / 지원파일 : JPG, JPEG, PNG (최대 2MB)"
                }
                size={"small"}
                value={imgUrl}
            />
            <LabelNormalButton
                className={"whitespace-nowrap py-2 px-4 text-sm h-fit"}
                htmlFor={`settings-${index}`}
                sx={{ height: "40px", whiteSpace: "nowrap" }}
            >
              업로드 하기
            </LabelNormalButton>
          </Box>
        </InputGroup>
      </Box>
      <Box sx={{ mt: 2 }}>
        <SettingFormLabel
          label={"내용"}
          isRequired={true}
          sx={{ fontSize: "14px" }}
        />
        <Box sx={{ mt: 1 }}>
          <VariableTextArea message={title} variables={variables} regex={regex} maxLength={25} onChangeMessage={onChangeTitle} />
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <SettingFormLabel
          label={"링크"}
          isRequired={true}
          sx={{ fontSize: "14px" }}
        />
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            mt: 1,
          }}
        >
          <TextField
            sx={{ flex: 1, backgroundColor: "#FFF" }}
            size={"small"}
            placeholder="연결할 페이지의 URL을 입력해주세요."
            value={urlMobile}
            onChange={(e) => onChangeImageLink(e.target.value)}
          />
          <NormalButton
            variant={"outlined"}
            onClick={() => window.open(urlMobile)}
            className={"whitespace-nowrap py-2 px-4 text-sm"}
          >
            URL 확인
          </NormalButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingItemForm;
