import Layout from "../components/layout/Layout";
import UserDetail from "../components/page/UserDetail";

const UserDetailPage = () => {
  return (
    <Layout>
      <UserDetail />
    </Layout>
  );
};
export default UserDetailPage;
