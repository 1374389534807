import Layout from "../components/layout/Layout";
import AIAgentMessageList from "../components/page/AIAgentMessageList";

const AIAgentMessageListPage = () => {
  return (
    <Layout>
      <AIAgentMessageList />
    </Layout>
  );
};
export default AIAgentMessageListPage;
