export const DEFAULT_HEADER = {
  header: {
    Accept: "application/json",
    "Content-Type": `application/json;charset=UTP-8`,
  },
};

export const API_HOST = process.env.REACT_APP_API_HOST;
export const MESSAGE_API_HOST = process.env.REACT_APP_MESSAGE_HOST;
export const API_URL = {
  LOGIN: `/user/login`,
  LOGIN_CHECK: `/user/check`,
  USER_INFO: `/user/my/info`,

  PARTNER: `/partner`,
  PARTNER_AUTH: `/partner/auth`,
  PARTNER_REGISTRATION: `/partner/registration`,

  GET_SPOTS: `/spot`,
  GET_ALL_SPOTS: `/spot/all`,
  GET_SPOTS_STATISTICS: `/spot/statistics`,
  CREATE_SPOT: `/spot`,
  GET_SPOT: `/spot/{spotId}`,
  UPDATE_SPOT: `/spot/{spotId}`,
  CREATE_SPOT_FOLDER: `/spot-folders`,
  UPDATE_SPOT_FOLDER: `/spot-folders/{id}`,
  DELETE_SPOT_FOLDER: `/spot-folders/{id}`,
  UPDATE_SPOTS_FOLDER_BATCH: `/spot-folders/batch`,
  UPDATE_SPOTS_BENEFIT_BATCH: `/spot/batch/benefit`,
  DUPLICATE_SPOT_CHECK: `/spot/duplicate`,
  DUPLICATE_SPOT_FOLDER_CHECK: `/spot/folder/duplicate`,

  DELETE_SPOT: `/spot/{id}`,

  GET_FOLDERS: `/spot-folders`,
  CREATE_FOLDER: `/spot-folders`,

  GET_COUPONS: `/event/`,
  GET_COUPON: `/event/{id}`,
  CREATE_COUPON: "/event",
  UPDATE_COUPON: "/event/{id}",
  DELETE_COUPON: "/event/delete",

  GET_BENEFITS: `/benefit`,
  GET_BENEFIT_LEGACY: `/benefit/{id}`,
  GET_BENEFIT: `/benefits/{id}`,
  LIST_BENEFIT: 'benefits',
  POST_BENEFIT: 'benefits',
  COPY_BENEFIT: `/benefit/{id}/copy`,
  DELETE_BENEFIT: `/benefits/{id}`,
  DELETE_BENEFITS: `/benefit/delete`,
  CREATE_BENEFIT: `/benefit`,
  UPDATE_BENEFIT: `/benefit/{id}`,
  DUPLICATE_BENEFIT_CHECK: `/benefit/duplicate`,

  GET_COUPONS_STATISTICS: `/event/{spotId}/statistics`,

  PARTNER_BENEFIT_STATSTICS: `/benefit/statistics`,
  SPOT_BENEFIT_STATSTICS: `/benefit/spot/{spotId}/statistics`,

  PARTNER_USER_LIST: `/kakao/user/search`,
  PARTNER_USER_WORKBOOK: `/kakao/user/workbook`,
  USER_LIST_ALL: '/kakao/user/search/all',
  USER_DETAIL: '/kakao/user/{id}/detail',
  PARTNER_USER_ANALYSIS_LIST: `/kakao/user/analysis/search`,
  PARTNER_USER_STATISTICS: `/kakao/user/statistics`,
  SPOT_USER_STATISTICS: `/kakao/user/spot/{spotId}/statistics`,
  SPOT_USER_INFLOW_STATISTICS: `/kakao/user/spot/{spotId}/inflow`,

  SAVE_MESSAGE_CONTENT: "/api/v1/campaign/insert",
  SCENARIO_SAVE: "/",

  FILE_UPLOAD: `/file/`,
  FILE_KAKAO_UPLOAD: `/file/kakao/upload`,
  MESSAGE_FILE_UPLOAD: `/api/v1/kakao-bizmessage/image`,

  EXPECTED_CUSTOMER_COUNT: `/volatility-message/expected-customers/count`,
  GET_VOLATILITY_MESSAGES: `/volatility-message`,
  GET_VOLATILITY_MESSAGE: `/volatility-message/{id}`,
  GET_VOLATILITY_CLICK_USERS: `/volatility-message/{id}/click-users`,
  VOLATILITY_MESSAGE_RESERVED_CANCEL: `/volatility-message/{id}/cancel`,
  VOLATILITY_MESSAGE_COPY: `/volatility-message/{id}/copy`,
  TEST_VOLATILITY_MESSAGE: '/volatility-message/test',
  SAVE_NOTIFICATION: `/volatility-message`,
  UPDATE_NOTIFICATION: `/volatility-message/{id}`,

  GET_CASH_HISTORY: `/cash`,
  GET_BILL: `/cash/bill`,
  GET_BILL_DETAILS: `/cash/bill/details`,

  VOLATILITY_MESSAGE_COUPON: `/volatility-message/coupon`,

  MESSAGES: `/messages`,
  MESSAGES_CAMPAIGN: `/messages/campaign/{id}`,

  AUTOMATE_MESSAGE: '/automate-message/{id}',
  AUTOMATE_MESSAGE_LIST: '/automate-message',
  PROJECT_LiST: '/project',
  GET_PROJECT: '/project/{id}',
  DELETE_PROJECT: '/project/{id}/delete',
  DETAIL_PROJECT: '/project/{id}/detail',
  DETAIL_EVENTS_PROJECT: '/project/{id}/detail/events',
  ENTRY_POINTS_PROJECT: '/project/{id}/entryPoints',
  DETAIL_BENEFIT_QUANTITY_PROJECT: '/project/{id}/detail/benefit/quantity',
  DETAIL_BENEFIT_STATISTICS_PROJECT: '/project/{id}/detail/benefit/statistics',
  DETAIL_UN_USED_BENEFIT_QUANTITY_PROJECT: '/project/{id}/detail/benefit/quantity/unused',
  DETAIL_DUPLICATES_PROJECT: '/project/{id}/detail/duplicates',
  PRIZE_USERS_PROJECT: '/project/{id}/prize/users',
  POST_PROJECT: '/project',
  SAVE_NAME_PROJECT: '/project/{id}/name',
  TURN_PROJECT: '/project/turn',
  EVENT_PROJECT: '/project/event',
  PASSWORD_PROJECT: '/project/password',
  PERIOD_PROJECT: '/project/period',
  DUPLICATES_PROJECT: '/project/duplicates',
  DUPLICATE_DELETE_PROJECT: '/project/duplicate/delete',
  ENTRYPOINT_PROJECT: '/project/entryPoint',
  EVENT_DELETE_PROJECT: '/project/event/delete',
  ENTRYPOINT_DELETE_PROJECT: '/project/entryPoint/delete',
  GET_REVISIT_EVENT_PROJECT: '/project/{id}/revisit',
  SAVE_REVISIT_EVENT_PROJECT: '/project/revisit',
  EVENT_LIST: '/event/list',
  POST_EVENT: '/event/save',
  DELETE_EVENT: '/event/{id}',
  GET_EVENT: '/event/get/{id}',
  STATISTICS_CUSTOMERS: '/statistics/customers',
  STATISTICS_SPOT: '/statistics/spot',
  STATISTICS_ORDERS: '/statistics/orders',
  STATISTICS_ORDERS_MESSAGE: '/statistics/orders/message',

  LIST_POINT_MENU: '/point-menu/list',
  GET_POINT_MENU: '/point-menu/{id}',
  SAVE_POINT_MENU: '/point-menu',
  VISIBLE_POINT_MENU: '/point-menu/visible',
};

const MESSAGE_TYPE = {
  default: "FT",
  default_image: "FI",
  wide: "FW",
  wide_list: "FL",
  carousel: "FC",
};
const MESSAGE_TYPE_NAME = {
  default: "TEXT",
  default_image: "TEXT_IMAGE",
  wide: "WIDE_IMAGE",
  wide_list: "LIST_IMAGE",
  carousel: "CAROUSEL",
};

export const getMessageType = (messageType, data) => {
  if (messageType === "default") {
    return data.image
      ? MESSAGE_TYPE.default_image
      : MESSAGE_TYPE.default;
  } else {
    return MESSAGE_TYPE[messageType];
  }
};

export const getMessageTypeName = (messageType, data) => {
  if (messageType === "default") {
    return data.image
      ? MESSAGE_TYPE_NAME.default_image
      : MESSAGE_TYPE_NAME.default;
  } else {
    return MESSAGE_TYPE_NAME[messageType];
  }
};
