
const TypeSelectionForm = ({types, selectedType, disabled, onChangeType}) => {

    return (
        <div className="grid grid-cols-2 gap-4">
            {types.map((type, index) => (
                <div
                    key={"message-type" + index}
                    className={
                        `flex flex-1 border rounded-lg cursor-pointer ${disabled ? 'disabled' : ''}` +
                        (type.key === selectedType
                            ? "border-[#4046e3] bg-indigo-50"
                            : "border-[#dbdbea]")
                    }
                    onClick={() => {
                        if (!disabled) {
                            onChangeType(type.key)
                        }
                    }}
                >
                    <div className="w-full bg-[#eaecef] rounded-l-lg">
                        <div className="ft-selection-preview-box">
                            <div className={type.imageClass}></div>
                        </div>
                    </div>
                    <div className="w-full flex-col justify-center p-8 text-gray-600 text-xs">
                        <h5 className="font-bold text-sm">{type.title}</h5>
                        <p className="mt-2">{type.description}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TypeSelectionForm