import { Box, Typography, Button } from "@mui/material";
import NoImage from "assets/images/no-image.svg";

const DefaultTypeContent = ({ data }) => {
  return (
    <Box sx={{ width: "220px" }} className="message-content">
        {
            data.image?.imgUrl?.length >= 0 ? (
                <img
                    className="preview-image-wrap"
                    src={data.image?.imgUrl.length > 0 ? data.image?.imgUrl : NoImage}
                    style={{
                        width: "100%",
                        height: "106px"
                    }}
                ></img>
            ) : <></>
        }
      <Box className="default-message-content">
        <Typography sx={{ whiteSpace: "break-spaces" }} fontSize={12}>
          {data.content ?? "내용"}
        </Typography>
        {data.buttons?.filter((button) => button.type === "WL" || button.type === "COUPON")
            .map((button, index) => {
              return (
                <Button
                  className="preview-button"
                  variant="outlined"
                  color={"grey7"}
                  onClick={() => window.open(button.link)}
                  sx={{ mt: index === 0 ? 2 : 1 }}
                >
                  {button.name || "메시지 버튼"}
                </Button>
              );
            })}
      </Box>
    </Box>
  );
};

export default DefaultTypeContent;
