import {Tab, TextInput, Toggle} from "omoplay";
import {useEffect, useState} from "react";
import CardBillingForm from "./CardBillingForm";

const AutoChargeAiCreditForm = ({minCharge, autoCharge, onOnCharge, onMinCharge, onAutoCharge, onSelectedCard}) => {
    const [on, setOn] = useState(true)
    const [localMinCharge, setLocalMinCharge] = useState(minCharge)
    const [localAutoCharge, setLocalAutoCharge] = useState(autoCharge)
    const [passiveMinCharge, setPassiveMinCharge] = useState(!["500", "1000", "3000", "5000", "10000"].includes(localMinCharge.toString()))
    const [passiveAutoCharge, setPassiveAutoCharge] = useState(!["500", "1000", "3000", "5000", "10000"].includes(localAutoCharge.toString()))
    const [errorMin, setErrorMin] = useState(false)
    const [errorCharge, setErrorCharge] = useState(false)

    useEffect(() => {
        onMinCharge(localMinCharge)
        onAutoCharge(localAutoCharge)
    }, [localMinCharge, localAutoCharge]);

    return (
        <div className="flex-col gap-[24px]">
            <div className="flex-align-center gap-2">
                <div>
                    <Toggle onToggle={(flag) => {
                        setOn(flag)
                        onOnCharge(flag)}
                    } value={on} />
                </div>
                <div className="text-[16px] font-semibold">AI 크레딧 자동 충전 사용</div>
            </div>
            <div className="border"></div>
            {
                on && (
                    <>
                        <div className="flex gap-1">
                            <div className="text-[16px] font-semibold">AI 크레딧이</div>
                            <div className="text-[16px] font-semibold underline">{localMinCharge.toLocaleString()} Credit</div>
                            <div className="text-[16px] font-semibold">이하이면</div>
                        </div>
                        <div className="flex gap-2">
                            <Tab variants="default"
                                 style={{gap: "4px"}}
                                 items={[
                                     {label: "500", value: "500", selected: localMinCharge.toString() === "500"},
                                     {label: "1,000", value: "1000", selected: localMinCharge.toString() === "1000"},
                                     {label: "3,000", value: "3000", selected: localMinCharge.toString() === "3000"},
                                     {label: "5,000", value: "5000", selected: localMinCharge.toString() === "5000"},
                                     {label: "1,0000", value: "10000", selected: localMinCharge.toString() === "10000"},
                                     {label: "직접 입력", value: "passive", selected: !["500", "1000", "3000", "5000", "10000"].includes(localMinCharge.toString())},
                                 ]}
                                 onChanged={(item) => {
                                     const value = item.value
                                     setPassiveMinCharge(value === "passive")
                                     if (value !== "passive") {
                                         setLocalMinCharge(value)
                                     }
                                 }}
                            />
                        </div>
                        {
                            passiveMinCharge && (
                                <div className="flex-align-center gap-2">
                                    <TextInput type="numeric" value={localMinCharge}
                                               onChange={(e) => {
                                                   setLocalMinCharge(e.target.value)
                                                   setErrorMin(Number(e.target.value) < 100)
                                               }}
                                    />
                                    <div className="text-[16px] font-semibold">Credit</div>
                                    {
                                        errorMin && (<div className="text-red-500">100 Credit 이하로 지정할 수 없습니다.</div>)
                                    }
                                </div>
                            )
                        }
                        <div className="flex gap-1">
                            <div className="text-[16px] font-semibold underline">{localAutoCharge.toLocaleString()} Credit</div>
                            <div className="text-[16px] font-semibold">을 자동으로 충전</div>
                        </div>
                        <div className="flex gap-2">
                            <Tab variants="default"
                                 style={{gap: "4px"}}
                                 items={[
                                     {label: "500", value: "500", selected: localAutoCharge.toString() === "500"},
                                     {label: "1,000", value: "1000", selected: localAutoCharge.toString() === "1000"},
                                     {label: "3,000", value: "3000", selected: localAutoCharge.toString() === "3000"},
                                     {label: "5,000", value: "5000", selected: localAutoCharge.toString() === "5000"},
                                     {label: "1,0000", value: "10000", selected: localAutoCharge.toString() === "10000"},
                                     {label: "직접 입력", value: "passive", selected: !["500", "1000", "3000", "5000", "10000"].includes(localAutoCharge.toString())},
                                 ]}
                                 onChanged={(item) => {
                                     setPassiveAutoCharge(item.value === "passive")
                                     if (item.value !== "passive") {
                                         setLocalAutoCharge(item.value)
                                     }
                                 }}
                            />
                        </div>
                        {
                            passiveAutoCharge && (
                                <div className="flex-align-center gap-2">
                                    <TextInput type="numeric" value={localAutoCharge}
                                               onChange={(e) => {
                                                   setLocalAutoCharge(e.target.value)
                                                   setErrorCharge(Number(e.target.value) < 500)
                                               }} />
                                    <div className="text-[16px] font-semibold">Credit</div>
                                    {
                                        errorCharge && (<div className="text-red-500">500 Credit 이하로 지정할 수 없습니다.</div>)
                                    }
                                </div>
                            )
                        }
                        <div className="font-semibold ">※ 실제 결제 금액은 VAT. 포함 금액으로 결제됩니다.</div>
                        <div className="border"></div>
                        <CardBillingForm onSelectedCard={onSelectedCard} />
                    </>
                )
            }
        </div>
    )
}

export default AutoChargeAiCreditForm