import MobilePreview from "../../MobilePreview";
import {Button} from "omoplay";

const EventRoulettePreview = ({eventBackgroundImage, eventImage, eventButtonImage}) => {
    const API_HOST = process.env.REACT_APP_API_HOST
    const url = `${API_HOST}/view/event?temp=true&eventBackgroundImage=${encodeURIComponent(eventBackgroundImage)}` +
        `&eventImage=${encodeURIComponent(eventImage)}&eventButtonImage=${encodeURIComponent(eventButtonImage)}`

    return (
        <div className="flex-col items-center gap-2">
            <MobilePreview>
                <iframe
                    className="relative h-full"
                    src={url}
                    title="OMOPLAY TEMP"
                />
            </MobilePreview>
            <div>
                <Button variants="outlined" value="미리보기" onClick={() => window.open(url)} />
            </div>
        </div>
    )
}

export default EventRoulettePreview