import {useEffect, useState} from "react";
import {
    Button,
    CalendarBlue,
    DocBlack,
    InfoOrange,
    LoadingGreen,
    Tab,
    Table,
    Tag,
    Toggle,
    WarningRed
} from "omoplay";
import {deleteProject, getProjectList, turnProject} from "../../api/project/project.api";
import MiniMenu from "../form/MiniMenu";

const ProjectList = () => {
    const headers = [
        { name: "ID", width: "60px" },
        { name: "진행", sortable: false, align: "center", width: "80px" },
        { name: "프로젝트 이름", sortable: false },
        { name: "프로젝트 기간", sortable: false },
        { name: "상태", sortable: false, width: "130px" },
        { name: "", sortable: false, width: "80px" }
    ]
    const tabItems = [
        { label: "전체", value: "", selected: true}, { label: "진행중", value: "PROGRESS"},
        { label: "진행 대기", value: "PREPARE"},
        { label: "종료", value: "END"}
    ]

    const [projectData, setProjectData] = useState([])
    const [projectTableData, setProjectTableData] = useState([])
    const [openMenuId, setOpenMenuId] = useState(null)
    const [tab, setTab] = useState("")

    useEffect(() => {
        getProjectList(tab).then(({data}) => {
            setProjectData(data)
        })
    }, [tab]);

    const onToggleTurn = (id, flag) => {
        setProjectData(prevData =>
            prevData.map(project =>
                project.id === id ? { ...project, start: flag } : project
            )
        )
        setTimeout(() => {
            const message = flag ? "해당 프로젝트를 시작 하시겠습니까?\n시작기간 수정 및 이벤트 수정이 불가능해 집니다." : "해당 프로젝트를 종료 하시겠습니까?"
            if (window.confirm(message)) {
                turnProject(id, flag).then(({data}) => {
                    if (data) {
                        alert(data)
                    }
                    window.location.reload()
                })
            } else {
                setProjectData(prevData =>
                    prevData.map(project =>
                        project.id === id ? { ...project, start: !flag } : project
                    )
                )
            }
        }, 0)
    }

    useEffect(() => {
        setProjectTableData(
            projectData.map(project => {
                let icon = <DocBlack />

                if (project.status === "PROGRESS") {
                    icon = <LoadingGreen />
                } else if (project.status === "PREPARE") {
                    icon = <InfoOrange />
                } else if (project.status === "NECESSARY") {
                    icon = <WarningRed />
                } else if (project.status === "RESERVED") {
                    icon = <CalendarBlue />
                }
                return [
                    <div className="flex-center-all h-full">{project.id}</div>,
                    <div className="flex-center-all h-full">
                        <Toggle value={project.start}
                                disabled={project.status === "NECESSARY"}
                                onToggle={(flag) => onToggleTurn(project.id, flag)} />
                    </div>,
                    <div className="flex-align-center gap-2 h-full underline pointer"
                         onClick={() => window.location.href = (`/project/detail?id=${project.id}`)}>
                        <div>{icon}</div>
                        <div>{project.name}</div>
                    </div>,
                    <div className="flex-align-center gap-2 h-full">
                        <div className="flex gap-2">
                            <div>{project.startDate}</div>
                        </div>
                        ~
                        <div className="flex gap-2">
                            <div>{project.endDate ?? "상시 진행"}</div>
                        </div>
                    </div>,
                    <div className="flex-align-center gap-2 h-full">
                        {
                            project.badgeColor === "blue" && (<div className="text-primary font-bold">D-{project.days}</div>)
                        }
                        <Tag size="small" color={project.badgeColor}>{project.statusKor}</Tag>
                    </div>,
                    <div className="flex-center-all h-full">
                        <MiniMenu
                            id={project.id}
                            openMenuId={openMenuId}
                            setOpenMenuId={setOpenMenuId}
                            disabledEdit={project.status !== "NECESSARY"}
                            onClickEdit={() => window.location.href = (`/project/edit?id=${project.id}`)}
                            disabledDelete={project.status !== "NECESSARY"}
                            onClickDelete={() => {
                                if(window.confirm("정말 삭제하시겠습니가?")) {
                                    deleteProject(project.id).then(() => {
                                        alert("삭제 되었습니다.")
                                        window.location.reload()
                                    })
                                }
                            }}
                        />
                    </div>
                ]
            })
        )
    }, [projectData, openMenuId]);

    return (
        <>
            <div>
                <div style={{width: "fit-content"}}>
                    <Tab variants="default"
                         style={{gap: "4px"}}
                         items={tabItems}
                         onChanged={(item) => {
                             setTab(item.value)
                         }}
                    />
                </div>
            </div>
            <div className="flex justify-between items-end mb-4">
                <div>
                    총 <span className="text-primary font-bold">{projectTableData.length}</span>개
                </div>
                <Button variants="primary" value="+ 프로젝트 추가" onClick={() => {window.location.href = ("/project/edit")}} />
            </div>
            <div>
                <Table headers={headers} data={projectTableData} />
            </div>
        </>
    );
};

export default ProjectList;
