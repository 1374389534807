

const CircleBadge = ({label, type}) => {
    const className = type === "mainblue" ? "bg-indigo-100 text-xs rounded-full h-4 w-4 text-center font-medium" : "bg-black text-xs rounded-full h-4 w-4 text-center font-medium text-white"
    return (
        <div className={className}>
            {label}
        </div>
    )
}

export default CircleBadge