import {API_HOST, API_URL, DEFAULT_HEADER} from "../../constants/axios";
import axios from "axios";

const instance = axios.create({
    baseURL: API_HOST,
});

export const loginUser = async (data) => {
    try {
        return await instance.post(API_URL.LOGIN, data);
    } catch (e) {
        return e.response;
    }
};

export const loginCheck = async (token, data) => {
    try {
        return await instance.post(API_URL.LOGIN_CHECK, data, {
            headers: getTokenHeader(token),
        });
    } catch (e) {
        return e.response;
    }
};

export const getUserInfo = async (token) => {
    try {
        return await instance.get(API_URL.ACCOUNT_INFO, {
            headers: getTokenHeader(token),
        });
    } catch (e) {
        return e.response;
    }
};

export const joinUserInfo = async (name, userId, password, phoneNumber) => {
    try {
        return await instance.post(API_URL.ACCOUNT_JOIN, {
            name: name,
            userId: userId,
            password: password,
            phoneNumber: phoneNumber
        });
    } catch (e) {
        return e.response;
    }
};
export const idCheck = async (userId) => {
    try {
        return await instance.get(API_URL.ACCOUNT_ID_CHECK + `?id=${userId}`);
    } catch (e) {
        return e.response;
    }
};

export const codeAuthorization = async (phoneNumber) => {
    try {
        return await instance.post(API_URL.KAKAO_AUTHORIZATION + `?phoneNumber=${phoneNumber}`);
    } catch (e) {
        return e.response;
    }
};

const getTokenHeader = (token) => {
    const res = { ...DEFAULT_HEADER };
    res.header["Authorization"] = `Bearer ${token}`;
    return res.header;
};
