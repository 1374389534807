import { Box, Typography, FormLabel, TextField } from "@mui/material";
import SettingFormLabel from "./SettingFormLabel";
const TextSettingInput = ({
    value,
    onChange,
    inputProps,
    label,
    size = "small",
    isRequired = false,
    placeholder,
}) => {
    return (
        <Box>
            <SettingFormLabel label={label} isRequired={isRequired} />
            <TextField
                sx={{ mt: 1 }}
                inputProps={inputProps}
                size={size}
                value={value}
                placeholder={placeholder}
                onChange={(e) => onChange(e)}
                fullWidth
            />
        </Box>
    );
};

export default TextSettingInput;
