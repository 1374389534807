import {ImageStatusCard} from "omoplay";
import React from "react"

const MiniWideListTypeContent = ({ data }) => {
    const items = data.items ?? []

    return (
      <>
          {items
              .filter((item) => item.imgUrl != null)
              .map((item) => {
                  return (
                      <div className="w-[64px] h-[64px]">
                          <ImageStatusCard imageUrl={item.imgUrl} status="Done" />
                      </div>
                  )
              })
          }
      </>
    );
};

export default MiniWideListTypeContent;
