import InputGroup from "../InputGroup";
import NormalButton from "../../NormalButton";
import {useState} from "react";
import {kakaoImageUpload} from "../../../api/file/file.api";
import HighlightWithinTextarea from "react-highlight-within-textarea";
import {TextInput} from "omoplay";
import VariableTextArea from "../VariableTextArea";

const BenefitMessageSettingForm = ({image, onImageChange, message, onMessageChange, buttonName, onChangeButtonName, regex, variables}) => {

    const uploadImage = (e) => {
        kakaoImageUpload(e.target.files[0]).then(({data}) => {
            if (!data?.imageUrl) {
                alert(data.message)
            } else {
                onImageChange(data?.imageUrl)
            }
            e.target.value = null
        })
    }

    return (
        <>
            <InputGroup
                label={"메시지 이미지"}
                isRequired={true}
                className="py-8"
            >
                <div className="flex gap-4">
                    <input
                        type="text"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
                        placeholder="업로드할 파일 선택"
                        maxLength={30}
                        readOnly
                        value={image}
                        required
                    />
                    <label
                        htmlFor={"prize_image"}
                        className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
                    >
                        첨부
                    </label>
                    <input
                        type="file"
                        id={"prize_image"}
                        className="hidden"
                        onChange={(e) => uploadImage(e)}
                    />
                </div>
                <div className="text-grey-4" style={{fontSize: "12px"}}>가로 500px 이상, 최소 비율 2:1, 최대 비율 3:4 지원 포맷: png,jpeg (최대 1MB)</div>
            </InputGroup>
            <InputGroup
                label={"당첨 메시지"}
                isRequired={true}
                className="py-8"
            >
                <div className="text-grey-5">혜택의 당첨 내용을 설정해 주세요.</div>
                <VariableTextArea message={message} variables={variables} regex={regex} maxLength={400} onChangeMessage={onMessageChange} />
            </InputGroup>
            <InputGroup
                label={"버튼명"}
                isRequired={true}
                className="py-8"
            >
                <TextInput value={buttonName} maxLength={8} onChange={(e) => onChangeButtonName(e.target.value)} />
            </InputGroup>
        </>
    )
}

export default BenefitMessageSettingForm