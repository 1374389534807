import { Link, useLocation } from "react-router-dom";
import PropertyIcon from "../../assets/images/property_icon.svg";
import AnalyzeIcon from "../../assets/images/analyze_icon.svg";
import AccountIcon from "../../assets/images/account_icon.svg";
import MessageIcon from "../../assets/images/message_icon.svg";

const Sidebar = () => {
  const location = useLocation();
  return (
    <div
      className="sidebar"
    >
      <div className="h-full py-4 overflow-y-auto bg-gray-800 flex-col divide-y">
        <div className="space-y-2 divide-y divide-grey">
          <ul>
            <li>
              <span className="ms-12px flex items-center p-10px navigation-title rounded-lg group">
                <img src={PropertyIcon} />
                <span className="pl-20px">플레이</span>
              </span>
              <ul className="pl-38px space-y-1 font-medium">
                <li>
                  <Link
                      to="/project"
                      className={
                          "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                          (location.pathname === "/project" ? "bg-gray-700" : "")
                      }
                  >
                    <span className="flex-1 ms-12px whitespace-nowrap normal-white-text">
                      프로젝트
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/spots"
                    className={
                      "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                      (location.pathname === "/spots" ? "bg-gray-700" : "")
                    }
                  >
                    <span className="flex-1 ms-12px whitespace-nowrap normal-white-text">
                      고객 진입점 (QR)
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/event"
                    className={
                      "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                      (location.pathname === "/event" ? "bg-gray-700" : "")
                    }
                  >
                    <span className="flex-1 ms-12px whitespace-nowrap normal-white-text">
                      이벤트
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                      to="/benefit"
                      className={
                          "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                          (location.pathname === "/benefit" ? "bg-gray-700" : "")
                      }
                  >
                <span className="flex-1 ms-12px whitespace-nowrap normal-white-text">
                  혜택
                </span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <span className="ms-12px flex items-center p-10px navigation-title rounded-lg group">
                <img src={MessageIcon} />
                <span className="pl-20px">고객 멤버쉽</span>
              </span>
              <ul className="pl-38px space-y-1 font-medium">
                <li>
                  <Link
                      to="/point-menu"
                      className={
                          "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                          (location.pathname === "/point-menu"
                              ? "bg-gray-700"
                              : "")
                      }
                  >
                    <span className="flex-1 ms-12px whitespace-nowrap normal-white-text">
                      포인트 메뉴
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <span className="ms-12px flex items-center p-10px navigation-title rounded-lg group">
                <img src={MessageIcon} />
                <span className="pl-20px">마케팅</span>
              </span>
              <ul className="pl-38px space-y-1 font-medium">
                <li>
                  <Link
                    to="/volatility-message"
                    className={
                      "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                      (location.pathname === "/volatility-message"
                        ? "bg-gray-700"
                        : "")
                    }
                  >
                    <span className="flex-1 ms-12px whitespace-nowrap normal-white-text">
                      1회성 메시지
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                      to="/automate-message"
                      className={
                          "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                          (location.pathname === "/automate-message"
                              ? "bg-gray-700"
                              : "")
                      }
                  >
                    <span className="flex-1 ms-12px whitespace-nowrap normal-white-text">
                      자동화 메시지
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li className="py-2">
              <span className="ms-12px flex items-center p-10px navigation-title rounded-lg group">
                <img src={AnalyzeIcon} />
                <span className="pl-20px">성과 보드</span>
              </span>
              <ul className="pl-38px space-y-1 font-medium">
                <li>
                  <Link
                    to="/"
                    className={
                      "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                      (location.pathname === "/" ? "bg-gray-700" : "")
                    }
                  >
                    <span className="ms-12px  normal-white-text">전체 성과</span>
                  </Link>
                </li>
                {/*
                <li>
                  <Link
                    to="/spot"
                    className={
                      "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                      (location.pathname === "/spot" ? "bg-gray-700" : "")
                    }
                  >
                    <span className="ms-12px normal-white-text">진입점 성과</span>
                  </Link>
                </li>
                */}
                {/*
                <li>
                  <Link
                    to="/promotion"
                    className={
                      "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                      (location.pathname === "/promotion" ? "bg-gray-700" : "")
                    }
                  >
                    <span className="ms-12px normal-white-text">혜택 리포트</span>
                  </Link>
                </li>
                */}
                <li>
                  <Link
                      to="/messages"
                      className={
                          "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                          (location.pathname === "/messages" ? "bg-gray-700" : "")
                      }
                  >
                    <span className="ms-12px normal-white-text">메시지 리포트</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li className="py-2">
              <span className="ms-12px flex items-center p-10px navigation-title rounded-lg group">
                <img src={AccountIcon} />
                <span className="pl-20px">고객</span>
              </span>
              <ul className="pl-38px space-y-1 font-medium">
                <li>
                  <Link
                    to="/users"
                    className={
                      "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                      (location.pathname === "/users" ? "bg-gray-700" : "")
                    }
                  >
                    <span className="ms-12px normal-white-text">고객 리스트</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/users-statistics"
                    className={
                      "flex items-center p-10px text-gray-900 rounded-lg hover:bg-gray-700 group " +
                      (location.pathname === "/users-statistics"
                        ? "bg-gray-700"
                        : "")
                    }
                  >
                    <span className="ms-12px normal-white-text">고객 분석</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
