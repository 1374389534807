import {useEffect, useState} from "react";
import {Alert, Button, Panel, RemoveTab, Table, Tag, TextInput} from "omoplay";
import SuspensePopup from "../../SuspensePopup";
import BenefitForm from "../benefit/BenefitForm";
import {getBenefit} from "../../../api/benefit/benefit.api";
import NoImage from "../../../assets/images/no-image.svg"
import BenefitMessageSettingForm from "../benefit/BenefitSettingDetailForm";
import LoseBombIcon from "../../../assets/images/lose_bomb_icon.svg"
import DisabledLoseBombIcon from "../../../assets/images/disabled_lose_bomb_icon.svg"
import InputGroup from "../InputGroup";

const EventBenefit = ({ benefitSettings, onSelectedBenefit, onDeletedBenefit, onUpdatedBenefit, onValid }) => {
  const [totalPercent, setTotalPercent] = useState("0")
  const [isOpenBenefitPopup, setOpenBenefitPopup] = useState(false)
  const [isEditBenefitPopup, setEditBenefitPopup] = useState(false)
  const [selectedBenefitList, setSelectedBenefitList] = useState([])
  const [selectedBenefitIndex, setSelectedBenefitIndex] = useState({})
  const [addBenefitId, setAddBenefitId] = useState()
  const [existsLose, setExistsLose] = useState(false)

  const headers = [
    {name: "", width: "50px"}, {name: "혜택", width: "400px"}, {name: "유효기간", align: "center"},
    {name: "당첨 확률", align: "center", width: "120px"}, {name: "혜택 설정", align: "center", width: "150px"},
    {name: "", width: "60px"}
  ]

  useEffect(() => {
    let sumPercent = 0
    const maxDecimalPlaces = benefitSettings?.reduce((max, benefit) => {
      const num = benefit.winningPercent ?? 0
      const decimalPlaces = (num.toString().split(".")[1] || "").length;
      return Math.max(max, decimalPlaces);
    }, 0);
    const selectedList = benefitSettings?.map((benefit, index) => {
      sumPercent += parseFloat(benefit.winningPercent) ?? 0
      const isLose = !benefit.id

      const openEdit = () => {
        setSelectedBenefitIndex(index)
        setEditBenefitPopup(true)
      }

      return [
        <div className="flex-center-all h-full">{benefit.id}</div>,
        <div className="flex-align-center h-full gap-4">
          <div style={{minWidth: "85px"}}><img alt="" width="85px" height="45px" src={benefit.messageImage ?? NoImage} /></div>
          <div>
              {
                  (benefit.messageText && benefit.messageImage) && (benefit.periodType || !benefit.id)
                      ? (<Tag color="success" style={{height: "18px"}}>설정 완료</Tag>)
                      : (<Tag color="error" style={{height: "18px"}}>설정 필요</Tag>)
              }
            <div className="underline pointer" onClick={openEdit}>
              <span className="font-bold">{isLose ? "꽝" : benefit.name}</span><span className="text-grey-7">{benefit.retailPrice ? ` / ${benefit.retailPrice}원` : ""}</span>
            </div>
            <div className="ellipsis" style={{maxWidth: "250px"}}>{benefit.messageText}</div>
          </div>
        </div>,
        <div className="flex-center-all flex-col h-full">{benefit.periodType ? (
            benefit.periodType === "issue" ? (
                <>
                  <div>발급일로부터</div>
                  <div>{benefit.periodDays}일까지</div>
                </>
            ) : (
                <>
                  <div>프로젝트</div>
                  <div>종료시까지</div>
                </>
            )
        ) : "-"}
        </div>,
        <div className="flex-center-all h-full">
          <TextInput placeholder="총 100%" value={benefit.winningPercent}
                     type="numeric"
                     style={{width: "100%"}}
                     onChange={(e) => {
                       const value = e.target.value
                       const winning = value.lastIndexOf(".") ? value : Number(value)
                       if (isNaN(winning)) {
                         return
                       }
                       const updateBenefit = { ...benefitSettings.find(bs => bs.id === benefit.id) }
                       updateBenefit.winningPercent = winning
                       onUpdatedBenefit(updateBenefit)
                     }} />
        </div>,
        <div className="flex-center-all h-full">
            <Button variants="dark" value="혜택 설정" onClick={openEdit} />
        </div>,
        <div className="flex-center-all h-full">
            <div className="pointer" onClick={() => onDeletedBenefit(benefit.id)}>
                <RemoveTab />
            </div>
        </div>
      ]
    })
    setExistsLose(benefitSettings?.some(benefit => !benefit.id) ?? false)
    setSelectedBenefitList(selectedList ?? [])
    setTotalPercent(sumPercent.toFixed(maxDecimalPlaces))
  }, [benefitSettings]);

  useEffect(() => {
    if (addBenefitId) {
      getBenefit(addBenefitId).then(({data}) => {
        onSelectedBenefit(data)
      })
    }
  }, [addBenefitId]);

  useEffect(() => {
    onValid(Number(totalPercent) === 100
        && !benefitSettings?.find(settings => settings.id && !settings.periodType)
        && !benefitSettings?.find(settings => !settings.id && !settings.messageText && !settings.messageImage && !settings.buttonName)) //꽝
  }, [benefitSettings, onValid, totalPercent]);

  return (
      <>
        <InputGroup label={(
            <div className="flex-align-center justify-between">
                <div className="font-semibold text-lg">혜택 설정</div>
                <div className="flex-center-all gap-2">
                <Button
                    variants="default"
                    value={(
                        <div className="flex-align-center gap-1">
                          <img alt="" src={existsLose ? DisabledLoseBombIcon : LoseBombIcon} />
                          <div>꽝 추가</div>
                        </div>
                    )}
                    onClick={() => {
                      onSelectedBenefit("꽝")
                    }}
                    disabled={existsLose}
                />
                <Button
                    variants="dark"
                    value="+ 혜택 추가"
                    onClick={() => setOpenBenefitPopup(true)}
                />
                </div>
            </div>
        )}
        >
            <div className="flex-col gap-2">
                {(Number(totalPercent) !== 100)  && (
                    <Alert title="당첨 확률이 100% 여야 합니다" type="error" withIcon={true} />
                )}
                <div>
                    <Panel variants="bar" items={[
                        {
                            title: "총 개수",
                            value: (benefitSettings?.length ?? 0 + "개")
                        },{
                            title: "당첨 확률",
                            value: (<div><span className="text-primary">{Number(totalPercent)}</span> / 100%</div>)
                        }
                    ]} />
                </div>
                <div>
                    <Table headers={headers} data={selectedBenefitList} />
                </div>
            </div>
        </InputGroup>
          <SuspensePopup title={"혜택 연결하기"} visible={isOpenBenefitPopup}
                         onClose={() => setOpenBenefitPopup(false)}
          >
              <BenefitForm
                  onSelectedBenefit={(benefitId) => {
                      if (!benefitSettings?.some(benefit => benefit.id === benefitId)) {
                          setAddBenefitId(benefitId)
                      }
                      setOpenBenefitPopup(false)
                  }}
              />
          </SuspensePopup>
          <SuspensePopup title={"혜택 당첨 설정"}
                         style={{width: "1100px"}}
                         visible={isEditBenefitPopup}
                         onClose={() => setEditBenefitPopup(false)}
          >
              <BenefitMessageSettingForm key={benefitSettings?.toString()}
                                         selectedBenefitIndex={selectedBenefitIndex}
                                         benefitList={benefitSettings}
                                         onSave={(currentBenefitIndex, messageImage, messageText, buttonName, periodType, periodDays) => {
                                             const updateBenefit = { ...benefitSettings[currentBenefitIndex] }
                                             updateBenefit.messageImage = messageImage
                                             updateBenefit.messageText = messageText
                                             updateBenefit.buttonName = buttonName
                                             if (updateBenefit.id) {
                                                 updateBenefit.periodType = periodType
                                                 updateBenefit.periodDays = periodDays
                                             }
                                             onUpdatedBenefit(updateBenefit)
                                         }}
                                         onClose={() => setEditBenefitPopup(false)}
              />
          </SuspensePopup>
      </>
  )
};

export default EventBenefit;
