import { useRecoilValue } from "recoil";
import OMOPlayLogo from "../../assets/images/omoplay_logo.svg";
import { partnerSelector } from "../../store/selector/partner";
import HeaderAccountInfo from "./HeaderAccountInfo";

const Header = () => { 
  const partner = useRecoilValue(partnerSelector);

  function sidebarFlag(target) {
    const sidebar = document.querySelector(".sidebar")
    const mainContent = document.querySelector("#main-content")

    if (sidebar.classList.contains("close")) {
      sidebar.classList.remove("close")
      sidebar.classList.add("open")
      target.classList.remove("right-arrow")
      target.classList.add("left-arrow")
      mainContent.classList.remove("side-close")
      mainContent.classList.add("side-open")
    } else {
      sidebar.classList.remove("open")
      sidebar.classList.add("close")
      target.classList.add("right-arrow")
      target.classList.remove("left-arrow")
      mainContent.classList.add("side-close")
      mainContent.classList.remove("side-open")
    }
  }

  return (
    <nav className="fixed top-0 z-50 w-full bg-gray-800 border-b-2 border-b-gray-700">
      <div className="p-11px">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between" style={{width: "226px", padding: "8px"}}>
            <span><img alt="" src={OMOPlayLogo} width="142px"/></span>
            <span className="left-arrow" onClick={e => sidebarFlag(e.currentTarget)}></span>
          </div>
          <div className="flex items-center">
            <div className="flex items-center ms-3">
              {/*
              {partner.currentCash < 0 && (
                <div className="py-1.5 px-4 bg-red-100 text-red-600 text-sm rounded-lg mr-4">
                  캐시가 부족합니다
                </div>
              )}
              */}
              <HeaderAccountInfo />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
