import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  getPartnerStatistics,
  getSpotStatistics,
} from "../../api/benefit/statistics.api";
import {getPartnerUserStatistics, getSpotInflowStatistics} from "../../api/user/statistics.api";
import BenefitPercentTable from "../BenefitPercentTable";
import CustomerStatisticsTable from "../CustomerStatisticsTable";
import DateStatistics from "../DateStatistics";
import Benefit from "../../assets/images/benefit.svg";
import Button from "../../assets/images/button.svg";
import Prize from "../../assets/images/prize.svg";
import Loading from "../Loading";
import SpotEventStatisticsTable from "../SpotEventStatisticsTable";
import {getDefaultDateRange} from "../../libs/helper";
import { spotSelector } from "../../store/selector/spot";
import {CalendarBar, Dropdown, QuestionTooltip, TabBar} from "omoplay";
import {ceil} from "lodash";


const defaultDateRage = getDefaultDateRange();
const dateItems = [
  { value: "all", label: "전체" },
  { value: "month", label: "최근 한 달" },
  { value: "week", label: "최근 일주일", selected: true },
];
const typeItems = [
    { value: "inflow", label: "유입" },
  { value: "customer", label: "고객" },
  { value: "benefit", label: "혜택" },
];

const SpotPage = () => {
    const [dateRange, setDateRange] = useState(defaultDateRage);
    const [activeType, setActiveType] = useState(typeItems[0].value);

    const [isCustomerLoading, setCustomerLoading] = useState(false);
    const [QRCustomerInfo, setQRCustomerInfo] = useState([]);
    const [inflowStatistics, setInflowStatistics] = useState({})

    const [isBenefitLoading, setBenefitLoading] = useState(false);
    const [benefitInfo, setBenefitInfo] = useState([]);

    const [isSpotLoading, setSpotLoading] = useState(false);
    const [spotEventInfo, setSpotEventInfo] = useState({results: [], items: []});
    const [activeSpot, setActiveSpot] = useState(null);
    const spots = useRecoilValue(spotSelector);
    const [spotId, setSpotId] = useState(spots.length !== 0 && spots[0].spotId)

    const getUserChart = useCallback(
    async (dateRange) => {
    const { startDate, endDate } = dateRange;
    const res = await getPartnerUserStatistics(startDate, endDate);
    if (res.code === 2000) {
        setQRCustomerInfo(res.data.items);
    }
    setCustomerLoading(false);
    },
    [dateRange]);

    const getSpotInflowStatisticsRequest = useCallback(
    async (dateRange) => {
        const { startDate, endDate } = dateRange;
        const res = await getSpotInflowStatistics(spotId, startDate, endDate);
            if (res.code === 2000) {
                setInflowStatistics(res.data);
            }
            setCustomerLoading(false);
        },
        [spotId, dateRange]
    );

    const getBenefitChart = useCallback(async (dateRange) => {
      const { startDate, endDate } = dateRange;
      const res = await getPartnerStatistics(startDate, endDate);
      if (res.code === 2000) {
        setBenefitInfo(res.data.items);
      }
      setBenefitLoading(false);
    },
    [dateRange])

    const getSpotEventChart = useCallback(
    async (dateRange) => {
        if (activeSpot !== null) {
            const { startDate, endDate } = dateRange;
            const res = await getSpotStatistics(activeSpot, startDate, endDate);
            if (res.code === 2000) {
                setSpotEventInfo(res.data);
            }
        }
        setSpotLoading(false);
    }, [activeSpot, dateRange]);

    const handleQuickChange = (type) => {
        const now = moment().format("YYYY-MM-DD");
        if (type === "all") {
          setDateRange({
            startDate: moment("2023-12-01", "YYYY-MM-DD").format("YYYY-MM-DD"),
            endDate: now,
          });
        } else if (type === "month") {
          setDateRange({
            startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
            endDate: now,
          });
        } else if (type === "week") {
          setDateRange({
            startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
            endDate: now,
          });
        }
    };

    const totalRegisterCount = useMemo(() => {
    return QRCustomerInfo.reduce(
      (a, b) => b.items.reduce((c, d) => d.count + c, 0) + a,
      0
    );
    }, [QRCustomerInfo]);

    const onDateInput = (dateRange) => {
        setDateRange({
            startDate: moment(dateRange.startDate).format("yyyy-MM-DD"),
            endDate: moment(dateRange.endDate).format("yyyy-MM-DD")
        });
    };

    const getCustomerCards = useCallback(() => {
        return [
          {
            label: "총 획득 고객",
            value: totalRegisterCount,
          },
        ];
    }, [QRCustomerInfo]);

    useEffect(() => {
    if (activeType === "customer") {
        setCustomerLoading(true);
        getUserChart(dateRange);
        getSpotEventChart(dateRange);
    } else if (activeType === "inflow") {
        setCustomerLoading(true);
        setInflowStatistics(getSpotInflowStatisticsRequest(dateRange))
    } else {
        setBenefitLoading(true);
        getBenefitChart(dateRange);
        getSpotEventChart(dateRange);
    }
    }, [activeSpot, dateRange, activeType]);

    useEffect(() => {
        setCustomerLoading(true);
        setInflowStatistics(getSpotInflowStatisticsRequest(dateRange))
    }, [spotId]);

    const handleRowClick = (spotId) => {
        setActiveSpot(spotId);
    };

    return (
        <>
            <div className="page-title">진입점 성과</div>
            <div className="w-full flex mb-10" style={{marginTop: "35px"}}>
                <div className="msg-type-filter-div flex justify-content-between">
                    <div className="flex">
                        {
                            typeItems.map((item, index) => {
                                return (
                                    <div className="msg-type-filter-btn">
                                        <label>
                                            <input type="radio" name="msg-type-filter-radio" onChange={() => setActiveType(item.value)} checked={activeType === item.value} />
                                            <span>{item.label}</span>
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="bg-white relative border border-gray-300 rounded-lg flex gap-4 p-4 items-center" style={{marginBottom: "15px"}}>
                <div style={{marginRight: "50px"}}>
                    기간
                </div>

                <div>
                    <TabBar
                      items={dateItems}
                      onChanged={(data) => handleQuickChange(data.value)}
                    />
                </div>
                <CalendarBar initDate={{startDate: moment(dateRange.startDate).toDate(), endDate: moment(dateRange.endDate).toDate()}}
                             onConfirm={(props) => onDateInput(props)} />
            </div>
            {
                activeType === "customer" ? (
                  <>
                    <div>
                      <h5 className="font-medium text-lg mb-4">진입점 고객</h5>
                    </div>
                    <DateStatistics keyName={"customers"} cards={getCustomerCards()}>
                      <div className="relative">
                        {isCustomerLoading && (
                          <div className="absolute w-full bottom-0 left-0 top-0 flex items-center justify-center bg-white bg-opacity-30 z-30">
                            <div className="w-32">
                              <Loading className="text-gray-600" />
                            </div>
                          </div>
                        )}
                        <div className="w-full flex flex-wrap overflow-x-auto relative border border-gray-300 rounded-lg text-xs">
                          <CustomerStatisticsTable
                            data={QRCustomerInfo}
                            totalCount={totalRegisterCount}
                          />
                        </div>
                      </div>
                    </DateStatistics>
                  </>
                ) : <></>
            }
          {
              activeType === "benefit" ? (
                  <>
                      <div>
                          <h5 className="font-medium text-lg mb-4 flex items-baseline pl-4">
                              <span className="text-indigo-500">사용</span>/발급
                              <span className="text-sm text-gray-400 ml-1">(사용률)</span>
                          </h5>
                      </div>
                      <div className="relative">
                          {isBenefitLoading && (
                              <div className="absolute w-full bottom-0 left-0 top-0 flex items-center justify-center bg-white bg-opacity-30 z-30">
                                  <td className="w-32">
                                      <Loading className="text-gray-600" />
                                  </td>
                              </div>
                          )}
                          <div className="w-full flex flex-wrap overflow-x-auto relative border border-gray-300 rounded-lg text-xs">
                              <BenefitPercentTable
                                  data={benefitInfo}
                                  onClick={handleRowClick}
                              />
                          </div>
                      </div>
                      {
                          activeSpot ? (
                              <div
                                  className="fixed h-screen bg-white top-11 transition-all p-8"
                                  style={{
                                      width: "50vw",
                                      right: spotId !== null ? "0" : "-50vw",
                                      boxShadow:
                                          spotId !== null ? "0 25px 50px -12px rgba(0,0,0,0.2)" : "none",
                                  }}
                              >
                                  <div className="w-full">
                                      <div className="w-full">
                                          <button onClick={() => setActiveSpot(null)}>
                                              <FontAwesomeIcon icon={faArrowRight} size={"xl"} />
                                          </button>
                                      </div>
                                      <h4 className="mt-20 font-medium text-lg mb-2">
                                          {spots.find((spot) => spot.spotId === activeSpot)?.name || ""}
                                      </h4>
                                      <div className="relative">
                                          {isSpotLoading && (
                                              <div className="absolute w-full bottom-0 left-0 top-0 flex items-center justify-center bg-white bg-opacity-30 z-30">
                                                  <td className="w-32">
                                                      <Loading className="text-gray-600" />
                                                  </td>
                                              </div>
                                          )}
                                          <div className="w-full flex flex-wrap overflow-x-auto relative border border-gray-300 rounded-lg text-xs">
                                              <SpotEventStatisticsTable data={spotEventInfo} />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          ) : <></>
                      }
                  </>
              ) : <></>
          }
            {
                activeType === "inflow" ? (
                    <>
                        <div className="flex items-center" style={{marginBottom: "15px", gap: "12px"}}>
                            <div>
                                {
                                    spots.length > 0 ?
                                        <Dropdown variants="default"
                                                  titleStyle={{backgroundColor: "white"}}
                                                  items={spots.map(spot => ({label: spot.name, value: spot.spotId}))}
                                                  onChanged={(items) => {
                                                      setSpotId(items[0].value)
                                                  }} /> : <></>
                                }
                            </div>
                            <div>
                                <div className="flex items-center justify-center" style={{width: "64px", height: "32px", borderRadius: "6px", backgroundColor: "#DDDFF8"}}>
                                    <span className="font-bold font-16px text-mainblue-d1">룰렛형</span>
                                </div>
                            </div>
                        </div>
                        <div className="spot-table">
                            <div className="spot-header">
                                <div style={{color: "#7B828B"}}><span className="number-badge" style={{marginRight: "5px"}}>1</span>첫 진입</div>
                                <div style={{color: "#7B828B"}}><span className="number-badge" style={{marginRight: "5px"}}>2</span>혜택 룰렛</div>
                                <div style={{color: "#7B828B"}}><span className="number-badge" style={{marginRight: "5px"}}>3</span>당첨 항목</div>
                            </div>
                            <div className="spot-row">
                                <div className="spot-row-item row-data">
                                    <div>
                                        <span className="rectangle" style={{marginRight: "10px"}} />
                                        <span>페이지 조회</span>
                                    </div>
                                    <div className="counting-text">
                                        <span>{inflowStatistics.firstPageCount?.toLocaleString()}</span>
                                        <span>회</span>
                                    </div>
                                </div>
                                <div className="spot-row-item row-data">
                                    <div>
                                        <span className="rectangle" style={{marginRight: "10px"}} />
                                        <span>페이지 조회</span>
                                    </div>
                                    <div className="counting-text">
                                        <span>{inflowStatistics.benefitPageCount?.toLocaleString()}</span>
                                        <span>회</span>
                                    </div>
                                </div>
                                <div className="spot-row-item row-data">
                                    <div>
                                        <span className="rectangle" style={{marginRight: "10px"}} />
                                        <span>페이지 조회</span>
                                    </div>
                                    <div className="counting-text">
                                        <span>{inflowStatistics.prizePageCount?.toLocaleString()}</span>
                                        <span>회</span>
                                    </div>
                                </div>
                            </div>
                            <div className="spot-row">
                                <div className="spot-row-item row-data">
                                    <div>
                                        <span className="rectangle" style={{marginRight: "10px"}} />
                                        <span>로그인 버튼 클릭</span>
                                    </div>
                                    <div className="counting-text">
                                        <span>{inflowStatistics.loginCount?.toLocaleString()}</span>
                                        <span>회</span>
                                    </div>
                                </div>
                                <div className="spot-row-item row-data">
                                    <div>
                                        <span className="rectangle" style={{marginRight: "10px"}} />
                                        <span>룰렛 클릭</span>
                                    </div>
                                    <div className="counting-text">
                                        <span>{inflowStatistics.benefitJoinCount?.toLocaleString()}</span>
                                        <span>회</span>
                                    </div>
                                </div>
                                <div className="spot-row-item">
                                    <div style={{width: "100%"}}>
                                        {
                                            inflowStatistics.prizeButtons?.map((button) => {
                                                return (
                                                    <div className="row-data">
                                                        <div>
                                                            <span className="rectangle" style={{marginRight: "10px"}} />
                                                            <span>{button.name}</span>
                                                        </div>
                                                        <div className="counting-text">
                                                            <span>{button.count}</span>
                                                            <span>회</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="spot-row">
                                <div className="spot-row-item row-data">
                                    <div className="row-data">
                                        <span style={{marginRight: "7px"}}>클릭 전환율</span>
                                        <span>
                                            <QuestionTooltip>로그인 버튼 클릭 수 / 페이지 조회 수</QuestionTooltip>
                                        </span>
                                    </div>
                                    <div className="percent-text">
                                        <span>{inflowStatistics.loginCount === 0 ? "-" : ceil(inflowStatistics.loginCount / inflowStatistics.firstPageCount * 100, 2)}</span>
                                        <span>%</span>
                                    </div>
                                </div>
                                <div className="spot-row-item row-data">
                                    <div className="row-data">
                                        <span style={{marginRight: "7px"}}>클릭 전환율</span>
                                        <span>
                                            <QuestionTooltip >룰렛 클릭 수 / 페이지 조회 수</QuestionTooltip>
                                        </span>
                                    </div>
                                    <div className="percent-text">
                                        <span>{inflowStatistics.benefitJoinCount === 0 ? "-" : ceil(inflowStatistics.benefitJoinCount / inflowStatistics.benefitPageCount * 100, 2)}</span>
                                        <span>%</span>
                                    </div>
                                </div>
                                <div className="spot-row-item row-data">
                                    <div className="row-data">
                                        <span style={{marginRight: "7px"}}>클릭 전환율</span>
                                        <span>
                                            <QuestionTooltip>(버튼 클릭 수의 합) / 페이지 조회 수</QuestionTooltip>
                                        </span>
                                    </div>
                                    <div className="percent-text">
                                        <span>{inflowStatistics.prizeButtonsCount === 0 ? "-" : ceil(inflowStatistics.prizeButtonsCount / inflowStatistics.prizePageCount * 100, 2)}</span>
                                        <span>%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="spot-row">
                                <div className="spot-row-item flex justify-center" style={{height: "267px"}}>
                                    <img src={Button} />
                                </div>
                                <div className="spot-row-item flex justify-center" style={{height: "267px"}}>
                                    <img src={Benefit} />
                                </div>
                                <div className="spot-row-item flex justify-center" style={{height: "267px"}}>
                                    <img src={Prize} />
                                </div>
                            </div>
                        </div>
                    </>
                ) : <></>
            }
        </>
    );
};

export default SpotPage;
