import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircleBadge from "./form/CircleBadge";

const TabTitle = ({ tabs, activeTab }) => {
  return (
    <div className="flex items-center justify-center gap-4">
      {tabs.map((tab, index) => {
        return (
          <div
            key={"tab_title_" + index}
            className={
              "flex items-center " +
              (activeTab === tab.value ? "text-indigo-700" : "text-gray-300")
            }
          >
            <CircleBadge label={index + 1} type="mainblue" />
            <h4 className={"ml-2 mb-0 h-fit text-sm font-semibold"}>
              {tab.label}
            </h4>
            {index !== tabs.length - 1 && (
              <FontAwesomeIcon
                icon={faAngleRight}
                className="ml-4"
                size={"sm"}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TabTitle;
