import Badge from "../badge/Badge";
import {Button, Table} from "omoplay";
import SuspensePopup from "../../SuspensePopup";
import EventSimpleList from "./EventSimpleList";
import {useEffect, useState} from "react";
import {
    deleteProjectDuplicate,
    deleteProjectEntryPoint,
    deleteProjectEvent, getDetailDuplicateProjects,
    getDetailEventsToProject, postProjectDuplicates,
    postProjectEntryPoint,
    postProjectEvent
} from "../../../api/project/project.api";
import GrayButton from "../../GrayButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import EntrypointSimpleList from "./EntrypointSimpleList";
import ProjectDetailPeriod from "./ProjectDetailPeriod";
import ProjectDetailBenefitPassword from "./ProjectDetailBenefitPassword";
import ProjectDetailDuplicate from "./ProjectDetailDuplicate";
import EntryPointPreviewPopup from "../entrypoint/EntryPointPreviewPopup";
import BenefitPreview from "../../BenefitPreview";


const ProjectDetailSummary = ({projectId, project, benefitPassword}) => {
    const eventHeaders = [
        { name: "이벤트명" }, { name: "혜택", unsetPadding: true }, { name: "진입점", unsetPadding: true }
    ]
    const [showEvents, setShowEvents] = useState(false)
    const [getEventIdAndShowEntryPoints, setGetEventIdAndShowEntryPoints] = useState(false)
    const [selectedEvents, setSelectedEvents] = useState([])
    const [renderEvents, setRenderEvents] = useState([])
    const [isEventChanged, setEventChanged] = useState(false)
    const [duplicateProjects, setDuplicateProjects] = useState([])
    const [isDuplicatesChanged, setDuplicatesChanged] = useState(false)
    const [allEntryPoints, setAllEntryPoints] = useState([])
    const [showEntryPointAndGetId, setShowEntryPointAndGetId] = useState()
    const [showPreviewPopupAndGetId, setShowPreviewPopupAndGetId] = useState(undefined);

    useEffect(() => {
        getDetailEventsToProject(projectId).then(({data}) => {
            const events = data.events
            setSelectedEvents(events)
            setRenderEvents(events.map(event => {
                setAllEntryPoints([...allEntryPoints, event.entryPoints])
                return [
                    <div>
                        <div className="flex justify-between">
                            <Badge name="룰렛형" color="blue"/>
                            {
                                !project.disabledDelete ? (
                                    <GrayButton className="px-1.5"
                                                onClick={() => eventDelete(event.id)}>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </GrayButton>
                                ) : <></>
                            }
                        </div>
                        <div>{event.name}</div>
                    </div>,
                    <div className="child-full">
                        <div className="table-column-border"><Badge name={`총 ${event.benefits.length}개`} color="grey"/></div>
                        <div>
                            {
                                event.benefits.map(benefit => {
                                    return (
                                        <div className={`table-column-border${benefit.id ? " pointer underline" : ""}`}
                                        onClick={() => {
                                            setShowPreviewPopupAndGetId(benefit.id)
                                        }}>
                                            {benefit.name} ({benefit.winningPercent}%)
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>,
                    <div className="child-full">
                        <div className="flex justify-between items-center w-full table-column-border" style={{height: "44px"}}>
                            <div className=""><Badge name={`총 ${event.entryPoints.length}개`} color="grey"/></div>
                            <div>
                                <Button variants="grey" value="+ 추가하기"
                                        onClick={() => {
                                            setGetEventIdAndShowEntryPoints(event.id)
                                        }}
                                        style={{ display: "flex", alignItems: "center", height: "30px"}} />
                            </div>
                        </div>
                        <div className="w-full">
                            {
                                event.entryPoints.map(entryPoint => {
                                    return (
                                        <div className="flex-align-center justify-between table-column-border">
                                            <div className="pointer underline"
                                                 onClick={() => setShowEntryPointAndGetId(entryPoint.id)}>
                                                {entryPoint.name}
                                            </div>
                                            <div>
                                                {
                                                    !project.disabledDelete ? (
                                                        <GrayButton className="px-1.5"
                                                                    onClick={() => entryPointDelete(event.id, entryPoint.id)}>
                                                            <FontAwesomeIcon icon={faXmark} />
                                                        </GrayButton>
                                                    ) : <></>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                ]
            }))
            setEventChanged(false)
        })
    }, [project.disabledDelete, projectId, isEventChanged]);

    useEffect(() => {
        getDetailDuplicateProjects(projectId).then(({data}) => {
            setDuplicateProjects(data.duplicateProjects)
            setDuplicatesChanged(false)
        })
    }, [projectId, isDuplicatesChanged])

    const eventSave = (eventId) => {
        postProjectEvent(projectId, eventId).then(() => {
            setEventChanged(true)
        })
    }

    const eventDelete = (eventId) => {
        deleteProjectEvent(projectId, eventId).then(() => {
            setEventChanged(true)
        })
    }
    const entryPointSave = (eventId, entryPointIds) => {
        postProjectEntryPoint(projectId, eventId, entryPointIds).then(() => {
            setEventChanged(true)
        })
    }
    const entryPointDelete = (eventId, entryPointId) => {
        deleteProjectEntryPoint(projectId, eventId, entryPointId).then(() => {
            setEventChanged(true)
        })
    }
    const duplicatesSave = (duplicateProjects) => {
        postProjectDuplicates(projectId, duplicateProjects).then(() => {
            setDuplicatesChanged(true)
        })
    }
    const duplicatesDelete = (duplicateProject) => {
        deleteProjectDuplicate(projectId, duplicateProject).then(() => {
            setDuplicatesChanged(true)
        })
    }

    return (
        <div className="flex-col gap-8">
            <div className="flex-col gap-4">
                <div className="flex justify-between">
                    <div>
                        <div className="font-bold font-20px">첫방문 이벤트 설정</div>
                        <div className="text-grey-7">첫방문 이벤트를 설정해 주세요.<span className="ml-2 text-red-500">* 아래 이벤트들은 중복 참여가 불가능합니다.</span></div>
                    </div>
                    {
                        selectedEvents?.length > 0 ? (
                            <div>
                                <Button variants="dark" value="+ 첫방문 이벤트 연결" onClick={() => setShowEvents(true)} />
                            </div>
                        ) : (
                            <div>
                                <Button variants="solid" value="+ 첫방문 이벤트 연결" onClick={() => setShowEvents(true)} />
                            </div>
                        )
                    }
                </div>
                <div>
                    <Table headers={eventHeaders} data={renderEvents} emptyItem={
                        <Button variants="solid" value="+ 첫방문 이벤트 연결" onClick={() => setShowEvents(true)} />
                    } />
                </div>
            </div>
            <div className="flex-col gap-4">
                <div className="flex justify-between">
                    <div className="flex-col gap-2">
                        <div className="font-bold font-20px">첫방문 이벤트 설정</div>
                        <div className="font-bold font-16px">수량 제한 사용</div>
                    </div>
                </div>
                <div className="bg-grey-1 w-full flex-align-center p-20px">
                    <p className="text-mainblue-0 text-sm">
                        *수량 제한을 사용하고 싶으시면 ‘수량 설정' 탭에서 설정해 주세요.
                    </p>
                </div>
            </div>
            <ProjectDetailBenefitPassword projectId={projectId} benefitPassword={benefitPassword} />
            <ProjectDetailPeriod projectId={projectId} initStartDate={project.startDate} initEndDate={project.endDate} disabledEdit={project.disabledDelete} />
            <ProjectDetailDuplicate projectId={projectId} duplicateProjects={duplicateProjects}
                                    onChange={(duplicateProjects) => duplicatesSave(duplicateProjects)}
                                    onDelete={(duplicateProject) => duplicatesDelete(duplicateProject)}
            />
            {
                showEvents && (
                    <SuspensePopup
                        isEditPopup={true}
                        title={"이벤트 추가하기"}
                        size={"w-[1200px]"}
                        onClose={() => setShowEvents(false)}
                    >
                        <EventSimpleList excludeEventIds={selectedEvents.map(event => event.id)}
                                         onSelected={(event) => {
                                             eventSave(event.id)
                                             setShowEvents(false)
                                         }}
                        />
                    </SuspensePopup>
                )
            }
            {
                getEventIdAndShowEntryPoints && (
                    <SuspensePopup
                        isEditPopup={true}
                        title={"진입점 추가하기"}
                        size={"w-[1200px]"}
                        onClose={() => setGetEventIdAndShowEntryPoints(undefined)}
                    >
                        <EntrypointSimpleList selectedEntrypointIds={allEntryPoints.flatMap(entryPoints => entryPoints.map(entryPoint => entryPoint.id))}
                                              onSelected={(entrypointList) => {
                            entryPointSave(getEventIdAndShowEntryPoints, entrypointList.map(entryPoint => entryPoint.spotId))
                            setGetEventIdAndShowEntryPoints(undefined)
                        }} />
                    </SuspensePopup>
                )
            }
            {showEntryPointAndGetId && (
                <SuspensePopup
                    title={"고객 진입점 방식"}
                    size={"w-[522px]"}
                    onClose={() => setShowEntryPointAndGetId(undefined)}
                >
                    <EntryPointPreviewPopup spotId={showEntryPointAndGetId} />
                </SuspensePopup>
            )}
            {showPreviewPopupAndGetId && (
                <SuspensePopup
                    title={"미리보기"}
                    size={"w-[522px]"}
                    onClose={() => setShowPreviewPopupAndGetId(undefined)}
                >
                    <BenefitPreview id={showPreviewPopupAndGetId} />
                </SuspensePopup>
            )}
        </div>
    )
}

export default ProjectDetailSummary