import {fetchApi} from "../fetch";
import {API_URL} from "../../constants/axios";

export const getProductList = async (ids) => {
    const res = await fetchApi.get(`${API_URL.PRODUCT_LIST}${ids ? `?ids=${ids}` : ""}`)
    return res.data;
}

export const getProductDetail = async (id) => {
    const res = await fetchApi.get(`${API_URL.PRODUCT_DETAIL.replace("{id}", id)}`)
    return res.data;
}
export const saveProduct = async (id, product) => {
    const res = await fetchApi.post(`${API_URL.PRODUCT_DETAIL.replace("{id}", id)}`,
        product)
    return res.data;
}

export const productRegistration = async (url) => {
    const res = await fetchApi.get(`${API_URL.PRODUCT_REGISTRATION}?url=${url}`)
    return res.data
}