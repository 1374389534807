import React, {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Editor} from "editor";
import ImageEditorContentsList from "./ImageEditorContentsList";
import {Button, Textarea, Toggle} from "omoplay";
import {FONTS} from "./const/fonts";
import ImageEditorReplaceBackgroundList from "./ImageEditorReplaceBackgroundList";
import {TEMPLATES} from "../constants/templates";

const DIMENSIONS = [
    {
        label: '2:1',
        width: 1000,
        height: 500
    }, {
        label: '4:3',
        width: 1000,
        height: 750
    }, {
        label: '1:1',
        width: 1000,
        height: 1000
    }, {
        label: '3:4',
        width: 1000,
        height: 1332
    }
];

const EditorsContext = createContext({
    setActiveEditorId: () => {},
    updateEditorInstance: () => {},
    activeEditorId: '',
    editors: {}
});

function useEditorsContextValue() {
    const [activeEditorId, setActiveEditorId] = useState('0');
    const [editors, _setEditors] = useState({});

    const setEditor = useCallback((id, value) => {
        _setEditors(state => ({
            ...state,
            [id]: value
        }));
    }, [_setEditors]);

    return useMemo(() => {
        return {
            setActiveEditorId,
            updateEditorInstance: setEditor,
            activeEditorId,
            editors,
        }
    }, [editors, activeEditorId, setActiveEditorId, setEditor]);
}

function OmoplayImageEditor({messageData, initEditors, index, products, onChangeEditors, onUploadImage}) {
    const [replaceBackgroundMode, setReplaceBackgroundMode] = useState(false)
    const [replaceBackgroundPrompt, setReplaceBackgroundPrompt] = useState("")
    const [replaceImages, setReplaceImages] = useState([])
    const [renderEditors, setRenderEditors] = useState(initEditors)
    const editorsContextValue = useEditorsContextValue();
    const {activeEditorId, setActiveEditorId, editors } = editorsContextValue;

    useEffect(() => {
        setRenderEditors(initEditors)
    }, [initEditors])

    useEffect(() => {
        setActiveEditorId(index)
    }, [index])

    useEffect(() => {
        onChangeEditors(editors)
    }, [editors]);

    const handleClickFillBackgrounds = useCallback(() => {
        setReplaceBackgroundPrompt("")
        setReplaceBackgroundMode(false)
        setReplaceImages([])
        replaceImages.forEach(id => {
            editors[id.toString()].ref.current.fillBackground(replaceBackgroundPrompt);
        })
    }, [editors, replaceBackgroundPrompt, replaceImages]);

    return (
        <EditorsContext.Provider value={editorsContextValue}>
            <div className="flex-col gap-2">
                <div className="flex-col gap-2 p-[16px] bg-depth1 rounded-[8px]">
                    <div className="flex-align-center gap-2">
                        <div>
                            <Toggle onToggle={(flag) => setReplaceBackgroundMode(flag)} />
                        </div>
                        <div className="font-semibold">선택한 피드 일괄 AI 배경 생성</div>
                    </div>
                    <div>* 선택한 이미지들은 모두 동일하게 배경제거 처리가 반영됩니다.</div>
                </div>
                <div className="p-[16px] bg-depth1 rounded-[8px]">
                    <div className="flex-center-all gap-[4px]">
                        {
                            replaceBackgroundMode ? (
                                <ImageEditorReplaceBackgroundList messageData={messageData} onChecked={(flag, index) => {
                                    if (flag) {
                                        setReplaceImages(prev => {
                                            const filter = prev.filter(i => i !== index)
                                            return [...filter, index]
                                        })
                                    } else {
                                        setReplaceImages(prev => {
                                            return prev.filter(i => i !== index)
                                        })
                                    }
                                }} />
                            ) : (
                                <ImageEditorContentsList messageData={messageData}
                                                         editors={editors}
                                                         onClickImage={(index) => {
                                                             setActiveEditorId(index.toString());
                                                         }}
                                                         onUploadFile={(imgUrl, index) => {
                                                             onUploadImage(imgUrl, index)
                                                             setActiveEditorId(index.toString())
                                                         }}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={`flex-col gap-2 mt-[12px]${replaceBackgroundMode ? "" : " none"}`}>
                <div className="font-medium">이미지 배경 생성 프롬프트</div>
                <div>
                    <Textarea placeholder="생성하고자 하는 배경을 설명하는 프롬프트를 입력해주세요. 예) 별빛이 있는 밤하늘, 바다"
                              onChange={(e) => setReplaceBackgroundPrompt(e.target.value)}
                    />
                </div>
                <Button variants="primary" disabled={replaceImages.length === 0} value="AI 배경 생성" onClick={() => {
                    if (window.confirm("정말 생성하시겠습니까?")) {
                        handleClickFillBackgrounds()
                    }
                }} />
            </div>
            <div className={`border rounded mt-[8px] overflow-hidden ${replaceBackgroundMode ? "none" : ""}`}>
                {
                    renderEditors.map(editor => {
                        return (
                            <div style={{display: (editor.id === activeEditorId ? 'block' : 'none')}}>
                                <EditorView id={editor.id}
                                            mainImageUrl={editor.mainImageUrl}
                                            products={products}
                                            fonts={FONTS}
                                            dimensions={DIMENSIONS}
                                            dimension="1:1"
                                            messages={{
                                                aiErasePopup: (<p>"AI 지우개"는 6 Credit이 차감됩니다. 진행하시겠습니까?</p>),
                                                aiRemoveBackgroundPopup: (<p>"AI 배경 제거"는 4 Credit이 차감됩니다. 진행하시겠습니까?</p>),
                                                aiReplaceBackgroundPopup: (<p>"AI 배경 바꾸기"는 10 Credit이 차감됩니다. 진행하시겠습니까?</p>),
                                                aiFillBackgroundPopup: (<p>"AI 배경 채우기"는 8 Credit이 차감됩니다. 진행하시겠습니까?</p>)
                                            }}
                                            onAIResponseError={(type, url, response) => {
                                                alert("AI 생성에 실패했습니다. 일시적인 오류로 다시 시도해주세요.")
                                                console.log('AI Response Error!', type, url, response);
                                            }} />
                            </div>
                        )
                    })
                }
            </div>
        </EditorsContext.Provider>
    )
}

function EditorView(props) {
    const {id} = props;
    const {activeEditorId, updateEditorInstance} = useContext(EditorsContext);

    const ref = useRef(null);
    const [thumbnailURL, setThumbnailURL] = useState('');
    const [processingAI, setProcessingAI] = useState(false);
    const productInfo = props.products[id].info

    const handleThumbnailUpdated = useCallback((url) => {
        setThumbnailURL(url);
    }, []);

    useEffect(() => {
        updateEditorInstance(id, {
            ref,
            thumbnailURL,
            isAIProcessing: processingAI
        });
    }, [id, thumbnailURL, processingAI, updateEditorInstance]);

    return (
        <Editor
            {...props}
            ref={ref}
            active={activeEditorId === id}
            onThumbnailUpdated={handleThumbnailUpdated}
            onAIProcessingStateChanged={setProcessingAI}

            customData={{
                productName: productInfo.productName,
                mainTitle: productInfo.productName,
                subTitle: productInfo.productName,
                originalPrice: productInfo.originalPrice,
                salesPrice: productInfo.price,
                discountRate: productInfo.discountRate
            }}
            templates={TEMPLATES}
        />
    );
}

export default OmoplayImageEditor;
