import InputGroup from "../InputGroup";
import Badge from "../badge/Badge";
import BenefitDetailPreview from "./BenefitDetailPreview";
import {Button, TextInput} from "omoplay";
import {fileUpload} from "../../../api/file/file.api";
import {useEffect} from "react";
import BenefitTitle from "./BenefitTitle";

const BenefitDetail = ({benefitDetails, onBenefitImage, onBenefitPrecautionTitle, onBenefitPrecautionMessage, onBenefitLink}) => {
    const benefitData = benefitDetails().benefit
    const benefitImage = benefitData.prizeImage
    const benefitPrecautionTitle = benefitData.prizeCautionTitle ?? benefitDetails().title
    const benefitPrecautionMessage = benefitData.prizeCautionDescription ?? benefitDetails().precaution
    const linkUrl = benefitData.linkUrl

    useEffect(() => {
        if (!benefitData.prizeCautionTitle) {
            onBenefitPrecautionTitle(benefitPrecautionTitle)
        }
        if (!benefitData.prizeCautionDescription) {
            onBenefitPrecautionMessage(benefitPrecautionMessage)
        }
    }, [])

    const uploadImage = (e) => {
        fileUpload(e.target.files[0]).then(({data}) => {
            onBenefitImage(data)
            e.target.value = null
        })
    }

  return (
      <div className="flex" style={{ paddingLeft: "34px", height: "100%"}}>
        <div style={{flexBasis: "50%"}}>
          <InputGroup
              label={"혜택 상세 화면"}
              isRequired={true}
              className="py-8"
          >
            <div>혜택에 대한 상세 화면을 설정해 주세요.</div>
          </InputGroup>
          <div style={{paddingRight: "20px"}}>
              <div className="bg-grey-0 border rounded" style={{padding: "0 32px 32px 32px"}}>
                  <InputGroup
                      label={"혜택 이미지"}
                      isRequired={true}
                      className="py-8"
                  >
                      <div className="flex gap-4">
                          <input
                              type="text"
                              className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
                              placeholder="업로드할 파일 선택"
                              maxLength={30}
                              readOnly
                              value={benefitImage}
                              required
                          />
                          <label
                              htmlFor={"prize_image"}
                              className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
                          >
                              첨부
                          </label>
                          <input
                              type="file"
                              id={"prize_image"}
                              className="hidden"
                              onChange={(e) => uploadImage(e)}
                          />
                      </div>
                      <div className="text-grey-4" style={{fontSize: "12px"}}>권장 비율 2:1 지원 포맷: png,jpeg (최대 10MB)</div>
                  </InputGroup>
                  <InputGroup label={"혜택 유의사항 안내"}>
                      <div className="text-grey-5">고객이 혜택에 대해 세부적으로 알아야 할 내용이 있다면 입력해 주세요.</div>
                      <div className="flex-col gap-2">
                          <div>제목</div>
                          <div>
                              <TextInput value={benefitPrecautionTitle}
                                         onChange={(e) => onBenefitPrecautionTitle(e.target.value)}
                                         maxLength={20}
                              />
                          </div>
                          <div>내용</div>
                          <div>
                              <textarea
                                  className="bg-white border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2  resize-none text-xs whitespace-pre-line"
                                  onChange={(e) => onBenefitPrecautionMessage(e.target.value)}
                                  rows={10}
                                  value={benefitPrecautionMessage}
                              ></textarea>
                          </div>
                      </div>
                  </InputGroup>
                  {
                      benefitData.type === "LINK" && (
                          <InputGroup label={"혜택 링크 설정"}>
                              <div>혜택 받기 버튼을 통해 이동할 URL을 설정해 주세요.</div>
                              <div className="flex-align-center gap-4">
                                  <div style={{flex: "1"}}>
                                      <TextInput value={linkUrl}
                                                 onChange={(e) => onBenefitLink(e.target.value)}
                                                 placeholder="https://www.omoplay.com" maxLength={200} />
                                  </div>
                                  <div><Button variants="default" value="URL 확인" onClick={() => {
                                      window.open(linkUrl)
                                  }} /></div>
                              </div>
                          </InputGroup>
                      )
                  }
              </div>
          </div>
        </div>
        <div className="bg-grey-0 flex-align-center flex-col gap-6" style={{flexBasis: "50%"}}>
            <BenefitTitle name={benefitData.name} retailPrice={benefitData.retailPrice} />
            <Badge name={benefitDetails().label} color="blue" padding="6px 40px" />
            <BenefitDetailPreview benefitData={benefitData} />
        </div>
      </div>
  )
};

export default BenefitDetail;
