import {Table} from "omoplay";
import {useEffect, useState} from "react";
import CircleBadge from "./CircleBadge";
import BenefitSimplePrizeUsers from "./project/BenefitSimplePrizeUsers";
import SuspensePopup from "../SuspensePopup";

const BenefitStatisticsDetail = ({projectId, benefit, events, index}) => {
    const [tableRenderData, setTableRenderData] = useState([])
    const [showPrizeUsers, setShowPrizeUsers] = useState(false)
    const [prizeBenefitId, setPrizeBenefitId] = useState()

    const headers = [
        {name: "이벤트"}, {name: "진입점"},
        {name: (<div className="flex gap-1">
                    <div className="text-mainblue-0">사용</div>
                    <div>/</div>
                    <div>발급</div>
            </div>), width: "190px", align: "center"}, {name: "사용률", width: "130px", align: "center"},
        {name: "마케팅 비용", width: "130px", align: "center"}

    ]

    useEffect(() => {
        const statisticsTableData = events.flatMap((event) => {
            return event.entryPointQuantities.map((entryPoint, index) => {
                return [
                    <div>{index === 0 && event.name}</div>,
                    <div>{entryPoint.name}</div>,
                    <div className="flex-center-all gap-1 font-bold">
                        <div className="text-mainblue-0">{entryPoint.usedQuantity?.toLocaleString()}</div>
                        <div>/</div>
                        <div>{entryPoint.issuedQuantity?.toLocaleString()}</div>
                    </div>,
                    <div className="text-center">{entryPoint.usedRate}%</div>,
                    <div className="text-center">{entryPoint.marketingPrice?.toLocaleString()}원</div>
                ]
            })
        })

        const entryPointQuantities = events.flatMap((event) => event.entryPointQuantities)

        statisticsTableData.push([
            <div className="font-semibold">합계</div>,
            <div></div>,
            <div className="flex-center-all gap-1 font-bold">
                <div className="text-mainblue-0">{benefit.usedQuantity?.toLocaleString()}</div>
                <div>/</div>
                <div>{benefit.issuedQuantity?.toLocaleString()}</div>
            </div>,
            <div className="text-center">{benefit.usedRate}%</div>,
            <div className="text-center">{entryPointQuantities.reduce((a, b) => (b.marketingPrice + a), 0).toLocaleString()}원</div>
        ])
        setTableRenderData(statisticsTableData)

    }, [events])

    return (
        <>
            <div className="flex-col gap-4">
                <div className="flex justify-between" style={{paddingRight: "20px"}}>
                    <div className="flex items-center gap-2">
                        <CircleBadge label={index + 1} />
                        <div className="text-mainblue-0">[{benefit.typeName}]</div>
                        <div className="font-bold">{benefit.name}</div>
                    </div>
                </div>
                <div className="flex justify-between border">
                    <div className="flex-align-center w-full justify-between" style={{padding: "10px 10px", borderRight: "1px solid var(--grey-1)"}}>
                        <div>원가</div>
                        <div className="font-bold">{benefit.supplyPrice.toLocaleString()}</div>
                    </div>
                    <div className="flex-align-center w-full justify-between" style={{padding: "10px 10px", borderRight: "1px solid var(--grey-1)"}}>
                        <div>소비자가</div>
                        <div className="font-bold">{benefit.retailPrice?.toLocaleString() ?? "미설정"}</div>
                    </div>
                    <div className="flex-align-center w-full justify-between" style={{padding: "10px 10px", borderRight: "1px solid var(--grey-1)"}}>
                        <div>사용/발급(사용률)</div>
                        <div className="flex gap-1 font-bold">
                            <div className="text-mainblue-0">{benefit.usedQuantity?.toLocaleString()}</div>
                            <div>/</div>
                            <div>{benefit.issuedQuantity?.toLocaleString()}</div>
                            <div>({benefit.usedRate}%)</div>
                        </div>
                    </div>
                    <div className="flex-align-center w-full justify-between" style={{padding: "10px 10px"}}>
                        <div>당첨자 확인</div>
                        <div className="font-bold underline pointer"
                             onClick={() => {
                                 setPrizeBenefitId(benefit.id)
                                 setShowPrizeUsers(true)
                             }}>당첨자 조회</div>
                    </div>
                </div>
                <Table headers={headers} data={tableRenderData} />
            </div>
            {
                showPrizeUsers && (
                    <SuspensePopup
                        title={benefit.name}
                        size={"w-[1100px]"}
                        onClose={() => setShowPrizeUsers(false)}
                    >
                        <BenefitSimplePrizeUsers projectId={projectId} benefitId={prizeBenefitId} />
                    </SuspensePopup>
                )
            }
        </>
    )
}

export default BenefitStatisticsDetail