import {Suspense, useEffect, useState} from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Loading from "../Loading";

const Layout = ({ children }) => {
    const [minWidth, setMinWidth] = useState(window.innerWidth);
    const [minHeight, setMinHeight] = useState(window.innerHeight);

    const updateMinSize = () => {
        const scrollWidth = window.innerWidth + window.scrollX;
        const scrollHeight = window.innerHeight + window.scrollY;
        setMinWidth(scrollWidth);
        setMinHeight(scrollHeight);
    };

    useEffect(() => {
        // 이벤트 핸들러 추가
        window.addEventListener('scroll', updateMinSize);
        window.addEventListener('resize', updateMinSize);
        window.addEventListener('touchmove', updateMinSize, { passive: true });

        // 초기 실행
        updateMinSize();

        // 이벤트 핸들러 제거
        return () => {
            window.removeEventListener('scroll', updateMinSize);
            window.removeEventListener('resize', updateMinSize);
            window.removeEventListener('touchmove', updateMinSize);
        };
    }, []);

  return (
    <div className="layout bg-slate-200" style={{minWidth: `${minWidth}px`, minHeight: `${minHeight}px`}}>
      <Header />
      <Sidebar />
      <div className="main-layout" id="main-content">
        <div className="inner-layout">
          <Suspense
            fallback={
              <div className="w-100 text-center py-8 justify-center">
                <Loading className={"w-20 h-20 mx-auto"} />
              </div>
            }
          >
              {children}
              <div style={{height: "100px"}}></div>
          </Suspense>
        </div>
      </div>
    </div>
  );
};
export default Layout;
