import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import DateChart from "../DateChart";
import { defaultPieChartConfig } from "../../constants/chart_config";

const defaultConfig = cloneDeep(defaultPieChartConfig);
defaultConfig.options.legend.show = true;
const UserStatistics = ({customers}) => {
  const [genderChartConfig, setGenderChartConfig] = useState({
    ...defaultConfig,
  });
  const [ageRangesChartConfig, setAgeRangesChartConfig] = useState({
    ...defaultConfig,
  });
  const [joinHoursChartConfig, setJoinHoursChartConfig] = useState({
    ...defaultConfig,
  });
  const [joinDayOfWeeksChartConfig, setJoinDayOfWeeksChartConfig] = useState({
    ...defaultConfig,
  });

  useEffect(() => {
    if (customers.gender) {
      setGenderChart()
      setAgesRangeChart()
      setHoursRangeChart()
      setDayOfWeekRangeChart()
    }
  }, [customers])

  const setGenderChart = () => {
    const chartConfig = cloneDeep(defaultConfig);
    const gender = customers.gender
    const maleSize = gender.male
    const femaleSize = gender.female
    const anonymousSize = gender.etc
    chartConfig.series = [maleSize, femaleSize, anonymousSize];
    chartConfig.options.labels = [
      `남성 (${maleSize}%)`,
      `여성 (${femaleSize}%)`,
      `알 수 없음 (${anonymousSize}%)`,
    ];
    setGenderChartConfig(chartConfig);
  }

  const setAgesRangeChart =  () => {
    const chartConfig = cloneDeep(defaultConfig);
    const groupAges = customers.groupAges

    chartConfig.series = [];
    chartConfig.options.labels = [];

    Object.entries(groupAges).forEach(([key, value]) => {
      chartConfig.series.push(value);
      chartConfig.options.labels.push(key + ` (${value}%)`);
    });
    setAgeRangesChartConfig(chartConfig);
  }

  const setHoursRangeChart =  () => {
    const chartConfig = cloneDeep(defaultConfig);
    const joinHours = customers.joinHours

    chartConfig.series = [];
    chartConfig.options.labels = [];

    Object.entries(joinHours).forEach(([key, value]) => {
      chartConfig.series.push(value);
      chartConfig.options.labels.push(key + ` (${value}%)`);
    });
    setJoinHoursChartConfig(chartConfig);
  }

  const setDayOfWeekRangeChart =  () => {
    const chartConfig = cloneDeep(defaultConfig);
    const joinDayOfWeeks = customers.joinDayOfWeeks

    chartConfig.series = [];
    chartConfig.options.labels = [];

    Object.entries(joinDayOfWeeks).forEach(([key, value]) => {
      chartConfig.series.push(value);
      chartConfig.options.labels.push(key + ` (${value}%)`);
    });
    setJoinDayOfWeeksChartConfig(chartConfig);
  }


  return (
    <>
      <div className="grid grid-cols-2 gap-4 relative">
        <div className="bg-white rounded-lg border border-gray-300">
          <div className="border-b border-b-gray-300 w-full py-2 px-4">
            <h5 className="font-medium">성별</h5>
          </div>
          <div className="p-4">
            <DateChart
              chartConfig={genderChartConfig}
              keyName={"user-statistics-gender"}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg border border-gray-300">
          <div className="border-b border-b-gray-300 w-full py-2 px-4">
            <h5 className="font-medium">연령대</h5>
          </div>
          <div className="p-4">
            <DateChart
              chartConfig={ageRangesChartConfig}
              keyName={"user-statistics-ageRange"}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg border border-gray-300">
          <div className="border-b border-b-gray-300 w-full py-2 px-4">
            <h5 className="font-medium">가입 시간대</h5>
          </div>
          <div className="p-4">
            <DateChart
              chartConfig={joinHoursChartConfig}
              keyName={"user-statistics-register-time"}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg border border-gray-300">
          <div className="border-b border-b-gray-300 w-full py-2 px-4">
            <h5 className="font-medium">가입 요일</h5>
          </div>
          <div className="p-4">
            <DateChart
              chartConfig={joinDayOfWeeksChartConfig}
              keyName={"user-statistics-register-week"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default UserStatistics;
