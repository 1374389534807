import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const getAutomateMessage = async (id) => {
    const res = await fetchApi.get(API_URL.AUTOMATE_MESSAGE.replace("{id}", id));
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
};

export const getAutomateMessageList = async () => {
    const res = await fetchApi.get(API_URL.AUTOMATE_MESSAGE_LIST);
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
};

export const postAutomateMessageList = async (conditionData) => {
    const res = await fetchApi.post(API_URL.AUTOMATE_MESSAGE_LIST, conditionData);
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
};
