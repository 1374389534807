import moment from "moment";
import { useMemo } from "react";

const SpotEventStatisticsTable = ({ data, onClick }) => {
  const isLoading = true;
  const weekendString = ["일", "월", "화", "수", "목", "금", "토"];
  const sumCount = (data) => data.reduce((a, b) => b.count + a, 0);

  const results = useMemo(() => data.results, [data]);

  const renderBenefitPercent = (issuedCount, usedCount) => {
    return (
      <p className="text-gray-800">
        <span className="text-indigo-500">{usedCount.toLocaleString()}</span>/
        {issuedCount.toLocaleString()}
        <span className="text-xs text-gray-600 ml-1 font-thin">
          (
          {issuedCount === 0
            ? 0
            : Math.round((usedCount / issuedCount) * 100) + "%"}
          )
        </span>
      </p>
    );
  };
  return (
    <table className="w-full text-left rtl:text-right text-gray-500 bg-white relative">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 rounded-tl border-b divide-y">
        <tr className="divide-x">
          <th
            className={
              "whitespace-nowrap sticky left-0 px-6 bg-gray-100 border-b"
            }
            style={{
              boxShadow: "#e5e7eb -1px 0px 0px 0px inset",
            }}
          ></th>
          {results.map((countInfo, index) => {
            const date = moment(countInfo.date);
            const w = date.day();
            const isWeekend = w === 6 || w === 0;

            return (
              <th
                key={"qr-user-table-header" + index}
                scope="col"
                className={
                  "px-2 py-3 whitespace-nowrap text-center font-normal " +
                  (isWeekend ? "text-red-400" : "text-gray-500")
                }
              >
                {`${date.format("MM-DD")} ${weekendString[w]}`}
              </th>
            );
          })}
          <th
            className={
              "whitespace-nowrap sticky right-0 px-6 bg-gray-100 border-b text-center"
            }
            style={{
              boxShadow: "#e5e7eb -1px 0px 0px 0px inset",
            }}
          >
            기간 합계
          </th>
        </tr>
      </thead>
      <tbody className="divide-y">
        <tr className="divide-x bg-indigo-50 text-indigo-500 sticky top-0">
          <th
            className="whitespace-nowrap sticky left-0 px-6 bg-indigo-50 text-gray-700"
            style={{
              boxShadow: "#e5e7eb -1px 0px 0px 0px inset",
            }}
          >
            전체
          </th>
          {results.map((countInfo, index) => {
            const issuedCount = sumCount(countInfo.issueItems) || 0;
            const usedCount = sumCount(countInfo.usedItems) || 0;
            return (
              <td
                key={"qr-user-table-header" + index}
                scope="col"
                className={"px-2 py-3 whitespace-nowrap text-center"}
              >
                {renderBenefitPercent(issuedCount, usedCount)}
              </td>
            );
          })}
          <th
            className="whitespace-nowrap sticky right-0 px-6 text-center bg-indigo-50 "
            style={{
              boxShadow: "#e5e7eb 1px 0px 0px 0px inset",
            }}
          >
            {renderBenefitPercent(
              results.reduce((a, b) => sumCount(b.issueItems) + a, 0),
              results.reduce((a, b) => sumCount(b.usedItems) + a, 0)
            )}
          </th>
        </tr>
        {data.items.map((benefit) => {
          return (
            <tr className="divide-x">
              <th
                className="whitespace-nowrap sticky left-0 px-6 bg-gray-50 cursor-pointer"
                style={{
                  boxShadow: "#e5e7eb -1px 0px 0px 0px inset",
                }}
              >
                {benefit.name}
              </th>
              {results.map((countInfo, index) => {
                const issuedCount =
                  sumCount(
                    countInfo.issueItems.filter(
                      (item) => item.benefitId === benefit.id
                    )
                  ) || 0;
                const usedCount =
                  sumCount(
                    countInfo.usedItems.filter(
                      (item) => item.benefitId === benefit.id
                    )
                  ) || 0;
                return (
                  <td
                    key={"qr-user-table-header" + index}
                    scope="col"
                    className={"px-2 py-3 whitespace-nowrap text-center"}
                  >
                    {renderBenefitPercent(issuedCount, usedCount)}
                  </td>
                );
              })}
              <td
                className="whitespace-nowrap sticky right-0 px-6 text-center bg-gray-50"
                style={{
                  boxShadow: "#e5e7eb 1px 0px 0px 0px inset",
                }}
              >
                {renderBenefitPercent(
                  results.reduce(
                    (a, b) =>
                      sumCount(
                        b.issueItems.filter(
                          (item) => item.benefitId === benefit.id
                        )
                      ) + a,
                    0
                  ),
                  results.reduce(
                    (a, b) =>
                      sumCount(
                        b.usedItems.filter(
                          (item) => item.benefitId === benefit.id
                        )
                      ) + a,
                    0
                  )
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SpotEventStatisticsTable;
