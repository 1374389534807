import {Checkbox} from "omoplay";
import Badge from "./badge/Badge";
import ArrowUpBlueIcon from "../../assets/images/icon.arrow.up.blue.svg";
import ArrowDownBlueIcon from "../../assets/images/icon.arrow.down.blue.svg";
import {useState} from "react";


const ValidCheckForm = ({index, title, children, isOpen, disabledCheckBox, onChecked}) => {
    const [check, setCheck] = useState(false)
    const [open, setOpen] = useState(isOpen ?? false)

    return (
        <div className={`flex-col gap-2 rounded-[8px] ${check ? "bg-[rgba(231,241,255,1)]" : "bg-shape"}`}>
            <div className="border-b">
                <div className="flex-align-center justify-between p-[14px]">
                    <div className="flex-align-center gap-2">
                        <div><Checkbox onChecked={(checked) => {
                            setCheck(checked)
                            onChecked(checked)
                        }} disabled={disabledCheckBox ?? false} /></div>
                        <div><Badge name={index} color="black" /></div>
                        <div className="text-[16px] font-bold">{title}</div>
                    </div>
                    <div className="flex-align-center gap-1 pointer" onClick={() => setOpen(!open)}>
                        <div className="text-primary text-[16px] font-bold">
                            {
                                check ? "자세히 보기 (완료!)" : "(필수)"
                            }
                        </div>
                        <div><img src={check ? ArrowUpBlueIcon : ArrowDownBlueIcon } alt="up" /></div>
                    </div>
                </div>
            </div>
            {
                open && children
            }
        </div>
    )
}

export default ValidCheckForm