import { DefaultValue, selector } from "recoil";
import { partnerState } from "../state/partner";
import { getPartner } from "../../api/partner/partner.api";

export const partnerSelector = selector({
  key: "partnerSelector",
  get: async ({ get }) => {
    const res = await getPartner();
    if (res.data) {
      return res.data;
    }
    return get(partnerState);
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(partnerState);
    } else if (typeof newValue === "number") {
      set(partnerState, newValue);
    }
  },
});
