import CarouselMessageSetting from "./CarouselMessageSetting.js";
import DefaultMessageSetting from "./DefaultMessageSetting";
import WideListMessageSetting from "./WideListMessageSetting";
import WideMessageSetting from "./WideMessageSetting";
import {Divider} from "@mui/material";
import InputGroup from "../../../form/InputGroup";
import {RadioGroup} from "omoplay";

const MessageContentsSettings = ({ type, messageData, onChangeMessageData, onChangeMessageDataArrays, onChangeImage, onDeleteMessageDataArrays }) => {
    let typeKor = ""
    let renderSetting
    const disabledInform = type === "FC"
    const variables = ["이름", "생일", "주소"]
    const regex = /\[[이름|생일|주소]+\]/g

    if (type === "FT" || type === "FI") {
        typeKor = "기본 텍스트형"
        renderSetting = (
            <DefaultMessageSetting messageData={messageData}
                                   variables={variables}
                                   regex={regex}
                                   onChangeImage={(imgUrl) => {
                                       onChangeImage(imgUrl)
                                   }}
                                   onChangeContents={(content) => {
                                       onChangeMessageData("content", content)
                                   }}
                                   onChangeButtons={(index, key, value) => {
                                       onChangeMessageDataArrays(["buttons", index, key], value)
                                   }}
            />
        )
    } else if (type === "FW") {
        typeKor = "와이드 이미지형"
        renderSetting = (
            <WideMessageSetting messageData={messageData}
                                variables={variables}
                                regex={regex}
                                onChangeImage={(imgUrl) => {
                                    onChangeMessageDataArrays(["image", "imgUrl"], imgUrl)
                                }}
                                onChangeImageLink={(imgLink) => {
                                    onChangeMessageDataArrays(["image", "imgLink"], imgLink)
                                }}
                                onChangeContents={(content) => {
                                   onChangeMessageData("content", content)
                                }}
                                onChangeButtons={(index, key, value) => {
                                    onChangeMessageDataArrays(["buttons", index, key], value)
                                }}
            />
        )
    } else if (type === "FL") {
        typeKor = "와이드 리스트형"
        renderSetting = (
            <WideListMessageSetting messageData={messageData}
                                    variables={variables}
                                    regex={regex}
                                    onChangeHeader={(header) => {
                                        onChangeMessageData("header", header)
                                    }}
                                    onChangeItems={(index, key, value) => {
                                        onChangeMessageDataArrays(["items", index, key], value)
                                    }}
                                    onDeleteItem={(index) => onDeleteMessageDataArrays(index, ["items"])}
            />
        )
    } else if (type === "FC") {
        typeKor = "캐러셀 피드형"
        renderSetting = (
            <CarouselMessageSetting messageData={messageData}
                                    variables={variables}
                                    regex={regex}
                                    onChangeCarousels={(index, key, value) => {
                                        onChangeMessageDataArrays(["carousels", index, key], value)
                                    }}
                                    onDeleteCarousels={(index) => {
                                        onDeleteMessageDataArrays(index, ["carousels"])
                                    }}
                                    onDeleteAllCarousels={() => {
                                        onChangeMessageData("carousels", [])
                                    }}
                                    onChangeCarouselImage={(index, value) => {
                                        onChangeMessageDataArrays(["carousels", index, "attachment", "image", "imgUrl"], value)
                                    }}
                                    onChangeCarouselButtons={(index, buttonIndex, key, value) => {
                                        onChangeMessageDataArrays(["carousels", index, "attachment", "buttons", buttonIndex, key], value)
                                    }}
                                    onChangeTail={(key, value) => {
                                        onChangeMessageDataArrays(["tail", key], value)
                                    }}
            />
        )
    }
  return (
      <>
          <h3 style={{ fontWeight: "600", fontSize: "20px", marginBottom: "2px", marginTop: "2px" }}>
              {typeKor} 작성하기
          </h3>
          <Divider />
          <InputGroup
              label={"광고 표기 여부"}
              isRequired={true}
              className="py-4"
          >
              <div className="flex-align-center gap-2">
                  <RadioGroup
                      items={[
                          {
                              label: "광고성",
                              value: "Y"
                          },
                          {
                              label: "정보성",
                              value: "N",
                              disabled: disabledInform
                          }
                      ]}
                      initChecked={messageData.adFlag ?? "Y"}
                      value={messageData.adFlag}
                      onSelected={(value) => onChangeMessageData("adFlag", value)}
                  />
              {
                  disabledInform && (
                      <div className="text-red-500">* 캐러셀 메시지는 광고메시지로 분류됩니다.</div>
                  )
              }
              </div>
          </InputGroup>
          <Divider sx={{ mb: 4 }} />
        {renderSetting}
      </>
  );
};

export default MessageContentsSettings;
