import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const getBillInfo = async (startDate, endDate) => {
  const searchParam = new URLSearchParams({
    startDate,
    endDate,
  });
  const res = await fetchApi.get(
    API_URL.GET_BILL + "?" + searchParam.toString()
  );
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};

export const getBillDetails = async (
  page = 0,
  size = 9999,
  startDate,
  endDate,
  type
) => {
  const searchParam = new URLSearchParams({
    page,
    size,
    startDate,
    endDate,
    type,
  });
  const res = await fetchApi.get(
    API_URL.GET_BILL_DETAILS + "?" + searchParam.toString()
  );
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};
