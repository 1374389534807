import {PageDArrowLeftIcon} from "omoplay";

const EditTitle = ({ title }) => {
  return (
    <div className="fixed flex-align-center bg-white w-full h-[56px] z-[20]" style={{padding: "24px"}}>
        <div className="flex-align-center pointer" style={{width: "32px", height: "32px"}} onClick={() => window.history.back()} >
            <PageDArrowLeftIcon />
        </div>
      <h2 className="ml-2 font-bold text-lg">{title}</h2>
    </div>
  );
};

export default EditTitle;
