import {useMemo} from "react";
import { MESSAGE_TYPES } from "../../constants/menus";
import { floor } from "lodash";
import moment from "moment";
import InputGroup from "../form/InputGroup";
import {Chip} from "omoplay";
import MessageWrapPreview from "../volatility-message/content/preview/MessageWrapPreview";

export const AIAgentMessageConfirm = ({message, customerCount, onValidState}) => {

  const messageData = message.messageData
  const filters = message.filters
  const filterCategories = useMemo(() => {
    return Array.from(new Set(filters?.map((item) => item.category)));
  }, [filters]);

  const issuedAt = message.issuedAt

  const campaignName = message.campaignName
  const type = messageData.type

  const targetSettingValid = moment(issuedAt.date.startDate, "YYYY-MM-DD")
      .hour(issuedAt.hour).minutes(issuedAt.min).isAfter(moment())
  onValidState(targetSettingValid)

  return (
      <InputGroup label="최종 확인" description="마지막으로 보내기 전 설정하신 내용에 잘못된 내용은 없는지 확인해 보세요.">
        <div className="flex">
          <div className="flex-1 pr-[16px] pb-[16px]">
            <div className="font-semibold">콘텐츠</div>
            <div className="flex-center-all h-full">
              <MessageWrapPreview messageData={messageData} type={type} />
            </div>
          </div>
          <div className="flex-col gap-4 pl-[16px]">
            <div className="flex-col gap-1">
              <div className="font-semibold">기본 설정</div>
              <ul className="flex-col border radius-6">
                <li className="flex items-center">
                  <label className="p-[14px] w-[120px] bg-depth1 font-medium">캠페인명</label>
                  <div className="p-[14px]">{campaignName}</div>
                </li>
                <li className="flex items-center">
                  <label className="p-[14px] w-[120px] bg-depth1 font-medium">메시지 타입</label>
                  <div className="p-[14px]">친구톡</div>
                </li>
                <li className="flex items-center">
                  <label className="p-[14px] w-[120px] bg-depth1 font-medium">친구톡 타입</label>
                  <div className="p-[14px]">
                    {type &&
                        MESSAGE_TYPES.filter(
                            (item) => item.key === type
                        )[0]?.title}
                  </div>
                </li>
              </ul>
            </div>
            <div className="flex-1 flex-col gap-1">
              <div className="font-semibold">대상 및 발송 일정</div>
              <ul className="flex-col border radius-6">
                <li className="flex items-center">
                  <label className="p-[14px] w-[120px] bg-depth1 font-medium">대상 조건</label>
                  <div className="pl-[14px]">
                    {(filterCategories && filterCategories.length > 0) ?
                        filterCategories.map((value, i) => {
                          return (
                              filters.filter((item) => item.category === value)
                                    .map((item, j) => {
                                      return (<Chip>{item.label}</Chip>
                                      );
                                    })
                          );
                        }) : <Chip>전체</Chip> }
                  </div>
                </li>
                <li className="flex items-center">
                  <label className="p-[14px] w-[120px] bg-depth1 font-medium">예상 발송 수</label>
                  <div className="pl-[14px]">{customerCount.toLocaleString()}명</div>
                </li>
                <li className="flex items-center">
                  <label className="p-[14px] w-[120px] bg-depth1 font-medium">발송 날짜/시간</label>
                  <div className="pl-[14px]">
                    {
                      `${issuedAt.date.startDate} ${issuedAt.hour}시 ${issuedAt.min}분`
                    }
                    {
                        !targetSettingValid && (<span className="text-red-500 ml-2">* 예약 날짜는 과거로 설정할 수 없습니다.</span>)
                    }
                  </div>
                </li>
                <li className="flex items-center">
                  <label className="p-[14px] w-[120px] bg-depth1 font-medium">유료 발송 수</label>
                  <div className="pl-[14px]">{customerCount.toLocaleString()}건</div>
                </li>
                <li className="flex items-center">
                  <label className="p-[14px] w-[120px] bg-depth1 font-medium">발송 비용 합계</label>
                  <div className="pl-[14px]">
                    16.5원 *{" "}
                    {customerCount.toLocaleString()}건 ={" "}
                    {floor(customerCount * 16.5).toLocaleString()}원
                  </div>
                </li>
              </ul>
              <div className="text-[13px] text-tertiary mt-[4px]">
                <div>* 메시지 차단 등으로 인해 예상 발송 수와 실발송 수가 다를 수 있습니다.</div>
                <div>* 메시지 발송등록 시 과금된 비용과 실발송 후의 차액은 발생할 수 있으며, 발송되지 않은 발송 건은 청구되지 않습니다.</div>
              </div>
            </div>
          </div>
        </div>
      </InputGroup>
  );
};

export default AIAgentMessageConfirm;
