import {
  TextField,
} from "@mui/material";
import React, {useRef, useState} from "react";
import {fileUpload, kakaoImageUpload} from "../../../../api/file/file.api";
import {Button, RadioGroup} from "omoplay";
import ButtonsForm from "../../../form/message/ButtonsForm";
import VariableTextArea from "../../../form/VariableTextArea";

const DefaultMessageSetting = ({ messageData, variables, regex, onChangeImage, onChangeContents, onChangeButtons}) => {
    const [isImage, setIsImage] = useState(messageData.image?.imgUrl ?? false)
    const buttons = messageData.buttons
    const content = messageData.content

    const fileInputRef = useRef(null)

    const uploadImage = (e) => {
        kakaoImageUpload(e.target.files[0], "FI").then(({data}) => {
            if (data.code === "0") {
                onChangeImage("imgUrl", data.imageUrl);
                fileUpload(e.target.files[0]).then(({data}) => {
                    onChangeImage("originalUrl", data)
                })
            } else {
                alert(data.message);
            }
        })
    }

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

  return (
        <div className="flex-col gap-8">
            <div className="flex-col gap-2">
                <div className="font-semibold">메시지 꾸미기</div>
                <RadioGroup
                    items={[
                        {
                            label: "없음",
                            value: "text"
                        },
                        {
                            label: "이미지",
                            value: "image"
                        }
                    ]}
                    initChecked={isImage ? "image" : "text"}
                    value={isImage}
                    onSelected={(e) => {
                        setIsImage(e === "image")
                        onChangeImage(e === "image" ? "" : undefined);
                    }}
                />
                {isImage && (
                    <>
                        <div className="flex gap-[8px]">
                            <input
                                accept="image/jpg, image/jpeg, image/png"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                id="raised-button-file"
                                type="file"
                                onChange={(e) => uploadImage(e)}
                            />
                            <TextField
                                inputProps={{ readOnly: true }}
                                fullWidth
                                placeholder="이미지 파일을 업로드 해주세요."
                                size={"small"}
                                value={messageData.image?.imgUrl}
                            />
                            <Button
                                variants="dark"
                                value="첨부"
                                onClick={handleButtonClick}
                                style={{ width: "105px", height: "40px" }}
                            />
                        </div>
                        <div className="text-tertiary text-[12px]">* 가로 500px 이상, 최소 비율 2:1, 최대 비율 3:4 지원 포맷: png,jpeg (최대 5MB)</div>
                    </>
                )}
            </div>
            <div>
                <div className="font-semibold mb-2">내용</div>
                <VariableTextArea type={messageData.type} message={content} variables={variables} regex={regex} maxLength={400} onChangeMessage={onChangeContents} />
            </div>
            <ButtonsForm maxLength={2} buttons={buttons} onChangeButtons={onChangeButtons} />
        </div>
  );
};

export default DefaultMessageSetting;
