import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getBillInfo } from "../../api/cash/bill.api";
import SortableTableHeader from "../table/SortableTableHeader";
import Loading from "../Loading";
import { csCZ } from "@mui/x-date-pickers";
import SuspensePopup from "../SuspensePopup";
import BillDetail from "../BillDetail";
import { padStart, range } from "lodash";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
export const BillWrap = () => {
  const [year, setYear] = useState(moment().format("YYYY"));
  const [month, setMonth] = useState(moment().add(-1, "months").format("MM"));
  const [endOfMonth, setEndOfMonth] = useState(1);
  const [data, setData] = useState([]);
  const [popup, setPopup] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const startDate = `${year}-${month}-01`;
    const endDay = moment(startDate).endOf("month").format("DD");
    setEndOfMonth(endDay);
  }, [year, month]);

  const fetchBillInfo = useCallback(async () => {
    const startDate = `${year}-${month}-01`;
    const endDate = `${year}-${month}-${endOfMonth}`;
    const res = await getBillInfo(startDate, endDate);
    setLoading(false);
    setData(res.data.days);
  }, [year, month, endOfMonth]);
  useEffect(() => {
    if (endOfMonth > 1) {
      setLoading(true);
      fetchBillInfo();
    }
  }, [endOfMonth]);

  const totalPrice = useMemo(() => {
    return data.reduce((a, b) => {
      return (
        b.items.reduce((a, b) => {
          return b.price * -1 + a;
        }, 0) + a
      );
    }, 0);
  }, [data]);

  const headers = [
    { key: "issuedAt", label: "일자", sortable: false },
    { key: "name", label: "내역", sortable: false },
    {
      key: "category",
      label: "항목",
      sortable: false,
    },
    {
      key: "perPrice",
      label: "단가",
      sortable: false,
    },
    {
      key: "count",
      label: "사용량",
      sortable: false,
    },
    {
      key: "price",
      label: "청구 금액",
      sortable: false,
    },
  ];

  const renderHistoryType = (row) => {
    return (
      <button
        className="underline hover:text-black text-gray-700"
        onClick={() => setPopup(row.historyTypeCode)}
      >
        {row.historyType}
      </button>
    );
  };

  return (
    <div>
      <div className="p-4 bg-gray-300 border w-full flex gap-4">
        <select
          className="w-100 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block p-2 disabled:text-gray-400 hover:disabled:bg-gray-300"
          onChange={(e) => setYear(e.target.value)}
        >
          {range(2024, Number(moment().format("YYYY")) + 1).map((year) => {
            return <option value={year}>{year}년</option>;
          })}
        </select>
        <ul className="-space-x-px text-base h-10 flex gap-2 items-center">
          <li>
            <button
              className="flex items-center justify-center w-6 h-6 ms-0 leading-tight text-gray-500 hover:bg-gray-700 hover:text-white rounded"
              disabled={month === "01"}
              onClick={() => setMonth(padStart(Number(month) - 1, 2, "0"))}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
          </li>
          <li>{month}</li>
          <li>
            <button
              className="flex items-center justify-center w-6 h-6 ms-0 leading-tight text-gray-500 hover:bg-gray-700 hover:text-white rounded disabled:text-gray-400 hover:disabled:bg-gray-300"
              disabled={
                month === "12" ||
                moment(`${year}-${month}-1`).isSameOrAfter(
                  moment().add(-1, "months")
                )
              }
              onClick={() => setMonth(padStart(Number(month) + 1, 2, "0"))}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </li>
        </ul>
      </div>
      <div className="w-full mt-8 flex-col gap-8">
        <div className="flex gap-4 text-sm">
          <div className="p-6 bg-white border border-gray-300 w-1/2">
            <div className="flex justify-between items-center">
              <p className="text-gray-700">
                {year}년 {month}월 청구 금액
              </p>
            </div>
            <div className="mt-3 mb-2">
              <p className="text-2xl text-gray-700 font-bold">
                {totalPrice.toLocaleString()}원
              </p>
            </div>
          </div>
          <div className="p-6 bg-white border border-gray-300 w-1/2">
            <div className="flex justify-between items-center h-full">
              <p className="text-gray-700">사용 기간</p>
              <p className="text-base font-semibold text-gray-600">
                {year}년 {month}월 01일 ~ {year}년 {month}월 {endOfMonth}일
              </p>
            </div>
          </div>
        </div>
        <div>
          <h4 className="font-semibold text-xl mb-2">캐시 충전/사용 내역</h4>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 border mb-4 relative ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 rounded-tl border-b">
              <tr className="divide-x">
                {headers.map((header, index) => {
                  return (
                    <SortableTableHeader
                      key={"bill-wrap-table-header" + index}
                      header={header}
                    />
                  );
                })}
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={7} className="text-center">
                    <Loading className="w-12 h-12 mx-auto my-6" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {data
                  ?.filter((row) => row.items.length > 0)
                  .map((row, index) => {
                    const cs = "px-3 py-2 ";
                    const rowspan = row.items.length;
                    const items = [...row.items];
                    const firstItem = items.shift();
                    return (
                      <>
                        <tr
                          className="bg-white border-b hover:bg-gray-50 divide-x"
                          key={"bill-" + row.day}
                        >
                          <td className={cs} rowSpan={rowspan}>
                            {row.day}
                          </td>
                          <td
                            className={cs + "font-medium text-gray-800"}
                            rowSpan={rowspan}
                          >
                            메시지 발송 비용
                          </td>
                          <td className={cs}>{firstItem.historyType}</td>
                          <td className={cs}>
                            {firstItem.unitPrice.toLocaleString()}
                          </td>
                          <td className={cs + "text-right"}>
                            {firstItem.count.toLocaleString()}
                          </td>
                          <td className={cs + "font-bold text-right"}>
                            ₩{(firstItem.price * -1).toLocaleString()}
                          </td>
                        </tr>
                        {items.map((item, j) => {
                          return (
                            <tr
                              className="border-b bg-white hover:bg-gray-50 divide-x"
                              key={"bill-category-" + j}
                            >
                              <td className={cs + "border-l"}>
                                {item.historyType}
                              </td>
                              <td className={cs}>
                                {item.unitPrice.toLocaleString()}
                              </td>
                              <td className={cs + "text-right"}>
                                {item.count.toLocaleString()}
                              </td>
                              <td className={cs + "font-bold text-right"}>
                                ₩{(item.price * -1).toLocaleString()}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    );
                  })}
                <tr className="bg-gray-50 text-sm divide-x">
                  <th className="px-6 py-3 rounded-bl">총합</th>
                  <th
                    className="text-right px-6 font-bold py-3 rounded-br"
                    colSpan={5}
                  >
                    ₩{totalPrice.toLocaleString()}
                  </th>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        {popup && (
          <SuspensePopup
            title={"세부내역"}
            size={"w-full max-w-4xl"}
            onClose={() => setPopup(null)}
          >
            <BillDetail
              type={popup}
              startDate={`${year}-${month}-01`}
              endDate={`${year}-${month}-${endOfMonth}`}
            />
          </SuspensePopup>
        )}
      </div>
    </div>
  );
};

export default BillWrap;
