import { useEffect, useState } from "react";
import {getAllDateRange} from "../../libs/helper";
import moment from "moment";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleExclamation, faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import GrayButton from "../GrayButton";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import Tooltip from "../Tooltip";
import {cancelReserved} from "../../api/message/volatility-message.api";
import {Button, CalendarBar, Pagination, Tab, Table} from "omoplay";
import {createAIMessage, listAIMessages} from "../../api/ai/ai.api";
import AIMessageBanner from "../../assets/images/ai_message_banner.svg"
import StarIcon from "../../assets/images/icon.star.svg"


const AIAgentMessageList = () => {
  const [dateRange, setDateRange] = useState(getAllDateRange());
  const [searchType, setSearchType] = useState("all");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPages] = useState(0);
  const [tableData, setTableData] = useState([])

  const dateItems = [
    { value: "all", label: "전체", selected: true },
    { value: "month", label: "최근 한 달" },
    { value: "week", label: "최근 일주일" },
  ];

  const messageStatus = [
    { value: "all", label: "전체" },
    { value: "succeed", label: "발송 완료" },
    { value: "failed", label: "발송 실패" },
    { value: "reserved", label: "발송 예약" },
  ]

  const headers = [
    { name: "", sortable: false, width: "100px" },
    { name: "상태", sortable: false, width: "150px" },
    { name: "메시지 타입", sortable: false, width: "150px" },
    {
      name: "발송 내역",
      sortable: false,
    },
    {
      name: "성공 수 / 발송 수",
      sortable: false,
      align: "end",
      width: "190px"
    },
    {
      name: "링크 클릭 수",
      sortable: false,
      align: "end",
      width: "110px"
    },
    {
      name: "발송 비용",
      sortable: false,
      width: "100px"
    },
    {
      name: "발송 일시",
      sortable: false,
      width: "200px"
    },
  ];

  useEffect(() => {
    const renderName = (id, status, name, errText) => {
      const commonClass = "flex justify-between w-full items-center ";
      if (status === "RESERVED") {
        return (
            <div className={commonClass}>
              <Link to={"/agent/message/edit?id=" + id}>
                <div className={commonClass + "cursor-pointer"}>
                  <p>{name}</p>
                </div>
              </Link>
              <GrayButton
                  className="text-xs px-3"
                  onClick={() => { cancelReservedMessage(id)} }
              >
                예약 취소
              </GrayButton>
            </div>
        );
      } else if (status === "FAILED") {
        return (
            <Link to={"/agent/message/edit?id=" + id}>
              <div className={commonClass + "text-red-500"}>
                <p>{name}</p>
                <div className="inline-block align-text-top ml-2">
                  <Tooltip top={"top-4"} message={errText}>
                    <FontAwesomeIcon icon={faCircleExclamation}  />
                  </Tooltip>
                </div>
              </div>
            </Link>
        );
      } else if (status === "COUNTING") {
        return (
            <div className={commonClass + "text-indigo-600 cursor-pointer"}>
              <p>{name}</p>
            </div>
        );
      } else if (status === "SUCCEED" || status === "SENDING") {
        return (
            <Link to={"/volatility-message/detail?id=" + id}>
              <div className={commonClass + "cursor-pointer"}>
                <p>{name}</p>
                <FontAwesomeIcon icon={faFileAlt} />
              </div>
            </Link>
        );
      } else if (status === "TEMPORARY") {
        return (
            <Link to={"/agent/message/edit?id=" + id}>
              <div className={commonClass + "text-gray-400"}>
                <p>{name}</p>
                <div className="inline-block align-text-top ml-2">
                  <Tooltip top={"top-4"} message="임시 저장 상태입니다">
                    <FontAwesomeIcon icon={faCircleExclamation}  />
                  </Tooltip>
                </div>
              </div>
            </Link>
        );
      }
    };

    const renderType = (type) => {
      if (type === "TEXT" || type === "TEXT_IMAGE") {
        return "기본 텍스트형"
      } else if (type === "WIDE_IMAGE") {
        return "와이드 이미지형"
      } else if (type === "LIST_IMAGE") {
        return "와이드 리스트형"
      } else if (type === "CAROUSEL") {
        return "캐러셀 피드형"
      }
    }

    listAIMessages(dateRange.startDate, dateRange.endDate, searchType.toUpperCase(), page - 1, 10)
        .then((res) => {
          if (res.data) {
            setTableData(res.data.content.map(message => {
              return [
                message.id,
                renderStatus(message.status),
                renderType(message.messageType),
                renderName(message.id, message.status, message.campaignName, message.errorText),
                <div className="text-right">
                  {message.successCount.toLocaleString()} / {message.sendCount.toLocaleString()} ({ message.successCount === 0 ? "0" : Math.round(message.successCount / message.sendCount * 100)}%)
                </div>,
                <div className="text-right">
                  {message.linkCount.toLocaleString()} (
                  {(message.linkCount && message.successCount
                          ? Math.round((message.linkCount / message.successCount) * 100)
                          : 0
                  ).toLocaleString() + "%"}
                  )
                </div>,
                `₩${message.totalPrice.toLocaleString()}`,
                moment(message.issuedAt).format("YYYY.MM.DD HH:mm")
              ]
            }))
            setTotalPages(res.data.totalElements);
          }
        })
  }, [dateRange, page, searchType]);

  const handleQuickChange = (type) => {
    const format = "YYYY-MM-DD"
    const allDateRange = getAllDateRange()

    if (type === "all") {
      setDateRange(allDateRange);
    } else if (type === "month") {
      setDateRange({
        startDate: moment().subtract(1, "months").format(format),
        endDate: allDateRange.endDate,
      });
    } else if (type === "week") {
      setDateRange({
        startDate: moment().subtract(6, "days").format(format),
        endDate: allDateRange.endDate,
      });
    }
  };

  const onDateInput = (dateRange) => {
    setDateRange({
      startDate: moment(dateRange.startDate).format("yyyy-MM-DD"),
      endDate: moment(dateRange.endDate).format("yyyy-MM-DD")
    });
  };

  const handleCreateClick = () => {
    createAIMessage().then(({data}) => {
      window.location.href = `/agent/message/edit?id=${data}`;
    })
  };

  const cancelReservedMessage = (id) => {
    if (window.confirm("예약을 취소하시겠습니까?\n임시 저장 상태로 변경됩니다.")) {
      cancelReserved(id).then(() => {
        window.location.reload()
      })
    }
  }

  const renderStatus = (status) => {
    if (status === "RESERVED") {
      return (
          <div className="flex gap-2 text-indigo-600 items-center font-semibold">
            <FontAwesomeIcon icon={faCheckCircle} />
            <p>발송 예약</p>
          </div>
      );
    } else if (status === "FAILED") {
      return (
          <div className="flex gap-2 text-red-500 items-center font-semibold">
            <FontAwesomeIcon icon={faCircleExclamation} />
            <p>발송 실패</p>
          </div>
      );
    } else if (status === "SENDING") {
      return (
          <div className="flex gap-2 text-indigo-600 items-center font-semibold">
            <FontAwesomeIcon icon={faSpinner} />
            <p>전송중</p>
          </div>
      );
    } else if (status === "SUCCEED") {
      return (
          <div className="flex gap-2 text-gray-600 items-center font-semibold">
            <FontAwesomeIcon icon={faCheckCircle} />
            <p>발송 완료</p>
          </div>
      );
    } else if (status === "TEMPORARY") {
      return (
          <div className="flex gap-2 text-gray-400 items-center font-semibold">
            <FontAwesomeIcon icon={faCircleExclamation} />
            <p>임시 저장</p>
          </div>
      );
    }
  };

  return (
    <>
      <div>
        <img className="w-full" alt="ai-message-banner" src={AIMessageBanner} />
      </div>
      <div className="bg-white relative border border-gray-300 rounded-lg flex gap-4 mb-8 p-4 items-center" style={{marginTop: "35px"}}>
        <div className="flex gap-4">
          <div>
            <Tab
                variants="input"
                items={dateItems}
                onChanged={(data) => handleQuickChange(data.value)}
            />
          </div>
          <CalendarBar initDate={{startDate: moment(dateRange.startDate).toDate(), endDate: moment(dateRange.endDate).toDate()}}
                       onConfirm={(props) => onDateInput(props)} />
        </div>
      </div>
      <div className="flex-col bg-white rounded p-8 px-4 w-full">
        <h4 className="text-xl font-semibold">메시지 목록</h4>
        <ul className="flex border-gray-300 border-b-2 gap-8 px-4 items-center">
          {messageStatus.map((status) => {
            return (
              <li
                className={
                  "py-4 px-4 cursor-pointer " +
                  (searchType === status.value
                    ? "border-b-4 border-indigo-500"
                    : "")
                }
                onClick={() => setSearchType(status.value)}
              >
                {status.label}
              </li>
            );
          })}
          <li className="ml-auto">
            <Button
              variants="dark"
              value={<div className="flex-align-center gap-1"><img src={StarIcon} alt="star" style={{width: "16px", height: "16px"}} />AI 자동 생성하기</div>}
              style={{background: "linear-gradient(96.03deg, #1271FF -0.08%, #600B99 99.92%)"}}
              onClick={() => handleCreateClick(true)}
             />
          </li>
        </ul>
        <div className="flex-col py-4">
          <Table headers={headers} data={tableData} />
          <div className="flex justify-center" style={{marginTop: "24px"}}>
            <Pagination rowSize={10} totalCount={totalPage} currentPage={page} onPaging={(page) => setPage(page)} />
          </div>
        </div>
      </div>
    </>
  );
};
export default AIAgentMessageList;
