import { useEffect, useState } from "react";
import { fileUpload } from "../../../api/file/file.api";
import InputGroup from "../InputGroup";
import {Button, RadioGroup, TabBar, TextInput} from "omoplay";
import Badge from "../badge/Badge";
import CircleBadge from "../CircleBadge";
import GrayButton from "../../GrayButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {Divider} from "@mui/material";
import CheckCircleIcon from "../../../assets/images/check_circle_icon.svg"
import DisabledCheckCircleIcon from "../../../assets/images/check_circle_disabled_icon.svg"
import EventLoginPreview from "./EventLoginPreview";
import EventRoulettePreview from "./EventRoulettePreview";
import EventPrizePreview from "./EventPrizePreview";
import EventLoginRoulettePreview from "./EventLoginRoulettePreview";
import EventLoginPrizePreview from "./EventLoginPrizePreview";

const EventScreen = ({
    event, onChangeLoginType, onChange, onValid
}) => {
  const eventType = event.type ?? "roulette"
  const loginBackgroundImage = event.loginBackgroundImage ?? ""
  const eventBackgroundImage = event.eventBackgroundImage ?? ""
  const eventImage = event.eventImage ?? ""
  const eventButtonImage = event.eventButtonImage ?? "https://img.headless.co.kr/roulette/start_image.svg"
  const loginButtonName = event.loginButtonName ?? "로그인하고 룰렛 돌리기"
  const loginPrecautionName = event.loginPrecautionName ?? ""
  const loginPrecautionContent = event.loginPrecautionContent ?? ""
  const prizePrecautionContent = event.prizePrecautionContent
  const additionalButtons = event.additionalButtons ?? []
  const [loginType, setLoginType] = useState(event.loginType ?? "registration")
  const [detailStep, setDetailStep] = useState(event.loginType === "event" ? 'roulette' : "login")
  const [notRequired, setNotRequired] = useState(false)
  const helpUrl = "https://drive.google.com/file/d/1mPVIRwPf5DzM7ZgvZP3lk2TwW4v1BBAM/view?usp=drive_link"

  const onChangeFile = (e, key) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      fileUpload(file).then((res) => {
        if (key === "login") {
          onChange("loginBackgroundImage", res.data)
        } else if (key === "event-background-image") {
          onChange("eventBackgroundImage", res.data)
        } else if (key === "event-image") {
          onChange("eventImage", res.data)
        } else if (key === "event-button") {
          onChange("eventButtonImage", res.data)
        }
      })
    }
    e.target.value = null
  }

  const radioTypeItems = [
    { label: (
        <div className="flex gap-2 items-center">
          <div>회원가입 (로그인)</div>
          <div>></div>
          <div>이벤트 참여</div>
          <div><Badge name="오프라인 추천" color="blue" /></div>
        </div>
      ), value: "registration" },
    { label: (
          <div className="flex gap-2 items-center">
            <div>이벤트 참여</div>
            <div>></div>
            <div>로그인</div>
            <div className="text-red-500">{eventType !== "roulette" ? "* 해당 타입은 선택할 수 없습니다." : ""}</div>
            <div><Badge name="온라인 추천" color="black" /></div>
          </div>
      ), value: "event", disabled: eventType !== "roulette"}
  ]

  useEffect(() => {
    let valid
    if (loginType === "registration") {
      if (eventType === "roulette") {
        valid = loginBackgroundImage && loginButtonName && eventBackgroundImage && eventImage && prizePrecautionContent
      } else {
        valid = loginBackgroundImage && loginButtonName && prizePrecautionContent
      }
    } else {
      valid = eventBackgroundImage && eventImage && prizePrecautionContent
    }
    setNotRequired(valid)
    onValid(valid)
  }, [loginBackgroundImage, loginButtonName, eventBackgroundImage, eventImage, eventButtonImage, detailStep, prizePrecautionContent])

  const registrationTypeSwitchItems = eventType === "roulette" ? [
    {
      label: (
          <div className="flex-center-all gap-2"><img alt="" src={(loginBackgroundImage && loginButtonName) ? CheckCircleIcon : DisabledCheckCircleIcon} /><div>로그인 설정</div></div>
      ),
      value: "login",
      selected: true
    },
    {
      label: (
          <div className="flex-center-all gap-2"><img alt="" src={(eventBackgroundImage && eventImage) ? CheckCircleIcon : DisabledCheckCircleIcon} /><div>룰렛 설정</div></div>
      ),
      value: "roulette"
    },
    {
      label: (
          <div className="flex-center-all gap-2"><img alt="" src={(prizePrecautionContent) ? CheckCircleIcon : DisabledCheckCircleIcon} /><div>당첨 안내 설정</div></div>
      ),
      value: "prize"
    }
  ] : [
    {
      label: (
          <div className="flex-center-all gap-2"><img alt="" src={(loginBackgroundImage && loginButtonName) ? CheckCircleIcon : DisabledCheckCircleIcon} /><div>로그인 설정</div></div>
      ),
      value: "login",
      selected: true
    },
    {
      label: (
          <div className="flex-center-all gap-2"><img alt="" src={(prizePrecautionContent) ? CheckCircleIcon : DisabledCheckCircleIcon} /><div>당첨 안내 설정</div></div>
      ),
      value: "prize"
    }
  ]

  const eventTypeSwitchItems = [
    {
      label: (
          <div className="flex-center-all gap-2"><img alt="" src={(eventBackgroundImage && eventImage && eventButtonImage) ? CheckCircleIcon : DisabledCheckCircleIcon} /><div>룰렛 설정</div></div>
      ),
      value: "roulette",
      selected: true
    },
    {
      label: (
          <div className="flex-center-all gap-2"><img alt="" src={(prizePrecautionContent) ? CheckCircleIcon : DisabledCheckCircleIcon} /><div>로그인 설정</div></div>
      ),
      value: "login"
    }
  ]

  const detailStepRender = () => {
    if (loginType === "registration") {
      if (detailStep === "login") {
        return {
          settings: (
              <div className="flex-col gap-8">
                <div className="flex-align-center justify-between">
                  <div className="font-bold">1. 로그인 설정<span className="text-red-500">*</span></div>
                  <div className="bg-mainblue-l4 radius-6" style={{padding: "5px 14px"}}>
                    <div className="text-mainblue-0 font-12px underline pointer"
                         onClick={() => window.open(helpUrl)}>
                      제작 가이드 다운로드 (일러스트 파일)
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <label className="text-sm font-semibold relative flex items-center gap-2">
                      <CircleBadge label={1} />
                      배경 이미지
                      <span className="text-red-500">*</span>
                    </label>
                  </div>
                  <div className="flex gap-4">
                    <input
                        type="text"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
                        placeholder="업로드할 파일 선택"
                        maxLength={30}
                        readOnly
                        value={loginBackgroundImage}
                        required
                    />
                    <label
                        htmlFor={"login_background"}
                        className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
                    >
                      첨부
                    </label>
                    <input
                        type="file"
                        id={"login_background"}
                        className="hidden"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => onChangeFile(e, "login")}
                    />
                  </div>
                  <p className="text-gray-400 text-xs mt-4">
                    권장 사이즈 : 920 X 1700px / 지원파일 : JPG, PNG (최대 10MB)
                  </p>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <label className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                      <CircleBadge label={2} />
                      로그인 버튼 설정
                      <span className="text-red-500">*</span>
                    </label>
                  </div>
                  <div className="relative">
                    <input
                        type="text"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2 "
                        value={
                            loginButtonName ?? "로그인하고 룰렛 돌리기"
                        }
                        onChange={(e) => onChange("loginButtonName", e.target.value)}
                        maxLength={20}
                        placeholder="버튼명을 입력해 주세요."
                        required
                    />
                    <span className="absolute end-3 bottom-3 text-xs text-gray-400">
                {loginButtonName?.length || 0}/20자
              </span>
                  </div>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <label className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                      <CircleBadge label={3} />
                      안내 버튼명
                    </label>
                  </div>
                  <div className="relative">
                    <input
                        type="text"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2 "
                        value={loginPrecautionName || ""}
                        onChange={(e) =>
                            onChange("loginPrecautionName", e.target.value)
                        }
                        maxLength={20}
                        placeholder="안내 버튼명을 입력해 주세요."
                    />
                    <span className="absolute end-3 bottom-3 text-xs text-gray-400">
                {loginPrecautionName?.length || 0}/20자
              </span>
                  </div>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <label className="text-sm font-semibold text-gray-900 relative flex items-center">
                      안내 내용 설정
                    </label>
                  </div>
                  <p className="text-gray-500 text-sm mb-2">
                    고객이 알아야 할 행사 및 이벤트의 내용을 입력해 주세요.
                  </p>
                  <div className="relative">
              <textarea
                  className="bg-white border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2  resize-none text-xs whitespace-pre-line"
                  onChange={(e) =>
                      onChange("loginPrecautionContent", e.target.value)
                  }
                  rows={10}
                  placeholder="[예시]&#13;&#10;
                · 본 행사는 000 회원만 참여 가능하며 콘테스트 기간 2000.00.00 ~ 2000.00.00 내 참여한 회원에 한해 응모 및 당첨 가능합니다.
                기간 내 1인 당 하나의 ID만 참여 가능합니다.&#13;&#10;
                · 상기 상품 이미지는 이해를 돕기 위해 연출된 것으로 실제 상품과 다를 수 있습니다.&#13;&#10;
                · 본 행사 내용은 당사 사정에 따라 예고 없이 변경 또는 중단될 수 있습니다.&#13;&#10;
                · 행사 관련 문의 : 고객센터 0000-0000 또는 카카오톡플러스친구 ‘0000’으로 문의주세요."
                  value={loginPrecautionContent}
              ></textarea>
                  </div>
                </div>
              </div>
          ),
          preview: (
              <EventLoginPreview loginBackgroundImage={loginBackgroundImage}
                                 loginButtonName={loginButtonName}
                                 loginPrecautionContent={loginPrecautionContent}
                                 loginPrecautionName={loginPrecautionName}
              />
          )
        }
      } else if (detailStep === "roulette") {
        return {
          settings: (
              <div className="flex-col gap-8">
                <div className="flex-align-center justify-between">
                  <div className="font-bold">2. 룰렛 설정<span className="text-red-500">*</span></div>
                  <div className="bg-mainblue-l4 radius-6" style={{padding: "5px 14px"}}>
                    <div className="text-mainblue-0 font-12px underline pointer"
                         onClick={() => window.open(helpUrl)}>
                      제작 가이드 다운로드 (일러스트 파일)
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <label className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                      <CircleBadge label={1} />
                      배경 이미지
                      <span className="text-red-500">*</span>
                    </label>
                  </div>
                  <div className="flex gap-4">
                    <input
                        type="text"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
                        placeholder="업로드할 파일 선택"
                        maxLength={30}
                        readOnly
                        value={eventBackgroundImage}
                        required
                    />
                    <label
                        htmlFor="event_background"
                        className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
                    >
                      첨부
                    </label>
                    <input
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        id="event_background"
                        className="hidden"
                        onChange={(e) => onChangeFile(e, "event-background-image")}
                    />
                  </div>
                  <p className="text-gray-400 text-xs mt-4">
                    권장 사이즈 : 1000 X 1800px / 지원파일 : JPG, PNG (최대 10MB)
                  </p>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <label className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                      <CircleBadge label={2} />
                      룰렛 이미지
                      <span className="text-red-500">*</span>
                    </label>
                  </div>
                  <div className="flex gap-4">
                    <input
                        type="text"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
                        placeholder="업로드할 파일 선택"
                        maxLength={30}
                        readOnly
                        value={eventImage}
                        required
                    />
                    <label
                        htmlFor={"event_image"}
                        className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
                    >
                      첨부
                    </label>
                    <input
                        type="file"
                        id={"event_image"}
                        accept="image/jpg, image/jpeg, image/png"
                        className="hidden"
                        onChange={(e) => onChangeFile(e, "event-image")}
                    />
                  </div>
                  <p className="text-gray-400 text-xs mt-4">
                    권장 사이즈 : 580 X 580px / 지원파일 : PNG (최대 10MB)
                  </p>
                </div>
                <div className="flex-col gap-2">
                  <div className="flex items-center">
                    <label className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                      <CircleBadge label={3} />
                      룰렛 버튼
                    </label>
                  </div>
                  <div className="flex gap-4">
                    <input
                        type="text"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
                        placeholder="업로드할 파일 선택"
                        maxLength={30}
                        readOnly
                        value={eventButtonImage}
                        required
                    />
                    <label
                        htmlFor={"event_button"}
                        className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
                    >
                      첨부
                    </label>
                    <input
                        type="file"
                        id={"event_button"}
                        accept="image/jpg, image/jpeg, image/png"
                        className="hidden"
                        onChange={(e) => onChangeFile(e, "event-button")}
                    />
                  </div>
                  <p className="text-gray-400 text-xs">
                    권장 사이즈 : 172 X 172px / 지원파일 : PNG (최대 10MB)
                  </p>
                  <p className="text-xs">
                    * 별도의 룰렛 버튼을 업로드 하지 않을 시 기본 버튼으로 제공됩니다.
                  </p>
                </div>
              </div>
          ),
          preview: (
              <EventRoulettePreview eventBackgroundImage={eventBackgroundImage}
                                    eventImage={eventImage}
                                    eventButtonImage={eventButtonImage}
              />
          )
        }
      } else if (detailStep === "prize") {
        return {
          settings: (
              <div className="flex-col gap-8">
                <div className="flex-align-center justify-between">
                  <div className="font-bold">3. 당첨 안내 설정<span className="text-red-500">*</span></div>
                  <div className="bg-mainblue-l4 radius-6" style={{padding: "5px 14px"}}>
                    <div className="text-mainblue-0 font-12px underline pointer"
                         onClick={() => window.open(helpUrl)}>
                      제작 가이드 다운로드 (일러스트 파일)
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <label className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                      <CircleBadge label={1} />
                      <div>당첨 안내 문구</div>
                      <span className="text-red-500">*</span>
                    </label>
                  </div>
                  <p className="text-gray-500 text-sm mb-2">
                    당첨 혜택 사용에 필요한 문구를 설정해 주세요.
                  </p>
                  <div className="relative">
                    <textarea
                        className="bg-white border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2  resize-none text-xs whitespace-pre-line"
                        onChange={(e) =>
                            onChange("prizePrecautionContent", e.target.value)
                        }
                        maxLength={40}
                        rows={5}
                        value={prizePrecautionContent}
                    ></textarea>
                    <span className="absolute end-3 bottom-3 text-xs text-gray-400">
                      {prizePrecautionContent?.length || 0}/40자
                    </span>
                  </div>
                </div>
                <div>
                  <div className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                    추가 유도 설정
                  </div>
                  <div className="text-gray-500 text-sm mb-2">
                    추가 유도 버튼을 설정해 보세요. (최대 2개, 최소 0개)
                  </div>
                  <div className="flex-col gap-4">
                    {
                      additionalButtons.map((button, index) => {
                        return (
                            <div className="flex-col gap-2 bg-grey-0 rounded" style={{padding: "12px"}}>
                              <div className="flex justify-between">
                                <div>
                                  버튼
                                </div>
                                <div>
                                  <GrayButton className="px-1.5" onClick={() => {
                                    onChange("additionalButtons", additionalButtons.filter((button, buttonIndex) => buttonIndex !== index))
                                  }}>
                                    <FontAwesomeIcon icon={faXmark} />
                                  </GrayButton>
                                </div>
                              </div>
                              <div>
                                <TextInput value={button.name} onChange={(e) => {
                                  const changeButtons = [...additionalButtons]
                                  const currentButton = changeButtons.find((button, buttonIndex) => buttonIndex === index)
                                  currentButton.name = e.target.value
                                  onChange("additionalButtons", changeButtons)
                                }} maxLength={14} />
                              </div>
                              <div className="flex gap-4">
                                <div style={{width: "100%"}}>
                                  <TextInput value={button.url} onChange={(e) => {
                                    const changeUrl = [...additionalButtons]
                                    const currentButton = changeUrl.find((button, buttonIndex) => buttonIndex === index)
                                    currentButton.url = e.target.value
                                    onChange("additionalButtons", changeUrl)
                                  }} />
                                </div>
                                <Button style={{flexBasis: "30%"}} variants="default" value="URL 확인" onClick={() => window.open(button.url)} />
                              </div>
                            </div>
                        )
                      })
                    }
                    <div>
                      <Button variants="default" value="+ 버튼 추가" disabled={additionalButtons.length >= 2} onClick={() => {
                        if (additionalButtons.length <= 2) {
                          onChange("additionalButtons", [...additionalButtons, {
                            name: "",
                            url: ""
                          }])
                        }
                      }} />
                    </div>
                  </div>
                </div>
              </div>
          ),
          preview: (
              <EventPrizePreview eventBackgroundImage={eventBackgroundImage}
                                 eventImage={eventImage}
                                 eventButtonImage={eventButtonImage}
                                 prizePrecautionContent={prizePrecautionContent}
                                 additionalButtons={additionalButtons}
              />
          )
        }
      }
    } else if (loginType === "event") {
      if (detailStep === "roulette") {
        return {
          settings: (
              <div className="flex-col gap-8">
                <div className="flex-align-center justify-between">
                  <div className="font-bold">1. 룰렛 설정<span className="text-red-500">*</span></div>
                  <div className="bg-mainblue-l4 radius-6" style={{padding: "5px 14px"}}>
                    <div className="text-mainblue-0 font-12px underline pointer"
                         onClick={() => window.open(helpUrl)}>
                      제작 가이드 다운로드 (일러스트 파일)
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <label className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                      <CircleBadge label={1} />
                      배경 이미지
                      <span className="text-red-500">*</span>
                    </label>
                  </div>
                  <p className="text-gray-500 text-sm mb-2">
                    이벤트 배경 이미지를 설정해 주세요.
                  </p>
                  <div className="flex gap-4">
                    <input
                        type="text"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
                        placeholder="업로드할 파일 선택"
                        maxLength={30}
                        readOnly
                        value={eventBackgroundImage}
                        required
                    />
                    <label
                        htmlFor={"event_background"}
                        className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
                    >
                      첨부
                    </label>
                    <input
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        id={"event_background"}
                        className="hidden"
                        onChange={(e) => onChangeFile(e, "event-background-image")}
                    />
                  </div>
                  <p className="text-gray-400 text-xs mt-4">
                    권장 사이즈 : 1000 X 1800px / 지원파일 : JPG, PNG (최대 10MB)
                  </p>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <label className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                      <CircleBadge label={2} />
                      룰렛 이미지
                      <span className="text-red-500">*</span>
                    </label>
                  </div>
                  <p className="text-gray-500 text-sm mb-2">
                    룰렛 이미지를 설정해 주세요.
                  </p>
                  <div className="flex gap-4">
                    <input
                        type="text"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
                        placeholder="업로드할 파일 선택"
                        maxLength={30}
                        readOnly
                        value={eventImage}
                        required
                    />
                    <label
                        htmlFor={"event_image"}
                        className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
                    >
                      첨부
                    </label>
                    <input
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        id={"event_image"}
                        className="hidden"
                        onChange={(e) => onChangeFile(e, "event-image")}
                    />
                  </div>
                  <p className="text-gray-400 text-xs mt-4">
                    권장 사이즈 : 580 X 580px / 지원파일 : PNG (최대 10MB)
                  </p>
                </div>

                <div>
                  <div className="flex items-center mb-1">
                    <label className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                      <CircleBadge label={3} />
                      룰렛 버튼
                      <span className="text-red-500">*</span>
                    </label>
                  </div>
                  <p className="text-gray-500 text-sm mb-2">
                    룰렛 버튼 이미지를 설정해 주세요.
                  </p>
                  <div className="flex gap-4">
                    <input
                        type="text"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
                        placeholder="업로드할 파일 선택"
                        maxLength={30}
                        readOnly
                        value={eventButtonImage}
                        required
                    />
                    <label
                        htmlFor={"event_button"}
                        className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
                    >
                      첨부
                    </label>
                    <input
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        id={"event_button"}
                        className="hidden"
                        onChange={(e) => onChangeFile(e, "event-button")}
                    />
                  </div>
                  <p className="text-gray-400 text-xs mt-4">
                    권장 사이즈 : 172 X 172px / 지원파일 : PNG (최대 10MB)
                  </p>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <label className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                      <CircleBadge label={4} />
                      안내 버튼명
                    </label>
                  </div>
                  <div className="relative">
                    <input
                        type="text"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2 "
                        value={loginPrecautionName || ""}
                        onChange={(e) =>
                            onChange("loginPrecautionName", e.target.value)
                        }
                        maxLength={20}
                        placeholder="안내 버튼명을 입력해 주세요."
                    />
                    <span className="absolute end-3 bottom-3 text-xs text-gray-400">
                {loginPrecautionName?.length || 0}/20자
              </span>
                  </div>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <label className="text-sm font-semibold text-gray-900 relative flex items-center">
                      안내 내용 설정
                    </label>
                  </div>
                  <p className="text-gray-500 text-sm mb-2">
                    고객이 알아야 할 행사 및 이벤트의 내용을 입력해 주세요.
                  </p>
                  <div className="relative">
              <textarea
                  className="bg-white border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2  resize-none text-xs whitespace-pre-line"
                  onChange={(e) =>
                      onChange("loginPrecautionContent", e.target.value)
                  }
                  rows={10}
                  placeholder="[예시]&#13;&#10;
                · 본 행사는 000 회원만 참여 가능하며 콘테스트 기간 2000.00.00 ~ 2000.00.00 내 참여한 회원에 한해 응모 및 당첨 가능합니다.
                기간 내 1인 당 하나의 ID만 참여 가능합니다.&#13;&#10;
                · 상기 상품 이미지는 이해를 돕기 위해 연출된 것으로 실제 상품과 다를 수 있습니다.&#13;&#10;
                · 본 행사 내용은 당사 사정에 따라 예고 없이 변경 또는 중단될 수 있습니다.&#13;&#10;
                · 행사 관련 문의 : 고객센터 0000-0000 또는 카카오톡플러스친구 ‘0000’으로 문의주세요."
                  value={loginPrecautionContent}
              ></textarea>
                  </div>
                </div>
              </div>
          ),
          preview: (
              <EventLoginRoulettePreview eventImage={eventImage}
                                         eventButtonImage={eventButtonImage}
                                         eventBackgroundImage={eventBackgroundImage}
                                         loginButtonName={loginButtonName}
                                         loginPrecautionContent={loginPrecautionContent}
                                         loginPrecautionName={loginPrecautionName}
              />
          )
        }
      } else if (detailStep === "login") {
        return {
          settings: (
              <div className="flex-col gap-2">
                <div className="flex-align-center justify-between">
                  <div className="font-bold">2. 로그인 설정<span className="text-red-500">*</span></div>
                  <div className="bg-mainblue-l4 radius-6" style={{padding: "5px 14px"}}>
                    <div className="text-mainblue-0 font-12px underline pointer"
                         onClick={() => window.open(helpUrl)}>
                      제작 가이드 다운로드 (일러스트 파일)
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <label className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                    <CircleBadge label={1} />
                    <div>당첨 안내 문구</div>
                    <span className="text-red-500">*</span>
                  </label>
                </div>
                <p className="text-gray-500 text-sm mb-2">
                  당첨 혜택 사용에 필요한 문구를 설정해 주세요.
                </p>
                <textarea
                    className="bg-white border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2  resize-none text-xs whitespace-pre-line"
                    onChange={(e) =>
                        onChange("prizePrecautionContent", e.target.value)
                    }
                    rows={5}
                    value={prizePrecautionContent ?? "위 항목이 당첨되었습니다\n" +
                      "간단한 가입으로 혜택을 수령해보세요!"
                    }
                ></textarea>
                <div className="flex items-center">
                  <label className="text-sm font-semibold text-gray-900 relative flex items-center gap-2">
                    <CircleBadge label={2} />
                    로그인 버튼 설정
                    <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="relative">
                  <input
                      type="text"
                      className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2 "
                      value={
                        loginButtonName ?? "1초 회원가입하고 혜택 받기"
                      }
                      onChange={(e) => onChange("loginButtonName", e.target.value)}
                      maxLength={20}
                      placeholder="버튼명을 입력해 주세요."
                      required
                  />
                  <span className="absolute end-3 bottom-3 text-xs text-gray-400">
                {loginButtonName?.length || 0}/20자
              </span>
                </div>
              </div>
          ),
          preview: (
              <EventLoginPrizePreview eventBackgroundImage={event.eventBackgroundImage}
                                      eventImage={event.eventImage}
                                      eventButtonImage={event.eventButtonImage}
                                      loginButtonName={event.loginButtonName}
                                      prizePrecautionContent={event.prizePrecautionContent}
              />
          )
        }
      }
    }
  }

  return (
      <div>
        <div style={{paddingLeft: "34px"}}>
          <InputGroup
              label={"고객 화면 설정"}
              isRequired={true}
              className="py-8"
          >
            <RadioGroup className="flex items-center radio-flex-50" items={radioTypeItems}
                        onSelected={(value) => {
                          setLoginType(value)
                          onChangeLoginType(value)
                          setDetailStep(value === "registration" ? "login" : "roulette")
                        }}
                        initChecked={loginType}
            />
            <div className="flex text-grey-5">
              <div style={{flexBasis: "50%", marginLeft: "24px"}}>어뷰징을 방지할 수 있고, 회원가입한 대상만 룰렛을 돌릴 수 있어요.</div>
              <div>혜택 조회 이후 회원가입을 하기 때문에 어뷰징이 발생할 수 있어요.</div>
            </div>
          </InputGroup>
        </div>
        <Divider sx={{ mt: 1 }} />
        <div className="flex gap-8" style={{paddingLeft: "34px"}}>
          <div style={{flexBasis: "50%", paddingTop: "34px"}}>
            {
              detailStepRender().settings
            }
          </div>
          <div className="w-1/2">
            {
              notRequired ? (
                  <div className="flex-center-all bg-mainblue-l4 text-mainblue-0" style={{padding: "13px"}}>
                    모든 설정이 완료되었습니다
                  </div>
              ) : (
                  <div className="flex-center-all" style={{color: "var(--red-0)", padding: "13px", backgroundColor: "var(--red-l3)"}}>
                    모든 단계의 필수 설정이 완료되지 않았습니다
                  </div>
              )
            }
            {
              loginType === "registration"
                ? <TabBar key={loginBackgroundImage} variants="box" items={registrationTypeSwitchItems}
                          onChanged={(item) => {
                            setDetailStep(item.value)
                            if (item.value === "prize" && !prizePrecautionContent) {
                              onChange("prizePrecautionContent", "카카오톡으로 쿠폰 발송 완료!\n" +
                                  "결제 시 사용해 주세요.")
                            }
                          }} />
                  : <TabBar variants="box" items={eventTypeSwitchItems}
                            onChanged={(item) => {
                              setDetailStep(item.value)
                              if (item.value === "login" && !prizePrecautionContent) {
                                onChange("prizePrecautionContent", "위 항목이 당첨되었습니다\n" +
                                    "간단한 가입으로 혜택을 수령해보세요!")
                              }
                            }} />
            }
            <div className="bg-gray-50 flex-center-all flex-col" style={{paddingTop: "30px", paddingBottom: "20px"}}>
              {
                detailStepRender().preview
              }
              <div style={{color: "var(--red-0)", padding: "20px 60px"}}>
                <div>* 위 화면은 정확한 위치와 차이가 있어,</div>
                <div>프로젝트에서 진입점을 연결한 후 직접 확인해 보시길 바랍니다.</div>
                </div>
            </div>
          </div>
        </div>
      </div>
  )
};

export default EventScreen;
