import SettingsLayout from "../layout/SettingsLayout";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import SettingsStepLayout from "../layout/SettingsStepLayout";
import BenefitDefault from "../form/benefit/BenefitDefault";
import BenefitPrizeMessage from "../form/benefit/BenefitPrizeMessage";
import BenefitDetail from "../form/benefit/BenefitDetail";
import BenefitPhonePreview from "../../assets/images/default_benefit_image.svg"
import DeliveryBenefitPhonePreview from "../../assets/images/delivery_benefit_image.svg"
import CodeBenefitPhonePreview from "../../assets/images/code_benefit_image.svg"
import LinkBenefitPhonePreview from "../../assets/images/benefit_link_image.svg"
import {getBenefit, postBenefit} from "../../api/benefit/benefit.api";
import BenefitDefaultSettings from "../form/benefit/BenefitDefaultSettings";
import ArrowRightIcon from "../../assets/images/icon.arrow.right.svg";

const BenefitSettingPage = () => {
    const id = useSearchParams()[0].get("id")
    const [benefitData, setBenefitData] = useState({})

    let tabs = ["기본 설정", "고객 노출 설정", "기본 당첨 메시지"]

    if (benefitData.type !== "POINT") {
        tabs.push("혜택 상세 화면")
    }
    const [tab, setTab] = useState(1);

    useEffect(() => {
        if (id !== null) {
            getBenefit(id).then(res => {
                const benefitData = res.data
                if (benefitData) {
                    setBenefitData(benefitData)
                }
            })
        }
    }, [id])

    const saveBenefit = (status) => {
        benefitData.status = status
        benefitData.messageText = benefitData.messageText ?? getBenefitDetails().message
        postBenefit(benefitData).then(() => {
            alert("저장 되었습니다.")
            window.location.href = ("/benefit")
        })
    }

    const description = () => {
        if (tab === 1) {
          return "혜택의 기본 정보를 설정해 주세요."
        } else if (tab === 2) {
          return "기본 당첨 메시지를 설정해 주세요. 설정한 내용은 이벤트 마다 변경해서 사용할 수 있습니다."
        } else if (tab === 3) {
          return "혜택에 관해 꼭 알아야 하는 공통적인 내용을 설정해 주세요. 이벤트마다 내용 변경이 불가합니다."
        } else {
          return undefined
        }
    }

  const getBenefitDetails = () => {
        const benefitType = benefitData.type
        if (benefitType === "DELIVERY") {
          return {
              benefit: benefitData,
              image: DeliveryBenefitPhonePreview,
              label: "고객 정보 수집형",
              description: (
                  <div className="flex gap-2">
                    <div className="flex items-center gap-1">
                      <div className="text-tertiary">1</div>
                      <div>혜택 당첨 메시지 수령</div>
                    </div>
                      <img src={ArrowRightIcon} alt=">" />
                    <div className="flex items-center gap-1">
                        <div className="text-tertiary">2</div>
                      <div>고객이 수령 정보 입력</div>
                    </div>
                      <img src={ArrowRightIcon} alt=">" />
                    <div className="flex items-center gap-1">
                        <div className="text-tertiary">3</div>
                      <div>오모플레이 대시보드 내 수령 정보 조회</div>
                    </div>
                      <img src={ArrowRightIcon} alt=">" />
                    <div className="flex items-center gap-1">
                        <div className="text-tertiary">4</div>
                      <div>본사에서 직접 혜택 발송</div>
                    </div>
                  </div>
              ),
              buttonName: "수령 정보 입력",
              message: "✨(예시)이벤트 당첨을 축하합니다 ✨\n" +
                  "[혜택명]가 당첨 되셨어요! 🎉 \n\n" +
                  "▶ 당첨 상품은 택배로 배송해 드리며,  [수령 정보 입력 기한]까지 수령 정보를 입력하지 않으면 당첨이 취소됩니다.\n" +
                  "▶ 혜택 발송일자는 이벤트 기간 이후 1개월 이내 택배로 발송되며, 일정이 변경될 시 별도로 연락드릴 수 있습니다.\n" +
                  "▶ 혜택 이미지는 이해를 돕기위해 연출된 이미지로 실제 상품과는 다를 수 있습니다.",
              title: "(예시) 혜택 택배 배송 안내",
              precaution: "▶ 택배는 아이스박스에 포장되어 발송되며 상할 수 있으므로, 수령 후 최대 3일 이내 섭취해 주세요.\n" +
                  "▶ 상품의 맛은 랜덤 발송되며, 선택하실 수 없습니다.\n" +
                  "▶ 해외 주소로는 발송이 불가합니다.",
              content: "브랜드사에서 직접 발송되는 혜택으로, 고객과 약속한 일정에 혜택을 발송해야 합니다.",
              variableReg: /\[[이름|생일|주소|혜택명|수령 정보 입력 기한]+\]/g,
              variable: ["이름", "생일", "주소", "혜택명", "수령 정보 입력 기한"]
          }
        } else if (benefitType === "CODE") {
            return {
              benefit: benefitData,
              image: CodeBenefitPhonePreview,
              label: "혜택 코드형",
              description: (
                  <div className="flex gap-2">
                    <div className="flex items-center gap-1">
                        <div className="text-tertiary">1</div>
                      <div>혜택 당첨 메시지 수령</div>
                    </div>
                      <img src={ArrowRightIcon} alt=">" />
                    <div className="flex items-center gap-1">
                        <div className="text-tertiary">2</div>
                      <div>매장 방문</div>
                    </div>
                      <img src={ArrowRightIcon} alt=">" />
                    <div className="flex items-center gap-1">
                        <div className="text-tertiary">3</div>
                      <div>직원에게 혜택 코드 확인 후 사용</div>
                    </div>
                      <img src={ArrowRightIcon} alt=">" />
                    <div className="flex items-center gap-1">
                        <div className="text-tertiary">4</div>
                      <div>직원이 직접 사용 확인</div>
                    </div>
                  </div>
              ),
              buttonName: "당첨 코드 확인",
              message: "✨(예시)이벤트 당첨을 축하합니다 ✨\n" +
                  "[혜택명]에 당첨 되셨어요! 🎉\n" +
                  "직원에게 코드를 보여주세요.\n\n" +
                  "▶ 혜택 사용 기한은 [혜택 유효기간]까지 입니다.",
                title: "(예시) 사용 불가/가능 매장",
                precaution: "[서울] 여의도IFC몰점, 롯데백화점미아점, 이마트월계점, 대신증권점, 롯데몰은평점,상암월드컵점,현대백화점유플렉스신촌",
                content: "혜택 번호는 12자리 숫자로 자동으로 생성됩니다.",
                variableReg: /\[[이름|생일|주소|혜택명|혜택 유효기간]+\]/g,
                variable: ["이름", "생일", "주소", "혜택명", "혜택 유효기간"]
          }
        } else if (benefitType === "LINK") {
            return {
                benefit: benefitData,
                image: LinkBenefitPhonePreview,
                label: "링크 혜택형",
                description: (
                    <div className="flex gap-2">
                        <div className="flex items-center gap-1">
                            <div className="text-tertiary">1</div>
                            <div>혜택 당첨 메시지 수령</div>
                        </div>
                        <img src={ArrowRightIcon} alt=">" />
                        <div className="flex items-center gap-1">
                            <div className="text-tertiary">2</div>
                            <div>'혜택 받기' 버튼 클릭</div>
                        </div>
                        <img src={ArrowRightIcon} alt=">" />
                        <div className="flex items-center gap-1">
                            <div className="text-tertiary">3</div>
                            <div>해당 URL로 이동</div>
                        </div>
                    </div>
                ),
                buttonName: "혜택 받기",
                message: "",
                title: "(예시) 혜택 안내",
                precaution: "▶ 혜택은 회사 내부 사정에 따라 변동될 수 있습니다. ",
                content: "혜택 링크에 대한 유효성은 보장하지 않습니다.",
                variableReg: /\[[이름|생일|주소|혜택명|혜택 유효기간]+\]/g,
                variable: ["이름", "생일", "주소", "혜택명", "혜택 유효기간"]
            }
        } else if (benefitType === "POINT") {
            return {
                benefit: benefitData,
                image: BenefitPhonePreview,
                label: "포인트 지급형",
                description: (
                    <div className="flex gap-2">
                        <div className="flex items-center gap-1">
                            <div className="text-tertiary">1</div>
                            <div>포인트 지급 메시지 수령</div>
                        </div>
                        <img src={ArrowRightIcon} alt=">" />
                        <div className="flex items-center gap-1">
                            <div className="text-tertiary">2</div>
                            <div>'포인트 보러가기' 버튼 클릭</div>
                        </div>
                    </div>
                ),
                buttonName: "포인트 확인하기",
                message: "예시)이벤트 당첨을 축하합니다 \n" +
                    "[이름]님 [포인트]포인트가 당첨 되셨어요! \n" +
                    "\n" +
                    "▶ 아래 버튼을 통해 받은 포인트를 확인할 수 있습니다.",
                title: "(예시) 혜택 안내",
                precaution: "▶ 혜택은 회사 내부 사정에 따라 변동될 수 있습니다. ",
                content: "포인트 지급은 메시지 유무와 관계없이 별도로 이루어집니다.",
                variableReg: /\[이름|생일|주소|포인트]+\]/g,
                variable: ["이름", "생일", "주소", "이름", "포인트"]
            }
        } else {
            return {
                benefit: benefitData,
                image: BenefitPhonePreview,
                label: "직원 확인형",
                description: (
                    <div className="flex gap-2">
                        <div className="flex items-center gap-1">
                            <div className="text-tertiary">1</div>
                            <div>혜택 당첨 메시지 수령</div>
                        </div>
                        <img src={ArrowRightIcon} alt=">" />
                        <div className="flex items-center gap-1">
                            <div className="text-tertiary">2</div>
                            <div>매장 방문</div>
                        </div>
                        <img src={ArrowRightIcon} alt=">" />
                        <div className="flex items-center gap-1">
                            <div className="text-tertiary">3</div>
                            <div>직원 전달</div>
                        </div>
                        <img src={ArrowRightIcon} alt=">" />
                        <div className="flex items-center gap-1">
                            <div className="text-tertiary">4</div>
                            <div>직원이 직접 사용 확인</div>
                        </div>
                    </div>
                ),
                buttonName: "직원 확인하기",
                message: " ✨(예시)이벤트 당첨을 축하합니다 ✨\n\n" +
                    "[혜택명]에 당첨 되셨어요! 🎉\n" +
                    "직원에게 혜택을 보여주세요.\n\n" +
                    "▶ 혜택 사용 기한은 [혜택 유효기간]까지 입니다.",
                title: "(예시) 사용 불가/가능 매장",
                precaution: "[서울] 여의도IFC몰점, 롯데백화점미아점, 이마트월계점, 대신증권점, 롯데몰은평점,상암월드컵점,현대백화점유플렉스신촌",
                content: "매장에 직접 방문하여 사용하는 혜택으로, 직접 방문하여 사용이 가능한 혜택만 발급해야합니다.",
                variableReg: /\[[이름|생일|주소|혜택명|혜택 유효기간]+\]/g,
                variable: ["이름", "생일", "주소", "혜택명", "혜택 유효기간"]
            }
        }
    }

  const updateBenefit = (key, value) => {
      const newBenefit = { ...benefitData }
      newBenefit[key] = value
      setBenefitData(newBenefit)
  }

  const render = () => {
    if (tab === 1) {
      return (<BenefitDefault benefitDetails={getBenefitDetails}
                              disabledBenefitType={id !== null}
                              onChangeBenefitType={(type) => {
                                  const newBenefit = { ...benefitData }
                                  newBenefit["type"] = type
                                  setBenefitData(newBenefit)
                              }}
      />)
    } else if (tab === 2) {
        return (<BenefitDefaultSettings benefitDetails={getBenefitDetails}
                                onChangeBenefitName={(name) => updateBenefit("name", name)}
                                disabledBenefitType={id !== null}
                                onChangeBenefitType={(type) => {
                                    const newBenefit = { ...benefitData }
                                    newBenefit["type"] = type
                                    setBenefitData(newBenefit)
                                }}
                                onChangeBenefitRetailPrice={(retailPrice) => updateBenefit("retailPrice", retailPrice)}
                                onChangeBenefitSupplyPrice={(supplyPrice) => updateBenefit("supplyPrice", supplyPrice)}
        />)
    } else if (tab === 3) {
      return (
          <BenefitPrizeMessage benefitDetails={getBenefitDetails}
                               onImageChange={(imageUrl) => updateBenefit("messageImage", imageUrl)}
                               onMessageChange={(message) => updateBenefit("messageText", message)}
                               onChangeButtonName={(buttonName) => updateBenefit("buttonName", buttonName)}
          />
      )
    } else if (tab === 4) {
      return (
          <BenefitDetail benefitDetails={getBenefitDetails}
                         onBenefitImage={(imageUrl) => updateBenefit("prizeImage", imageUrl)}
                         onBenefitPrecautionTitle={(title) => updateBenefit("prizeCautionTitle", title)}
                         onBenefitLink={(linkUrl) => updateBenefit("linkUrl", linkUrl)}
                         onBenefitPrecautionMessage={(description) => updateBenefit("prizeCautionDescription", description)}
                         onShowAddress={(value) => updateBenefit("showAddress", value === "enabled")}
          />
      )
    } else {
      return <></>;
    }
  }

  function valid() {
        return (
            benefitData.name?.length > 0
            && benefitData.messageImage?.length > 0 && (benefitData.messageText === undefined ? true : benefitData.messageText.length > 0)
            && (benefitData.prizeImage?.length > 0 || benefitData.type === "POINT")
        )
  }

  return (
    <SettingsLayout
      title={"혜택 추가하기"}
      tabs={tabs}
      activeTab={tab}
      isConfirm={valid()}
      onChange={(value) => setTab(value)}
      onTemporarySave={() => {
          saveBenefit("TEMPORARY")
      }}
      onConfirm={() => {
          saveBenefit("ACTIVE")
      }}
      onClose={() => window.location.href = ("/benefit")}
    >
      <SettingsStepLayout description={description()} renderChild={render()} />
    </SettingsLayout>
  );
};
export default BenefitSettingPage;
