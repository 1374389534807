import {
  RadioGroup,
  TextField,
} from "@mui/material";
import React, {useRef} from "react";
import {fileUpload, kakaoImageUpload} from "../../../../api/file/file.api";
import ButtonsForm from "../../../form/message/ButtonsForm";
import VariableTextArea from "../../../form/VariableTextArea";
import {Button} from "omoplay";

const WideMessageSetting = ({ messageData, variables, regex, onChangeImage, onChangeImageLink, onChangeContents, onChangeButtons }) => {
    const image = messageData.image ?? {}
    const imageUrl = image.imgUrl
    const imageLink = image.imgLink
    const content = messageData.content
    const buttons = messageData.buttons

    const fileInputRef = useRef(null)

    const uploadImage = (e) => {
        kakaoImageUpload(e.target.files[0], "FW").then(({data}) => {
            if (data.code === "0") {
                onChangeImage("imgUrl", data.imageUrl);
                fileUpload(e.target.files[0]).then(({data}) => {
                    onChangeImage("originalUrl", data)
                })
            } else {
                alert(data.message);
            }
        })
    }

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

  return (
    <div className="flex-col gap-2">
        <div className="flex-col gap-1">
            <div className="font-semibold">메시지 꾸미기</div>
            <RadioGroup
                items={[
                    {
                        label: "이미지",
                        value: "image"
                    }
                ]}
                value={"image"}
            />
            <div style={{ display: "flex", gap: "8px" }}>
                <input
                    accept="image/jpg, image/jpeg, image/png"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    id="wide-image-file"
                    type="file"
                    onChange={(e) => uploadImage(e)}
                />
                <TextField
                    inputProps={{ readOnly: true }}
                    fullWidth
                    placeholder="이미지 파일을 업로드 해주세요."
                    size={"small"}
                    value={imageUrl}
                />
                <Button
                    variants="dark"
                    value="첨부"
                    onClick={handleButtonClick}
                    style={{ width: "105px", height: "40px" }}
                />
            </div>
            <div className="text-tertiary text-[12px]">* 가로 500px 이상, 권장: 800 x 600px / png, jpeg (최대 5MB)</div>
        </div>
        <div className="font-semibold">링크</div>
        <div className="flex gap-2">
            <TextField
                style={{ flex: 1 }}
                size={"small"}
                value={imageLink}
                placeholder="연결할 페이지의 URL을 입력해주세요."
                onChange={(e) => onChangeImageLink(e.target.value)}
            />
            <Button variants="dark" onClick={() => window.open(imageLink)} value="URL 확인" />
        </div>
      <div>
          <div className="font-semibold mb-2">내용</div>
          <VariableTextArea type={"FW"} message={content} variables={variables} regex={regex} maxLength={76} onChangeMessage={onChangeContents} />
      </div>
        <ButtonsForm maxLength={2} buttons={buttons} onChangeButtons={onChangeButtons} />
    </div>
  );
};

export default WideMessageSetting;
