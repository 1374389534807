import {Button, Pagination, Table, TextInput} from "omoplay";
import {useEffect, useState} from "react";
import {getTargetCustomers, testSend} from "../../api/message/volatility-message.api";
import {getUsers, getUsersAll} from "../../api/user/user.api";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import {getSpots} from "../../api/spot/spot.api";
import {MESSAGE_TARGET_FILTER} from "../../constants/menus";
import {getProjectList} from "../../api/project/project.api";

const TargetMessageForm = ({id, isTest, selectUserIds, onSelectedUserIds, onSendMessage}) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [users, setUsers] = useState({ kakaoUsers: [] });
    const [isSending, setSending] = useState(false)
    const [selectUsers, setSelectUsers] = useState([])
    const [spots, setSpots] = useState([]);
    const [innerSelectUserIds, setInnerSelectUserIds] = useState(selectUserIds)

    const requestTestSend = () => {
        setSending(true)
        testSend(innerSelectUserIds, id)
            .then(() => {
                alert("발송 되었습니다.")
                onSendMessage()
            })
    }

    const headers = [
        { name: "이름", align: "center", width: "120px" },
        { name: "성별", align: "center", width: "80px" },
        { name: "연령대(생일)", align: "center", width: "130px" },
        { name: "전화번호", width: "160px" },
        { name: "회원가입" },
        { name: "", width: "120px" }
    ];

    const getSpotName = (spotId) => {
        if (spotId != null) {
            const findIndex = spots.findIndex((spot) => spot.spotId === spotId);
            if (findIndex > -1) {
                return spots[findIndex].isDeleted ? (
                    spots[findIndex].name
                ) : (
                    <Link
                        to={`/spot?spotId=${spotId}`}
                        className="text-indigo-500 hover:underline"
                    >
                        {spots[findIndex].name}
                    </Link>
                );
            }
        }
        return "";
    };

    const displayGenderText = (gender) => {
        if (gender === "male") {
            return "남성";
        } else if (gender === "female") {
            return "여성";
        } else {
            return gender;
        }
    };

    useEffect(() => {
        getSpots().then(({data}) => {
            const spots = data.content
            const spotFilterIndex = MESSAGE_TARGET_FILTER.findIndex(
                (item) => item.value === "spot"
            );
            MESSAGE_TARGET_FILTER[spotFilterIndex].data = spots.map((spot) => {
                return { value: spot.spotId, label: spot.name };
            });
            setSpots(data.content)
        })
        getProjectList().then(({data}) => {
            const projectFilterIndex = MESSAGE_TARGET_FILTER.findIndex(
                (item) => item.value === "project"
            );
            MESSAGE_TARGET_FILTER[projectFilterIndex].data = data.map((project) => {
                return { value: project.id, label: project.name };
            });
        })
    }, []);

    useEffect(() => {
        if (id != null && innerSelectUserIds.length === 0) {
            getTargetCustomers(id).then(({data}) => {
                onSelectedUserIds(data)
            })
        }
    }, []);

    useEffect(() => {
        getUsers(search, null, null, true, page - 1, 4, "createdAt,desc").then((res) => {
            setUsers(res.data)
        })
    }, [page, search]);

    useEffect(() => {
        getUsersAll(innerSelectUserIds).then(({data}) => {
            setSelectUsers(data.map(user => {
                return [
                    <div className="text-center">{user.name}</div>,
                    <div className="text-center">{displayGenderText(user.gender)}</div>,
                    <div className="text-center">
                        <div>{user.ageRange}</div>
                        <div>({user.birthday
                            ? user.birthday.slice(0, 2) +
                            "." +
                            user.birthday.slice(2, 4)
                            : ""})
                        </div>
                    </div>,
                    user.phoneNumber,
                    <div className="flex-col">
                        <div>{getSpotName(user.spotId)}</div>
                        <div>
                            {moment(user.createdAt)
                                .add(9, "hours")
                                .format("YYYY.MM.DD HH:mm")}
                        </div>
                    </div>,
                    <div>
                        <Button variants="danger" value="삭제" onClick={() => {
                            onSelectedUserIds(innerSelectUserIds.filter((value) => value !== user.id))
                            setInnerSelectUserIds(innerSelectUserIds.filter((value) => value !== user.id))
                        }} />
                    </div>
                ]
            }))
        })
    }, [innerSelectUserIds]);

    const tableData = users.kakaoUsers.map(user => {
        return [
            <div className="text-center">{user.name}</div>,
            <div className="text-center">{displayGenderText(user.gender)}</div>,
            <div className="text-center">
                <div>{user.ageRange}</div>
                <div>({user.birthday
                    ? user.birthday.slice(0, 2) +
                    "." +
                    user.birthday.slice(2, 4)
                    : ""})
                </div>
            </div>,
            user.phoneNumber,
            <div className="flex-col">
                <div>{getSpotName(user.spotId)}</div>
                <div>
                    {moment(user.createdAt)
                        .add(9, "hours")
                        .format("YYYY.MM.DD HH:mm")}
                </div>
            </div>,
            <div>
                <Button variants="outlined" value="선택" onClick={() => {
                    onSelectedUserIds([user.id, ...innerSelectUserIds])
                    setInnerSelectUserIds([user.id, ...innerSelectUserIds])
                }} />
            </div>
        ]
    })

    return (
        <div>
            <div>
                <div className="font-semibold" style={{marginBottom: "16px", marginTop: "16px"}}>전화번호 검색하기</div>
                <div style={{width: "300px", marginBottom: "16px"}}>
                    <TextInput showIcon={true} placeholder="전화번호 검색"
                               onKeyDown={(e) => {
                                   if (e.keyCode === 13) {
                                       setPage(1)
                                       setSearch(e.currentTarget.value)
                                   }
                               }} />
                </div>
                <Table headers={headers} data={tableData} />
                <div className="flex justify-center" style={{marginTop: "24px"}}>
                    <Pagination rowSize={4} totalCount={users.totalCount} currentPage={page} onPaging={(page) => setPage(page)} />
                </div>
            </div>
            <div>
                <div className="font-semibold" style={{marginBottom: "16px", marginTop: "16px"}}>발송 대상</div>
                <div>
                    <Table headers={headers} data={selectUsers} pagination={{
                        rowSize: 4,
                        totalSize: selectUsers.length
                    }} />
                </div>
            </div>
            {
                isTest && (
                    <>
                        <div className="flex justify-center">
                            <div className="text-center">
                                <div style={{fontSize: "12px"}}>*선택된 대상이 메시지 차단 등으로 발송이 불가할 수 있습니다.</div>
                                <div style={{fontSize: "12px"}}>테스트 발송에도 과금이 발생합니다. (발송 불가인 경우 과금되지 않습니다.)</div>
                            </div>
                        </div>
                        <div className="flex-center-all">
                            <div className="flex-center-all" style={{marginBottom: "16px", marginTop: "8px", width: "300px"}}>
                                <Button variants="primary" value="테스트 발송하기" onClick={() => {
                                    requestTestSend()
                                }} disabled={isSending}
                                />
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default TargetMessageForm