import HighlightWithinTextarea from "react-highlight-within-textarea";
import NormalButton from "../NormalButton";
import {useState} from "react";


const VariableTextArea = ({message, regex, variables, maxLength, onChangeMessage}) => {
    const [isVariablePopup, setVariablePopup] = useState(false)


    const addVariable = (text) => {
        const variable = message + `[${text}]`
        onChangeMessage(variable)
        setVariablePopup(false);
    }

    return (
        <div>
            <div className="block w-full p-2 pb-6 pr-8 text-xs text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-indigo-500 focus:border-indigo-500 relative">
                <HighlightWithinTextarea
                    placeholder=""
                    value={message ?? ""}
                    onChange={(value) => {
                        let text = value
                        if (value.length > maxLength) {
                            text = value.substring(0, maxLength)
                        }
                        onChangeMessage(text)
                    }}
                    highlight={[
                        {
                            highlight: regex,
                            className: "variable_highlight",
                        },
                    ]}
                    className="max-w-[340px]"
                />
                <span className="absolute end-3 bottom-2 text-xs text-gray-400">
                            {message?.length || 0}/{maxLength}자
                </span>
            </div>
            <div className="flex justify-end relative pt-2">
                <NormalButton
                    className="whitespace-nowrap px-4 py-2 text-sm"
                    onClick={() => setVariablePopup(!isVariablePopup)}
                >
                    + 변수 설정
                </NormalButton>
                {isVariablePopup && (
                    <ul className="absolute bottom-12 right-0 bg-white rounded-lg text-sm shadow-lg border border-gray-200 text-gray-600 ">
                        {
                            variables.map(variable => {
                                return (
                                    <li
                                        className="hover:bg-gray-50 cursor-pointer py-3 px-6 rounded-t-lg"
                                        onClick={() => addVariable(variable)}
                                    >
                                        {variable}
                                    </li>
                                )
                            })
                        }
                    </ul>
                )}
            </div>
        </div>
    )
}

export default VariableTextArea