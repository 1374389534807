import { Box, Typography, Button } from "@mui/material";
const WideTypeContent = ({ data }) => {
    const image = data.image ?? {}
  return (
    <Box sx={{ width: "262px" }} className="message-content">
      <Box
        className="preview-image-wrap clickable"
        sx={{
          width: "100%",
          height: "200px",
          backgroundImage: `url(${image.imgUrl})`,
        }}
        onClick={() => window.open(image.imgLink)}
      ></Box>
      <Box className="default-message-content">
          <Typography sx={{ whiteSpace: "break-spaces" }} fontSize={12}>
              {data.content ?? "내용"}
          </Typography>
          <Box className="flex-center-all" style={{padding: "8px", gap: "5px"}}>
          {data.buttons?.filter((button) => button.type === "WL" || button.type === "COUPON")
                  .map((button, index) => {
                      return (
                          <Button
                              className="preview-button"
                              variant="outlined"
                              color={"grey7"}
                              onClick={() => window.open(button.link)}
                          >
                              {button.name || "메시지 버튼"}
                          </Button>
                      );
                  })}
          </Box>
      </Box>
    </Box>
  );
};

export default WideTypeContent;
