import OMOPlayLogo from "../../assets/images/omoplay_logo_black.svg";
import {Button, Stepper} from "omoplay";
import {useState} from "react";
import OMOSettingsLayout from "../layout/OMOSettingsLayout";
import {postAgentRegistration} from "../../api/account/account.api";
import CardBillingForm from "../form/CardBillingForm";

const OMOAgentSettingPage = () => {
    const [existsCardInfo, setExistsCardInfo] = useState(false)

    return (
        <OMOSettingsLayout title="오모 Agent 신청">
                <div className="items-center">
                    <div className="border bg-white p-[32px]" style={{width: "720px", borderRadius: "16px"}}>
                        <div className="mb-10">
                            <div className="mb-8" style={{width: "200px"}}>
                                <img alt="" src={OMOPlayLogo} />
                            </div>
                        </div>
                        <div>
                            <Stepper
                                listLabel={[]}
                                totalStep={1}
                                stepActive={1}
                            />
                        <div>
                            <div>
                                <div className="text-gray-900 font-semibold text-lg mb-2">
                                    결제 수단을 입력 해주세요
                                </div>
                                <CardBillingForm onSelectedCard={() => setExistsCardInfo(true)} />
                            </div>
                        </div>
                        <div className="flex justify-end gap-2 mt-[12px]">
                            <Button variants="primary" value="완료" disabled={!existsCardInfo}
                                    onClick={() => postAgentRegistration().then(() => {
                                        alert("신청이 완료 되었습니다.")
                                        window.location.href = "/services"
                                    })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </OMOSettingsLayout>
    );
};
export default OMOAgentSettingPage;
