import GrayButton from "../../GrayButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

export const ProjectDuplicateLink = ({
    projectName,
    onCancel
}) => {
    return (
        <div className="event-link" style={{padding: "12px"}}>
            <div className="flex justify-between">
                <div>
                    {projectName}
                </div>
                <div>
                    <GrayButton className="px-1.5" onClick={() => onCancel()}>
                        <FontAwesomeIcon icon={faXmark} />
                    </GrayButton>
                </div>
            </div>
        </div>
    )
}

export default ProjectDuplicateLink
