import OMOSettingsLayout from "../layout/OMOSettingsLayout";
import OMOPlayLogo from "../../assets/images/omoplay_logo_black.svg";
import KakaoLogo from "../../assets/images/kakao_channel_logo.svg";
import LineLogo from "../../assets/images/line_logo.svg";
import {Button, RadioGroup, TextInput} from "omoplay";
import KakaoAuthorization from "../form/message/KakaoAuthorization";
import {useState} from "react";
import ValidCheckForm from "../form/ValidCheckForm";
import GuideIcon from "../../assets/images/guide_icon.svg";
import {postChannelRegistration} from "../../api/account/account.api";

const OMOAgentSettingPage = () => {
    const [channelName, setChannelName] = useState("")
    const [publicId, setPublicId] = useState("")
    const [channelDescription, setChannelDescription] = useState("")
    const [validBusinessSetting, setValidBusinessSetting] = useState(false)
    const [validChannelAuth, setValidChannelAuth] = useState(false)
    const [checkInviteAccount, setCheckInviteAccount] = useState(false)

    return (
        <OMOSettingsLayout title="채널 추가">
            <div>
                <div className="items-center">
                    <div className="flex-col gap-[32px] border bg-white p-[32px]" style={{width: "720px", borderRadius: "16px"}}>
                        <div>
                            <div className="w-[200px]">
                                <img alt="" src={OMOPlayLogo} />
                            </div>
                        </div>
                        <div className="text-[28px] font-bold">
                            환영합니다! 지금 채널을 추가하고<br/> 최고의 CRM 파이프라인을 구축하세요
                        </div>
                        <div className="flex-col gap-1 mb-[12px]">
                            <div className="font-medium">채널 구분<span className="ml-1 text-red-500">*</span></div>
                            <div>
                                <RadioGroup items={[
                                    {
                                        label: (
                                            <div>
                                                <div className="flex gap-1">
                                                    <div><img src={KakaoLogo} alt="kakao" /></div>
                                                    <div className="font-medium">카카오 채널</div>
                                                </div>
                                                <div className="text-tertiary">카카오톡을 이용한 자사몰과의 연동</div>
                                            </div>
                                        ),
                                        value: "kakao"
                                    },
                                    {
                                        label: (
                                            <div>
                                                <div className="flex gap-1">
                                                    <div><img src={LineLogo} alt="line" /></div>
                                                    <div className="font-medium">라인 채널 (준비중)</div>
                                                </div>
                                                <div className="text-tertiary">라인을 이용한 자사몰과의 연동</div>
                                            </div>
                                        ),
                                        value: "line",
                                        disabled: true
                                    }
                                ]} onSelected={() => {}} />
                            </div>
                        </div>
                        <ValidCheckForm index={1} title="채널 정보를 입력하세요"
                                        isOpen={true}
                                        onChecked={(checked) => setValidBusinessSetting(checked)}
                        >
                            <div className="flex-col gap-2 p-[16px]">
                                <div className="flex-col gap-1">
                                    <div className="font-medium">채널 이름<span className="ml-1 text-red-500">*</span></div>
                                    <div>
                                        <TextInput placeholder="채널 이름을 입력해 주세요."
                                                   onChange={(e) =>
                                                       setChannelName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="flex-col gap-1">
                                    <div className="font-medium">검색용 ID<span className="ml-1 text-red-500">*</span> ex) @omoplay</div>
                                    <div>
                                        <TextInput placeholder="채널에 붙어있는 @아이디를 입력해 주세요."
                                                   onChange={(e) =>
                                                       setPublicId(e.target.value)} />
                                    </div>
                                </div>
                                <div className="flex-col gap-1">
                                    <div className="font-medium">채널 설명</div>
                                    <div>
                                        <TextInput placeholder="채널에 대한 설명을 입력하세요"
                                                   onChange={(e) =>
                                                       setChannelDescription(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </ValidCheckForm>
                        <ValidCheckForm index={2} title="채널 인증을 해주세요"
                                        disabledCheckBox={true}
                        >
                            <div className="flex-col gap-2 p-[16px]">
                                <KakaoAuthorization channelName={publicId} onSubmit={() => setValidChannelAuth(true)} />
                            </div>
                        </ValidCheckForm>
                        <ValidCheckForm index={3} title="채널 관리자를 초대해 주세요"
                                        onChecked={(checked) => setCheckInviteAccount(checked)}
                        >
                            <div className="flex-col gap-2 p-[16px]">
                                <div className="text-center w-full p-[16px]">
                                    <div className="flex-cfont-semibold">
                                        <div>omoplay@kakao.com 계정을</div>
                                        <div>
                                            <span className="text-primary">카카오 비즈니스</span>
                                            {" "}관리자로 초대해 주세요.
                                        </div>
                                    </div>
                                </div>
                                <Button variants="outlined" value={
                                    <div className="flex justify-center items-center">
                                        <img alt="" src={GuideIcon} width="16px" height="16px" />
                                        <div className="ml-2">신청 가이드 - 카카오 비즈니스에서 채널에 오모플레이 계정 초대하기</div>
                                    </div>
                                } onClick={() => {
                                    window.open("https://local-room-f00.notion.site/1132d9e7196a81d39490fed719c110f7?pvs=4")
                                }} />
                            </div>
                        </ValidCheckForm>
                        <div className="flex justify-end">
                            <Button variants="primary" size="large" value="채널 추가 완료"
                                    disabled={!validBusinessSetting || !validChannelAuth || !checkInviteAccount}
                                    onClick={() => postChannelRegistration(channelName, publicId, channelDescription)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </OMOSettingsLayout>
    );
};
export default OMOAgentSettingPage;
