import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEquals,
  faWonSign,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import moment from "moment";
import { range } from "../../libs/helper";
import SuspensePopup from "../SuspensePopup";
import {Button, CalendarBar, RadioGroup} from "omoplay";
import InputGroup from "../form/InputGroup";
import {getCountAllFriend} from "../../api/moment/moment.api";
import TargetMessageForm from "../volatility-message/TargetMessageForm";

export const AIAgentMessageTargetSetting = ({id, reservedDate, messageData,
                                       onChangeReservedDate, onChangeAdult, onChangeCountFriend, selectUserIds}) => {
  const [testMessagePopup, setTestMessagePopup] = useState(false)
  const [countFriend, setCountFriend] = useState(0)

  useEffect(() => {
    getCountAllFriend().then(({data}) => {
      setCountFriend(data.populationScore)
      onChangeCountFriend(data.populationScore)
    })
  }, [])


  const handleReserveDateChange = (type, value) => {
    const updateValue = { ...reservedDate }
    if (type === "date") {
      value.startDate = moment(value).format("YYYY-MM-DD")
      value.endDate = moment(value).format("YYYY-MM-DD")
    }
    updateValue[type] = value;
    onChangeReservedDate(updateValue)
  }


  return (
      <div className="flex-col gap-2">
        <InputGroup label="발송 대상 확인" description={(
            <div className="flex-align-center justify-between">
              <div>메시지 발송 대상을 확인 합니다.</div>
              <Button variants="outlined" value="테스트 발송" onClick={() => setTestMessagePopup(true)} />
            </div>)}
        >
          <div className="flex-col gap-4">
            <div className="flex-col gap-4">
              <div className="flex-col">
                <div className="flex-col gap-2 mt-[20px]">
                  <div className="bg-gray-100 p-4 flex-col">
                    <div className="flex items-center gap-4">
                      <div className="rounded border border-gray-300 text-center w-[286px]">
                        <div className="text-gray-400 py-1.5 text-sm border-b border-gray-300">
                          예상 고객 수 (발송 건 수)
                        </div>
                        <div className="bg-white py-2 font-semibold text-center">
                          {countFriend.toLocaleString()}명 (건)
                        </div>
                      </div>
                      <div className="w-6 h-6 bg-white rounded flex justify-center items-center text-gray-500">
                        <FontAwesomeIcon icon={faXmark} />
                      </div>
                      <div className="rounded border border-gray-300 text-center w-[118px]">
                        <div className="text-gray-400 py-1.5 text-sm border-b border-gray-300">
                          메시지 단가
                        </div>
                        <div className="py-2 font-semibold">
                          16.5원
                        </div>
                      </div>
                      <div className="w-6 h-6 bg-white rounded flex justify-center items-center text-gray-500">
                        <FontAwesomeIcon icon={faEquals} />
                      </div>
                      <div className="rounded border border-gray-300 text-center w-[326px]">
                        <div className="text-gray-400 py-1.5 text-sm border-b border-gray-300">
                          총 예상 발송 비용
                        </div>
                        <div className="bg-white py-2 font-semibold flex justify-center gap-2 text-indigo-600">
                          <>
                            <div className="bg-indigo-100 w-6 h-6 flex justify-center items-center  rounded">
                              <FontAwesomeIcon icon={faWonSign} size={"xs"} />
                            </div>
                            {(countFriend * 16.5).toLocaleString()}원
                          </>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className="text-gray-500 text-sm my-4 text-[12px]">
                        * ‘예상 고객 수’는 발송을 시도하는 고객 수를 의미하고, 실제 발송되는 고객 수와는 차이가 있을 수 있습니다.
                      </p>
                      <p className="text-gray-500 text-sm my-4 text-[12px]">
                        * 메시지 발송 등록 시 과금된 비용과 실발송 후의 차액은 발생할 수 있으며, 발송되지 않은 발송 건은 청구되지 않습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </InputGroup>
        <InputGroup label={"연령 인증 메시지"}>
          <div className="flex-col justify-center gap-2">
            <RadioGroup
                items={[
                  {
                    label: "선택안함",
                    value: "N"
                  },
                  {
                    label: "선택함",
                    value: "Y"
                  }
                ]}
                initChecked={messageData.adult ?? "N"}
                value={messageData.adult}
                onSelected={(value) => {
                  onChangeAdult(value)
                }}
            />
            <div>
              <div className="font-12px">연령인증 전까지 채팅방 메시지에 커버가 씌워지며, 20세 이상 성인만 내용을 확인할 수 있습니다.</div>
              <div className="font-12px">20세 이상에게 적합한 내용을 담고 있다면 필수 선택해주세요.</div>
              <div className="font-12px">예: 주류, 청소년 이용불가 게임 및 영화 등</div>
            </div>
          </div>
        </InputGroup>
        <InputGroup label="발송 날짜/시간 설정">
          <div className="flex gap-2 my-2 items-center">
            <div className="flex border-gray-200 border rounded">
              <CalendarBar minDate={new Date()} multiple={false} initDate={{
                startDate: moment(reservedDate.date.startDate).toDate(),
                endDate: moment(reservedDate.date.endDate).toDate()
              }} onConfirm={(dateProps) => {
                handleReserveDateChange("date", dateProps.startDate);
              }} />
            </div>
            <select
                className="w-100 bg-white border border-gray-200 text-gray-900 rounded focus:ring-indigo-500 focus:border-indigo-500 block p-2 h-[45px]"
                value={reservedDate.hour}
                onChange={(e) =>
                    handleReserveDateChange("hour", e.target.value)
                }
            >
              {range(13, 8).map((time) => {
                return (
                    <option value={String(time).padStart(2, "0")}>
                      {String(time).padStart(2, "0")}시
                    </option>
                );
              })}
            </select>
            <select
                className="w-100 bg-white border border-gray-200 text-gray-900 rounded focus:ring-indigo-500 focus:border-indigo-500 block p-2 h-[45px]"
                value={reservedDate.min}
                onChange={(e) => {
                  handleReserveDateChange("min", e.target.value)
                }}
            >
              {Array.from({ length: 12 }, (_, i) => i * 5).map((time) => {
                if (reservedDate.hour === "20" && time > 55) {
                  return
                }
                return (
                    <option value={String(time).padStart(2, "0")}>
                      {String(time).padStart(2, "0")}분
                    </option>
                );
              })}
            </select>
          </div>
          <div className="w-full rounded px-4 py-0.5">
            <p className="text-[12px] my-2">
              * 20:55부터 08:00까지는 메시지 수신이 불가하여 <span className="text-[12px] text-primary">익일 오전 08:00 이후에 메시지를 받게 됩니다.</span>
            </p>
          </div>
        </InputGroup>
        <SuspensePopup
            title={"테스트 발송하기"}
            visible={testMessagePopup}
            style={{width: "1000px"}}
            onClose={() => setTestMessagePopup(false)}
        >
          <TargetMessageForm id={id}
                             isTest={true}
                             onSelectedUserIds={() => {}}
                             selectUserIds={selectUserIds ?? []}
                             onSendMessage={() => setTestMessagePopup(false)}

          />
        </SuspensePopup>
      </div>
  );
};

export default AIAgentMessageTargetSetting;
