import { API_URL, getMessageType } from "../../constants/axios";
import { fetchApi, message_instance } from "../fetch";

export const fileUpload = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await fetchApi.multipart_post(API_URL.FILE_UPLOAD, formData);
  if (res.status === 200 && res.data.code === 2000) {
    return res.data;
  } else if (res.data.code === 4080) {
    alert("파일 업로드 용량이 초과하였습니다.");
  }
  return {};
};

export const messageFileUpload = (
  messageType,
  file,
  successCallback = () => {},
  catchCallback = () => {}
) => {
  const formData = new FormData();
  const convertType = getMessageType(messageType, { image: true });
  formData.append("bizType", convertType === "FT" ? "FI" : convertType);
  formData.append("image", file);
  message_instance
    .post(API_URL.MESSAGE_FILE_UPLOAD, formData, [
      {
        header: {
          Accept: "application/json",
          "Content-Type": `multipart/form-data`,
        },
        transformRequest: [
          function () {
            return formData;
          },
        ],
      },
    ])
    .then(successCallback)
    .catch((error) => {
      catchCallback(error);
    });
}

export const kakaoImageUpload = async (
    file,
    messageType = "FI"
) => {
  const formData = new FormData();
  formData.append("messageType", messageType);
  formData.append("file", file)

  const res = await fetchApi.multipart_post(API_URL.FILE_KAKAO_UPLOAD, formData)

  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};
