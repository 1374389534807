import {Button, CalendarBar, RadioGroup, TextInput} from "omoplay";
import SuspensePopup from "../../SuspensePopup";
import React, {useEffect, useState} from "react";
import BenefitForm from "../benefit/BenefitForm";
import {getBenefit} from "../../../api/benefit/benefit.api";
import moment from "moment";


const ButtonsForm = ({maxLength, buttons, onChangeButtons}) => {
    const [openBenefitPopup, setOpenBenefitPopup] = useState(null)
    const [benefitName, setBenefitName] = useState("")


    useEffect(() => {
        const benefitId = buttons?.find(button => button.type === "COUPON")?.benefitId
        if (benefitId !== undefined && !isNaN(Number(benefitId))) {
            getBenefit(benefitId).then(({data}) => {
                setBenefitName(data.name)
            })
        }
    }, [buttons])

    const openUrl = (url) => {
        window.open(url)
    }

    return (
        <>
            {Array.from({ length: maxLength }, (_, index) => {
                const button = (buttons ?? [])[index] ?? {}
                const type = button.type ?? ""

                return (
                    <div className="flex-col gap-2">
                        <div className="font-semibold">버튼 {index + 1}</div>
                        {
                            type !== undefined ? (
                                <RadioGroup
                                    key={type}
                                    items={[
                                        {
                                            label: "없음",
                                            value: "",
                                        },
                                        {
                                            label: "링크",
                                            value: "WL"
                                        },
                                        {
                                            label: "쿠폰",
                                            value: "COUPON"
                                        }
                                    ]}
                                    initChecked={type}
                                    value={type}
                                    onSelected={(value) => onChangeButtons(index, "type", value)}
                                />
                            ) : <></>
                        }
                        {type === "WL" && (
                            <div className="flex-col gap-2">
                                <TextInput
                                    placeholder="버튼명을 입력해 주세요."
                                    maxLength={8}
                                    value={button.name ?? ""}
                                    onChange={(e) => onChangeButtons(index, "name", e.target.value)}
                                />
                                <div className="flex gap-2">
                                    <div style={{width: "100%", flex: "1"}}>
                                        <TextInput
                                            placeholder="연결할 페이지의 URL을 입력해주세요."
                                            value={button.urlMobile ?? ""}
                                            onChange={(e) =>
                                                onChangeButtons(index, "urlMobile", e.target.value)
                                            }
                                        />
                                    </div>
                                    <Button
                                        variants="dark"
                                        onClick={() => openUrl(button.urlMobile)}
                                        value="URL 확인"
                                    />
                                </div>
                                <div>PC 전용 링크</div>
                                <div className="flex gap-2">
                                    <div style={{width: "100%", flex: "1"}}>
                                        <TextInput
                                            placeholder="연결할 페이지의 URL을 입력해주세요."
                                            value={button.urlPc ?? ""}
                                            onChange={(e) =>
                                                onChangeButtons(index, "urlPc", e.target.value)
                                            }
                                        />
                                    </div>
                                    <Button
                                        variants="dark"
                                        onClick={() => openUrl(button.urlPc)}
                                        value="URL 확인"
                                    />
                                </div>
                            </div>
                        )}
                        {button?.type === "COUPON" && (
                            <div className="flex-col gap-2">
                                <TextInput
                                    placeholder="혜택명을 입력해 주세요."
                                    maxLength={8}
                                    value={button.name ?? ""}
                                    onChange={(e) => onChangeButtons(index, "name", e.target.value)}
                                />
                                <div className="flex gap-2">
                                    <div style={{width: "100%", flex: "1"}}>
                                        <TextInput
                                            placeholder="연결할 혜택을 선택해주세요"
                                            value={benefitName}
                                            disabled={true}
                                            readOnly
                                        />
                                    </div>
                                    <Button
                                        variants="default"
                                        value="혜택 연결하기"
                                        onClick={() => setOpenBenefitPopup(index)}
                                     />
                                </div>
                                <div className="flex-align-center gap-4">
                                    <div className="font-16px">혜택 만료일</div>
                                    <CalendarBar multiple={false}
                                                 initDate={{
                                                     startDate: moment(button.benefitExpiredDate ?? new Date(), "YYYY-MM-DD").add(1, "day").toDate(),
                                                     endDate: moment(button.benefitExpiredDate ?? new Date(), "YYYY-MM-DD").add(1, "day").toDate()
                                                 }}
                                                 minDate={new Date()}
                                                 onConfirm={(dateProps) => {
                                                     onChangeButtons(index, "benefitExpiredDate", moment(dateProps.startDate).format("YYYY-MM-DD"))
                                                 }} />
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
            <SuspensePopup
                title={"혜택 연결하기"}
                style={{width: "960px"}}
                visible={openBenefitPopup !== null}
                onClose={() => setOpenBenefitPopup(null)}
            >
                <BenefitForm
                    onSelectedBenefit={(benefitId, benefitName) => {
                        onChangeButtons(openBenefitPopup, "benefitId", benefitId)
                        setBenefitName(benefitName)
                        setOpenBenefitPopup(null)
                    }}
                />
            </SuspensePopup>
        </>
    )
}

export default ButtonsForm