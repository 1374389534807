import {CalendarBar, Dropdown, QuestionTooltip, Tab} from "omoplay";
import {ceil} from "lodash";
import Button from "../../assets/images/entry_preview_image.png";
import Benefit from "../../assets/images/event_preview_image.png";
import Prize from "../../assets/images/prize_preview_image.png";
import {useEffect, useState} from "react";
import moment from "moment/moment";
import {getAllDateRange, getDefaultDateRange} from "../../libs/helper";
import {getDetailEventsToProject} from "../../api/project/project.api";
import {getSpotStatistics} from "../../api/statistics/statistics.api";
import InputGroup from "./InputGroup";

const EventStatistics = ({periodStartDate, projectId}) => {
    const [dateRange, setDateRange] = useState(getDefaultDateRange())
    const [spots, setSpots] = useState([])
    const [spotId, setSpotId] = useState()
    const [spotStatistics, setSpotStatistics] = useState({})

    const dateItems = [
        { value: "all", label: "전체" },
        { value: "month", label: "최근 한 달" },
        { value: "week", label: "최근 일주일", selected: true },
    ];
    const handleQuickChange = (type) => {
        const format = "YYYY-MM-DD"
        const allDateRange = getAllDateRange()

        if (type === "all") {
            setDateRange({
                startDate: (periodStartDate ? moment(periodStartDate) : allDateRange.startDate).format(format),
                endDate: allDateRange.endDate,
            })
        } else if (type === "month") {
            setDateRange({
                startDate: allDateRange.startDate,
                endDate: allDateRange.endDate,
            })
        } else if (type === "week") {
            setDateRange({
                startDate: moment().subtract(6, "days").format(format),
                endDate: allDateRange.endDate,
            })
        }
    };
    const onDateInput = (dateRange) => {
        setDateRange({
            startDate: moment(dateRange.startDate).format("yyyy-MM-DD"),
            endDate: moment(dateRange.endDate).format("yyyy-MM-DD")
        });
    };
    useEffect(() => {
        getDetailEventsToProject(projectId).then(({data}) => {
            const events = data.events
            const spots = events.flatMap(event => event.entryPoints, [])
            setSpots([
                {
                    name: "전체"
                }, ...spots
            ])
        })
    }, [projectId]);

    useEffect(() => {
        const { startDate, endDate } = dateRange;
        getSpotStatistics(projectId, spotId, startDate, endDate).then(({data}) => {
            setSpotStatistics(data)
        })
    }, [projectId, spotId, dateRange])

    return (
        <InputGroup description={(
            <div className="flex gap-2">
                <div>
                    <Tab
                        variants="input"
                        items={dateItems}
                        onChanged={(data) => handleQuickChange(data.value)}
                    />
                </div>
                <CalendarBar initDate={{startDate: moment(dateRange.startDate).toDate(), endDate: moment(dateRange.endDate).toDate()}}
                             multiple={true}
                             onConfirm={(props) => onDateInput(props)} />
            </div>
        )}>
            <div className="flex items-center" style={{marginBottom: "15px", gap: "12px"}}>
                <div>
                    {
                        spots.length > 0 ?
                            <Dropdown variants="default"
                                      style={{width: "200px"}}
                                      titleStyle={{backgroundColor: "white"}}
                                      items={spots.map(spot => ({label: spot.name, value: spot.spotId}))}
                                      onChanged={(items) => {
                                          setSpotId(items[0].value)
                                      }} /> : <></>
                    }
                </div>
            </div>
            <div className="spot-table">
                <div className="spot-header">
                    <div className="flex-1 text-left">
                        <span className="number-badge" style={{marginRight: "5px"}}>1</span>
                        <span className="font-semibold" style={{color: "var(--color-foreground-tertiary)"}}>첫 진입</span>
                    </div>
                    <div className="flex-1 text-left">
                        <span className="number-badge" style={{marginRight: "5px"}}>2</span>
                        <span className="font-semibold" style={{color: "var(--color-foreground-tertiary)"}}>혜택 룰렛</span>
                    </div>
                    <div className="flex-1 text-left ">
                        <span className="number-badge" style={{marginRight: "5px"}}>3</span>
                        <span className="font-semibold" style={{color: "var(--color-foreground-tertiary)"}}>당첨 항목</span>
                    </div>
                </div>
                <div className="spot-row">
                    <div className="spot-row-item row-data">
                        <div >
                            <span className="rectangle" style={{marginRight: "10px"}} />
                            <span>페이지 조회</span>
                        </div>
                        <div className="counting-text">
                            <span>{spotStatistics?.firstPageCount?.toLocaleString()}</span>
                            <span>회</span>
                        </div>
                    </div>
                    <div className="spot-row-item row-data">
                        <div>
                            <span className="rectangle" style={{marginRight: "10px"}} />
                            <span>페이지 조회</span>
                        </div>
                        <div className="counting-text">
                            <span>{spotStatistics?.benefitPageCount?.toLocaleString()}</span>
                            <span>회</span>
                        </div>
                    </div>
                    <div className="spot-row-item row-data">
                        <div>
                            <span className="rectangle" style={{marginRight: "10px"}} />
                            <span>페이지 조회</span>
                        </div>
                        <div className="counting-text">
                            <span>{spotStatistics?.prizePageCount?.toLocaleString()}</span>
                            <span>회</span>
                        </div>
                    </div>
                </div>
                <div className="spot-row">
                    <div className="spot-row-item row-data">
                        <div>
                            <span className="rectangle" style={{marginRight: "10px"}} />
                            <span>로그인 버튼 클릭</span>
                        </div>
                        <div className="counting-text">
                            <span>{spotStatistics?.loginCount?.toLocaleString()}</span>
                            <span>회</span>
                        </div>
                    </div>
                    <div className="spot-row-item row-data">
                        <div>
                            <span className="rectangle" style={{marginRight: "10px"}} />
                            <span>룰렛 클릭</span>
                        </div>
                        <div className="counting-text">
                            <span>{spotStatistics?.benefitJoinCount?.toLocaleString()}</span>
                            <span>회</span>
                        </div>
                    </div>
                    <div className="spot-row-item">
                        <div style={{width: "100%"}}>
                            {
                                spotStatistics?.prizeButtons?.map((button) => {
                                    return (
                                        <div className="row-data">
                                            <div>
                                                <span className="rectangle" style={{marginRight: "10px"}} />
                                                <span>{button.name}</span>
                                            </div>
                                            <div className="counting-text">
                                                <span>{button.count}</span>
                                                <span>회</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="spot-row">
                    <div className="spot-row-item row-data" style={{backgroundColor: "var(--color-shape-depth1)"}}>
                        <div className="row-data">
                            <span style={{marginRight: "7px"}}>클릭 전환율</span>
                            <span>
                                            <QuestionTooltip>로그인 버튼 클릭 수 / 페이지 조회 수</QuestionTooltip>
                                        </span>
                        </div>
                        <div className="percent-text">
                            <span>{
                                spotStatistics?.loginCount === 0 ? "-"
                                    : ceil(spotStatistics?.loginCount / spotStatistics?.firstPageCount * 100, 2)
                            }</span>
                            <span>%</span>
                        </div>
                    </div>
                    <div className="spot-row-item row-data" style={{backgroundColor: "var(--color-shape-depth1)"}}>
                        <div className="row-data">
                            <span style={{marginRight: "7px"}}>클릭 전환율</span>
                            <span>
                                            <QuestionTooltip >룰렛 클릭 수 / 페이지 조회 수</QuestionTooltip>
                                        </span>
                        </div>
                        <div className="percent-text">
                            <span>{
                                (spotStatistics?.benefitPageCount === 0 && spotStatistics?.benefitJoinCount > 0) ? '100' :
                                    spotStatistics?.benefitJoinCount === 0 ? "-"
                                        : ceil(spotStatistics?.benefitJoinCount / spotStatistics?.benefitPageCount * 100, 2)
                            }</span>
                            <span>%</span>
                        </div>
                    </div>
                    <div className="spot-row-item row-data" style={{backgroundColor: "var(--color-shape-depth1)"}}>
                        <div className="row-data">
                            <span style={{marginRight: "7px"}}>클릭 전환율</span>
                            <span>
                                            <QuestionTooltip>(버튼 클릭 수의 합) / 페이지 조회 수</QuestionTooltip>
                                        </span>
                        </div>
                        <div className="percent-text">
                            <span>{spotStatistics?.prizeButtonsCount === 0 ? "-" : ceil(spotStatistics?.prizeButtonsCount / spotStatistics?.prizePageCount * 100, 2)}</span>
                            <span>%</span>
                        </div>
                    </div>
                </div>
                <div className="spot-row">
                    <div className="spot-row-item flex justify-center" style={{height: "500px"}}>
                        <img alt="" src={Button} />
                    </div>
                    <div className="spot-row-item flex justify-center" style={{height: "500px"}}>
                        <img alt="" src={Benefit} />
                    </div>
                    <div className="spot-row-item flex justify-center" style={{height: "500px"}}>
                        <img alt="" src={Prize} />
                    </div>
                </div>
            </div>
        </InputGroup>
    )
}

export default EventStatistics