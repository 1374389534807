import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/EntryPointPage";
import Login from "./pages/Login";
import UserStatistics from "./pages/UserStatistics";
import UserListPage from "./pages/Users";
import Coupons from "./pages/BenefitListPage";
import VolatilityMessageDetail from "./pages/VolatilityMessageDetail";
import Payments from "./pages/Payments";
import "moment/locale/ko";
import "omoplay/dist/style.scss"
import MessagePage from "./pages/Messages";
import AutomateMessages from "./pages/AutomateMessages";
import AutomateMessageEditPage from "./pages/AutomateMessageEditPage";
import OMOConnectSettingPage from "./components/page/OMOConnectSettingPage";
import ProjectsPage from "./pages/Projects";
import ProjectSettings from "./pages/ProjectSettings";
import EventEdit from "./pages/EventEdit";
import EventPage from "./pages/EventPage";
import BenefitEdit from "./pages/BenefitEdit";
import ProjectDetailPage from "./pages/ProjectDetail";
import VolatilityMessageSettingsPage from "./pages/VolatilityMessageSettingsPage";
import PointMenuPage from "./pages/PointMenuPage";
import UserDetailPage from "./pages/UserDetail";
import OnlineOrderUsersPage from "./pages/OnlineOrderUsersPage";
import ProductListPage from "./pages/ProductListPage";
import ProductDetailPage from "./pages/ProductDetailPage";
import AIVolatilityMessageListPage from "./pages/AIVolatilityMessageListPage";
import VolatilityMessageListPage from "./pages/VolatilityMessageListPage";
import AIVolatilityMessageEditPage from "./pages/AIVolatilityMessageEditPage";
import Join from "./pages/Join";
import BrandPage from "./pages/BrandPage";
import ProductEditPage from "./pages/ProductEditPage";
import Services from "./pages/Services";
import OMOAgentSettingPage from "./components/page/OMOAgentSettingPage";
import {useRecoilValue} from "recoil";
import {accountSelector, channelSelector} from "./store/selector/selector";
import AIAgentMessageListPage from "./pages/AIAgentMessageListPage";
import AIAgentMessageEditPage from "./pages/AIAgentMessageEditPage";
import AIAgentAutomationPage from "./pages/AIAgentAutomationPage";
import CreditPage from "./pages/CreditPage";
import CreditDetailPage from "./pages/CreditDetailPage";
import {useEffect} from "react";
import ChannelRegistrationPage from "./components/page/ChannelRegistrationPage";

function App() {
  const account = useRecoilValue(accountSelector);
  const channel = useRecoilValue(channelSelector)

  const permissions = account?.channels?.find(ch => ch.id === channel)?.permissions
  const showConnect = permissions?.findIndex(pm => pm.serviceType === "Connect") > -1
  const showAgent = permissions?.findIndex(pm => pm.serviceType === "Agent") > -1

  useEffect(() => {
    if (localStorage.getItem("channelState") === "undefined") {
      localStorage.removeItem("channelState")
      window.location.reload()
    }
    if (!localStorage.getItem("channelState") && account.channels?.length > 0) {
      localStorage.setItem("channelState", account.channels[0].id);
      window.location.reload()
    }
  }, [account]);

  return (
    <Router>
      <Routes>
        {
            showConnect && (
              <>
                <Route path="/spots" element={<Home />} />
                <Route path="/event" element={<EventPage />} />
                <Route path="/event/edit" element={<EventEdit />} />
                <Route path="/benefit/edit" element={<BenefitEdit />} />
                <Route path="/volatility-message" element={<VolatilityMessageListPage />} />
                <Route
                    path="/volatility-message/edit"
                    element={<VolatilityMessageSettingsPage />}
                />
                <Route
                    path="/volatility-message/detail"
                    element={<VolatilityMessageDetail />}
                />
                <Route path="/benefit" element={<Coupons />} />
                <Route path="/users" element={<UserListPage />} />
                <Route path="/user/detail" element={<UserDetailPage />} />
                <Route path="/messages" element={<MessagePage />} />
                <Route path="/users-statistics" element={<UserStatistics />} />
                <Route path="/automate-message" element={<AutomateMessages />} />
                <Route path="/automate-message/edit" element={<AutomateMessageEditPage />} />
                <Route path="/project" element={<ProjectsPage />} />
                <Route path="/project/edit" element={<ProjectSettings />} />
                <Route path="/project/detail" element={<ProjectDetailPage />} />
                <Route path="/point-menu" element={<PointMenuPage />} />
                <Route path="/online/orders" element={<OnlineOrderUsersPage />} />
              </>
            )
        }
        {
          showAgent && (
              <>
                <Route path="/agent/message" element={<AIAgentMessageListPage />} />
                <Route path="/agent/message/edit" element={<AIAgentMessageEditPage />} />
                <Route path="/agent/message/automation" element={<AIAgentAutomationPage />} />
                <Route path="/product" element={<ProductListPage />} />
                <Route path="/product/detail" element={<ProductDetailPage />} />
                <Route path="/product/edit" element={<ProductEditPage />} />
              </>
            )
        }
        {
          (showConnect && showAgent) && (
              <>
                <Route path="/ai/volatility-message" element={<AIVolatilityMessageListPage />} />
                <Route path="/ai/volatility-message/edit" element={<AIVolatilityMessageEditPage />} />
              </>
            )
        }
        <Route path="/" element={<Services />} />
        <Route path="/login" element={<Login />} />
        <Route path="/services" element={<Services />} />
        <Route path="/brand" element={<BrandPage />} />
        <Route path="/join/account" element={<Join />} />
        <Route path="/settings/connect" element={<OMOConnectSettingPage />} />
        <Route path="/settings/agent" element={<OMOAgentSettingPage />} />
        <Route path="/credit" element={<CreditPage />} />
        <Route path="/credit/detail" element={<CreditDetailPage />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/channel/registration" element={<ChannelRegistrationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
