import MobilePreview from "../../MobilePreview";
import {Button} from "omoplay";

const EventLoginPreview = ({loginBackgroundImage, loginButtonName, loginPrecautionName, loginPrecautionContent}) => {
    const API_HOST = process.env.REACT_APP_API_HOST
    const url = `${API_HOST}/view/login?temp=true` +
        `&loginBackgroundImage=${encodeURIComponent(loginBackgroundImage)}&loginButtonName=${encodeURIComponent(loginButtonName)}` +
        `&introduceButton=${encodeURIComponent(loginPrecautionName)}` +
        `&introduceTitle=${encodeURIComponent(loginPrecautionName)}` +
        `&introduceContent=${encodeURIComponent(loginPrecautionContent.replaceAll("\n", "-br-"))}`

    return (
        <div className="flex-col items-center gap-2">
            <MobilePreview>
                <iframe
                    className="relative h-full"
                    src={url}
                    title="OMOPLAY TEMP"
                />
            </MobilePreview>
            <div>
                <Button variants="outlined" value="미리보기" onClick={() => window.open(url)} />
            </div>
        </div>
    )
}

export default EventLoginPreview