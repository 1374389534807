import {useEffect, useState} from "react";
import {getDetailBenefitStatistics} from "../../api/project/project.api";
import BenefitStatisticsDetail from "./BenefitStatisticsDetail";
import {Divider} from "@mui/material";

const BenefitStatistics = ({projectId}) => {
    const [benefitStatistics, setBenefitStatistics] = useState([])

    useEffect(() => {
        getDetailBenefitStatistics(projectId).then(({data}) => {
            const benefitStatistics = data.benefitStatistics
            setBenefitStatistics(benefitStatistics)
        })
    }, [projectId]);

    return (
        <div className="flex-col gap-2 bg-white">
            {
                benefitStatistics?.map((benefit, index) => {
                    return (
                        <div className="flex-col gap-4">
                            <div style={{padding: "24px"}}>
                                <BenefitStatisticsDetail projectId={projectId} benefit={benefit}
                                                         events={benefit.events} index={index}
                                />
                            </div>
                            <Divider />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default BenefitStatistics