import Layout from "../components/layout/Layout";
import ServicesPage from "../components/page/ServicesPage";

const Services = () => {
    return (
        <Layout>
            <ServicesPage />
        </Layout>
    )
}

export default Services