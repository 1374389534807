import {useEffect, useState} from "react";
import {getDetailBenefitStatistics} from "../../api/project/project.api";
import BenefitStatisticsDetail from "./BenefitStatisticsDetail";
import InputGroup from "./InputGroup";

const BenefitStatistics = ({projectId}) => {
    const [benefitStatistics, setBenefitStatistics] = useState([])

    useEffect(() => {
        getDetailBenefitStatistics(projectId).then(({data}) => {
            const benefitStatistics = data.benefitStatistics
            setBenefitStatistics(benefitStatistics)
        })
    }, [projectId]);

    return (
        <InputGroup
            label="혜택 성과"
            description="해당 프로젝트에 참여한 고객 분석입니다."
        >
            <div className="flex-col gap-4">
                {
                    benefitStatistics?.map((benefit, index) => {
                        return (
                            <div className="flex-col gap-4">
                                <BenefitStatisticsDetail projectId={projectId} benefit={benefit}
                                                         events={benefit.events} index={index}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </InputGroup>
    )
}

export default BenefitStatistics