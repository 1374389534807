import React from "react";
import {ImageStatusCard} from "omoplay";

const MiniCarouselTypeContent = ({ data }) => {
    const carousels = data.carousels ?? []
    return (
        <>
            {carousels
                .filter((carousel) => carousel.attachment?.image != null)
                .map((carousel) => {
                    const attachment = carousel.attachment ?? {}
                    return (
                        <div className="w-[64px] h-[64px]">
                            <ImageStatusCard imageUrl={attachment.image.imgUrl} status="Done" />
                        </div>
                    )
                })
            }
        </>
    );
};

export default MiniCarouselTypeContent;
