import Loading from "./Loading";

const LabelNormalButton = ({
  children = "",
  htmlFor = "",
  className = "py-1.5 px-4 text-sm",
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className={
        "border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center disabled:bg-gray-400 transition " +
        className
      }
    >
      {children}
    </label>
  );
};
export default LabelNormalButton;
