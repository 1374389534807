import CardDesign from "./CardDesign";
import {useEffect, useState} from "react";
import {getCardInfo} from "../../api/cash/cash.api";


const RefundForm = ({creditType, availableCredit, cardId, refundCredit, refundAmount}) => {
    const [cardInfo, setCardInfo] = useState({})

    useEffect(() => {
        getCardInfo(cardId).then(({data}) => {
            setCardInfo(data)
        })
    }, [cardId]);

    return (
        <>
            <div className="font-bold text-[20px] mb-2">환불될 카드</div>
            <div className="font-medium text-[16px]"><CardDesign cardInfo={cardInfo} /></div>
            <div className="w-full flex-col items-end">
                {
                    creditType === "AI" && (
                        <label className="font-medium text-[16px]">
                            환불 크레딧: {refundCredit.toLocaleString()} Credit
                        </label>
                    )
                }
                {
                    availableCredit < refundCredit && (
                        <div className="text-red-500 text-[16px]">잔액 부족으로 환불할 수 없습니다.</div>
                    )
                }
                <label className="font-medium text-[18px] text-primary">
                    총 환불 금액: {refundAmount.toLocaleString()}원 KRW
                </label>
            </div>
        </>
    )
}

export default RefundForm