import {fetchApi} from "../fetch";
import {API_HOST, API_URL} from "../../constants/axios";



export const listAIMessages = async (startDate, endDate, type, page, size) => {
    const searchParam = new URLSearchParams({
        startDate,
        endDate,
        page,
        size,
    });
    if (type !== "ALL") {
        searchParam.set("messageStatus", type);
    }
    const res = await fetchApi.get(API_URL.AI_MESSAGE_LIST + "?" + searchParam.toString())
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const getTextHistory = async (id) => {
    const res = await fetchApi.get(`${API_URL.AI_CHAT_DETAIL.replace("{id}", id)}`)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const updateProducts = async (id, productIds) => {
    const res = await fetchApi.post(`${API_URL.AI_UPDATE_PRODUCTS.replace("{id}", id)}`, {
        productIds: productIds
    })
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const getAIMessage = async (id) => {
    const res = await fetchApi.get(`${API_URL.AI_MESSAGE_DETAIL.replace("{id}", id)}`)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const createAIMessage = async () => {
    const res = await fetchApi.post(`${API_URL.AI_MESSAGE}`)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const saveAIMessage = async (id, message) => {
    const res = await fetchApi.post(`${API_URL.AI_MESSAGE_DETAIL.replace("{id}", id)}`, {
        campaignName: message.campaignName,
        objective: message.objective,
        detailObjective: message.detailObjective,
        messageData: message.messageData
    })
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const getVariantsAIMessage = async (id, variantsKey) => {
    const res = await fetchApi.get(`${API_URL.AI_MESSAGE_VARIANTS.replace("{id}", id)}?variantsKey=${variantsKey}`)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const saveVariantsAIMessage = async (id, variantsKey) => {
    const res = await fetchApi.post(`${API_URL.AI_MESSAGE_VARIANTS.replace("{id}", id)}?variantsKey=${variantsKey}`)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const changeVariantsStatus = async (id, variantsKey, status) => {
    const res = await fetchApi.post(`${API_URL.AI_MESSAGE_VARIANTS_STATUS.replace("{id}", id)}?variantsKey=${variantsKey}&status=${status}`)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}


export const addProduct = async (id, productId) => {
    const res = await fetchApi.post(`${API_URL.AI_ADD_PRODUCT.replace("{id}", id)}`, {
        productId: productId
    })
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}


export const updateImage = async (id, blobs) => {
    const res = await fetchApi.multipart_post(`${API_URL.IMAGE_UPDATE.replace("{id}", id)}`, blobs)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const createMessageGeneration = async (id) => {
    const res = await fetchApi.post(`${API_URL.AI_CREATE_GENERATION.replace("{id}", id)}`)
    return res.data
}

export const messageChatInitStreamGeneration = (id, text) => {
    return fetchApi.source(`${API_HOST}${API_URL.AI_STREAM_INIT_GENERATION.replace("{id}", id)}?text=${encodeURIComponent(text)}`)
}

export const messageChatStreamGeneration = (id, text) => {
    return fetchApi.source(`${API_HOST}${API_URL.AI_STREAM_GENERATION.replace("{id}", id)}?text=${encodeURIComponent(text)}`)
}
