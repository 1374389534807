import SettingItemForm from "../../../form/message/SettingItemForm";
import React, {useEffect, useState} from "react";
import {Button, TextInput} from "omoplay";
import Trash from "../../../../assets/images/icon.trash.svg"
import ButtonsForm from "../../../form/message/ButtonsForm";

const WideListMessageSetting = ({ messageData, variables, regex, onChangeHeader, onChangeItems, onDeleteItem, onChangeButtons }) => {
    const header = messageData.header
    const items = messageData.items ?? [{},{},{}]
    const buttons = messageData.buttons
    const [maxLength, setMaxLength] = useState(items.length)

    useEffect(() => {
        setMaxLength(Math.max(items.length, 3))
    }, [items.length]);

    return (
        <div className="flex-col gap-4">
          <div>
              <div className="font-semibold">타이틀</div>
              <TextInput value={header} placeholder={"타이틀을 입력해주세요"}
                         maxLength={20}
                         onChange={(e) => onChangeHeader(e.target.value)}
              />
          </div>
            {
                Array.from({ length: maxLength }, (_, index) => {
                    return (
                        <div key={"wide-list" + index}>
                            <div className="font-semibold mb-2">{index === 0 ? "메인 아이템" : "리스트 항목 " + (index + 1)}</div>
                            <SettingItemForm
                                index={index}
                                variables={variables}
                                regex={regex}
                                item={items[index] ?? {}}
                                onChangeImage={(key, imgUrl) => onChangeItems(index, key, imgUrl)}
                                onChangeTitle={(title) => onChangeItems(index, "title", title)}
                                onChangeImageLink={(imgLink) => onChangeItems(index, "urlMobile", imgLink)}
                            />
                        </div>
                    );
                })
            }
            {
                maxLength === 3 ? (
                    <Button
                        style={{width: "100%"}}
                        variants="outlined"
                        onClick={() => {
                            setMaxLength(4)
                            onChangeItems(3, "title", "")
                        }}
                        value="+ 추가하기" />
                ) : (
                    <Button
                        style={{width: "100%"}}
                        variants="outlined"
                        onClick={() => onDeleteItem(3)}
                        value={<div className="flex gap-1"><img src={Trash} /><div className="text-error">삭제하기</div></div>} />
                )
            }
            <ButtonsForm maxLength={2} buttons={buttons} onChangeButtons={onChangeButtons} />
        </div>
    );
};

export default WideListMessageSetting;
