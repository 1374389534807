import MobilePreview from "../../MobilePreview";
import BenefitBackground from "../../../assets/images/benefit_background.svg";
import NoImage from "../../../assets/images/no-image.svg"
import Gift from "../../../assets/images/icon.gift.svg"
import {Button} from "omoplay";

const BenefitDetailPreview = ({benefitData}) => {
    return (
        <MobilePreview>
            <div className="relative h-full bg-repeat-round bg-contain bg-white overflow-auto">
                <div
                    className="bg-[#E5E6FF] absolute w-full h-[150px]"
                    style={{
                        backgroundImage: `url(${BenefitBackground})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center 0",
                    }}
                />
                {
                    benefitData.type === "DELIVERY" ? (
                            <div className="relative flex-center-all gap-2 mt-[22px]">
                                <div className="flex-col flex-center-all gap-2 w-full">
                                    <img src={Gift} alt="Gift" />
                                    <div className="text-white font-semibold text-center">
                                        {benefitData.name}
                                    </div>
                                    <div className="text-white text-xs font-semibold">{ benefitData.retailPrice ? `${Number(benefitData.retailPrice).toLocaleString()}원` : ""}</div>
                                    <div className="text-center w-full p-[16px]" style={{borderColor: "rgba(0,0,0,0.04)", borderRadius: "4px"}}>
                                        <img className="rounded w-full" alt={benefitData.name} src={benefitData.prizeImage ?? NoImage} />
                                        <div className="bg-depth1 p-[12px]" style={{borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"}}>
                                            <div className="text-tertiary text-[9px]">유효 기간: 0000-00-00 00:00까지</div>
                                            <div className="text-tertiary text-[9px]">기한 내 입력하지 않으면 당첨이 취소됩니다.</div>
                                        </div>
                                    </div>
                                    <div className="flex-col gap-2 w-full p-[16px]">
                                        <div>
                                            <div className="font-10px font-bold">수령자 이름</div>
                                            <div className="border rounded font-10px p-1 text-grey-5">수령자 이름을 입력해 주세요</div>
                                        </div>
                                        <div>
                                            <div className="font-10px font-bold">전화번호</div>
                                            <div className="border rounded font-10px p-1 text-grey-5">전화번호를 입력해 주세요</div>
                                        </div>
                                        {
                                            benefitData.showAddress && (
                                                <div className="flex-col gap-1">
                                                    <div className="font-10px font-bold">주소</div>
                                                    <div className="flex">
                                                        <div className="border rounded font-10px p-1 text-grey-5 w-full">주소를 검색해 주세요</div>
                                                        <div className="border rounded flex-center-all text-[8px] w-[80px] text-white" style={{backgroundColor: "var(--color-shape-tertiary)"}}>주소 검색</div>
                                                    </div>
                                                    <div className="border rounded font-10px p-1 text-grey-5">상세 주소를 입력해 주세요</div>
                                                </div>
                                            )
                                        }
                                        <div className="flex-col gap-1">
                                            <div className="flex gap-1">
                                                <div className="border w-[9px] h-[9px]"></div>
                                                <div className="text-[8px]">(필수)이용에 필요한 개인정보 수집에 동의합니다.</div>
                                            </div>
                                            <div className="text-red-500 rounded text-[8px] p-[8px] text-center" style={{backgroundColor: "rgba(253, 236, 236, 1)"}}>
                                                * 수집된 개인정보는 혜택 수령을 위해서만 활용됩니다.<br/>
                                                동의하지 않으면 혜택을 수령하실 수 없습니다.
                                            </div>
                                        </div>
                                        <Button variants="primary" value="제출하기" size="small" />
                                        <div className="p-[32px]"></div>
                                    </div>
                                </div>
                            </div>
                    ) : (
                        <div className="relative flex-center-all gap-2 mt-[22px]">
                            <div className="flex-col flex-center-all gap-2 w-full">
                                <img src={Gift} alt="Gift" />
                                <div className="text-white font-semibold text-center">
                                    {benefitData.name}
                                </div>
                                <div className="text-white text-xs font-semibold">{ benefitData.retailPrice ? `${Number(benefitData.retailPrice).toLocaleString()}원` : ""}</div>
                                <div className="text-center w-full p-[16px]">
                                    <img className="rounded w-full" alt={benefitData.name} src={benefitData.prizeImage ?? NoImage} />
                                    <div className="bg-depth1 p-[12px]" style={{borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"}}>
                                        {
                                            benefitData.type === "CODE" ? (
                                                <div>
                                                    <div className="text-tertiary text-[14px]">쿠폰 코드</div>
                                                    <div className="text-[20px] font-bold">1000-1234-5678</div>
                                                </div>
                                            ) : (<></>)
                                        }
                                        <div className="text-tertiary text-[14px]">유효 기간: 0000-00-00 00:00까지</div>
                                    </div>
                                    {
                                        benefitData.type !== "CODE" && (<div className="w-full pt-[8px]"><Button variants="primary" value="혜택 받기" style={{width: "100%"}} /></div>)
                                    }
                                </div>
                                <div className="text-gray-500 text-left w-full p-[16px]">
                                    <p className="font-bold">{benefitData.prizeCautionTitle}</p>
                                    <p className="whitespace-break-spaces">{benefitData.prizeCautionDescription}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </MobilePreview>
    )
}

export default BenefitDetailPreview