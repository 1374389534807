import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const fileUpload = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await fetchApi.multipart_post(API_URL.FILE_UPLOAD, formData);
  if (res.status === 200 && res.data.code === 2000) {
    return res.data;
  } else if (res.data.code === 4080) {
    alert("파일 업로드 용량이 초과하였습니다.");
  }
  return {};
};

export const kakaoImageUpload = async (
    file,
    messageType = "FI"
) => {
  const formData = new FormData();
  formData.append("messageType", messageType);
  formData.append("file", file)

  const res = await fetchApi.multipart_post(API_URL.FILE_KAKAO_UPLOAD, formData)

  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};
