export const DROP_DOWN_HOURS = [
    {label: "00시", value: "0"},{label: "01시", value: "1"},
    {label: "02시", value: "2"},{label: "03시", value: "3"},
    {label: "04시", value: "4"},{label: "05시", value: "5"},
    {label: "06시", value: "6"},{label: "07시", value: "7"},
    {label: "08시", value: "8"}, {label: "09시", value: "9"},
    {label: "10시", value: "10"}, {label: "11시", value: "11"},
    {label: "12시", value: "12"}, {label: "13시", value: "13"},
    {label: "14시", value: "14"}, {label: "15시", value: "15"},
    {label: "16시", value: "16"}, {label: "17시", value: "17"},
    {label: "18시", value: "18"}, {label: "19시", value: "19"},
    {label: "20시", value: "20"}, {label: "21시", value: "21"},
    {label: "22시", value: "22"}, {label: "23시", value: "23"}
]

export const DROP_DOWN_MINUTES = [
    {label: "00분", value: "0"}, {label: "05분", value: "5"},
    {label: "10분", value: "10"}, {label: "15분", value: "15"},
    {label: "20분", value: "20"}, {label: "25분", value: "25"},
    {label: "30분", value: "30"}, {label: "35분", value: "35"},
    {label: "40분", value: "40"}, {label: "45분", value: "45"},
    {label: "50분", value: "50"}, {label: "55분", value: "55"},
]