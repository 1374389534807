import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import SettingsLayout from "../components/layout/SettingsLayout";
import {useEffect, useState} from "react";
import { useSearchParams } from "react-router-dom";
import SettingsStepLayout from "../components/layout/SettingsStepLayout";
import EventDefault from "../components/form/event/EventDefault";
import EventScreen from "../components/form/event/EventScreen";
import EventBenefit from "../components/form/event/EventBenefit";
import {getEvent, postEvent} from "../api/event/event.api";

const EditBenefit = () => {
  const tabs = [
    { label: "진입 설정", value: 1 },
    { label: "룰렛 설정", value: 2 },
    { label: "혜택 설정", value: 3 },
  ];
  const [query] = useSearchParams();
  const [event, setEvent] = useState({});
  const [tab, setTab] = useState(tabs[0].value);

  const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
  const [isConfirm, setConfirm] = useState(false)

  useEffect(() => {
    if (query.has("id")) {
      getEvent(query.get("id")).then(({data}) => {
        data.loginButtonName = data.loginType === "registration" ? (data.loginButtonName ?? "로그인 하고 룰렛 돌리기") : (data.loginButtonName ?? "1초 회원가입하고 혜택 받기")
        data.eventButtonImage = data.eventButtonImage ?? "https://img.headless.co.kr/roulette/start_image.svg"
        data.prizePrecautionContent = data.prizePrecautionContent ?? "위 항목이 당첨되었습니다\n" +
            "간단한 가입으로 혜택을 수령해보세요!"
        setEvent(data)
      })
    }
  }, [])

  const saveEvent = (status) => {
      event.status = status
      event.loginType = event.loginType ?? "registration"
      event.loginButtonName = event.loginButtonName ?? "로그인하고 룰렛 돌리기"
      postEvent(event).then(() => {
          alert("저장되었습니다.")
          window.location.href = "/event"
      })
  }

  const description = () => {
    if (tab === 1) {
      return "이벤트의 기본 정보를 설정해 주세요."
    } else if (tab === 2) {
      return "고객이 이벤트에 접속할 때 노출 될 화면을 설정해 주세요."
    } else if (tab === 3) {
      return "이벤트의 혜택을 설정해 주세요. 각 혜택의 수량 제한은 '프로젝트'에서 설정해 주세요."
    } else {
      return undefined
    }
  }

  const updateEvent = (key, value) => {
    const newEvent = { ...event }
    newEvent[key] = value
    setEvent(newEvent)
  }

  const render = () => {
    if (tab === 1) {
      return (
          <div className="setting-step-padding">
            <EventDefault event={event}
                          onChangeEventName={(eventName) => updateEvent("name", eventName) }
                          onSelectType={(eventType) => updateEvent("type", eventType)} />
          </div>
      )
    } else if (tab === 2) {
      return (
          <EventScreen event={event}
                       onChange={(key, value) => updateEvent(key, value)}
                       onValid={(valid) => setNextButtonDisabled(!valid)}
                       onChangeLoginType={(loginType) => {
                         const newEvent = { ...event }
                         newEvent.loginType = loginType
                         newEvent.prizePrecautionContent = null
                         setEvent(newEvent)
                       }}
          />
      )
    } else if (tab === 3) {
      return (
          <EventBenefit
              benefitSettings={event.benefitSettings}
              onSelectedBenefit={(benefit) => {
                const benefitList = event.benefitSettings ?? []
                updateEvent("benefitSettings", [...benefitList, benefit])
              }}
              onDeletedBenefit={(benefitId) => {
                updateEvent("benefitSettings", event.benefitSettings.filter((value) => value.id !== benefitId))
              }}
              onUpdatedBenefit={(benefit) => {
                updateEvent("benefitSettings", event.benefitSettings.map((value) => {
                  if (value.id === benefit.id) {
                    return benefit
                  }
                  return value
                }))
              }}
              onValid={(valid) => {
                setConfirm(event.name?.length > 0 && valid)
              }}
          />
      )
    } else {
      return "";
    }
  }

  return (
    <SettingsLayout
      title={"이벤트 만들기"}
      icon={faCommentDots}
      tabs={tabs}
      activeTab={tab}
      isConfirm={isConfirm}
      onChange={(value) => {
        setTab(value)
        setNextButtonDisabled(false)
      }}
      onTemporarySave={() => saveEvent("TEMPORARY")}
      onConfirm={() => saveEvent("ACTIVE")}
      onClose={() => window.location.href = "/event"}
      nextButtonDisabled={nextButtonDisabled}
    >
      <SettingsStepLayout description={description()} renderChild={render()} />
    </SettingsLayout>
  );
};
export default EditBenefit;
