import MobilePreview from "../../MobilePreview";
import {Button} from "omoplay";

const EventLoginPrizePreview = ({eventBackgroundImage, eventImage, eventButtonImage, prizePrecautionContent, loginButtonName}) => {
    const API_HOST = process.env.REACT_APP_API_HOST
    const url = `${API_HOST}/view/event?temp=true&login=true&prize=true` +
        `&eventBackgroundImage=${encodeURIComponent(eventBackgroundImage)}` +
        `&eventImage=${encodeURIComponent(eventImage)}` +
        `&loginButtonName=${encodeURIComponent(loginButtonName)}` +
        `&eventButtonImage=${encodeURIComponent(eventButtonImage)}` +
        `&prizePrecautionContent=${encodeURIComponent(prizePrecautionContent.replaceAll("\n", "-br-"))}` +
        `&prizeImage=${encodeURIComponent("https://img.headless.co.kr/offline_qr_service/7/40fb1936-c57f-46ad-b69d-c2630a22f69c.png")}`
    return (
        <div className="flex-col items-center gap-2">
            <MobilePreview>
                <iframe
                    className="relative h-full"
                    src={url}
                    title="OMOPLAY TEMP"
                />
            </MobilePreview>
            <div>
                <Button variants="outlined" value="미리보기" onClick={() => window.open(url)} />
            </div>
        </div>
    )
}

export default EventLoginPrizePreview