import Layout from "../components/layout/Layout";
import EntryPointList from "../components/page/QRList";

const EntryPointPage = () => {
  return (
    <Layout>
      <EntryPointList />
    </Layout>
  );
};
export default EntryPointPage;
