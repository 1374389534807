import {
  Box,
  Divider,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";
import SettingFormLabel from "../../../form/SettingFormLabel";
import { messageFileUpload } from "../../../../api/file/file.api";
import NormalButton from "../../../NormalButton";
import LabelNormalButton from "../../../LabelNormalButton";
import InputGroup from "../../../form/InputGroup";
import ButtonsForm from "../../../form/message/ButtonsForm";
import VariableTextArea from "../../../form/VariableTextArea";

const WideMessageSetting = ({ messageData, variables, regex, onChangeImage, onChangeImageLink, onChangeContents, onChangeButtons }) => {
    const image = messageData.image ?? {}
    const imageUrl = image.imgUrl
    const imageLink = image.imgLink
    const content = messageData.content
    const buttons = messageData.buttons

  const uploadImage = (e) => {
    messageFileUpload(
      "wide",
      e.target.files[0],
      (res) => {
        if (res.status === 200) {
          const data = res.data;
          if (data.code === "0") {
            onChangeImage(res.data.imageUrl);
          } else {
            alert(data.message);
          }
        } else {
          console.error(res.data);
          alert(
            res.status +
              "에러가 발생했습니다. 헤드리스 담당자에게 문의 해주세요."
          );
        }
      },
      (error) => {
        alert(error);
      }
    );
  };

  return (
    <>
      <InputGroup
          label={"메시지 꾸미기"}
          isRequired={true}
          className="py-4"
      >
        <RadioGroup
            items={[
              {
                label: "이미지",
                value: "image"
              }
            ]}
            value={"image"}
        />
        <Box sx={{ display: "flex", gap: "8px" }}>
          <input
              accept="image/jpg, image/jpeg, image/png"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={(e) => uploadImage(e)}
          />
          <TextField
              inputProps={{ readOnly: true }}
              fullWidth
              placeholder="이미지 파일을 업로드 해주세요."
              helperText="* 고정: 800px x 600px / 지원파일 : png, jpeg (최대 2MB)"
              size={"small"}
              value={imageUrl}
          />
          <LabelNormalButton
              htmlFor="raised-button-file"
              className="whitespace-nowrap py-2 px-4 text-sm h-fit"
          >
            업로드 하기
          </LabelNormalButton>
        </Box>
      </InputGroup>
      <Box sx={{ mt: 2 }}>
        <SettingFormLabel label={"링크"} />
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            mt: 1,
          }}
        >
          <TextField
            sx={{ flex: 1 }}
            size={"small"}
            value={imageLink}
            placeholder="연결할 페이지의 URL을 입력해주세요."
            onChange={(e) => onChangeImageLink(e.target.value)}
          />
          <NormalButton onClick={() => window.open(imageLink)}>URL 확인</NormalButton>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 4 }} />
      <Box>
        <SettingFormLabel label={"내용"} isRequired={true} />
          <VariableTextArea message={content} variables={variables} regex={regex} maxLength={76} onChangeMessage={onChangeContents} />
      </Box>
      <Divider sx={{ mt: 2, mb: 4 }} />
        <ButtonsForm maxLength={2} buttons={buttons} onChangeButtons={onChangeButtons} />
    </>
  );
};

export default WideMessageSetting;
