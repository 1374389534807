import {atom, DefaultValue, selector} from "recoil";
import { getAccount } from "../../api/account/account.api";

export const accountSelector = selector({
  key: "accountSelector",
  get: async ({ get }) => {
    const res = await getAccount();
    if (res.data) {
      return res.data;
    }
    return get(accountState);
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(accountState);
    } else if (typeof newValue === "number") {
      set(accountState, newValue);
    }
  },
});
export const channelSelector = selector({
  key: "channelSelector",
  get: async ({ get }) => {
    return Number(get(channelState));
  },
});

export const accountState = atom({
  key: "account",
  default: {},
});

export const channelState = atom({
  key: "channel",
  default: localStorage.getItem("channelState") ?? 0,
});