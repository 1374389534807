import Loading from "./Loading";

const NormalButton = ({
  children = "",
  isLoading,
  type = "button",
  disabled = false,
  className = "py-1.5 px-4 text-sm",
  onClick = () => {
    return;
  },
}) => {
  return (
    <button
      className={
        "border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center disabled:bg-gray-400 transition " +
        className
      }
      type={type}
      disabled={disabled || isLoading}
      onClick={() => onClick()}
    >
      {isLoading ? (
        <div className="flex justify-center">
          <p className="text-white fill-indigo-500 m-auto">
            <Loading className="w-6 h-6" />
          </p>
        </div>
      ) : (
        children
      )}
    </button>
  );
};
export default NormalButton;
