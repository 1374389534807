const InputGroup = ({
  label,
  isRequired = false,
  className = "",
  children,
}) => {
  return (
    <div className={"flex-col gap-2 " + className}>
      <div>
        <label className="text-md font-bold text-gray-900 relative">
          {label}
          {isRequired && (
            <span className="absolute text-red-500 -end-2 -top-1">*</span>
          )}
        </label>
      </div>
      {children}
    </div>
  );
};

export default InputGroup;
