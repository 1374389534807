import {Button, Tag} from "omoplay";
import InputGroup from "../form/InputGroup";
import {useRecoilValue} from "recoil";
import {accountSelector} from "../../store/selector/selector";
import OMOAgentImage from "../../assets/images/omo_agent_image.png";
import OMOConnectImage from "../../assets/images/omo_connect_image.svg";
import {getChannelInfo} from "../../api/account/account.api";
import {useEffect, useState} from "react";
import KakaoIcon from "../../assets/images/kakao_icon.svg";

const ServicesPage = () => {
    const account = useRecoilValue(accountSelector);
    const [channelsInfo, setChannelsInfo] = useState([])

    useEffect(() => {
        account.channels?.forEach(channel => {
            getChannelInfo(channel.id).then(({data}) => {
                setChannelsInfo((prev) => {
                    return [
                        ...prev,
                        {
                            id: channel.id,
                            description: data.description,
                            isAvailable: data.isAvailable
                        }
                    ]
                })
            })
        })
    }, []);

    return (
        <div className="flex-col gap-2">
            <div className="flex justify-end w-full">
                <Button variants="primary" value="+ 채널 추가" size="large" onClick={() => window.location.href = "/channel/registration"} />
            </div>
            <div className="flex-col gap-4">
                {
                    account.channels?.map(channel => {
                        const info = channelsInfo.find(description => description.id === channel.id)

                        return (
                            <InputGroup label={
                                <div className="flex-align-center gap-2">
                                    <div className="text-[18px]">{channel.name}</div>
                                    <img src={KakaoIcon} alt="kakao-icon" />
                                    <div>
                                        {
                                            !info?.isAvailable ? <></> : (info.isAvailable ?
                                                <Tag color="info">사용 가능</Tag>
                                                : (
                                                    <div className="flex-align-center gap-2">
                                                        <Tag color="error">사용 불가</Tag>
                                                        <div className="text-red-500">오모플레이 계정에 관리자 초대가 되어 있지 않습니다.</div>
                                                    </div>
                                                    )
                                            )
                                        }
                                    </div>
                                </div>
                            } description={info?.description}>
                                <div className="flex gap-2">
                                    <div className="flex flex-1 border rounded-lg border-[#dbdbea]">
                                        <div className="w-full bg-[#eaecef] rounded-l-lg">
                                            <div className="flex-center-all h-full">
                                                <img src={OMOConnectImage} alt="omo-connect" />
                                            </div>
                                        </div>
                                        <div className="w-full flex-col justify-center p-8 text-gray-600 text-xs">
                                            <h5 className="font-bold text-sm">오모 Connect</h5>
                                            <div className="mt-2">
                                                <div>최고의 CRM 도구 오모 Connect</div>
                                                <div className="flex justify-end w-full">
                                                    {
                                                        channel.permissions.find(permission => permission.serviceType === "Connect") ? (
                                                            <div><Tag color="info" size="large">운영중</Tag></div>
                                                        ) : (
                                                            <Button variants="primary" disabled={!info?.isAvailable} value="신청하기" onClick={() => {
                                                                window.location.href = `/settings/connect?id=${channel.id}`
                                                            }} />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-1 border rounded-lg border-[#dbdbea]">
                                        <div className="w-full bg-[#eaecef] rounded-l-lg">
                                            <div className="flex-center-all h-full">
                                                <img src={OMOAgentImage} alt="omo-agent" />
                                            </div>
                                        </div>
                                        <div className="w-full flex-col justify-center p-8 text-gray-600 text-xs">
                                            <h5 className="font-bold text-sm">오모 Agent</h5>
                                            <div className="mt-2">
                                                <div>AI로 간편하게 메시지 제작</div>
                                                <div className="flex justify-end w-full">
                                                    {
                                                        channel.permissions.find(permission => permission.serviceType === "Agent") ? (
                                                            <div><Tag color="info" size="large">운영중</Tag></div>
                                                        ) : (
                                                            <Button variants="primary" disabled={!info?.isAvailable} value="신청하기" onClick={() => {
                                                                window.location.href = `/settings/agent?id=${channel.id}`
                                                            }} />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InputGroup>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ServicesPage