import Layout from "../components/layout/Layout";
import QRList from "../components/page/QRList";

const HomePage = () => {
  return (
    <Layout>
      <QRList />
    </Layout>
  );
};
export default HomePage;
