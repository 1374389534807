import {API_HOST, DEFAULT_HEADER, MESSAGE_API_HOST,} from "../constants/axios";
import axios from "axios";
import {Cookies} from "react-cookie";

const cookies = new Cookies();

export const instance = axios.create({
  baseURL: API_HOST,
});

export const message_instance = axios.create({
  baseURL: MESSAGE_API_HOST,
});

export const fetchApi = {
  get: async (url) => {
    try {
      return await instance.get(url, {
        headers: await getTokenHeader(),
      });
    } catch (res) {
      return checkAuthorization(res);
    }
  },
  getBlob: async (url) => {
    try {
      return await instance.get(url, {
        headers: await getTokenHeader(),
        responseType: "blob"
      });
    } catch (res) {
      return checkAuthorization(res);
    }
  },
  post: async (url, data) => {
    try {
      return await instance.post(url, data, {
        headers: await getTokenHeader(),
      });
    } catch (res) {
      return checkAuthorization(res);
    }
  },
  multipart_post: async (url, data) => {
    try {
      return await instance.post(url, data, {
        headers: {
          ...(await getTokenHeader()),
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (res) {
      return checkAuthorization(res);
    }
  },

  put: async (url, data) => {
    try {
      return await instance.put(url, data, {
        headers: await getTokenHeader(),
      });
    } catch (res) {
      return checkAuthorization(res);
    }
  },

  delete: async (url) => {
    try {
      return await instance.delete(url, {
        headers: await getTokenHeader(),
      });
    } catch (res) {
      return checkAuthorization(res);
    }
  },
};

const checkAuthorization = (data) => {
  if (data && data?.response?.status === 401) {
    window.location.href = "/login";
  }
  return data;
};

const getTokenHeader = async () => {
  const res = { ...DEFAULT_HEADER };
  const token = await cookies.get("token");
  res.header["Authorization"] = `Bearer ${token}`;
  return res.header;
};
