import { Typography, FormLabel, Box } from "@mui/material";

const SettingFormLabel = ({
    label,
    isRequired = false,
    actionButton,
    sx = {},
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <FormLabel sx={{ fontWeight: 700, color: "#000", ...sx }}>
                {label}
                {isRequired && (
                    <Typography variant="p" color={"red"} sx={{ ml: 0.7 }}>
                        *
                    </Typography>
                )}
            </FormLabel>
            {actionButton && actionButton}
        </Box>
    );
};

export default SettingFormLabel;
