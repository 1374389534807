import { DefaultValue, selector, selectorFamily } from "recoil";
import { getCashHistories } from "../../api/cash/cashHistory.api";
import { cashState } from "../state/cashHistory";

export const cashSelector = selector({
  key: "cashSelector",
  get: async ({ get }) => {
    const res = await getCashHistories();
    if (res.data) {
      return res.data.content;
    }
    return get(cashState);
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(cashState);
    } else if (typeof newValue === "number") {
      set(cashState, newValue);
    }
  },
});

export const cashFamilySelector = selectorFamily({
  key: "cashFamilySelector",
  get:
    (param) =>
    async ({ get }) => {
      const [name, page, size, sort] = param.split("|");
      const res = await getCashHistories(name, page, size, sort);
      if (res.data) {
        return res.data;
      }
      return get(cashState);
    },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(cashState);
    } else if (typeof newValue === "number") {
      set(cashState, newValue);
    }
  },
});
