import Layout from "../components/layout/Layout";
import Credit from "../components/page/Credit";


const CreditPage = () => {
    return (
        <Layout>
            <Credit />
        </Layout>
    )
}

export default CreditPage