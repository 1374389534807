import Layout from "../components/layout/Layout";
import ProjectList from "../components/page/ProjectList";

const ProjectsPage = () => {
  return (
    <Layout>
      <ProjectList />
    </Layout>
  );
};
export default ProjectsPage;
