import MobilePreview from "../../MobilePreview";
import Firework from "../../../assets/images/coupon_result_firework.svg";
import NoImage from "../../../assets/images/no-image.svg"
import GrayButton from "../../GrayButton";
import LOGO from "../../../assets/images/omoplay_logo.svg"
import CheckboxIcon from "../../../assets/images/check_box_icon 2.svg"

const BenefitDetailPreview = ({benefitData}) => {
    return (
        <MobilePreview>
            <div className="relative h-full bg-repeat-round bg-contain bg-white overflow-auto">
                <div className="flex-center-all h-[28px] bg-mainblue-0">
                    <div>
                        <img src={LOGO} style={{width: "120px"}} />
                    </div>
                </div>
                <div
                    className="bg-[#E5E6FF] absolute top-[28px] w-full h-[150px]"
                    style={{
                        backgroundImage: `url(${Firework})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center 0",
                    }}
                />
                {
                    benefitData.type === "DELIVERY" ? (
                        <div className="absolute w-full items-center flex-col mt-4">
                            <div className="bg-white border" style={{borderRadius: "12px"}}>
                                <img src={benefitData.prizeImage ?? NoImage} className="w-4/5 mx-auto mt-2" />
                                <div className="flex-col flex-center-all mt-2">
                                    <div className="font-10px font-bold text-mainblue-0">
                                        {benefitData.name}
                                    </div>
                                    <div className="font-10px font-bold">{benefitData.retailPrice ? `${Number(benefitData.retailPrice).toLocaleString()}원` : ""}</div>
                                </div>
                                <div className="flex-col gap-2" style={{padding: "0 16px 16px 16px"}}>
                                    <div className="text-center" style={{backgroundColor: "#F0F0FF", borderRadius: "6px", padding: "8px"}}>
                                        <div className="text-mainblue-0 font-bold" style={{fontSize: "8px"}}>입력기한 0000-00-00 00:00까지</div>
                                        <div className="text-mainblue-0" style={{fontSize: "8px"}}>기한 내 입력하지 않으면 당첨이 취소됩니다.</div>
                                    </div>
                                    <div>
                                        <div className="font-10px font-bold">수령자 이름</div>
                                        <div className="border rounded font-10px pl-1 text-grey-5">수령자 이름을 입력해 주세요</div>
                                    </div>
                                    <div>
                                        <div className="font-10px font-bold">전화번호</div>
                                        <div className="border rounded font-10px pl-1 text-grey-5">전화번호를 입력해 주세요</div>
                                    </div>
                                    <div className="flex-col gap-1">
                                        <div className="font-10px font-bold">주소</div>
                                        <div className="flex">
                                            <div className="border rounded font-10px pl-1 text-grey-5 w-full">주소를 검색해 주세요</div>
                                            <div className="border rounded flex-center-all" style={{fontSize: "7px", width: "50px"}}>주소 검색</div>
                                        </div>
                                        <div className="border rounded font-10px pl-1 text-grey-5">상세 주소를 입력해 주세요</div>
                                    </div>
                                    <div className="flex-col gap-1">
                                        <div className="flex justify-between">
                                            <div className="font-10px font-bold">개인정보 수집 동의</div>
                                            <div className="underline font-bold text-grey-5" style={{fontSize: "10px"}}>보기</div>
                                        </div>
                                        <div className="flex items-center gap-1 bg-grey-1" style={{padding: "6px", borderRadius: "3px"}}>
                                            <div><img src={CheckboxIcon} /></div>
                                            <div className="font-10px">동의합니다</div>
                                            <div className="border" style={{width: "9px", height: "9px"}}></div>
                                            <div className="font-10px">동의하지 않습니다</div>
                                        </div>
                                        <div className="text-red-500" style={{fontSize: "8px"}}>* 동의하지 않으면 혜택을 수령하실 수 없습니다.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-[10px] text-gray-500 text-left w-4/5">
                                <p className="font-bold" style={{fontSize: "8px"}}>{benefitData.precautionTitle}</p>
                                <p className="whitespace-break-spaces" style={{fontSize: "8px"}}>{benefitData.precautionDescription}</p>
                            </div>
                        </div>
                    ) : (
                        <div className="absolute w-full items-center flex-col">
                            <h4 className="text-[#4046E3] font-medium mt-4 w-4/5 text-center">
                                {benefitData.name}
                            </h4>
                            <p className="text-xs font-medium">{ benefitData.retailPrice ? `${Number(benefitData.retailPrice).toLocaleString()}원` : ""}</p>
                            <div className="p-4 bg-white rounded-lg w-4/5 text-[10px] text-center shadow-lg mt-2">
                                <p style={{fontSize: "12px"}}>결제 시 직원에게 보여주세요.</p>
                                <p className="text-mainblue-0" style={{fontSize: "9px"}}>사용기한 0000-00-00 00:00까지</p>
                                <img src={benefitData.prizeImage ?? NoImage} className="w-4/5 mx-auto mt-2" />
                                {
                                    benefitData.type === "CODE" ? (
                                        <div className="mt-4" style={{backgroundColor: "#F0F0FF", borderRadius: "6px", padding: "8px"}}>
                                            <div className="text-mainblue-0" style={{fontSize: "11px", fontWeight: "700"}}>쿠폰 코드</div>
                                            <div className="text-mainblue-0" style={{fontSize: "14px", fontWeight: "700"}}>1000-1234-5678</div>
                                            <div className="text-mainblue-0" style={{fontSize: "9px"}}>유효기간 : 0000-00-00 00:00까지</div>
                                        </div>
                                    ) : (<GrayButton className="py-1.5 mt-4">혜택 받기</GrayButton>)
                                }
                            </div>
                            <div className="mt-4 text-[10px] text-gray-500 text-left w-4/5">
                                <p className="font-bold" style={{fontSize: "8px"}}>{benefitData.prizeCautionTitle}</p>
                                <p className="whitespace-break-spaces" style={{fontSize: "8px"}}>{benefitData.prizeCautionDescription}</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </MobilePreview>
    )
}

export default BenefitDetailPreview