import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const getSpots = async (
  folderId = "",
  name = "",
  page = 0,
  size = 9999,
  sort = "name,desc"
) => {
  const searchParam = new URLSearchParams({
    folderId,
    name,
    sort,
    page,
    size,
  });
  const res = await fetchApi.get(
    API_URL.GET_SPOTS + "?" + searchParam.toString()
  );
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};

export const getSpot = async (spotId) => {
  const res = await fetchApi.get(API_URL.GET_SPOT.replace("{spotId}", spotId));
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};


export const getAllSpots = async () => {
  const res = await fetchApi.get(API_URL.GET_ALL_SPOTS);
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};

export const createSpot = async (name, folderId, benefitId) => {
  const res = await fetchApi.post(API_URL.CREATE_SPOT, {
    name
  });
  if (res.status === 200) {
    return res.data;
  }
  return res.data;
};

export const updateSpot = async (
  spotId,
  isActive,
  name,
  folderId,
  benefitId
) => {
  const res = await fetchApi.put(
    API_URL.UPDATE_SPOT.replace("{spotId}", spotId),
    {
      active: isActive,
      name,
      folderId: Number(folderId),
      benefitId: Number(benefitId),
    }
  );
  if (res.status === 200) {
    return res.data;
  }
  return res.data;
};

export const duplicateCheck = async (name) => {
  const res = await fetchApi.post(API_URL.DUPLICATE_SPOT_CHECK, {
    name,
  });
  if (res.status === 200) {
    return res.data;
  }
  return res.data;
};


export const deleteSpot = async (id) => {
  const res = await fetchApi.delete(API_URL.DELETE_SPOT.replace("{id}", id));
  if (res.status === 200) {
    return res.data;
  }
  return res.data;
};
