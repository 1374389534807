import MessageWrapPreview from "../../volatility-message/content/preview/MessageWrapPreview";
import { MESSAGE_TYPES } from "../../../constants/menus";
import InputGroup from "../InputGroup";

export const AutomateMessageConfirm = ({campaignName, messageData, type}) => {

  return (
      <InputGroup label="최종 확인" description="마지막으로 보내기 전 설정하신 내용에 잘못된 내용은 없는지 확인해 보세요.">
        <div className="flex">
          <div className="flex-1 pr-[16px]">
            <div className="font-semibold">콘텐츠</div>
            <div className="flex-center-all h-full">
              <MessageWrapPreview messageData={messageData} type={type} />
            </div>
          </div>
          <div className="flex-1 flex-col gap-4 pl-[16px]">
            <div>
              <div className="font-semibold">기본 설정</div>
              <ul className="flex-col border radius-6">
                <li className="flex items-center">
                  <label className="p-[14px] w-[120px] bg-depth1 font-medium">캠페인명</label>
                  <div className="p-[14px]">{campaignName}</div>
                </li>
                <li className="flex items-center">
                  <label className="p-[14px] w-[120px] bg-depth1 font-medium">메시지 타입</label>
                  <div className="p-[14px]">친구톡</div>
                </li>
                <li className="flex items-center">
                  <label className="p-[14px] w-[120px] bg-depth1 font-medium">친구톡 타입</label>
                  <div className="p-[14px]">
                    {type &&
                        MESSAGE_TYPES.filter(
                            (item) => item.key === type
                        )[0]?.title}
                  </div>
                </li>
              </ul>
            </div>
            <div className="flex-1">
              <div className="font-semibold">대상 및 발송 일정</div>
              <ul className="flex-col border radius-6">
                <li className="flex items-center">
                  <label className="p-[14px] w-[120px] bg-depth1 font-medium">발송 시간</label>
                  <div className="p-[14px]">{messageData.sendHours}시{messageData.sendMinutes}분</div>
                </li>
              </ul>
              <div className="text-[13px] text-tertiary mt-[4px]">
                <div>* 메시지 차단 등으로 인해 발송되지 않을 수 있습니다.</div>
              </div>
            </div>
          </div>
        </div>
      </InputGroup>
  );
};

export default AutomateMessageConfirm;
