import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { getPartnerStatistics } from "../../api/benefit/statistics.api";
import { getPartnerUserStatistics } from "../../api/user/statistics.api";
import DateChart from "../DateChart";
import DateStatistics from "../DateStatistics";
import {
  defaultBarChartConfig,
  defaultLineBarChartConfig,
  defaultPieChartConfig,
} from "../../constants/chart_config";
import { getDefaultDateRange } from "../../libs/helper";
import {CalendarBar, TabBar} from "omoplay";
const defaultDateRage = getDefaultDateRange();
const dateItems = [
  { value: "all", label: "전체" },
  { value: "month", label: "최근 한 달" },
  { value: "week", label: "최근 일주일", selected: true },
];

const AllSpotsPage = () => {
  const [dateRange, setDateRange] = useState(defaultDateRage);
  const [customerChart, setCustomerChart] = useState({
    ...defaultLineBarChartConfig,
  });
  const [customerPieChart, setCustomerPieChart] = useState({
    ...defaultPieChartConfig,
  });
  const [benefitChart, setBenefitChart] = useState({
    ...defaultBarChartConfig,
  });
  const [customerPieLabels, setCustomerPieLabels] = useState([]);
  const [totalIssuedItems, setTotalIssuedItems] = useState(0)
  const [totalUsedItems, setTotalUsedItems] = useState(0)

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    getPartnerUserStatistics(startDate, endDate).then(({data}) => {
      const customerConfig = { ...defaultLineBarChartConfig };
      const customerRangeConfig = { ...defaultPieChartConfig };
      customerConfig.series[0].data = [];
      customerConfig.series[0].data = data.items.map((row) => {
        const todayTotalCount = row.items.reduce((a, b) => b.count + a, 0);
        return { x: row.date, y: todayTotalCount };
      });

      customerRangeConfig.series = [];
      let pieData = {};
      data.items.forEach((row) => {
        row.items.forEach((item) => {
          if (pieData[item.spotId]) {
            pieData[item.spotId].count += item.count;
          } else {
            pieData[item.spotId] = {
              name: item.spotName,
              count: item.count,
            };
          }
        });
      });
      const sortedPie = Object.entries(pieData)
          .sort(([, a], [, b]) => {
            return b.count - a.count;
          })
          .reduce((r, [k, v]) => {
            return { ...r, [v.name]: v.count };
          }, {});

      const excludeOver10PieData = {};
      Object.keys(sortedPie).every((key, index) => {
        excludeOver10PieData[key] = sortedPie[key];
        return true;
      });

      customerRangeConfig.series = Object.values(excludeOver10PieData);
      customerRangeConfig.options.labels = Object.keys(excludeOver10PieData);

      setCustomerChart(customerConfig);
      setCustomerPieChart(customerRangeConfig);
      setCustomerPieLabels(sortedPie);
    })

    getPartnerStatistics(startDate, endDate).then(({data}) => {
      const benefitConfig = { ...defaultBarChartConfig };
      benefitConfig.series[0].data = [];
      benefitConfig.series[1].data = [];
      let totalIssueItems = 0
      let totalUsedItems = 0
      data.items.forEach((row) => {
        benefitConfig.series[0].data.push({
          x: row.date,
          y: row.issueItems.reduce((a, b) => b.count + a, 0),
        });
        benefitConfig.series[1].data.push({
          x: row.date,
          y: row.usedItems.reduce((a, b) => b.count + a, 0),
        });
        row.issueItems.forEach(value => {
          totalIssueItems += value.count
        })
        row.usedItems.forEach(value => {
          totalUsedItems += value.count
        })
      });
      setTotalIssuedItems(totalIssueItems)
      setTotalUsedItems(totalUsedItems)
      setBenefitChart(benefitConfig);
    })
  }, [dateRange]);

  const getCutomerCards = useCallback(() => {
    return [
      {
        label: "획득 고객",
        value: customerPieChart.series.reduce((a, b) => b + a, 0),
      },
    ];
  }, [customerPieChart]);

  const getBenefitCards = useCallback(() => {
    return [
      {
        label: "혜택 발급",
        value: totalIssuedItems,
      },
      {
        label: "혜택 사용",
        value: totalUsedItems,
      },
      {
        label: "사용률",
        value: Math.round((totalUsedItems / totalIssuedItems) * 100) + "%",
      },
    ];
  }, [totalIssuedItems, totalUsedItems]);
  const handleQuickChange = (type) => {
    const format = "YYYY-MM-DD"
    const now = moment().format(format);
    if (type === "all") {
      setDateRange({
        startDate: moment("2023-12-01", format).format(format),
        endDate: now,
      });
    } else if (type === "month") {
      setDateRange({
        startDate: moment().subtract(1, "months").format(format),
        endDate: now,
      });
    } else if (type === "week") {
      setDateRange({
        startDate: moment().subtract(6, "days").format(format),
        endDate: now,
      });
    }
  };

  const onDateInput = (dateRange) => {
    setDateRange({
      startDate: moment(dateRange.startDate).format("yyyy-MM-DD"),
      endDate: moment(dateRange.endDate).format("yyyy-MM-DD")
    });
  };

  return (
      <>
        <div className="page-title">전체 성과</div>
        <div className="bg-white relative border border-gray-300 rounded-lg flex gap-4 mb-20 p-4 items-center" style={{marginTop: "35px"}}>
          <div>
            <TabBar
                items={dateItems}
                onChanged={(data) => handleQuickChange(data.value)}
            />
          </div>
          <CalendarBar initDate={{startDate: moment(dateRange.startDate).toDate(), endDate: moment(dateRange.endDate).toDate()}}
                       onConfirm={(props) => onDateInput(props)} />

        </div>
        <div className="flex gap-4 flex-col">
          <div>
            <h5 className="font-medium text-lg">전체 고객</h5>
          </div>
          <DateStatistics keyName={"customers"} cards={getCutomerCards()}>
            <div className="w-full flex border divide-x border-gray-300 divide-gray-300">
              <div className="w-1/2 p-4">
                <DateChart
                    keyName={"customer_date_chart"}
                    chartConfig={customerChart}
                />
              </div>
              <div className="w-1/2 flex gap-4 items-center p-4">
                <div className="w-1/2">
                  <DateChart
                      keyName={"customer_pie_date_chart"}
                      chartConfig={customerPieChart}
                  />
                </div>
                <div className="w-1/2 h-full flex items-center">
                  <ul
                      className="w-full bg-white rounded-lg divide-y overflow-y-auto"
                      style={{ maxHeight: "360px" }}
                  >
                    {Object.entries(customerPieLabels).map(
                        ([name, count], index) => {
                          const matchColor =
                              customerPieChart.options.colors[index] || "#999999";
                          return (
                              <li
                                  className="py-2 px-4 text-xs flex justify-between text-gray-500"
                                  key={"customer-pie-label" + index}
                              >
                                <div className="flex gap-2">
                                  <div
                                      className="rounded-full w-4 h-4"
                                      style={{
                                        backgroundColor: matchColor,
                                      }}
                                  ></div>
                                  {name}
                                </div>
                                <div>
                                  {count}명 (
                                  {Math.round(
                                      (count /
                                          customerPieChart.series.reduce(
                                              (a, b) => b + a,
                                              0
                                          )) *
                                      1000
                                  ) / 10}
                                  %)
                                </div>
                              </li>
                          );
                        }
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </DateStatistics>
          <div className="mt-10">
            <h5 className="font-medium text-lg">전체 혜택</h5>
          </div>
          <div className="mb-20">
            <DateStatistics keyName={"benefit"} cards={getBenefitCards()}>
              <DateChart
                  keyName={"benefit_date_chart"}
                  chartConfig={benefitChart}
              />
            </DateStatistics>
          </div>
        </div>
      </>
  );
};
export default AllSpotsPage;
