import { useState } from "react";
import CashWrap from "../layout/CashWrap";
import BillWrap from "../layout/BillWrap";

const tabs = [
  //{ value: "cash", label: "캐시 조회" },
  { value: "bill", label: "월 별 청구서" },
];
const Payments = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  return (
    <div className="w-[1231px]">
      <ul className="flex border-gray-300 border-b-2 gap-8 px-4 items-center w-full">
        {tabs.map((tab, index) => {
          return (
            <li
              key={"payments-tab" + index}
              className={
                "py-4 px-4 cursor-pointer " +
                (activeTab === tab.value ? "border-b-4 border-indigo-500" : "")
              }
              onClick={() => setActiveTab(tab.value)}
            >
              {tab.label}
            </li>
          );
        })}
      </ul>
      {activeTab === "cash" && <CashWrap />}
      {activeTab === "bill" && <BillWrap />}
    </div>
  );
};

export default Payments;
