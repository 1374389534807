import { useState } from "react";
import { getUserInfo, loginUser } from "../api/auth/auth.api";
import { SET_TOKEN } from "../store/auth";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshToken } from "../storage/cookie";
import { useSetRecoilState } from "recoil";
import { userState } from "../store/state/user";
import ActiveButton from "../components/ActiveButton";
import OMOPlayLogo from "../assets/images/omoplay_logo.svg";

const LoginPage = () => {
  const data = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const setUserInfo = useSetRecoilState(userState);

  const doConfirm = async () => {
    setLoading(true);
    const res = await loginUser({
      username: username,
      password: password,
    });
    if (res.status === 200) {
      dispatch(SET_TOKEN(res.data));
      setRefreshToken(res.data.data);
      saveUserInfo(res.data.data);
    } else {
      alert("로그인에 실패하였습니다. 계정 정보를 다시 확인해주세요");
      setLoading(false);
      console.log(res.data);
    }
  };

  const saveUserInfo = async (token) => {
    const userInfo = await getUserInfo(token);
    const body = userInfo.data;
    if (body.code === 2000) {
      setUserInfo(body.data)
      if (!body.data.kakaoProfileKey) {
        return window.location.href = ("/account");
      } else {
        return window.location.href = ("/");
      }
    } else {
      alert(body.message);
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      doConfirm();
    }
  };
  return (
    <div className="w-full h-screen flex justify-center items-center bg-gray-800">
      <div>
        <div className="flex justify-center" style={{marginBottom: "20px"}}>
          <img src={OMOPlayLogo} width="272px" />
        </div>
        <div className="text-white text-center" style={{marginBottom: "43px"}}>NO.1 온오프라인 브랜드사 고객 마케팅 서비스</div>
        <div className="bg-white shadow-lg rounded px-8 pt-6 pb-8 mb-4 max-w-md flex-col w-96 border border-gray-300">
          <div className="mb-4">
            <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="username"
            >
              아이디
            </label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="아이디를 입력해 주세요."
                onChange={(e) => setUsername(e.target.value)}
                value={username}
            />
          </div>
          <div className="mb-6">
            <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
            >
              비밀번호
            </label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="비밀번호를 입력해 주세요."
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
                value={password}
            />
          </div>
          <ActiveButton
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => doConfirm()}
              className="py-2"
          >
            로그인
          </ActiveButton>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
