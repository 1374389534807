import flagIcon from "../assets/images/flag_icon.svg";


const DescriptionBar = ({description}) => {
    return (
        <div className="description-bar">
            {
                description && (
                    <div>
                        <div><img src={flagIcon} /></div>
                        <div>{description}</div>
                    </div>
                )
            }
        </div>
    )
}

export default DescriptionBar