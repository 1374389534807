import PreviewTemplate from "./PreviewTemplate";
import DefaultTypeContent from "./DefaultTypeContent";
import WideTypeContent from "./WideTypeContent";
import WideListTypeContent from "./WideListTypeContent";
import CarouselTypeContent from "./CarouselTypeContent";

const MessagePreview = ({ messageData, type, fixed }) => {
  const renderForType = () => {
    if (type === "FI" || type === "FT") {
      return <DefaultTypeContent data={messageData} />;
    } else if (type === "FW") {
      return <WideTypeContent data={messageData} />;
    } else if (type === "FL") {
      return <WideListTypeContent data={messageData} />;
    } else if (type === "FC") {
      return <CarouselTypeContent data={messageData} />;
    }
  };

  return (
    <PreviewTemplate isAd={messageData.adFlag ?? "Y"} fixed={fixed}>{renderForType()}</PreviewTemplate>
  );
};

export default MessagePreview;
