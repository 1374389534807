import { useCallback, useEffect, useState } from "react";
import {createSpot, deleteSpot, getSpots, updateSpot} from "../../api/spot/spot.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import NormalButton from "../../components/NormalButton";
import QREditForm from "../form/QREditForm";
import SuspensePopup from "../../components/SuspensePopup";
import EventPreview from "../form/event/EventPreview";
import Tooltip from "../Tooltip";
import EntryPointPreviewPopup from "../form/entrypoint/EntryPointPreviewPopup";
import {Table, Pagination, Button} from "omoplay";
import moment from "moment";
import MiniMenu from "../form/MiniMenu";
import DescriptionBar from "../DescriptionBar";
import Popup from "../Popup";

const QRList = () => {
  const [editingSpot, setEditingSpot] = useState(null);
  const [isCreatePopup, setCreatePopup] = useState(false);
  const [page, setPage] = useState(1);
  const [spots, setSpots] = useState([]);
  const [totalPage, setTotalPages] = useState(0);
  const [entryPoint, setEntryPoint] = useState(false);
  const [spotId, setSpotId] = useState();
  const [previewPopup, setPreviewPopup] = useState();
  const [benefit, setBenefit] = useState();
  const [tableData, setTableData] = useState([])
  const [openMenuId, setOpenMenuId] = useState(null)
  const [deleteId, setDeleteId] = useState(undefined)

  useEffect(() => {
    getSpots("", "", page - 1, 10, "createdAt,desc")
      .then((res) => {
        if (res.data) {
          const spots = res.data.content
          setSpots(spots)
          setTotalPages(res.data.totalElements);
        }
    })
  }, [page])

  useEffect(() => {
    setTableData(spots.map((spot) => {
      return [
          spot.spotId,
        spot.name,
          spot.spotUid,
        moment(spot.createdAt).add(9, "hours").format("YYYY.MM.DD HH:mm"),
        spot.isError ? (
            <div className="relative">
              <Tooltip
                  top={"top-8"}
                  message={"삭제된 혜택이 연결되어 있습니다."}
              >
                <div className="text-red-500 py-2 rounded">
                  {spot.benefit?.name || "-"}{" "}
                  <FontAwesomeIcon
                      icon={faExclamationCircle}
                      size={"lg"}
                      className="opacity-80 mr-0.5"
                  />
                </div>
              </Tooltip>
            </div>
        ) : (
            spot.projectNames?.map(name => {
              return (
                  <div>{name}</div>
              )
            })
        ),
        <div className="flex justify-center">
          <NormalButton
              variant={"outlined"}
              onClick={() => {
                setEntryPoint(true)
                setSpotId(spot.spotId)
              }}
          >
            보기
          </NormalButton>
        </div>,
        <div className="flex justify-center">
          <MiniMenu
              id={spot.spotId}
              openMenuId={openMenuId}
              setOpenMenuId={setOpenMenuId}
              onClickEdit={() => setEditingSpot(spots.find((_spot) => _spot.spotId === spot.spotId))}
              onClickDelete={() => setDeleteId(spot.spotId)}
              disabledDelete={spot.used}
          />
        </div>
      ]
    }))
  }, [openMenuId, spots]);

  const handleConfirmEdit = (name, folderId, benefitId) => {
    const spot = { ...editingSpot };
    setEditingSpot(null);
    handleEditConfirm(spot, name, folderId, benefitId).then(() => {
      window.location.reload()
    })
  }

  const handleEditConfirm = useCallback(
    async (spot, name, folderId, benefitId) => {
      await updateSpot(
        spot.spotId,
        spot.active,
        name,
        folderId,
        benefitId
      )
    }
  );

  const handleDelete = () => {
    setDeleteId(undefined)
    deleteSpot(deleteId).then(() => {
      window.location.reload()
    })
  }

  const handleConfirmCreate = (name, folderId, benefitId) => {
    if (!name) {
      alert("필수 값을 입력해 주세요.")
      return
    }
    handleCreateSpot(name, folderId, benefitId);
  };

  const handleCreateSpot = useCallback(
    async (name, folderId, benefitId) => {
      const res = await createSpot(name, folderId, benefitId);
      if (res) {
        setCreatePopup(null);
        window.location.reload()
      } else {
        alert("오류가 발생했습니다. 잠시후 다시 시도해주세요.");
      }
    }
  );

  useEffect(() => {
    if (!isCreatePopup) {
      setBenefit(null);
    }
  }, [isCreatePopup]);

  useEffect(() => {
    if (editingSpot == null) {
      setBenefit(null);
    }
  }, [editingSpot]);

  const headers = [
    { name: "ID", width: "60px" },
    { name: "고객 진입점 (QR)" },
    { name: "ID" },
    { name: "생성일", sortable: true, width: "166px" },
    { name: "연결된 프로젝트", sortable: true },
    { name: "진입점", align: "center", width: "100px" },
    { name: "", align: "center", width: "80px" },
  ]

  return (
    <>
      <div className="page-title">고객 진입점</div>
      <div className="flex gap-4 w-100 items-center justify-between">
        <div style={{flexGrow: "1"}}>
          <DescriptionBar description="고객 진입점은 고객들이 프로젝트(이벤트)에 참여하기 위해 접하게 되는 접점으로 ‘URL링크'와 ‘QR’이 제공됩니다." />
        </div>
        <div>
          <Button variants="solid"
                  onClick={() => setCreatePopup(true)}
                  value="+ 진입점 추가"
          />
        </div>
      </div>
      <div className="flex-col gap-2">
        <Table headers={headers} data={tableData} />
        <div className="flex justify-center">
          <Pagination rowSize={10} totalSize={totalPage} page={page} onPaging={(page) => setPage(page)} />
        </div>
      </div>
      {isCreatePopup && (
        <QREditForm
          onClose={() => setCreatePopup(false)}
          benefit={benefit}
          onConfirm={handleConfirmCreate}
        />
      )}
      {editingSpot ? (
        <QREditForm
          data={editingSpot}
          onClose={() => setEditingSpot(null)}
          benefit={benefit}
          onConfirm={handleConfirmEdit}
        />
      ) : <></>}
      {previewPopup && (
        <SuspensePopup
          title={"미리보기"}
          size={"w-full max-w-xl"}
          onClose={() => setPreviewPopup(null)}
        >
          <EventPreview id={previewPopup} />
        </SuspensePopup>
      )}
      {deleteId && (
          <Popup
              title={"정말 삭제하시겠습니까?"}
              buttons={[
                { label: "취소", onClick: () => setDeleteId(undefined) },
                { label: "확인", type: "info", onClick: () => handleDelete() },
              ]}
          />
      )}
      {entryPoint && (
          <SuspensePopup
              title={"고객 진입점 방식"}
              size={"w-[522px]"}
              onClose={() => setEntryPoint(null)}
          >
            <EntryPointPreviewPopup spotId={spotId} />
          </SuspensePopup>
      )}
    </>
  );
};
export default QRList;
