import InputGroup from "../form/InputGroup";
import { MESSAGE_TYPES } from "../../constants/menus";
import {RadioGroup, TextInput} from "omoplay";
import TypeSelectionForm from "../form/TypeSelectionForm";
import KakaoIcon from "../../assets/images/kakao_icon.svg"

export const MessageDefaultInfo = ({campaignName, type, onChangeCampaignName, onChangeMessageType}) => {

  return (
    <>
      <div className="flex-col gap-1">
        <InputGroup label="메시지 캠페인명" description="다른 메시지들과 구분하기 위한 캠페인명을 작성해 주세요.">
          <TextInput
            value={campaignName}
            placeholder="캠페인명을 입력해 주세요."
            maxLength={40}
            onChange={(e) => onChangeCampaignName(e.target.value)}
          />
        </InputGroup>
        <InputGroup label="메시지 타입" description="어떤 메시지를 보낼지 골라주세요">
          <RadioGroup items={[{label: (<div className="flex-align-center gap-2"><img src={KakaoIcon} alt="ft" /><div>친구톡</div></div>), value: "ft"}]} onSelected={() => {}} />
        </InputGroup>
        <InputGroup
          label="친구톡 타입 고르기"
          description="어떤 메시지를 보내실지 골라주세요."
        >
          <TypeSelectionForm types={MESSAGE_TYPES} selectedType={type === "FT" ? "FI" : type} onChangeType={onChangeMessageType} />
        </InputGroup>
      </div>
    </>
  );
};

export default MessageDefaultInfo;
