import {CalendarBar, TabBar} from "omoplay";
import moment from "moment";
import {useState} from "react";
import {getDefaultDateRange} from "../libs/helper";


const ProjectCalendarSearchBar = ({periodStartDate, onConfirm}) => {
    const dateItems = [
        { value: "all", label: "전체" },
        { value: "month", label: "최근 한 달" },
        { value: "week", label: "최근 일주일", selected: true },
    ]

    const [dateRange, setDateRange] = useState(getDefaultDateRange());

    const handleQuickChange = (type) => {
        const now = moment().format("YYYY-MM-DD");
        let convertDateRage = dateRange

        if (type === "all") {
            convertDateRage = {
                startDate: moment(periodStartDate ?? "2023-12-01", "YYYY-MM-DD").format("YYYY-MM-DD"),
                endDate: now,
            }
        } else if (type === "month") {
            convertDateRage = {
                startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
                endDate: now,
            }
        } else if (type === "week") {
            convertDateRage = {
                startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
                endDate: now,
            }
        }
        setDateRange(convertDateRage)
        onConfirm(convertDateRage)
    }

    return (
        <div className="bg-white relative border border-gray-300 rounded-lg flex gap-4 p-4 items-center" style={{marginBottom: "15px"}}>
            <div style={{marginRight: "50px"}}>
                프로젝트 기간
            </div>
            <div>
                <TabBar
                    items={dateItems}
                    onChanged={(data) => handleQuickChange(data.value)}
                />
            </div>
            <CalendarBar initDate={{startDate: moment(dateRange.startDate).toDate(), endDate: moment(dateRange.endDate).toDate()}}
                         onConfirm={(dateProps) => {
                             const convertDateRange = {
                                 startDate: moment(dateProps.startDate).format("yyyy-MM-DD"),
                                 endDate: moment(dateProps.endDate).format("yyyy-MM-DD")
                             }
                             setDateRange(convertDateRange)
                             onConfirm(convertDateRange)
                         }} />
        </div>
    )
}

export default ProjectCalendarSearchBar