import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const getBillInfo = async (startDate, endDate) => {
  const searchParam = new URLSearchParams({
    startDate,
    endDate,
  });
  const res = await fetchApi.get(
    API_URL.GET_BILL + "?" + searchParam.toString()
  );
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};

export const getBillDetails = async (
  page = 0,
  size = 9999,
  startDate,
  endDate,
  type
) => {
  const searchParam = new URLSearchParams({
    page,
    size,
    startDate,
    endDate,
    type,
  });
  const res = await fetchApi.get(
    API_URL.GET_BILL_DETAILS + "?" + searchParam.toString()
  );
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};

export const getCardInfoList = async () => {
  const res = await fetchApi.get(API_URL.CARD_INFO_LIST)
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const getCardInfo = async (id) => {
  const res = await fetchApi.get(API_URL.CARD_INFO + `?cardId=${id}`)
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const registrationCardBill = async (cardNumber, cardExpire, cardType, birth, pwd, isDefault) => {
  const res = await fetchApi.post(API_URL.PAYMENT_BILL,
      {
        cardNumber: cardNumber,
        cardExpire: cardExpire,
        cardType: cardType,
        birth: birth,
        pwd: pwd,
        isDefault: isDefault
      })
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const pay = async (creditType, cardId, amount) => {
  const res = await fetchApi.post(`${API_URL.PAYMENT_PAY}?creditType=${creditType}&cardId=${cardId}&amount=${amount}`)
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const refund = async (historyId, amount) => {
  const res = await fetchApi.post(`${API_URL.PAYMENT_REFUND}?historyId=${historyId}&amount=${amount}`)
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}