import React, {useRef, useState} from "react";

const ImageSelectListForm = ({productInfo, onSelectProductInfo}) => {
    const imageScrollRef = useRef(null);
    const [isDrag, setIsDrag] = useState(false);
    const [startX, setStartX] = useState(0);
    const [selectIndex, setSelectIndex] = useState(0)

    const onDragStart = (e, ref) => {
        e.preventDefault();
        if (ref.current) {
            setIsDrag(true);
            setStartX(e.pageX + ref.current.scrollLeft);
        }
    };

    const onDragEnd = () => {
        setIsDrag(false);
    };

    const onDragMove = (e, ref) => {
        if (isDrag && ref.current) {
            ref.current.scrollLeft = startX - e.pageX;
        }
    };

    return (
        <div className="flex gap-1 overflow-x-scroll"
             style={{height: "80px"}}
             onMouseDown={(e) => onDragStart(e, imageScrollRef)}
             onMouseMove={(e) => onDragMove(e, imageScrollRef)}
             onMouseUp={onDragEnd}
             onMouseLeave={onDragEnd}
             ref={imageScrollRef}
        >
            {
                productInfo?.images?.map((image, index) => {
                    return (
                        <img alt="parsing-image"
                             className={selectIndex === index ? "product-image-selected" : ""}
                             src={image}
                             style={{minWidth: "80px", minHeight: "80px"}}
                             onClick={() => {
                                 setSelectIndex(index)
                                 onSelectProductInfo({
                                     ...productInfo,
                                     imageUrl: image
                                 })
                             }}
                        />
                    )
                })
            }
        </div>
    )
}

export default ImageSelectListForm