import {fetchApi} from "../fetch";
import {API_URL} from "../../constants/axios";

export const getCustomersStatistics = async (startDate, endDate, projectId, entryPointId) => {
    const params = new URLSearchParams({
        startDate,
        endDate,
    });
    if (projectId) params.append("projectId", projectId);
    if (entryPointId) params.append("entryPointId", entryPointId);

    const res = await fetchApi.get(`${API_URL.STATISTICS_CUSTOMERS}?${params.toString()}`)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const getSpotStatistics = async (projectId, spotId, startDate, endDate) => {
    const res = await fetchApi.get(
        `${API_URL.STATISTICS_SPOT}?projectId=${projectId}${spotId ? `&spotId=${spotId}` : ''}&startDate=${startDate}&endDate=${endDate}`);
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}