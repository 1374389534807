import {Button, RemoveTab, Table} from "omoplay";
import SuspensePopup from "../../SuspensePopup";
import EventSimpleList from "./EventSimpleList";
import {useEffect, useState} from "react";
import {
    deleteProjectDuplicate,
    deleteProjectEntryPoint,
    deleteProjectEvent, getDetailDuplicateProjects,
    getDetailEventsToProject, postProjectDuplicates,
    postProjectEntryPoint,
    postProjectEvent
} from "../../../api/project/project.api";
import GrayButton from "../../GrayButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import EntrypointSimpleList from "./EntrypointSimpleList";
import ProjectDetailPeriod from "./ProjectDetailPeriod";
import ProjectDetailBenefitPassword from "./ProjectDetailBenefitPassword";
import ProjectDetailDuplicate from "./ProjectDetailDuplicate";
import EntryPointPreviewPopup from "../entrypoint/EntryPointPreviewPopup";
import BenefitPreview from "../../BenefitPreview";
import InputGroup from "../InputGroup";


const ProjectDetailSummary = ({projectId, project, benefitPassword}) => {
    const eventHeaders = [
        { name: "이벤트명" }, { name: "혜택", unsetPadding: true }, { name: "진입점", unsetPadding: true },
        { name: "삭제", align: "center", width: "100px" }
    ]
    const [showEvents, setShowEvents] = useState(false)
    const [getEventIdAndShowEntryPoints, setGetEventIdAndShowEntryPoints] = useState(false)
    const [selectedEvents, setSelectedEvents] = useState([])
    const [renderEvents, setRenderEvents] = useState([])
    const [isEventChanged, setEventChanged] = useState(false)
    const [duplicateProjects, setDuplicateProjects] = useState([])
    const [isDuplicatesChanged, setDuplicatesChanged] = useState(false)
    const [allEntryPoints, setAllEntryPoints] = useState([])
    const [showEntryPointAndGetId, setShowEntryPointAndGetId] = useState()
    const [showPreviewPopupAndGetId, setShowPreviewPopupAndGetId] = useState(undefined);

    useEffect(() => {
        getDetailEventsToProject(projectId).then(({data}) => {
            const events = data.events
            setSelectedEvents(events)
            setRenderEvents(events.map(event => {
                setAllEntryPoints([...allEntryPoints, event.entryPoints])
                return [
                    <div className="flex-align-center gap-2">
                        <div>{event.name}</div>
                    </div>,
                    <div className="child-full" style={{borderBottom: "unset"}}>
                        <div>
                            {
                                event.benefits.map(benefit => {
                                    return (
                                        <div className={`table-column-border${benefit.id ? " pointer underline" : ""}`}
                                        onClick={() => {
                                            setShowPreviewPopupAndGetId(benefit.id)
                                        }}>
                                            {benefit.name} ({benefit.winningPercent}%)
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>,
                    <div className="child-full">
                        <div className="w-full">
                            {
                                event.entryPoints.map(entryPoint => {
                                    return (
                                        <div className="flex-align-center justify-between table-column-border">
                                            <div className="pointer underline"
                                                 onClick={() => setShowEntryPointAndGetId(entryPoint.id)}>
                                                {entryPoint.name}
                                            </div>
                                            <div>
                                                {
                                                    !project.disabledDelete ? (
                                                        <GrayButton className="px-1.5"
                                                                    onClick={() => entryPointDelete(event.id, entryPoint.id)}>
                                                            <FontAwesomeIcon icon={faXmark} />
                                                        </GrayButton>
                                                    ) : <></>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="flex-center-all w-full" style={{height: "44px"}}
                             onClick={() => {
                                 setGetEventIdAndShowEntryPoints(event.id)
                             }}>
                            <div className="flex-center-all w-full pointer">
                                + 추가하기
                            </div>
                        </div>
                    </div>,
                    <div className="flex-center-all h-full">
                        {
                            !project.disabledDelete ? (
                                <div className="pointer" onClick={() => eventDelete(event.id)}>
                                    <RemoveTab />
                                </div>
                            ) : (<div></div>)
                        }
                    </div>
                ]
            }))
            setEventChanged(false)
        })
    }, [project.disabledDelete, projectId, isEventChanged]);

    useEffect(() => {
        getDetailDuplicateProjects(projectId).then(({data}) => {
            setDuplicateProjects(data.duplicateProjects)
            setDuplicatesChanged(false)
        })
    }, [projectId, isDuplicatesChanged])

    const eventSave = (eventId) => {
        postProjectEvent(projectId, eventId).then(() => {
            setEventChanged(true)
        })
    }

    const eventDelete = (eventId) => {
        if (window.confirm("이벤트를 삭제하시겠습니까?")) {
            deleteProjectEvent(projectId, eventId).then(() => {
                setEventChanged(true)
            })
        }
    }
    const entryPointSave = (eventId, entryPointIds) => {
        postProjectEntryPoint(projectId, eventId, entryPointIds).then(() => {
            setEventChanged(true)
        })
    }
    const entryPointDelete = (eventId, entryPointId) => {
        deleteProjectEntryPoint(projectId, eventId, entryPointId).then(() => {
            setEventChanged(true)
        })
    }
    const duplicatesSave = (duplicateProjects) => {
        postProjectDuplicates(projectId, duplicateProjects).then(() => {
            setDuplicatesChanged(true)
        })
    }
    const duplicatesDelete = (duplicateProject) => {
        deleteProjectDuplicate(projectId, duplicateProject).then(() => {
            setDuplicatesChanged(true)
        })
    }

    return (
        <div className="flex-col gap-2">
            <InputGroup
                label="첫방문 이벤트 설정"
                description="아래 이벤트들은 중복 참여가 불가능합니다."
            >
                <div className="flex-col gap-2">
                    <div className="flex justify-end">
                        <div>
                            <Button variants="dark" value="+ 첫방문 이벤트 연결" onClick={() => setShowEvents(true)} />
                        </div>
                    </div>
                    <div>
                        <Table headers={eventHeaders} data={renderEvents} emptyItem={
                            <Button variants="primary" value="+ 첫방문 이벤트 연결" onClick={() => setShowEvents(true)} />
                        } />
                    </div>
                </div>
            </InputGroup>
            <InputGroup label="첫방문 이벤트 수량 제한 설정">
                <div className="flex-col gap-4">
                    <div className="bg-grey-1 w-full flex-align-center p-20px" style={{borderRadius: "8px"}}>
                        <p className="text-sm">
                            수량 제한을 사용하고 싶으시면 ‘수량 설정' 탭에서 설정해 주세요.
                        </p>
                    </div>
                </div>
            </InputGroup>
            <ProjectDetailBenefitPassword projectId={projectId} benefitPassword={benefitPassword} />
            <ProjectDetailPeriod projectId={projectId} initStartDate={project.startDate}
                                 initEndDate={project.endDate} disabledEdit={project.disabledDelete} isEdit={true}
            />
            <ProjectDetailDuplicate projectId={projectId} duplicateProjects={duplicateProjects}
                                    onChange={(duplicateProjects) => duplicatesSave(duplicateProjects)}
                                    onDelete={(duplicateProject) => duplicatesDelete(duplicateProject)}
            />
            <SuspensePopup
                title={"이벤트 추가하기"}
                visible={showEvents}
                style={{width: "1200px"}}
                onClose={() => setShowEvents(false)}
            >
                <EventSimpleList excludeEventIds={selectedEvents.map(event => event.id)}
                                 onSelected={(event) => {
                                     eventSave(event.id)
                                     setShowEvents(false)
                                 }}
                />
            </SuspensePopup>
            <SuspensePopup
                title={"진입점 추가하기"}
                visible={getEventIdAndShowEntryPoints}
                style={{width: "1200px"}}
                onClose={() => setGetEventIdAndShowEntryPoints(undefined)}
            >
                <EntrypointSimpleList selectedEntrypointIds={allEntryPoints.flatMap(entryPoints => entryPoints.map(entryPoint => entryPoint.id))}
                                      onSelected={(entrypointList) => {
                                          entryPointSave(getEventIdAndShowEntryPoints, entrypointList.map(entryPoint => entryPoint.id))
                                          setGetEventIdAndShowEntryPoints(undefined)
                                      }} />
            </SuspensePopup>
            <SuspensePopup
                title={"고객 진입점 방식"}
                visible={showEntryPointAndGetId}
                onClose={() => setShowEntryPointAndGetId(undefined)}
            >
                <EntryPointPreviewPopup spotId={showEntryPointAndGetId} />
            </SuspensePopup>
            <SuspensePopup
                title={"미리보기"}
                visible={showPreviewPopupAndGetId}
                onClose={() => setShowPreviewPopupAndGetId(undefined)}
            >
                <BenefitPreview id={showPreviewPopupAndGetId} />
            </SuspensePopup>
        </div>
    )
}

export default ProjectDetailSummary