import {Button} from "omoplay";
import ProjectDuplicateLink from "./ProjectDuplicateLink";
import {useState} from "react";
import InputGroup from "../InputGroup";
import SuspensePopup from "../../SuspensePopup";
import ProjectSimpleList from "./ProjectSimpleList";

const ProjectDetailDuplicate = ({projectId, duplicateProjects, onChange, onDelete}) => {
    const [showDuplicateProject, setShowDuplicateProject] = useState(false)

    return (
        <>
            <InputGroup
                label={"프로젝트 중복 불가 설정"}
                description="프로젝트 끼리는 중복참여가 가능하나, 해당 기능을 통해 중복 참여 불가 설정을 하실 수 있습니다."
            >
                <div className="flex-col gap-2">
                    <div className="flex justify-between">
                        <div className="text-gray-500 text-sm mb-2">
                            총 <span className="text-primary font-semibold">{duplicateProjects.length}</span>개 프로젝트와는 중복으로 참여할 수 없습니다.
                        </div>
                        <div>
                            <Button variants="dark" value="+ 중복 불가 프로젝트 추가하기" onClick={() => setShowDuplicateProject(true)} />
                        </div>
                    </div>
                    <div>
                        {
                            duplicateProjects.map(duplicateProject => {
                                return (
                                    <ProjectDuplicateLink projectName={duplicateProject.name} onCancel={() => {
                                        onDelete(duplicateProject.id)
                                    }} />
                                )
                            })
                        }
                    </div>
                </div>
            </InputGroup>
            <SuspensePopup
                title={"중복 불가 프로젝트 추가하기"}
                visible={showDuplicateProject}
                style={{width: "1200px"}}
                onClose={() => setShowDuplicateProject(false)}
            >
                <ProjectSimpleList selectedProjectList={duplicateProjects} excludeProjectId={projectId} onSelected={(selectProjectList) => {
                    onChange(selectProjectList)
                    setShowDuplicateProject(false)
                }} />
            </SuspensePopup>
        </>
    )
}

export default ProjectDetailDuplicate