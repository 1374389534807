import React from "react";
import {ImageStatusCard} from "omoplay";

const MiniDefaultTypeContent = ({ data }) => {
    return (
        <div className="w-[64px] h-[64px]">
            <ImageStatusCard imageUrl={data.image.imgUrl} status="Done" />
        </div>
    );
};

export default MiniDefaultTypeContent;
