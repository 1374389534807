import {fetchApi} from "../fetch";
import {API_URL} from "../../constants/axios";

export const getCustomersStatistics = async (startDate, endDate, projectId, entryPointId) => {
    const params = new URLSearchParams({
        startDate,
        endDate,
    });
    if (projectId) params.append("projectId", projectId);
    if (entryPointId) params.append("entryPointId", entryPointId);

    const res = await fetchApi.get(`${API_URL.STATISTICS_CUSTOMERS}?${params.toString()}`)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const getSpotStatistics = async (projectId, spotId, startDate, endDate) => {
    const res = await fetchApi.get(
        `${API_URL.STATISTICS_SPOT}?projectId=${projectId}${spotId ? `&spotId=${spotId}` : ''}&startDate=${startDate}&endDate=${endDate}`);
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const getOrdersStatistics = async (userId, paymentStartDate, paymentEndDate, search, isOffline, page, limit) => {
    const params = new URLSearchParams()

    if (paymentStartDate) params.append("paymentStartDate", paymentStartDate)
    if (paymentEndDate) params.append("paymentEndDate", paymentEndDate)
    if (userId) params.append("userId", userId)
    if (search) params.append("search", search)
    if (isOffline) params.append("isOffline", isOffline)
    if (page) params.append("page", page)
    if (limit) params.append("limit", limit)

    const res = await fetchApi.get(`${API_URL.STATISTICS_ORDERS}?${params.toString()}`);
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}
export const getMessageOrdersStatistics = async (messageId, page, limit) => {
    const params = new URLSearchParams({
        messageId: messageId,
        page: page,
        limit: limit
    })

    const res = await fetchApi.get(`${API_URL.STATISTICS_ORDERS_MESSAGE}?${params.toString()}`);
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}