import { atom } from "recoil";

export const spotState = atom({
  key: "spots",
  default: [],
});

export const spotStatisticsState = atom({
  key: "spot_statistics",
  default: [],
});

export const folderState = atom({
  key: "folders",
  default: [],
});
