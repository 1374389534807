import SettingsLayout from "../layout/SettingsLayout";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import ProjectDefault from "../form/project/ProjectDefault";
import SettingsStepLayout from "../layout/SettingsStepLayout";
import {getProject, postProject} from "../../api/project/project.api";

const ProjectEdit = () => {
    const [query] = useSearchParams();
    const [project, setProject] = useState({ projectSettings: {} })
    const [isRequired, setRequired] = useState(false)

    const id = query.get("id")

    useEffect(() => {
        if (id) {
            getProject(id).then(({data}) => {
                setProject(data)
                setRequired(true)
            })
        }
    }, [id, query])

    const updateProject = (key, value) => {
        const newProject = { ...project }
        newProject[key] = value
        setProject(newProject)
    }

    const handleConfirm = async () => {
        postProject(project).then(({data}) => {
            alert("저장 되었습니다.")
            window.location.href = (`/project/detail?id=${data}`);
        })
    }

    const render = () => {
        return (
            <div className="setting-step-padding">
                <ProjectDefault project={project} onChangeProjectName={(name) => {
                    updateProject("name", name)
                    setRequired(name.length > 0)
                }}
                                onChangeStartDate={(startDate) => {
                                    updateProject("startDate", startDate.format("YYYY-MM-DD HH:mm"))
                                }}
                                onChangeEndDate={(endDate) => {
                                    updateProject("endDate", endDate?.format("YYYY-MM-DD HH:mm"))
                                }}
                                onChangeDuplicateProject={(projects) => {
                                    updateProject("duplicateProjects", projects)
                                }}
                />
            </div>
        )
    }

    return (
        <SettingsLayout
            title={"프로젝트 추가"}
            onConfirm={handleConfirm}
            isConfirm={isRequired}
            confirmText={"완료"}
            isCancel={true}
            onClose={() => window.location.href = ("/project")}
        >
            <SettingsStepLayout renderChild={render()} />
        </SettingsLayout>
    );
};

export default ProjectEdit;
