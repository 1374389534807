import {getProductDetail, saveProduct} from "../../api/product/product.api";
import React, {useEffect, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import InputGroup from "../form/InputGroup";
import {Button, RadioGroup, RemoveTab, TextInput} from "omoplay";
import NoImage from "../../assets/images/no-image.svg";
import SettingsLayout from "../layout/SettingsLayout";
import ImageForm from "../ImageForm";
import ImageSelectListForm from "../form/ImageSelectListForm";

const ProductEdit = () => {
    const [query] = useSearchParams()
    const id = query.get("id")

    const [productInfo, setProductInfo] = useState({})
    const [isDirect, setDirect] = useState(false)

    useEffect(() => {
        getProductDetail(id).then(({data}) => {
            const info = data.info
            setProductInfo(
                {
                    productName: info.productName,
                    productCode: info.productCode,
                    images: info.images,
                    imageUrl: info.images[0],
                    price: info.price,
                    originalPrice: info.originalPrice,
                    discountRate: info.discountRate,
                    options: info.options
                }
            )
        })
    }, [query]);

    const save = () => {
        saveProduct(id, productInfo).then(() => {
            window.location.href = `/product/detail?id=${id}`
        })
    }

    const onChangeOptions = (index, value) => {
        setProductInfo((prev) => {
            const changeOptions = [...prev.options]
            changeOptions[index] = value
            return {...prev, options: changeOptions}
        })
    }

    const onAddOptions = () => {
        setProductInfo((prev) => {
            const changeOptions = [...prev.options]
            changeOptions.push("")
            return {...prev, options: changeOptions}
        })
    }

    const onRemoveOptions = (index) => {
        setProductInfo((prev) => {
            const changeOptions = [...prev.options].filter((_, i) => i !== index)
            return {...prev, options: changeOptions}
        })
    }

    return (
        <SettingsLayout
            title="제품 상세 수정"
            confirmText="저장하기"
            onConfirm={save}
        >
            <InputGroup label="제품 상세 정보" description="제품에 대한 상세 설정을 해주세요.">
                <div className="flex gap-8 w-[960px]">
                    <div className="flex gap-8">
                        <div className="flex-col gap-1">
                            <div className="text-secondary font-bold">제품 대표 이미지</div>
                            <div>
                                <img alt="main-image" src={productInfo?.imageUrl}
                                      className="max-w-[216px] max-h-[306px] min-w-[216px] min-h-[306px]"/>
                            </div>
                        </div>
                        {/*
                        <div className="flex-col gap-1">
                            <div className="text-secondary font-bold">배경없는 이미지</div>
                            <div><img alt="no-image" src={NoImage} width={216} height={306} /></div>
                        </div>
                        */}
                    </div>
                    <div className="overflow-hidden">
                        <div className="flex-col gap-4">
                            <div className="flex-col gap-1">
                                <div className="font-semibold">제품 이름</div>
                                <TextInput value={productInfo?.productName}
                                           onChange={(e) => setProductInfo({...productInfo, productName: e.target.value})}
                                />
                            </div>
                            <div className="flex-col gap-1">
                                <div className="font-semibold">제품 코드/상품 코드</div>
                                <TextInput value={productInfo?.productCode}
                                           onChange={(e) => setProductInfo({...productInfo, productCode: e.target.value})}
                                />
                            </div>
                            <div className="flex-col gap-2">
                                <div className="font-semibold">제품 대표 이미지 선택</div>
                                <div>
                                    <RadioGroup items={[{label: "이미지 선택", value: "image"}, {label: "직접 업로드", value: "upload"}]}
                                                onSelected={(value) => {
                                                    setDirect(value === "upload")
                                                }} />
                                </div>
                                {
                                    isDirect ? (
                                        <ImageForm imageUrl={productInfo?.imageUrl} onChangeImageUrl={(url) =>
                                            setProductInfo({
                                                ...productInfo,
                                                imageUrl: url
                                            })} />
                                    ) : (
                                        <ImageSelectListForm productInfo={productInfo} onSelectProductInfo={setProductInfo} />
                                    )
                                }
                            </div>
                            <div className="flex-align-center gap-[8px]">
                                <div className="flex-col gap-1">
                                    <div className="font-semibold">현재 가격</div>
                                    <TextInput value={productInfo?.price}
                                               onChange={(e) =>
                                                   setProductInfo({...productInfo, price: e.target.value})}
                                    />
                                </div>
                                <div className="flex-col gap-1">
                                    <div className="font-semibold">정상 가격</div>
                                    <TextInput value={productInfo?.originalPrice}
                                               onChange={(e) =>
                                                   setProductInfo({...productInfo, originalPrice: e.target.value})}
                                    />
                                </div>
                                <div className="flex-col gap-1">
                                    <div className="font-semibold">할인율</div>
                                    <TextInput value={productInfo?.discountRate}
                                               onChange={(e) =>
                                                   setProductInfo({...productInfo, discountRate: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="flex-col gap-2">
                                <div className="font-semibold">제품 옵션</div>
                                {
                                    productInfo?.options?.map((option, index) => {
                                        return (
                                            <div className="flex-align-center gap-2">
                                                <div className="w-full">
                                                    <TextInput value={option}
                                                               onChange={(e) =>
                                                                   onChangeOptions(index, e.target.value)} />
                                                </div>
                                                <RemoveTab className="pointer" onClick={() => {
                                                    onRemoveOptions(index)
                                                }} />
                                            </div>
                                        )
                                    })
                                }
                                <div><Button variants="default" value="추가하기"
                                             style={{width: "100%"}}
                                             onClick={() => onAddOptions()}
                                /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </InputGroup>
        </SettingsLayout>
    );
};

export default ProductEdit;
