import CustomerChartsForm from "../statistics/CustomerChartsForm";
import ProjectCalendarSearchBar from "../ProjectCalendarSearchBar";
import {useEffect, useState} from "react";
import {getDefaultDateRange} from "../../libs/helper";
import {getCustomersStatistics} from "../../api/statistics/statistics.api";
import SpecificCustomerStatistics from "../page/SpecificCustomerStatistics";
import {Dropdown} from "omoplay";
import {getEntryPointsToProject} from "../../api/project/project.api";


const GraphStatistics = ({periodStartDate, projectId}) => {
    const [dateRange, setDateRange] = useState(getDefaultDateRange())
    const [customers, setCustomers] = useState([])
    const [entryPoints, setEntryPoints] = useState([])
    const [entryPointId, setEntryPointId] = useState(undefined)

    useEffect(() => {
        getCustomersStatistics(dateRange.startDate, dateRange.endDate, projectId, entryPointId).then(({data}) => {
            setCustomers(data)
        })
    }, [dateRange, projectId, entryPointId])

    useEffect(() => {
        if (projectId) {
            getEntryPointsToProject(projectId).then(({data}) => {
                setEntryPoints([{name: "전체"}, ...data.entryPoints])
            })
        }
    }, [projectId])

    return (
        <div className="flex-col gap-4">
            <div className="flex-col gap-2">
                <div className="font-bold font-20px">참여 고객 추이</div>
                <div className="font-medium font-16px">기간별 참여 고객 추이를 확인하실 수 있습니다</div>
                <div>
                    {
                        entryPoints.length > 0 ?
                            <Dropdown variants="default"
                                      style={{width: "200px"}}
                                      titleStyle={{backgroundColor: "white"}}
                                      items={entryPoints.map(spot => ({label: spot.name, value: spot.id}))}
                                      onChanged={(items) => {
                                          setEntryPointId(items[0].value)
                                      }} /> : <></>
                    }
                </div>
                <div>
                    <ProjectCalendarSearchBar
                        periodStartDate={periodStartDate}
                        onConfirm={(dateProps) => {
                            setDateRange(dateProps)
                        }} />
                </div>
            </div>
            <CustomerChartsForm customers={customers} />
            <SpecificCustomerStatistics customers={customers} />
        </div>
    )
}

export default GraphStatistics