import {
  Box,
  Typography,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import { useRef, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CarouselTypeContent = ({ data }) => {
    const scrollRef = useRef(null);
    const [isDrag, setIsDrag] = useState(false);
    const [startX, setStartX] = useState();

    const carousels = data.carousels ?? []
    const tail = data.tail ?? {}

  const onDragStart = (e) => {
    e.preventDefault();
    setIsDrag(true);
    setStartX(e.pageX + scrollRef.current.scrollLeft);
  };

  const onDragEnd = () => {
    setIsDrag(false);
  };

  const onDragMove = (e) => {
    if (isDrag) {
      scrollRef.current.scrollLeft = startX - e.pageX;
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          width: "312px",
          gap: 1,
          transition: ".2s",
          marginLeft: "-40px",
        }}
        className="hide-scrollbar"
        onMouseDown={onDragStart}
        onMouseMove={onDragMove}
        onMouseUp={onDragEnd}
        onMouseLeave={onDragEnd}
        ref={scrollRef}
      >
        <Box sx={{ width: "32px", flex: "0 0 auto" }}></Box>
        {carousels
            .filter((carousel) => carousel.attachment?.image != null)
            .map((carousel, index) => {
                const attachment = carousel.attachment ?? {}

                return (
                    <Box
                      className="message-content"
                      key={"preview-carousel-item" + index}
                    >
                        <Box
                            className="preview-image-wrap flex-align-center"
                            sx={{
                                width: "210px",
                                height: "280px",
                            }}
                        >
                            <img alt="" style={{width: "auto"}} src={attachment.image.imgUrl} />
                        </Box>
                        <Box className="default-message-content" style={{minHeight: "150px"}}>
                            <Typography fontSize={14}>{carousel.header}</Typography>
                            <Divider sx={{ mt: 1, mb: 2 }} />
                            <Typography
                              sx={{ whiteSpace: "break-spaces" }}
                              fontSize={12}
                            >
                              {carousel.message}
                            </Typography>
                        </Box>
                        <Box className="flex" style={{padding: "8px", gap: "5px"}}>
                        {attachment.buttons?.filter((button) => button.type === "WL" || button.type === "COUPON")
                            .map((button) => {
                                return (
                                    <Button
                                        className="preview-button"
                                        variant="outlined"
                                        color={"grey7"}
                                        onClick={() => window.open(button.urlMobile)}
                                    >
                                        {button.name || "메시지 버튼"}
                                    </Button>
                                );
                            })}
                        </Box>
                    </Box>
                );
            })}
          {
              tail.urlMobile && (
                  <Box
                      sx={{
                          width: "47px",
                          flex: "0 0 auto",
                          display: "flex",
                          alignItems: "center",
                      }}
                  >
                      <Box sx={{ textAlign: "center" }}>
                          <IconButton
                              onClick={() => window.open(tail.urlMobile)}
                              sx={{ backgroundColor: "#FFF" }}
                          >
                              <ArrowForwardIcon />
                          </IconButton>
                          <Typography fontSize={9} color={"grey7"} sx={{ mt: 0.5 }}>
                              더보기
                          </Typography>
                      </Box>
                  </Box>
              )
          }
      </Box>
      <Box sx={{ textAlign: "right" }}>
        <Typography fontSize={9} color={"grey7"} sx={{ mt: 1, mr: 2 }}>
          수신거부 | 홈 > 채널차단
        </Typography>
      </Box>
    </Box>
  );
};

export default CarouselTypeContent;
