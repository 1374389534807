import { DefaultValue, selector, selectorFamily } from "recoil";
import {
  getSpots,
  getFolders,
  getSpotStatistics,
} from "../../api/spot/spot.api";
import { folderState, spotState, spotStatisticsState } from "../state/spot";

export const spotSelector = selector({
  key: "spotSelector",
  get: async ({ get }) => {
    const res = await getSpots();
    if (res.data) {
      return res.data.content;
    }
    return get(spotState);
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(spotState);
    } else if (typeof newValue === "number") {
      set(spotState, newValue);
    }
  },
});

export const spotStatisticsSelector = selector({
  key: "spotStatisticsSelector",
  get: async ({ get }) => {
    const res = await getSpotStatistics();
    if (res.data) {
      return res.data;
    }
    return get(spotStatisticsState);
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(spotStatisticsState);
    } else if (typeof newValue === "number") {
      set(spotStatisticsState, newValue);
    }
  },
});

export const spotFamilySelector = selectorFamily({
  key: "spotFamilySelector",
  get:
    (param) =>
    async ({ get }) => {
      const [folderId, name, page, size, sort] = param.split("|");
      const res = await getSpots(folderId, name, page, size, sort);
      if (res.data) {
        return res.data;
      }
      return get(spotState);
    },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(spotState);
    } else if (typeof newValue === "number") {
      set(spotState, newValue);
    }
  },
});

export const folderSelector = selector({
  key: "folderSelector",
  get: async ({ get }) => {
    const res = await getFolders();
    if (res.data) {
      return res.data;
    }
    return get(folderState);
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(folderState);
    } else if (typeof newValue === "number") {
      set(folderState, newValue);
    }
  },
});
