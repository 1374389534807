import OMOPlayLogo from "../assets/images/omoplay_logo_black.svg";
import GuideIcon from "../assets/images/guide_icon.svg";
import {Table, TextInput, Button} from "omoplay";
import {getPartner, partnerAuth, partnerRegistration, putPartner} from "../api/partner/partner.api";
import {useEffect, useState} from "react";
import Accordion from "../components/Accordion";

const AccountSettingPage = () => {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [code, setCode] = useState("")
    const [authFlag, setAuthFlag] = useState(false)
    const [authCodeDisabled, setAuthCodeDisabled] = useState(true)
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [plusFriendName, setPlusFriendName] = useState()
    const [hostingName, setHostingName] = useState("")
    const [hostingId, setHostingId] = useState("")
    const [hostingPwd, setHostingPwd] = useState("")
    const [hostingSubmit, setHostingSubmit] = useState(false)

    const [open, setOpen] = useState("cafe24");

    useEffect(() => {
        getPartner().then(({data}) => {
            setPlusFriendName(data.kakaoChannelName)
            setOpen(data.kakaoChannelName ? "authentication" : "cafe24")
        })
    }, [])

    const auth = () => {
        setAuthCodeDisabled(false)
        partnerAuth(plusFriendName, phoneNumber)
            .then(({data}) => {
                if (data.code !== "200") {
                    alert(data.message)
                } else {
                    alert("인증번호가 전송되었습니다. 알림톡을 확인해 주세요.")
                }
            })
    }
    const registration = () => {
        partnerRegistration(plusFriendName, phoneNumber, code)
            .then(({data}) => {
                if (data.code !== "200") {
                    alert(data.message)
                } else {
                    alert("인증 되었습니다.")
                    setAuthCodeDisabled(true)
                    setAuthFlag(true)
                    setSubmitDisabled(false)
                }
            })
    }

    const submit = () => {
        if (authFlag) {
            window.location.replace("/")
        }
    }

    const updatePartner = () => {
        setHostingSubmit(true)
        putPartner(hostingName, hostingId, hostingPwd).then(() => {
            alert("제출 되었습니다")
            setOpen(open === "business" ? null : "business")
        })
    }

    return (
        <>
            <div className="flex justify-center items-start bg-gray-800" style={{ height: "100vh", paddingTop: "53px", overflow: "scroll"}}>
                <div className="border rounded-lg" style={{
                    backgroundColor: "rgb(255,255,255,0.32)",
                    padding: "30px 40px",
                    marginRight: "30px",
                    marginLeft: "-460px"
                }}>
                    <div>
                        <div className="text-white" style={{ width: "400px", marginBottom: "13px"}}>아래 항목들이 필요해요!</div>
                    </div>
                    <div>
                        <Table headers={[
                            {
                                name: "기본 항목",
                                sortable: false
                            },
                            {
                                name: "목적",
                                sortable: false
                            }
                        ]} data={[
                            ["카카오 비즈니스", "카카오 플러스 친구"],
                            ["카카오 디벨로퍼", "1초 로그인 (카카오 싱크)"],
                            ["기존 웹호스팅사", "1초 로그인 설치"]
                        ]} />
                    </div>
                </div>
                <div>
                    <div className="items-center">
                        <div className="border rounded-lg bg-white p-20" style={{width: "720px"}}>
                            <div className="mb-10">
                                <div className="mb-8" style={{width: "200px"}}>
                                    <img alt="" src={OMOPlayLogo} />
                                </div>
                                <div>
                                    <p className="text-gray-900 font-semibold text-lg mb-2">
                                        환영합니다! 아래 연동을 통해 계정을 활성화 해주세요
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="mb-5" style={plusFriendName ? {opacity: "0.5"} : {}}>
                                    <Accordion
                                        title={
                                        <div className="flex items-center">
                                            <div className="bg-black text-xs rounded-full h-4 w-4 text-center font-medium text-white">
                                                1
                                            </div>
                                            <div className="ml-2">카페24 계정을 알려주세요.</div>
                                        </div>
                                        }
                                        isOpen={open === "cafe24"}
                                        onClick={() => setOpen(open === "cafe24" ? null : "cafe24")}
                                    >
                                        <div style={{padding: "24px"}}>
                                            <div>웹호스팅사 (예 : 식스샵 및 카페24) 계정을 알려주세요.</div>
                                            <div>
                                                <div className="mb-2" style={{width: "100%"}}>
                                                    <TextInput placeholder="사용하는 웹호스팅사는 어디인가요?"
                                                               autoComplete="off"
                                                               id="hosting"
                                                               onKeyUp={(e) => {
                                                                   setHostingName(e.currentTarget.value)
                                                               }}
                                                               onKeyDown={(e) => {
                                                                   setHostingName(e.currentTarget.value)
                                                               }}/>
                                                </div>
                                                <div className="flex gap-2 mb-2" style={{width: "100%"}}>
                                                    <div style={{width: "100%"}}>
                                                        <TextInput placeholder="ID"
                                                                   autoComplete="off"
                                                                   id="hostingId"
                                                                   onKeyUp={(e) => {
                                                                       setHostingId(e.currentTarget.value)
                                                                   }}
                                                                   onKeyDown={(e) => {
                                                                       setHostingId(e.currentTarget.value)
                                                                   }} />
                                                    </div>
                                                    <div style={{width: "100%"}}>
                                                        <TextInput type="password" placeholder="PW"
                                                                   autoComplete="new-password"
                                                                   id="hostingPwd"
                                                                   onKeyUp={(e) => {
                                                                       setHostingPwd(e.currentTarget.value)
                                                                   }}
                                                                   onKeyDown={(e) => {
                                                                       setHostingPwd(e.currentTarget.value)
                                                                   }} />
                                                    </div>
                                                </div>
                                                <div className="text-red-500 mb-2 text-xs">
                                                    * 카카오 싱크 설정 및 약관 등록용으로만 수집되며 초기 설정 이후 즉시 파기됩니다.
                                                    <br />만약 계정을 알려주시기 불편하시면, 별도 제출 없이 아래 수동가이드를 따라 설정해 주세요.
                                                </div>
                                                <div className="flex justify-end gap-4">
                                                    <div>
                                                        <button className="rounded-lg bg-gray-100" style={{width: "165px", height: "42px"}}
                                                                onClick={() => {
                                                                    window.open("https://local-room-f00.notion.site/1132d9e7196a8142bd4cfc6e219ca3dc?pvs=4")
                                                                }}>
                                                            <div className="flex justify-center items-center">
                                                                <img alt="" src={GuideIcon} width="16px" height="16px" />
                                                                <div className="ml-2">수동 가이드 확인하기</div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <Button variants="solid" value="계정 제출" onClick={() => updatePartner()} disabled={hostingSubmit} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion>
                                </div>
                                <div className="mb-5" style={plusFriendName ? {opacity: "0.5"} : {}}>
                                    <Accordion
                                        title={
                                            <div className="flex items-center">
                                                <div className="bg-black text-xs rounded-full h-4 w-4 text-center font-medium text-white">
                                                    2
                                                </div>
                                                <div className="ml-2">카카오 비즈니스를 인증해 주세요.</div>
                                            </div>
                                        }
                                        isOpen={open === "business"}
                                        onClick={() => setOpen(open === "business" ? null : "business")}
                                    >
                                        <div style={{padding: "24px"}}>
                                            <div className="text-red-500 text-xs mb-5">
                                                * 이미 인증 했다면 생략
                                            </div>
                                            <div className="flex justify-end gap-2">
                                                <button className="rounded-lg bg-gray-100" style={{width: "165px", height: "42px"}}
                                                        onClick={() => {
                                                            window.open("https://local-room-f00.notion.site/1132d9e7196a81ceb0f8f719e50aa7dc?pvs=4")
                                                        }}>
                                                    <div className="flex justify-center items-center">
                                                        <img alt="" src={GuideIcon} width="16px" height="16px" />
                                                        <div className="ml-2">설정 가이드 확인하기</div>
                                                    </div>
                                                </button>
                                                <div>
                                                    <Button variants="solid" value="설정 가이드를 확인하여 진행했습니다." onClick={() => {
                                                        setOpen(open === "kakao" ? null : "kakao")
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion>
                                </div>
                                <div className="mb-10" style={plusFriendName ? {opacity: "0.5"} : {}}>
                                    <Accordion
                                        title={
                                            <div className="flex items-center">
                                                <div className="bg-black text-xs rounded-full h-4 w-4 text-center font-medium text-white">
                                                    3
                                                </div>
                                                <div className="ml-2">카카오 디벨로퍼의 애플리케이션 추가 및 채널 서비스의 관리자로 초대해 주세요.</div>
                                            </div>
                                        }
                                        isOpen={open === "kakao"}
                                        onClick={() => setOpen(open === "kakao" ? null : "kakao")}
                                    >
                                        <div style={{padding: "24px"}}>
                                            <div className="flex justify-end gap-2">
                                                <button className="rounded-lg bg-gray-100" style={{width: "165px", height: "42px"}}
                                                        onClick={() => {
                                                            window.open("https://local-room-f00.notion.site/1132d9e7196a81d39490fed719c110f7?pvs=4")
                                                        }}>
                                                    <div className="flex justify-center items-center">
                                                        <img alt="" src={GuideIcon} width="16px" height="16px" />
                                                        <div className="ml-2">설정 가이드 확인하기</div>
                                                    </div>
                                                </button>
                                                <div>
                                                    <Button variants="solid" value="설정 가이드를 확인하여 진행했습니다." onClick={() => {
                                                        alert("담당자가 확인하면 4번이 활성화 됩니다.\n잠시만 기다려주세요.")
                                                        setOpen(open === "authentication" ? null : "authentication")
                                                    }} />
                                                </div>
                                            </div>
                                            <p className="mt-5">
                                                해당 단계를 진행하시고, 오모플레이 담당자에게 초대 내역을 전달해 주시면<br/>
                                                팀원 요청을 수락하여 카카오 싱크를 직접 설정해 드립니다.
                                                <span className="font-bold" style={{color: "var(--mainblue-0)"}} >(1~3 영업일 소요)</span>
                                            </p>
                                        </div>
                                    </Accordion>
                                </div>
                                <div className="mb-2" style={{borderBottom: "4px dashed var(--grey-3)"}}></div>
                                <div className="flex justify-center items-center mb-10 font-bold rounded" style={{backgroundColor: "#F2F3FF", height: "42px"}}>
                                    <div className="mr-1">담당자 확인 후</div>
                                    <div className="bg-black text-xs rounded-full h-4 w-4 text-center font-medium text-white mr-1">
                                        4
                                    </div>
                                    <div>번이 활성화 됩니다</div>
                                </div>
                                <div className="mb-10" style={!plusFriendName ? {opacity: "0.5"} : {}}>
                                    <Accordion
                                        title={
                                            <div className="flex items-center">
                                                <div className="bg-black text-xs rounded-full h-4 w-4 text-center font-medium text-white">
                                                    4
                                                </div>
                                                <div className="ml-2">카카오 채널을 인증해 주세요.</div>
                                            </div>
                                        }
                                        isOpen={open === "authentication"}
                                        onClick={() => setOpen(open === "authentication" ? null : "authentication")}
                                    >
                                        <div style={{padding: "24px"}}>
                                            <div>
                                                <div className="mb-5">
                                                    <div className="mb-2">
                                                        <div>카카오톡채널 관리자 휴대폰 번호 <span className="text-red-500">*</span></div>
                                                        <div>
                                                            <button className="rounded-lg bg-gray-100" style={{width: "165px", height: "42px"}}
                                                                    onClick={() => {
                                                                        window.open("https://local-room-f00.notion.site/1132d9e7196a8161b713c87184417389?pvs=4")
                                                                    }}>
                                                                <div className="flex justify-center items-center">
                                                                    <img alt="" src={GuideIcon} width="16px" height="16px" />
                                                                    <div className="ml-2">설정 가이드 확인하기</div>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="mb-2">
                                                        <div className="flex items-center justify-between">
                                                            <div style={{width: "100%"}}>
                                                                <TextInput placeholder="01000000000"
                                                                           onKeyUp={(e) => {
                                                                               setPhoneNumber(e.currentTarget.value)
                                                                           }}
                                                                           onKeyDown={(e) => {
                                                                               setPhoneNumber(e.currentTarget.value)
                                                                           }}
                                                                           disabled={authFlag} />
                                                            </div>
                                                            <div style={{minWidth: "130px", marginLeft: "10px"}}>
                                                                <Button variants="solid" value="인증번호 받기" onClick={() => auth()} disabled={!plusFriendName} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center justify-between">
                                                        <div style={{width: "100%"}}>
                                                            <TextInput placeholder="인증번호"
                                                                       onKeyUp={(e) =>
                                                                           setCode(e.currentTarget.value)}
                                                                       disabled={authFlag} />
                                                        </div>
                                                        <div style={{minWidth: "130px", marginLeft: "10px"}}>
                                                            <Button variants="solid" value="인증 확인" disabled={authCodeDisabled}
                                                                    onClick={() => registration()} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion>
                                </div>
                                <div className="fixed flex justify-center items-center" style={{ bottom: 0, left: 0, width: "100%", height: "80px", backgroundColor: "rgba(255, 255, 255, 0.3)"}}>
                                    <Button variants="solid" value="계정 활성화"
                                            style={{width: "472px", height: "50px", flexWrap: "wrap"}}
                                            disabled={submitDisabled}
                                            onClick={() => submit()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AccountSettingPage;
