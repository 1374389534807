import DateStatistics from "../DateStatistics";
import {useEffect, useState} from "react";
import {
    basicBarOption,
    pieOption
} from "../../constants/chart_config";
import EChartsReact from "echarts-for-react";

const CustomerChartsForm = ({customers}) => {
    const [customerData, setCustomerData] = useState([]);
    const [entryPointData, setEntryPointData] = useState([]);
    const [customerCount, setCustomerCount] = useState(0)

    useEffect(() => {
        if (customers.dateGroups !== undefined) {
            const dateGroups = customers.dateGroups
            const entryPointGroups = customers.entryPointGroups

            let count = 0
            dateGroups.forEach(row => count += row.joinCount)
            setCustomerCount(count)

            setCustomerData({
                xAxis: dateGroups.map((row) => {
                    return row.createdAt
                }),
                series: dateGroups.map((row) => {
                    return row.joinCount
                })
            })

            setEntryPointData(entryPointGroups.map(entryPoint => {
                return {
                    name: entryPoint.name,
                    value: entryPoint.joinCount
                }
            }))
        }
    }, [customers])

    return (
        <DateStatistics keyName={"customers"} cards={[
            {
                label: "참여 고객",
                value: customerCount,
            },
        ]}>
            <div className="w-full flex border divide-x border-gray-300 divide-gray-300">
                <div className="w-1/2 p-4">
                    <EChartsReact option={basicBarOption(customerData.xAxis, customerData.series, "명")} />
                </div>
                <div className="w-1/2 flex gap-4 items-center p-4">
                    <div className="w-full">
                        <EChartsReact
                            option={pieOption("획득 고객", entryPointData, "명")}
                        />
                    </div>
                </div>
            </div>
        </DateStatistics>
    )
}

export default CustomerChartsForm