import {useEffect, useState} from "react";
import DescriptionBar from "../DescriptionBar";


const SettingsStepLayout = ({description, renderChild}) => {
    const [height, setHeight] = useState(window.innerHeight - 106 - 54 - 30)

    const updateHeight = () => {
        setHeight(window.innerHeight - 106 - 54 - 30);
    };
    useEffect(() => {
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    return (
        <>
            <DescriptionBar description={description} />
            <div className="settings-container" style={{ maxHeight: height, height: height}}>
                {
                    renderChild
                }
            </div>
        </>
    )
}

export default SettingsStepLayout