import Loading from "./Loading";

const ActiveButton = ({
  children = "",
  isLoading,
  type = "button",
  disabled = false,
  className = "py-1.5 px-4",
  onClick = () => {
    return "";
  },
}) => {
  return (
    <div className="flex items-center justify-between">
      <button
        className={
          "bg-mainblue-0 bg-mainblue-d1-hover text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full text-center disabled:bg-gray-400 transition " +
          className
        }
        type={type}
        disabled={disabled || isLoading}
        onClick={() => onClick()}
      >
        {isLoading ? (
          <div className="flex justify-center">
            <p className="text-white fill-indigo-500 m-auto">
              <Loading className="w-6 h-6" />
            </p>
          </div>
        ) : (
          children
        )}
      </button>
    </div>
  );
};
export default ActiveButton;
