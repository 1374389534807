import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEquals,
  faWonSign,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useMemo, useState } from "react";
import { MESSAGE_TARGET_FILTER } from "../../constants/menus";
import moment from "moment";
import { getMessagePrice, range } from "../../libs/helper";
import {cloneDeep, floor} from "lodash";
import SuspensePopup from "../SuspensePopup";
import {Button, CalendarBar, Chip, RadioGroup, Tab, Toggle} from "omoplay";
import {getExpectedCustomerCount} from "../../api/message/volatility-message.api";
import InputGroup from "../form/InputGroup";
import TargetMessageForm from "./TargetMessageForm";
import {getAllSpots} from "../../api/spot/spot.api";
import {getProjectList} from "../../api/project/project.api";
import {getCountExpectedFriend} from "../../api/moment/moment.api";

export const MessageTargetSetting = ({id, targetType, targetFilters, reservedDate, onChangeTargetType,
                                       onChangeTargetFilters, messageData, isSendMoment, onChangeCustomerCnt,
                                       onChangeReservedDate, onChangeAdult, selectUserIds, onChangeSelectUserIds,
                                       countAllFriend, onChangeSendFriend}) => {
  const [onActiveFilter, setActiveFilter] = useState([null, null, null]);
  const [testMessagePopup, setTestMessagePopup] = useState(false)
  const [customerCount, setCustomerCount] = useState(selectUserIds?.length ?? 0)
  const [filterTargetType, setFilterTargetType] = useState(targetType ?? "total")
  const [targetFilter, setTargetFilter] = useState(MESSAGE_TARGET_FILTER)
  const [countFriend, setCountFriend] = useState(countAllFriend)

  const type = messageData.type

  const convertFilter = (filters) => {
    return filters && cloneDeep(filters).map((filter) => {
      if (filter.category === "address") {
        filter.value = filter.label;
      } else if (filter.category === "createdWeek") {
        filter.value = filter.value.split("_")[1];
      }
      return {
        category: filter.category,
        value: filter.value,
        label: filter.label,
      };
    });
  }

  useEffect(() => {
    if (countAllFriend === 0) {
      getCountExpectedFriend().then(({data}) => {
        setCountFriend(data)
      })
    }
  }, [])

  useEffect(() => {
    if (filterTargetType !== "target") {
      getExpectedCustomerCount(id, filterTargetType, convertFilter(targetFilters)).then(({data}) => {
        setCustomerCount(data)
        onChangeCustomerCnt(data)
      })
    }
    if (filterTargetType === "filter") {
      getAllSpots().then(({data}) => {
        getProjectList().then((res) => {
          setTargetFilter(MESSAGE_TARGET_FILTER.map((filter) => {
                if (filter.value === "spot") {
                  return {
                    ...filter, data: data.map(value => ({value: value.id, label: value.name}))
                  }
                } else if (filter.value === "project") {
                  return {
                    ...filter, data: res.data.map(value => ({value: value.id, label: value.name}))
                  }
                } else {
                  return filter
                }
              }
          ))
        })
      })
    }
  }, [targetFilters, filterTargetType, targetType])

  const handleChangeFilter = (depth, value, isActive) => {
    if (isActive) {
      cancelItem(value, depth);
    } else {
      const updateValue = [...onActiveFilter];
      updateValue[depth - 1] = value;
      setActiveFilter(updateValue);
      if (depth > 1) {
        targetFilter.filter(
          (parent) => parent.value === updateValue[0]
        ).forEach((parent) => {
          parent.data
            .filter((item) => item.value === updateValue[1])
            .forEach((item) => {
              if (item.data && item.data.length > 0) {
                item.data
                  .filter((children) => children.value === updateValue[2])
                  .forEach((children) => {
                    addItem(children, item, parent);
                  });
              } else {
                addItem(item, parent);
              }
            });
        });
      }
    }
  };


  const cancelItem = (value, depth = null) => {
    const updateValue = [...targetFilters];
    const removeIndex = updateValue.findIndex((item) => item.value === value);
    if (removeIndex > -1) {
      updateValue.splice(removeIndex, 1);
      onChangeTargetFilters(convertFilter(updateValue));
    }

    if (depth != null && onActiveFilter[depth - 1] === value) {
      const updateValue = [...onActiveFilter];
      updateValue[depth - 1] = null;
      setActiveFilter(updateValue);
    }
  };

  const addItem = (children, item, parent = null) => {
    const updateValue = [...targetFilters];
    const existIndex = updateValue.findIndex(
      (item) => item.value === children.value
    );
    if (existIndex > -1) return;

    updateValue.push({
      category: parent ? parent.value : item.value,
      value: children.value,
      label: parent ? item.label + " " + children.label : children.label,
    });
    onChangeTargetFilters(convertFilter(updateValue));
  };

  const filterCategories = useMemo(() => {
    if (targetFilters) {
      return Array.from(new Set(targetFilters.map((item) => item.category)));
    }
  }, [targetFilters]);

  const handleCategoryClick = (value) => {
    const updateValue = [...onActiveFilter];
    updateValue[0] = value;
    setActiveFilter(updateValue);
  };

  const handleReserveDateChange = (type, value) => {
    const updateValue = { ...reservedDate }
    if (type === "date") {
      value.startDate = moment(value).format("YYYY-MM-DD")
      value.endDate = moment(value).format("YYYY-MM-DD")
    }
    updateValue[type] = value;
    onChangeReservedDate(updateValue)
  }


  return (
      <div className="flex-col gap-2">
        <InputGroup label="대상 조건 설정" description={(
            <div className="flex-align-center justify-between">
              <div>메시지 발송 대상의 조건을 설정해 주세요.</div>
              <Button variants="outlined" value="테스트 발송" onClick={() => setTestMessagePopup(true)} />
            </div>)}
        >
          <div className="flex-col gap-4">
            <label className="text-md font-semibold text-gray-900 relative">
              누구에게 발송하시겠습니까?
            </label>
            <Tab items={[{label: "전체 고객", value: "total", selected: filterTargetType === "total", style: {flex: 1}},
              {label: "조건 필터링 고객", value: "filter", selected: filterTargetType === "filter", style: {flex: 1}},
              {label: "대상 고객", value: "target", selected: filterTargetType === "target", style: {flex: 1}}]}
                 variants="sub"
                 onChanged={(item) => {
                   const value = item.value
                   if (value === "total") {
                     setTargetFilter([])
                     onChangeTargetFilters([])
                     setActiveFilter([])
                     onChangeSelectUserIds(null)
                   } else if (value === "filter") {
                     onChangeSelectUserIds(null)
                     onChangeSendFriend?.(0)
                   } else if (value === "target") {
                     setCustomerCount(selectUserIds?.length ?? 0)
                     onChangeSendFriend?.(0)
                   }
                   setFilterTargetType(value)
                   onChangeTargetType(value)
                 }}
            />
            <div className="flex-col gap-4">
              <div className="flex-col">
                {filterTargetType === "filter" && (
                    <div className="pt-[8px]">
                      <div className="border-gray-200 bg-gray-100 border">
                        <div className="flex divide-x border-gray-200">
                          <div className="w-1/3 h-full">
                            <ul className="flex-col gap-2 flex p-4 overflow-auto max-h-[298px]">
                              {MESSAGE_TARGET_FILTER.map((item, index) => {
                                let disabled = item.value === "" ? "disabled" : ""
                                const isActive =
                                    onActiveFilter[0] === item.value ||
                                    targetFilters.find((target) => target.value === item.value) !== undefined;

                                return (
                                    <Button variants={isActive ? "primary" : "outlined"} value={item.label}
                                            icon={<div></div>}
                                            onClick={() => {
                                              if (!disabled) {
                                                handleChangeFilter(1, item.value, isActive)
                                              }
                                            }} />
                                );
                              })}
                            </ul>
                          </div>
                          <div className="w-1/3 h-full">
                            <ul className="flex-col gap-2 flex p-4 overflow-auto max-h-[298px]">
                              {targetFilter.filter(
                                  (item) => item.value === onActiveFilter[0]
                              ).map((item) =>
                                  item.data.map((item, index) => {
                                    const isActive = onActiveFilter[1] === item.value ||
                                        targetFilters.find((target) => target.value === item.value) !== undefined;
                                    return (
                                        <Button variants={isActive ? "primary" : "outlined"} value={item.label}
                                                icon={<div></div>}
                                                onClick={() => {
                                                  handleChangeFilter(2, item.value, isActive)
                                                }} />
                                    );
                                  })
                              )}
                            </ul>
                          </div>
                          <div className="w-1/3 h-full">
                            <ul className="flex-col gap-2 flex p-4 overflow-auto max-h-[298px]">
                              {MESSAGE_TARGET_FILTER.filter(
                                  (item) => item.value === onActiveFilter[0]
                              ).map((item) =>
                                  item.data
                                      .filter((item) => item.value === onActiveFilter[1])
                                      .map((item) =>
                                          item.data?.map((item, index) => {
                                            const isActive = targetFilters.find((target) => target.label.includes(item.label)) !== undefined;
                                            return (
                                                <Button variants={isActive ? "primary" : "outlined"} value={item.label}
                                                        icon={<div></div>}
                                                        onClick={() => {
                                                          handleChangeFilter(3, item.value, isActive)
                                                        }} />
                                            );
                                          })
                                      )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="flex-col p-4 gap-4">
                        {filterCategories &&
                            filterCategories.map((value) => {
                              const categoryIndex = MESSAGE_TARGET_FILTER.findIndex(
                                  (parent) => parent.value === value
                              );
                              return (
                                  <div className="flex gap-4">
                                    <div className="flex-align-center font-semibold">
                                      {MESSAGE_TARGET_FILTER[categoryIndex].label}
                                    </div>
                                    <ul className="flex gap-4 flex-wrap">
                                      {targetFilters
                                          .filter((item) => item.category === value)
                                          .map((item, index) => {
                                            return (
                                                <Chip size="large" className="font-bold" closeable onClose={() => cancelItem(item.value)}>{item.label}</Chip>
                                            );
                                          })}
                                    </ul>
                                  </div>
                              );
                            })}
                      </div>
                    </div>
                )}
                {
                    filterTargetType === "target" && (
                        <TargetMessageForm id={id} selectUserIds={selectUserIds ?? []}
                                           onSelectedUserIds={(userIds) => {
                                             onChangeSelectUserIds(userIds)
                                             setCustomerCount([...new Set(userIds)].length)
                                             onChangeCustomerCnt([...new Set(userIds)].length)
                                          }} />
                    )
                }
                <div className="bg-gray-100 p-4 flex-col">
                  <div className="flex items-center gap-4">
                    <div className="rounded border border-gray-300 text-center w-[286px]">
                      <div className="text-gray-400 py-1.5 text-sm border-b border-gray-300">
                        예상 고객 수 (발송 건 수)
                      </div>
                      <div className="bg-white py-2 font-semibold text-center">
                        {customerCount.toLocaleString()}명 (건)
                      </div>
                    </div>
                    <div className="w-6 h-6 bg-white rounded flex justify-center items-center text-gray-500">
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                    <div className="rounded border border-gray-300 text-center w-[118px]">
                      <div className="text-gray-400 py-1.5 text-sm border-b border-gray-300">
                        메시지 단가
                      </div>
                      <div className="py-2 font-semibold">
                        {getMessagePrice(type)}원
                      </div>
                    </div>
                    <div className="w-6 h-6 bg-white rounded flex justify-center items-center text-gray-500">
                      <FontAwesomeIcon icon={faEquals} />
                    </div>
                    <div className="rounded border border-gray-300 text-center w-[326px]">
                      <div className="text-gray-400 py-1.5 text-sm border-b border-gray-300">
                        총 예상 발송 비용
                      </div>
                      <div className="bg-white py-2 font-semibold flex justify-center gap-2 text-indigo-600">
                        <>
                          <div className="bg-indigo-100 w-6 h-6 flex justify-center items-center  rounded">
                            <FontAwesomeIcon icon={faWonSign} size={"xs"} />
                          </div>
                          {floor(
                              customerCount *
                              getMessagePrice(type)
                          ).toLocaleString()}
                          원
                        </>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="text-gray-500 text-sm my-4 text-[12px]">
                      * ‘예상 고객 수’는 발송을 시도하는 고객 수를 의미하고, 실제 발송되는 고객 수와는 차이가 있을 수 있습니다.
                    </p>
                    <p className="text-gray-500 text-sm my-4 text-[12px]">
                      * 메시지 발송 등록 시 과금된 비용과 실발송 후의 차액은 발생할 수 있으며, 발송되지 않은 발송 건은 청구되지 않습니다.
                    </p>
                  </div>
                </div>
                <div className="flex-col gap-2 mt-[20px]">
                  <div className="flex gap-2">
                    <div className="font-semibold">회원이 아닌 카카오 친구 포함하기</div>
                    <Toggle disabled={countFriend <= 10} value={countAllFriend > 0 || isSendMoment} onToggle={(flag) => {
                      onChangeSendFriend(flag ? countFriend : 0)
                    }} />
                    {
                        countFriend <= 10 && (<div className="text-red-500">* 10명 이하는 전송할 수 없습니다.</div>)
                    }
                  </div>
                  <div className="bg-gray-100 p-4 flex-col">
                    <div className="flex items-center gap-4">
                      <div className="rounded border border-gray-300 text-center w-[286px]">
                        <div className="text-gray-400 py-1.5 text-sm border-b border-gray-300">
                          예상 고객 수 (발송 건 수)
                        </div>
                        <div className="bg-white py-2 font-semibold text-center">
                          {countFriend.toLocaleString()}명 (건)
                        </div>
                      </div>
                      <div className="w-6 h-6 bg-white rounded flex justify-center items-center text-gray-500">
                        <FontAwesomeIcon icon={faXmark} />
                      </div>
                      <div className="rounded border border-gray-300 text-center w-[118px]">
                        <div className="text-gray-400 py-1.5 text-sm border-b border-gray-300">
                          메시지 단가
                        </div>
                        <div className="py-2 font-semibold">
                          22원
                        </div>
                      </div>
                      <div className="w-6 h-6 bg-white rounded flex justify-center items-center text-gray-500">
                        <FontAwesomeIcon icon={faEquals} />
                      </div>
                      <div className="rounded border border-gray-300 text-center w-[326px]">
                        <div className="text-gray-400 py-1.5 text-sm border-b border-gray-300">
                          총 예상 발송 비용
                        </div>
                        <div className="bg-white py-2 font-semibold flex justify-center gap-2 text-indigo-600">
                          <>
                            <div className="bg-indigo-100 w-6 h-6 flex justify-center items-center  rounded">
                              <FontAwesomeIcon icon={faWonSign} size={"xs"} />
                            </div>
                            {(countFriend * 22).toLocaleString()}원
                          </>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className="text-gray-500 text-sm my-4 text-[12px]">
                        * ‘예상 고객 수’는 발송을 시도하는 고객 수를 의미하고, 실제 발송되는 고객 수와는 차이가 있을 수 있습니다.
                      </p>
                      <p className="text-gray-500 text-sm my-4 text-[12px]">
                        * 메시지 발송 등록 시 과금된 비용과 실발송 후의 차액은 발생할 수 있으며, 발송되지 않은 발송 건은 청구되지 않습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </InputGroup>
        <InputGroup label={"연령 인증 메시지"}>
          <div className="flex-col justify-center gap-2">
            <RadioGroup
                items={[
                  {
                    label: "선택안함",
                    value: "N"
                  },
                  {
                    label: "선택함",
                    value: "Y"
                  }
                ]}
                initChecked={messageData.adult ?? "N"}
                value={messageData.adult}
                onSelected={(value) => {
                  onChangeAdult(value)
                }}
            />
            <div>
              <div className="font-12px">연령인증 전까지 채팅방 메시지에 커버가 씌워지며, 20세 이상 성인만 내용을 확인할 수 있습니다.</div>
              <div className="font-12px">20세 이상에게 적합한 내용을 담고 있다면 필수 선택해주세요.</div>
              <div className="font-12px">예: 주류, 청소년 이용불가 게임 및 영화 등</div>
            </div>
          </div>
        </InputGroup>
        <InputGroup label="발송 날짜/시간 설정">
          <div className="flex gap-2 my-2 items-center">
            <div className="flex border-gray-200 border rounded">
              <CalendarBar minDate={new Date()} multiple={false} initDate={{
                startDate: moment(reservedDate.date.startDate).toDate(),
                endDate: moment(reservedDate.date.endDate).toDate()
              }} onConfirm={(dateProps) => {
                handleReserveDateChange("date", dateProps.startDate);
              }} />
            </div>
            <select
                className="w-100 bg-white border border-gray-200 text-gray-900 rounded focus:ring-indigo-500 focus:border-indigo-500 block p-2 h-[45px]"
                value={reservedDate.hour}
                onChange={(e) =>
                    handleReserveDateChange("hour", e.target.value)
                }
            >
              {range(13, 8).map((time) => {
                return (
                    <option value={String(time).padStart(2, "0")}>
                      {String(time).padStart(2, "0")}시
                    </option>
                );
              })}
            </select>
            <select
                className="w-100 bg-white border border-gray-200 text-gray-900 rounded focus:ring-indigo-500 focus:border-indigo-500 block p-2 h-[45px]"
                value={reservedDate.min}
                onChange={(e) => {
                  handleReserveDateChange("min", e.target.value)
                }}
            >
              {Array.from({ length: 12 }, (_, i) => i * 5).map((time) => {
                if (reservedDate.hour === "20" && time > 55) {
                  return
                }
                return (
                    <option value={String(time).padStart(2, "0")}>
                      {String(time).padStart(2, "0")}분
                    </option>
                );
              })}
            </select>
          </div>
          <div className="w-full rounded px-4 py-0.5">
            <p className="text-[12px] my-2">
              * 20:55부터 08:00까지는 메시지 수신이 불가하여 <span className="text-[12px] text-primary">익일 오전 08:00 이후에 메시지를 받게 됩니다.</span>
            </p>
          </div>
        </InputGroup>
        <SuspensePopup
            title={"테스트 발송하기"}
            visible={testMessagePopup}
            style={{width: "1000px"}}
            onClose={() => setTestMessagePopup(false)}
        >
          <TargetMessageForm id={id}
                             isTest={true}
                             onSelectedUserIds={() => {}}
                             selectUserIds={selectUserIds ?? []}
                             onSendMessage={() => setTestMessagePopup(false)}

          />
        </SuspensePopup>
      </div>
  );
};

export default MessageTargetSetting;
