import {Dropdown} from "omoplay";
import {useEffect, useState} from "react";
import InputGroup from "../InputGroup";

export const AutomateMessageCondition = ({automateMessageData, setAutomateMessageData}) => {
    const [conditionDays, setConditionDays] = useState(automateMessageData.conditionDays)
    const [sendHours, setSendHours] = useState(automateMessageData.sendHours)
    const [sendMinutes, setSendMinutes] = useState(automateMessageData.sendMinutes)

    useEffect(() => {
        automateMessageData.conditionDays = conditionDays
        automateMessageData.sendHours = sendHours
        automateMessageData.sendMinutes = sendMinutes
        setAutomateMessageData(automateMessageData)
    }, [automateMessageData, conditionDays, sendHours, sendMinutes, setAutomateMessageData]);

    return (
        <>
            <InputGroup label="대상 조건 설정" description="메시지 발송 대상의 조건을 설정해 주세요.">
                <div className="flex-col">
                    <div className="flex-col gap-4">
                        <div className="text-sm font-semibold">발송 일수</div>
                        <div className="flex items-center bg-gray-100 w-full rounded p-4">
                            {
                                automateMessageData.condition === "BIRTHDAY" ?
                                    <>
                                        <div className="mr-4"><span className="font-bold underline">생일</span> 고객에게</div>
                                        <input
                                            type="text"
                                            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block p-2"
                                            style={{width: "50px"}}
                                            defaultValue={`${conditionDays}`}
                                            maxLength={2}
                                            onKeyUp={(e) => {
                                                setConditionDays(e.currentTarget.value)
                                            }}
                                            onKeyDown={(e) => {
                                                setConditionDays(e.currentTarget.value)
                                            }}
                                        />
                                        <div className="mr-4">일 전에 메시지를 자동 발송합니다.</div>
                                    </>
                                    : (
                                        automateMessageData.condition === "COUPON_UNUSED" ?
                                            <>
                                                <div className="mr-4"><span className="font-bold underline">혜택 미사용</span> 고객에게 혜택 유효기간</div>
                                                <input
                                                    type="text"
                                                    className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block p-2"
                                                    style={{width: "50px"}}
                                                    defaultValue={`${conditionDays}`}
                                                    maxLength={2}
                                                    onKeyUp={(e) => {
                                                        setConditionDays(e.currentTarget.value)
                                                    }}
                                                    onKeyDown={(e) => {
                                                        setConditionDays(e.currentTarget.value)
                                                    }}
                                                />
                                                <div className="mr-4">일 전에 메시지를 자동 발송합니다.</div>
                                            </>
                                            : ""
                                    )
                            }
                        </div>
                    </div>
                </div>
            </InputGroup>
            <InputGroup label="발송 시간">
                <div>
                    <div className="flex items-center">
                        <div className="mr-2">
                            <Dropdown variants="default"
                                      initialValue={`${sendHours}`}
                                      items={[{label: "08시", value: "8"},
                                          {label: "09시", value: "9"}, {label: "10시", value: "10"},
                                          {label: "11시", value: "11"}, {label: "12시", value: "12"},
                                          {label: "13시", value: "13"}, {label: "14시", value: "14"},
                                          {label: "15시", value: "15"}, {label: "16시", value: "16"},
                                          {label: "17시", value: "17"}, {label: "18시", value: "18"},
                                          {label: "19시", value: "19"}, {label: "20시", value: "20"},
                                      ]}
                                      onChanged={(items) => {
                                          setSendHours(items[0].value)
                                      }}
                            />
                        </div>
                        <div>
                            <Dropdown variants="default"
                                      initialValue={`${sendMinutes}`}
                                      items={[
                                          {label: "00분", value: "0"}, {label: "05분", value: "5"},
                                          {label: "10분", value: "10"}, {label: "15분", value: "15"},
                                          {label: "20분", value: "20"}, {label: "25분", value: "25"},
                                          {label: "30분", value: "30"}, {label: "35분", value: "35"},
                                          {label: "40분", value: "40"}, {label: "45분", value: "45"},
                                          {label: "50분", value: "50"}, {label: "55분", value: "55"},
                                      ]}
                                      onChanged={(items) => {
                                          setSendMinutes(items[0].value)
                                      }}
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full px-4 py-0.5">
                    <div className="text-gray-500 text-[12px] my-2">
                        * 광고성 메시지는 20:55부터 08:00까지 메시지 수신이 불가합니다.
                    </div>
                </div>
            </InputGroup>
        </>
    );
};

export default AutomateMessageCondition;
