import {Suspense, useEffect, useState} from "react";
import { motion } from "framer-motion";
import Loading from "../Loading";
import {Header, Sidebar, ChartIcon, MarketingIcon, RecruitIcon, UserIcon, Settings, Dropdown} from "omoplay";
import {useLocation, useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {accountSelector, channelSelector} from "../../store/selector/selector";
import {logout} from "../../api/account/account.api";
import AICreditIcon from "../../assets/images/icon.credit.svg"
import MessageCreditIcon from "../../assets/images/icon.message.svg"
import AssetIcon from "../../assets/images/icon.asset.svg"

const Layout = ({ children }) => {
    const [minWidth, setMinWidth] = useState(window.innerWidth);
    const [minHeight, setMinHeight] = useState(window.innerHeight);
    const [collapsedPadding, setCollapsedPadding] = useState("250px")

    const location = useLocation();
    const navigate = useNavigate();
    const account = useRecoilValue(accountSelector)
    const channel = useRecoilValue(channelSelector)

    const permissions = account?.channels?.find(ch => ch.id === channel)?.permissions
    const showConnect = permissions?.findIndex(pm => pm.serviceType === "Connect") > -1
    const showAgent = permissions?.findIndex(pm => pm.serviceType === "Agent") > -1

    const env = process.env.REACT_APP_ENV

    const updateMinSize = () => {
        const scrollWidth = window.innerWidth + window.scrollX;
        const scrollHeight = window.innerHeight + window.scrollY;
        setMinWidth(scrollWidth);
        setMinHeight(scrollHeight);
    };

    useEffect(() => {
        // 이벤트 핸들러 추가
        window.addEventListener('scroll', updateMinSize);
        window.addEventListener('resize', updateMinSize);
        window.addEventListener('touchmove', updateMinSize, { passive: true });

        // 초기 실행
        updateMinSize();

        // 이벤트 핸들러 제거
        return () => {
            window.removeEventListener('scroll', updateMinSize);
            window.removeEventListener('resize', updateMinSize);
            window.removeEventListener('touchmove', updateMinSize);
        };
    }, []);

    const marketingChildren = [
        {
            label: '개인화 메시지',
            active: location.pathname.startsWith("/volatility-message"),
            onClick: () => navigate("/volatility-message")
        },
        {
            label: '개인화 자동 메시지',
            active: location.pathname.startsWith("/automate-message"),
            onClick: () => navigate("/automate-message")
        },
    ]

    if (showConnect && showAgent) {
        marketingChildren.push(
            {
                label: 'AI 개인화 메시지',
                active: location.pathname.startsWith("/ai/volatility-message"),
                onClick: () => navigate("/ai/volatility-message")
            }
        )
    }

    const agentChildren = [
        {
            label: 'AI 생성 메시지',
            active: ["/agent/message"].includes(location.pathname),
            onClick: () => navigate("/agent/message")
        }
    ]
    if (env === "dev") {
        agentChildren.push(
            {
                label: 'AI 자동화 메시지',
                active: ["/agent/message/automation"].includes(location.pathname),
                onClick: () => navigate("/agent/message/automation")
            }
        )
    }

    const productChildren = [
        {
            label: '브랜드 정보',
            active: location.pathname.startsWith("/brand"),
            onClick: () => navigate("/brand")
        }
    ]

    if (showAgent) {
        productChildren.push(
            {
                label: '제품 목록',
                active: location.pathname === "/product",
                onClick: () => navigate("/product")
            }
        )
    }

    const sideBarItems = [
        {
            title: '플레이',
            icon: <RecruitIcon />,
            show: showConnect,
            open: ["/project", "/spots", "/event", "/benefit"].some(path => location.pathname.startsWith(path)),
            children: [
                {
                    label: '프로젝트',
                    active: location.pathname.startsWith("/project"),
                    onClick: () => navigate("/project")
                },
                {
                    label: '고객 진입점 (QR)',
                    active: location.pathname.startsWith("/spots"),
                    onClick: () => navigate("/spots")
                },
                {
                    label: '이벤트',
                    active: location.pathname.startsWith("/event"),
                    onClick: () => navigate("/event")
                },
                {
                    label: '혜택',
                    active: location.pathname.startsWith("/benefit"),
                    onClick: () => navigate("/benefit")
                },
            ],
        },
        {
            title: '개인화 메시지',
            icon: <MarketingIcon />,
            show: showConnect,
            open: ["/volatility-message", "/ai/volatility-message", "/automate-message"].includes(location.pathname),
            children: marketingChildren,
        },
        {
            title: '성과 보드',
            icon: <ChartIcon />,
            show: showConnect,
            open: ["/messages", "/online/orders"].includes(location.pathname),
            children: [
                {
                    label: '메시지 리포트',
                    active: location.pathname.startsWith("/messages"),
                    onClick: () => navigate("/messages")
                },
                {
                    label: '온라인 매출',
                    active: location.pathname.startsWith("/online/orders"),
                    onClick: () => navigate("/online/orders")
                },
            ],
        },
        {
            title: '고객',
            icon: <UserIcon />,
            show: showConnect,
            open: ["/user/detail", "/users", "/users-statistics"].includes(location.pathname),
            children: [
                {
                    label: '고객 정보',
                    active: ["/users", "/user/detail"].includes(location.pathname),
                    onClick: () => navigate("/users")
                },
                {
                    label: '고객 분석',
                    active: location.pathname.startsWith("/users-statistics"),
                    onClick: () => navigate("/users-statistics")
                },
            ],
        },
        {
            title: '자산',
            icon: <img src={AssetIcon} alt="asset" />,
            show: showAgent,
            open: ["/brand", "/product"].includes(location.pathname),
            children: productChildren,
        },
        {
            title: '에이전트',
            icon: <UserIcon />,
            show: showAgent,
            open: ["/agent/message", "/agent/message/automation"].includes(location.pathname),
            children: agentChildren
        },
        {
            title: "설정",
            icon: <Settings />,
            open: ["/credit", "/credit/detail", "/payments"].includes(location.pathname),
            children: [
                {
                    label: 'AI 크레딧 / 예치금',
                    active: ["/credit", "/credit/detail"].includes(location.pathname),
                    onClick: () => navigate("/credit")
                },
                {
                    label: '결제 정보',
                    active: ["/payments"].includes(location.pathname),
                    onClick: () => navigate("/payments")
                }
            ]
        }
    ]

    const pageVariants = {
        initial: { opacity: 0, y: 0 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const excludeHeaders = [
        { label: "서비스 설정", location: "/services" }, { label: "제품 상세", location: "/product/detail" },
    ]

    const title = sideBarItems.flatMap(item => item.children).filter(child => child.active).map(child => child.label)[0] ??
        excludeHeaders.filter(header => header.location.includes(location.pathname))[0].label ?? ""

    return (
        <div className="layout bg-slate-200" style={{minWidth: `${minWidth}px`, minHeight: `${minHeight}px`}}>
            <Sidebar items={sideBarItems} topElement={(
                <div className="flex-col gap-[12px] p-[20px] pt-0">
                    <Dropdown variants="default"
                              titleStyle={{backgroundColor: "#FFF", color: "black", borderRadius: "8px", minWidth: "200px"}}
                              boxStyle={{overflow: "hidden", color: "black"}}
                              initialValue={channel}
                              items={account.channels.length === 0 ? [{}] : account.channels.map(channel => {
                                  return ({
                                      label: channel.name,
                                      value: channel.id
                                  })
                              })}
                              onChanged={(items) => {
                                  if (channel !== Number(items[0].value)) {
                                      localStorage.setItem("channelState", items[0].value)
                                      window.location.href = "/"
                                  }
                              }}
                    />
                    <div>
                        <div className="flex-col gap-[12px] p-[12px] bg-[#0A2855] rounded-[8px]">
                            <div className="text-purple">보유한 크레딧</div>
                            <div className="flex-align-center gap-1">
                                <div><img src={AICreditIcon} align="ai_credit" /></div>
                                <div className="text-[16px]">{account.aiCredit.toLocaleString()}</div>
                                <div className="text-[16px] text-disabled">Credit</div>
                            </div>
                            <div className="flex-align-center gap-1">
                                <div><img src={MessageCreditIcon} align="message_credit" /></div>
                                <div className="text-[16px]">{account.messageCredit.toLocaleString()}</div>
                                <div className="text-[16px] text-disabled">원</div>
                            </div>
                        </div>
                    </div>
                    <div className="border-b" style={{borderColor: "rgba(255, 255, 255, 0.16)"}}></div>
                </div>
            )}
                     onCollapsed={(collapsed) => setCollapsedPadding(collapsed ? "90px" : "250px")}
            />
            <div className="main-layout" style={{paddingLeft: collapsedPadding}}>
                <div className="fixed w-full" style={{paddingRight: collapsedPadding, zIndex: "10"}}>
                    <Header
                        title={title}
                        nickName={account.name}
                        items={[
                            {label: '서비스 설정', onclick: () => navigate("/services")},
                            {label: "로그아웃", onclick: () => logout()},
                        ]}
                    />
                </div>
                <motion.div
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={pageVariants}
                >
                    <div className="w-full flex-col items-center" style={{paddingTop: "56px"}}>
                        <div className="inner-layout">
                            <Suspense fallback={
                                <div className="w-full text-center py-8 justify-center">
                                    <Loading className={"w-20 h-20 mx-auto"} />
                                </div>
                            }
                            >
                                {children}
                                <div style={{height: "100px"}}></div>
                            </Suspense>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};
export default Layout;
