import ButtonsForm from "../../../form/message/ButtonsForm";
import React, {useEffect, useState} from "react";
import {RadioGroup, TextInput, CarouselItem, Button} from "omoplay";
import {TextField} from "@mui/material";
import LabelNormalButton from "../../../LabelNormalButton";
import {fileUpload, kakaoImageUpload} from "../../../../api/file/file.api";
import VariableTextArea from "../../../form/VariableTextArea";

const CarouselMessageSetting = ({ messageData, variables, regex, onChangeCarousels, onDeleteCarousels,
                                    onChangeCarouselImage, onChangeCarouselButtons, onChangeTail, onOrderCarousels }) => {
    const [tabs, setTabs] = useState()
    const [activeIndex, setActiveIndex] = useState(0)
    const [activeCarousel, setActiveCarousel] = useState({})
    const tail = messageData.tail ?? {}

    useEffect(() => {
        if (messageData.carousels === undefined) {
            onChangeCarouselImage(0, "imgUrl", "")
            onChangeCarouselImage(1, "imgUrl", "")
        } else {
            setActiveCarousel(messageData.carousels[activeIndex])
            setTabs(messageData.carousels?.map((data, index) =>
                ({label: `피드${index + 1}`, index: index})) ?? [{label: "피드1", index: 0},{label: "피드2", index: 1}])
        }
    }, [activeIndex, messageData, onChangeCarousels])

    const uploadImage = (e) => {
        kakaoImageUpload(e.target.files[0], "FC").then(({data}) => {
            if (data.code === "0") {
                onChangeCarouselImage(activeIndex, "imgUrl", data.imageUrl);
                fileUpload(e.target.files[0]).then(({data}) => {
                    onChangeCarouselImage(activeIndex, "originalUrl", data)
                })
            } else {
                alert(data.message);
            }
        })
    }

    return (
        <div>
            <CarouselItem tabs={tabs}
                          activeTabIndex={activeIndex}
                          onTabsChange={(tabs) => {
                              onOrderCarousels(tabs.map(tab => messageData.carousels[tab.index]))
                              setTabs(tabs)
                          }}
                          onAddTab={(tab) => {
                              onChangeCarouselImage(tab.index, "imgUrl", "")
                          }}
                          onRemoveTab={(index) => {
                              onDeleteCarousels(index)
                              setActiveIndex(index === 0 ? 0 : index - 1)
                          }}
                          onChangedTab={(tab) => {
                              setActiveIndex(tab.index)
                          }}
            >
                <div className="carousel-content">
                    <div className="flex-col gap-1">
                        <div className="flex gap-2">
                            <input
                                accept="image/jpg, image/jpeg, image/png"
                                style={{ display: "none" }}
                                id="raised-button-file"
                                type="file"
                                onChange={(e) => uploadImage(e)}
                            />
                            <TextField
                                inputProps={{ readOnly: true }}
                                fullWidth
                                placeholder="이미지 파일을 업로드 해주세요."
                                size={"small"}
                                value={activeCarousel?.attachment?.image?.imgUrl ?? ""}
                            />
                            <LabelNormalButton
                                htmlFor="raised-button-file"
                                className="whitespace-nowrap py-2 px-4 text-sm h-fit"
                            >
                                업로드 하기
                            </LabelNormalButton>
                        </div>
                        <div className="text-tertiary" style={{fontSize: "12px"}}>
                            가로 500px 이상, 2:1 이상, 3:4 이하 / png,jpg (최대 5MB)
                        </div>
                    </div>
                    <div className="flex-col gap-2">
                        <div className="font-semibold">타이틀</div>
                        <TextInput maxLength={20}
                                   placeholder="타이틀을 입력해주세요."
                                   defaultValue={activeCarousel.header ?? ""}
                                   value={activeCarousel.header ?? ""}
                                   onChange={(e) => {
                                       onChangeCarousels(activeIndex, "header", e.target.value)
                                   }}
                        />
                    </div>
                    <div className="font-semibold mb-2">내용</div>
                    <VariableTextArea type={"FC"}
                                      message={activeCarousel.message} variables={variables}
                                      regex={regex} maxLength={180}
                                      onChangeMessage={(text) => onChangeCarousels(activeIndex, "message",text)} />
                    <ButtonsForm maxLength={2} buttons={activeCarousel.attachment?.buttons}
                                 onChangeButtons={(index, key, value) => {
                                     onChangeCarouselButtons(activeIndex, index, key, value)
                                 }} />
                </div>
            </CarouselItem>
            <div className="flex-col gap-2 pt-[16px]">
                <div className="font-semibold">더보기</div>
                <RadioGroup
                    items={[
                        {
                            label: "없음",
                            value: "none"
                        },
                        {
                            label: "링크",
                            value: "link"
                        }
                    ]}
                    initChecked={tail.urlMobile ? "link" : "none"}
                    onSelected={(value) => {
                        if (value === "none") {
                            onChangeTail("urlMobile", undefined)
                        } else {
                            onChangeTail("urlMobile", "")
                        }
                    }}
                />
                {
                    tail.urlMobile?.length >= 0 && (
                        <div className="flex-col gap-2">
                            <div className="flex gap-2">
                                <div style={{width: "100%", flex: "1"}}>
                                    <TextInput
                                        placeholder="연결할 페이지의 URL을 입력해주세요."
                                        value={tail.urlMobile ?? ""}
                                        onChange={(e) =>
                                            onChangeTail("urlMobile", e.target.value)
                                        }
                                    />
                                </div>
                                <Button
                                    variants="dark"
                                    onClick={() => window.open(tail.urlMobile)}
                                    value="URL 확인"
                                />
                            </div>
                            <div>PC 전용 링크</div>
                            <div className="flex gap-2">
                                <div style={{width: "100%", flex: "1"}}>
                                    <TextInput
                                        placeholder="연결할 페이지의 URL을 입력해주세요."
                                        value={tail.urlPc ?? ""}
                                        onChange={(e) =>
                                            onChangeTail("urlPc", e.target.value)
                                        }
                                    />
                                </div>
                                <Button
                                    variants="dark"
                                    onClick={() => window.open(tail.urlPc)}
                                    value="URL 확인"
                                 />
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default CarouselMessageSetting;
