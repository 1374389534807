
import { useEffect, useState } from "react";
import {downloadUsersWorkbook, getUsers} from "../../api/user/user.api";
import SearchInput from "../SearchInput";
import {Button, CalendarBar, Checkbox, Pagination, Table} from "omoplay";
import {getAllDateRange} from "../../libs/helper";
import SuspensePopup from "../SuspensePopup";
import KakaoAuthorization from "../form/message/KakaoAuthorization";
const moment = require("moment");

const UserListPage = () => {
  const [users, setUsers] = useState({kakaoUsers: []});
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState(getAllDateRange());
  const [openDownloadAuth, setOpenDownlaodAuth] = useState(false)
  const [isAuth, setAuth] = useState(false)
  const [isFriend, setFriend] = useState(false)

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    getUsers(search, startDate, endDate, isFriend, page - 1, 10, "createdAt,desc").then((res) => {
      if (res.code === 2000) {
        setUsers(res.data);
      } else {
        alert(res.message);
      }
    })
  }, [search, dateRange, page, isFriend]);

  const handleSearchChange = (search) => {
    setPage(1)
    setSearch(search);
  };

  const onDateInput = (dateRange) => {
    setPage(1)
    setDateRange({
      startDate: moment(dateRange.startDate).format("yyyy-MM-DD"),
      endDate: moment(dateRange.endDate).format("yyyy-MM-DD")
    });
  };

  const displayGenderText = (gender) => {
    if (gender === "male") {
      return "남성";
    } else if (gender === "female") {
      return "여성";
    } else {
      return gender;
    }
  };

  const headers = [
    { name: "이름", align: "center", width: "100px" },
    { name: "이메일(카카오계정)", width: "190px" },
    { name: "성별", align: "center", width: "80px" },
    { name: "연령", align: "center", width: "120px" },
    { name: "전화번호", width: "180px" },
    { name: "배송지 주소" },
    { name: "회원가입", width: "200px"  },
    { name: "친구 상태", width: "120px", align: "center" },
  ];

  const downloadUsers = () => {
    const { startDate, endDate } = dateRange;

    downloadUsersWorkbook(search, isFriend, startDate, endDate).then((res) => {
      const contentDisposition = res.headers['content-disposition']
      const fileName = contentDisposition
          ? contentDisposition.split('filename=')[1].replace(/"/g, '') // 파일명 추출
          : 'downloaded_file.xlsx';
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
  }

  function userDetail(id) {
    window.location.href = `/user/detail?id=${id}`
  }

  const tableData = users.kakaoUsers.map(user => {
    return [
        <div className="text-center break-all underline pointer" onClick={() => userDetail(user.id)}>{user.name}</div>,
      <div>{user.email}</div>,
        <div className="text-center">{displayGenderText(user.gender)}</div>,
        <div className="text-center">
          <div>{user.ageRange}</div>
          <div>({`${user.birthyear ? `${user.birthyear}.` : ''}`}{user.birthday
              ? user.birthday.slice(0, 2) +
              "." +
              user.birthday.slice(2, 4)
              : ""})
          </div>
        </div>,
      user.phoneNumber,
        <>
          <span
              className="float-left"
              style={{
                maxWidth: "190px",
                overflowX: "hidden",
                whiteSpace: "nowrap"
              }}
          >
            {user.baseAddress}
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>********</span>
        </>,
        <>
          <div
              className="flex"
              style={{
                maxWidth: "180px",
                textOverflow: "ellipsis",
                overflowX: "clip",
                whiteSpace: "nowrap",
                color: "var(--mainblue-0)"
              }}
          >
          {user.spotName}
          </div>
          <div>
            {moment(user.createdAt).format("YYYY.MM.DD HH:mm")}
          </div>
        </>,
        <div className="text-center">
          <div>
            {user.friendStatus}
          </div>
          <div>
            {user.modifiedFriendsAt}
          </div>
        </div>
    ]
  })

  return (
    <>
      <div className="flex-col rounded w-full">
        <div className="page-title">고객 리스트</div>
        <div className="flex items-end" style={{marginTop: "35px", gap: "24px"}}>
          <div style={{maxWidth: "405px", width: "405px"}}>
            <div style={{marginBottom: "9px"}}>
              <label className="search-label">검색어</label>
            </div>
            <SearchInput onSubmit={(text) => handleSearchChange(text)} />
          </div>
          <div>
            <div style={{marginBottom: "9px"}}>
              <label className="search-label">회원 가입일</label>
            </div>
            <CalendarBar initDate={{startDate: moment(dateRange.startDate).toDate(), endDate: moment(dateRange.endDate).toDate()}}
                         onConfirm={(props) => onDateInput(props)} />
          </div>
          <div className="h-full">
            <div style={{marginBottom: "9px"}}>
              <label className="text-red-500">* 친구 상태는 매일 새벽에 업데이트 됩니다.</label>
            </div>
            <div className="flex-align-center gap-4" style={{height: "50%"}}>
              <div>친구 고객만 보기</div>
              <div><Checkbox onChecked={(checked) => {
                setFriend(checked)
              }} /></div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-end" style={{marginTop: "30px", marginLeft: "14px"}}>
          <span className="font-16px">
            총 <span className="mainblue-0">{users.totalCount?.toLocaleString()}</span>명
          </span>
          <div>
            <Button variants="solid" value="고객 다운받기" onClick={() => setOpenDownlaodAuth(true)} />
          </div>
        </div>
        <div className="flex-col py-4">
          <Table headers={headers} data={tableData} />
          <div className="flex justify-center" style={{marginTop: "24px"}}>
            <Pagination rowSize={10} totalSize={users.totalCount} page={page} onPaging={(page) => setPage(page)} />
          </div>
        </div>
      </div>
      {
        openDownloadAuth && (
              <SuspensePopup
                  title={"카카오 인증하기"}
                  size={"w-[600px]"}
                  onClose={() => {
                    setOpenDownlaodAuth(false)
                    setAuth(false)
                  }}
              >
                <div className="flex-col gap-8" style={{padding: "36px 0 0 0"}}>
                  <KakaoAuthorization onSubmit={() => setAuth(true)} />
                  <div className="flex justify-between">
                    <div>{isAuth && "비밀번호: omoplay0808"}</div>
                    <Button variants="dark" value="엑셀 다운로드 받기"
                            disabled={!isAuth} onClick={() => downloadUsers()} />
                  </div>
                </div>
              </SuspensePopup>
          )
      }
    </>
  );
};
export default UserListPage;
