import {useRecoilValue} from "recoil";
import {accountSelector} from "../../store/selector/selector";
import {Alert} from "omoplay";
import {useEffect, useState} from "react";


const CheckCreditModalForm = ({deductMoney, onValid}) => {
    const account = useRecoilValue(accountSelector)
    const messageCredit = account.messageCredit

    const [isValid, setValid] = useState(false)

    useEffect(() => {
        setValid(messageCredit - deductMoney >= 0)
        onValid(messageCredit - deductMoney >= 0)
    }, [deductMoney, messageCredit]);

    return (
        <div className="flex-col gap-8">
            <div className="flex-col gap-2">
                <div className="font-semibold">차감 예치금 (예상)</div>
                <div className="font-semibold rounded-[8px] bg-shape flex-align-center p-[12px] w-[240px]">{deductMoney.toLocaleString()}원 (KRW)</div>
            </div>
            <div className="flex-col gap-2">
                <div className="font-semibold">현재 가용 예치금</div>
                <div className="font-semibold rounded-[8px] bg-bluedepth text-primary flex-align-center p-[12px] w-[240px]">{messageCredit.toLocaleString()}원 (KRW)</div>
            </div>
            {
                isValid ? (
                    <Alert title="주의사항" type="warning" withIcon={true}>
                        <li>메시지 차감 예치금은 예상 수량이며, 발송 실패 수량은 실제로 차감되지 않습니다.</li>
                        <li>예치금 차감과 관련하여 문의내용은 고객센터 를 이용해 주십시오.</li>
                    </Alert>
                ) : (
                    <Alert title="현재 가용 예치금이 부족합니다." type="error" withIcon={true}>
                        <li>예약된 예치금은 다른 작업에 배정되어 있는 크레딧 입니다.</li>
                        <li>예치금 차감과 관련하여 문의내용은 고객센터 를 이용해 주십시오.</li>
                    </Alert>
                )
            }
        </div>
    )
}

export default CheckCreditModalForm