import {TextField} from "@mui/material";
import {fileUpload, kakaoImageUpload} from "../../../api/file/file.api";
import VariableTextArea from "../VariableTextArea";
import React, {useRef} from "react";
import {Button, TextInput} from "omoplay";

const SettingItemForm = ({index, variables, regex, item, onChangeImage, onChangeTitle, onChangeImageLink}) => {
  const imgUrl = item?.imgUrl
  const title = item?.title
  const urlMobile = item?.urlMobile

  const fileInputRef = useRef(null)

  const uploadImage = (e, type) => {
    kakaoImageUpload(e.target.files[0], type).then(({data}) => {
      if (data.code === "0") {
        onChangeImage("imgUrl", data.imageUrl);
        fileUpload(e.target.files[0]).then(({data}) => {
          onChangeImage("originalUrl", data)
        })
      } else {
        alert(data.message);
      }
    })
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="p-[16px] bg-[#F2F4F7] flex-col gap-4">
      <div>
        <div className="flex gap-2">
          <div className="flex-1">
            <input
                accept="image/jpg, image/jpeg, image/png"
                style={{ display: "none" }}
                ref={fileInputRef}
                type="file"
                onChange={(e) => uploadImage(e, index === 0 ? "FFL" : "FL")}
            />
            <TextInput
                readOnly={true}
                placeholder="이미지 파일을 업로드 해주세요."
                value={imgUrl}
            />
          </div>
          <Button
              variants="dark"
              htmlFor={`settings-${index}`}
              value="첨부"
              onClick={handleButtonClick}
              style={{ width: "105px", height: "40px" }}
           />
        </div>
        <div className="text-tertiary text-[12px] mt-2">{ (index === 0 ? "고정 비율 2:1, 가로 500, 세로 250px 이상"
                : "고정 비율 1:1, 가로, 세로 500px 이상") +
            " / JPG, PNG (최대 5MB)" }
        </div>
      </div>
      <div>
        <div className="font-semibold mb-2">내용</div>
        <div>
          <VariableTextArea  type={"FL"}
                             message={title} variables={variables}
                             regex={regex}
                             maxLength={index === 0 ? 25 : 30} onChangeMessage={onChangeTitle} />
        </div>
      </div>
      <div>
        <div className="font-semibold mb-2">링크</div>
        <div className="flex gap-2">
          <TextField
            style={{ flex: 1, backgroundColor: "#FFF" }}
            size={"small"}
            placeholder="연결할 페이지의 URL을 입력해주세요."
            value={urlMobile}
            onChange={(e) => onChangeImageLink(e.target.value)}
          />
            <Button variants="dark" onClick={() => window.open(urlMobile)} value="URL 확인" />
        </div>
      </div>
    </div>
  );
};

export default SettingItemForm;
