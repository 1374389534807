import InputGroup from "../InputGroup";
import BenefitDetailPreview from "./BenefitDetailPreview";
import {Button, RadioGroup, TextInput} from "omoplay";
import React, {useEffect} from "react";
import ImageForm from "../../ImageForm";

const BenefitDetail = ({benefitDetails, onBenefitImage, onBenefitPrecautionTitle, onBenefitPrecautionMessage,
                           onBenefitLink, onShowAddress}) => {
    const benefitData = benefitDetails().benefit
    const benefitImage = benefitData.prizeImage
    const benefitPrecautionTitle = benefitData.prizeCautionTitle ?? benefitDetails().title
    const benefitPrecautionMessage = benefitData.prizeCautionDescription ?? benefitDetails().precaution
    const linkUrl = benefitData.linkUrl

    useEffect(() => {
        if (!benefitData.prizeCautionTitle) {
            onBenefitPrecautionTitle(benefitPrecautionTitle)
        }
        if (!benefitData.prizeCautionDescription) {
            onBenefitPrecautionMessage(benefitPrecautionMessage)
        }
    }, [])

  return (
      <InputGroup label="혜택 상세 화면" description="혜택에 대한 상세 화면을 설정해 주세요.">
          <div className="flex">
              <div className="flex flex-1">
                  <div className="flex-col flex-1 mr-[12px]">
                      <div className="flex-col gap-2">
                          <div className="font-semibold">혜택 이미지</div>
                          <ImageForm imageUrl={benefitImage} onChangeImageUrl={(url) => onBenefitImage(url)} />
                      </div>
                      <div className="flex-col gap-2 mt-[50px]">
                          <div className="font-semibold">혜택 유의사항 안내</div>
                          <div className="flex-col gap-2 p-[16px]" style={{backgroundColor: "var(--color-shape-depth1)"}}>
                              <div>제목</div>
                              <div>
                                  <TextInput value={benefitPrecautionTitle}
                                             onChange={(e) => onBenefitPrecautionTitle(e.target.value)}
                                             maxLength={20}
                                  />
                              </div>
                              <div>내용</div>
                              <div>
                                      <textarea
                                          className="bg-white border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2  resize-none text-xs whitespace-pre-line"
                                          onChange={(e) => onBenefitPrecautionMessage(e.target.value)}
                                          rows={10}
                                          value={benefitPrecautionMessage}
                                      ></textarea>
                              </div>
                          </div>
                      </div>
                      {
                          benefitData.type === "LINK" && (
                              <div className="flex-col gap-2 mt-[50px]">
                                  <div className="font-semibold">혜택 링크 설정</div>
                                  <div className="flex-align-center gap-4">
                                      <div style={{flex: "1"}}>
                                          <TextInput value={linkUrl}
                                                     onChange={(e) => onBenefitLink(e.target.value)}
                                                     placeholder="https://www.omoplay.com" maxLength={200} />
                                      </div>
                                      <div><Button variants="secondary" value="URL 확인" onClick={() => {
                                          window.open(linkUrl)
                                      }} /></div>
                                  </div>
                              </div>
                          )
                      }
                      {
                          benefitData.type === "DELIVERY" && (
                              <div className="flex-col gap-2 mt-[50px]">
                                  <div className="font-semibold">주소 입력 여부</div>
                                  <div>
                                      <RadioGroup items={[{label: "사용 안함", value: "disabled"}, {label: "사용", value: "enabled"}]}
                                                  initChecked={benefitData.showAddress ? "enabled" : "disabled"}
                                                  onSelected={(value) => {
                                                      onShowAddress(value)
                                                  }} />
                                  </div>
                              </div>
                          )
                      }
                  </div>
              </div>
              <div className="bg-grey-0 flex-center-all flex-1 ml-[12px]">
                  <BenefitDetailPreview benefitData={benefitData} />
              </div>
          </div>
      </InputGroup>
  )
};

export default BenefitDetail;
