import HighlightWithinTextarea from "react-highlight-within-textarea";
import {useEffect, useState} from "react";
import {Button} from "omoplay";


const VariableTextArea = ({type, message, regex, variables, maxLength, onChangeMessage}) => {
    const [isVariablePopup, setVariablePopup] = useState(false)
    const [invalid, setInvalid] = useState(false)
    const validCount = (type === "FC" ? 2 : 1)

    const addVariable = (text) => {
        const variable = message + `[${text}]`
        onChangeMessage(variable)
        setVariablePopup(false);
    }

    useEffect(() => {
        if (type !== "FI" && type !== undefined) {
            setInvalid((message?.match(/\n/g) || []).length > validCount)
        }
    }, [message])

    return (
        <div>
            <div className="block w-full p-2 pb-6 pr-8 text-xs text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-indigo-500 focus:border-indigo-500 relative">
                <HighlightWithinTextarea
                    placeholder=""
                    value={message ?? ""}
                    onChange={(value) => {
                        let text = value
                        if (value.length > maxLength) {
                            text = value.substring(0, maxLength)
                        }
                        onChangeMessage(text)
                    }}
                    highlight={[
                        {
                            highlight: regex,
                            className: "variable_highlight",
                        },
                    ]}
                    className="max-w-[340px]"
                />
                <span className="absolute end-3 bottom-2 text-xs text-gray-400">
                            {message?.length || 0}/{maxLength}자
                </span>
            </div>
            <div className="flex-align-center gap-2 justify-end pt-2">
                {
                    invalid && (<div className="text-red-500">줄바꿈은 {validCount}개이상 넣을 수 없습니다.</div>)
                }
                <Button variants="dark" onClick={() => setVariablePopup(!isVariablePopup)} value="+ 변수 설정" style={{display: "flex", alignItems: "center", height: "32px"}} />
                {isVariablePopup && (
                    <ul className="absolute bottom-12 right-0 bg-white rounded-lg text-sm shadow-lg border border-gray-200 text-gray-600 ">
                        {
                            variables.map(variable => {
                                return (
                                    <li
                                        className="hover:bg-gray-50 cursor-pointer py-3 px-6 rounded-t-lg"
                                        onClick={() => addVariable(variable)}
                                    >
                                        {variable}
                                    </li>
                                )
                            })
                        }
                    </ul>
                )}
            </div>
        </div>
    )
}

export default VariableTextArea