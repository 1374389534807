import { useEffect, useState } from "react";
import Popup from "../../components/Popup";
import SuspensePopup from "../../components/SuspensePopup";
import BenefitPreview from "../BenefitPreview";
import {Button, Pagination, Table} from "omoplay";
import Badge from "../form/badge/Badge";
import MiniMenu from "../form/MiniMenu";
import {deleteBenefit, listBenefit} from "../../api/benefit/benefit.api";
import moment from "moment";

const BenefitList = () => {
  const [page, setPage] = useState(1);
  const [benefits, setBenefits] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [deleteBenefitId, setDeleteBenefitId] = useState(undefined);
  const [previewPopup, setPreviewPopup] = useState();
  const [tableData, setTableData] = useState([])
  const [openMenuId, setOpenMenuId] = useState(null)
  const rowSize = 10

  useEffect(() => {
    listBenefit(page, rowSize).then(({data}) => {
      setBenefits(data.content)
      setTotalPages(data.totalElements);
    })
  }, [page])

  function goEdit(benefitId) {
    window.location.href = (`/benefit/edit?id=${benefitId}`)
  }

  useEffect(() => {
    setTableData(benefits.map(benefit => {
      let badgeColor;
      if (benefit.status === "ACTIVE") {
        badgeColor = "blue"
      } else {
        badgeColor = "grey"
      }
      return [
        <div>{benefit.id}</div>,
        <div>
          <div className="underline pointer" onClick={() => goEdit(benefit.id)}>{benefit.name}</div>
          <div className="text-grey-4">{moment(benefit.modifiedAt).format("YYYY-MM-DD HH:mm")} 업데이트</div>
        </div>,
        <div>{benefit.retailPrice ? `${benefit.retailPrice.toLocaleString()}원` : "미설정"}</div>,
        <div className="text-center">{benefit.typeKor}</div>,
        <div className="flex-center-all">
          <Badge name={benefit.statusKor} color={badgeColor} />
        </div>,
        <div className="flex-center-all">
          <MiniMenu
              id={benefit.id}
              openMenuId={openMenuId}
              setOpenMenuId={setOpenMenuId}
              onClickEdit={() => goEdit(benefit.id)}
              onClickPreview={() => setPreviewPopup(benefit.id)}
              onClickDelete={() => setDeleteBenefitId(benefit.id)}
              disabledDelete={benefit.used}
          />
        </div>
      ]
    }))
  }, [benefits, openMenuId]);

  const headers = [
    { name: "ID", width: "60px" },
    { name: "혜택명", sortable: false },
    {
      name: "소비자가",
      sortable: false,
    },
    {
      name: "혜택 형식",
      sortable: false,
      align: "center",
    },
    {
      name: "혜택 상태",
      sortable: false,
      align: "center",
    },
    {
      name: "",
      sortable: false,
      align: "center",
      width: "80px"
    },
  ];

  const handleDelete = () => {
    setDeleteBenefitId(undefined)
    deleteBenefit(deleteBenefitId).then(() => {
      window.location.reload()
    })
  };

  return (
    <>
      <div className="flex items-center gap-4">
        <h4 className="page-title">혜택</h4>
      </div>
      <div className="w-full" style={{marginTop: "33px"}}>
        <div className="w-full flex items-center justify-between">
          <div className="flex gap-4 items-center">
          </div>
          <div className="w-full flex justify-between gap-2 items-center">
            <div className="text-gray-600">
              총 <span className="font-bold">{totalPages.toLocaleString()}</span>개
            </div>
            <Button
                variants="solid"
                value="+ 혜택 추가"
                onClick={() => window.location.href = ("/benefit/edit")}
             />
          </div>
        </div>
        <div className="flex-col py-4">
          <Table headers={headers} data={tableData} />
          <div className="flex justify-center" style={{marginTop: "24px"}}>
            <Pagination rowSize={rowSize} totalSize={totalPages} page={page} onPaging={(page) => setPage(page)} />
          </div>
        </div>
      </div>
      {previewPopup && (
        <SuspensePopup
          title={"미리보기"}
          size={"w-full max-w-xl"}
          onClose={() => setPreviewPopup(null)}
        >
          <BenefitPreview id={previewPopup} />
        </SuspensePopup>
      )}
      {deleteBenefitId && (
        <Popup
          title={"정말 삭제하시겠습니까?"}
          buttons={[
            { label: "취소", onClick: () => setDeleteBenefitId(undefined) },
            { label: "확인", type: "info", onClick: () => handleDelete() },
          ]}
        />
      )}
    </>
  );
};
export default BenefitList;
