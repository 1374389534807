import {
  Box,
  Divider,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { messageFileUpload } from "../../../../api/file/file.api";
import LabelNormalButton from "../../../LabelNormalButton";
import InputGroup from "../../../form/InputGroup";
import {RadioGroup} from "omoplay";
import ButtonsForm from "../../../form/message/ButtonsForm";
import VariableTextArea from "../../../form/VariableTextArea";

const DefaultMessageSetting = ({ messageData, variables, regex, onChangeImage, onChangeContents, onChangeButtons}) => {
    const [isImage, setIsImage] = useState(messageData.image ?? false)
    const buttons = messageData.buttons
    const content = messageData.content

    const uploadImage = (e) => {
        messageFileUpload(
          "default",
          e.target.files[0],
          (res) => {
            if (res.status === 200) {
              const data = res.data;
              if (data.code === "0") {
                onChangeImage(res.data.imageUrl);
              } else {
                alert(data.message);
              }
            } else {
              console.error(res.data);
              alert(
                res.status +
                  "에러가 발생했습니다. 헤드리스 담당자에게 문의 해주세요."
              );
            }
          },
          (error) => {
            alert(error);
          }
        );
    }

  return (
    <>
      <InputGroup
          label={"메시지 꾸미기"}
          isRequired={true}
          className="py-4"
      >
        <RadioGroup
            items={[
              {
                label: "없음(텍스트만)",
                value: "text"
              },
              {
                label: "이미지",
                value: "image"
              }
            ]}
            initChecked={isImage ? "image" : "text"}
            value={isImage}
            onSelected={(e) => {
                setIsImage(e === "image")
                onChangeImage(e === "image" ? "" : undefined);
            }}
        />
        {isImage && (
            <Box sx={{ display: "flex", gap: "8px" }}>
              <input
                  accept="image/jpg, image/jpeg, image/png"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={(e) => uploadImage(e)}
              />
              <TextField
                  inputProps={{ readOnly: true }}
                  fullWidth
                  placeholder="이미지 파일을 업로드 해주세요."
                  helperText="* 가로 500px 이상, 최소 비율 2:1, 최대 비율 3:4 지원 포맷: png,jpeg (최대 1MB)"
                  size={"small"}
                  value={messageData.image?.imgUrl}
              />
              <LabelNormalButton
                  htmlFor="raised-button-file"
                  className="whitespace-nowrap py-2 px-4 text-sm h-fit"
              >
                업로드 하기
              </LabelNormalButton>
            </Box>
        )}
      </InputGroup>
      <Divider />
      <InputGroup
          label={"내용"}
          isRequired={true}
          className="py-4"
      >
          <VariableTextArea message={content} variables={variables} regex={regex} maxLength={400} onChangeMessage={onChangeContents} />
      </InputGroup>
      <Divider />
        <ButtonsForm maxLength={2} buttons={buttons} onChangeButtons={onChangeButtons} />
    </>
  );
};

export default DefaultMessageSetting;
