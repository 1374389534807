import {
    Box, Divider, Typography
} from "@mui/material";
import SettingFormLabel from "../../../form/SettingFormLabel";
import ButtonsForm from "../../../form/message/ButtonsForm";
import NormalButton from "../../../NormalButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React, {useState} from "react";
import CarouselImageForm from "../../../form/message/CarouselImageForm";
import {RadioGroup, TextInput} from "omoplay";
import VariableTextArea from "../../../form/VariableTextArea";

const CarouselMessageSetting = ({ messageData, variables, regex, onChangeCarousels, onDeleteCarousels, onDeleteAllCarousels, onChangeCarouselImage, onChangeCarouselButtons, onChangeTail }) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const carousels = messageData.carousels ?? []
    const activeCarousel = carousels[activeIndex] ?? []
    const activeAttachment = activeCarousel.attachment ?? {}
    const tail = messageData.tail ?? {}

    return (
        <>
          <Box>
            <SettingFormLabel label={"캐러셀"} />
              <Box sx={{ p: 2, backgroundColor: "#F2F4F7", borderRadius: "6px" }}>
                  <Box
                      sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                      }}
                  >
                      <Typography fontSize={"12px"} color={"grey5"}>
                          가로 500px 이상, 최소 비율 2:1, 최대 비율 3:4 지원 포맷: png,jpeg (최대 2MB)
                      </Typography>
                      <NormalButton
                          startIcon={<DeleteOutlineIcon />}
                          className="text-sm py-1 px-2"
                          onClick={() => {
                              if (window.confirm("입력한 정보를 모두 삭제하시겠습니까?")) {
                                  onDeleteAllCarousels()
                                  setActiveIndex(0)
                              }
                          }}
                      >
                          전체 삭제
                      </NormalButton>
                  </Box>
                  <Box className="carousel-header-wrap">
                      {
                          Array.from({ length: Math.min(6, Math.max(2, carousels.length + 1)) }, (_, index) => {
                              const carousel = carousels[index] ?? {}
                              const attachment = carousel.attachment ?? {}
                              const image = attachment.image ?? {}

                              return (
                                  <CarouselImageForm index={index}
                                                     activeIndex={activeIndex}
                                                     imgUrl={image.imgUrl}
                                                     onChangeImage={(imgUrl) => {
                                                         onChangeCarouselImage(index, imgUrl)
                                                     }}
                                                     onChangeActiveIndex={setActiveIndex}
                                                     onDelete={() => onDeleteCarousels(index)}
                                  />
                              )
                          })
                      }
                      {carousels.length < 2 && (
                          <Box className="text-red-500 text-sm mb-4">* 2개는 필수입니다</Box>
                      )}
                      <Box className="carousel-background-mask"></Box>
                  </Box>
                  <Box className="carousel-content">
                      <Box>
                          <div className="relative">
                              <SettingFormLabel label={"타이틀"} isRequired={true} />
                              <TextInput maxLength={20}
                                         placeholder="타이틀을 입력해주세요."
                                         defaultValue={activeCarousel.header}
                                         value={activeCarousel.header ?? ""}
                                         onChange={(e) => {
                                             onChangeCarousels(activeIndex, "header", e.target.value)
                                         }}
                              />
                          </div>
                      </Box>
                      <Divider sx={{ mt: 2, mb: 4 }} />
                      <Box>
                          <SettingFormLabel label={"내용"} isRequired={true} />

                          <VariableTextArea message={activeCarousel.message}
                                            variables={variables}
                                            regex={regex}
                                            maxLength={180}
                                            onChangeMessage={(message) => onChangeCarousels(activeIndex, "message", message)}
                          />
                      </Box>
                      <Divider sx={{ mt: 2, mb: 4 }} />
                      <ButtonsForm maxLength={2} buttons={activeAttachment.buttons} onChangeButtons={(index, key, value) => {
                          onChangeCarouselButtons(activeIndex, index, key, value)
                      }} />
                  </Box>
                  <Divider sx={{ mt: 2, mb: 4 }} />
                  <div className="flex-col gap-2">
                      <SettingFormLabel label={"더보기"} />
                      <RadioGroup
                          items={[
                              {
                                  label: "없음",
                                  value: "none"
                              },
                              {
                                  label: "링크",
                                  value: "link"
                              }
                          ]}
                          initChecked={tail.urlMobile ? "link" : "none"}
                          onSelected={(value) => {
                              if (value === "none") {
                                  onChangeTail("urlMobile", undefined)
                              } else {
                                  onChangeTail("urlMobile", "")
                              }
                          }}
                      />
                      {
                          tail.urlMobile?.length >= 0 && (
                              <div className="flex-col gap-2">
                                  <div className="flex gap-2">
                                      <div style={{width: "100%", flex: "1"}}>
                                          <TextInput
                                              placeholder="연결할 페이지의 URL을 입력해주세요."
                                              value={tail.urlMobile ?? ""}
                                              onChange={(e) =>
                                                  onChangeTail("urlMobile", e.target.value)
                                              }
                                          />
                                      </div>
                                      <NormalButton
                                          variant={"outlined"}
                                          onClick={() => window.open(tail.urlMobile)}
                                      >
                                          URL 확인
                                      </NormalButton>
                                  </div>
                                  <div>PC 전용 링크</div>
                                  <div className="flex gap-2">
                                      <div style={{width: "100%", flex: "1"}}>
                                          <TextInput
                                              placeholder="연결할 페이지의 URL을 입력해주세요."
                                              value={tail.urlPc ?? ""}
                                              onChange={(e) =>
                                                  onChangeTail("urlPc", e.target.value)
                                              }
                                          />
                                      </div>
                                      <NormalButton
                                          variant={"outlined"}
                                          onClick={() => window.open(tail.urlPc)}
                                      >
                                          URL 확인
                                      </NormalButton>
                                  </div>
                              </div>
                          )
                      }
                  </div>
              </Box>
          </Box>
        </>
    );
};

export default CarouselMessageSetting;
