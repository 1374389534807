import {Button, Table, TextInput} from "omoplay";
import {getEventList} from "../../../api/event/event.api";
import {useEffect, useState} from "react";
import Badge from "../badge/Badge";
import EventPreview from "../event/EventPreview";
import SuspensePopup from "../../SuspensePopup";

export const EventSimpleList = ({onSelected, excludeEventIds}) => {
    const [eventTableList, setEventTableList] = useState([])
    const [totalSize, setTotalSize] = useState(0)
    const rowSize = 5
    const [showPreviewId, setPreviewId] = useState(undefined)
    const [page, setPage] = useState(1)

    const headers = [
        { name: "이벤트명", sortable: false }, { name: "혜택수", sortable: false },
        { name: "혜택", sortable: false }, { name: "보기", sortable: false, align: "center" },
        { name: "선택", align: "center" }
    ]

    useEffect(() => {
        getEventList(null, null, "ACTIVE").then(({data}) => {
            const eventList = data.content.filter(event => !excludeEventIds?.some(excludeEventId => excludeEventId === event.id))
            setTotalSize(eventList.length)
            setEventTableList(eventList.map(event => {
                return [
                    <div>
                        <div style={{width: "fit-content"}}>
                            {
                                event.type === "skip" ? <Badge name="바로당첨형" color="blue" /> : <Badge name="룰렛형" color="blue" />
                            }
                        </div>
                        <div>{event.name}</div>
                        <div>{event.modifiedAt} 업데이트</div>
                    </div>,
                    <div>{event.benefits.length}</div>,
                    <div>
                        {
                            event.benefits.map(benefit => {
                                return (
                                    <div>{benefit.name}</div>
                                )
                            })
                        }
                    </div>,
                    <div className="text-center">
                        <Button variants="default" value="보기" onClick={() => {
                            setPreviewId(event.id)
                        }} />
                    </div>,
                    <div className="text-center">
                        <Button variants="dark" value="선택" onClick={() => { onSelected(event) }} />
                    </div>
                ]
            }))
        })
    }, [page])

    return (
        <>
            <div className="flex-col gap-4" style={{marginTop: "24px"}}>
                <div className="flex justify-between">
                    <TextInput showIcon={true} placeholder="검색어를 입력해 주세요." />
                </div>
                <div>
                    <Table headers={headers} data={eventTableList} pagination={{
                        rowSize: rowSize,
                        totalSize: totalSize,
                        onPaging: (page) => setPage(page)
                    }} />
                </div>
            </div>
            {
                showPreviewId && (
                    <SuspensePopup
                        title={"이벤트 미리보기"}
                        size={"w-full max-w-xl"}
                        onClose={() => setPreviewId(undefined)}
                    >
                        <EventPreview id={showPreviewId} />
                    </SuspensePopup>
                )
            }
        </>
    )
}

export default EventSimpleList
