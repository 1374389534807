import moment from "moment";
import { useEffect, useState } from "react";
import { getBillInfo } from "../../api/cash/cash.api";
import SortableTableHeader from "../table/SortableTableHeader";
import SuspensePopup from "../SuspensePopup";
import BillDetail from "../BillDetail";
import { range } from "lodash";
import InputGroup from "../form/InputGroup";
import {Panel} from "omoplay";

export const BillWrap = () => {
  const [date, setDate] = useState(moment())
  const [data, setData] = useState([]);
  const [popup, setPopup] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0)


  useEffect(() => {
    const searchDate = date
    getBillInfo(searchDate.format("YYYY-MM-01"), searchDate.endOf("month").format("YYYY-MM-DD")).then(({data}) => {
      setData(data.days);
      setTotalPrice(data.days.reduce((a, b) => {
        return (
            b.items.reduce((a, b) => {
              return b.price * -1 + a;
            }, 0) + a
        );
      }, 0))
    })
  }, [date]);

  const headers = [
    { key: "issuedAt", label: "일자", sortable: false },
    { key: "name", label: "내역", sortable: false },
    {
      key: "category",
      label: "항목",
      sortable: false,
    },
    {
      key: "perPrice",
      label: "단가",
      sortable: false,
    },
    {
      key: "count",
      label: "사용량",
      sortable: false,
    },
    {
      key: "price",
      label: "청구 금액",
      sortable: false,
    },
  ];

  return (
    <div>
      <div className="p-4 w-full flex gap-4">
        <select
          className="w-100 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block p-2 disabled:text-gray-400 hover:disabled:bg-gray-300"
          onChange={(e) => {
            setDate((prev) => {
              return moment(prev).set("year", Number(e.target.value)).set("month", date.month())
            })
          }}
        >
          {range(2024, Number(moment().format("YYYY")) + 1).map((year) => {
            return <option value={year} selected={date.year() === year}>{year}년</option>;
          })}
        </select>
        <div className="-space-x-px text-base h-10 flex gap-2 items-center">
          <select
              className="w-100 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block p-2 disabled:text-gray-400 hover:disabled:bg-gray-300"
              onChange={(e) => {
                setDate((prev) => {
                  return moment().set("year", date.year()).set("months", Number(e.target.value) - 1)
                })
              }}
          >
            {range(1, 13).map((month) => {
              return <option value={month} selected={date.month() === month - 1}>{month}</option>;
            })}
          </select>
        </div>
      </div>
      <Panel items={[
        {title: `${date.year()}년 ${date.month() + 1}월 청구 금액`, value: totalPrice.toLocaleString(), suffix: "원"},
        {title: "사용 기간", value: `${date.year()}.${date.month() + 1}.01 ~ ${date.year()} ${date.month() + 1}.${date.endOf("month").format("DD")}`}
      ]} />
      <div className="w-full mt-8 flex-col gap-8">
        <InputGroup label="캐시 충전/사용 내역">
          <div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border mb-4 relative ">
              <thead className="text-xs text-gray-700 uppercase rounded-tl border-b">
              <tr className="divide-x">
                {headers.map((header, index) => {
                  return (
                      <SortableTableHeader
                          key={"bill-wrap-table-header" + index}
                          header={header}
                      />
                  );
                })}
              </tr>
              </thead>
              <tbody>
              {data
                  ?.filter((row) => row.items.length > 0)
                  .map((row, index) => {
                    const cs = "px-3 py-2 ";
                    const rowspan = row.items.length;
                    const items = [...row.items];
                    const firstItem = items.shift();
                    return (
                        <>
                          <tr
                              className="bg-white border-b hover:bg-gray-50 divide-x"
                              key={"bill-" + row.day}
                          >
                            <td className={cs} rowSpan={rowspan}>
                              {row.day}
                            </td>
                            <td
                                className={cs + "font-semibold text-gray-800"}
                                rowSpan={rowspan}
                            >
                              메시지 발송 비용
                            </td>
                            <td className={cs}>{firstItem.historyType}</td>
                            <td className={cs}>
                              {firstItem.unitPrice.toLocaleString()}
                            </td>
                            <td className={cs + "text-right"}>
                              {firstItem.count.toLocaleString()}
                            </td>
                            <td className={cs + "font-bold text-right"}>
                              ₩{(firstItem.price * -1).toLocaleString()}
                            </td>
                          </tr>
                          {items.map((item, j) => {
                            return (
                                <tr
                                    className="border-b bg-white hover:bg-gray-50 divide-x"
                                    key={"bill-category-" + j}
                                >
                                  <td className={cs + "border-l"}>
                                    {item.historyType}
                                  </td>
                                  <td className={cs}>
                                    {item.unitPrice.toLocaleString()}
                                  </td>
                                  <td className={cs + "text-right"}>
                                    {item.count.toLocaleString()}
                                  </td>
                                  <td className={cs + "font-bold text-right"}>
                                    ₩{(item.price * -1).toLocaleString()}
                                  </td>
                                </tr>
                            );
                          })}
                        </>
                    );
                  })}
              <tr className="bg-gray-50 text-sm divide-x">
                <th className="px-6 py-3 rounded-bl">총합</th>
                <th
                    className="text-right px-6 font-bold py-3 rounded-br"
                    colSpan={5}
                >
                  ₩{totalPrice.toLocaleString()}
                </th>
              </tr>
              </tbody>
            </table>
          </div>
        </InputGroup>
        <SuspensePopup
            title={"세부내역"}
            visible={popup}
            onClose={() => setPopup(null)}
        >
          <BillDetail
              type={popup}
              startDate={`${date.year()}-${date.month() + 1}-01`}
              endDate={`${date.year()}-${date.month() + 1}-${date.endOf("month").format("DD")}`}
          />
        </SuspensePopup>
      </div>
    </div>
  );
};

export default BillWrap;
