import {Button, Table, TextInput} from "omoplay";
import {useEffect, useState} from "react";
import {getProjectList} from "../../../api/project/project.api";

export const ProjectSimpleList = ({selectedProjectList, excludeProjectId, onSelected}) => {
    const [projectList, setProjectList] = useState([])
    const [projectTableList, setProjectTableList] = useState([])
    const [checkedProjectList, setCheckedProjectList] = useState([])
    const rowSize = 5

    const headers = [
        { name: "프로젝트 이름", sortable: false }, { name: "상태", sortable: false }, { name: "프로젝트 기간", sortable: false }
    ]

    useEffect(() => {
        getProjectList().then(({data}) => {
            const filterData = data.filter(projectData =>  projectData.id !== excludeProjectId)
                .filter(projectData => !selectedProjectList.some(selectedProject =>
                        selectedProject.id === projectData.id
                    )
                )
            setProjectList(filterData)
            setProjectTableList(filterData.map(project => {
                return [
                    <div className="font-semibold">{project.name}</div>,
                    <div>{project.statusKor}</div>,
                    <div>{project.startDate} ~ {project.endDate ?? "상시 진행"}</div>
                ]
            }))
        })
    }, [selectedProjectList])

    return (
      <div className="flex-col gap-4" style={{marginTop: "24px"}}>
          <div className="flex justify-between">
              <TextInput showIcon={true} placeholder="검색어를 입력해 주세요." />
              <Button variants="dark" value="선택 연결" onClick={() => { onSelected(checkedProjectList) }} />
          </div>
          <div>
              <Table checkbox={true} headers={headers} data={projectTableList}
                     onCheckIndex={(index, checked) => {
                         const newCheckedProjectList = [...checkedProjectList]
                         if (checked) {
                             newCheckedProjectList.push(projectList.find((value, _index) => _index === index))
                             setCheckedProjectList(newCheckedProjectList)
                         } else {
                             setCheckedProjectList(newCheckedProjectList.filter((value, _index) => _index !== index))
                         }
                     }}
                     onCheckedAll={(page, checked) => {
                         if (checked) {
                             setCheckedProjectList([...projectList.filter((value, index) => index < page * rowSize && index >= (page - 1) * rowSize)])
                         } else {
                             setCheckedProjectList([])
                         }
                     }}
                     pagination={{
                          rowSize: rowSize,
                          totalSize: projectTableList.length
                      }}
              />
          </div>
      </div>
    )
}

export default ProjectSimpleList
