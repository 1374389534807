import EditTitle from "./EditTitle";
import EditController from "./EditController";
import {Stepper} from "omoplay";
const SettingsLayout = ({
  title,
  tabs = [],
  activeTab = 1,
  children,
  onChange,
  isTemporarySave = true,
  onTemporarySave,
  onConfirm,
  isConfirm = true,
  onClose,
  isCancel = false,
  confirmText = "완료",
  nextButtonDisabled
}) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <div className="min-h-screen bg-slate-200">
      <EditTitle title={title} />
      <div className="flex justify-center">
        <div style={{paddingTop: "80px", height: "100vh"}}>
          {
            tabs.length > 0 && (
                  <div className="flex-center-all">
                    <Stepper
                        listLabel={tabs}
                        totalStep={tabs.length}
                        stepActive={activeTab}
                    />
                  </div>
              )
          }
          <div className="mb-[120px]" style={{height: "calc(100vh - 220px)", overflow: "scroll"}}>
            {children}
          </div>
          <EditController
              tabs={tabs}
              activeTab={activeTab}
              onChange={onChange}
              isTemporarySave={isTemporarySave}
              onTemporarySave={onTemporarySave}
              onConfirm={onConfirm}
              isConfirm={isConfirm}
              confirmText={confirmText}
              isCancel={isCancel}
              onClose={handleClose}
              nextButtonDisabled={nextButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};
export default SettingsLayout;
