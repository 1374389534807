import {Button, RadioGroup, TextInput} from "omoplay";
import {useEffect, useState} from "react";
import {postProjectPassword} from "../../../api/project/project.api";


const ProjectDetailBenefitPassword = ({projectId, benefitPassword}) => {
    const [password, setPassword] = useState()
    const [showPassword, setShowPassword] = useState(false)

    const onSubmit = (password) => {
        postProjectPassword(projectId, password).then(() => {
            alert("적용 되었습니다.")
        })
    }

    useEffect(() => {
        setPassword(benefitPassword)
        setShowPassword(benefitPassword ?? false)
    }, [benefitPassword]);

    return (
        <div className="flex-col gap-4">
            <div className="flex justify-between">
                <div>
                    <div className="font-bold font-20px">혜택 사용 비밀번호</div>
                </div>
                <div>
                    <Button variants="dark" value="적용하기" onClick={() => onSubmit(password)} />
                </div>
            </div>
            <div className="flex-align-center gap-4 w-full bg-grey-0 radius-6 p-20px">
                <div>
                    <RadioGroup key={`${showPassword}`} items={[{ label: "사용 안함", value: "disabled"}, { label: "사용함", value: "enabled"}]}
                                onSelected={(value) => {
                                    if (value === "disabled") {
                                        setPassword(undefined)
                                    }
                                    setShowPassword(value === "enabled")
                                }}
                                initChecked={showPassword && "enabled"}
                    />
                </div>
                {
                    showPassword && (
                        <div>
                            <TextInput type="numeric"
                                       value={password}
                                       placeholder="4자리 숫자"
                                       onChange={(e) => {
                                           setPassword(e.target.value)
                                       }}
                                       maxLength={4}
                            />
                        </div>
                    )
                }
            </div>
            <p className="text-mainblue-0 text-sm">
                혜택을 사용할 때, 비밀번호를 입력합니다. (직원 확인 혜택에만 적용)
            </p>
        </div>
    )
}

export default ProjectDetailBenefitPassword