import {Button, Pagination, Table, TextInput} from "omoplay";
import {useEffect, useState} from "react";
import {listBenefit} from "../../../api/benefit/benefit.api";
import moment from "moment/moment";
import DescriptionBar from "../../DescriptionBar";

const BenefitForm = ({ onSelectedBenefit }) => {
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(1);
  const [benefits, setBenefits] = useState([]);
  const [totalPage, setTotalPages] = useState(0);
  const rowSize = 5

  const headers = [
    { name: "혜택명" },
    {
      name: "원가",
      width: "100px",
      align: "center",
    },
    {
      name: "소비자가",
      width: "100px",
      align: "center",
    },
    {
      name: "혜택 형식",
      width: "110px",
      align: "center",
    },
    {
      name: "생성일",
      width: "170px",
      align: "center",
    },
    {
      name: "",
      width: "120px",
      align: "center",
    },
  ]

  useEffect(() => {
    listBenefit(page, rowSize, "ACTIVE").then(({data}) => {
      setBenefits(data.content)
      setTotalPages(data.totalElements)
    })
  }, [page])

  useEffect(() => {
    setTableData(benefits.map(benefit => {
      return [
        <div>
          <div>{benefit.name}</div>
          <div className="text-grey-4">{moment(benefit.modifiedAt).format("YYYY-MM-DD HH:mm")} 업데이트</div>
        </div>,
        <div className="text-center">{benefit.supplyPrice.toLocaleString()}원</div>,
        <div className="text-center">{benefit.retailPrice ? `${benefit.retailPrice.toLocaleString()}원` : "미설정"}</div>,
        <div className="text-center">{benefit.typeKor}</div>,
        <div className="text-center">
          {benefit.createdAt}
        </div>,
        <div className="text-center">
          <Button variants="dark" value="선택" onClick={() => onSelectedBenefit(benefit.id, benefit.name)} />
        </div>
      ]
    }))
  }, [benefits])

  return (
      <div className="flex-col gap-3" style={{height: "50%"}}>
        <DescriptionBar description="임시저장된 혜택이나 일부 설정에서는 특정 혜택이 보이지 않을 수 있습니다." />
        <div className="flex">
          <div><TextInput showicon="true" placeholder="검색어를 입력해 주세요" /></div>
        </div>
        <div>
          <Table headers={headers} data={tableData} />
          <div className="flex justify-center" style={{marginTop: "24px"}}>
            <Pagination rowSize={rowSize} totalSize={totalPage} page={page} onPaging={(page) => setPage(page)} />
          </div>
        </div>
      </div>
  )
};

export default BenefitForm;
