import Calendar from 'react-calendar';
import {useState} from "react";
import 'react-calendar/dist/Calendar.css';

const AIAgentAutomationList = () => {

    const [value, onChange] = useState(new Date());

    return (
        <div>
            <Calendar value={value} onChange={onChange} />
        </div>
    )
}

export default AIAgentAutomationList