import SpecificCustomerStatistics from "./SpecificCustomerStatistics";
import {useEffect, useState} from "react";
import {getCustomersStatistics} from "../../api/statistics/statistics.api";
import {getDefaultDateRange} from "../../libs/helper";
import ProjectCalendarSearchBar from "../ProjectCalendarSearchBar";

const UserStatistics = () => {
  const [dateRange, setDateRange] = useState(getDefaultDateRange())
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    getCustomersStatistics(dateRange.startDate, dateRange.endDate).then(({data}) => {
      setCustomers(data)
    })
  }, [dateRange]);

  return (
    <>
      <div className="page-title">고객 분석</div>
      <div>
        <ProjectCalendarSearchBar
            onConfirm={(dateProps) => {
              setDateRange(dateProps)
            }} />
      </div>
      <SpecificCustomerStatistics customers={customers} />
    </>
  );
};
export default UserStatistics;
