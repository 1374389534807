import {Alert, Checkbox, RadioGroup, TextInput} from "omoplay";
import PlusIcon from "../../assets/images/icon.plus.svg";
import {useEffect, useState} from "react";
import {getCardInfoList, registrationCardBill} from "../../api/cash/cash.api";
import InputGroup from "./InputGroup";
import SuspensePopup from "../SuspensePopup";
import CardDesign from "./CardDesign";


const CardBillingForm = ({onSelectedCard}) => {
    const [cardInfoItems, setCardInfoItems] = useState([])
    const [showRegistration, setShowRegistration] = useState(false)
    const [cardType, setCardType] = useState("0")
    const [cardNumber, setCardNumber] = useState(["", "", "", ""])
    const [cardExpire, setCardExpire] = useState(["", ""])
    const [birth, setBirth] = useState("")
    const [pwd, setPwd] = useState("")
    const [isDefault, setIsDefault] = useState(false)

    useEffect(() => {
        getCardInfoList().then(({data}) => {
            setCardInfoItems(data)
            onSelectedCard?.(data[0].id)
            setIsDefault(data.length === 0)
        })
    }, []);

    const registration = () => {
        if (window.confirm("결제 승인 확인을 위해 1,000원이 결제된 후 취소 됩니다.")) {
            registrationCardBill(cardNumber.reduce((previousValue, currentValue) => previousValue + currentValue),
                cardExpire.reduce((previousValue, currentValue) => previousValue + currentValue),
                cardType, birth, pwd, isDefault).then(({data}) => {
                alert(data === "정상처리" ? "등록 되었습니다" : data)
                if (data === "정상처리") {
                    setShowRegistration(false)
                }
            })
        }
    }

    return (
        <>
            <InputGroup label="결제 카드 정보">
                <div className="w-full overflow-scroll show-scrollbar">
                    <div className="flex-align-center gap-[24px] w-fit">
                        {
                            cardInfoItems.map(cardInfo => {
                                return (
                                    <CardDesign cardInfo={cardInfo} />
                                )
                            })
                        }
                        <div className="w-[320px] h-[200px] border rounded-[8px] bg-white flex-center-all">
                            <div className="rounded-[36px] bg-pointactive w-[56px] h-[56px] flex-center-all pointer" onClick={() => setShowRegistration(true)}>
                                <img src={PlusIcon} alt="add-card" />
                            </div>
                        </div>
                    </div>
                </div>
            </InputGroup>
            <SuspensePopup title="결제 카드 추가" style={{maxWidth: "600px"}}
                           visible={showRegistration}
                           onConfirm={registration}
                           onClose={() => setShowRegistration(false)}>
                <div className="flex-col gap-[24px]">
                    <div className="flex-col gap-2">
                        <div>카드 번호<span className="ml-1 text-red-500">*</span></div>
                        <div className="flex gap-2">
                            <TextInput placeholder="1111" showCount={false} maxLength={4}
                                       autoComplete="off"
                                       onChange={(e) => {
                                           setCardNumber((prev) => {
                                               prev[0] = e.target.value
                                               return [...prev]
                                           })}}
                            />
                            <TextInput placeholder="2222" showCount={false} maxLength={4}
                                       autoComplete="off"
                                       onChange={(e) => {
                                           setCardNumber((prev) => {
                                               prev[1] = e.target.value
                                               return [...prev]
                                           })}}
                            />
                            <TextInput type="password" placeholder="****" showCount={false} maxLength={4}
                                       autoComplete="new-password"
                                       onChange={(e) => {
                                           setCardNumber((prev) => {
                                               prev[2] = e.target.value
                                               return [...prev]
                                           })}}
                            />
                            <TextInput type="password" placeholder="****" showCount={false} maxLength={4}
                                       autoComplete="new-password"
                                       onChange={(e) => {
                                           setCardNumber((prev) => {
                                               prev[3] = e.target.value
                                               return [...prev]
                                           })}}
                            />
                        </div>
                    </div>
                    <div className="flex-col gap-2">
                        <div>카드 유효기간<span className="ml-1 text-red-500">*</span></div>
                        <div className="flex-align-center gap-2">
                            <TextInput placeholder="11" showCount={false} maxLength={2}
                                       style={{width: "40px"}}
                                       onChange={(e) => {
                                           setCardExpire((prev) => {
                                               prev[1] = e.target.value
                                               return [...prev]
                                           })}}
                            />월
                            <TextInput placeholder="22" showCount={false} maxLength={2}
                                       style={{width: "40px"}}
                                       onChange={(e) => {
                                           setCardExpire((prev) => {
                                               prev[0] = e.target.value
                                               return [...prev]
                                           })}}
                            />년
                        </div>
                    </div>
                    <div className="flex-col gap-2">
                        <div>카드 비밀번호 앞 2자리<span className="ml-1 text-red-500">*</span></div>
                        <div className="flex-align-center gap-2">
                            <TextInput placeholder="**" type="password" showCount={false} maxLength={2}
                                       style={{width: "50px"}}
                                       onChange={(e) => {
                                           setPwd(e.currentTarget.value)
                                       }}
                            />
                            <div className="text-[20px] font-medium">*</div>
                            <div className="text-[20px] font-medium">*</div>
                        </div>
                    </div>
                    <div className="flex-col gap-2">
                        <div>
                            <RadioGroup items={[{label: "개인 카드", value: "0"}, {label: "법인 카드", value: "1"}]}
                                        onSelected={(value) => setCardType(value)} />
                        </div>
                        <TextInput placeholder={cardType === "0" ? "생년월일 6자리를 입력하세요" : "사업자번호 등록번호 최대 11자리를 입력하세요"} showCount={false}
                                   maxLength={cardType === "0" ? 6 : 11}
                                   onChange={(e) => {
                                       setBirth(e.currentTarget.value)
                                   }}
                        />
                        <div>
                            <Checkbox onChecked={setIsDefault} item={{
                                label: "이 카드를 기본 결제 카드로 설정합니다",
                                value: "default",
                                checked: cardInfoItems.length === 0
                            }} disabled={cardInfoItems.length === 0} />
                        </div>
                    </div>
                    <Alert title="참고해주세요" type="info" withIcon={true}>
                        <li>
                            결제 카드 추가시 승인 확인을 위해 1,000원이 결제된 후 취소 됩니다.
                        </li>
                        <li>
                            결제정보는 PG사에 안전하게 암호화되어 저장되며, 오모플레이는 결제정보를<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;직접 저장하지 않습니다.
                        </li>
                    </Alert>
                </div>
            </SuspensePopup>
        </>
    )
}

export default CardBillingForm