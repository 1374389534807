const moment = require("moment");

export const getDefaultDateRange = () => {
  return {
    startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };
};
export const getAllDateRange = () => {
  const format = "YYYY-MM-DD"
  return {
    startDate: moment().subtract(1, "year").format(format),
    endDate: moment().format(format),
  };
};

export const range = (size, startAt) => {
  return [...Array(size).keys()].map((i) => i + startAt);
};

export const getMessagePrice = (messageType) => {
  if (messageType === "FW") {
    return 27;
  } else if (messageType === "FC" || messageType === "FL") {
    return 35;
  }  else {
    return 25;
  }
};
