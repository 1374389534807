import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GrayButton from "../GrayButton";
import {
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  copyMessage, getVolatilityClickUsers, getVolatilityMessage,
} from "../../api/message/volatility-message.api";
import {useSearchParams} from "react-router-dom";
import MessageWrapPreview from "./content/preview/MessageWrapPreview";
import {getCampaignMessages} from "../../api/message/messages.api";
import {
  Button, Chip,
  CoinIcon,
  CouponIcon,
  MouseIcon,
  Pagination,
  Panel,
  Table,
  UsersIcon
} from "omoplay";
import {userBarChartConfig, userLineChartConfig} from "../../constants/chart_config";
import DateChart from "../DateChart";
import CommonCalendarSearchBar from "../CommonCalendarSearchBar";
import {getMessageOrdersStatistics} from "../../api/statistics/statistics.api";
import InputGroup from "../form/InputGroup";

export const VolatilityMessageDetail = () => {
  const [query] = useSearchParams();
  const id = query.get("id")
  const [messageUserPage, setMessageUserPage] = useState(1);
  const [messageUserTotalPages, setMessageUserTotalPages] = useState(0);
  const [sendUsersTable, setSendUsersTable] = useState([]);
  const [volatilityData, setVolatilityData] = useState({})
  const [benefitTableData, setBenefitTableData] = useState([])
  const [previewData, setPreviewData] = useState({})
  const [filterCategories, setFilterCategories] = useState([])
  const [clickUserData, setClickUserData] = useState({})
  const [contentsClickUserTable, setContentsClickUserTable] = useState([])
  const [totalClickUserTable, setTotalClickUserTable] = useState([])
  const [clickUserChartConfig, setClickUserChartConfig] = useState({...userBarChartConfig})
  const [clickUserLineChartConfig, setClickUserLineChartConfig] = useState({...userLineChartConfig})
  const [dateRange, setDateRange] = useState({})
  const [orderTables, setOrderTables] = useState([])
  const [orderTotalCount, setOrderTotalCount] = useState(0)
  const [orderTotalSales, setOrderTotalSales] = useState(0)
  const [orderPage, setOrderPage] = useState(1)

  const orderLimit = 10

  const clickUserHeaders = [
    { name: "이름", align: "center", width: "100px" },
    { name: "성별", align: "center", width: "100px" },
    { name: "연령", align: "center", width: "120px" },
    { name: "회원가입" },
    { name: "클릭 수" },
    { name: "클릭 시간" },
  ];
  const sendUserHeaders = [
    { name: "이름", align: "center", width: "100px" },
    { name: "성별", align: "center", width: "100px" },
    { name: "연령", align: "center", width: "120px" },
    { name: "회원가입" },
    { name: "상태" },
  ];

  const benefitHeaders = [
    { name: "혜택명"},
    { name: "금액" },
    { name: "형식" },
    { name: "사용 수 / 발급 수" },
  ];
  const orderHeaders = [
    { name: "상품명", align: "center" },
    { name: "이름", align: "center", width: "100px" },
    { name: "성별", align: "center", width: "70px" },
    { name: "연령", align: "center", width: "110px" },
    { name: "회원가입", align: "center", width: "120px" },
    { name: "상태", width: "70px", align: "center" },
    { name: "주문 상태", width: "100px", align: "center" },
    { name: "결제 가격", width: "120px", align: "center" },
    { name: "결제 날짜", width: "120px", align: "center" },
  ];


  const displayGenderText = (gender) => {
    if (gender === "male") {
      return "남성";
    } else if (gender === "female") {
      return "여성";
    } else {
      return gender;
    }
  }

  useEffect(() => {
    getVolatilityMessage(id).then(({data}) => {
      setVolatilityData(data);
      setBenefitTableData(data.benefitList?.map((benefit, index) => {
        return [
            <div>{benefit.name}</div>,
            <div>{benefit.price.toLocaleString()}원</div>,
            <div>{benefit.type}</div>,
            <div>{benefit.usedAndIssued}</div>
        ]
      }))
      setFilterCategories(data.filters)
      setPreviewData(data.messageData)
    })

    getVolatilityClickUsers(id, dateRange.startDate, dateRange.endDate).then(({data}) => {
      if (data) {
        setClickUserData(data)
        setContentsClickUserTable(data.contents.map(content => {
          return [
            <div>
              {
                content.typeName === "버튼"
                    ? <Button variants="grey" value={content.name} />
                    : (content.typeName === "이미지" || content.typeName === "아이템" ? <div className="flex-align-center gap-2"><img alt={content.name} src={content.imgUrl} width={128} height={128}></img>{content.name}</div> : <></>)
              }
            </div>,
            <div className="flex-align-center h-full">{content.typeName}</div>,
            <div className="flex-align-center h-full">{content.totalCount.toLocaleString()}회 / {content.users.length.toLocaleString()}명</div>,
          ]
        }))
        setTotalClickUserTable(data.totalClickUsers.map(user => {
          return [
            <div className="text-center">{user.name}</div>,
            <div className="text-center">{displayGenderText(user.gender)}</div>,
            <div className="text-center">{user.age}</div>,
            <>
              <div
                  className="flex text-primary"
                  style={{
                    maxWidth: "180px",
                    textOverflow: "ellipsis",
                    overflowX: "clip",
                    whiteSpace: "nowrap",
                  }}
              >
                {user.spotName}
              </div>
              <div>
                {user.createdAt}
              </div>
            </>,
            <div>{user.clickNum}</div>,
            <div>{user.clickDates.map(date => {
              return (
                  <div>{date}</div>
              )
            })}</div>
          ]
        }))

        const userBarConfig = { ...userBarChartConfig };
        userBarConfig.series[0].data = [];
        userBarConfig.series[1].data = [];

        Object.entries(data.groupAgeTotalCount).forEach(([key, value]) => {
          userBarConfig.series[0].data.push({
            x: key,
            y: value,
          });
        });
        Object.entries(data.groupAgeClickCount).forEach(([key, value]) => {
          userBarConfig.series[1].data.push({
            x: key,
            y: value,
          });
        });
        setClickUserChartConfig(userBarConfig);

        const userLineConfig = { ...userLineChartConfig };
        userLineConfig.series[0].data = [];

        Object.entries(data.groupDateClickCount).forEach(([key, value]) => {
          userLineConfig.series[0].data.push({
            x: key,
            y: value,
          });
        });
        setClickUserLineChartConfig(userLineConfig)
      }
    })
  }, [id, dateRange]);

  useEffect(() => {
    getMessageOrdersStatistics(id, orderPage, orderLimit).then(({data}) => {
      setOrderTotalCount(data.totalCount)
      setOrderTotalSales(data.totalSales)
      setOrderTables(data.users.map(user => {
        return [
          <div className="">{user.productNames.map(name => <div>{name}</div>)}</div>,
          <div className={`flex-center-all h-full break-all`}>{user.name}</div>,
          <div className="flex-center-all h-full">{user.gender}</div>,
          <div className="flex-center-all h-full">{user.age}</div>,
          <div className="flex-center-all h-full">{user.entryPointName}</div>,
          <div className="flex-center-all h-full">{user.friendStatus}</div>,
          <div className="flex-center-all h-full">{user.productStatus}</div>,
          <div className="flex-center-all h-full">{user.paymentAmount.toLocaleString()}원</div>,
          <div className="flex-center-all h-full">{user.paymentDate}</div>,
        ]
      }))
    })
  }, [id, orderPage]);

  useEffect(() => {
    getCampaignMessages(id, messageUserPage - 1).then(({data}) => {
      setSendUsersTable(data.content.map(user => {
        return [
          <div className="text-center">{user.name}</div>,
          <div className="text-center">{displayGenderText(user.gender)}</div>,
          <div className="text-center">{user.age}</div>,
          <>
            <div
                className="flex text-primary"
                style={{
                  maxWidth: "180px",
                  textOverflow: "ellipsis",
                  overflowX: "clip",
                  whiteSpace: "nowrap",
                }}
            >
              {user.spotName}
            </div>
            <div>
              {moment(user.createdAt).format("YYYY.MM.DD HH:mm")}
            </div>
          </>,
          <div>
            {displayStatus(user.status, user.errorCode)}
          </div>
        ]
      }));
      setMessageUserTotalPages(data.totalElements);
    })
  }, [id, messageUserPage]);


  const displayStatus = (status, errorCode) => {
    if (status === "2") {
      return <span>발송 성공</span>
    } else if (status === "4") {
      return <><span style={{color: "#CE326C"}}>발송 실패</span><br/><span>({errorCode})</span></>
    } else {
      return <span>결과 대기</span>;
    }
  };

  return (
      <div>
        <div className="flex justify-between items-center mb-2">
          <div className="flex gap-4">
            <div className="w-10 h-10">
              <GrayButton
                  className="w-10 h-10"
                  onClick={() => window.location.href = ("/volatility-message")}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </GrayButton>
            </div>
            <h4 className="text-3xl font-semibold">{volatilityData.campaignName}</h4>
          </div>
          <div>
            <Button variants="dark" value="복제하기" onClick={() => {
              if (window.confirm("정말 복제하시겠습니까?")) {
                copyMessage(id).then(() => {
                  window.location.href = "/volatility-message"
                })
              }
            }} />
          </div>
        </div>
        <div className="flex-col gap-2">
          <div>
            <div className="flex-col gap-2">
              <label className="text-lg font-semibold text-gray-900 relative">
                메시지 발송 결과
              </label>
              <div className="white border border-gray-300 rounded p-4 bg-white">
                <p>
                  <span className="font-semibold">발송 일시 : </span>
                  {volatilityData.issuedAt}
                </p>
              </div>
              <div className="flex-col gap-20 mt-8">
                <Panel items={[
                  {
                    title: (
                        <>
                          <div className='panel-icon'>
                            <UsersIcon />
                          </div>
                          <p>발송 성공 / 발송 시도 고객 수</p>
                        </>
                    ),
                    value: `${volatilityData.successCount?.toLocaleString()} 명`,
                    suffix: `/ ${volatilityData.sendCount?.toLocaleString()}명`,
                  },
                  {
                    title: (
                        <>
                          <div className='panel-icon'>
                            <MouseIcon />
                          </div>
                          <p>클릭 고객 수</p>
                        </>
                    ),
                    value: clickUserData?.totalClickUsers?.length?.toLocaleString(),
                    suffix: '명',
                  },
                  {
                    title: (
                        <>
                          <div className='panel-icon'>
                            <CoinIcon />
                          </div>
                          <p>발송 비용</p>
                        </>
                    ),
                    value: volatilityData.totalPrice?.toLocaleString(),
                    suffix: '원',
                  },
                  {
                    title: (
                        <>
                          <div className='panel-icon'>
                            <CouponIcon />
                          </div>
                          <p>쿠폰 사용 / 쿠폰 발급 수</p>
                        </>
                    ),
                    value: volatilityData.issuedCouponCount,
                    suffix: '개',
                  },
                ]} />
              </div>
            </div>
          </div>
          <div>
            <InputGroup label="발송 조건" description="아래 조건을 포함하고 있는 대상에게 발송되었습니다.">
              {filterCategories.length > 0 ?
                  filterCategories.map((filter, i) => {
                    return (<Chip>{filter.label}</Chip>)
                  }) : (<Chip>전체</Chip>
                  )}
            </InputGroup>
          </div>
          <div>
            <InputGroup label="발급 혜택" description="메시지에 포함된 발급 혜택 리스트 입니다.">
              <Table headers={benefitHeaders} data={benefitTableData} />
            </InputGroup>
          </div>
          <InputGroup label="발송 메시지 및 발송 분석">
            <div className="flex">
              <div className="flex-1 flex-col gap-4 pr-[12px]">
                <Panel variants="bar" items={[
                  {title: "링크 총 클릭수", value: `${volatilityData.linkCount?.toLocaleString()}번`, valueStyle: {flex: "1"}}
                ]} />
                <div>
                  <Table headers={
                    [{name: "클릭 컨텐츠"}, {name: "형태", width: "90px"}, {name: "클릭 (횟수/고객수)", width: "160px"}]
                  } data={contentsClickUserTable} />
                </div>
              </div>
              <div className="pl-[12px] flex-1 flex-center-all bg-depth1">
                <MessageWrapPreview messageData={previewData} type={previewData.type} />
              </div>
            </div>
          </InputGroup>
          <InputGroup label="메시지 클릭 고객 분석" description="메시지에 클릭한 고객을 분석합니다">
            <div className="flex-col gap-2">
              <div className="flex justify-between items-center border">
                <div className="flex gap-2 p-20px">
                  <div>클릭 고객 수</div>
                  <div>/</div>
                  <div>메시지 발송 수</div>
                </div>
                <div className="flex-align-center gap-2" style={{paddingRight: "20px"}}>
                  <div style={{fontSize: "26px", fontWeight: "700"}}>{clickUserData?.totalClickUsers?.length?.toLocaleString()}명</div>
                  <div style={{fontSize: "16px"}}>/</div>
                  <div style={{fontSize: "16px"}}>{volatilityData.successCount?.toLocaleString()}명</div>
                  <div style={{fontSize: "16px"}}>({Math.round(clickUserData?.totalClickUsers?.length / volatilityData.successCount * 1000) / 10}%)</div>
                </div>
              </div>
            </div>
            <div>
              <div className="float-right">
                {
                  volatilityData.issuedAt ? (
                      <CommonCalendarSearchBar periodStartDate={moment(volatilityData.issuedAt)}
                                               onConfirm={(dateRange) => {
                                                 setDateRange(dateRange)
                                               }} />
                  ) : <></>
                }
              </div>
              <DateChart
                  keyName={"click_user_line_chart"}
                  chartConfig={clickUserLineChartConfig}
              />
            </div>
            <div>
              <DateChart
                  keyName={"user_bar_chart"}
                  chartConfig={clickUserChartConfig}
              />
            </div>
          </InputGroup>
          <InputGroup label={
            <div className="flex gap-2">
              <div>메시지 클릭 고객</div>
              <div className="text-primary">{clickUserData?.totalClickUsers?.length?.toLocaleString()}명</div>
            </div>
          }
          >
            <Table headers={clickUserHeaders} data={totalClickUserTable}
                   pagination={{pageSize: 10}}
            />
          </InputGroup>
          <InputGroup label={
            <div className="flex gap-2">
              <div>발송 후 24시간 이내 온라인 구매 고객</div>
              <div className="text-primary">{orderTotalCount.toLocaleString()}명</div>/
              <span className="text-primary">{orderTotalSales.toLocaleString()}원</span>
            </div>
          }>
            <Table headers={orderHeaders} data={orderTables} />
            <div className="flex justify-center" style={{marginTop: "24px"}}>
              <Pagination rowSize={orderLimit} totalCount={orderTotalCount} currentPage={orderPage} onPaging={(page) => setOrderPage(page)} />
            </div>
          </InputGroup>
          <InputGroup label={
            <div className="flex gap-2">
              <div>메시지 발송 고객</div>
              <div className="text-primary">{messageUserTotalPages?.toLocaleString()}명</div>
            </div>
          }>
            <Table headers={sendUserHeaders} data={sendUsersTable} />
            <div className="flex justify-center" style={{marginTop: "24px"}}>
              <Pagination rowSize={10} totalCount={messageUserTotalPages} currentPage={messageUserPage} onPaging={(page) => setMessageUserPage(page)} />
            </div>
          </InputGroup>
        </div>
      </div>
  );
};
export default VolatilityMessageDetail;
