import Layout from "../components/layout/Layout";
import ProjectDetail from "../components/form/project/ProjectDetail";

const ProjectDetailPage = () => {

  return (
    <Layout>
        <ProjectDetail />
    </Layout>
  );
};
export default ProjectDetailPage;
