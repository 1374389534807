import {fetchApi} from "../fetch";
import {API_URL} from "../../constants/axios";


export const getCountAllFriend = async () => {
    const res = await fetchApi.get(API_URL.COUNT_ALL_FRIEND)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const getCountExpectedFriend = async () => {
    const res = await fetchApi.get(API_URL.COUNT_EXPECTED_FRIEND)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const getProfileCheck = async (id) => {
    const res = await fetchApi.get(API_URL.MOMENT_PROFILE_CHECK + `?id=${id}`)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}