import InputGroup from "../form/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faCommentSms,
} from "@fortawesome/free-solid-svg-icons";
import { MESSAGE_TYPES } from "../../constants/menus";
import {TextInput} from "omoplay";
import TypeSelectionForm from "../form/TypeSelectionForm";

export const MessageDefaultInfo = ({campaignName, type, onChangeCampaignName, onChangeMessageType}) => {

  return (
    <>
      <div className="flex-col divide-y">
        <InputGroup
          label={"메시지 캠페인명"}
          isRequired={true}
          className="py-8"
        >
          <p className="text-gray-500 text-sm mb-2">
            다른 메시지들과 구분하기 위한 캠페인명을 작성해 주세요.
          </p>
          <TextInput
            value={campaignName}
            placeholder="캠페인명을 입력해 주세요."
            maxLength={40}
            onChange={(e) => onChangeCampaignName(e.target.value)}
          />
        </InputGroup>
        <InputGroup label={"메시지 타입"} isRequired={true} className="py-8">
          <p className="text-gray-500 text-sm mb-2">
            어떤 메시지를 보낼지 골라주세요
          </p>
          <ul className="flex w-full gap-8">
            <li className="border-indigo-500 bg-indigo-50 py-6 w-[200px] rounded-lg flex gap-2 items-center text-gray-600 border justify-center cursor-pointer">
              <div className="rounded-full bg-gray-200 w-[48px] h-[48px] flex items-center justify-center text-indigo-600">
                <FontAwesomeIcon icon={faComment} size={"lg"}></FontAwesomeIcon>
              </div>
              <p className="font-medium">친구톡</p>
            </li>
            <li className="border-gray-100 bg-gray-100 py-6 w-[200px] rounded-lg flex gap-2 items-center text-gray-400 border justify-center">
              <div className="rounded-full bg-gray-200 w-[48px] h-[48px] flex items-center justify-center text-gray-400">
                <FontAwesomeIcon
                  icon={faCommentSms}
                  size={"lg"}
                ></FontAwesomeIcon>
              </div>
              <p className="font-medium">문자</p>
            </li>
          </ul>
        </InputGroup>
        <InputGroup
          label={"친구톡 타입 고르기"}
          isRequired={true}
          className="py-8"
        >
          <p className="text-gray-500 text-sm mb-2">
            어떤 메시지를 보내실지 골라주세요.
          </p>
          <TypeSelectionForm types={MESSAGE_TYPES} selectedType={type === "FT" ? "FI" : type} onChangeType={onChangeMessageType} />
        </InputGroup>
      </div>
    </>
  );
};

export default MessageDefaultInfo;
