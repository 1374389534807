import {MockUp} from "omoplay";
const MobilePreview = ({ children }) => {
  return (
      <MockUp backgroundColor="white">
        {children}
      </MockUp>
  );
};

export default MobilePreview;
