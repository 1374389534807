import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentMask from "./ContentMask";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Suspense } from "react";
import Loading from "./Loading";

const SuspensePopup = ({
  title,
  size,
  onClose,
  children,
  isEditPopup = false,
}) => {
  return (
    <ContentMask includeHeader={isEditPopup}>
      <div
        className={
          "relative max-h-full " + (size ? `${size}` : `w-full`)
        }
        style={{maxWidth: "1280px"}}
      >
        <div className="relative bg-white rounded-lg shadow py-4 px-8">
          <div className="w-100 border-b border-b-gray-300 flex justify-between pt-2 pb-4 items-center">
            <h4 className="text-xl font-bold">{title}</h4>
            <button onClick={onClose}>
              <FontAwesomeIcon icon={faXmark} size={"lg"} color={"#999"} />
            </button>
          </div>
          <Suspense
            fallback={
              <div className="w-100 text-center py-8 justify-center">
                <Loading className={"w-20 h-20 mx-auto text-gray"} />
              </div>
            }
          >
            {children}
          </Suspense>
        </div>
      </div>
    </ContentMask>
  );
};

export default SuspensePopup;
