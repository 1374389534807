import {Button, Panel} from "omoplay";
import {getCreditHistory} from "../api/billing/billing.api";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";


const CreditDetail = () => {
    const [query] = useSearchParams()
    const id = query.get("id")

    const [historyItems, setHistoryItems] = useState([])

    useEffect(() => {
        getCreditHistory(id).then(({data}) => {
            setHistoryItems([
                {
                    title: "결제 일시", value: data.createdAt,
                    titleStyle: {minWidth: "150px"}
                },
                {
                    title: "승인 코드", value: data.tid,
                    titleStyle: {minWidth: "150px"}
                },
                {
                    title: "결제 카드", value: data.maskCardNumber,
                    titleStyle: {minWidth: "150px"}
                },
                {
                    title: "결제 내역", value: `${data.credit.toLocaleString()} ${data.creditType === "AI" ? "Credit" : "예치금"}`,
                    titleStyle: {minWidth: "150px"}
                },
                {
                    title: "결제 금액", value: `${data.amount.toLocaleString()}원`,
                    titleStyle: {minWidth: "150px"}
                },
                {
                    title: "결제 유형", value: data.type,
                    titleStyle: {minWidth: "150px"}
                }
            ])
        })
    }, [])

    return (
        <div className="flex-col gap-4">
            <div className="flex-align-center gap-4" style={{borderRadius: "6px"}}>
                <Button variants="outlined"
                        style={{width: "40px"}}
                        onClick={() => window.location.href = ("/credit")}
                        value={<FontAwesomeIcon icon={faArrowLeft} />}
                />
                <div className="font-20px">결제 내역 상세 정보</div>
            </div>
            <Panel variants="list-bar" items={historyItems} />
        </div>
    )
}

export default CreditDetail