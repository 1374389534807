import CardChipIcon from "../../assets/images/icon.card.chip.svg";
import {Tag} from "omoplay";

const CardDesign = ({cardInfo}) => {
    return (
        <div className="w-[320px] h-[200px] border rounded-[8px] bg-pointactive p-[16px]">
            <div className="flex-col justify-between h-full">
                <div className="flex justify-between">
                    <div>
                        <img src={CardChipIcon} alt="card-chip" />
                    </div>
                    <div>
                        <div className="text-white font-medium">{cardInfo.cardCompany}</div>
                        <div>
                            {
                                cardInfo.isDefault && (
                                    <Tag color="">기본 결제 카드</Tag>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <div className="font-bold text-[20px] text-white">{cardInfo.maskCardNumber}</div>
                </div>
                <div className="flex justify-between">
                    <div className="font-medium text-white">CREDIT CARD</div>
                    <div className="font-medium text-white">{cardInfo.createdAt}</div>
                </div>
            </div>
        </div>
    )
}

export default CardDesign