import CircleBadge from "../CircleBadge";
import {Button, TextInput} from "omoplay";
import {useEffect, useState} from "react";
import {getSpot} from "../../../api/spot/spot.api";

const EntryPointPreviewPopup = ({ spotId, excludeEntryPointIds }) => {
    const [copy, setCopy] = useState(false)
    const [entryPoint, setEntryPoint] = useState({})

    useEffect(() => {
        getSpot(spotId).then(({data}) => {
            setEntryPoint(data)
        })
    }, [spotId]);

  return (
    <div className="flex-col gap-4 py-4">
        <div>
            <label className="flex-align-center gap-2">
                <CircleBadge label={1} />
                <div className="font-bold font-16px">
                    QR 이미지
                </div>
            </label>
            <div className="text-grey-5">활용 예시 : 오프라인 홍보물, 오프라인 광고 디스플레이</div>
        </div>
        <div className="flex-col flex-center-all p-4 gap-4" style={{backgroundColor: "var(--color-shape-depth1, rgba(247, 247, 249, 1))"}}>
            <img alt="" src={entryPoint.imageUrl} className="w-32 h-32"/>
            <div className="text-grey-5">화면 아래에서 해당 ID를 확인 해주세요. [{entryPoint.uid}]</div>
        </div>
        <div>
            <label className="flex-align-center gap-2">
                <CircleBadge label={2} />
                <div className="font-bold font-16px">
                    URL 링크
                </div>
            </label>
            <div className="text-grey-5">활용 예시 : 온라인 광고 링크</div>
        </div>
        <div className="flex-col gap-2">
            <TextInput
                disabled={true}
                value={entryPoint.linkUrl}
            />
            <div className="flex gap-2">
                <Button
                    variants="outlined"
                    onClick={() => window.open(entryPoint.linkUrl)}
                    value="URL 확인"
                />
                <div className={`absolute ${!copy && 'none'}`} style={{bottom: "20px", right: "40px", color: "red"}}>복사 되었습니다</div>
                <Button
                    variants="dark"
                    onClick={() => navigator.clipboard.writeText(entryPoint.linkUrl).then(() => {
                        setCopy(true)
                    })}
                    value="URL 복사"
                />
            </div>
        </div>
    </div>
  );
};
export default EntryPointPreviewPopup;
