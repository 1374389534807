import {useEffect, useState} from "react";


const SettingsStepLayout = ({description, renderChild}) => {
    const [height, setHeight] = useState(window.innerHeight - 106 - 54 - 30)

    const updateHeight = () => {
        setHeight(window.innerHeight - 106 - 54 - 30);
    };
    useEffect(() => {
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    return (
        <>
            <div className="settings-container">
                {
                    renderChild
                }
            </div>
        </>
    )
}

export default SettingsStepLayout