const DateStatistics = ({ children, keyName, cards = [] }) => {
    return (
        <>
            <div
                className={
                    "flex-col gap-8 rounded " +
                    (cards && cards.length > 0 ? "bg-gray-100 p-4 " : "")
                }
            >
                {cards && cards.length > 0 && (
                    <div className="grid grid-flow-col auto-cols-auto divide-x border border-gray-300 divide-gray-300 rounded-lg bg-white">
                        {cards.map((card, index) => {
                            return (
                                <div
                                    className="p-4"
                                    key={keyName + "_card" + index}
                                >
                                    <div className="w-full flex gap-8 items-center justify-between">
                                        <h6>{card.label}</h6>
                                        <label className="font-extrabold text-2xl text-green-500">
                                            + {card.value.toLocaleString()}
                                        </label>
                                    </div>
                                    {card.total != null && (
                                        <label className="block text-gray-400 w-full text-right">
                                            TOTAL {card.total.toLocaleString()}
                                        </label>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
                {children}
            </div>
        </>
    );
};
export default DateStatistics;
