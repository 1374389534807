import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const getUsers = async (
    search = null,
    startDate = null,
    endDate = null,
    isFriend = null,
    page = 0,
    size = 2,
    sort = "name,desc"
) => {
    const params = new URLSearchParams({
        page,
        size,
        sort,
    });
    if (search) params.append("search", search);
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);
    if (isFriend) params.append("isFriend", isFriend);

    const res = await fetchApi.get(
        API_URL.PARTNER_USER_LIST + `?${params.toString()}`
    );
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
};
export const getUsersAll = async (
    userIdList
) => {
    const res = await fetchApi.post(API_URL.USER_LIST_ALL, {
        userIdList: userIdList
    });
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
};

export const downloadUsersWorkbook = async (
    search = null,
    isFriend = null,
    startDate = null,
    endDate = null,
) => {
    const params = new URLSearchParams();
    if (search) params.append("search", search);
    if (isFriend) params.append("isFriend", isFriend);
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);

    return await fetchApi.getBlob(API_URL.PARTNER_USER_WORKBOOK + `?${params.toString()}`);
};

export const getUserDetail = async (id) => {
    const res = await fetchApi.get(API_URL.USER_DETAIL.replace("{id}", id));

    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
};