import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Accordion = ({
  isOpen,
  onClick,
  isError,
  errorText,
  title,
  children,
}) => {
  return (
    <div className="border-gray-300 border rounded">
      <div
        className="bg-gray-100 flex justify-between items-center p-4 rounded-t"
        onClick={onClick}
      >
        <p className="font-semibold">
          {title}{" "}
          {isError && (
            <span className="text-red-500 text-xs ml-5">{errorText}</span>
          )}
        </p>
        <FontAwesomeIcon icon={isOpen ? faArrowUp : faArrowDown} />
      </div>
      {isOpen && <div className=" border-gray-300 border-t ">{children}</div>}
    </div>
  );
};

export default Accordion;
