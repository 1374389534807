import { Suspense } from "react";
import EditTitle from "./EditTitle";
import Loading from "../Loading";
import EditController from "./EditController";
const SettingsLayout = ({
  icon,
  title,
  tabs,
  activeTab,
  children,
  onChange,
  isTemporarySave = true,
  onTemporarySave,
  onConfirm,
  isConfirm = true,
  onClose,
  confirmText = "완료",
  nextButtonDisabled
}) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <div className="min-h-screen bg-slate-200">
      <EditTitle
        icon={icon}
        title={title}
        tabs={tabs}
        activeTab={activeTab}
      />
      <div className="min-h-screen flex justify-center">
        <div className="mt-14">
          <Suspense
            fallback={
              <div className="w-100 text-center py-8 justify-center">
                <Loading className={"w-20 h-20 mx-auto"} />
              </div>
            }
          >
            {children}
          </Suspense>
        </div>
      </div>
      <EditController
        tabs={tabs}
        activeTab={activeTab}
        onChange={onChange}
        isTemporarySave={isTemporarySave}
        onTemporarySave={onTemporarySave}
        onConfirm={onConfirm}
        isConfirm={isConfirm}
        confirmText={confirmText}
        onClose={handleClose}
        nextButtonDisabled={nextButtonDisabled}
      />
    </div>
  );
};
export default SettingsLayout;
