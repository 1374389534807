import {
    Box,
    Divider, IconButton,
} from "@mui/material";
import TextSettingInput from "../../../form/TextSettingInput";
import SettingFormLabel from "../../../form/SettingFormLabel";
import SettingItemForm from "../../../form/message/SettingItemForm";
import NormalButton from "../../../NormalButton";
import {useState} from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const WideListMessageSetting = ({ messageData, variables, regex, onChangeHeader, onChangeItems, onDeleteItem }) => {
    const header = messageData.header
    const items = messageData.items ?? []
    const [maxLength, setMaxLength] = useState(3)

    return (
    <>
      <Box>
        <TextSettingInput
          label={"타이틀"}
          isRequired={true}
          inputProps={{ maxLength: 25 }}
          size={"small"}
          value={header}
          placeholder={"타이틀을 작성해주세요"}
          onChange={(e) => onChangeHeader(e.target.value)}
        />
      </Box>
      <Divider sx={{ mt: 3, mb: 4 }} />
        {
            Array.from({ length: maxLength }, (_, index) => {
                return (
                    <Box sx={{ mt: 2 }} key={"wide-list" + index}>
                        <SettingFormLabel label={index === 0 ? "메인 아이템" : "리스트 항목 " + (index + 1)} isRequired
                                          actionButton={ index === 3 && (
                                              <IconButton onClick={() => {
                                                  setMaxLength(3)
                                                  onDeleteItem(3)
                                              }} >
                                                  <DeleteOutlineIcon />
                                              </IconButton>
                                          )}
                        />
                        <SettingItemForm
                            index={index}
                            variables={variables}
                            regex={regex}
                            item={items[index] ?? {}}
                            onChangeImage={(imgUrl) => onChangeItems(index, "imgUrl", imgUrl)}
                            onChangeTitle={(title) => onChangeItems(index, "title", title)}
                            onChangeImageLink={(imgLink) => onChangeItems(index, "urlMobile", imgLink)}
                        />
                    </Box>
                );
            })
        }
        <div className={`${maxLength === 4 && "none"}`} style={{ marginTop: "20px" }}>
            <NormalButton
                variant="outlined"
                className="py-1.5 w-full"
                onClick={() => {
                    setMaxLength(4)
                    onChangeItems(3, "title", "")
                }}
            >
                + 리스트 항목 추가
            </NormalButton>
        </div>
    </>
    );
};

export default WideListMessageSetting;
