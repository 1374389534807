import Layout from "../components/layout/Layout";
import AutoMessages from "../components/page/AutomateMessages";

const AutomateMessagePage = () => {
  return (
    <Layout>
      <AutoMessages />
    </Layout>
  );
};
export default AutomateMessagePage;
