import InputGroup from "../InputGroup";
import {TextInput, RadioGroup, Alert, Panel} from "omoplay";
import {useEffect, useState} from "react";

const BenefitDefaultSettings = ({ benefitDetails, disabledBenefitType, onChangeBenefitName,
                          onChangeBenefitType, onChangeBenefitRetailPrice, onChangeBenefitSupplyPrice,
}) => {
  const benefitData = benefitDetails().benefit

  const radioRetailPriceItems = [
    { label: "미설정", value: "disabled"}, { label: "설정", value: "enabled" }
  ]

  const [retailType, setRetailType] = useState("disabled")

  useEffect(() => {
    setRetailType(benefitData.retailPrice === null || benefitData.retailPrice === undefined ? "disabled" : "enabled")
  }, [benefitData.retailPrice]);

  return (
      <InputGroup label={(
          <div className="flex-col gap-2">
            <div>고객 노출 설정</div>
            <Alert title={benefitDetails().content} type="warning" withIcon={true} />
            <Panel variants="bar" items={[
                { title: "혜택 사용 순서",
                  value: benefitDetails().description, valueStyle: {width: "100%", flex: "1"}
                }]} />
          </div>
      )}>
        <div className="flex">
          <div className="pr-[12px] flex-1">
            <div className="flex-col gap-2 bg-grey-0" style={{padding: "16px"}}>
              <div>혜택명<span className="ml-1 text-red-500">*</span></div>
              <div>
                <TextInput value={benefitData.name}
                           onChange={(e) => onChangeBenefitName(e.target.value)}
                           maxLength={30}
                />
              </div>
              {
                  benefitData.type !== "POINT" && (
                      <>
                        <div>소비자가<span className="ml-1 text-red-500">*</span></div>
                        <div className="flex-align-center gap-2">
                          <RadioGroup key={retailType} items={radioRetailPriceItems}
                                      onSelected={(value) => {
                                        if (value === "disabled") {
                                          onChangeBenefitRetailPrice(undefined)
                                        } else {
                                          onChangeBenefitRetailPrice(0)
                                        }
                                      }}
                                      initChecked={retailType}
                          />
                          <div className={`${retailType === "disabled" ? "none" : ""}`}>
                            <TextInput type="numeric" value={benefitData.retailPrice}
                                       onChange={(e) => onChangeBenefitRetailPrice(e.target.value)} />
                          </div>
                        </div>
                      </>
                  )
              }
            </div>
            <div className="flex-col gap-2 mt-[50px]">
              <div>{benefitData.type === "POINT" ? "포인트" : "원가"}<span className="ml-1 text-red-500">*</span></div>
              <TextInput type="numeric" value={benefitData.supplyPrice ?? 0}
                         onChange={(e) => onChangeBenefitSupplyPrice(e.target.value)} />
              {
                  benefitData.type !== "POINT" && (
                      <div className="flex-col gap-2 bg-grey-0" style={{padding: "16px"}}>
                        <div className="font-bold text-grey-7">원가란?</div>
                        <div className="text-grey-7">마케팅 비용 리포트에서 원가 기준으로 마케팅 비용에 대해 확인하실 수 있으며 고객에게는 노출되지 않습니다.</div>
                      </div>
                  )
              }
            </div>
          </div>
          <div className="pl-[12px] flex-1">
            <div className="bg-grey-0" style={{flexBasis: "50%"}}>
              <div className="flex-col gap-4" style={{padding: "37px"}}>
                <div className="flex justify-center">
                  <img alt="" src={benefitDetails().image} style={{height: "338px"}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </InputGroup>
  )
};

export default BenefitDefaultSettings;
