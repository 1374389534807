import {Button} from "omoplay";

const EditController = ({
  tabs,
  activeTab,
  onChange,
  isTemporarySave,
  onTemporarySave,
  onConfirm,
  isConfirm,
  confirmText,
  onClose, isCancel = false,
  nextButtonDisabled,
}) => {

  return (
      <div className="fixed bottom-5 flex justify-between w-[960px]">
          {activeTab > 1 ? (
              <Button
                  variants="outlined"
                  onClick={() => onChange(activeTab - 1)}
                  style={{width: "160px"}}
                  value="이전"
              />
          ) : (<div></div>)}
          <div className="flex gap-1">
              {
                  isCancel && (<Button variants="outlined" value="취소" onClick={onClose} style={{width: "160px"}} />)
              }
              {onTemporarySave && (
                  <Button
                      disabled={!isTemporarySave}
                      variants="outlined"
                      onClick={() => onTemporarySave()}
                      style={{width: "160px"}}
                      value="임시저장"
                  />
              )}
              {activeTab < tabs.length ? (
                  <Button
                      variants="primary"
                      onClick={() => onChange(activeTab + 1)}
                      style={{width: "160px"}}
                      value="다음"
                      disabled={nextButtonDisabled ?? false}
                  />
              ) : (
                  <Button
                      disabled={!isConfirm}
                      variants="primary"
                      onClick={() => onConfirm()}
                      style={{width: "160px"}}
                      value={confirmText}
                  />
              )}
          </div>
      </div>
  );
};

export default EditController;
