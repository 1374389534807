import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const DateChart = ({ keyName, chartConfig }) => {
    const [currentChartData, setCurrentChartData] = useState(
        chartConfig.series
    );
    const [currentChartOptions, setCurrentChartOptions] = useState(
        chartConfig.options
    );

    useEffect(() => {
        setCurrentChartData([]);
        setCurrentChartOptions([]);
    }, [chartConfig]);

    useEffect(() => {
        if (currentChartData?.length === 0) {
            setCurrentChartData(chartConfig.series);
        }
    }, [chartConfig.series, currentChartData]);

    useEffect(() => {
        if (currentChartOptions) {
            setCurrentChartOptions(chartConfig.options);
        }
    }, [currentChartOptions]);
    return (
        <div key={keyName}>
            <div className="w-full flex justify-center flex-col gap-4">
                <Chart
                    type={chartConfig.type}
                    series={currentChartData}
                    height={chartConfig.height}
                    options={currentChartOptions}
                />
            </div>
        </div>
    );
};

export default DateChart;
