import {Button, RadioGroup} from "omoplay";
import BenefitQuantityDetailSettings from "../BenefitQuantityDetailSettings";
import {useEffect, useState} from "react";
import {
    getDetailBenefitQuantities,
    postProjectBenefitQuantities,
    postProjectUnUsedBenefitQuantity
} from "../../../api/project/project.api";
import {Divider} from "@mui/material";


const QuantitySettings = ({projectId}) => {
    const [used, setUsed] = useState(false)
    const [benefitQuantitySettings, setBenefitQuantitySettings] = useState()
    const [isError, setError] = useState(true)
    const [isChanged, setChanged] = useState(true)
    const [saveBenefitQuantitySettings, setSaveBenefitQuantitySettings] = useState([])
    const [quantityType, setQuantityType] = useState()
    const [defaultBenefitSettings, setDefaultBenefitSettings] = useState([])
    const [choiceDefaultBenefits, setChoiceDefaultBenefits] = useState([])

    const existsLose = defaultBenefitSettings?.some(defaultBenefit => defaultBenefit.benefits.some(benefit => !benefit.id))

    useEffect(() => {
        if (isChanged) {
            getDetailBenefitQuantities(projectId).then(({data}) => {
                const benefitQuantitySettings = data.benefitQuantitySettings
                const defaultBenefitSettings = data.defaultBenefitSettings
                const quantityType = data.quantityType ?? "AT_LEAST_BENEFIT"

                setBenefitQuantitySettings(benefitQuantitySettings)
                setDefaultBenefitSettings(defaultBenefitSettings)
                setQuantityType(quantityType)
                setChoiceDefaultBenefits(quantityType === "AT_LEAST_BENEFIT" ?
                    defaultBenefitSettings?.map(benefitSetting => {
                        return (
                            {
                                eventId: benefitSetting.eventId,
                                benefitId: benefitSetting.defaultBenefitId ??
                                    (benefitSetting.benefits.some(benefit => benefit.id === null)
                                        ? null
                                        : benefitSetting.benefits[0].id)
                            }
                        )
                    }) : [])
                setUsed(data.used)
                setChanged(false)
                setError(false)
                setSaveBenefitQuantitySettings(benefitQuantitySettings?.map(setting => {
                    return (
                        {
                            id: setting.id,
                            quantityLimit: setting.quantityLimit,
                            entryPointQuantities: setting.events.flatMap(event => {
                                return event.entryPointQuantities.map(ep => {
                                    return (
                                        {
                                            id: ep.id,
                                            allocatedQuantity: ep.allocatedQuantity
                                        }
                                    )
                                })
                            })
                        })
                }))
            })
        }
    }, [projectId, isChanged])

    const saveBenefitQuantities = () => {
        if (used) {
            postProjectBenefitQuantities(projectId, quantityType, choiceDefaultBenefits, saveBenefitQuantitySettings).then(() => {
                alert("저장 되었습니다.")
                setBenefitQuantitySettings(undefined)
                setChanged(true)
            })
        } else {
            postProjectUnUsedBenefitQuantity(projectId).then(() => {
                alert("저장 되었습니다.")
                setBenefitQuantitySettings(undefined)
                setChanged(true)
            })
        }
    }

    return (
        <div className="flex-col gap-4">
            <div className="font-bold font-20px">첫방문 이벤트 수량 설정</div>
            <div className="flex gap-2 font-16px">
                <div className="font-bold">수량 제한 사용</div>
                {
                    benefitQuantitySettings === null && (
                        <div className="text-red-d1">* 이벤트 연결을 하셔야 사용하실 수 있습니다.</div>
                    )
                }
            </div>
            <div className="bg-white p-20px radius-6">
                <RadioGroup items={[{label: "미사용", value: "disabled"},
                    {label: "사용", value: "enabled", disabled: !benefitQuantitySettings}]}
                            key={used ? "enabled" : "disabled"}
                            initChecked={used ? "enabled" : "disabled"}
                            onSelected={(value) => {
                                setUsed(value === "enabled")
                            }}
                />
            </div>
            {
                used && (
                    <div className="flex-col gap-4">
                        <div className="font-bold font-16px">혜택 수량 조건</div>
                        <div className="flex-col gap-2 bg-white p-20px radius-6">
                            <RadioGroup items={[
                                {label: "최소 1개의 기본 혜택 설정", value: "AT_LEAST_BENEFIT", style: {flex: "1"}},
                                {
                                    label: (existsLose ?
                                        (<div>모든 혜택에 수량 제한 설정 <span className="text-red-d1">* 꽝이 있으면 선택할 수 없습니다.</span></div>)
                                        : (<div>모든 혜택에 수량 제한 설정</div>)),
                                    value: "ALL_LIMIT", style: {flex: "1 1 30%"},
                                    disabled: existsLose
                                }
                                ]}
                                        key={quantityType}
                                        initChecked={quantityType}
                                        onSelected={(value) => {
                                            setError(value !== "AT_LEAST_BENEFIT")
                                            setChoiceDefaultBenefits(value === "AT_LEAST_BENEFIT" ?
                                                defaultBenefitSettings.map(benefit => {
                                                    return (
                                                        {
                                                            eventId: benefit.eventId,
                                                            id: benefit.benefits[0].id
                                                        }
                                                    )
                                                }) : [])
                                            setQuantityType(value)
                                        }}
                            />
                            <div className="flex text-mainblue-0" style={{gap: "20px", marginLeft: "26px"}}>
                                <div style={{flex: "1 1 2%"}}>무제한 지급되는 혜택이 최소 1개가 있어 확률이 변동되지 않습니다.</div>
                                <div style={{flex: "1 1 30%"}}>일부 혜택이 모두 소진되면 확률이 변동되고 이벤트가 종료될 수 있습니다.</div>
                            </div>
                        </div>
                        {
                            quantityType === "AT_LEAST_BENEFIT" && (
                                <div className="flex-col gap-4">
                                    <div className="font-bold font-16px">기본 혜택 설정</div>
                                    <div className="font-16px">이벤트별로 혜택 수량이 모두 소진되었을 때 무제한으로 지급되는 혜택을 선택해 주세요.</div>
                                    <div className="bg-white radius-6">
                                        {
                                            defaultBenefitSettings.map(defaultBenefit => {
                                                return (
                                                    <div className="flex p-20px" style={{borderBottom: "1px solid var(--grey-2)"}}>
                                                        <div style={{width: "300px"}}>{defaultBenefit.eventName}</div>
                                                        <div>
                                                            <RadioGroup
                                                                items={defaultBenefit.benefits.map(benefit => {
                                                                    return (
                                                                        {
                                                                            label: benefit.name,
                                                                            value: benefit.id
                                                                        }
                                                                    )
                                                                })}
                                                                initChecked={choiceDefaultBenefits.find(benefit => defaultBenefit.eventId === benefit.eventId).benefitId}
                                                                onSelected={(value) => {
                                                                    setChoiceDefaultBenefits(prevBenefits => {
                                                                        const index = prevBenefits.findIndex(benefit => benefit.eventId === defaultBenefit.eventId)

                                                                        if (index !== -1) {
                                                                            const updatedBenefits = [...prevBenefits]
                                                                            updatedBenefits[index] = { ...updatedBenefits[index], benefitId: value }
                                                                            return updatedBenefits
                                                                        } else {
                                                                            return [...prevBenefits, {
                                                                                eventId: defaultBenefit.eventId,
                                                                                benefitId: value
                                                                            }]
                                                                        }
                                                                    })
                                                                    setSaveBenefitQuantitySettings(prevSettings =>
                                                                        prevSettings.map(setting =>
                                                                            setting.id === value
                                                                                ? { ...setting, quantityLimit: undefined }
                                                                                : setting
                                                                        )
                                                                    );
                                                                }} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <div className="font-bold font-16px">혜택 수량 제한</div>
                        <div className="flex-col gap-8 bg-white radius-6">
                            {
                                benefitQuantitySettings?.map((benefitQuantitySetting, index) => {
                                    return (
                                        <div className="flex-col gap-4">
                                            <div style={{padding: "24px"}}>
                                                <BenefitQuantityDetailSettings
                                                    benefitQuantitySetting={benefitQuantitySetting}
                                                    index={index}
                                                    isAllLimit={quantityType === "ALL_LIMIT"}
                                                    choiceDefaultBenefits={choiceDefaultBenefits}
                                                    onBenefitQuantity={(quantityLimit) => {
                                                        setSaveBenefitQuantitySettings(prevSettings =>
                                                            prevSettings.map(setting =>
                                                                setting.id === benefitQuantitySetting.id
                                                                    ? { ...setting, quantityLimit: quantityLimit }
                                                                    : setting
                                                            )
                                                        )
                                                    }}
                                                    onEntryPointQuantity={(entryPointId, ePAllocatedQuantity) => {
                                                        setSaveBenefitQuantitySettings(prevSettings =>
                                                            prevSettings.map(setting =>
                                                                setting.id === benefitQuantitySetting.id
                                                                    ? {
                                                                        ...setting,
                                                                        entryPointQuantities: setting.entryPointQuantities.map(epQuantity =>
                                                                            epQuantity.id === entryPointId
                                                                                ? { ...epQuantity, allocatedQuantity: ePAllocatedQuantity }
                                                                                : epQuantity
                                                                        )
                                                                    }
                                                                    : setting
                                                            )
                                                        )
                                                    }}
                                                    isError={(error) => {
                                                        setError(error)
                                                    }}
                                                />
                                            </div>
                                            <Divider />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }
            <div className="flex-center-all">
                <Button variants="dark" value="저장하기" disabled={isError}
                        onClick={() => saveBenefitQuantities()}
                        style={{width: "165px"}} />
            </div>
            <div className="flex-center-all text-red-0">최종으로 저장을 하셔야 반영됩니다</div>
        </div>
    )
}

export default QuantitySettings