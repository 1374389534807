import { ReactComponent as PreviewIcon } from "../assets/images/preview_mobile_header_icon.svg";
const MobilePreview = ({ children }) => {
  return (
    <div
      className="relative rounded-3xl border-gray-300 border-2 bg-gray-100 flex-col items-center overflow-scroll"
      style={{ width: "320px", height: "535px" }}
    >
      <div className="mt-4">
        <PreviewIcon />
      </div>
      <div className="absolute right-4 left-4 top-11 bottom-5">{children}</div>
    </div>
  );
};

export default MobilePreview;
