import moment from "moment";
import {useState} from "react";
import { useRecoilValue } from "recoil";
import PromotionCard from "../PromotionCard";
import { spotSelector } from "../../store/selector/spot";
import {CalendarBar, Dropdown, TabBar} from "omoplay";

const dateItems = [
  { value: "all", label: "전체" },
  { value: "month", label: "최근 한 달" },
  { value: "week", label: "최근 일주일" },
  { value: "day", label: "오늘", selected: true },
];
const now = moment().format("YYYY-MM-DD");
const PromotionPage = () => {
  const [dateRange, setDateRange] = useState({
    startDate: now,
    endDate: now,
  });
  const [spotId, setSpotId] = useState(undefined);
  const spots = useRecoilValue(spotSelector);

  const handleQuickChange = (type) => {
    if (type === "all") {
      setDateRange({
        startDate: moment("2023-12-01", "YYYY-MM-DD").format("YYYY-MM-DD"),
        endDate: now,
      });
    } else if (type === "month") {
      setDateRange({
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: now,
      });
    } else if (type === "week") {
      setDateRange({
        startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
        endDate: now,
      });
    } else if (type === "day") {
      setDateRange({
        startDate: now,
        endDate: now,
      });
    }
  };

  const onDateInput = (dateRange) => {
    setDateRange({
      startDate: moment(dateRange.startDate).format("yyyy-MM-DD"),
      endDate: moment(dateRange.endDate).format("yyyy-MM-DD")
    });
  };

  return (
    <>
      <div className="page-title">혜택 리포트</div>
      <div className="flex justify-between bg-white border rounded-lg p-20px" style={{marginTop: "35px", height: "64px"}}>
        <div className="flex justify-center items-center" style={{gap: "53px"}}>
          <div>
            <TabBar
                items={dateItems}
                onChanged={(data) => handleQuickChange(data.value)}
            />
          </div>
          <div>
            <Dropdown variants="default"
                      items={[{label: "전체 지점", value: ""}, ...spots.map(spot => ({label: spot.name, value: spot.spotId}))]}
                      onChanged={(items) => setSpotId(items[0].value)} />
          </div>
          <CalendarBar initDate={{startDate: moment(dateRange.startDate).toDate(), endDate: moment(dateRange.endDate).toDate()}}
                       onConfirm={(props) => onDateInput(props)} />
        </div>
      </div>
      <div className="flex-col gap-8">
        {spots.filter(spot => !spotId ? true : `${spot.spotId}` === spotId).map((spot) => {
          return (
              <PromotionCard
                  spot={spot}
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
              />
          )
        })}
      </div>
    </>
  );
};
export default PromotionPage;
