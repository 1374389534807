import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const listPointMenu = async () => {
  const res = await fetchApi.get(API_URL.LIST_POINT_MENU);
  if (res.status === 200) {
    return res.data
  } else {
    return {}
  }
}

export const getPointMenu = async (id) => {
  const res = await fetchApi.get(`${API_URL.GET_POINT_MENU.replace("{id}", id)}`);
  if (res.status === 200) {
    return res.data
  } else {
    return {}
  }
}

export const savePointMenu = async (id, benefitId, point, quantity) => {
  const res = await fetchApi.post(API_URL.SAVE_POINT_MENU, {
    id: id,
    benefitId: benefitId,
    point: point,
    quantity: quantity
  });
  if (res.status === 200) {
    return res.data
  } else {
    return {}
  }
}

export const saveVisiblePointMenu = async (id, visible) => {
  const res = await fetchApi.post(API_URL.VISIBLE_POINT_MENU, {
    id: id,
    visible: visible
  });
  if (res.status === 200) {
    return res.data
  } else {
    return {}
  }
}
