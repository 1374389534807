import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const listBenefit = async (page, size, status) => {
  const res = await fetchApi.get(`${API_URL.LIST_BENEFIT}?page=${page}&size=${size}${status ? `&status=${status}` : ''}`);
  if (res.status === 200) {
    return res.data
  } else {
    return {}
  }
}


export const getBenefit = async (id) => {
  const res = await fetchApi.get(API_URL.GET_BENEFIT.replace("{id}", id));
  if (res.status === 200) {
    return res.data
  } else {
    return {}
  }
}

export const postBenefit = async (benefit) => {
  const res = await fetchApi.post(API_URL.POST_BENEFIT, {
    ...benefit,
    type: benefit.type ?? "DIRECT",
    supplyPrice: benefit.supplyPrice ?? 0,
  });
  if (res.status === 200) {
    return res.data
  } else {
    return {}
  }
}

export const deleteBenefit = async (id) => {
  const res = await fetchApi.delete(API_URL.DELETE_BENEFIT.replace("{id}", id));
  if (res.status === 200) {
    return res.data
  } else {
    return {}
  }
}