import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "../Tooltip";
import {
  faCircleQuestion,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

const SortableTableHeader = ({
  header,
  onClick,
  sortKey,
  sortDirection,
  columnStyle = "px-6 py-3",
}) => {
  const isSortable = header.sortable !== false;

  const renderSortDirectionIcon = (key) => {
    if (key !== sortKey) {
      return <FontAwesomeIcon icon={faSort} />;
    } else {
      if (sortDirection === "desc") return <FontAwesomeIcon icon={faSortUp} />;
      else return <FontAwesomeIcon icon={faSortDown} />;
    }
  };

  return (
    <th
      scope="col"
      className={
        (columnStyle || "px-6 py-3") + " relative " + (header.className || "")
      }
      style={header.style}
      onClick={() => isSortable && onClick(header.key)}
    >
      {header.render ? header.render : header.label}
      {header.tooltip && (
        <div className="inline-block align-text-top ml-2">
          <Tooltip message={header.tooltip}>
            <FontAwesomeIcon
              icon={faCircleQuestion}
              size={"lg"}
              color={"#999"}
            />
          </Tooltip>
        </div>
      )}
      {isSortable && (
        <div className="ml-2 inline-block">
          {renderSortDirectionIcon(header.key)}
        </div>
      )}
    </th>
  );
};

export default SortableTableHeader;
