import InputGroup from "../InputGroup";
import {Dropdown, TextInput, RadioGroup} from "omoplay";
import {Divider} from "@mui/material";
import Badge from "../badge/Badge";
import {useEffect, useState} from "react";

const BenefitDefault = ({ benefitDetails, disabledBenefitType, onChangeBenefitName,
                          onChangeBenefitType, onChangeBenefitRetailPrice, onChangeBenefitSupplyPrice,
}) => {
  const benefitData = benefitDetails().benefit
  const typeItems = [
    { label: "직원 확인형", value: "DIRECT"},
    { label: "고객 정보 수집형", value: "DELIVERY"},
    { label: "혜택 코드형", value: "CODE"},
    { label: "링크 혜택형", value: "LINK"},
    //{ label: "포인트 지급형", value: "POINT"},
  ]

  const radioRetailPriceItems = [
    { label: "미설정", value: "disabled"}, { label: "설정", value: "enabled" }
  ]

  const [retailType, setRetailType] = useState("disabled")

  useEffect(() => {
    setRetailType(benefitData.retailPrice === null || benefitData.retailPrice === undefined ? "disabled" : "enabled")
  }, [benefitData.retailPrice]);

  return (
      <div className="flex" style={{height: "100%"}}>
        <div style={{flexBasis: "50%"}}>
          <div style={{padding: "0 34px"}}>
            <InputGroup
                label={"혜택 형식"}
                isRequired={true}
                className="py-8"
            >
              <Dropdown key={benefitData.type} variants="default" initialValue={benefitData.type}
                        items={typeItems} onChanged={(items) => onChangeBenefitType(items[0].value)}
                        disabled={disabledBenefitType}
              />
            </InputGroup>
          </div>
          <Divider />
          <div className="flex-col gap-4" style={{padding: "0 34px"}}>
            <InputGroup
                label={"혜택 상세 설정"}
                isRequired={true}
                className="pt-8"
            >
              <div className="flex">
                <label>고객 노출 설정</label>
                <div></div>
              </div>
              <div className="flex-col gap-2 bg-grey-0" style={{padding: "16px"}}>
                <div>혜택명<span className="ml-1 text-red-500">*</span></div>
                <div>
                  <TextInput value={benefitData.name}
                             onChange={(e) => onChangeBenefitName(e.target.value)}
                             maxLength={30}
                  />
                </div>
                {
                  benefitData.type !== "POINT" && (
                      <>
                        <div>소비자가<span className="ml-1 text-red-500">*</span></div>
                        <div className="flex-align-center gap-2">
                          <RadioGroup key={retailType} items={radioRetailPriceItems}
                                      onSelected={(value) => {
                                        if (value === "disabled") {
                                          onChangeBenefitRetailPrice(undefined)
                                        } else {
                                          onChangeBenefitRetailPrice(0)
                                        }
                                      }}
                                      initChecked={retailType}
                          />
                          <div className={`${retailType === "disabled" ? "none" : ""}`}>
                            <TextInput type="numeric" value={benefitData.retailPrice}
                                       onChange={(e) => onChangeBenefitRetailPrice(e.target.value)} />
                          </div>
                        </div>
                        <div style={{marginTop: "30px"}}>
                          <div className="font-bold">소비자가란?</div>
                          <div>고객의 혜택에 노출되는 혜택 가격입니다. 고객들에게 노출하기 위한 용도입니다.</div>
                        </div>
                      </>
                    )
                }
              </div>
            </InputGroup>
            <div className="flex-col gap-2">
              <div>{benefitData.type === "POINT" ? "포인트" : "원가"}<span className="ml-1 text-red-500">*</span></div>
              <TextInput type="numeric" value={benefitData.supplyPrice ?? 0}
                         onChange={(e) => onChangeBenefitSupplyPrice(e.target.value)} />
              {
                  benefitData.type !== "POINT" && (
                      <div className="flex-col gap-2 bg-grey-0" style={{padding: "16px"}}>
                        <div className="font-bold text-grey-7">원가란?</div>
                        <div className="text-grey-7">마케팅 비용 리포트에서 원가 기준으로 마케팅 비용에 대해 확인하실 수 있으며 고객에게는 노출되지 않습니다.</div>
                      </div>
                  )
              }
            </div>
          </div>
        </div>
        <div className="bg-grey-0" style={{flexBasis: "50%"}}>
          <div className="flex-col gap-4" style={{padding: "37px"}}>
            <div>
              <div className="flex-center-all">
                <Badge name={benefitDetails().label} color="blue" padding="8px 20px" />
              </div>
            </div>
            <div className="flex justify-center">
              <img src={benefitDetails().image} style={{height: "338px"}} />
            </div>
            <div>
              <div className="flex-col gap-1" style={{paddingLeft: "24px"}}>
                <div className="font-bold font-16px">혜택 사용 순서</div>
                {benefitDetails().description}
              </div>
            </div>
            <div className="bg-white" style={{ padding: "16px" }}>
              <div style={{fontWeight: "600"}}>유의 사항</div>
              <div>
                {benefitDetails().content}
              </div>
            </div>
          </div>
        </div>
      </div>
  )
};

export default BenefitDefault;
