import {Divider} from "@mui/material";
import InputGroup from "../InputGroup";
import {RadioGroup, TextInput} from "omoplay";
import NormalButton from "../../NormalButton";
import SuspensePopup from "../../SuspensePopup";
import {useEffect, useState} from "react";
import BenefitForm from "../benefit/BenefitForm";
import {getBenefit} from "../../../api/benefit/benefit.api";


const ButtonsForm = ({maxLength, buttons, onChangeButtons}) => {
    const [openBenefitPopup, setOpenBenefitPopup] = useState(null)
    const [benefitName, setBenefitName] = useState("")


    useEffect(() => {
        const benefitId = buttons?.find(button => button.type === "COUPON")?.urlMobile
        if (benefitId !== undefined && !isNaN(Number(benefitId))) {
            getBenefit(benefitId).then(({data}) => {
                setBenefitName(data.name)
            })
        }
    }, [buttons])

    const openUrl = (url) => {
        window.open(url)
    }

    return (
        <>
            {Array.from({ length: maxLength }, (_, index) => {
                const button = (buttons ?? [])[index] ?? {}
                const type = button.type ?? ""

                return (
                    <>
                        <InputGroup
                            label={`메시지 버튼 ${index + 1}`}
                            className="py-4"
                        >
                            {
                                type !== undefined ? (
                                    <RadioGroup
                                        key={type}
                                        items={[
                                            {
                                                label: "없음",
                                                value: "",
                                            },
                                            {
                                                label: "링크",
                                                value: "WL"
                                            },
                                            {
                                                label: "쿠폰",
                                                value: "COUPON"
                                            }
                                        ]}
                                        initChecked={type}
                                        value={type}
                                        onSelected={(value) => onChangeButtons(index, "type", value)}
                                    />
                                ) : <></>
                            }
                        </InputGroup>
                        {type === "WL" && (
                            <div className="flex-col gap-2">
                                <TextInput
                                    placeholder="버튼명을 입력해 주세요."
                                    maxLength={8}
                                    value={button.name ?? ""}
                                    onChange={(e) => onChangeButtons(index, "name", e.target.value)}
                                />
                                <div className="flex gap-2">
                                    <div style={{width: "100%", flex: "1"}}>
                                        <TextInput
                                            placeholder="연결할 페이지의 URL을 입력해주세요."
                                            value={button.urlMobile ?? ""}
                                            onChange={(e) =>
                                                onChangeButtons(index, "urlMobile", e.target.value)
                                            }
                                        />
                                    </div>
                                    <NormalButton
                                        variant={"outlined"}
                                        onClick={() => openUrl(button.urlMobile)}
                                    >
                                        URL 확인
                                    </NormalButton>
                                </div>
                                <div>PC 전용 링크</div>
                                <div className="flex gap-2">
                                    <div style={{width: "100%", flex: "1"}}>
                                        <TextInput
                                            placeholder="연결할 페이지의 URL을 입력해주세요."
                                            value={button.urlPc ?? ""}
                                            onChange={(e) =>
                                                onChangeButtons(index, "urlPc", e.target.value)
                                            }
                                        />
                                    </div>
                                    <NormalButton
                                        variant={"outlined"}
                                        onClick={() => openUrl(button.urlPc)}
                                    >
                                        URL 확인
                                    </NormalButton>
                                </div>
                            </div>
                        )}
                        {button?.type === "COUPON" && (
                            <div className="flex-col gap-2">
                                <TextInput
                                    placeholder="혜택명을 입력해 주세요."
                                    maxLength={8}
                                    value={button.name ?? ""}
                                    onChange={(e) => onChangeButtons(index, "name", e.target.value)}
                                />
                                <div className="flex gap-2">
                                    <div style={{width: "100%", flex: "1"}}>
                                        <TextInput
                                            placeholder="연결할 혜택을 선택해주세요"
                                            value={benefitName}
                                            disabled={true}
                                            readOnly
                                        />
                                    </div>
                                    <NormalButton
                                        variant={"outlined"}
                                        onClick={() => setOpenBenefitPopup(index)}
                                    >
                                        혜택 연결하기
                                    </NormalButton>
                                </div>
                            </div>
                        )}
                        {index + 1 < buttons?.length && (
                            <Divider />
                        )}
                    </>
                );
            })}
            {openBenefitPopup !== null && (
                <SuspensePopup
                    isEditPopup={true}
                    title={"혜택 연결하기"}
                    onClose={() => setOpenBenefitPopup(null)}
                >
                    <BenefitForm
                        onSelectedBenefit={(benefitId, benefitName) => {
                            onChangeButtons(openBenefitPopup, "urlMobile", benefitId)
                            setBenefitName(benefitName)
                            setOpenBenefitPopup(null)
                        }}
                    />
                </SuspensePopup>
            )}
        </>
    )
}

export default ButtonsForm