import { useState } from "react";

const SearchInput = ({ className = "", value, onSubmit }) => {
  const [text, setText] = useState(value);
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSubmit(text);
    }
  };
  return (
    <div className={"relative " + className}>
      <input
        type="search"
        className="block w-full p-2 pr-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-indigo-500 focus:border-indigo-500"
        style={{height: "40px"}}
        placeholder="검색어를 입력해 주세요."
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
        value={text || ""}
      />
      <div className="absolute inset-y-0 end-0 flex items-center pr-3 pointer-events-none">
        <svg
          className="w-4 h-4 text-gray-500"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </div>
    </div>
  );
};

export default SearchInput;
