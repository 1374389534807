import { useEffect, useState } from "react";
import NoImage from "../assets/images/no-image.svg";
import BenefitMessagePreview from "./layout/BenefitMessagePreview";
import InfoButton from "./InfoButton";
import TabTitle from "./TabTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import BenefitDetailPreview from "./form/benefit/BenefitDetailPreview";
import {getBenefit} from "../api/benefit/benefit.api";

const BenefitPreview = ({ id }) => {
  const tabs = [
    { label: "당첨 안내", value: 1 },
    { label: "혜택 상세", value: 2 },
  ];
  const [tab, setTab] = useState(tabs[0].value);
  const [benefitData, setBenefitData] = useState({})

  let buttonName = "직원 확인하기"

  if (benefitData.type === "CODE") {
    buttonName = "당첨 코드 확인"
  } else if (benefitData.type === "DELIVERY") {
    buttonName = "수령 정보 입력"
  } else if (benefitData.type === "LINK") {
    buttonName = "혜택 받기"
  }

  useEffect(() => {
    getBenefit(id).then(res => {
      if (res.data) {
        setBenefitData(res.data)
      }
    })
  }, [id]);

  const renderByTab = (tab) => {
    if (tab === 1) {
      return (
          <BenefitMessagePreview
              preview={
                <div className="relative h-full bg-repeat-round bg-contain bg-[#ABC1D1] p-4 max-h-[460px] overflow-auto">
                  <h4 className="font-medium text-gray-800 pl-2 py-2">
                    당첨 카카오톡 안내
                  </h4>
                  <div className="flex w-full flex-col justify-center bg-white rounded-lg p-2.5">
                    <img src={benefitData.messageImage ?? NoImage} />
                    <div className="mt-2 text-xs whitespace-pre-line">{benefitData.messageText}</div>
                    <button className="bg-[#F5F5F5] py-1 text-[#474A4E] text-sm font-medium rounded mt-4">
                      {buttonName}
                    </button>
                  </div>
                </div>
              }
          />
      );
    } else if (tab === 2) {
      return (
          <BenefitDetailPreview benefitData={benefitData}/>
      );
    }
  };

  return (
    <div className="flex-col items-center gap-4 py-8">
      <TabTitle tabs={tabs} activeTab={tab} />
      { renderByTab(tab) }
      <div className="flex gap-4">
        <InfoButton
            className="px-2 py-0.5"
            disabled={tab === 1}
            onClick={() => setTab(tab - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </InfoButton>
        <InfoButton
            className="px-2 py-0.5"
            disabled={tab === 2}
            onClick={() => setTab(tab + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </InfoButton>
      </div>


    </div>
  );
};
export default BenefitPreview;
