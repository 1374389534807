import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const getProjectList = async (status) => {
  const res = await fetchApi.get(`${API_URL.PROJECT_LiST}${status ? `?status=${status}` : ''}`);
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const getProject = async (id) => {
  const res = await fetchApi.get(API_URL.GET_PROJECT.replace("{id}", id));
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const deleteProject = async (id) => {
  const res = await fetchApi.post(API_URL.DELETE_PROJECT.replace("{id}", id));
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const postProject = async (project) => {
  const res = await fetchApi.post(API_URL.POST_PROJECT, project);
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const postNameProject = async (id, name) => {
  const res = await fetchApi.post(API_URL.SAVE_NAME_PROJECT.replace("{id}", id), {
    name: name
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const turnProject = async (id, turn) => {
  const res = await fetchApi.post(API_URL.TURN_PROJECT, {
    id: id,
    turn: turn
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const getDetailProject = async (id) => {
  const res = await fetchApi.get(API_URL.DETAIL_PROJECT.replace("{id}", id));
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const getDetailEventsToProject = async (id) => {
  const res = await fetchApi.get(API_URL.DETAIL_EVENTS_PROJECT.replace("{id}", id));
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const getEntryPointsToProject = async (id) => {
  const res = await fetchApi.get(API_URL.ENTRY_POINTS_PROJECT.replace("{id}", id));
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const getDetailBenefitQuantities = async (id) => {
  const res = await fetchApi.get(API_URL.DETAIL_BENEFIT_QUANTITY_PROJECT.replace("{id}", id));
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const getDetailBenefitStatistics = async (id) => {
  const res = await fetchApi.get(API_URL.DETAIL_BENEFIT_STATISTICS_PROJECT.replace("{id}", id));
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const getDetailDuplicateProjects = async (id) => {
  const res = await fetchApi.get(API_URL.DETAIL_DUPLICATES_PROJECT.replace("{id}", id));
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const postProjectEvent = async (projectId, eventId) => {
  const res = await fetchApi.post(API_URL.EVENT_PROJECT, {
    projectId: projectId,
    eventId: eventId
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const deleteProjectEvent = async (projectId, eventId) => {
  const res = await fetchApi.post(API_URL.EVENT_DELETE_PROJECT, {
    projectId: projectId,
    eventId: eventId
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const postProjectEntryPoint = async (projectId, eventId, entryPointIds) => {
  const res = await fetchApi.post(API_URL.ENTRYPOINT_PROJECT, {
    projectId: projectId,
    eventId: eventId,
    entryPointIds: entryPointIds
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const postProjectPassword = async (id, password) => {
  const res = await fetchApi.post(API_URL.PASSWORD_PROJECT, {
    id: id,
    benefitPassword: password,
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const postProjectPeriod = async (id, startDate, endDate) => {
  const res = await fetchApi.post(API_URL.PERIOD_PROJECT, {
    id: id,
    startDate: startDate,
    endDate: endDate
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const postProjectDuplicates = async (id, duplicateProjects) => {
  const res = await fetchApi.post(API_URL.DUPLICATES_PROJECT, {
    id: id,
    duplicateProjects: duplicateProjects
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const postProjectBenefitQuantities = async (id, quantityType, defaultBenefitSettings, benefitQuantitySettings) => {
  const res = await fetchApi.post(API_URL.DETAIL_BENEFIT_QUANTITY_PROJECT.replace("{id}", id), {
    id: id,
    quantityType: quantityType,
    defaultBenefitSettings: defaultBenefitSettings,
    benefitQuantitySettings: benefitQuantitySettings
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const postProjectUnUsedBenefitQuantity = async (id) => {
  const res = await fetchApi.post(API_URL.DETAIL_UN_USED_BENEFIT_QUANTITY_PROJECT.replace("{id}", id), {
    id: id
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const deleteProjectDuplicate = async (id, duplicateProject) => {
  const res = await fetchApi.post(API_URL.DUPLICATE_DELETE_PROJECT, {
    id: id,
    duplicateProject: duplicateProject
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}
export const deleteProjectEntryPoint = async (projectId, eventId, entryPointId) => {
  const res = await fetchApi.post(API_URL.ENTRYPOINT_DELETE_PROJECT, {
    projectId: projectId,
    eventId: eventId,
    entryPointId: entryPointId
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const getProjectPrizeUsers = async (projectId, benefitId, userName, page, size) => {
  const res = await fetchApi.get(`${API_URL.PRIZE_USERS_PROJECT.replace("{id}", projectId)}?page=${page}&size=${size}${benefitId ? `&benefitId=${benefitId}` : ""}${userName ? `&userName=${userName}` : ""}`);
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const getProjectRevisit = async (projectId) => {
  const res = await fetchApi.get(`${API_URL.GET_REVISIT_EVENT_PROJECT.replace("{id}", projectId)}`);
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}

export const postProjectRevisitEvent = async (projectId, used, minPoint, maxPoint) => {
  const res = await fetchApi.post(API_URL.SAVE_REVISIT_EVENT_PROJECT, {
    projectId: projectId,
    used: used,
    minPoint: Number(minPoint),
    maxPoint: Number(maxPoint)
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
}