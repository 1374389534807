import { useEffect, useState } from "react";
import {
  basicBarOption,
  pieOption
} from "../../constants/chart_config";
import EChartsReact from "echarts-for-react";


const UserStatistics = ({customers, showFriendAddAndBlock = false}) => {
  const [genderData, setGenderData] = useState([]);
  const [ageRangeData, setAgeRangeData] = useState([]);
  const [joinHoursData, setJoinHoursData] = useState([]);
  const [joinDaysData, setJoinDaysData] = useState([]);
  const [friendUser, setFriendUser] = useState({});
  const [blockedUser, setBlockedUser] = useState({});

  useEffect(() => {
    if (customers.groupGenders) {
      setGenderChart()
      setAgesRangeChart()
      setHoursRangeChart()
      setDayOfWeekRangeChart()
      setAddFriendsRangeChart()
      setBlockedFriendsRangeChart()
    }
  }, [customers])

  const setGenderChart = () => {
    const groupGenders = customers.groupGenders

    setGenderData(Object.entries(groupGenders).map(([key, value]) => {
      return {
        name: key,
        value: value
      }
    }))
  }

  const setAgesRangeChart =  () => {
    const groupAges = customers.groupAges

    setAgeRangeData(Object.entries(groupAges).map(([key, value]) => {
      return {
        name: key,
        value: value
      }
    }))
  }

  const setHoursRangeChart =  () => {
    const joinHours = customers.joinHours

    setJoinHoursData(Object.entries(joinHours).map(([key, value]) => {
      return {
        name: key,
        value: value
      }
    }))
  }

  const setDayOfWeekRangeChart =  () => {
    const joinDayOfWeeks = customers.joinDayOfWeeks

    setJoinDaysData(Object.entries(joinDayOfWeeks).map(([key, value]) => {
      return {
        name: key,
        value: value
      }
    }));
  }

  const setAddFriendsRangeChart =  () => {
    const addCountGroups = customers.addCountGroups
    const xAxis = []
    const series = []

    addCountGroups.forEach(({addCount, createdAt}) => {
      xAxis.push(createdAt)
      series.push(addCount)
    })
    setFriendUser({
      xAxis: xAxis,
      series: series
    });
  }

  const setBlockedFriendsRangeChart =  () => {
    const blockedCountGroups = customers.blockedCountGroups
    const xAxis = []
    const series = []

    blockedCountGroups.forEach(({blockedCount, createdAt}) => {
      xAxis.push(createdAt)
      series.push(blockedCount)
    })
    setBlockedUser({
      xAxis: xAxis,
      series: series
    })
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-4 relative">
        {
          showFriendAddAndBlock && (
              <>
                <div className="rounded border border-gray-300">
                  <div className="border-b border-b-gray-300 w-full p-4">
                    <h5 className="font-semibold">친구 추가 수</h5>
                  </div>
                  <div className="p-4">
                    <EChartsReact
                        option={basicBarOption(friendUser.xAxis, friendUser.series)}
                    />
                  </div>
                </div>
                <div className="bg-white rounded border border-gray-300">
                  <div className="border-b border-b-gray-300 w-full p-4">
                    <h5 className="font-semibold">친구 차단 수</h5>
                  </div>
                  <div className="p-4">
                    <EChartsReact
                        option={basicBarOption(blockedUser.xAxis, blockedUser.series)}
                    />
                  </div>
                </div>
              </>
            )
        }
        <div className="bg-white rounded border border-gray-300">
          <div className="border-b border-b-gray-300 w-full p-4">
            <h5 className="font-semibold">성별</h5>
          </div>
          <div className="p-4">
            <EChartsReact
                option={pieOption("성별", genderData, "%")}
            />
          </div>
        </div>
        <div className="bg-white rounded border border-gray-300">
          <div className="border-b border-b-gray-300 w-full p-4">
            <h5 className="font-semibold">연령대</h5>
          </div>
          <div className="p-4">
            <EChartsReact
                option={pieOption("연령대", ageRangeData, "%")}
            />
          </div>
        </div>
        <div className="bg-white rounded border border-gray-300">
          <div className="border-b border-b-gray-300 w-full p-4">
            <h5 className="font-semibold">가입 시간대</h5>
          </div>
          <div className="p-4">
            <EChartsReact
                option={pieOption("가입 시간대", joinHoursData, "%")}
            />
          </div>
        </div>
        <div className="bg-white rounded border border-gray-300">
          <div className="border-b border-b-gray-300 w-full p-4">
            <h5 className="font-semibold">가입 요일</h5>
          </div>
          <div className="p-4">
            <EChartsReact
                option={pieOption("가입 요일", joinDaysData, "%")}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default UserStatistics;
