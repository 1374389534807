import InputGroup from "../InputGroup";
import {TextInput} from "omoplay";
import moment from "moment";
import ProjectDetailPeriod from "./ProjectDetailPeriod";
import ProjectDetailDuplicate from "./ProjectDetailDuplicate";

export const ProjectDefault = ({project, onChangeProjectName, onChangeStartDate, onChangeEndDate, onChangeDuplicateProject}) => {
    const startDate = project.startDate ? moment(project.startDate) : moment().hours(0).minutes(0)
    const endDate = project.endDate
    const duplicateProjectList = project.duplicateProjects ?? []

    return (
        <>
          <InputGroup
              label={"프로젝트명"}
              isRequired={true}
          >
            <div>
              <TextInput
                  value={project.name}
                  placeholder="프로젝트 이름을 입력해 주세요."
                  onChange={(e) => onChangeProjectName(e.target.value)}
                  maxLength={20}
              />
            </div>
          </InputGroup>
            <ProjectDetailPeriod projectId={project.id} initStartDate={startDate} initEndDate={endDate}
                                 onChangeStartDate={onChangeStartDate} onChangeEndDate={onChangeEndDate}
            />
            <ProjectDetailDuplicate projectId={project.id} duplicateProjects={duplicateProjectList}
                                    onChange={(duplicateProjects) => {
                                        onChangeDuplicateProject(duplicateProjects)
                                    }}
                                    onDelete={(duplicateProjectId) => {
                                        onChangeDuplicateProject(duplicateProjectList.filter(rProject => rProject.id !== duplicateProjectId))
                                    }}
            />
        </>
    )
}

export default ProjectDefault
