import {API_URL,} from "../../constants/axios";
import {fetchApi} from "../fetch";


const convertImage = (imageUrl, imageLink) => {
  return {
    imgUrl: imageUrl,
    imgLink: imageLink,
  };
};
const convertButtons = (messageButtons) => {
  return messageButtons
    .filter((button) => button.isLink !== 'none')
    .map((button) => {
      const buttonData = {
        name: button.name,
        type: "WL",
      };
      if (button.isLink === "link") {
        buttonData.urlMobile = button.link
        buttonData.urlPc = button.pcLink
      } else if (button.isLink === "coupon" && button.coupon) {
        buttonData.couponId = button.coupon.id.toString();
      }
      return buttonData
    });
};
const convertListItems = (listItems) => {
  return listItems.map((item) => {
    return {
      title: item.content,
      imgUrl: item.image,
      urlMobile: item.link,
      urlPc: item.pcLink,
    };
  });
};
const convertCarouselItems = (listItems) => {
  return listItems
    .filter((item) => item.title && item.content)
    .map((item) => {
      return {
        header: item.title,
        message: item.content,
        attachment: {
          buttons: convertButtons(item.messageButtons),
          image: convertImage(item.image, item.link),
        },
      };
    });
};

const convertCarouselTail = (buttons) => {
  if (buttons.length > 0) {
    const button = buttons[0];
    if (button.link) {
      return {
        urlMobile: button.link,
        urlPc: button.pcLink,
      };
    } else {
      return null;
    }
  }
  return null;
};

export const convertRequestData = (messageType, data) => {
  if (messageType === "default") {
    return {
      type: data.image ? "FI" : "FT",
      content: data.content,
      image: data.image ? convertImage(data.image, null) : null,
      buttons: convertButtons(data.messageButtons),
    };
  } else if (messageType === "wide") {
    return {
      type: "FW",
      content: data.content,
      image: data.image ? convertImage(data.image, data.link) : null,
      buttons: convertButtons(data.messageButtons),
    };
  } else if (messageType === "wide_list") {
    return {
      header: data.title,
      type: "FL",
      content: data.content,
      items: [
        ...convertListItems([data.mainItem]),
        ...convertListItems(data.listItems),
      ],
      buttons: convertButtons(data.messageButtons),
    };
  } else if (messageType === "carousel") {
    return {
      type: "FC",
      content: data.content,
      carousels: convertCarouselItems(data.listItems),
      tail: convertCarouselTail(data.messageButtons),
    };
  }
};

export const getExpectedCustomerCount = async (id, type, filters) => {
  const res = await fetchApi.post(API_URL.EXPECTED_CUSTOMER_COUNT, {
    messageId: id,
    type: type,
    data: filters,
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return 0;
  }
};
export const getTargetCustomers = async (id) => {
  const res = await fetchApi.get(`${API_URL.TARGET_CUSTOMERS}?id=${id}`);
  if (res.status === 200) {
    return res.data;
  } else {
    return 0;
  }
};

export const saveNotification = async (status, message) => {
  const requestData = {
    ...message,
    status: status,
    messageType: message.messageData.type,
    reservedDate: `${message.issuedAt.date.startDate} ${message.issuedAt.hour}:${message.issuedAt.min}`,
    messageData: JSON.stringify(message.messageData)
  };

  const res = await fetchApi.post(API_URL.SAVE_NOTIFICATION, requestData);
  if (res.status === 200) {
    return res.data;
  } else {
    return 0;
  }
};

export const getVolatilityMessages = async (
  startDate,
  endDate,
  type,
  page = 0,
  size = 9999
) => {
  const searchParam = new URLSearchParams({
    startDate,
    endDate,
    page,
    size,
  });
  if (type !== "ALL") {
    searchParam.set("messageStatus", type);
  }
  const res = await fetchApi.get(
    API_URL.GET_VOLATILITY_MESSAGES + "?" + searchParam.toString()
  );
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};

export const getVolatilityMessage = async (id) => {
  const res = await fetchApi.get(
    API_URL.GET_VOLATILITY_MESSAGE.replace("{id}", id)
  );
  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};

export const getVolatilityClickUsers = async (id, startDate, endDate) => {
  const res = await fetchApi.get(
    `${API_URL.GET_VOLATILITY_CLICK_USERS.replace("{id}", id)}${startDate ? `?startDate=${startDate}&endDate=${endDate}` : ""}`
  );
  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};

export const cancelReserved = async (id) => {
  const res = await fetchApi.post(
      API_URL.VOLATILITY_MESSAGE_RESERVED_CANCEL.replace("{id}", id)
  );
  return res.data;
};

export const copyMessage = async (id) => {
  const res = await fetchApi.post(
      API_URL.VOLATILITY_MESSAGE_COPY.replace("{id}", id)
  );
  return res.data;
};

export const getContentRequired = (messageType, data) => {
  if (!data)
    return false
  validButtons(data.messageButtons)
  if (messageType === "default") {
    return data.content != null && data.content.length > 0;
  } else if (messageType === "wide") {
    return isWideItemRequired(data);
  } else if (messageType === "wide_list") {
    return (
      isWideItemRequired(data.mainItem) &&
      data.listItems?.filter((item) => isWideItemRequired(item)).length > 0
    );
  } else if (messageType === "carousel") {
    return (
      data.listItems?.filter((item) => isTitleContentRequired(item)).length > 1
    );
  }
};

export const validContent = (messageData) => {
  const type = messageData?.type
  if (type === "FT") {
    return messageData.content?.length > 0 && validButtons(messageData.buttons)
  } else if (type === "FI") {
    return messageData.content?.length > 0 && validButtons(messageData.buttons)
  } else if (type === "FW") {
    return messageData.content?.length > 0 && validChangeOfLine(messageData.content) && validButtons(messageData.buttons) && messageData.image?.imgUrl?.length > 0
  } else if (type === "FL") {
    return messageData.header?.length > 0
        && messageData.items.filter((item) => {
          return (
              item.title?.length > 0 &&
              validChangeOfLine(item.title) && item.imgUrl?.length > 0 && item.urlMobile?.length > 0
          )
        }).length > 2
  } else if (type === "FC") {
    let validMessage = true
    messageData.carousels.forEach((carousel) => {
      if (validMessage) {
        validMessage = carousel.header?.length > 0 &&
            carousel.message?.length > 0 &&
            validChangeOfLine(carousel.message) &&
            carousel.attachment?.image?.imgUrl?.length > 0 &&
            validButtons(carousel.attachment?.buttons)
      }
    })
    return messageData.carousels.length > 1 && validMessage
  }
  return false
}

const validButtons = (buttons) => {
  let isValid = true
  buttons?.forEach(button => {
    if ((button.type === "WL" && (!(button.name?.length > 0) || !(button.urlMobile?.length > 0))) ||
        (button.type === "COUPON" && (!(button.benefitId?.toString()?.length > 0) || isNaN(button.benefitId)))) {
      isValid = false
    }
  })
  return isValid
}

const validChangeOfLine = (text) => {
  return (text.match(/\n/g) || []).length < 2
}

export const testSend = async (userIdList, id) => {
  const res = await fetchApi.post(API_URL.TEST_VOLATILITY_MESSAGE, {
    userIdList: userIdList,
    messageId: id
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};

const isWideItemRequired = (data) => {
  return (
    data && data.link != null &&
    data.content?.length > 0 &&
    data.image != null
  );
};

const isTitleContentRequired = (data) => {
  return (
      data &&
      data.image != null &&
      data.title?.length > 0 &&
      data.content?.length > 0 &&
      validButtons(data.messageButtons)
  );
};