import {MessagePreview} from "omoplay";

const MessageWrapPreview = ({ messageData, type, fixed, isShowToggle }) => {

  return (
      <MessagePreview messageData={messageData ?? {}} type={type} fixed={fixed} isShowToggle={isShowToggle} />
  );
};

export default MessageWrapPreview;
