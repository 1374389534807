import {Alert, Button, RadioGroup, TextInput} from "omoplay";
import {useState} from "react";
import {postCounsel} from "../../api/account/account.api";


const AnotherMallForm = () => {
    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [url, setUrl] = useState("")
    const [framework, setFramework] = useState("")
    const [developer, setDeveloper] = useState("yes")
    const [other, setOther] = useState("")

    return (
        <>
            <div className="flex-col gap-2">
                <div>담당자 이름</div>
                <TextInput placeholder="아이디를 입력해 주세요."
                           autoComplete="off"
                           value={name}
                           onChange={(e) => {
                               setName(e.currentTarget.value)
                           }} />
            </div>
            <div className="flex-col gap-2">
                <div>연락처</div>
                <TextInput placeholder="연락처를 입력해 주세요"
                           autoComplete="off"
                           value={phoneNumber}
                           onChange={(e) => {
                               setPhoneNumber(e.currentTarget.value)
                           }} />
            </div>
            <div className="flex-col gap-2">
                <div>독립몰 주소 (URL)</div>
                <TextInput placeholder="독립몰이 있는 경우 주소를 입력해 주세요"
                           autoComplete="off"
                           value={url}
                           onChange={(e) => {
                               setUrl(e.currentTarget.value)
                           }} />
            </div>
            <div className="flex-col gap-2">
                <div>독립몰이 사용하는 프레임웍이 있나요?</div>
                <TextInput placeholder="예) 쇼피파이, 워드프레스 등. 모르는 경우 ‘모름’ 또는 공란으로 두시면 됩니다."
                           autoComplete="off"
                           value={framework}
                           onChange={(e) => {
                               setFramework(e.currentTarget.value)
                           }} />
            </div>
            <div className="flex-col gap-2">
                <div>백엔드와 프론트엔드를 개발할 수 있는 개발자가 있습니까? (외주 개발자 또는 자체 개발자)</div>
                <RadioGroup items={[{label: "개발자가 있습니다", value: "yes"}, {label: "개발자가 없습니다", value: "no"}]}
                            onSelected={(value) => {
                                setDeveloper(value)
                            }} />
            </div>
            <div className="flex-col gap-2">
                <div>기타 독립몰의 현재 상황에 대해 알려주세요</div>
                <TextInput placeholder="독립몰 관련 알아야 할 이슈 또는 부가 설명"
                           autoComplete="off"
                           value={other}
                           onChange={(e) => {
                               setOther(e.currentTarget.value)
                           }} />
            </div>
            <Alert title="독립몰인 경우 연동이 불가할 수도 있습니다." type="error" withIcon={true}>
                <label className="text-[13px]">
                    독립몰에 카카오 싱크 연동 및 설정이 필요하며, 카카오의 검수까지 마쳐야 서비스 연동이 가능합니다.<br/>
                    이를 위해 별도 개발이 필요할 수 있습니다.
                </label>
            </Alert>
            <div className="flex justify-end w-full">
                <Button variants="primary" size="large" value="상담 신청"
                        onClick={() => {
                            postCounsel(name, phoneNumber, url, framework, developer === "yes", other).then(() => {
                                alert("상담이 요청되었습니다.")
                                window.location.href = "/services"
                            })
                        }}
                />
            </div>
        </>
    )
}

export default AnotherMallForm