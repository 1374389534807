import {
    Alert,
    Button,
    DocBlack,
    Panel, RadioGroup, Tab,
    Table,
} from "omoplay";
import SuspensePopup from "../SuspensePopup";
import {useEffect, useState} from "react";
import AICreditIcon from "../../assets/images/icon.aicredit.group.svg"
import CreditFreeIcon from "../../assets/images/icon.credit.free.svg"
import CoinIcon from "../../assets/images/icon.coin.group.svg"
import BookIcon from "../../assets/images/icon.book.group.svg"
import MoneyIcon from "../../assets/images/icon.money.group.svg"
import FreeIcon from "../../assets/images/icon.free.group.svg"
import {pay, refund} from "../../api/cash/cash.api";
import {listCreditHistory} from "../../api/billing/billing.api";
import InputGroup from "../form/InputGroup";
import AutoChargeAiCreditForm from "../form/AutoChargeAiCreditForm";
import {getCredit, getReservedCredits, postAutoChargeCredit} from "../../api/account/account.api";
import AutoChargeMessageForm from "../form/AutoChargeMessageForm";
import CreditChargeForm from "../form/CreditChargeForm";
import RefundForm from "../form/RefundForm";

const Credit = () => {
    const [chargeOpen, setChargeOpen] = useState(false)
    const [refundOpen, setRefundOpen] = useState(false)
    const [billingHistories, setBillingHistories] = useState([])
    const [chargeAmount, setChargeAmount] = useState(0)
    const [refundHistory, setRefundHistory] = useState({})
    const [creditType, setCreditType] = useState("AI")
    const [creditTypeName, setCreditTypeName] = useState(creditType === "AI" ? "크레딧" : "예치금")
    const [showAutoCharge, setShowAutoCharge] = useState(false)
    const [selectedCardId, setSelectedCardId] = useState()
    const [onAutoCharge, setOnAutoCharge] = useState(false)
    const [availableCredit, setAvailableCredit] = useState(0)
    const [minCharge, setMinCharge] = useState()
    const [autoCharge, setAutoCharge] = useState()
    const [panelItems, setPanelItems] = useState([])
    const [reservedDataList, setReservedDataList] = useState([])
    const [reservedCredit, setReservedCredit] = useState(0)
    const [showReservedCreditList, setShowReservedCreditList] = useState(false)

    const historyHeaders = [
        { name: "날짜" }, { name: "유형" }, { name: "금액" }, { name: "결제 내역" }, { name: "만료 기한" }, { name: "환불" }
    ]

    useEffect(() => {
        listCreditHistory(creditType).then(({data}) => {
            setBillingHistories(data.map(history => {
                return [
                    <div className="flex-align-center h-full">{history.createdAt}</div>,
                    <div className="flex-align-center h-full">{history.type}</div>,
                    <div className="flex-align-center h-full">{history.credit.toLocaleString()} {creditTypeName} { creditType === "AI" && (`(${history.amount.toLocaleString()}원)`)}</div>,
                    <div className="flex-align-center h-full gap-1">
                        <DocBlack />
                        <div className="underline pointer" onClick={() =>
                            window.location.href = `/credit/detail?id=${history.id}`}>결제 내역 보기</div>
                    </div>,
                    <div className="flex-align-center h-full">{history.expiredAt}</div>,
                    <>
                        {
                            (history.amount > 0 && history.isRefund === false) ? (
                                <div className="flex-align-center h-full">
                                    <Button variants="danger" value="환불하기" size="small" onClick={() => {
                                        setRefundHistory(history)
                                        setRefundOpen(true)
                                    }} />
                                </div>
                            ) : <></>
                        }
                    </>
                ]
            }))
        })
        getCredit().then(({data}) => {
            if (creditType === "AI") {
                setPanelItems([
                    {
                        title: (
                            <>
                                <img src={AICreditIcon} alt="ai_credit" />
                                <div className="text-[16px] font-medium">충전 AI 크레딧</div>
                            </>
                        ),
                        value: (
                            <div>
                                <div className="flex items-baseline gap-1">
                                    <div className="text-[32px] text-gradient">
                                        {data.aiCredit.toLocaleString()}
                                    </div>
                                    <div>Credit</div>
                                </div>
                                <div className="flex-align-center justify-end gap-2">
                                    <div>
                                        <Button variants="primary" value="+ AI 크레딧 충전" size="large" onClick={() => setChargeOpen(true)} />
                                    </div>
                                </div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <>
                                <img src={CreditFreeIcon} alt="ai_credit" />
                                <div className="text-[16px] font-medium">무료 제공 AI 크레딧</div>
                            </>
                        ),
                        value: (
                            <div>
                                <div className="flex items-baseline gap-1">
                                    <div className="text-[32px] text-pointactive">
                                        {data.freeAiCredit.toLocaleString()}
                                    </div>
                                    <div>Credit</div>
                                </div>
                            </div>
                        ),
                    },
                ])
                setOnAutoCharge(data.onAutoChargeAi)
                setMinCharge(data.minAiCredit)
                setAutoCharge(data.autoChargeAiCredit)
                setAvailableCredit(data.aiCredit)
            } else {
                getReservedCredits().then(({data: reservedData}) => {
                    setReservedDataList(reservedData)
                    const reservedCredit = reservedData.reduce((previousValue, currentValue) =>
                        previousValue + currentValue.useCredit, 0)
                    setPanelItems([
                        {
                            title: (
                                <>
                                    <img src={MoneyIcon} alt="message_money" />
                                    <div className="text-[16px] font-medium">사용 가능 예치금</div>
                                </>
                            ),
                            value: (
                                <div>
                                    <div className="flex items-baseline gap-1">
                                        <div className="text-[32px] text-primary">
                                            {(data.messageCredit - reservedCredit + data.freeMessageCredit).toLocaleString()}
                                        </div>
                                        <div>원</div>
                                        <div>(KRW)</div>
                                    </div>
                                </div>
                            ),
                        },
                        {
                            title: (
                                <>
                                    <img src={CoinIcon} alt="message_coin" />
                                    <div className="text-[16px] font-medium">충전 예치금 잔액</div>
                                </>
                            ),
                            value: (
                                <div>
                                    <div className="flex items-baseline gap-1">
                                        <div className="text-[32px]">
                                            {data.messageCredit.toLocaleString()}
                                        </div>
                                        <div>원</div>
                                        <div>(KRW)</div>
                                    </div>
                                    <div className="flex-align-center justify-end gap-2">
                                        <div>
                                            <Button variants="primary" value="+ 예치금 충전" size="large" onClick={() => setChargeOpen(true)} />
                                        </div>
                                    </div>
                                </div>
                            ),
                        },
                        {
                            title: (
                                <>
                                    <img src={BookIcon} alt="message_book" />
                                    <div className="text-[16px] font-medium">예약된 예치금 ({reservedData.length}건)</div>
                                    <div className="text-primary underline pointer" onClick={() => setShowReservedCreditList(true)}>상세보기</div>
                                </>
                            ),
                            value: (
                                <div>
                                    <div className="flex items-baseline gap-1">
                                        <div className="text-[32px]">
                                            {
                                                reservedCredit.toLocaleString()
                                            }
                                        </div>
                                        <div>원</div>
                                        <div>(KRW)</div>
                                    </div>
                                </div>
                            ),
                        },
                        {
                            title: (
                                <>
                                    <img src={FreeIcon} alt="message_free" />
                                    <div className="text-[16px] font-medium">무료 제공 예치금</div>
                                </>
                            ),
                            value: (
                                <div>
                                    <div className="flex items-baseline gap-1">
                                        <div className="text-[32px] text-primary">
                                            {data.freeMessageCredit.toLocaleString()}
                                        </div>
                                        <div>원</div>
                                        <div>(KRW)</div>
                                    </div>
                                </div>
                            ),
                        }
                    ])
                    setReservedCredit(reservedCredit)
                    setOnAutoCharge(data.onAutoChargeMessage)
                    setMinCharge(data.minMessageCredit)
                    setAutoCharge(data.autoChargeMessageCredit)
                    setAvailableCredit(data.messageCredit)
                })
            }
        })
    }, [creditType])


    return (
        <>
            <div className="flex-col gap-4">
                <Alert title="크레딧 자동 충전을 설정하면 더 편리합니다." type="info" withIcon={true} />
                <div>
                    <Tab variants="default"
                         style={{gap: "4px"}}
                         items={[
                             {label: "AI 크레딧", value: "AI", selected: creditType === "AI"},
                             {label: "예치금", value: "MESSAGE", selected: creditType === "MESSAGE"}
                         ]}
                         onChanged={(item) => {
                             setCreditType(item.value)
                             setCreditTypeName(item.value === "AI" ? "크레딧" : "예치금")
                         }}
                    />
                </div>
                <Panel items={panelItems} />
                <InputGroup label="크레딧 자동 충전">
                    <div className="w-[600px]">
                        <RadioGroup key={onAutoCharge} initChecked={onAutoCharge ? "auto" : "passive"} items={[{label: (
                                <div>
                                    <div>수동으로 충전합니다</div>
                                    <div className="text-tertiary">자동화 메시지가 {creditTypeName} 부족으로 발송되지 않을 수도 있습니다.</div>
                                </div>
                            ), value: "passive"},
                            {label: (
                                    <div>
                                        <div>설정한 값을 기준으로 {creditTypeName}을 자동으로 충전합니다</div>
                                        <div className="flex text-tertiary">
                                            <div>사용 가능 {creditTypeName}이 </div>
                                            <div className="ml-1 underline font-semibold">{minCharge?.toLocaleString()} {creditType === "AI" ? "Credit" : "원(KRW)"} 이하</div>
                                            <div>로 떨어지면</div>
                                            <div className="ml-1 underline font-semibold">{autoCharge?.toLocaleString()} {creditType === "AI" ? "Credit" : "원(KRW)"}</div>
                                            <div>을 자동으로 충전.</div>
                                            <div className="text-primary underline pointer ml-1" onClick={() => setShowAutoCharge(true)}>설정하기</div>
                                        </div>
                                    </div>
                                ), value: "auto"}]}
                                    onSelected={(value) => {
                                        postAutoChargeCredit(value === "auto", creditType, minCharge, autoCharge).then(r => r)
                                    }}
                        />
                    </div>
                </InputGroup>
                <Table headers={historyHeaders} data={billingHistories} />
            </div>
            <SuspensePopup title={creditType === "AI" ? "AI 크레딧 충전" : "예치금 충전"} visible={chargeOpen}
                           onClose={() => setChargeOpen(false)}
                           confirmText="충전하기"
                           onConfirm={() => {
                               if (chargeAmount >= 1000 && selectedCardId !== undefined) {
                                   const charge = creditType === "AI" ? chargeAmount * 1.1 : chargeAmount
                                   if (window.confirm(`총 ${charge.toLocaleString()}원이 결제됩니다. 진행하시겠습니까?`)) {
                                       pay(creditType, selectedCardId, charge).then(({data}) => {
                                           if (data.resultCd === "0000") {
                                               alert("결제 되었습니다.")
                                               window.location.reload()
                                           } else {
                                               alert(data.resultMsg)
                                           }
                                       })
                                   }
                               }
                           }}
            >
                <CreditChargeForm creditType={creditType} onSelectedCard={setSelectedCardId} onChargeAmount={setChargeAmount} />
            </SuspensePopup>
            <SuspensePopup title={creditType === "AI" ? "AI 크레딧 환불" : "예치금 환불"} visible={refundOpen}
                           onClose={() => setRefundOpen(false)}
                           confirmText="환불하기"
                           onConfirm={() => {
                               const amount = refundHistory.amount
                               if (refundHistory.credit < availableCredit) {
                                   if (window.confirm(`환불된 이후에는 철회할 수 없습니다. 환불 하시겠습니까?`)) {
                                       refund(refundHistory.id, amount).then(({data}) => {
                                           if (data.resultCd === "0000") {
                                               alert("환불 되었습니다. 카드사에 따라 시간이 소요될 수 있습니다.")
                                               setRefundOpen(false)
                                           } else {
                                               alert(data.resultMsg)
                                           }
                                       })
                                   }
                               }
                           }}
            >
                <RefundForm creditType={creditType}
                            availableCredit={availableCredit}
                            cardId={refundHistory.cardId}
                            refundCredit={refundHistory.credit}
                            refundAmount={refundHistory.amount}
                />
            </SuspensePopup>
            <SuspensePopup title={creditType === "AI" ? "AI 크레딧 자동 충전" : "예치금 자동 충전"} visible={showAutoCharge}
                           onClose={() => setShowAutoCharge(false)}
                           style={{width: "600px"}}
                           confirmText="확인"
                           onConfirm={() => {
                               if (selectedCardId && minCharge > 100 && autoCharge > 500) {
                                   postAutoChargeCredit(onAutoCharge, creditType, minCharge, autoCharge).then(() => {
                                       alert("변경 되었습니다.")
                                       setShowAutoCharge(false)
                                   })
                               }
                           }}
            >
                {
                    creditType === "AI" ? (
                        <AutoChargeAiCreditForm
                            minCharge={minCharge}
                            autoCharge={autoCharge}
                            onMinCharge={setMinCharge}
                            onAutoCharge={setAutoCharge}
                            onSelectedCard={setSelectedCardId}
                            onOnCharge={setOnAutoCharge}
                        />
                    ) : (
                        <AutoChargeMessageForm
                            minCharge={minCharge}
                            autoCharge={autoCharge}
                            onMinCharge={setMinCharge}
                            onAutoCharge={setAutoCharge}
                            onSelectedCard={setSelectedCardId}
                            onOnCharge={setOnAutoCharge}
                        />
                    )
                }
            </SuspensePopup>
            <SuspensePopup title={"예약된 예치금 내역"} visible={showReservedCreditList}
                           onClose={() => setShowReservedCreditList(false)}
                           style={{width: "900px"}}
            >
                <div className="flex-col gap-2">
                    <div>
                        <Panel variants="bar" items={[
                            { title: "예약 건", value: reservedDataList.length + "건", style: {flex: "1"} },
                            { title: "예약 예치금", value: reservedCredit.toLocaleString()  + "원 (KRW)",
                                style: {flex: "1"}
                            }
                        ]} />
                    </div>
                    <div>
                        <Table headers={[{name: "캠페인 명"}, {name: "발송 수(예상)"}, {name: "예약 예치금"}, {name: "발송 일시"}]}
                               data={reservedDataList.map(reserved => {
                                   return [
                                       <div className="flex-align-center h-full">{reserved.name}</div>,
                                       <div className="flex-align-center h-full">{reserved.sendCount.toLocaleString()}건</div>,
                                       <div className="flex-align-center h-full">{reserved.useCredit.toLocaleString()}원 (KRW)</div>,
                                       <div className="flex-align-center h-full">{reserved.sendDateTime}</div>,
                                   ]
                               })} />
                    </div>
                </div>
            </SuspensePopup>
        </>
    )
}

export default Credit