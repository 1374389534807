import {Button, CalendarBar, Dropdown, RadioGroup} from "omoplay";
import moment from "moment";
import {useEffect, useState} from "react";
import {postProjectPeriod} from "../../../api/project/project.api";


const ProjectDetailPeriod = ({projectId, initStartDate, initEndDate, disabledEdit}) => {
    const [type, setType] = useState("period")
    const [startDate, setStartDate] = useState(moment().hours(0).minutes(0))
    const [endDate, setEndDate] = useState(moment().hours(0).minutes(0))
    const format = "yyyy년 MM월 DD일 HH시 mm분"

    useEffect(() => {
        setStartDate(moment(initStartDate))
        setEndDate(initEndDate ? moment(initEndDate) : moment().hours(0).minutes(0))
        setType(initEndDate ? "period" : "always")
    }, [initStartDate, initEndDate]);

    const onSubmit = (startDate, endDate) => {
        postProjectPeriod(projectId, startDate, endDate).then(() => {
            alert("적용 되었습니다.")
        })
    }

    return (
        <>
            <div className="flex justify-between">
                <div>
                    <div className="font-bold font-20px">프로젝트 기간</div>
                </div>
                <div>
                    <Button variants="dark" value="적용하기" onClick={() => {
                        onSubmit(startDate.format("YYYY-MM-DD HH:mm"), type === "period" ? endDate.format("YYYY-MM-DD HH:mm") : null)
                    }} />
                </div>
            </div>
            <div className="flex-col gap-4 bg-white">
                <div className="flex justify-evenly p-4 rounded-lg gap-8 w-full">
                    <div>프로젝트 기간</div>
                    <div style={{borderLeft: "1px solid var(--grey-2)"}}></div>
                    <div className="flex gap-2" style={{flexGrow: "1"}}>
                        <div>{`${startDate.format(format)} ~ ${type === "always" ? '상시 진행' : endDate.format(format)}`}</div>
                        {
                            type === "period" && (
                                <div className="text-mainblue-0 font-semibold">({endDate.diff(startDate, 'days')}일간)</div>
                            )
                        }
                    </div>
                </div>
                <div className="pl-20px">
                    <RadioGroup key={endDate} items={[
                        { label: "상시 진행", value: "always" },
                        { label: "기간 설정", value: "period" }
                    ]} initChecked={type} onSelected={(value) => {
                        setType(value)
                    }} />
                </div>
                <div className="flex-align-center gap-4 p-20px">
                    <div>시작 날짜</div>
                    <div>
                        <CalendarBar
                            initDate={{
                                startDate: startDate.toDate(),
                                endDate: startDate.toDate()
                            }}
                            multiple={false}
                            minDate={new Date()}
                            onConfirm={({startDate}) => {
                                setStartDate(moment(startDate))
                            }}
                            disabled={disabledEdit}
                        />
                    </div>
                    <div className="flex items-center gap-4">
                        <div>
                            <Dropdown key={startDate}
                                      variants="default"
                                      initialValue={startDate.hours().toString()}
                                      disabled={disabledEdit}
                                      items={[
                                          {label: "00시", value: "0"},{label: "01시", value: "1"},
                                          {label: "02시", value: "2"},{label: "03시", value: "3"},
                                          {label: "04시", value: "4"},{label: "05시", value: "5"},
                                          {label: "06시", value: "6"},{label: "07시", value: "7"},
                                          {label: "08시", value: "8"}, {label: "09시", value: "9"},
                                          {label: "10시", value: "10"}, {label: "11시", value: "11"},
                                          {label: "12시", value: "12"}, {label: "13시", value: "13"},
                                          {label: "14시", value: "14"}, {label: "15시", value: "15"},
                                          {label: "16시", value: "16"}, {label: "17시", value: "17"},
                                          {label: "18시", value: "18"}, {label: "19시", value: "19"},
                                          {label: "20시", value: "20"}, {label: "21시", value: "21"},
                                          {label: "22시", value: "22"}, {label: "23시", value: "23"}
                                      ]}
                                      onChanged={(items) => {
                                          setStartDate(startDate.hours(items[0].value))
                                      }} />
                        </div>
                        <div>
                            <Dropdown key={startDate}
                                      variants="default"
                                      initialValue={startDate.minutes().toString()}
                                      disabled={disabledEdit}
                                      items={[
                                          {label: "00분", value: "0"}, {label: "05분", value: "5"},
                                          {label: "10분", value: "10"}, {label: "15분", value: "15"},
                                          {label: "20분", value: "20"}, {label: "25분", value: "25"},
                                          {label: "30분", value: "30"}, {label: "35분", value: "35"},
                                          {label: "40분", value: "40"}, {label: "45분", value: "45"},
                                          {label: "50분", value: "50"}, {label: "55분", value: "55"},
                                      ]}
                                      onChanged={(items) => {
                                          setStartDate(startDate.minutes(items[0].value))
                                      }} />
                        </div>
                    </div>
                    <div className="flex-center-all font-bold">~</div>
                    <div className="flex-align-center gap-4">
                        {
                            type === "always" ? (
                                <div>상시 진행</div>
                            ) : (
                                <>
                                    <div>종료 날짜</div>
                                    <div>
                                        <CalendarBar initDate={{
                                            startDate: endDate.toDate(),
                                            endDate: endDate.toDate()
                                        }} multiple={false} onConfirm={({endDate}) => {
                                            setEndDate(moment(endDate))
                                        }} minDate={new Date()}
                                        />
                                    </div>
                                    <div>
                                        <Dropdown key={endDate}
                                                  variants="default"
                                                  initialValue={endDate.hours().toString()}
                                                  items={[
                                                      {label: "00시", value: "0"},{label: "01시", value: "1"},
                                                      {label: "02시", value: "2"},{label: "03시", value: "3"},
                                                      {label: "04시", value: "4"},{label: "05시", value: "5"},
                                                      {label: "06시", value: "6"},{label: "07시", value: "7"},
                                                      {label: "08시", value: "8"}, {label: "09시", value: "9"},
                                                      {label: "10시", value: "10"}, {label: "11시", value: "11"},
                                                      {label: "12시", value: "12"}, {label: "13시", value: "13"},
                                                      {label: "14시", value: "14"}, {label: "15시", value: "15"},
                                                      {label: "16시", value: "16"}, {label: "17시", value: "17"},
                                                      {label: "18시", value: "18"}, {label: "19시", value: "19"},
                                                      {label: "20시", value: "20"}, {label: "21시", value: "21"},
                                                      {label: "22시", value: "22"}, {label: "23시", value: "23"}
                                                  ]}
                                                  onChanged={(items) => {
                                                      setEndDate(endDate.hours(items[0].value))
                                                  }}
                                                  disabled={type === "always"}
                                        />
                                    </div>
                                    <div>
                                        <Dropdown key={endDate}
                                                  variants="default"
                                                  initialValue={endDate.minutes().toString()}
                                                  items={[
                                                      {label: "00분", value: "0"}, {label: "05분", value: "5"},
                                                      {label: "10분", value: "10"}, {label: "15분", value: "15"},
                                                      {label: "20분", value: "20"}, {label: "25분", value: "25"},
                                                      {label: "30분", value: "30"}, {label: "35분", value: "35"},
                                                      {label: "40분", value: "40"}, {label: "45분", value: "45"},
                                                      {label: "50분", value: "50"}, {label: "55분", value: "55"},
                                                  ]}
                                                  onChanged={(items) => {
                                                      setEndDate(endDate.minutes(items[0].value))
                                                  }}
                                                  disabled={type === "always"}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectDetailPeriod