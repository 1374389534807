

const Badge = ({name, color, padding}) => {
    return (
        <div className={`badge ${color}`} style={{padding: padding}}>
            {name}
        </div>
    )
}

export default Badge