import Layout from "../components/layout/Layout";
import VolatilityMessageDetail from "../components/volatility-message/VolatilityMessageDetail";

const VolatilityMessageDetailPage = () => {

  return (
    <Layout>
        <VolatilityMessageDetail />
    </Layout>
  );
};
export default VolatilityMessageDetailPage;
