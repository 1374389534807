import InputGroup from "../form/InputGroup";
import {Button, Tag, Textarea, TextInput} from "omoplay";
import React, {useEffect, useState} from "react";
import {getBrand, saveBrand} from "../../api/brand/brand.api";
import ImageForm from "../ImageForm";

const BrandDetail = () => {
    const [logoUrl, setLogoUrl] = useState()
    const [targetDescription, setTargetDescription] = useState()
    const [name, setName] = useState("")

    useEffect(() => {
        getBrand().then(({data}) => {
            setName(data?.name)
            setTargetDescription(data?.targetDescription)
            setLogoUrl(data?.logoUrl)
        })
    }, [])

    const saveBrandInfo = () => {
        saveBrand(name, logoUrl, targetDescription).then(() => {
            alert("저장 되었습니다.")
        })
    }

    return (
        <div className="flex-col items-center w-full">
            <div className="w-[720px] flex-col gap-[16px]">
                <div className="flex gap-[16px]">
                    <div>
                        {
                            logoUrl ? <img className="w-[80px] h-[80px]" alt="logo-url" src={logoUrl} />
                                : <div className="w-[80px] h-[80px] bg-white"></div>
                        }

                    </div>
                    <div className="flex-col gap-1">
                        <div><Tag color="info">브랜드명</Tag></div>
                        <div className="text-[28px] font-extrabold">{name}</div>
                    </div>
                </div>
                <InputGroup label="브랜드 기본 정보">
                    <div className="flex-col gap-[24px]">
                        <div className="flex-col gap-2">
                            <div className="font-semibold">브랜드명</div>
                            <div><TextInput value={name} onChange={(e) => setName(e.target.value)} /></div>
                        </div>
                        <div className="flex-col gap-2">
                            <div className="font-semibold">브랜드 로고</div>
                            <ImageForm imageUrl={logoUrl} onChangeImageUrl={(url) => setLogoUrl(url)} />
                        </div>
                    </div>
                </InputGroup>
                <InputGroup label="브랜드 핵심 타겟 고객">
                    <Textarea value={targetDescription} onChange={(e) => setTargetDescription(e.target.value)} />
                </InputGroup>
                <div className="flex justify-end">
                    <Button variants="primary" value="저장하기" size="large"
                            onClick={saveBrandInfo}
                            style={{width: "160px"}} />
                </div>
            </div>
        </div>
    );
};

export default BrandDetail;
