import Pretendard from "../../assets/fonts/01Pretendard.png"
import Hansansneo from "../../assets/fonts/02Hansansneo.png"
import OAGothic from "../../assets/fonts/03OAGothic.png"
import Paperlogy from "../../assets/fonts/04Paperlogy.png"
import Aggro from "../../assets/fonts/05Aggro.png"
import Ohsquare from "../../assets/fonts/06Ohsquare.png"
import Maruburi from "../../assets/fonts/07Maruburi.png"
import Mapoflowerisland from "../../assets/fonts/08Mapoflowerisland.png"
import Gowundodum from "../../assets/fonts/09Gowundodum.png"
import Aritadotum from "../../assets/fonts/10Aritadotum.png"
import Euljiro from "../../assets/fonts/11Euljiro.png"
import Supermagic from "../../assets/fonts/12Supermagic.png"
import Ownglyphwiseelist from "../../assets/fonts/13Ownglyphwiseelist.png"
import Ownglyphpdh from "../../assets/fonts/14Ownglyphpdh.png"
import Ownglyphruttung from "../../assets/fonts/15Ownglyphruttung.png"


export const FONTS = [
  {
      "fontName": "프리텐다드",
      "fontFamilyName": "Pretendard",
      "fontPreviewThumbnailUrl": `${Pretendard}`,
      "fontFaces": [
          {
              "weight": "100",
              "local": ["Pretendard Thin", "Pretendard-Thin"],
              "woff2": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff2/Pretendard-Thin.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff/Pretendard-Thin.woff"
          },
          {
              "weight": "200",
              "local": ["Pretendard ExtraLight", "Pretendard-ExtraLight"],
              "woff2": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff2/Pretendard-ExtraLight.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff/Pretendard-ExtraLight.woff"
          },
          {
              "weight": "300",
              "local": ["Pretendard Light", "Pretendard-Light"],
              "woff2": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff2/Pretendard-Light.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff/Pretendard-Light.woff",
              "default": true
          },
          {
              "weight": "400",
              "local": ["Pretendard Regular", "Pretendard-Regular"],
              "woff2": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff2/Pretendard-Regular.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff/Pretendard-Regular.woff"
          },
          {
              "weight": "500",
              "local": ["Pretendard Medium", "Pretendard-Medium"],
              "woff2": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff2/Pretendard-Medium.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff/Pretendard-Medium.woff"
          },
          {
              "weight": "600",
              "local": ["Pretendard SemiBold", "Pretendard-SemiBold"],
              "woff2": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff2/Pretendard-SemiBold.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff/Pretendard-SemiBold.woff"
          },
          {
              "weight": "700",
              "local": ["Pretendard Bold", "Pretendard-Bold"],
              "woff2": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff2/Pretendard-Bold.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff/Pretendard-Bold.woff"
          },
          {
              "weight": "800",
              "local": ["Pretendard ExtraBold", "Pretendard-ExtraBold"],
              "woff2": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff2/Pretendard-ExtraBold.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff/Pretendard-ExtraBold.woff"
          },
          {
              "weight": "900",
              "local": ["Pretendard Black", "Pretendard-Black"],
              "woff2": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff2/Pretendard-Black.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/packages/pretendard/dist/web/static/woff/Pretendard-Black.woff"
          }
      ]
  },
  {
      "fontName": "스포카 한 산스 네오",
      "fontFamilyName": "Spoqa Han Sans Neo",
      "fontPreviewThumbnailUrl": `${Hansansneo}`,
      "fontFaces": [
          {
              "weight": "100",
              "local": ["Spoqa Han Sans Neo Thin", "SpoqaHanSansNeo-Thin"],
              "woff2": "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff"
          },
          {
              "weight": "300",
              "local": ["Spoqa Han Sans Neo Light", "SpoqaHanSansNeo-Light"],
              "woff2": "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff"
          },
          {
              "weight": "400",
              "local": ["Spoqa Han Sans Neo Regular", "SpoqaHanSansNeo-Regular"],
              "woff2": "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff"
          },
          {
              "weight": "500",
              "local": ["Spoqa Han Sans Neo Medium", "SpoqaHanSansNeo-Medium"],
              "woff2": "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff"
          },
          {
              "weight": "700",
              "local": ["Spoqa Han Sans Neo Bold", "SpoqaHanSansNeo-Bold"],
              "woff2": "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff2",
              "woff": "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff"
          }
      ]
  },
  {
      "fontName": "오아고딕",
      "fontFamilyName": "OAGothic",
      "fontPreviewThumbnailUrl": `${OAGothic}`,
      "fontFaces": [
          {
              "weight": "500",
              "local": ["OA Gothic Medium", "OAGothic-Medium"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/OAGothic-Medium.woff2",
              "woff": ""
          },
          {
              "weight": "800",
              "local": ["OAGothic ExtraBold", "OAGothic-ExtraBold"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/OAGothic-ExtraBold.woff2",
              "woff": ""
          }
      ]
  },
  {
      "fontName": "페이퍼로지",
      "fontFamilyName": "Paperlogy",
      "fontPreviewThumbnailUrl": `${Paperlogy}`,
      "fontFaces": [
          {
              "weight": "100",
              "local": ["Paperlogy Thin", "Paperlogy-Thin"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-1Thin.woff2",
              "woff": ""
          },
          {
              "weight": "200",
              "local": ["Paperlogy ExtraLight", "Paperlogy-ExtraLight"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-2ExtraLight.woff2",
              "woff": ""
          },
          {
              "weight": "300",
              "local": ["Paperlogy Light", "Paperlogy-Light"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-3Light.woff2",
              "woff": ""
          },
          {
              "weight": "400",
              "local": ["Paperlogy Regular", "Paperlogy-Regular"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-4Regular.woff2",
              "woff": ""
          },
          {
              "weight": "500",
              "local": ["Paperlogy Medium", "Paperlogy-Medium"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-5Medium.woff2",
              "woff": ""
          },
          {
              "weight": "600",
              "local": ["Paperlogy SemiBold", "Paperlogy-SemiBold"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-6SemiBold.woff2",
              "woff": ""
          },
          {
              "weight": "700",
              "local": ["Paperlogy Bold", "Paperlogy-Bold"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-7Bold.woff2",
              "woff": ""
          },
          {
              "weight": "800",
              "local": ["Paperlogy ExtraBold", "Paperlogy-ExtraBold"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-8ExtraBold.woff2",
              "woff": ""
          },
          {
              "weight": "900",
              "local": ["Paperlogy Black", "Paperlogy-Black"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-9Black.woff2",
              "woff": ""
          }
      ]
  },
  {
      "fontName": "어그로체",
      "fontFamilyName": "SBAggro",
      "fontPreviewThumbnailUrl": `${Aggro}`,
      "fontFaces": [
          {
              "weight": "300",
              "local": ["SBAggro Light", "SBAggroL"],
              "woff2": "",
              "woff": "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroL.woff"
          },
          {
              "weight": "500",
              "local": ["SBAggro Medium", "SBAggroM"],
              "woff2": "",
              "woff": "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroM.woff"
          },
          {
              "weight": "700",
              "local": ["SBAggro Bold", "SBAggroB"],
              "woff2": "",
              "woff": "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff"
          }
      ]
  },
  {
      "fontName": "카페24 아네모네",
      "fontFamilyName": "Cafe24Ohsquare",
      "fontPreviewThumbnailUrl": `${Ohsquare}`,
      "fontFaces": [
          {
              "weight": "300",
              "local": ["Cafe24 Ohsquare Air (Light)", "Cafe24Ohsquareair-Light"],
              "woff2": "",
              "woff": "https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202@1.0/Cafe24Ohsquareair.woff"
          },
          {
              "weight": "400",
              "local": ["Cafe24 Ohsquare (Regular)", "Cafe24Ohsquare-Regular"],
              "woff2": "",
              "woff": "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/Cafe24Ohsquare.woff"
          }
      ]
  },
  {
      "fontName": "마루부리",
      "fontFamilyName": "MaruBuri",
      "fontPreviewThumbnailUrl": `${Maruburi}`,
      "fontFaces": [
          {
              "weight": "200",
              "local": ["MaruBuri ExtraLight", "MaruBuri-ExtraLight"],
              "woff2": "https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-ExtraLight.woff2",
              "woff": "https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-ExtraLight.woff"
          },
          {
              "weight": "300",
              "local": ["MaruBuri Light", "MaruBuri-Light"],
              "woff2": "https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Light.woff2",
              "woff": "https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Light.woff"
          },
          {
              "weight": "400",
              "local": ["MaruBuri Regular", "MaruBuri-Regular"],
              "woff2": "https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.woff2",
              "woff": "https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.woff"
          },
          {
              "weight": "600",
              "local": ["MaruBuri SemiBold", "MaruBuri-SemiBold"],
              "woff2": "https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-SemiBold.woff2",
              "woff": "https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-SemiBold.woff"
          },
          {
              "weight": "700",
              "local": ["MaruBuri Bold", "MaruBuri-Bold"],
              "woff2": "https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Bold.woff2",
              "woff": "https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Bold.woff"
          }
      ]
  },
  {
      "fontName": "마포꽃섬",
      "fontFamilyName": "MapoFlowerIsland",
      "fontPreviewThumbnailUrl": `${Mapoflowerisland}`,
      "fontFaces": [
          {
              "weight": "400",
              "local": ["Mapo FlowerIsland (Regular)", "MapoFlowerIsland-Regular"],
              "woff2": "",
              "woff": "https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/MapoFlowerIslandA.woff"
          }
      ]
  },
  {
      "fontName": "고운돋움",
      "fontFamilyName": "GowunDodum",
      "fontPreviewThumbnailUrl": `${Gowundodum}`,
      "fontFaces": [
          {
              "weight": "400",
              "local": ["Gowun Dodum (Regular)", "GowunDodum-Regular"],
              "woff2": "https://fonts.ladn.org/ofl/gowundodum/GowunDodum-Regular.woff2",
              "woff": "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/GowunDodum-Regular.woff"
          }
      ]
  },
  {
      "fontName": "아리따돋움",
      "fontFamilyName": "Arita-dotum",
      "fontPreviewThumbnailUrl": `${Aritadotum}`,
      "fontFaces": [
          {
              "weight": "100",
              "local": ["Arita-dotum Thin", "Arita-dotum-Thin", "Arita4.0_T"],
              "woff2": "https://cdn.jsdelivr.net/gh/taevel02/typeface-arita/Arita4.0_T.woff2",
              "woff": "https://webfontbox.github.io/Arita/src/Arita4.0_T.woff"
          },
          {
              "weight": "300",
              "local": ["Arita-dotum Light", "Arita-dotum-Light", "Arita4.0_L"],
              "woff2": "https://cdn.jsdelivr.net/gh/taevel02/typeface-arita/Arita4.0_L.woff2",
              "woff": "https://webfontbox.github.io/Arita/src/Arita4.0_L.woff"
          },
          {
              "weight": "500",
              "local": ["Arita-dotum Medium", "Arita-dotum-Medium", "Arita4.0_M"],
              "woff2": "https://cdn.jsdelivr.net/gh/taevel02/typeface-arita/Arita4.0_M.woff2",
              "woff": "https://webfontbox.github.io/Arita/src/Arita4.0_M.woff"
          },
          {
              "weight": "600",
              "local": ["Arita-dotum SemiBold", "Arita-dotum-SemiBold", "Arita4.0_SB"],
              "woff2": "https://cdn.jsdelivr.net/gh/taevel02/typeface-arita/Arita4.0_SB.woff2",
              "woff": "https://webfontbox.github.io/Arita/src/Arita4.0_SB.woff"
          },
          {
              "weight": "700",
              "local": ["Arita-dotum Bold", "Arita-dotum-Bold", "Arita4.0_B"],
              "woff2": "https://cdn.jsdelivr.net/gh/taevel02/typeface-arita/Arita4.0_B.woff2",
              "woff": "https://webfontbox.github.io/Arita/src/Arita4.0_B.woff"
          }
      ]
  },
  {
      "fontName": "을지로체",
      "fontFamilyName": "BMEULJIRO",
      "fontPreviewThumbnailUrl": `${Euljiro}`,
      "fontFaces": [
          {
              "weight": "400",
              "local": ["BM EULJIRO", "BMEULJIRO (Regular)"],
              "woff2": "",
              "woff": "https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/BMEULJIRO.woff"
          }
      ]
  },
  {
      "fontName": "카페24 슈퍼매직",
      "fontFamilyName": "Cafe24Supermagic",
      "fontPreviewThumbnailUrl": `${Supermagic}`,
      "fontFaces": [
          {
              "weight": "400",
              "local": ["Cafe24 Supermagic Regular", "Cafe24Supermagic-Regular"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2307-2@1.0/Cafe24Supermagic-Regular-v1.0.woff2",
              "woff": ""
          },
          {
              "weight": "700",
              "local": ["Cafe24 Supermagic Bold", "Cafe24Supermagic-Bold"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2307-2@1.0/Cafe24Supermagic-Bold-v1.0.woff2",
              "woff": ""
          }
      ]
  },
  {
      "fontName": "온글잎 위씨리스트",
      "fontFamilyName": "Ownglyph_wiseelist-Rg",
      "fontPreviewThumbnailUrl": `${Ownglyphwiseelist}`,
      "fontFaces": [
          {
              "weight": "400",
              "local": ["Ownglyph wiseelingRg", "Ownglyph_wiseelist-Rg"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/2501-1@1.1/Ownglyph_wiseelist-Rg.woff2",
              "woff": ""
          }
      ]
  },
  {
      "fontName": "온글잎 박다현체",
      "fontFamilyName": "Ownglyph_ParkDaHyun",
      "fontPreviewThumbnailUrl": `${Ownglyphpdh}`,
      "fontFaces": [
          {
              "weight": "400",
              "local": ["Ownglyph PDHRg", "Ownglyph_PDH-Rg"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/2411-3@1.0/Ownglyph_ParkDaHyun.woff2",
              "woff": ""
          }
      ]
  },
  {
      "fontName": "온글잎 류뚱체",
      "fontFamilyName": "Ownglyph_ryuttung-Rg",
      "fontPreviewThumbnailUrl": `${Ownglyphruttung}`,
      "fontFaces": [
          {
              "weight": "400",
              "local": ["Ownglyph ryuttungRg", "Ownglyph_ryuttung-Rg"],
              "woff2": "https://fastly.jsdelivr.net/gh/projectnoonnu/2405-2@1.0/Ownglyph_ryuttung-Rg.woff2",
              "woff": ""
          }
      ]
  }
]
