import { Box, Typography } from "@mui/material";
import { ReactComponent as ArrowLeft } from "../../../../assets/images/arrrow-left.svg";
import { ReactComponent as Search } from "../../../../assets/images/search-icon.svg";
import { ReactComponent as Hamburger } from "../../../../assets/images/hamburger.svg";
const PreviewTemplate = ({ children, isAd, fixed }) => {
    return (
        <Box>
            <Box className="preview-wrap" style={fixed ? {position: "fixed"} : {}}>
                <Box className="preview-item">
                    <Box className="preview-header">
                        <Box className="chat-arrow-left">
                            <ArrowLeft />
                        </Box>
                        <Box sx={{ textAlign: "center" }}>
                            <Typography
                                className="grey-7-color"
                                sx={{
                                    fontSize: "12px",
                                    fontWeight: 700,
                                }}
                            >
                                헤드리스 스토어
                            </Typography>
                            <Typography
                                className="grey-7-color"
                                sx={{ fontSize: "10px", color: "" }}
                            >
                                1234-1234
                            </Typography>
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    marginRight: "12px",
                                    display: "inline-block",
                                }}
                            >
                                <Search />
                            </Box>
                            <Hamburger />
                        </Box>
                    </Box>
                    <Box className="preview-content">
                        <Box className="preview-icon-box">
                            <Typography
                                sx={{
                                    color: "#FFF",
                                    fontSize: "14px",
                                    lineHeight: "24px",
                                    width: "24px",
                                }}
                            >
                                H
                            </Typography>
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Box className="message-title">
                                <Typography
                                    className="grey-7-color"
                                    fontSize="10px"
                                >
                                    {isAd === "Y" ? "(광고)" : ""}헤드리스
                                </Typography>
                            </Box>
                            {children}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default PreviewTemplate;
