import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import store from "./store/index";
import "tailwindcss/tailwind.css";
import "./assets/common.css";
import { RecoilRoot } from "recoil";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ko";
import { ThemeProvider, createTheme } from "@mui/material";
import {LoadingProvider} from "./components/provider/LoadingProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    primary: {
      main: "#4046E3",
    },
    grey: createColor("#eaeaea"),
    grey7: createColor("#474a4e"),
    grey5: createColor("#7b828b"),
    grey3: createColor("#BCC2C9"),
    default: {
      main: "#474a4e",
    },
  },
});
root.render(
  <RecoilRoot>
    <CookiesProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ko"}>
            <LoadingProvider>
              <App />
            </LoadingProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </CookiesProvider>
  </RecoilRoot>
);
