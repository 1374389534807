import { API_HOST, API_URL, DEFAULT_HEADER } from "../../constants/axios";
import axios from "axios";
const instance = axios.create({
    baseURL: API_HOST,
});

export const loginUser = async (data) => {
    try {
        const res = await instance.post(API_URL.LOGIN, data);
        return res;
    } catch (e) {
        return e.response;
    }
};

export const loginCheck = async (token, data) => {
    try {
        const res = await instance.post(API_URL.LOGIN_CHECK, data, {
            headers: await getTokenHeader(token),
        });
        return res;
    } catch (e) {
        return e.response;
    }
};

export const getUserInfo = async (token) => {
    try {
        const res = await instance.get(API_URL.USER_INFO, {
            headers: getTokenHeader(token),
        });
        return res;
    } catch (e) {
        return e.response;
    }
};

const getTokenHeader = (token) => {
    const res = { ...DEFAULT_HEADER };
    res.header["Authorization"] = `Bearer ${token}`;
    return res.header;
};
