import {
    addProduct,
    getTextHistory,
    createMessageGeneration,
    messageChatInitStreamGeneration,
    messageChatStreamGeneration, updateProducts, saveAIMessage
} from "../../api/ai/ai.api";
import {useEffect, useRef, useState} from "react";
import MessageWrapPreview from "../volatility-message/content/preview/MessageWrapPreview";
import {Button, TextInput} from "omoplay";
import SuspensePopup from "../SuspensePopup";
import ProductConnectionList from "../form/ProductConnectionList";
import {useSearchParams} from "react-router-dom";
import MessageMiniPreview from "../volatility-message/content/preview/MessageMiniPreview";
import ArrowTop from "../../assets/images/icon.arrow.top.svg"
import ChatIcon from "../../assets/images/icon.chat_logo.svg"
import {getProductList} from "../../api/product/product.api";


const AIMessageEdit = ({message, onChangeMessageData}) => {
    const [query] = useSearchParams();
    const [messages, setMessages] = useState([])
    const [blockGenerate, setBlockGenerate] = useState(false)
    const [input, setInput] = useState("")
    const [initDone, setInitDone] = useState(false)
    const scrollRef = useRef(null)
    const [productConnect, setProductConnect] = useState(false)
    const [productsConnect, setProductsConnect] = useState(false)
    const [lastMessageData, setLastMessageData] = useState({})
    const [isCommandKeyPressed, setIsCommandKeyPressed] = useState(false)
    const [isCreateMessageData, setCreateMessageData] = useState(false)
    const [productIds, setProductIds] = useState([])

    const id = query.get("id");

    async function renderProductsTable(data, isLast) {
        const json = JSON.parse(data)
        const {data: productData} = await getProductList(json.productIds);
        renderTable(productData)
    }

    async function renderReProductTable(data) {
        const json = JSON.parse(data)
        const {data: productData} = await getProductList(json.reProductIds);
        renderTable(productData)
    }

    async function renderProductTable(data) {
        const json = JSON.parse(data)
        const {data: productData} = await getProductList(json.productId);
        renderTable(productData)
    }

    function renderTable(productData) {
        setMessages((prevMessages) => [...prevMessages,{
            type: "user",
            message: (
                <div className="flex-col w-auto rounded-[16px] bg-depth1 gap-[10px] p-[20px]">
                    {
                        productData.map(product => {
                            const info = product.info
                            return (
                                <div className="flex min-w-[800px]">
                                    <div className="flex w-full border rounded-[16px] bg-white">
                                        <div className="flex-center-all gap-2 flex-1 border-r" style={{padding: "8px 16px"}}>
                                            <div><img src={info.images[0]} width="48px" height="48px"/></div>
                                            <div className="text-left">{info.productName}</div>
                                        </div>
                                        <div className="flex-center-all flex-1 border-r">{info.category}</div>
                                        <div className="flex-center-all flex-1 border-r">
                                            {info.originalPrice?.toLocaleString()}
                                        </div>
                                        <div className="flex-center-all flex-1">{info.siteName}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        ]);
    }

    function getHistories() {
        getTextHistory(id).then(async ({data}) => {
            setInitDone(data.initDone)
            setMessages([])
            if (data.initDone) {
                setProductIds(data.products.productIds)
                data.history.histories.forEach(history => {
                    let data = history.data
                    if (typeof data !== "string") {
                        setLastMessageData(data)
                        onChangeMessageData(data)
                        setMessages((prevMessages) =>
                            [...prevMessages,
                                {
                                    type: history.type,
                                    message: <MessageWrapPreview messageData={data} type={data.type}/>
                                }
                            ]);
                    } else {
                        setChatModelMessages(history.type, data)
                    }
                })
            } else {
                const initialHistories = data.initializeHistory.histories
                console.log(initialHistories)
                for (const history of initialHistories) {
                    const index = initialHistories.indexOf(history);
                    const historyData = history.data
                    if (historyData.includes("productIds")) {
                        console.log(index, initialHistories.length)
                        await renderProductsTable(historyData, index === initialHistories.length - 1)
                    } else if (historyData.includes("reProductIds")) {
                        await renderReProductTable(historyData)
                    } else if (historyData.includes("productId")) {
                        await renderProductTable(historyData)
                    } else {
                        setChatModelMessages(history.type, historyData)
                    }
                }
            }
        })
    }

    function setChatModelMessages(type, message) {
        setMessages((prevMessages) => [...prevMessages, {
            type: type,
            message: message
        }]);
    }

    const streamGeneration = (text, isText = true) => {
        setBlockGenerate(true)
        setInput("")
        // Enter 키를 누르면 메시지를 추가
        if (isText) {
            setChatModelMessages("user", text)
        }

        const eventSource = initDone ? messageChatStreamGeneration(id, text) : messageChatInitStreamGeneration(id, text)

        let curMessage = "";
        setChatModelMessages("model", curMessage)

        // 서버에서 데이터가 올 때마다 처리
        eventSource.onmessage = (event) => {
            const data = event.data
            if (data.includes("AI_CHAT_END")) {
                setTimeout(() => {
                    getHistories()
                    setCreateMessageData(false)
                    setBlockGenerate(false)
                }, 1000)
                eventSource.close();
            } else {
                if (data.includes("{") || curMessage.includes("{")) {
                    setCreateMessageData(true)
                } else {
                    curMessage += data
                    setMessages((prevMessages) => {
                        // 마지막 메시지만 curMessage로 업데이트
                        const updatedMessages = [...prevMessages];
                        updatedMessages[updatedMessages.length - 1] = {
                            type: "model",
                            message: curMessage
                        };
                        return updatedMessages;
                    });
                }
            }
        };
    }

    const handleChatKeyDown = (e) => {
        if (e.key === "Shift") {
            setIsCommandKeyPressed(true)
        }
    }

    const handleChatKeyUp = (e) => {
        if (e.key === "Enter" && !isCommandKeyPressed && input.trim() !== "" && !blockGenerate) {
            streamGeneration(input)
            return
        }

        if (e.key === "Shift") {
            setIsCommandKeyPressed(false)
        }
    }

    const handleChatSubmit = () => {
        if (input.trim() !== "" && !blockGenerate) {
            streamGeneration(input)
        }
    }

    function onEventAICreateFun(event, isLast) {
        const button = event.target.closest("button[data-action]");
        if (button && isLast) {
            event.stopPropagation()
            const action = button.dataset.action
            if (action === "productsConnect") {
                setProductsConnect(true)
            } else if (action === "productReConnect") {
                setProductConnect(true)
            } else if (action === "confirmCreate" && !button.disabled && !blockGenerate) {
                button.disabled = true
                createMessageGeneration(id).then(({data}) => {
                    if (data === "insufficient") {
                        alert("크레딧이 부족합니다.")
                    } else if (data === "fail") {
                        alert("일시적인 오류로 메시지 생성에 실패 했습니다. 잠시 후 다시 시도 해주세요.")
                    } else {
                        setTimeout(() => {
                            getHistories()
                        }, 1000)
                    }
                })
            } else if (action === "productConnect") {
                setProductConnect(true)
            } else if (action === "imageType") {
                streamGeneration("이미지 추가")
            } else if (action === "textType") {
                streamGeneration("특정 주제로 콘텐츠 생성하기")
            }
        }
    }

    useEffect(() => {
        saveAIMessage(id, message).then(() => {
            getHistories()
        })
    }, []);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="flex-col h-full bg-white border rounded-[8px]" style={{height: "calc(100vh - 220px)"}}>
            <div className="w-full border-b" style={{padding: "16px 24px"}}>
                {
                    lastMessageData.type ? <div><MessageMiniPreview id={id} messageData={lastMessageData}
                                                                    productIds={productIds}
                                                                    onChangeImage={() => {
                                                                        getHistories()
                                                                    }} /></div>
                        : <div>아직 생성된 콘텐츠가 없습니다.</div>
                }
            </div>
            <div className="flex-col flex-1 justify-end w-full overflow-scroll">
                <div className="flex-col gap-4 p-8 overflow-scroll" ref={scrollRef}>
                    {messages.map(({type, message}, index) => {
                        if (type === "model") {
                            return (
                                <>
                                    <div className="flex gap-1">
                                        <img src={ChatIcon} alt="chat-logo" width="20" />
                                        <span className="text-primary font-medium">AI 메시지 봇</span>
                                    </div>
                                    {
                                        initDone ? ((index === messages.length - 1 && isCreateMessageData) ? "메시지를 생성 중입니다..." : message)
                                            : (<div className="ml-[20px]"
                                                    onClick={(e) =>
                                                        onEventAICreateFun(e, index === messages.length - 1)}
                                                    dangerouslySetInnerHTML={{__html: message}}></div>)
                                    }
                                </>
                            )
                        } else {
                            return (
                                <div className="flex justify-end text-right">
                                    <div className="w-auto bg-depth1 p-20px rounded-[16px]">{message}</div>
                                </div>
                            )
                        }
                    })}
                </div>
                <div className="flex p-[24px] border-t">
                    <div className="flex-1 gap-2">
                        {/*
                        <Textarea placeholder="AI에게 질문 해보세요."
                                  value={input}
                                  onChange={(e) => setInput(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  onKeyUp={handleKeyUp}
                        />
                        */}
                        <TextInput placeholder="AI에게 질문 해보세요."
                                   value={input}
                                   onChange={(e) => setInput(e.target.value)}
                                   onKeyDown={handleChatKeyDown}
                                   onKeyUp={handleChatKeyUp}
                                   style={{width: "100%", height: "68px"}}
                        />
                    </div>
                    <div className="relative flex-center-all">
                        <div className="absolute right-0 p-[24px]">
                            <Button disabled={blockGenerate} variants="rounded"
                                    value={<img alt="질문" src={ArrowTop} />}
                                    onClick={handleChatSubmit} />
                        </div>
                    </div>
                </div>
            </div>
            <SuspensePopup
                title={"제품 연결하기"}
                visible={productsConnect || productConnect}
                style={{width: "1200px", height: "600px"}}
                onClose={() => {
                    setProductConnect(false)
                    setProductsConnect(false)
                }}
            >
                <ProductConnectionList isMultiple={productsConnect} onConfirm={(ids) => {
                    setProductConnect(false)
                    setProductsConnect(false)
                    if (productConnect) {
                        addProduct(id, ids).then(() => {
                            const text = `{"reProductIds":[${ids}]}`
                            renderReProductTable(text).then(() => {
                                streamGeneration(text, false)
                            })
                        })
                    } else if (productConnect) {
                        addProduct(id, ids).then(() => {
                            const text = `{"productId":[${ids}]}`
                            renderProductTable(text).then(() => {
                                streamGeneration(text, false)
                            })
                        })
                    } else {
                        updateProducts(id, ids).then(() => {
                            const text = `{"productIds":[${ids}]}`
                            renderProductsTable(text).then(() => {
                                streamGeneration(text, false)
                            })
                        })
                    }
                }}
                />
            </SuspensePopup>
        </div>
    )
}

export default AIMessageEdit