import React from "react";
import {Checkbox} from "omoplay";


const ImageEditorReplaceBackgroundList = ({messageData, onChecked}) => {
    const type = messageData.type

    if (type === "FI" || type === "FT" || type === "FW") {
        return (
            <div>
                <img className="w-[142px] h-[106px]" src={messageData.originalUrl} alt="Thumbnail url"
                     crossOrigin="anonymous" />
                <div className="flex-center-all p-[8px]">
                    <Checkbox onChecked={(flag) => onChecked(flag, 0)} />
                </div>
            </div>
        )
    } else if (type === "FL") {
        const items = messageData.items ?? []

        return (
            <>
                {
                    items
                    .filter((item) => item.originalUrl != null)
                    .map((item, index) => {
                        return (
                            <div>
                                <img className="w-[142px] h-[106px]" src={item.originalUrl} alt="Thumbnail url"
                                     crossOrigin="anonymous" />
                                <div className="flex-center-all p-[8px]">
                                    <Checkbox onChecked={(flag) => onChecked(flag, index)} />
                                </div>
                            </div>
                        )
                    })
                }
            </>
        )
    } else if (type === "FC") {
        const carousels = messageData.carousels ?? []

        return (
            <>
                {
                    carousels
                    .filter((carousel) => carousel.attachment?.image != null)
                    .map((carousel, index) => {
                        return (
                            <div className="bg-white rounded-[8px]">
                                <img className="w-[142px] h-[106px]" src={carousel.attachment.image.originalUrl}
                                     alt="Thumbnail url"
                                     crossOrigin="anonymous" />
                                <div className="flex-center-all p-[8px]">
                                    <Checkbox onChecked={(flag) => onChecked(flag, index)} />
                                </div>
                            </div>
                        )
                    })
                }
            </>
        )
    }
}

export default ImageEditorReplaceBackgroundList