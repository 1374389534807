import { Suspense } from "react";
import Loading from "../Loading";
import {PageDArrowLeftIcon} from "omoplay";

const OMOSettingsLayout = ({title, children}) => {
  return (
    <div className="min-h-screen" style={{background: "var(--color-shape-background, rgba(247, 247, 249, 1))"}}>
        <div className="relative">
            <nav className="fixed top-0 w-full bg-white border-b border-b-gray-300 divide-y divide-gray-300 z-10">
                <div className="p-1">
                    <div className="flex items-center">
                        <div className="px-3 py-4">
                            <div className="flex-align-center pointer" style={{width: "32px", height: "32px"}} onClick={() => window.location.href = "/services"} >
                                <PageDArrowLeftIcon />
                            </div>
                        </div>
                        <div className="ml-2 font-bold text-lg">{title}</div>
                    </div>
                </div>
            </nav>
        </div>
      <div className="min-h-screen flex justify-center">
        <div className="mt-20">
          <Suspense
            fallback={
              <div className="w-100 text-center py-8 justify-center">
                <Loading className={"w-20 h-20 mx-auto"} />
              </div>
            }
          >
            {children}
          </Suspense>
        </div>
      </div>
    </div>
  );
};
export default OMOSettingsLayout;
