import { useState } from "react";
import BillWrap from "../layout/BillWrap";
import {Tab} from "omoplay";
import CardBillingForm from "../form/CardBillingForm";

const tabs = [
    { value: "regular", label: "결제 정보", selected: true },
    { value: "bill", label: "월 별 청구서" },
];
const Payments = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  return (
    <div className="flex-col gap-4">
        <Tab items={tabs} onChanged={(tab) => setActiveTab(tab.value)}
             style={{gap: "4px"}} />
        {activeTab === "bill" && <BillWrap />}
        {activeTab === "regular" && <CardBillingForm />}
    </div>
  );
};

export default Payments;
