import { API_HOST, API_URL, DEFAULT_HEADER } from "../../constants/axios";
import axios from "axios";
import { fetchApi } from "../fetch";

export const getPartnerUserStatistics = async (startDate, endDate) => {
    const res = await fetchApi.get(
        API_URL.PARTNER_USER_STATISTICS +
            `?startDate=${startDate}&endDate=${endDate}`
    );
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
};

export const getSpotUserStatistics = async (spotId, startDate, endDate) => {
    const res = await fetchApi.get(
        API_URL.SPOT_USER_STATISTICS.replace("{spotId}", spotId) +
            `?startDate=${startDate}&endDate=${endDate}`
    );
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
};



export const getSpotInflowStatistics = async (spotId, startDate, endDate) => {
    const res = await fetchApi.get(
        API_URL.SPOT_USER_INFLOW_STATISTICS.replace("{spotId}", spotId) +
        `?startDate=${startDate}&endDate=${endDate}`
    );
    if (res.status === 200) {
        return res.data;
    }
    return {};
};
