import Layout from "../components/layout/Layout";
import EventList from "../components/page/EventList";

const EventPage = () => {
  return (
    <Layout>
      <EventList />
    </Layout>
  );
};
export default EventPage;
